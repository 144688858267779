import React from 'react'
import { Table, TableBody, TableHead, Box } from '@mui/material'
import { TableBodyRow, TableHeaderRow } from '../../../../../components/customTable/tableRow'

import CampaignMagnetScore from './campaignMagnetScore'
import LegendRow from './legendRow'
import SearchReportButton from './searchReportButton'
import { InteractionWrap, TableWrap } from './style'

const columnSx = {
  fontWeight: '600 !important',
}

const headerMeta = [
  {
    id: 'TriggerName',
    label: 'Trigger Name',
    minWidth: '20%',
    align: 'right',
    sx: { visibility: 'hidden' },
  },
  {
    id: 'CampaignMagnetScore',
    label: 'Campaign <br/> Magnet Score',
    isHTML: true,
    minWidth: '20%',
    sx: columnSx,
  },
  {
    id: 'SentimentEngagement',
    label: 'Sentiment & <br/> Engagement',
    isHTML: true,
    minWidth: '20%',
    sx: columnSx,
  },
  {
    id: 'InteractionsPaidInteractions',
    label: 'Interactions & <br/> Paid Interactions',
    isHTML: true,
    minWidth: '20%',
    sx: columnSx,
  },
  {
    id: 'BrandAssociation',
    label: 'Brand <br/> Association',
    isHTML: true,
    minWidth: '20%',
    sx: columnSx,
  },
  { id: 'Action', label: '', align: 'left', minWidth: '2%' },
]

const SandBoxDataTable = ({data}) => {

  return (
    <TableWrap className='campaignTriggersRow tableWrap'>
      <Table stickyHeader sx={{ marginTop: 4 }} aria-label='sticky table'>
        <TableHead style={{ border: '5px solid #838383' }}>
          <TableHeaderRow headerMeta={headerMeta} />
        </TableHead>
        <TableBody>
          {data?.map((modul, index) => {
            // if(index === 0) return null
            const updatedModul = {
              ...modul,
              TriggerName: <Box className='triggerName'>{modul.TriggerName}</Box>,
              CampaignMagnetScore: (
                <CampaignMagnetScore
                  bottomText={parseInt(modul.CampaignMagnetScore).toLocaleString()}
                  barData={modul.barData['CampaignMagnetScore'].bar}
                  rowData={modul}
                  maxPercentRatio={modul.barData['CampaignMagnetScore'].maxPercentRatio}
                  endIcon={modul.barData['CampaignMagnetScore'].icon}
                />
              ),
              SentimentEngagement: (
                <CampaignMagnetScore
                  // bottomText={`${parseInt(modul.Engagement).toLocaleString()} | Net Sentiment ${modul.NetSentiment}%`}
                  bottomText={`${parseInt(modul.Engagement).toLocaleString()} | Net Sentiment ${modul.PositivePercent - modul.NegativePercent}%`}
                  rowData={modul}
                  barData={modul.barData['SentimentEngagement'].bar}
                  maxPercentRatio={modul.barData['SentimentEngagement'].maxPercentRatio}
                  valueInPercent={true}
                  showToolTip={true}
                />
              ),
              InteractionsPaidInteractions: (
                <InteractionWrap>
                  <CampaignMagnetScore
                    // bottomText={`${parseInt(modul.EarnedOrganicReach).toLocaleString()} | ${parseInt(modul.PaidReach).toLocaleString()}`}
                    rowData={modul}
                    showInside={true}
                    maxPercentRatio={modul.barData['InteractionsPaidInteractions'].maxPercentRatio}
                    customPercentage="100"
                    barData={modul.barData['InteractionsPaidInteractions'].bar}
                  />
                </InteractionWrap>
              ),
              BrandAssociation: (
                <CampaignMagnetScore
                  bottomText={(parseInt(modul.BrandAssociation) + parseInt(modul.BrandMention) / 100)+'%'}
                  rowData={modul}
                  background={modul.barData['BrandAssociation'].background}
                  barData={modul.barData['BrandAssociation'].bar}
                  maxPercentRatio={100}
                />
              ),
              Action: <SearchReportButton id={modul.TriggerId} />,
              sx: {
                paddingRight: '5px',
                paddingLeft: '5px',
              },
            }
            return (
              <TableBodyRow
                key={index + modul.TriggerName}
                rowData={updatedModul}
                headerMeta={headerMeta}
              />
            )
          })}
        </TableBody>
      </Table>
      <LegendRow />
    </TableWrap>
  )
}

export default SandBoxDataTable
