import React, { useEffect, useRef } from 'react'
import {
  select,
  scaleBand,
  axisBottom,
  stack,
  max,
  scaleLinear,
  axisLeft
} from 'd3'
import { Box } from '@mui/material'
import { setCurrency } from '../../util'

import {
  Wrapper,
  SvgWrapper
} from './style'

export default function D3BarVerticalStacked (props) {
  return (
    <D3BarVerticalStackedChild color={props?.colors} barWidth={props?.barWidth} data={props?.data} keys={props?.keys} noText={props?.noText || false} yAxis={props?.yAxis || true} />
  )
}

// const D3BarVerticalStackedChild = async ({ barWidth, data, keys, colors, noText, yAxis }) => {
const D3BarVerticalStackedChild = ({barWidth, noText, data, keys, color}) => {
  // https://codepen.io/learning-monk/pen/qBWydYq

  const width = 190, height = 300;
  const margin = { top: 10, right: 10, bottom: 20, left: 70 };
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;

  const finalData = data
  const stackGenerator = stack().keys(keys)

  // Data, stacked
  const series = stackGenerator(finalData)

  // set-up scales
  const xScale = scaleBand()
    .domain(finalData.map((d) => d.Region))
    .range([0, innerWidth])
    .padding(0.1);

  const xAxis = axisBottom().scale(xScale);

  const yScale = scaleLinear()
    .domain([0, max(series, (d) => max(d, (d) => d[1]))])
    .rangeRound([innerHeight, 0]);

  const yAxis = axisLeft().scale(yScale);

  const svgRefWrapper = useRef()
  const svgRefNew = useRef()

  const generateGraph = () => {
    const svgWrapper = select(svgRefWrapper.current)
    const svg = select(svgRefNew.current).append('svg').attr('width', width).attr('height', height).attr('viewBox', [0, 0, width, height])
    const dataLength = finalData.length

    const mainG = svg
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    // Add a group for each row of data
    const g = mainG
      .selectAll('g')
      .data(series)
      .enter()
      .append('g')
      .style('fill', function (d, i) {
        return color[d.key]
      })

    // Add a rect for each data value
    g.selectAll('rect')
      .data((d) => d)
      .enter()
      .append('rect')
      .attr('x', function (d, i) {
        const defaultPosi = (width/2) - (barWidth/2) - (margin.left/2) - (margin.right/2)
        if(dataLength === 1){
          return defaultPosi
        }
        return (defaultPosi*i) + 20;
      })
      .attr('y', function (d) {
        return yScale(d[1]);
      })
      .attr('height', function (d) {
        return yScale(d[0]) - yScale(d[1]);
      })
      .attr('width', barWidth)

      .on('mouseover', function (event, d) {
        let xPosition = parseFloat(select(this).attr('x')) - 15;
        let yPosition = parseFloat(select(this).attr('y') - (-select(this).attr('height')/2) );

        // Update the tooltip position and value
        svgWrapper.select('.tooltip')
          .style('left', xPosition + 'px')
          .style('top', yPosition + 'px')
          .select('.value')
          .text(setCurrency( d[1]*1 - d[0]*1 ))

        svgWrapper.select('.tooltip').classed('hidden', false);
      })
      .on('mouseout', function () {
        svgWrapper.select('.tooltip').classed('hidden', true);
      })
  
    mainG
      .append('g')
      .call(xAxis)
      .attr('transform', `translate(0,${innerHeight})`)

    mainG.append('g').call(yAxis);

  }

  useEffect(() => {
    generateGraph()
  }, [])

  let visibility = '' 
  noText ? visibility = 'invisible' : visibility = ''

  return (
    <Wrapper>
      <SvgWrapper ref={svgRefWrapper} xaxis={!noText} className='svg-wrap'>
        <Box ref={svgRefNew}></Box>
        <div className='tooltip hidden'>
          <p className="value"></p>
        </div>
      </SvgWrapper>
    </Wrapper>
  )
}
