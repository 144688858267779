import { Table, TableBody, TableHead } from '@mui/material'
import React, { useState } from 'react'
import { SearchInput } from '../../../../components'
import { TableBodyRow, TableHeaderRow } from '../../../../components/customTable/tableRow'
import { DraftContentWrapper, DraftSearchRowWrap, DraftTableWrap, DraftTitleWrap } from './style'


const Draft = ({onClose}) => {
  const [projectData, setProjectData] = useState([])
  const filteredRows = [{
    name: 'Dummy Data 1',
    report: 'Dummy Data 2', 
    ModifiedDate: new Date().toISOString()
  }, {
    name: 'Dummy Data 3',
    report: 'Dummy Data 4', 
    ModifiedDate: new Date().toISOString()
  }]

  const headerMeta = [
    {
      id: 'name',
      label: 'Simulation Name',
      minWidth: '40%',
      align: 'left',
      filterMethod: setProjectData,
    },
    {
      id: 'report',
      label: 'Report',
      minWidth: '40%',
      align: 'left',
      filterMethod: setProjectData,
    },
    {
      id: 'ModifiedDate',
      label: 'Last Updated',
      minWidth: '20%',
      align: 'right',
      filterMethod: setProjectData,
    }
  ]

  return (
    <DraftContentWrapper>
      <DraftTableWrap>
        <DraftSearchRowWrap>
          <DraftTitleWrap>Simulation Records</DraftTitleWrap>
          <SearchInput name='simulatorSearch' />
        </DraftSearchRowWrap>
        <Table className='tableRoot'>
          <TableHead>
            <TableHeaderRow headerMeta={headerMeta} />
          </TableHead>
          <TableBody>
            {filteredRows?.map((modul, index) => {
              return (
                <TableBodyRow
                  key={modul.id}
                  rowData={modul}
                  headerMeta={headerMeta}
                />
              )
            })}
          </TableBody>
        </Table>
      </DraftTableWrap>
    </DraftContentWrapper>
  )
}

export default Draft;

