import React from 'react';
import { search } from '../../assets/images/icons';
import MuiImage from '../muiImage';
import MuiTextField from '../muiTextField';
import { InputWrap } from './style';


const SearchInput = ({ value = '', onChange, placeholder="Search", name = 'searchInput', ...rest }) => {
  return (
    <InputWrap className='searchInputWrap'>
      <MuiTextField
        InputProps={{
          endAdornment: <MuiImage style={{ opacity: rest.disabled ? 0.3 : 1 }} src={search} width='17px' />,
        }}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        name={name}
        {...rest}
      />
    </InputWrap>
  );
};

export default SearchInput;
