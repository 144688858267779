
export const NAME_LOGIN = 'Login'
export const NAME_RESET_PASSWORD = 'Reset Password'
export const NAME_PROJECTS = 'Projects'
export const NAME_INITIATE_PROJECTS = 'Initiate New Project'
export const NAME_USER_MANAGEMENT = 'User Management'
export const NAME_RESOURCES = 'Resources'
export const NAME_HELP = 'Help'

export const NAME_REPORTS_BACKGROUND = 'Background'
export const NAME_REPORTS_EXECUTIVE_SUMMARY = 'Executive Summary'
export const NAME_REPORTS_KEY_FUNDINGS = 'Key Findings'
export const NAME_REPORTS_RESEARCH_SECTION1 = 'Research Section 1'
export const NAME_REPORTS_RESEARCH_SECTION2 = 'Research Section 2'
export const NAME_REPORTS_DEEP_DIVE_ANALYSIS = 'Deep Dive Analysis'
export const NAME_REPORTS_SANDBOX = 'Sandbox'

