import React from 'react';
import { close } from '../../assets/images/icons'
import { IconButton } from '@mui/material';

const CloseButton = ({size="small", onClick, position='absolute'}) => {

  return (
    <IconButton size={size} onClick={onClick} className="closeButton" sx={{
        position: position,
        right: "10px",
        top: "10px"
       }}>
       {/* <HighlightOffOutlined /> */}
       <img src={close} alt='Close' width='24px' />
    </IconButton>
  )
}

export default CloseButton