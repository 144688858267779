import styled from '@emotion/styled'
import { Box } from '@mui/material'

export const ToolTipWrap = styled(Box)(`
    padding: 28px 46px 28px 14px;
    font-size: 0.9rem,
    overflow: auto;
    background: #F7F7F9;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.15);
`)


export const ToolTipRoot = styled(Box)(``)