import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'

export const InnerWrapper = styled(Box)( props => `
    position:relative;
    display:block;
	background:#fff;

    width: 100%;
    height: 100%;
	border: ${props.hasTabs ? '1px' : '0'} #EEE solid;
    // box-shadow: ${props.hasTabs ? '0 0 10px 0 #D9D9D9' : 'none'};
    // display: ${props.hasTabs ? 'block' : 'none'};
    // position: absolute;
    // left: 0;
    // top: 0;

`)

export const GridHeaderWrapper = styled(Box)(`
    display:flex;
    width:100%;
    position:relative;
    // z-index:1;
`)

export const HeaderWrap = styled(Box)(`
	display: flex;
	align-items: center;
	width: 100%;
`)

export const ContentWrapper = styled(Box)( props => `
	padding: ${props.hasTabs ? '12' : 0}px;

	${props.type === 'BrandImageryMappingAndProgressionGraph' && `
		padding:0 !important;
	`}

	& .filterOuterWrap {
		width: 100% !important;
		margin: 10px 0 10px 0 !important;
	}
`)

export const HeaderTab = styled(Box)(`
    display: flex;
	align-items: center;
	background: #F7F7F9;
	padding: 6px 10px;
	// overflow: hidden; // showing error when we click on Global Tutorial Button
	width: 100%;
	& .tab {
		padding: 5px 10px;
		font-size: 14px;
		font-weight: 300;
		margin-right: 2px;
		cursor: pointer;
		position:relative;

		&.active {
			background: #fff;
			font-weight: 500;
		}
	}
`)
