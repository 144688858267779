import React, { useState } from 'react'
import { BarWrap, SegmentRow, SegmentRowContent, SegmentTitle, ComparisonViewWrap, IssuesWrap, SegmentSubTitle } from './style'
import { ChartBarHorizontalCustom } from '../../../../components'
import {  LEGEND_TEXT_COLOR_MAP } from '../../../../constants'
import { Typography } from '@mui/material'

const SegmentTable = ({ data=[], hideTopBlueBar=false, type, insightsElementType }) => {
	const [segmentWidth, setSegmentWidth] = useState(0)
	const setSegmentWidthFunc = (text) => {
		const wid = text.length*13
		if(wid > segmentWidth) {
			setSegmentWidth(prev => wid)
		}
		return text
	}

	return (
		<ComparisonViewWrap>
			{
				data?.map((item, index) => {
					if(!item?.SegmentDrivers) return false
					const driverElements = Array.isArray(item.SegmentDrivers.DriverElement) ? item.SegmentDrivers.DriverElement :
					Object.keys(item.SegmentDrivers.DriverElement || {})?.length ? [item.SegmentDrivers.DriverElement] : [];
					return (
						<SegmentRow sx={{
							marginBottom: data?.length > index ? "5px !important" : "",
							width: `calc(100% - ${segmentWidth}px)`
						}} className='segment-row' key={item.SegmentName}>
						<SegmentSubTitle className='segment-title'>{item?.SegmentName || item.PeriodName}</SegmentSubTitle>
							{ (!['SegmentDriversAnalysis', 'Triangulation', 'TriangulationComparisonViewOnly'].includes(insightsElementType)) && !hideTopBlueBar &&
								<SegmentRowContent className='segment-row-content' sx={{
									marginBottom: "25px !important", marginLeft: "-10px !important"
								}}>
									<BarWrap>
										<ChartBarHorizontalCustom
											showInside={true}
											bottomText={false}
											data={[{
												"0": item.SegmentValue
											}]}
											// valueInPercent={true}
											total={item.SegmentValue}
											maxPercentRatio={100}
											maxWidth="100%"
										/>
									</BarWrap>
								</SegmentRowContent>
							}
							{
								driverElements.map((productItem) => {
									const driverAttributeStandouts = Array.isArray(productItem?.DriverAttributeStandouts?.DriverAttributeStandout) ? productItem?.DriverAttributeStandouts?.DriverAttributeStandout :
										Object.keys((productItem?.DriverAttributeStandouts?.DriverAttributeStandout || {}))?.length ? [productItem?.DriverAttributeStandouts?.DriverAttributeStandout] : [];

										let bars = type ? {
											[type]: productItem.Percent,
										} : {
											Positive: productItem.PositivePercent,
											Neutral: productItem.NeutralPercent,
											Mixed: productItem.MixedPercent,
											Negative: productItem.NegativePercent
										}

										if(['Triangulation', 'TriangulationComparisonViewOnly'].includes(insightsElementType)){
											const d = bars
											if( d.Positive + d.Neutral + d.Mixed + d.Negative === 0 ){
												bars = {SearchKeywords: productItem.Percent}
											}
										}

										if(['SegmentDriversOnlyAnalysisWithoutSentiments'].includes(insightsElementType)){
											bars = {SearchKeywords: productItem.Percent}
										}

									return (
										<SegmentRowContent className='segment-row-content' key={productItem.DriverId}>
											<SegmentTitle sx={{
												lineHeight: "12px !important"
											}} className='segment-title'>{productItem?.DriverName}</SegmentTitle>
											<BarWrap>
												<ChartBarHorizontalCustom
													showInside={!(['DriversProgressionWithoutSentiments' , 'SearchDriversProgression', 'Triangulation', 'TriangulationComparisonViewOnly'].includes(insightsElementType))}
													bottomText={false}
													valueInPercent={true}
													total={`${productItem.Percent}`}
													data={[bars]}
													maxPercentRatio={100}
													maxWidth="100%"
												/>
											</BarWrap>
											{
												!!driverAttributeStandouts.length && (!['Triangulation', 'TriangulationComparisonViewOnly'].includes(insightsElementType)) && 
												<IssuesWrap>
													<div className='radio'>
														<div className='box'></div>
													</div>
													{
														driverAttributeStandouts?.slice(0, 2)?.map((driverAttribute, ii) => {
															return (
																<Typography sx={{
																	marginRight: "3px",
																	color: LEGEND_TEXT_COLOR_MAP[type || driverAttribute?.Sentiment]
																}} className='radio-text'>
																	{`${setSegmentWidthFunc(driverAttribute?.AttributeName)}${(ii === 0 && driverAttributeStandouts?.length > 1) ? ',' : ' '} `}
																</Typography>
															)
														})
													}
												</IssuesWrap>
											}

										</SegmentRowContent>
									)
								})
							}
						</SegmentRow>
					)
				})
			}
		</ComparisonViewWrap>
	)
}

export default SegmentTable;