import React from 'react'
import { BarLine } from '../../../../components'
import { BrandBarWrap, SegmentText, SegmentWrap } from './style'

const BrandBar = ({maxValue=100, value=0, title="", color}) => {
  
  return (
    <BrandBarWrap className='brandBar'>
        <SegmentWrap>
          <SegmentText className='brandBarTitle'>{title}</SegmentText>
        </SegmentWrap>
        <BarLine maxValue={maxValue} value={value} color={color} />
    </BrandBarWrap>
  )
}

export default BrandBar;