import React from 'react'
import { DrfatButton, Title, Wrapper } from './style'
import { Modal } from '../../../components'

const DeleteConfirm = ({ onConfirm, onClose }) => {
  return (
    <Modal title='' showClose={true} onClose={onClose}>
      <Wrapper>
        <Title variant='body1'>
          Are you sure you want to delete this user?
        </Title>
        <DrfatButton color='primary' variant='contained' onClick={onConfirm}>
          Yes
        </DrfatButton>
      </Wrapper>
    </Modal>
  )
}

export default DeleteConfirm
