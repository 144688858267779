import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import { LEGEND_TEXT_COLOR_MAP } from '../../../../constants'
import { exportToPPT_tableCss } from '../../../../util'
import { MEDIA } from "../../../../constants";

export const OuterWrapper = styled(Box)(`
    width:100%;
    display: flex;
    align-items: center;
    // border: 1px red solid;

    &.exportToPPT {
      max-width:1200px;
      padding:0;
      
      // .columnLine span{width:380px; left: -190px;}
      .infoRow{margin-top:20px !important; margin-bottom:0 !important;}
      .tabWrapper { display:none !important; }
      .tab2:before { display:none; }
      & > div { box-shadow:none; }

      .legendItem { max-width:auto; }

      & .brandCategory { border:0; }
      & .brandCategory > div { padding:0; }      
    }
    // &.exportToPPT svg text{font-size:14px !important; border:1px red solid;}
    ${ exportToPPT_tableCss }

`);

export const InnerWrapper = styled(Box)(`
    display:flex; flex-direction: column; width:100%; position: relative;
`);

export const ProgressionFiltersWrapper = styled(Box)(`
    & .filterOuterWrap {
      padding: 0;
      margin-bottom: 10px;
      // margin-left: -30px;
      // width: calc(100% + 60px);
      // margin-top: -20px;
    }
`);

export const ContentWrapper = styled(Box)(`
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    text-transform: capitalize;
    color: #000000;
    // padding:20px 30px 20px 30px;
    position: relative;
    & ul {
        list-style-position: outside;
        margin-left: 35px;
    }
    .legendItem {
        margin-left: 0;
        margin-right: 10px;
    }

    .infoRow{margin-top:50px !important;}

  .rowLine {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -20px;
    & span {
      width:auto;
      background: #fff;
      display: inline-block;
      position: absolute;
      left: 50%;
      top: -11px;
      font-size: 12px;
      font-weight: 400;
      padding: 0 10px;
      transform: translate(-50%);
      text-align: center;
      line-height: 18px;
      text-wrap: nowrap;
    }
  }
  
  .rowLine:before {
    content: "";
    width: 100%;
    height: 1px;
    background: #7E7E7E;
    position: absolute;
    margin-top: -1px;
    left: 0;
  }
  
  .rowLine:after {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 0 5px 5px;
    border-color: transparent transparent transparent #7E7E7E;
    position: absolute;
    margin-top: -5px;
    right: 0;
  }

  .columnLine {
    position: absolute;
    width: 1px;
    height: 100%;
    background: #7E7E7E;
    top: 0;
    left: -20px;
    & span {
      width: auto;
      background: #fff;
      display: inline-block;
      position: absolute;
      left: 0;
      top: 48%;
      font-size: 12px;
      font-weight: 400;
      padding: 0 10px;
      transform: rotate(-90deg);
      text-align:center;
      text-wrap: nowrap;
    }
  }

  .columnLine:after {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0px 5px 5px 5px;
    border-color: transparent transparent #7E7E7E transparent ;
    position: absolute;
    margin-top: 0;
    left: -5px;
  }
 
  @media (max-width: ${MEDIA.lg}) {
    padding:20px 20px 40px 20px;
  }
`);

export const ImpactDestinationsWrapper = styled(Box)(`
    border: 1px solid #000;
    position: relative;
    margin-left: 25px;
    margin-bottom: 30px;
    min-height: 500;
    position:relative;

    
    & .tooltip .overlay{
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: rgba(0, 0, 0, 0.3);
      z-index:1;
    }

    & .tooltip .popup{
      position: absolute;
      width: 280px;
      min-height: 160px;
      left: calc(50% - 140px);
      top: calc(50% - 80px);

      margin-left: -74px;
      margin-top: -49px;

      background: #FFFFFF;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      border-radius: 2px;
      z-index:2;
      display:flex;
      flex-direction:column;
      padding:10px 25px 20px 15px;
    }

    & .tooltip .popup .driverName{
      font-weight:500;
    }

    & .tooltip .popup .close{
      position:absolute;
      right:10px;
      top:3px;
    }

    & .tooltip .popup * {
      font-size: 12px;
      line-height: 18px;
      color: #000;
    }

    & .tooltip .popup .description{
      padding:10px 0;
      font-weight:normal;
    }

`);

export const AppendicesHeading = styled(Box)(`
  font-size: 16px;
  color: #000;
  padding-bottom:10px;
`);

export const ImpactDestinationsWrapper_ = styled(Box)(`
    border: 1px solid #7E7E7E;
    position: relative;
    &::after {
        content: '';
        background: #7E7E7E;
        height: 1px;
        left: 0;
        right: 0;
        position: absolute;
        top: 50%;
    }
    &::before {
        content: '';
        background: #7E7E7E;
        width: 1px;
        left: 50%;
        position: absolute;
        top: 0;
        bottom: 0;
    }

    & .tooltip .overlay{
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: rgba(0, 0, 0, 0.3);
      z-index:1;
    }

    & .tooltip .popup{
      position: absolute;
      width: 280px;
      min-height: 160px;
      left: calc(50% - 140px);
      top: calc(50% - 80px);

      // margin-left: -28px;
      // margin-top: -28px;
      margin-left: -74px;
      margin-top: -49px;

      background: #FFFFFF;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      border-radius: 2px;
      z-index:2;
      display:flex;
      flex-direction:column;
      padding:10px 25px 20px 15px;
    }

    & .tooltip .popup .close{
      position:absolute;
      right:10px;
      top:3px;
    }

    & .tooltip .popup .link{
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      text-decoration-line: underline;
      color: #095BBD;
    }

    & .tooltip .popup img{
      width:16px;
      height:16px;
    }

    & .tooltip .popup p{
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color#000;
    }

    & .tooltip .popup .percentage{
      color: #000;
      font-weight: 500;
      padding-top:10px;
    }

    & .tooltip .popup .description{
      padding:10px 0;
      font-weight:normal;
    }

    & .tooltip .popup .post{
      font-weight: 500;
    }

    & .tooltip .popup .type{
      font-size: 12px;
      line-height: 18px;
      color: #00B050;
      text-decoration:underline;

      &.Positive a{ color: ${LEGEND_TEXT_COLOR_MAP.Positive};}
      &.Negative a{ color: ${LEGEND_TEXT_COLOR_MAP.Negative};}
      &.Mixed a{ color: ${LEGEND_TEXT_COLOR_MAP.Mixed};}
      &.Neutral a{ color: ${LEGEND_TEXT_COLOR_MAP.Neutral};}
    }

    & .tooltip .popup .positive{
      color: ${LEGEND_TEXT_COLOR_MAP.Positive};
    }
    & .tooltip .popup .positive:before{
      content:'+';
    }
    & .tooltip .popup .negative{
      color: ${LEGEND_TEXT_COLOR_MAP.Negative};
    }
`);

export const InfoWrapper = styled(Box)(`
  margin: 20px 0;
`);

export const ProgressionDataNotFoundWrapper = styled(Box)(`
  display:flex;
  justify-content:center;
  align-items:center;
  // position:absolute;
  // top:0;
  // left:0;
  width:100%;
  height:500px;
  background:#fff;
`);

export const ProgressionDataNotFound = styled(Box)(`
  width:360px;
  padding:40px;
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0px 0px 20px 0px #ABABAB;
  font-size: 16px;
  font-weight: 300;
  text-align:center;
`);

export const LegendWrapper = styled(Box)(`
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;

  // & .legendBox{ opacity:0.5; }

  @media (max-width: 980px) {
    width:100%;
    flex-wrap:wrap;
  }
`);

export const StyledTypography = styled(Typography)(`
  font-size: 12px;
  padding: 3px 0;
  text-transform: initial;
  & b {
    font-weight: 500;
    margin-right: 5px;
  }
`);

export const LinkInfoWrapper = styled("ul")(`
    font-size: 12px;
    color: #095BBD;
    margin-bottom: 20px;
    margin-left: 12px !important;
    margin-top: -20px;
`);

export const ArrowWrapper = styled(Box)(`
  & .line {
    margin-top: 14px;
    width: 90px;
    background: blue;
    height: 10px;
    float: left;
  }
  & .point {
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 30px solid blue;
    float: right;
  }
`);

export const IsAppendicesContentWrapper = styled(Box)(`
  display: flex;
  flex-direction: column;
`);

