import { IconButton } from '@mui/material'
import React from 'react'
import { reportSearch } from '../../../../../assets/images/icons'
import { MuiImage } from '../../../../../components'


const SearchReportButton = ({ id ='' }) => {
  
  const buttonClickHandler = () => {
    const element = document.getElementById(id)
    if(!element) return
    element.scrollIntoView({behavior: 'smooth'})
  }

  return (
    <IconButton onClick={buttonClickHandler}>
      <MuiImage src={reportSearch}/>
    </IconButton>
  )
}

export default SearchReportButton