import {ApiPost} from '../util/axios'

const getUsers = async (companyId, getLatest=false) => {
  const options = {
    reqId: 'GetUsers',
    payload: { companyId },
    resId: 'GetUsersResult',
    resType: 'array',
    getLatest: getLatest
  }

  return await ApiPost(options).then( (res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const getMarkets = async (payload, getLatest=false) => {
  const options = {
    reqId: 'GetMarkets',
    payload: payload,
    resId: 'GetMarketsResult',
    resType: 'array',
    getLatest: getLatest
  }

  return await ApiPost(options).then( (res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const getBrandsAndCategories = async (payload, getLatest=false) => {
  const options = {
    reqId: 'GetBrandsAndCategories',
    payload: payload,
    resId: 'GetBrandsAndCategoriesResult',
    resType: 'array',
    getLatest: getLatest
  }

  return await ApiPost(options).then( (res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const setUsers = async (payload) => {
  const options = {
    reqId: 'SetUser',
    payload: payload,
    resId: 'SetUserResult',
    resType: 'object'
  }

  return await ApiPost(options).then( (res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}


export default { getUsers, getMarkets, getBrandsAndCategories, setUsers }


