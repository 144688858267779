import React from 'react'
const IconRoundExpand = () => {
    return (
        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_3004_87785)">
                <circle cx="11.5" cy="11.5" r="10.5" fill="white" />
                <circle cx="11.5" cy="11.5" r="10" stroke="#5BA3FA" />
            </g>
            <path d="M12.6875 11.1875H16.375C16.5474 11.1875 16.6875 11.3276 16.6875 11.5C16.6875 11.6724 16.5474 11.8125 16.375 11.8125H12.6875H12.3125H6.625C6.45264 11.8125 6.3125 11.6724 6.3125 11.5C6.3125 11.3276 6.45264 11.1875 6.625 11.1875H12.3125H12.6875Z" fill="#5BA3FA" stroke="#5BA3FA" />
            <defs>
                <filter id="filter0_d_3004_87785" x="0" y="0" width="23" height="23" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset />
                    <feGaussianBlur stdDeviation="0.5" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.32 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3004_87785" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3004_87785" result="shape" />
                </filter>
            </defs>
        </svg>
    )
}

export default IconRoundExpand
