import { styled } from '@mui/material/styles'
import { Typography, FormControlLabel, Box } from '@mui/material'
import { MuiButton } from '../../components'

export const FilterOuterWrap = styled(Box)(`
    padding: 0;
	display: flex;
	width: 100%;
	// margin-top:-50px;

	.filterButton {
		color: #095BBD;
		font-size: 14px;
		font-weight: 500;
		background: none;
		padding: 0;
		min-width: auto;
		margin-bottom:10px;
	}
`)

export const FilterListOverlay = styled(Box)(`
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 5;
	background: rgba(255, 255, 255, 0.02);
	overflow: auto;
`)

export const FilterExpanded = styled(Box)(`
	display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
	position: relative;
	z-index: 5;

	width: 100%;
	// padding: 10px 0 0;
	// padding: 10px 0;
	// border-bottom: 1px #F6F4F5 solid;

	& > button:before { content:''; width:0px; height: 25px; border-left:1px #EEE solid; position: absolute; top: 5px; left: -10px; display:block; }
	& > button:first-of-type:before { display: none; }

	& label span {
		line-height: 15px !important;
		font-size: 14px !important;
	}
	& .selectAll {
		margin-left:-6px;
		color:#095BBD;
	}
	& svg {
		margin-left:10px;
	}
`)

export const FilterCollapsed = styled(Box)(`
	display: flex;
	width: 100%;

	& svg {
		margin-left:10px;
		transform: rotate(180deg);
	}
`)

export const FilterListWrapper = styled(Box)(`
	position: absolute;
	top:40px;
	right:0;
	z-index:6;
	background: #fff;
	background: linear-gradient(90deg, #F7F7F9 0%, #F7F7F9 17.4%, rgba(255,255,255,1) 17.4%, rgba(255,255,255,1) 100%);
	width: 240px;
	height: auto;
	max-height: 250px;
	border: 1px #1D6AC6 solid;
	overflow:hidden;
	display: none;

	&.show_true {display: block;}
`)

export const FilterSearchWrap = styled(Box)(`
	width: 100%;
	padding: 10px 0;

	& .selectAll span.MuiCheckbox-root:hover {
		background: none
	}

	& input[type=text], & input[type=text]:focus-visible {
		width: 95px;
		height: 25px;
		border: 0;
		border-bottom: 1px #CECECE solid;
		outline:0;
		padding:0;
		margin:0 14px 0 0;
		line-height: 25px;
		font-size: 14px;
	}

	& a.clearAll {
		font-weight: 500;
		font-size: 14px;
		color: #1D6AC5;
		text-decoration: none;
	}
`)

export const FilterHeading = styled(MuiButton)(`
	display: flex;
	color: #000;
	font-size: 14px;
	font-weight: 500;
	margin-left:20px;
	z-index: 1;

	&.active {
		font-weight: 500;
		color: #1D6AC6;
		position: relative;
	}
	& span {
		display:none;
	}
	&.active span {
		display:block;
		position: absolute;
		top:22px;
		left:calc(50% - 10px);
		border: 10px transparent solid;
		border-bottom-color: #1D6AC6;
	}
`)

export const FilterSelectAll = styled(FormControlLabel)(`
  & .MuiTypography-root {
    font-size: 14px;
    font-weight: 400;
	color: #095BBD;
  }
  & .MuiCheckbox-root{
    padding: 0;
    margin: 3px 10px auto 12px;
  }
`)

export const FilterCheckboxWrap = styled(Box)(`
	display: flex;
	padding: 1px 6px 0px 13px;
	flex-wrap: wrap;
	width: calc(100% - -12px);
	@-moz-document url-prefix() {
		width: 100%;
	}
	overflow: auto;
	max-height: 170px;
	align-content: flex-start;

	& label {
		width: 100%;
		align-items: flex-start;
		padding-bottom: 19px !important;
		margin:0;
	}
	& label > span.MuiTypography-body1 {
		max-width: 175px;
	}
	& label > span.MuiCheckbox-root {
		min-width: 30px;
		padding: 0 25px 0 0;
	}
	& label > span.MuiCheckbox-root:hover {
		background: none;
	}
	& label svg {
		margin: 0;
	}

	&.radioWrapper span.MuiRadio-root {
		padding-right: 25px;
	}
	&.radioWrapper span.MuiRadio-root:hover {
		background: none;
	}

`)

export const FilterButtonsWrap = styled(Box)(`
	display: flex;
	width: 100%;
	justify-content: end;
	padding: 15px 0 30px 0;
`)



