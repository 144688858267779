import React, { useState, useEffect, useRef } from 'react'
import ReportsService from '../../services/reports.service'

import { Modal, Rte } from '..'

import { LoginButton, Title, Wrapper } from './style'

const ReportBrandImageryEditHeaderLevel2 = (props) => {
  const {onClose, insightsElementId, driverId, driverName, pageInsights, onSuccess} = props
  const [showEditHeaderModal, setShowEditHeaderModal] = useState(true)

  const handleOnEditHeader = () => {
    setShowEditHeaderModal(false)
    onClose()
  }

  const form = useRef()
  const [loader, setLoader] = useState(false)
  const [insightsValue, setInsightsValue] = useState(pageInsights || '')

  useEffect(() => {
    setInsightsValue(pageInsights);
  }, [pageInsights])
 
  const onSubmit = (e) => {
    e.preventDefault()
    const updatedValues = {
      insightsElementId : insightsElementId,
      driverId : driverId,
      insights : insightsValue
    }

    setLoader(true)
    ReportsService.reportSetBrandImageryMappingElementInsights(updatedValues)
    .then((resp) => {
      setLoader(false)
      setShowEditHeaderModal(false)
      onSuccess(updatedValues)
    })
    .catch((error) => {
      setLoader(false)
      const resMessage = error
      console.log('ERROR : ', resMessage)
        // enqueueSnackbar(resMessage, { variant: 'error' })
    })
  }

  return (
    <>
      {showEditHeaderModal && <Modal title='' showClose={true} onClose={handleOnEditHeader}>
        <Wrapper>
          <form ref={form}>
            <Title variant='body1'>Edit {driverName}</Title>

            <Rte title={'Insights'} value={insightsValue} onChange={(e) => setInsightsValue(e)} height={320} />

            <LoginButton
              color='primary'
              variant='contained'
              type='submit'
              // onClick={formik.handleSubmit}
              onClick={onSubmit}
              disabled={loader || false}
            >
              Submit
            </LoginButton>
          </form>
        </Wrapper>
      </Modal>}
    </>
  )
}

export default ReportBrandImageryEditHeaderLevel2
