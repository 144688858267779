import React, { useEffect, useState } from 'react'
import { LoaderWrapper, ReportHeading, ReportOuterWrapper } from '../../../../components'
import reportsService from '../../../../services/reports.service'
import { useUpdateLhs } from '../../../../hooks/useUpdateLhs'
import BuyerTable from '../buyerMaturity/buyerTable'
import RelativeBrands from '../relativeBrandsAnalysis/relativeBrands'
import { ContentWrap, Divider, RootWrap, SegmentOfSegmentWrap } from './style'
import { exportExcel_addPercentage } from '../../../../util'

const ReportSegmentAndSegmentOfSegment = (props) => {
  const [graphPieData, setGraphPieData] = useState([])
  const [loading, setLoading] = useState(false)
  const [state, setState] = useState([])
  const [segmentExcelData, setSegmentExcelData] = useState()
  const [eventCount, setEventCount] = useState(1)
  const {updateSubLhsApiLoaded} = useUpdateLhs()

  const loadStudySetup = () => {
    setLoading(true)
    if (props.InsightsElementType === 'SegmentAndSegmentOfSegment') {
      reportsService.getSegmentOverviewElement({ insightsElementId: props.InsightsElementId })
        .then((resp) => {
          const pieData = resp.map(d => { return { name: d.SegmentName, value: d.SegmentValue } })
          const checkPieTotal = pieData.map(d => d.value).reduce((a, b) => (a + b), 0)
          if(checkPieTotal < 100){
            pieData.push({name: 'REMAINING_VALUE', value: 100-checkPieTotal})
          }
          setGraphPieData(pieData)
          setTimeout(() => {
            setLoading(false)
            updateSubLhsApiLoaded(props)
          }, 2000)
        })
        .catch((error) => {
          const resMessage = error
          console.log('ERROR : ', resMessage)
          setLoading(false)
        })
    }
  }

  const getSegment = () => {
    setLoading(true)
    reportsService.getSegmentOfSegmentOverviewElement({ insightsElementId: props.InsightsElementId })
      .then((resp) => {
        setState(resp);
        setTimeout(() => {
          setLoading(false)
          updateSubLhsApiLoaded(props)
        }, 2000)
      })
      .catch((error) => {
        const resMessage = error
        console.log('ERROR : ', resMessage)
      })
  }

  useEffect(() => {
    loadStudySetup()
    getSegment()
  }, [])

  useEffect(() => {
    if(!!state && !!graphPieData){
      const segmentExcelDataObj = []   
      for(let i=0; i<graphPieData.length; i++){
        segmentExcelDataObj.push([{value: 'Overall'}, {value: graphPieData[i].name}, exportExcel_addPercentage(graphPieData[i].value)])
      }

      for(let i=0; i<state.length; i++){
        const segmentSet = Array.isArray(state[i]?.SecondarySegmentSet?.SecondarySegment) ? state[i].SecondarySegmentSet.SecondarySegment : [state[i].SecondarySegmentSet.SecondarySegment]
        for(let j=0; j<segmentSet.length; j++){
          segmentExcelDataObj.push([{value: state[i].PrimarySegmentName}, {value: segmentSet[j].SegmentName}, exportExcel_addPercentage(segmentSet[j].SegmentValue)])
        }
      }
      setSegmentExcelData(segmentExcelDataObj)
    }
  }, [state, graphPieData])

  useEffect(() => {
    setTimeout(() => {
      setEventCount(p => p+1)
    }, 2000)
  }, [state, graphPieData])

  return (
    <>
      <ReportHeading resetInsightHeight={eventCount} downloadId={props?.type} {...props} downloadIdData={segmentExcelData} />
      <ReportOuterWrapper>
        <RootWrap id={props?.type}>
          <LoaderWrapper loading={loading} loadOnlyApi={props.loadOnlyApi}>
            { !!(graphPieData?.length || state?.length) &&
              <ContentWrap className='content'>
                {!!graphPieData?.length &&
                  <RelativeBrands graphPieData={graphPieData} />
                }

                {!!state?.length &&
                  <SegmentOfSegmentWrap className='barGraph'>
                    <BuyerTable isExtraColor={props.isExtraColor} data={state} />
                  </SegmentOfSegmentWrap>
                }
              </ContentWrap>
            }
          </LoaderWrapper>
        </RootWrap>
      </ReportOuterWrapper>
    </>
  )
}

export default ReportSegmentAndSegmentOfSegment