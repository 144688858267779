import styled from "@emotion/styled";
import { Box, TableCell, TableRow } from "@mui/material";

export const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd) td": {
    borderTop: "1px solid #F7F7F9",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#F7F7F9",
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.10)',
  },
  "&:last-child td, &:last-child th": {
    borderBottom: "1px solid #F7F7F9",
  }
}));

export const StyledTableCell = styled(TableCell)(() => ({
  fontSize: "14px !important",
  lineHeight: "16px !important",
}));


export const CustomTableWrap = styled(Box)(`
& .tableRoot {
  margin-top: 40px;
  overflow: auto;
 }
  & tr{
      box-shadow:none !important;
  }
  & th, td {
      border-bottom: 1px solid #EEEEEE;
      background: #fff;
      font-size: 14px;
      padding-left: 0;
      font-weight: 300;
  }
  & th {
      text-transform: capitalize !important;
  }
`)