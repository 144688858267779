import { styled } from '@mui/system'
import { Box } from '@mui/material'
import { MuiButton } from '../'
import { MEDIA } from '../../constants';

export const WrapperOld = styled(Box)(`
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    padding-bottom: 60px;

    position: absolute;
    width: 100%;
    height: 100%;
`)

export const TextWrapper = styled(Box)(`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    color: #fff;
    font-weight: 500;
    font-size: 12px;
    line-height: 13px;
    max-width: 150px;
    display:flex;

    & span{ padding-left:10px; }
    & svg { width:40px; }

    &.valign_bottom span{ padding-top:25px; }

    &.align_left{ flex-direction: row-reverse; transform: translate(-100%) }
    &.align_left svg{ transform: scaleX(-1) }
    &.arrowDirection_upSideDown svg{ transform: scaleY(-1) }

    &[data-type='mobile']{ display:none;}
    &[data-type='desktop']{ display:flex;}

    @media (max-width: ${MEDIA.lg}) {
        &[data-type='mobile']{ display:flex;}
        &[data-type='desktop']{ display:none;}
    }
`)

export const HeaderOverlay = styled(Box)(`
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:60px;
    z-index:100;
    background:rgb(0, 0, 0, 0.5);

    @media (max-width: ${MEDIA.lg}) {
        height:211px;
    }
`)

export const LhsOverlay = styled(Box)(`
    position:absolute;
    top:60px;
    left:0;
    width:200px;
    height:calc(100% - 60px);
    z-index:100;
    background:rgb(0, 0, 0, 0.5);

    &.reportPage_hideLhs {
        width:40px;
    }
    
    @media (max-width: ${MEDIA.lg}) {
        display:none;
    }
`)

export const ReportOverlay = styled(Box)(`
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    z-index:50;
    background:rgb(0, 0, 0, 0.5);
`)

export const AskIaOverlay = styled(Box)(`
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    z-index:50;
    background:rgb(0, 0, 0, 0.5);
`)

export const ReportFooterOverlay = styled(Box)(`
    position:absolute;
    top:-1px;
    left:0;
    width:100%;
    height:calc(100% + 1px);
    z-index:50;
    background:rgb(0, 0, 0, 0.5);
`)

export const PostLoginOverlay = styled(Box)(`
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    z-index:50;
    background:rgb(0, 0, 0, 0.5);
`)

export const CloseButton = styled(MuiButton)(`
    position:fixed;
    bottom:30px;
    left:50vw;
    width:150px;
    z-index:51;
    margin-left:-75px;
`)

export const NoDataAvailable = styled(Box)(`
    position:fixed;
    top:50%;
    left:0;
    width:100%;
    text-align:center;
    color: #fff;
    font-weight: 500;
    font-size: 12px;
    line-height: 13px;
    z-index:51;
`)
