import { styled } from '@mui/material/styles'
import { Box, Typography } from '@mui/material'
import MuiButton from '../muiButton'

export const Button = styled(MuiButton)(`
  margin-top: 10px;
  margin-left: 0 !important;
  margin-right: 20px;
  text-transform: capitalize;
`)

export const DialogActions = styled(Box)(`
  display: flex;
`)

export const Title = styled(Typography)(`
  font-size: 20px;
  line-height: 30px;
  padding-bottom: 30px;
  font-style: normal;
  font-weight: 300;
  white-space: pre-wrap;
`)

export const Wrapper = styled(Box)(`
  width: 100%;
  min-width: 300px;
  max-width: 350px;
`)
