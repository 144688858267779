import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";

export const RootWrap= styled(Box)(`
    width: 100%;
    background: #F7F7F9;
    padding: 16px 20px;
    display: flex;
    flex-direction: column;
    min-height: 100px;
    border-radius: 2px;
`)

export const PrimaryText = styled(Typography)(`
    font-size: 14px;
`)

export const SecondaryText = styled(Typography)(`
    font-size: 20px;
`)