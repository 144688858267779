import { styled } from '@mui/material/styles'
import { Box, Typography } from '@mui/material'
import MuiButton from '../muiButton'

export const Wrapper = styled(Box)(`
  width:100%;
  max-width:370px;
  min-width:370px;
`)

export const LoginButton = styled(MuiButton)(`
  margin-top: 20px;
`)

export const Title = styled(Typography)(`
  margin-top: -10px;
  padding-bottom: 30px;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
`)

export const InputWrap = styled(Box)(`
  height: 70px;
`)