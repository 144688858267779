import React, {useState, useEffect} from 'react'
import { useHistory } from 'react-router-dom'

import { BlockingLoader } from '../../components'

import {ROLES_CLIENT_SECONDARY} from '../../constants'

import ReportsService from '../../services/reports.service'
import { useGlobalStore } from '../../store'

const PreLoadReportsData = ({data=null, page='', showBlockLoader=true}) => {
  const { user, setLoadAllLhs } = useGlobalStore()
  const navigate = useHistory()
  const [open, setOpen] = useState(false)
  const [driverIsLoading, setDriverIsLoading] = useState()
  const [counter, setCounter] = useState(0)
  const [tabsData, setTabsData] = useState([])
  const [autoRedirect, setAutoRedirect] = useState(true)
  
  const redirectToReportsPage = () => {
    if(autoRedirect && page==='projects') navigate.push(data?.href)
  }

  const load = async () => {
    if(data?.StudyId && ROLES_CLIENT_SECONDARY.includes(user.UserType)){
      setDriverIsLoading(true)
      try{
        const driverTypeArray = (page==='projects') ? ['Drivers', 'DriversAndSubBrands', 'DriversWithoutSentimentsAndSubBrands', 'DriversAndRatingImpacts', 'DriversAndTriggers'] : []
        const projectsMixTypeArray = (page==='projects') ? ['BusinessObjective', 'Recommendation', 'CampaignTriggers'] : []
        const segmentsTypeArray = [] // (page==='projects') ? ['DriversProgression', 'DriversProgressionWithoutSentiments', 'SearchDriversProgression', 'Triangulation', 'TriangulationComparisonViewOnly'] : []
        const reportsMixTypeArray = [] // (page==='projects') ? ['SegmentDriversAnalysis', 'SegmentDriversOnlyAnalysis', 'SegmentAndSegmentOfSegment'] : []

        const allTabs = await ReportsService.reportAllTabsApi({ studyId: data.StudyId })
        const tabDriverData = (allTabs.filter(tab => driverTypeArray.includes(tab.InsightsElementType)))
        const tabProjectsMixData = (allTabs.filter(tab => projectsMixTypeArray.includes(tab.InsightsElementType)))
        const tabSegmentsData = (allTabs.filter(tab => segmentsTypeArray.includes(tab.InsightsElementType)))
        const tabReportsMixData = (allTabs.filter(tab => reportsMixTypeArray.includes(tab.InsightsElementType)))

        // if(tabDriverData.length < 10){
          setTimeout(() => {
            setAutoRedirect(false)
            redirectToReportsPage()
          }, 10000)
        // }

        setLoadAllLhs(allTabs)
        setCounter(1)

        const redirect = (index) => {
          if(index === (tabDriverData.length + tabProjectsMixData.length + tabSegmentsData.length + tabReportsMixData.length)-1){
            setDriverIsLoading(false)
            setTimeout(() => {
              redirectToReportsPage()
            }, 1000)
          } else {
            loadTabsDataFunc(index+1)
            setCounter(prev => prev+1)
          }
        }

        const loadTabsDataFunc = async (index) => {
          const tab = [...tabProjectsMixData, ...tabDriverData, ...tabSegmentsData, ...tabReportsMixData][index]
          // console.log(`loadTabsDataFunc --- page:${page} --- index:${index} ----- TAB:${tab.InsightsElementType}`)

          await ReportsService.reportGetHeaderApi({insightsElementId:tab.InsightsElementId}, false)
          await ReportsService.reportGetInsightsApi({insightsElementId:tab.InsightsElementId}, false)

          if(driverTypeArray.includes(tab.InsightsElementType) && tab?.EntityId){
            await ReportsService.reportEntityDetailsApi({ entityId: tab.EntityId })
              .then( async (EntityDetails) => {
                // if(EntityDetails.EntityType === 'Category') {
                  await ReportsService.reportGetDriverElementsApi({ insightsElementId: tab.InsightsElementId, entityId: tab.EntityId, type: tab.InsightsElementType })
                  .then(() => { redirect(index) })
                // }
              })
          }
          else if(['BusinessObjective'].includes(tab.InsightsElementType)){
            await ReportsService.reportGetBusinessObjectiveApi({insightsElementId:tab.InsightsElementId}, false).then( () => { redirect(index) })
          }
          else if(['Recommendation'].includes(tab.InsightsElementType)){
            await ReportsService.reportRecommendationsApi({insightsElementId:tab.InsightsElementId}).then( () => { redirect(index) })
          }
          else if(['CampaignTriggers'].includes(tab.InsightsElementType)){
            await ReportsService.campaignTriggerElement({insightsElementId:tab.InsightsElementId}).then( () => { redirect(index) })
          }
          else if(['CategoryPersona'].includes(tab.InsightsElementType)){
            await ReportsService.getCategoryPersona({insightsElementId:tab.InsightsElementId}).then( () => { redirect(index) })
          }
          else if(['SegmentAndSegmentOfSegment'].includes(tab.InsightsElementType)){
            await ReportsService.getSegmentOverviewElement({insightsElementId:tab.InsightsElementId}).then( () => { redirect(index) })
          }
          else if(['SegmentDriversAnalysis', 'SegmentDriversOnlyAnalysis'].includes(tab.InsightsElementType)){
            await ReportsService.getSegmentDriversAnalysisElement({insightsElementId:tab.InsightsElementId}).then( () => { redirect(index) })
          }
          else if(segmentsTypeArray.includes(tab.InsightsElementType)){
            await ReportsService.getDriverProgressionElement({insightsElementId:tab.InsightsElementId}).then( () => { redirect(index) })
          }

        }

        if((tabDriverData.length + tabProjectsMixData.length + tabSegmentsData.length + tabReportsMixData.length) > 0) {
          setTabsData([...tabProjectsMixData, ...tabDriverData, ...tabSegmentsData, ...tabReportsMixData])
          loadTabsDataFunc(0)
        }
        else {
          redirectToReportsPage()
        }

      }
      catch(error){
        console.log('ERROR - ', error)
        setDriverIsLoading(false)
      }
    }
    else{
      redirectToReportsPage()
    }
  }

  useEffect(() => {
    load()
  }, [data])

  return (
    <>
      {showBlockLoader && driverIsLoading && <BlockingLoader msg='Your study is being loaded' value={counter} maxValue={tabsData?.length+1} />}
    </>
  )
}

export default PreLoadReportsData