import React from 'react'
import { OuterWrapper } from './style'

const ReportOuterWrapper = ({children, id}) => {
  return (
    <OuterWrapper className='reportOuterWrapper' id={id}>
      {children}
    </OuterWrapper>
  )
}

export default ReportOuterWrapper;