import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'

export const OuterWrapper = styled(Box)(`
    & p {
        padding:0;
        width: 100%;
    }
    & .typing_pending, & .typing_deleted {
        display: none;
        opacity:0;
    }
    & .typing {
        display: block;
        opacity: 1;
        animation-name: fadeInOpacity;
        animation-iteration-count: 1;
        animation-timing-function: ease-in;
        animation-duration: 0.4s;
    }
    & .typing .cursor {
        width: 3px;
        height: 14px;
        display: inline-block;
        background: #000;
        transform: translate(2px, 3px);
    }

    @keyframes fadeInOpacity {
        0% { opacity: 0; }
        50% { opacity: 0; }
        100% { opacity: 1; }
    }

    & > p::first-letter {
        text-transform: uppercase;
    }
`)
