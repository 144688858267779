import { styled } from '@mui/system'
import { Grid, Box, Typography, Avatar, ListItemText } from '@mui/material'
import { exportToPPT_tableCss } from '../../../../util'
import { MEDIA } from '../../../../constants'

export const ContentInnerWrapper = styled(Box)(`
  padding:50px 0;
  border-bottom:1px solid #E0E0E0;
  // width:calc(100vw - 350px);
  width:100%;
  display:flex;
  // overflow-y:auto;
`)

export const ContentWrapper = styled(Box)(`
  width:100%;
  display: flex;
  flex-wrap: wrap;
  & .dynamicRow {
    // border: 1px #EEE solid;
  }

  &.exportToPPT {
    min-height:500px; // IT IS REQUIRED ELSE IN EXPORT TO PPT IT WILL GIVE A BOTTOM BLACK BORDER
  }
  &.exportToPPT > div {
    padding-bottom:0;
  }
  &.exportToPPT .dynamicRow > div {
    padding-bottom:0;
  }
  ${ exportToPPT_tableCss }

  @media (max-width: 980px) {
    & > div {
      width:100%;
    }
  }
`)

export const LhsWrapper = styled(Box)(`
  width:calc(100% - 420px);
  // padding: 20px 30px 50px 30px;
  // @media (max-width: ${MEDIA.lg}) {
    // padding: 20px;
  // }
`)

export const RhsWrapper = styled(Box)(`
  padding: 20px 30px;
  min-width:420px;
  width:420px;
  display: flex;
  flex-direction:column;
`)

export const ChartRadialBarWrapper = styled(Box)(`
  width:100%;
  display: flex;
  flex-direction:column;
  align-items:center;
`)

export const ChartRadialBarHeading = styled(Box)(`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  padding-bottom:3px;
  border-bottom:2px #E7E7E7 solid;
  margin-bottom:30px;
  white-space: nowrap;
`)

export const CardsOuterWrapper = styled(Box)(`
  display:flex;
  flex-wrap:wrap;
  justify-content:space-between;
`)

export const CardsInnerWrapper = styled(Box)(`
  display:flex;
  width:100%;

  &.isLast > div {
    margin-bottom:0;
  }

  @media (max-width: 980px) {
    width:100% !important;
  }
`)

export const CardWrapper = styled(Box)(`
  width: 100%;
  background: #FFFFFF;
  box-shadow: 5px 4px 12px rgba(34, 47, 62, 0.1);
  border-top: 3px #E7E7E7 solid;
  padding:18px 20px;
  margin-bottom:20px;
  display:flex;
  position:relative;
`)

export const AvatarWrapper = styled(Avatar)(`
  width: 35px;
  height:32px;
  position:absolute;
  top:20px;
  left:20px;
  border-radius:0;
  & img {
    height:30px;
    width:auto;
  }
`)

export const ListItemTextWrapper = styled(ListItemText)(`
  margin:0;
  & span {
    padding-left:50px;
    height:35px;
    width:100%;
    line-height:35px;
    display:flex;
    align-content:center;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
  }
  & .paragraph {
    padding-top: 10px;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
  }
  & ul {
    margin-left:20px;
  }
  & li {
    // padding-top:10px;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
  }
`)
