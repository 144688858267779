import styled from "@emotion/styled";

export const TextWrap = styled("div")(`
    & ul {
        list-style: disc;
        margin-left: 20px;
        font-size: 14px;
        margin-top: 8px;
        & li{
            margin-bottom: 8px;
        }
    }
`)


