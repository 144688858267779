import styled from '@emotion/styled'
import { Box, Grid, Stack, Typography } from '@mui/material'

export const CampaignGraphsRoot = styled(Stack)(`
    width: 100%;
    margin: 20px 0;
    padding: 20px;
    background: #FFFFFF;
    box-shadow: 0px 10px 50px rgba(34, 47, 62, 0.1);
    border-top: 3px solid #E7E7E7;
    & .campaignMagnetScore {
        padding: 10px 0;
    }
`)

export const TitleForPPT = styled(Typography)(`
    font-size: 20px;
    font-weight: 300;
    line-height: 1.5;
    padding-bottom:20px;
    display:none;
`)

export const CardHeading = styled(Typography)(`
   font-size: 14px;
   font-weight: 300;
   margin-bottom: 20px;
`)

export const VerbatimWrap = styled(Box)(`
    background: #F5F5F5;
    padding: 20px;
    height: 100%;
`);

export const HeadingRow = styled(Box)(`
    display: flex;
    align-items: center;
    justify-content: space-between;
    & .headingRowTitle {
        font-size: 14px;
        font-weight: 300;
    };
    & .Information {
        font-size: 12px !important;
        font-weight: 300;
    }
`);

export const CampaignBarWrap = styled(Grid)(`
    padding-left: 50px;
    & .barGridItem {
        padding: 10px 0;
    }
`)

export const PageBreakerForPPT = styled(Box)(`
    display:none;
    height:3px; // BASED ON THIS HEIGHT THE PPT CODE WILL GIVE A PAGEBREAK
    width:100%;
    padding:0;
    margin:0;
    // background:red;
    overflow:hidden;
`)
