import { styled } from '@mui/system'
import { Box, TextareaAutosize, TextField } from '@mui/material'

export const Wrapper = styled(Box)(`
  display: flex;
  flex-direction: column;
`)

export const FormLabel = styled('label')(`
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 19px;
  color: #000;
  width:100%;
`)

export const TextFieldStyled = styled(TextField)(props=> `
  box-shadow: ${props.shadow ? '0px 1px 2px rgba(16, 24, 40, 0.12)' : ''};
  height: ${props.compact ? '42px' : 'auto'};
  & .MuiInputBase-root{
    height: ${props.compact ? '42px' : 'auto'};
    &.Mui-disabled{
      background: #F7F7F9;
    }
  }
  & input {
    padding: ${props.compact ? '9px 16px' : ''};
  }
  & div > textarea {
    font-size: 12px;
  }
  & div > textarea::placeholder {
    font-size: 12px;
  }
  & div > input {
    font-size: 12px;
  }
  & div > input::placeholder {
    font-size: 12px;
  }

  ${props.variant === 'standard' && `& .MuiInputBase-root:before{
    border-bottom:1px solid rgba(0, 0, 0, 0.2);
  }`}

`)

export const TextFieldWrapper = styled(Box)(({ theme }) => `
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  // margin-bottom: 8px;

`)

export const FormReq = styled('span')(`
  font-size: 12px;
  color: #DA2A52;
  padding-left:5px;
`)

export const IconWrap = styled(Box)(({ theme }) => `
  width:40px;
  height:40px;
  display: flex;
  justify-content: center;
  align-items: center;
  & img {
    max-width:20px;
    max-height:20px;
  }
`)

export const FormError = styled(Box)(`
  width:100%;
  display:block;
  line-height: 20px;

  font-size: 0.75rem;
  font-weight: 400;
  margin: 4px 14px 0 14px;
  color: #D14343;
  background-color: transparent !important;
`)


export const TextareaInput = styled(TextareaAutosize)(() => ({
  "label + &": {
    marginTop: 20,
  },
  border: "1px solid #E4E7EC",
  fontSize: 12,
  width: "100%",
  height: 35,
  padding: "8px 16px",
  fontFamily: "Poppins",
  boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.12)",
  width: "100%",
}));