import * as React from 'react'
import { Typography } from '@mui/material'
import { Wrapper, Title, ButtonWrapper } from './style'

const Header = (props) => {
  const {title, children} = props
  return (
    <Wrapper>
      {title && <Title>{title}</Title>}
      {children && <ButtonWrapper>{children}</ButtonWrapper>}
    </Wrapper>
  )
}
export default Header
