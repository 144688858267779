import * as React from 'react'
import { Typography } from '@mui/material'
import { Wrapper } from './style'
import { noDataFound } from '../../assets/images/icons' 
import MuiImage from '../muiImage'

const NoDataFound = ({title, img="", position="left", loader, ...rest}) => {

  return (
    <Wrapper position={position} className="noDataFound">
      { loader }
     { !loader &&
      <>
      <MuiImage src={img || noDataFound} alt={title || ''} {...rest}/> 
       {title && <Typography variant='h6'>{title}</Typography>}
      </>
     }
    </Wrapper>
  )
}
export default NoDataFound
