import styled from '@emotion/styled'
import { Box, Button, Grid, List, ListItem, Typography, IconButton } from '@mui/material'

export const Wrapper = styled(Grid)(`
    height: calc(100vh - 240px);
    display: flex;
    flex-direction: column;
`)

export const LhsWrapper = styled(Box)(`
    width: 320px;
    padding: 23px 16px 10px;
    background: #F7F7F9;
    border-radius: 10px 0px 0px 10px;
    overflow:hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
`)

export const ContentWrapper = styled(Box)(`
    width: calc(100% - 300px);
    position: relative;
    display:flex;
    flex-direction: column;
    & label{
        font-size: 16px;
    }
`)

export const OuterWrapper = styled(Box)(`
    display:flex;
    border-radius: 15px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
    height: 100%;
    height: calc(100vh - 235px);
`)

export const SearchRow = styled(Box)(`
    display:flex;
    align-items: center;
    & .TextFieldWrapper {
        margin-bottom: 0 !important;
        background: #fff;
    }
    & .userSearchButton{
        margin-left: 8px;
        padding: 9px 15px;
        min-width: 90px;
        height: 34px;
        background: #D9D9D9;
        border: 1px solid #F1F6FF;
        color: #000;
        margin-top: 0px;
    }
    & .MuiInputBase-root.MuiOutlinedInput-root{
        border-radius: 0 !important ;
    }
    &.formDisabled {
        & .userSearchButton{
            background: #F7F7F9;
            opacity: 0.8;
            img {
              opacity: 0.1 !important;
            }
        }
        & .MuiInputBase-root.MuiOutlinedInput-root{
          opacity: 0.5;
      }
    }
`)

export const UsersWrap = styled(List)(`
    margin-top: 23px;
    padding-top: 0;
    margin-left: -15px;
    margin-right: -28px;
    overflow: auto;
    height: 100%;
`)

export const UserWrap = styled(ListItem)(`
    padding-left: 0;
    border-top: 1px solid #FFFFFF;
    padding: 19px;
    cursor: pointer;
    position: relative;
    height: 80px;
    justify-content: space-between;
    &.active {
        background: #fff;
        & .userWrap {
            background: #D9D9D9;
        }
        & .name{
            font-weight: 500;
            font-size: 16px;
        }
    }
`)

export const UserIconWrap = styled(Box)(`
    background: #FFFFFF;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
    border-radius: 84px;
    width: 42px;
    height: 42px;
    min-width: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
`)

export const UserNameWrap = styled(Box)(`
    width:155px;
    line-height: 14px;
    & .name {
        font-size: 16px;
        font-weight: 300;
    }
    & .role {
        font-size: 12px;
        font-weight: 300;
    }
`)


export const MultiSelectWrap = styled(Box)(`
 
`)

export const Footer = styled(Box)(`
 display: flex;
 margin-top: auto;
 border-top: 1px solid #EEEEEE;
 padding-top: 20px;
 padding-right: 40px;
 margin-left: -40px;
 margin-right: -40px;
    & .cancelButton{
        margin-left: auto;
        margin-right: 20px;
    }
`)

export const FormOuterWrap = styled(Box)(`
    display: flex;
    flex-direction: column;
    overflow:hidden;
`)

export const FormWrap = styled(Box)(`
    display: flex;
    flex-direction: column;
    height: calc(100vh - 330px);
    overflow: auto;
    // width: 100%;
    width: calc(100% - -12px);
    padding: 20px 40px;
    & .MuiTextField-root {
        height: 34px;
        & input {
            height: 34px;
            padding: 0px 12px;
        }   
    }
    & .MuiFormHelperText-root.Mui-error, .selectError {
        margin-left: 0;
    }
    & .row1 label {
        line-height: 27px;
    }
`)

export const AddMoreButton = styled(Button)(`
    color: #000;
    font-size: 16px;
    font-weight: 400;
    margin-top: 10px;
`)

export const Divider = styled(Box)(`
    width: 100%;
    border-bottom: 2px solid #F1F1F3;
    margin: 15px 0;
`)

export const StyledGrid = styled(Grid)(`
    position: relative;
    // & p {
    //     line-height: 42px;
    // }
    & .selectError{
        position: absolute;
    }
`)

export const ReadOnlyTypography = styled(Typography)(`
    font-weight: 300;
    font-size: 14px;
    line-height:2;
`)

export const ActionRowWrapper = styled(Box)(`
    width:100%;
    display: flex;
    justify-content: space-between;
    padding:20px 30px 10px 37px;
    & p {
        margin:0;
    }
`)

export const ActionRow = styled(Box)(`
    display: flex;
    justify-content: flex-end;
    & .iconButton {
        background: #D9D9D9;
        boxShadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
        margin-left: 10px;
    }
`)

export const StyledListItem = styled(ListItem)(`
    font-size: 14px;
    border-bottom: 1px solid #F5F5F5;
    cursor: pointer;
    &.active {
        background: #F7F7F9;
    }
    &:hover {
        background: #F7F7F9;
    };
    &:last-child{
        border-bottom: 0;
    };
`)

export const NoDataFoundWrap = styled(Box)(`
    display: flex;
    aligin-item: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    & .noDataFound {
        padding: 0;
    }
`)

export const UserDeleteWrap = styled(IconButton)(`
    background: #D9D9D9;
    width:24px;
    height:24px;
`)

export const FormError = styled(Box)(`
  font-size: 0.75rem;
  font-weight: 400;
  margin: 4px 0 0 0;
  color: #D14343;
  text-align:right;
`)
