import React, { useState } from 'react';
import { DrfatButton, Title, Wrapper } from './style';
import { Modal, MuiButton, MuiTextField } from '../../../../components';

const DraftModalForm = ({ draftName = '', onConfirm, onClose }) => {
 
  const [state, setState] = useState(draftName);
  const [errors, setErrors] = useState('');

  const validate = () => {
    const errorObj = {}
    if(!state) {
        errorObj.draftName = 'Draft name is required'
    }

    setErrors(errorObj);

    return !!Object.keys(errorObj).length;
  };

  const onSubmit = () => {
    const isError = validate();
    if(isError) return;
    
    onConfirm(state);
  }

  return (
        <Modal title='' showClose={true} onClose={onClose}>
          <Wrapper>
            <Title variant='body1'>Save as Draft</Title>
            <MuiTextField
              error={Boolean(errors.draftName)}
              fullWidth
              helperText={errors.draftName}
              label='Draft Name'
              name='draftName'
              onChange={(e) => setState(e.target.value)}
              value={state}
              variant='standard'
            />
            <DrfatButton
              color='primary'
              variant='contained'
              onClick={onSubmit}
            >
              Save
            </DrfatButton>
          </Wrapper>
        </Modal>
  );
};

export default DraftModalForm;
