import styled from "@emotion/styled"

export const Tabs = styled('div')(`
	position: relative;
	width: 100%;
	display: flex;
`)

export const Tab = styled('div')(`
	cursor: pointer;
	width: 33%;
	border: 1px solid #E4E7EC;
	padding: 10px;
    font-size: 12px;
    text-align: center;
	color: #000;
	&.active {
		color: #000000;
		font-weight: 500;
		background: #E4E7EC;
	}
`)