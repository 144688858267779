import { styled } from '@mui/material/styles'
import { Box, Typography } from '@mui/material'
import { bulb } from '../../../../assets/images/icons'
import { MEDIA } from '../../../../constants'

export const OuterWrapper = styled(Box)(`
    width:100%;
`)

export const InnerWrapper = styled(Box)(`
    display:flex; flex-direction: column; width:100%; position: relative;
`)

export const ContentWrapper = styled(Box)(`
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    text-transform: capitalize;
    color: #000000;
    padding:20px 30px 50px 30px;

    & p {
        padding-bottom:10px;
    }
    & ul {
        list-style-position: outside;
        margin-left: 35px;
    }
    & ul li {
        padding-bottom:10px;
    }
    & li.ql-indent-1 {
        margin-left: 30px;
        list-style: circle;
    }
    & li.ql-indent-2 {
        margin-left: 60px;
        list-style: circle;
    }

    > ul {
        list-style: none;
        > li {
            position: relative;
            display: flex;
            flex-direction: column;
            background: #FFFFFF;
            box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.05);
            border-radius: 4px;
            padding: 20px 40px;
            font-weight: 300;
            font-size: 14px;
            line-height: calc(18 / 14);
            margin-bottom: 10px;
            &:before {
                content:url('${bulb}');
                position: absolute;
                top: 10px;
                left: 0;
                display: flex;
                border-radius: 50%;
                padding: 15px 20px;
                background: #F7F7F9;
                transform: translate(-50%, 0px);
            }

            > p {
                padding-left: 20px;
            }
            > ul {
                list-style: '-';
                > li {
                    padding-left: 20px;
                    font-weight: 300;
                    font-size: 14px;
                    line-height: calc(18 / 14);
                }
            }
        }
    }

    @media (max-width: ${MEDIA.lg}) {
        padding:20px 20px 40px 20px;
    }
`)
