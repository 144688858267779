import { Pagination } from '@mui/material'
import React from 'react'
import { PaginationWrap } from './style'

const MuiPagination = ({ totalPage, currentPage, onPageChange }) => {

  return (
    <PaginationWrap>
      <Pagination color='primary' onChange={(_e, page) => onPageChange(page)}  showFirstButton showLastButton shape="rounded" page={currentPage} siblingCount={1} count={totalPage} />
    </PaginationWrap>
  )
}

export default MuiPagination