import React from 'react'
import { Tab, Tabs } from './style'

const CustomTab = ({tabs, value, onChange, isHtml=false}) => {

  return (
   <Tabs className='custom-tabs'>
        {tabs.map((tab) => (
          <Tab
            key={tab.id}
            onClick={() => onChange(tab)}
            className={`custom-tabs-item ${tab.id === value ? 'active' : ''}`}
          >
            { isHtml ? <div dangerouslySetInnerHTML={{__html: tab.label }} /> : tab.label }
          </Tab>
        ))}
      </Tabs>
  )
}

export default CustomTab