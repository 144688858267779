import React, { useState, useRef } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import AuthService from '../../services/auth.service'
import { MuiTextField } from '../../components'
import { useGlobalStore } from '../../store'
import { InputWrap, LoginButton, Title, Wrapper } from './style'
import { useEffect } from 'react'

const ChangePassword = (props) => {
  const { onClose } = props
  const { user, setUser } = useGlobalStore();

  const form = useRef()
  const [loader, setLoader] = useState(false)
  const [showMessage, setShowMessage] = useState(false)
  // const { enqueueSnackbar } = useSnackbar()

  const onSubmit = (data) => {
    setLoader(true)
    if (user?.UsingDefaultPassword) {
      AuthService.changePasswordFirstTimeApi(data)
        .then((resp) => {
          setLoader(false)
          setShowMessage(true)
          let userDetails = user
          userDetails.UsingDefaultPassword = false
          setUser(userDetails)
          localStorage.setItem('user', JSON.stringify(userDetails))
        })
        .catch((error) => {
          setLoader(false)
          const resMessage = error
          console.log('ERROR : ', resMessage)
        // enqueueSnackbar(resMessage, { variant: 'error' })
        })
    }
    else {
      AuthService.changePasswordApi(data)
        .then((resp) => {
          setLoader(false)
          setShowMessage(true)
        })
        .catch((error) => {
          setLoader(false)
          const resMessage = error
          console.log('ERROR : ', resMessage)
        // enqueueSnackbar(resMessage, { variant: 'error' })
        })
    }
  }

  const validation = (values) => {
    const errors = {}

    if (values.newPassword !== values.confirmPassword) {
      errors.confirmPassword = 'Password mismatch. Please try again.'
    }
    return errors
  }

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      currentPassword: Yup.string().max(25).required('Current password is required'),
      newPassword: Yup.string().min(8).max(25).required('New password is required'),
      confirmPassword: Yup.string().min(8).max(25).required('Confirm password is required')
    }),
    validate: (values) => validation(values),
    onSubmit: (values) => {
      const updatedValues = {
        currentPassword: values.currentPassword,
        newPassword: values.newPassword,
        email: user?.UserEmail || ''
      }
      onSubmit(updatedValues)
    }
  })

  useEffect(() => {
    if (user?.UsingDefaultPassword) {
      formik.setFieldValue('currentPassword', 'UsingDefaultPassword')
    }
  }, [])

  return (
    <Wrapper>
      {!showMessage &&
        <form ref={form} onSubmit={formik.handleSubmit}>
          <Title variant='body1'>Change Password</Title>
          {!user?.UsingDefaultPassword &&
            <InputWrap>
              <MuiTextField
                error={Boolean(formik.touched.currentPassword && formik.errors.currentPassword)}
                fullWidth
                helperText={formik.touched.currentPassword && formik.errors.currentPassword}
                label='Current Password'
                name='currentPassword'
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type='password'
                value={formik.values.currentPassword}
                variant='standard'
              />
            </InputWrap>
          }
          <InputWrap>
            <MuiTextField
              error={Boolean(formik.touched.newPassword && formik.errors.newPassword)}
              fullWidth
              helperText={formik.touched.newPassword && formik.errors.newPassword}
              label='New Password'
              name='newPassword'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type='password'
              value={formik.values.newPassword}
              variant='standard'
            /></InputWrap>
          <InputWrap>
            <MuiTextField
              error={Boolean(formik.touched.confirmPassword && formik.errors.confirmPassword)}
              fullWidth
              helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
              label='Confirm Password'
              name='confirmPassword'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type='password'
              value={formik.values.confirmPassword}
              variant='standard'
            />
          </InputWrap>
          <LoginButton color='primary' variant='contained' type='submit' onClick={formik.handleSubmit} disabled={loader || false}>Save Password</LoginButton>
        </form>
      }

      {showMessage &&
        <>
          <Title variant='body1'>Your password has been changed successfully!</Title>
          <LoginButton color='primary' variant='contained' type='button' onClick={onClose}>OK, got it!</LoginButton>
        </>
      }
    </Wrapper>
  )
}

export default ChangePassword
