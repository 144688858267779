import { styled } from '@mui/material/styles'
import { Box, Typography } from '@mui/material'
import { exportToPPT_tableCss } from '../../../../util'

export const CardOuterWrapper = styled(Box)(`
  // padding:20px 30px;
  min-height:350px;
  width:100%;
  // padding-bottom: 50px;

  &.exportToPPT {
    // padding:20px;
  }
  ${ exportToPPT_tableCss }
`)

export const CardInnerWrapper = styled(Box)(`

`)

export const CardUl = styled(Box)(`
  display:flex;
  overflow-x:auto;
  position:relative;
  padding:0 0 0 90px;
`)

export const CardLiHeading = styled(Box)(`
  position:absolute;
  top:0;
  left:0;
  width:90px;  
  list-style:none;
  & p {
    padding-top:145px;
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    color: #095BBD;
  }
`)

export const CardLiContent = styled(Box)(`
  display:flex;
  overflow-x:auto;
  position:relative;
  width:100%;
  padding:5px;
`)

export const CardLi = styled('li')(`
  list-style:none;
  padding: 30px 0;
  width: 142px;
  min-width: 142px;
  margin-right:20px;
  text-align:center;
  background: #FFFFFF;
  box-shadow: 0px 0px 4px rgba(34, 47, 62, 0.1);
`)

export const CardGraphWrapper = styled(Box)(`
  text-align:center;
  display:flex;
  justify-content:center;
  flex-direction:column;
  align-items: center;
  & > div {
    margin: 0 auto;
  }
`)

export const CardMediaWrapper = styled(Box)(`
  // text-align:center;
  // display:flex;
  // justify-content:center;
  // flex-direction:column;
`)

export const Title = styled(Typography)(`
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #242424;
  padding-bottom:30px;
`)

export const TitleDonut = styled(Typography)(`
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #000000;
  padding-top:10px;
  padding-bottom:20px;
`)

export const TitlePie = styled(Typography)(`
  margin-top:0;
  padding:0 5px;
  height: 20px;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  text-transform: capitalize;
  color: #FFFFFF;
  background: #475467;
  border-radius: 2px;
  display:inline-block;
`)

export const GraphDescription = styled(Box)(`
`)

export const GraphLegendWrapper = styled(Box)(`
    display:flex;
    padding-top:40px;
    padding-bottom:10px;
`)

export const GraphLegend = styled(Typography)(props => `
    font-weight: 300;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: -0.01em;
    text-transform: capitalize;
    color: #545454;
    margin-right:10px;
    display: flex;
    align-items: baseline;

    &:before{
        content:'';
        background:${props.color};
        width:8px;
        height:8px;
        display:block;
        margin-right:5px;
    }
`)



