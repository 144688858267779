import { Box, IconButton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { IconRoundCollapse, IconRoundExpand } from '../../../../assets/images/icons'
// import { MuiImage } from '../../../../components'
import { ExpandCollapseWrap, MiningBoxWrap, MiningBoxDetail, MiningColumnWrap, SegmentRow, SegmentRowContent } from './style'

const MiningTable = ({isExpendedAll, data=[]}) => {
	return (
		<Box className='segmentWrap'>
		{
			data.map((item, index) => {
			if(!item?.data?.length) return null;
			return (
				<MiningRow isExpendedAll={isExpendedAll} row={item} key={index} index={index} />
			)
			})
		}
		</Box>
	)
}

export default MiningTable;


const MiningRow = ({ row, isExpendedAll, index }) => {
	const [isExpended, setExpended] = useState(false);

	useEffect(() => {
		setExpended(isExpendedAll);
	}, [isExpendedAll])
	

	const hasChild = (arr) => {
		for (let i = 0; i < arr.length; i++) {
			const element = arr[i]?.data || [];
			if(element?.length) return true;
		}
		return false;
	}

	return (
		<SegmentRow className='segmentRow' expended={isExpended ? 'true': ''} sentiment={row.Sentiment} data-mining-attribute={row.DriverName}>
			<SegmentRowContent className='segmentRowContent'>
				{
					row?.data?.map((driverAttributeStandout, index) => {
						return (
							<MiningColumn key={index} isExpended={isExpended? 'true' : ''} driverAttributeStandout={driverAttributeStandout} />
						)
					})
				}
			</SegmentRowContent>
			{
				!!row?.data?.length && hasChild(row?.data) &&
				<ExpandCollapseWrap className={`${index===0 ? 'segmentRowExpand' : null}`}>
					<IconButton onClick={() => setExpended((prev) => !prev)}>
						{isExpended ? <IconRoundExpand /> : <IconRoundCollapse />}
					</IconButton>
				</ExpandCollapseWrap>
			}
		</SegmentRow>
	)
}

const MiningColumn = ({ driverAttributeStandout, isExpended }) => {
	const boxWidth = 140 * Math.ceil(driverAttributeStandout?.data.length / 7)
	return (
		<MiningColumnWrap className={`isExpended_${isExpended}`}>
			<MiningBox sentiment={driverAttributeStandout.Sentiment} attributeName={driverAttributeStandout.AttributeName} />
			<MiningBoxDetail className={'miningDetails'} style={{display:(isExpended ? 'flex' : 'none'), minWidth: `${boxWidth}px`, maxWidth: `${boxWidth}px`}}>
				{
					driverAttributeStandout?.data?.map((childItem, index) => {
						return (
							<MiningBox key={index} sentiment={childItem.Sentiment} attributeName={childItem.SubAttributeName} isChild />
						)
					})
				}
			</MiningBoxDetail>
		</MiningColumnWrap>
	)
}

const MiningBox = ({ attributeName, sentiment, isChild }) => {
	return (
		<MiningBoxWrap data-name={attributeName} sentiment={sentiment} ischild={isChild?.toString()} className={`miningBoxWrap ${isChild ? "child-mining-box" : ''} dataOverflow`}>
			<span>{!isChild && attributeName}</span>
		</MiningBoxWrap>
	)
}