import React, { useEffect, useState } from 'react'
import { LoaderWrapper, ReportHeading } from '../../../../components'
import ReportsService from '../../../../services/reports.service'
import { useUpdateLhs } from '../../../../hooks/useUpdateLhs'
import { Box } from '@mui/material'

import { ChartBarHorizontalCustom, Legend } from '../../../../components'
import {
  RootWrap,
  BarGraphTitle,
  LegendWrapper,
  SegmentInneWrapper,
  HeadingWrapper,
  HeadingLhs,
  HeadingRhs,
  BorderShadowWrapper,
  SegmentRowConversation,
  SegmentRowHeading
} from './style'

const CampaignSegmentsEngagementProgression = (props) => {
  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(false);
  const [headings, setHeadings] = useState(false);
  const [legendsArray, setLegendsArray] = useState([]);
  const { updateSubLhsApiLoaded } = useUpdateLhs()

  // const legendsArray = [
  //   { label: 'Consumers', value: 'Consumers' }, { label: 'News / Media', value: 'NewsMedia' }, { label: 'MG + Partners', value: 'MGPartners' }
  // ]

  const getSegment = () => {
    setLoading(true)
    ReportsService.getCampaignSegmentProgressionElement({ insightsElementId: props.InsightsElementId })
      .then((resp) => {
        setState(resp);
        const headingList = resp[0].PeriodCampaignSegments?.CampaignSegmentElement.map(m => m.SegmentName)
        setHeadings(headingList)
        setLegendsArray([{ label: headingList[1], value: 'Consumers' }, { label: headingList[2], value: 'NewsMedia' }, { label: headingList[3], value: 'MGPartners' }])
        updateSubLhsApiLoaded(props)
      })
      .catch((error) => {
        const resMessage = error
        console.log('ERROR : ', resMessage)
        setLoading(false)
      }).finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    getSegment()
  }, [])


  return (
    <>
      <ReportHeading downloadId={props.type} {...props} />
      <RootWrap id={props.type}>
        <LoaderWrapper loading={loading} loadOnlyApi={props.loadOnlyApi}>
          
            <SegmentInneWrapper>
              <HeadingWrapper>
                <HeadingLhs>Conversation Split</HeadingLhs>
                <HeadingRhs>Net Sentiment (% Positive - % Negative)</HeadingRhs>
              </HeadingWrapper>

              <SegmentRowHeading>
                <Box className='lhs'></Box>
                {!!headings?.length && <Box className='rhs'>
                  {headings.map((t, i) => (<Box key={i}>{t}</Box>))}
                </Box>}
              </SegmentRowHeading>

              <BorderShadowWrapper>
                {state.map((list, i) => {
                  const graphData = list?.PeriodCampaignSegments?.CampaignSegmentElement.filter(f => f.SegmentName !== 'Overall').map(m => m.Percent)
                  return (
                    <SegmentRowConversation key={i}>
                      <Box className='lhs'>
                        <BarGraphTitle>{list.PeriodName}</BarGraphTitle>
                        <ChartBarHorizontalCustom
                          showInside={true}
                          bottomText={false}
                          data={[{ Consumers:graphData[0], NewsMedia:graphData[1], MGPartners:graphData[2] }]}
                          valueInPercent={true}
                          maxPercentRatio={100}
                          maxWidth="100%" 
                        />
                      </Box>
                      <Box className='rhs'>
                        {list?.PeriodCampaignSegments?.CampaignSegmentElement?.map((seg, i) => {
                          return (
                            <Box key={i}>{seg.Percent}%</Box>
                          )
                        })}
                      </Box>
                    </SegmentRowConversation>
                  )
                })}
              </BorderShadowWrapper>
            </SegmentInneWrapper>
            {legendsArray && <LegendWrapper><Legend legends={legendsArray} /></LegendWrapper>}
          
        </LoaderWrapper>
      </RootWrap>
    </>
  )
}

export default CampaignSegmentsEngagementProgression