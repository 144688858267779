import { styled } from '@mui/system'
import { Box } from '@mui/material'

export const InsightsWrapper = styled(Box)(`
    // padding-top:10px !important;
    width:100% !important;

    & hr {
        display:none;
    }
    & ol, & ul {
        margin-left:15px;
    }
    & li {
        position:relative;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #000;
        line-height:18px;
        padding-bottom:5px;
    }
    
    & ul li ul {
        margin-top:5px;
    }

    & table { border-color: #E0E0E0; }
    & table thead { background-color: #F7F7F9; }
    & table thead td { font-weight: 500; color:#000; }
    & table td { 
        padding:10px; 
        font-weight: 400;
        font-size: 14px;
        vertical-align: text-bottom;
    }
`)