import React from 'react';
import { horse, error } from '../../assets/audio'
import { IsLocalHost } from '../'

const AudioTag = ({play='horse'}) => {
  const music = play==='horse' ? horse : error
  return (
    <div style={{display: 'none'}}>
      <IsLocalHost>
        {/* <audio controls autoPlay>
          <source src={music} type="audio/mpeg" />
        </audio> */}
      </IsLocalHost>
    </div>
  )
}

export default AudioTag