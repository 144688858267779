
import React from 'react'

const IconThumbsUpButton = ({isClicked=false}) => {
  return (
    <>
      {isClicked 
      ? <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.5 3.5L7 0L9 0.5L8.5 3.5L7.5 6L10.5 5.5L12.5 7L12 8.5L11.5 9.5L11 13L9.5 14.5H7L3.5 14L3 13H1.5H0.5V6.5H1.5L3 6L5.5 3.5Z" fill="#353535"/>
      </svg>
      : <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.609 6.92578C12.1905 6.37884 11.5078 6.03905 10.8274 6.03905H8.7925C9.14752 5.36983 9.40666 4.74133 9.56572 4.16272C9.82484 3.22026 9.81931 2.40393 9.54927 1.73636C9.18808 0.843405 8.38942 0.262614 7.17549 0.0101278C7.04289 -0.0174334 6.90483 0.0117764 6.79271 0.0914228C6.68059 0.171069 6.60357 0.294449 6.57883 0.434044C6.05419 3.39288 5.00271 4.33394 4.07504 5.16424C3.56596 5.6199 3.04172 6.08907 2.67487 6.82266H1.18175C0.530152 6.82266 0 7.37846 0 8.06158V13.7751C0 14.4582 0.530152 15.014 1.18175 15.014H2.53356C2.93031 15.6221 3.59558 16 4.30702 16H9.66995C10.7073 16 11.5251 15.1819 11.8042 13.865L12.9617 8.40446C13.0706 7.89055 12.9454 7.36543 12.609 6.92578ZM11.9726 8.17404L10.8151 13.6346C10.7321 14.0265 10.4543 14.9378 9.66995 14.9378H4.30702C3.87269 14.9378 3.47053 14.6697 3.28241 14.2548C3.19846 14.0697 3.02039 13.9517 2.82488 13.9517H1.18175C1.09041 13.9517 1.01323 13.8708 1.01323 13.7751V8.06158C1.01323 7.96583 1.09041 7.88491 1.18175 7.88491H2.99719C3.20269 7.88491 3.38783 7.75478 3.46587 7.55549C3.73671 6.86377 4.17634 6.47029 4.73293 5.97211C5.67809 5.12618 6.84091 4.08563 7.46592 1.18495C8.05324 1.3894 8.43932 1.71319 8.61647 2.15108C8.97358 3.03392 8.56681 4.50108 7.47115 6.28231C7.37062 6.44572 7.36333 6.65364 7.45207 6.82436C7.54081 6.99506 7.71144 7.10129 7.89691 7.10129H10.8274C11.1964 7.10129 11.5948 7.29787 11.8186 7.59047C11.9629 7.77904 12.0147 7.97535 11.9726 8.17404Z" fill="#353535"/>
      </svg>}
    </>
  )
}

export default IconThumbsUpButton