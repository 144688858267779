import { ApiPost } from '../util/axios'

const backgroundApi = async (payload) => {
  // const tempPayload1 = {studyId: '66f1b755-6ae4-11ed-817c-0a1ea4c5532f'} // Loreal
  // const tempPayload1 = {studyId: '66a6085b-559b-11ed-817c-0a1ea4c5532f'} // Ford ID
  // const tempPayload1 = {studyId: 'af4fa515-5500-11ed-817c-0a1ea4c5532f'} // CELESTIQ

  const options = {
    reqId: 'GetAllInsightsElements',
    payload: payload,
    resId: 'GetAllInsightsElementsResult',
    resType: 'array'
  }

  return await ApiPost(options).then((res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const reportAllTabsApi = async (payload) => { // API NOT IN USE
  // const tempPayload1 = {studyId: '66f1b755-6ae4-11ed-817c-0a1ea4c5532f'} // Loreal

  const options = {
    reqId: 'GetAllInsightsElements',
    payload: payload, // || tempPayload1 || payload,
    resId: 'GetAllInsightsElementsResult',
    resType: 'array'
  }

  return await ApiPost(options).then((res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const reportEntityDetailsApi = async (payload) => {
  const options = {
    reqId: 'GetEntityDetails',
    payload: {entityId: payload.entityId},
    resId: 'GetEntityDetailsResult',
    resType: 'object'
  }

  return await ApiPost(options).then((res) => {
    return {
      EntityName: res.EntityName,
      EntityType: res.EntityType
    }
  },
  (error) => {
    console.log('error - ', error)
  })
}

const reportTabsApi = async (payload) => {
  // const tempPayload1 = { tabId: '65313662-3766-6533-2d35-3638332d3131' } // working
  // const payload = {
  //   studyId: '66f1b755-6ae4-11ed-817c-0a1ea4c5532f',
  //   tabId: payloads.tabId
  // }

  const options = {
    reqId: 'GetInsightsElementsForTab',
    payload: payload, // || tempPayload1 || payload,
    resId: 'GetInsightsElementsForTabResult',
    resType: 'array'
  }

  return await ApiPost(options).then((res) => {
    return res
    // return reportEntityDetailsApi(res)
  },
  (error) => {
    console.log('error - ', error)
  })
}

const reportHeadingsApi = async (payload) => {
  const options = {
    reqId: 'GetInsightsElementHeading',
    payload: payload,
    resId: 'GetInsightsElementHeadingResponse',
    resType: 'object'
  }

  return await ApiPost(options).then((res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const reportEditHeaderApi = async (payload) => {
  const options = {
    reqId: 'SetInsightsElementHeadingAndInsights',
    payload: payload,
    resId: 'SetInsightsElementHeadingAndInsightsResult',
    resType: 'object'
  }

  return await ApiPost(options).then((res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const reportSetDriverElementInsights = async (payload) => {
  const options = {
    reqId: 'SetDriverElementInsights',
    payload: payload,
    resId: 'SetDriverElementInsightsResponse',
    resType: 'object'
  }

  return await ApiPost(options).then((res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const reportSetBrandImageryMappingElementInsights = async (payload) => {
  const options = {
    reqId: 'SetBrandImageryMappingElementInsights',
    payload: payload,
    resId: 'SetBrandImageryMappingElementInsightsResponse',
    resType: 'object'
  }

  return await ApiPost(options).then((res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const reportGetHeaderApi = async (payload, getLatest = false) => {
  const options = {
    reqId: 'GetInsightsElementHeading',
    payload: payload,
    resId: 'GetInsightsElementHeadingResponse',
    resType: 'object',
    getLatest: getLatest
  }

  return await ApiPost(options).then((res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const reportGetInsightsApi = async (payload, getLatest = false) => {
  const options = {
    reqId: 'GetInsightsElementInsights',
    payload: payload,
    resId: 'GetInsightsElementInsightsResponse',
    resType: 'object',
    getLatest: getLatest
  }

  return await ApiPost(options).then((res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const reportGetDriverElementsApi = async (payloads) => {
  let payload = {insightsElementId: payloads.insightsElementId}

  let options = {
    reqId: 'GetDriverElements',
    payload: payload,
    resId: 'GetDriverElementsResult',
    resType: 'array',
    getLatest: payloads.getLatest
  }

  // if( payloads?.type?.indexOf('SearchDrivers') !== -1 ){
  if( payloads?.type?.split('__').includes('SearchDrivers') ){
    options = {
      reqId: 'GetSearchDriverElements',
      payload: payload,
      resId: 'GetSearchDriverElementsResult',
      resType: 'array',
      getLatest: payloads.getLatest
    }
  }

  return await ApiPost(options).then( async (res) => {
    const EntityDetails = await reportEntityDetailsApi({entityId:payloads.entityId})
    return {respData:res, entityDetails:EntityDetails}
  },
  (error) => {
    console.log('error - ', error)
  })
}

const reportGetBusinessObjectiveApi = async (payload, getLatest) => {
  const options = {
    reqId: 'GetBusinessObjectiveElement',
    payload: payload,
    resId: 'GetBusinessObjectiveElementResponse',
    resType: 'object',
    getLatest: getLatest
  }

  return await ApiPost(options).then((res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const reportRecommendationsApi = async (payload, getLatest) => {
  // const tempPayload1 = { insightsElementId: 'd83f0af3-568d-11ed-817c-0a1ea4c5532f' }
  const options = {
    reqId: 'GetRecommendationElement',
    payload: payload, // tempPayload1 || payload,
    resId: 'GetRecommendationElementResult',
    resType: 'array',
    getLatest: getLatest
  }

  return await ApiPost(options).then((res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const reportBuzzMetricsApi = async (payload, getLatest) => {
  // const tempPayload1 = { insightsElementId: '5d96f99d-671d-11ed-817c-0a1ea4c5532f' }
  const options = {
    reqId: 'GetBuzzMetricsElement',
    payload: payload, // tempPayload1 || payload,
    resId: 'GetBuzzMetricsElementResult',
    resType: 'array',
    getLatest: getLatest
  }

  return await ApiPost(options).then((res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const reportSetRecommendationElement = async (payload) => {
  const options = {
    reqId: 'SetRecommendationElement',
    payload: payload,
    resId: 'SetRecommendationElementResult',
    resType: 'object'
  }

  return await ApiPost(options).then((res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const reportGetKeyTakeawaysObjectiveApi = async (payload, getLatest) => {
  // const tempPayload1 = { insightsElementId: 'd83f0af3-568d-11ed-817c-0a1ea4c5532f' }
  const options = {
    reqId: 'GetRecommendationElement',
    payload: payload, // tempPayload1 || payload,
    resId: 'GetRecommendationElementResult',
    resType: 'array',
    getLatest: getLatest
  }

  return await ApiPost(options).then((res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const reportSetKeyTakeawaysObjectiveApi = async (payload) => {
  const options = {
    reqId: 'SetRecommendationElement',
    payload: payload,
    resId: 'SetRecommendationElementResult',
    resType: 'object'
  }

  return await ApiPost(options).then((res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const reportGetBrandImageryMappingApi = async (payload) => {
  // const tempPayload1 = { insightsElementId: '2f561d94-6311-11ed-817c-0a1ea4c5532f' }
  const options = {
    reqId: 'GetBrandImageryMappingElement',
    payload: payload, // || tempPayload1 || payload,
    resId: 'GetBrandImageryMappingElementResult',
    resType: 'array'
  }

  return await ApiPost(options).then((res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const reportGetBrandImageryMappingAndProgressionGraphApi = async (payload) => {
  const options = {
    // reqId: 'GetBrandImageryMappingElement',
    reqId: 'GetBrandImageryProgressionElement',
    // payload: payload, // || tempPayload1 || payload,
    payload: {insightsElementId: payload.insightsElementId},
    // resId: 'GetBrandImageryMappingElementResult',
    resId: 'GetBrandImageryProgressionElementResult',
    resType: 'array',
    getLatest: payload.getLatest
  }

  return await ApiPost(options).then((res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const reportGetMagnetScoreApi = async (payload) => {
  // const tempPayload1 = { insightsElementId: '4ae7a675-6331-11ed-817c-0a1ea4c5532f' }
  const options = {
    reqId: 'GetBrandCategoryMetricsElement',
    payload: payload, // || tempPayload1 || payload,
    resId: 'GetBrandCategoryMetricsElementResult',
    resType: 'array'
  }

  return await ApiPost(options).then((res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const reportGetMagnetScoreAndProgressionApi = async (payload) => {
  const options = {
    reqId: 'GetBrandCategoryMetricsProgressionElement',
    payload: payload, // || tempPayload1 || payload,
    resId: 'GetBrandCategoryMetricsProgressionElementResult',
    resType: 'array'
  }

  return await ApiPost(options).then((res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const reportGetBrandImageryGraphMappingApi = async (payload) => {
  // const tempPayload1 = { insightsElementId: 'afa7a377-630f-11ed-817c-0a1ea4c5532f' }
  const options = {
    reqId: 'GetBrandImageryMappingElement',
    // payload: payload, // tempPayload1 || payload,
    payload: {insightsElementId: payload.insightsElementId},
    resId: 'GetBrandImageryMappingElementResult',
    resType: 'array', 
    getLatest: payload.getLatest
  }

  return await ApiPost(options).then((res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const reportSetBusinessObjectiveApi = async (payload) => { // working
  const options = {
    reqId: 'SetBusinessObjectiveElement',
    payload: payload,
    resId: 'SetBusinessObjectiveElementResult',
    resType: 'object'
  }

  return await ApiPost(options).then((res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const reportStudySetupApi = async (payloads) => {
  const payload = { insightsElementId: payloads.insightsElementId }

  // if (payloads.type === 'BrandHealthSetup') { payload.insightsElementId = '3bc5245a-5688-11ed-817c-0a1ea4c5532f' } // RESTESTED WORKING
  // else if (payloads.type === 'CampaignAndIssuesAssessmentSetup') { payload.insightsElementId = '9906ad1b-568c-11ed-817c-0a1ea4c5532f' } // RESTESTED WORKING
  // else if (payloads.type === 'CategoryExplorationSetup') { payload.insightsElementId = '70db57f2-6620-11ed-817c-0a1ea4c5532f' } // RESTESTED WORKING
  // else if (payloads.type === 'ProductRatingOptimizationSetup') { payload.insightsElementId = 'a45c07b1-6622-11ed-817c-0a1ea4c5532f' } // RESTESTED WORKING

  const options = {
    reqId: 'GetSetupElement',
    payload: payload,
    resId: 'GetSetupElementResult',
    resType: 'object'
  }

  return await ApiPost(options).then((res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const reportAuraEntityApi = async (payloads, auraRes) => {
  const payload = { entityId: payloads.entityId }
  const options = {
    reqId: 'GetEntityDetails',
    payload: payload,
    resId: 'GetEntityDetailsResult',
    resType: 'object'
  }

  return await ApiPost(options).then((res) => {
    return { entity: res, aura: auraRes }
  },
  (error) => {
    console.log('error - ', error)
  })
}

const reportAuraApi = async (payloads) => {
  // const tempUrl1 = { insightsElementId: 'af45eddd-571a-11ed-817c-0a1ea4c5532f' }
  const payload = { insightsElementId: payloads.insightsElementId } // Working
  const options = {
    reqId: 'GetAuraElement',
    payload: payload, // || tempUrl1 || payload,
    resId: 'GetAuraElementResult',
    resType: 'array'
  }

  return await ApiPost(options).then((res) => {
    return reportAuraEntityApi(payloads, res)
  },
  (error) => {
    console.log('error - ', error)
  })
}

const reportLhsApi = async (payload, getLatest=false) => {
  const options = {
    reqId: 'GetStudyLayoutTabs',
    payload: payload,
    resId: 'GetStudyLayoutTabsResult',
    resType: 'array',
    getLatest: getLatest
  }

  return await ApiPost(options).then((res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const campaignTriggerElement = async (payload) => {
  const tempPayload1 = { insightsElementId: '5f952c6d-6093-11ed-817c-0a1ea4c5532f' } // working
  const options = {
    reqId: 'GetCampaignTriggerElement',
    payload: payload,
    resId: 'GetCampaignTriggerElementResult',
    resType: 'array'
  }

  return await ApiPost(options).then((res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const getImpactDestinationElement = async (payload) => {
  // const tempPayload1 = { insightsElementId: 'cfb0cb19-5e75-11ed-817c-0a1ea4c5532f' } // working
  const options = {
    reqId: 'GetImpactDestinationElement',
    payload: payload, // tempPayload1 || payload,
    resId: 'GetImpactDestinationElementResult',
    resType: 'array'
  }

  return await ApiPost(options).then((res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const getCompetitiveLandscapeElement = async (payload) => {
  // const tempPayload1 = { insightsElementId: '4ae7a675-6331-11ed-817c-0a1ea4c5532f' } // working
  const options = {
    reqId: 'GetCompetitiveLandscapeElement',
    payload: payload, // tempPayload1 || payload,
    resId: 'GetCompetitiveLandscapeElementResult',
    resType: 'array',
    getLatest: false
  }

  return await ApiPost(options).then((res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const getBrandCategoryMetricsElement = async (payload) => {
  // const tempPayload1 = { insightsElementId: '4ae7a675-6331-11ed-817c-0a1ea4c5532f' } // working
  const options = {
    reqId: 'GetBrandCategoryMetricsElement',
    payload: payload, // tempPayload1 || payload,
    resId: 'GetBrandCategoryMetricsElementResult',
    resType: 'array',
    getLatest: false
  }

  return await ApiPost(options).then((res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const getSandboxEntityNamesElement = async (payload) => {
  // const tempPayload1 = { insightsElementId: 'c7bea4f1-664c-11ed-817c-0a1ea4c5532f' }
  const options = {
    reqId: 'GetSandboxEntityNamesElement',
    payload: payload, // tempPayload1 || payload,
    resId: 'GetSandboxEntityNamesElementResult',
    resType: 'array',
    getFromLocal: false
  }

  return await ApiPost(options).then((res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const getSandboxAllEntityNamesElement = async (payload) => {
  // const tempPayload1 = { insightsElementId: 'c7bea4f1-664c-11ed-817c-0a1ea4c5532f' }
  const options = {
    reqId: 'GetSandboxAllEntityNamesElement',
    payload: payload, // tempPayload1 || payload,
    resId: 'GetSandboxAllEntityNamesElementResult',
    resType: 'array',
    getLatest: true
  }

  return await ApiPost(options).then((res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const getSandboxDriverNamesElement = async (payload) => {

  const options = {
    reqId: 'GetSandboxDriverNamesElement',
    payload: payload, // tempPayload1 || payload,
    resId: 'GetSandboxDriverNamesElementResult',
    resType: 'array',
    getFromLocal: false
  }

  return await ApiPost(options).then((res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const getSandboxAttributeNamesElement = async (payload) => {
  const tempPayload1 = {
    entityName: payload.entityName,
    driverName: payload.driverName,
    insightsElementId: payload.insightsElementId,
    // insightsElementId: 'c7bea4f1-664c-11ed-817c-0a1ea4c5532f'
  }

  const options = {
    reqId: 'GetSandboxAttributeNamesElement',
    payload: tempPayload1 || payload,
    resId: 'GetSandboxAttributeNamesElementResult',
    resType: 'array',
    getFromLocal: false
  }

  return await ApiPost(options).then((res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}


const getSandboxSubAttributeNamesElement = async (payload) => {
  const tempPayload1 = {
    entityName: payload.entityName,
    driverName: payload.driverName,
    attributeName: payload.attributeName,
    insightsElementId: payload.insightsElementId
  }

  const options = {
    reqId: 'GetSandboxSubAttributeNamesElement',
    payload: tempPayload1 || payload,
    resId: 'GetSandboxSubAttributeNamesElementResult',
    resType: 'array',
    getFromLocal: false
  }

  return await ApiPost(options).then((res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}


const getSandboxVerbatimElement = async (payload) => {
 
  const options = {
    reqId: 'GetSandboxVerbatimElement',
    payload: payload,
    resId: 'GetSandboxVerbatimElementResult',
    resType: 'array',
    getFromLocal: false
  }

  return await ApiPost(options).then((res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}


const getSandboxVerbatimElementWithSearch = async (payload) => {
  const payloadNew = payload
  payloadNew.searchText = encodeURIComponent(payloadNew.searchText)

  const options = {
    reqId: 'GetSandboxVerbatimElementWithSearch',
    payload: payloadNew,
    resId: 'GetSandboxVerbatimElementWithSearchResult',
    resType: 'array',
    // getLatest: true
    getFromLocal: false
  }

  return await ApiPost(options).then((res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const getCategoryPersona = async (payload) => {
  // const id = [
  //   '3f7e2b5d-b18a-11ed-817c-0a1ea4c5532f',
  //   '3783a34a-b1b5-11ed-817c-0a1ea4c5532f',
  //   '66a47969-b1b9-11ed-817c-0a1ea4c5532f'
  // ]

  const options = {
    reqId: 'GetCategoryPersona',
    // payload: payload,
    payload: {insightsElementId: payload.insightsElementId},
    resId: 'GetCategoryPersonaResult',
    resType: 'object',
    getLatest: payload.getLatest
    // getFromLocal: false
  }

  return await ApiPost(options).then((res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const GetPersonaDescription = async (payload) => {
  const options = {
    reqId: 'GetPersonaDescription',
    payload: payload,
    resId: 'GetPersonaDescriptionResult',
    resType: 'object'
  }

  return await ApiPost(options).then((res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const GetPersonaOpportunities = async (payload) => {
  const options = {
    reqId: 'GetPersonaOpportunities',
    payload: payload,
    resId: 'GetPersonaOpportunitiesResult',
    resType: 'object'
  }

  return await ApiPost(options).then((res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const GetPersonaPropertiesHeading = async (payload) => {
  const options = {
    reqId: 'GetPersonaPropertiesHeading',
    payload: payload,
    resId: 'GetPersonaPropertiesHeadingResult',
    resType: 'object'
  }

  return await ApiPost(options).then((res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const GetPersonaBehaviorsHeading = async (payload) => {
  const options = {
    reqId: 'GetPersonaBehaviorsHeading',
    payload: payload,
    resId: 'GetPersonaBehaviorsHeadingResult',
    resType: 'object'
  }

  return await ApiPost(options).then((res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const SetPersonaDescOppPropHeadBehHead = async (payload) => {
  const options = {
    reqId: 'SetPersonaDescOppPropHeadBehHead',
    payload: payload,
    resId: 'SetPersonaDescOppPropHeadBehHeadResponse',
    resType: 'object'
  }

  return await ApiPost(options).then((res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const setSandboxVerbatim = async (payload) => {
  const options = {
    reqId: 'SetSandboxVerbatim',
    payload: payload,
    resId: 'SetSandboxVerbatimResponse',
    resType: 'object',
  }

  return await ApiPost(options).then((res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const getSegmentDriversAnalysisElement = async (payload) => {
  const options = {
    reqId: 'GetSegmentDriversAnalysisElement',
    payload: payload,
    resId: 'GetSegmentDriversAnalysisElementResult',
    resType: 'array'
  }

  return await ApiPost(options).then((res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const GetSegmentDriversAnalysisWithDeepDiveElement = async (payload) => {
  const options = {
    reqId: 'GetSegmentDriversAnalysisWithDeepDiveElement',
    payload: payload,
    resId: 'GetSegmentDriversAnalysisWithDeepDiveElementResult',
    resType: 'array'
  }

  return await ApiPost(options).then((res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}


const getSegmentOverviewElement = async (payload) => {
  const options = {
    reqId: 'GetSegmentOverviewElement',
    payload: payload,
    resId: 'GetSegmentOverviewElementResult',
    resType: 'array'
  }

  return await ApiPost(options).then((res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const getRelativeBrandsAnalysis = async (payload ) => {
  const options = {
    reqId: 'GetRelativeBrandsElement',
    payload: payload,
    resId: 'GetRelativeBrandsElementResult',
    resType: 'array'
  }

  return await ApiPost(options).then((res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const getSegmentOfSegmentOverviewElement = async (payload) => {
  const options = {
    reqId: 'GetSegmentOfSegmentOverviewElement',
    payload: payload,
    resId: 'GetSegmentOfSegmentOverviewElementResult',
    resType: 'array'
  }

  return await ApiPost(options).then((res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const getSegmentOfSegmentProgressionElement = async (payload) => {
  const options = {
    reqId: 'GetSegmentOfSegmentProgressionElement',
    payload: payload,
    resId: 'GetSegmentOfSegmentProgressionElementResult',
    resType: 'array'
  }

  return await ApiPost(options).then((res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const getInsightsMiningFramework = async (payload) => {
  const options = {
    reqId: 'GetInsightsMiningFramework',
    payload: payload,
    resId: 'GetInsightsMiningFrameworkResult',
    resType: 'array'
  }

  return await ApiPost(options).then((res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const getDriverProgressionElement = async (payload) => {
  const options = {
    reqId: 'GetDriverProgressionElement',
    payload: payload,
    resId: 'GetDriverProgressionElementResult',
    resType: 'array'
  }

  return await ApiPost(options).then((res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const GetTriangulationElement = async (payload) => {
  const options = {
    reqId: 'GetTriangulationElement',
    payload: payload,
    resId: 'GetTriangulationElementResult',
    resType: 'array'
  }

  return await ApiPost(options).then((res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const getNPSElement = async (payload) => {
  const options = {
    reqId: 'GetNPSElement',
    payload: payload,
    resId: 'GetNPSElementResult',
    resType: 'array'
  }

  return await ApiPost(options).then((res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const getVerbatimForManagement = async (payload) => {
  const options = {
    reqId: 'GetVerbatimForManagement',
    payload: payload,
    resId: 'GetVerbatimForManagementResult',
    resType: 'array',
    // getLatest: true,
    getFromLocal: false
  }

  return await ApiPost(options).then((res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const setVerbatimInManagement = async (payload) => {
  const options = {
    reqId: 'SetVerbatimInManagement',
    payload: payload,
    resId: 'SetVerbatimInManagementResponse',
    resType: 'object',
  }

  return await ApiPost(options).then((res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
    throw new Error(error);
  })
}

const setVerbatimInInsightsAssistant = async (payload) => {
  const options = {
    reqId: 'SetVerbatimInInsightsAssistant',
    payload: payload,
    resId: 'SetVerbatimInInsightsAssistantResponse',
    resType: 'object',
  }

  return await ApiPost(options).then((res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const getTriggerNamesElement = async (payload) => {
  const options = {
    reqId: 'GetTriggerNamesElement',
    payload: payload,
    resId: 'GetTriggerNamesElementResult',
    resType: 'array',
    getLatest: true
  }

  return await ApiPost(options).then((res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const getVerbatimByTextSearch = async (payload) => {
  const options = {
    reqId: 'GetVerbatimByTextSearch',
    payload: payload,
    resId: 'GetVerbatimByTextSearchResult',
    resType: 'array',
    getLatest: true,
    getFromLocal: false
  }

  return await ApiPost(options).then((res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const getCampaignSegmentElement = async (payload) => {
  const options = {
    reqId: 'GetCampaignSegmentElement',
    payload: payload,
    resId: 'GetCampaignSegmentElementResult',
    resType: 'array',
    getLatest: true
  }

  return await ApiPost(options).then((res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const getCampaignSegmentProgressionElement = async (payload) => {
  const options = {
    reqId: 'GetCampaignSegmentProgressionElement',
    payload: payload,
    resId: 'GetCampaignSegmentProgressionElementResult',
    resType: 'array',
    getLatest: true
  }

  return await ApiPost(options).then((res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const getFactorInterLinkageElement = async (payload) => {
  const options = {
    reqId: 'GetFactorInterlinkageElement',
    payload: {insightsElementId: payload.insightsElementId},
    resId: 'GetFactorInterlinkageElementResult',
    resType: 'array',
    getLatest: payload.getLatest
  }

  return await ApiPost(options).then((res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}
//////////////////////
const reportGetBrandAttributeMapElementApi = async (payload) => {
  const options = {
    reqId: 'GetBrandAttributeMapElement',
    payload: {insightsElementId: payload.insightsElementId},
    resId: 'GetBrandAttributeMapElementResult',
    resType: 'array',
    getLatest: payload.getLatest
  }

  return await ApiPost(options).then((res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}
const reportGetBrandAttributeMapProgressionElementApi = async (payload) => {
  const options = {
    reqId: 'GetBrandAttributeMapProgressionElement',
    payload: {insightsElementId: payload.insightsElementId},
    resId: 'GetBrandAttributeMapProgressionElementResult',
    resType: 'array',
    getLatest: payload.getLatest
  }

  return await ApiPost(options).then((res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}
///////////////////////

const setFactorLinkageInsights = async (payload) => {
  const options = {
    reqId: 'SetFactorLinkageInsights',
    payload: payload,
    resId: 'SetFactorLinkageInsightsResponse',
    resType: 'array'
  }

  return await ApiPost(options).then((res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const setBrandAttributeMapInsightsApi = async (payload) => {
  const options = {
    reqId: 'SetBrandAttributeMapInsights',
    payload: payload,
    resId: 'SetBrandAttributeMapInsightsResponse',
    resType: 'array'
  }

  return await ApiPost(options).then((res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const reportGetTriangulationGraphConfigurationApi = async (payload) => {
  const options = {
    reqId: 'GetTriangulationGraphConfiguration',
    payload: payload,
    resId: 'GetTriangulationGraphConfigurationResponse',
    resType: 'array'
  }

  return await ApiPost(options).then((res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}


export default {
  backgroundApi,
  reportTabsApi,
  reportAllTabsApi,
  reportLhsApi,
  reportHeadingsApi,
  reportEditHeaderApi,
  reportSetDriverElementInsights,
  reportSetBrandImageryMappingElementInsights,
  reportGetHeaderApi,
  reportGetInsightsApi,
  reportGetDriverElementsApi,
  reportGetBusinessObjectiveApi,
  reportSetBusinessObjectiveApi,
  reportSetRecommendationElement,
  reportGetKeyTakeawaysObjectiveApi,
  reportSetKeyTakeawaysObjectiveApi,
  reportBuzzMetricsApi,
  reportStudySetupApi,
  reportAuraApi,
  reportGetMagnetScoreApi,
  reportGetMagnetScoreAndProgressionApi,
  // sandbox
  campaignTriggerElement,
  reportGetBrandImageryMappingApi,
  reportGetBrandImageryMappingAndProgressionGraphApi,
  reportRecommendationsApi,
  reportGetBrandImageryGraphMappingApi,
  getImpactDestinationElement,
  reportEntityDetailsApi,
  getCompetitiveLandscapeElement,
  getBrandCategoryMetricsElement,
  getSandboxEntityNamesElement,
  getSandboxAllEntityNamesElement,
  getSandboxDriverNamesElement,
  getSandboxAttributeNamesElement,
  getSandboxSubAttributeNamesElement,
  getSandboxVerbatimElement,
  setSandboxVerbatim,
  getSegmentDriversAnalysisElement,
  GetSegmentDriversAnalysisWithDeepDiveElement,
  getSegmentOverviewElement,
  getRelativeBrandsAnalysis,
  getSegmentOfSegmentOverviewElement,
  getInsightsMiningFramework,
  getDriverProgressionElement,
  GetTriangulationElement,
  getNPSElement,
  getVerbatimForManagement,
  setVerbatimInManagement,
  getTriggerNamesElement,
  setVerbatimInInsightsAssistant,
  getVerbatimByTextSearch,
  getSandboxVerbatimElementWithSearch,
  
  getCategoryPersona,
  GetPersonaDescription,
  GetPersonaOpportunities,
  GetPersonaPropertiesHeading,
  GetPersonaBehaviorsHeading,
  SetPersonaDescOppPropHeadBehHead,
  getCampaignSegmentElement,
  getCampaignSegmentProgressionElement,
  getFactorInterLinkageElement,
  setFactorLinkageInsights,

  reportGetBrandAttributeMapProgressionElementApi,
  reportGetBrandAttributeMapElementApi,

  getSegmentOfSegmentProgressionElement,
  setBrandAttributeMapInsightsApi,
  reportGetTriangulationGraphConfigurationApi
}
