import React from 'react'
import { EmojiRow, Legend } from '../../../../../components'
import { LegendRowWrap } from './style'

const LegendRow = () => {

  const legends = [
    { label: 'Positive', value: 'Positive' },
    { label: 'Neutral', value: 'Neutral' },
    { label: 'Mixed', value: 'Mixed' },
    { label: 'Negative', value: 'Negative' },
    { label: 'Interactions', value: 'Interactions' },
    { label: 'Paid Interactions', value: 'PaidInteractions' },
    { label: 'Association', value: 'Association' },
    { label: 'Mention', value: 'Mention' }
  ]

  return (
    <LegendRowWrap>
         <EmojiRow type="multi"/>
         <Legend legends={legends} />
    </LegendRowWrap>
  )
}

export default LegendRow