import React, { useState, useEffect } from 'react'
import { Table, TableCell, TableRow, Typography } from '@mui/material'
import { ChartBarHorizontalCustom } from '../../../../components'
import { TableContainerWrapper, TableHeadWrapper, TableBodyWrapper, TableRowWrapper, TableCellHead, ContWrap, ContOuterWrap } from './style'

const NanoDataGrid = (props) => {
  const { rows, theme='theme1', setting={} } = props
  const [showBtnTrExpandIndex, setShowBtnTrExpandIndex] = useState(0)
  const [showBtnTrQuotesIndex, setShowBtnTrQuotesIndex] = useState(0)

  useEffect(() => {
    if( rows.length > 0 ) showBtnTrExpandQuotes()
  }, [rows])

  if (rows.length === 0 ) { return false }
  const maxPercentRatio = rows?.[0].Percent

  const showBtnTrExpandQuotes = () => {
    for(let e=1; e<rows.length; e++){
      const row = rows[e]
      if( !!row?.DriverAttributes?.length && showBtnTrExpandIndex===0){
        if( (setting?.DriverRatingImpactsValue && row?.IsHighlighted) || !setting?.DriverRatingImpactsValue ){
          setShowBtnTrExpandIndex(e)
          break
        }
      }
    }
    for(let e=1; e<rows.length; e++){
      const row = rows[e]
      if((!!row?.Verbatim.length || !!row?.SearchKeywords.length) && showBtnTrQuotesIndex===0){
        setShowBtnTrQuotesIndex(e)
        break
      }
    }
  }

  const isHeadingLevel1 = theme.split(' ').includes('theme1') ? 'headingLevel1' : 'headingLevel2Plus'
  const addDriverTrClass = isHeadingLevel1 === 'headingLevel1' ? 'DriversTr headerPPT' : ''
  
  return (
    <TableContainerWrapper className='tableContainerWrapper'>
      <Table className={theme}>
        <TableHeadWrapper>
          <TableRow className={`${addDriverTrClass} tRow ${isHeadingLevel1} pptSearchKeywords_${setting?.SearchKeywords}`}>
            <TableCellHead style={{width:'205px'}} className='headingTitle'>{setting?.DriverRatingImpactsValue && 'Drivers'}</TableCellHead>
            {/* <TableCellHead style={{width:'300px'}} className='headingSentiment'>{!(setting?.SearchKeywords || setting?.WithoutSentiments) && 'Sentiment'}</TableCellHead> */}
            <TableCellHead style={{width:'300px'}} className='headingSentiment'>Sentiment</TableCellHead>
            {setting?.DriverRatingImpactsValue && <TableCellHead style={{minWidth:'120px'}} className='headingImpactStarRating'>Impact on Star Rating</TableCellHead>}
            <TableCellHead style={{minWidth:'300px'}} className='headingAttributes'>{setting?.DriverAttributeStandouts && 'Attributes'}</TableCellHead>
            {setting?.WithoutSentiments && <TableCellHead style={{minWidth:'10px'}} className='headingWithoutSentiments'></TableCellHead>}
            {setting?.DriverSubBrandStandouts && <TableCellHead style={{minWidth:'200px'}} className='headingSubBrands'>Sub-brands</TableCellHead>}
            {setting?.DriverTriggerStandouts && <TableCellHead style={{width:'520px'}} className='headingTriggers'>Triggers</TableCellHead>}
            <TableCellHead width='50px' className='headingActions' />
          </TableRow>
        </TableHeadWrapper>
        <TableBodyWrapper>
          {rows.length !== 0 && rows.map((row,i) => {
            return (
              <Rows key={i} index={i} row={row} theme={theme} maxPercentRatio={maxPercentRatio} showBtnTrExpandQuoteIndex={[showBtnTrExpandIndex, showBtnTrQuotesIndex]} {...props} />
            )
          })}
        </TableBodyWrapper>
      </Table>
    </TableContainerWrapper>
  )
}


const Rows = ({index, row, action, showDetails=false, showDetailsContent, maxPercentRatio=100, setting, showBtnTrExpandQuoteIndex, theme, isAppendices }) => {
  const showBtnTrExpandIndex = (index===showBtnTrExpandQuoteIndex[0]) && 'showBtnTrExpandIndex'
  const showBtnTrQuotesIndex = (index===showBtnTrExpandQuoteIndex[1]) && 'showBtnTrQuotesIndex'
  const level1_array = row.levelIndexing?.split('_')
  const isLevel1 = (level1_array?.length === 2) ? 'rowLevel1' : ''
  const addPptSubHeading = (isAppendices && level1_array?.length === 2) ? 'pptSubHeading' : ''
  if(row?.Percent === 0){return false;}

  return (
    <>
      <TableRowWrapper className={`DriversTr ${isLevel1} tRow ${row.selected?'selected':'notSelected'} ${showBtnTrExpandIndex} ${showBtnTrQuotesIndex}`}>
        <TableCell style={{width:'205px'}} className='headingTitle' align='right'>
          {row?.IsHighlighted && <span className='IsHighlighted'></span>}{row?.DriverName} {setting?.DriverRatingImpactsValue && `(${row.Percent}%)`}
          <Typography className={`hideP ${addPptSubHeading}`}>{row?.DriverName} {setting?.DriverRatingImpactsValue && `(${row.Percent}%)`}</Typography>
        </TableCell>
        <TableCell style={{width:'300px'}} className='headingSentiment'><BarChartWrapper row={row} maxPercentRatio={maxPercentRatio} fullWidth={setting?.DriverRatingImpactsValue} setting={setting} /></TableCell>

        {setting?.DriverRatingImpactsValue && <TableCell className='headingImpactStarRating'>{row?.DriverRatingImpactsValue}</TableCell>}

        <TableCell className='headingAttributes'>
          {setting?.DriverAttributeStandouts && row?.DriverAttributeStandouts.map((d,i) => (
            <span key={i} className={!(setting?.DriverRatingImpactsValue || setting?.SearchKeywords || setting?.WithoutSentiments) && d.Sentiment || ""}>{d.AttributeName}{i<row.DriverAttributeStandouts.length-1 && ','} </span>
          ))}
        </TableCell>

        {setting?.WithoutSentiments && <TableCell></TableCell>}

        {setting?.DriverSubBrandStandouts && <TableCell className='headingSubBrands'>{row?.DriverSubBrandStandouts.map((d,i) => (<span key={i} className={d.Sentiment || "" }>{d.SubBrandName}{i<row.DriverSubBrandStandouts.length-1 && ','} </span>))}</TableCell>}
        {setting?.DriverTriggerStandouts && <TableCell className='headingTriggers' style={{width:'520px'}}>{row?.DriverTriggerStandouts.map((d,i) => (<span key={i} className={d.Sentiment || ""}>{d.TriggerName}{i<row.DriverTriggerStandouts.length-1 && ','} </span>))}</TableCell>}
        {/* <TableCell>{React.cloneElement(action, {row})}</TableCell> */}
        <TableCell className='headingActions'>{React.cloneElement(action, {row})}</TableCell>
      </TableRowWrapper>

      {showDetails && row.selected && <TableRowWrapper className='DriversTr___ details'>
        <TableCell colSpan={8}>{React.cloneElement(showDetailsContent, {content:row})}</TableCell>
      </TableRowWrapper>}
    </>
  )
}


const BarChartWrapper = (props) => {
  const {row, maxPercentRatio, fullWidth, setting} = props
  let maxPercentRatioNew = maxPercentRatio
  if(fullWidth){
    maxPercentRatioNew = row.Percent
  }
  
  const isSpecial = !!setting?.DriverRatingImpactsValue;
  const positive = isSpecial ? Math.round((row.PositivePercent / row.Percent) * 100) : Number(row.PositivePercent);
  const neutral = isSpecial ? Math.round((row.NeutralPercent / row.Percent) * 100) : Number(row.NeutralPercent);
  const mixed = isSpecial ? Math.round((row.MixedPercent / row.Percent) * 100) : Number(row.MixedPercent);
  const negative = isSpecial ? Math.round((row.NegativePercent / row.Percent) * 100) : Number(row.NegativePercent);

  return (
    <ContOuterWrap>
      {/* <ContWrap className='driverName'>{row?.DriverName}</ContWrap> */}
      {/* {setting?.SearchKeywords && <ChartBarHorizontalCustom data={[{ SearchKeywords: row.Percent }]} showInside={false} total={!fullWidth && Number(row.Percent)} maxPercentRatio={maxPercentRatioNew} valueInPercent={true} />}
      {!setting?.SearchKeywords && <ChartBarHorizontalCustom data={[{ Positive: positive, Neutral:neutral, Mixed:mixed, Negative: negative }]} total={!fullWidth && Number(row.Percent)} maxPercentRatio={maxPercentRatioNew} valueInPercent={true} />} */}
      {setting?.WithoutSentiments && <ChartBarHorizontalCustom data={[{ WithoutSentiments: row.Percent }]} showInside={false} total={!fullWidth && Number(row.Percent)} maxPercentRatio={maxPercentRatioNew} valueInPercent={true} />}
      {setting?.SearchKeywords && <ChartBarHorizontalCustom data={[{ SearchKeywords: row.Percent }]} showInside={false} total={!fullWidth && Number(row.Percent)} maxPercentRatio={maxPercentRatioNew} valueInPercent={true} />}
      {!(setting?.SearchKeywords || setting?.WithoutSentiments) && <ChartBarHorizontalCustom data={[{ Positive: positive, Neutral:neutral, Mixed:mixed, Negative: negative }]} total={!fullWidth && Number(row.Percent)} maxPercentRatio={maxPercentRatioNew} valueInPercent={true} />}
    </ContOuterWrap>
  )
}

export default NanoDataGrid
