import { Box, Typography } from '@mui/material'
import React from 'react'
import { LEGEND_COLOR_MAP } from '../../constants'
import { standoutColor, Standout } from "../../assets/images/icons";
import { LegendItem, LegendsWrap } from './style'

const DEFAULT = [
  { label: 'Positive', value: 'Positive' },
  { label: 'Neutral', value: 'Neutral' },
  { label: 'Mixed', value: 'Mixed' },
  { label: 'Negative', value: 'Negative' },
  { label: 'Interactions', value: 'Interactions' },
  { label: 'Paid Interactions', value: 'PaidInteractions' },
  { label: 'Association', value: 'Association' },
  { label: 'Mention', value: 'Mention' },
  { label: 'Net Vibe', value: 'NetVibe' },
]

const Legend = ({ legends = DEFAULT, colorPallet=[] }) => {
  return (
    <LegendsWrap className='legendRoot'>
      {legends.map((legendKey, i) => {
        if(typeof legendKey === 'number') { return false }
        const bgColor = colorPallet.length > 0 ? colorPallet[i] : LEGEND_COLOR_MAP[legendKey.value]
        return (
          <LegendItem key={legendKey.value} className='legendItem'>
            {legendKey.value === 'StandoutInsight' && <Box sx={{ paddingRight: '3px', display:'flex', width:'12px' }}><Standout /></Box>}
            {legendKey.value === 'Standout' && <img src={standoutColor} alt='' width='20px' style={{paddingRight: '3px', borderLeft: '1px #333 solid', paddingLeft: '8px'}} />}
            {!['StandoutInsight', 'Standout'].includes(legendKey.value) && <Box sx={{ background: bgColor }} className='legendBox'></Box>}
            <Typography className='legendText'>{legendKey.label}</Typography>
          </LegendItem>
        )
      })}
    </LegendsWrap>
  )
}

export default Legend
