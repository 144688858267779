import React, { useState, useEffect } from 'react'
import { MuiButton } from '../../../../components'
import { TitleWrap, ContentWrapper, SectionInnerWrapper } from './style'
import PostLoginReportsEditModal from '../../../../layout/postLoginReportsEditModal'
import ProSimulatorDriver from '../proSimulatorDriver'
import Draft from './draft'

const ProSimulator = (props) => {
  const {onLoadShow = false} = props
  const [showHistoryModal, setShowHistoryModal] = useState(false)
  const [simulationEvents, setSimulationEvents] = useState('')
  // const [resetSimulation, setResetSimulation] = useState(false)
  // const [saveSimulation, setSaveSimulation] = useState(false)
  // const [updateProductRating, setUpdateProductRating] = useState(false)

  const HeadingButton = () => {
    return (
      <>
        <MuiButton variant='text' color='primary' onClick={() => setShowHistoryModal(true)}>Saved Simulations</MuiButton>
        <MuiButton variant='contained' color='light' onClick={() => setSimulationEvents('Reset')}>Reset</MuiButton>
        <MuiButton variant='contained' color='light' onClick={() => setSimulationEvents('Save')}>Save</MuiButton>
        <MuiButton variant='contained' color='primary' onClick={() => setSimulationEvents('Update')}>Update Product Rating</MuiButton>
      </>
    )
  }

  const HeadingHistoryButton = () => {
    return (
      <>
        <MuiButton variant='contained' color='primary'>Run New Simulation</MuiButton>
      </>
    )
  }

  const modalOpenedFunc = () => {
    console.log('modalOpenedFunc')
  }

  // const updateProductRatingClicked = () => {
  //   // let settingData = {...settings}
  //   // settingData.UpdateProductRating = true
  //   // setSettings(settingData)
  // }

  // useEffect(() => {
  //   console.log('useEffect(() => simulationEvents - ', simulationEvents)
  // }, [simulationEvents])

  return (
    <>
      {!showHistoryModal && <PostLoginReportsEditModal modalTitle='Simulator' onLoadShow={onLoadShow} buttonName='Run Simulation' additionalButtons={<HeadingButton />} modalOpened={modalOpenedFunc}>
        <ContentWrapper>
            <TitleWrap>Product Rating Optimizer </TitleWrap>
            <SectionInnerWrapper>
              <ProSimulatorDriver {...props} simulationEvents={simulationEvents} setSimulationEvents={(str)=>setSimulationEvents(str)} />
            </SectionInnerWrapper>
        </ContentWrapper>
      </PostLoginReportsEditModal>}

      {showHistoryModal && <PostLoginReportsEditModal modalTitle='Simulator' showButton={false} onLoadShow={true} additionalButtons={<HeadingHistoryButton />} modalOpened={modalOpenedFunc} modalClosed={() => setShowHistoryModal(false)}>
        <Draft />
      </PostLoginReportsEditModal>}

    </>
  )
}

export default ProSimulator
