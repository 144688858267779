import styled from '@emotion/styled'
import { Box, List, ListItem } from '@mui/material'

export const Wrapper = styled(List)( props => `
    width: 100%;
    display:flex;
    flex-wrap:wrap;
    list-style-type: disc;
    list-style-position: outside;
`)

export const SearchList = styled(ListItem)( props => `
    display:list-item;
    width: calc(50% - 20px);
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: #000;

    padding: 10px 20px 10px 0;
    margin-left: 20px;
`)



