import moment from 'moment';
import cookie from 'js-cookie';

export const getCompanyDetails = JSON.parse(window.localStorage.getItem('company'))
export const getUserDetails = JSON.parse(window.localStorage.getItem('user'))

export const setUserDetails = (param) => JSON.parse(window.localStorage.setItem('user', param))
export const removeUserDetails = () => {
    window.localStorage.removeItem('user')
    window.localStorage.removeItem('logs')
}

export const getImageUrl = (type = '', fileName, extension = 'png') => {
    const folderPath = (type === '') ? '' : `/${type}`
    return `${process.env.REACT_APP_IMAGES_URL}${folderPath}/${fileName}.${extension}`
}

export const getLogData = (ls = 'logs', reqIdString = '', payloadObject = {}, responseObject = {}) => {
    // BELOW ARE THE USE CASES OF THIS FUNCTION
    // const getDataFromLS = getLogData('GetProjects', {companyId: 'e0804536-45e9-11ed-bca4-0a1ea4c5532f', userId: 'f9846ea8-4625-11ed-bca4-0a1ea4c5532f'})
    // const getDataFromLS = getLogData('GetStudies', {projectId: '9e19abd1-559a-11ed-817c-0a1ea4c5532f'})
    // const projectIdValue = getLogData('GetStudies', {}, {StudyId: '11808e09-559b-11ed-817c-0a1ea4c5532f'})[0].ProjectId
    // const headerData = getLogData('GetProjects', {}, {ProjectId: '9e19abd1-559a-11ed-817c-0a1ea4c5532f'})[0]

    if (!window.localStorage.getItem(ls)) { return [] }

    let lsData = JSON.parse(window.localStorage.getItem(ls))
    const payloadObjectLength = Object.keys(payloadObject).length
    const responseObjectLength = Object.keys(responseObject).length

    if (reqIdString !== '') {
        lsData = lsData.filter(d => d.reqId === reqIdString)
    }

    if (payloadObjectLength) {
        // WIP
        let objectArray = Object.keys(payloadObject)
        lsData = lsData.filter(d => d.payload[objectArray[0]] === payloadObject[objectArray[0]])
        lsData = [lsData[lsData.length-1]]
        if (objectArray[1]) { lsData = lsData.filter(d => d.payload[objectArray[1]] === payloadObject[objectArray[1]]) }
        if (objectArray[2]) { lsData = lsData.filter(d => d.payload[objectArray[2]] === payloadObject[objectArray[2]]) }
        if (objectArray[3]) { lsData = lsData.filter(d => d.payload[objectArray[3]] === payloadObject[objectArray[3]]) }
    }

    if (responseObjectLength) {
        // WIP
        let objectArray = Object.keys(responseObject)
        lsData = lsData.map(r => r.response).flat(1).filter(s => s[objectArray[0]] === responseObject[objectArray[0]])
        lsData = [lsData[lsData.length-1]]
        if (objectArray[1]) { lsData = lsData.map(r => r.response).flat(1).filter(s => s[objectArray[1]] === responseObject[objectArray[1]]) }
        if (objectArray[2]) { lsData = lsData.map(r => r.response).flat(1).filter(s => s[objectArray[2]] === responseObject[objectArray[2]]) }
        if (objectArray[3]) { lsData = lsData.map(r => r.response).flat(1).filter(s => s[objectArray[3]] === responseObject[objectArray[3]]) }
        return lsData
    }
    return lsData
}

export const setCurrency = (val) => {
    return val.toLocaleString('en-US', { currency: 'USD' })
}


export const timeStampToDate = (str) => {
    return moment(str).format('MMMM DD, YYYY')
}

export const dateDifference = (str) => {
    return moment([]).diff(moment(str), 'days')
}

// use in initiate project
export const isDirty = (values) => {
    return !!(values.BrandName ||
        values.CategoryName ||
        values.ProjectFrequency ||
        values.ProjectStartDate ||
        values.ProjectEndDate ||
        values.KeyQuestions ||
        values.TargetSegment ||
        values.BusinessObjective ||
        values.KeyQuestions ||
        values.CompetitorsOption !== "NA")
}

export const getPxToInch = (px=0) => {
    return px * 0.0104166667
}

export const removeRememberMe = () => {
    cookie.remove("userEmail")
    cookie.remove("userPassword")
}

export const rememberMe = (data) => {
    cookie.set("userEmail", data.email)
    cookie.set("userPassword", data.password)
}

export const getRememberUser = () => {
    const userName =  cookie.get("userEmail")
    const password = cookie.get("userPassword") 
    return ({
        userName,
        password
    })
}

export const getObjectAsArray = (data=[]) => {
    return Array.isArray(data) ? data : !!Object.keys(data || {})?.length ? [data] : []
}

export const clearLocalStorageOldData = (maxLen=100) => {
    window.localStorage.setItem('GetSmallApiLogs', JSON.stringify([]))
    let _lsTotal = 0,
    _xLen, _x
    for (_x in localStorage) {
        if (!localStorage.hasOwnProperty(_x)) {
            continue
        }
        _xLen = ((localStorage[_x].length + _x.length) * 2)
        _lsTotal += _xLen
    }
    const kb = (_lsTotal / 1024).toFixed(2)
    const mb = (kb / 1024).toFixed(2)
    if(mb > 0.7){
        const localStorageLogs = JSON.parse(window.localStorage.getItem('logs'))
        if(localStorageLogs.length > 10){
            let finalLocalStorageLogs = []
            for(let i=0; i<localStorageLogs.length; i++){
                const drivers = ['Drivers', 'SearchDrivers', 'DriversAndSubBrands', 'DriversWithoutSentimentsAndSubBrands', 'DriversAndRatingImpacts'].includes(localStorageLogs[i].reqId)
                if( !(i > 4 && i < maxLen && !drivers) ){
                    finalLocalStorageLogs.push(localStorageLogs[i])
                }
            }
            window.localStorage.setItem('logs', JSON.stringify(finalLocalStorageLogs))
            clearLocalStorageOldData(200)
        }
    }
}

export const checkLocalStorageSize = (maxLen=100) => {
    let _lsTotal = 0,
    _xLen, _x
    for (_x in localStorage) {
        if (!localStorage.hasOwnProperty(_x)) {
            continue
        }
        _xLen = ((localStorage[_x].length + _x.length) * 2)
        _lsTotal += _xLen
    }
    const kb = (_lsTotal / 1024).toFixed(2)
    const mb = (kb / 1024).toFixed(2)
    if(mb > 9){
        console.log('OMG -- cached cleared')
        const localStorageLogs = JSON.parse(window.localStorage.getItem('logs'))
        if(localStorageLogs.length > 10){
            let finalLocalStorageLogs = []
            for(let i=0; i<localStorageLogs.length; i++){
                const drivers = ['Drivers', 'SearchDrivers', 'DriversAndSubBrands', 'DriversWithoutSentimentsAndSubBrands', 'DriversAndRatingImpacts'].includes(localStorageLogs[i].reqId)
                if( !(i > 10 && i < maxLen && !drivers) ){
                    finalLocalStorageLogs.push(localStorageLogs[i])
                }
            }
            window.localStorage.setItem('logs', JSON.stringify(finalLocalStorageLogs))
            checkLocalStorageSize(200)
        }
    }
}

export const exportExcel_addPercentage = (value) => {
    // return { value: (value*1), style: { alignment:{horizontal:'right'} } }
    return { value: (value*1)/100, style: {numFmt : '0%', alignment:{horizontal:'right'} } } // WORKING 
}

export const exportExcel_addSegments = (obj, showPercent=false) => {
    const array = [
        exportExcel_addPercentage(obj?.PositivePercent || 0),
        exportExcel_addPercentage(obj?.NeutralPercent || 0),
        exportExcel_addPercentage(obj?.MixedPercent || 0),
        exportExcel_addPercentage(obj?.NegativePercent || 0)
    ]
    if(showPercent){
        array.push( exportExcel_addPercentage(obj?.Percent || 0) )
    }
    return array
}

export const rteRemovingStyleFromTable = (str) => {
    return str.replaceAll('<td style="','_style="').replaceAll('<tr style="','_style="')
}

export const removeSpecialCharFromLhs = (str) => {
    // return str.replaceAll(" ", "").replaceAll(":", "").replaceAll(".", "").replaceAll("/", "").replaceAll("'", "")
    // return str.replace(/[ :.//']/g, "")
    return str.replace(/[^a-zA-Z]/g, "")
}

export const exportToPPT_tableCss = `
    &.exportToPPT, & .exportToPPT {
        .liLi { list-style: circle; margin-left: 24px; }
        .theadTr { background:#f1f1f1;}
        .theadTr td, .tbodyTr td { padding:10px; border:1px #E0E0E0 solid;}
        .tbodyTr td ul{ margin-left:10px;}
    }
`
export const tableCss = `
    & .liLi { list-style: circle; margin-left: 24px; }
    & table { width: 100%; border-collapse:collapse; border:1px; border-color: #E0E0E052;}
    & table td { padding:10px;  font-weight: 400; font-size: 14px; vertical-align: text-bottom; border:1px #E0E0E0 solid; }
    & thead { background:#f1f1f1;}
    & thead td { font-weight: 500; color:#000; }
    // & thead td, & tbody td { padding:10px; border:1px #E0E0E0 solid;}
    & tbody td ul{ margin-left:20px;}
`
export const ulLiCss = `
    & ul{ list-style-type:disc; margin-left:20px !important; padding-left:0 !important;}
    & ul li{ padding-bottom:2px;}
    & ul ul{ list-style-type:circle;}
    & ul ul ul{ list-style-type:square;}
    & ul ul ul ul{ list-style-type:disc;}
    & ul ul ul ul ul{ list-style-type:circle;}
    & ul ul ul ul ul ul{ list-style-type:square;}
`
export const ulLiCssRecommendation = `
    & ul{ list-style-type:disc; margin-left:20px !important; padding-left:0 !important;}
    & ul li{ padding-bottom:2px;}
    & ul ul{ list-style-type:disc;}
    & ul ul ul{ list-style-type:circle;}
    & ul ul ul ul{ list-style-type:square;}
    & ul ul ul ul ul{ list-style-type:disc;}
    & ul ul ul ul ul ul{ list-style-type:circle;}
    & ul ul ul ul ul ul ul{ list-style-type:square;}
`

export const charWidthFunc = ( str ) => {
    const setValues = [4, 6, 8, 10, 12]

    const set_0 = [ // 4
        'f', 'i', 'j', 'l', 'r', 't',
        'I', '', '', '',
        '|', '!', ' ', ',', '.'
    ];
    
    const set_1 = [ // 6
        'k', 's', 'v', 'x', 'y', 'z',
        'E', 'F', 'J', 'L', 'P', 'Z',
        '&', '#', '\\'
    ];

    const set_2 = [ // 8
        'a', 'b', 'c', 'd', 'e', 'g', 'h', 'n', 'o', 'p', 'q', 'u', 
        'A', 'B', 'D', 'H', 'K', 'N', 'R', 'S', 'T', 'U', 'V', 'X', 'Y', 
        '$'
    ];

    const set_3 = [ // 10
        'C', 'G', 'M', 'O', 'Q'
    ];

    const set_4 = [ // 12
        'm', 'w', 'W', '@'
    ];

    let length = 0
    for (let i=0; i<str.length; i++) {
        if( set_0.includes(str.charAt(i)) ) { length += setValues[0] }
        else if( set_1.includes(str.charAt(i)) ) { length += setValues[1] }
        else if( set_2.includes(str.charAt(i)) ) { length += setValues[2] }
        else if( set_3.includes(str.charAt(i)) ) { length += setValues[3] }
        else if( set_4.includes(str.charAt(i)) ) { length += setValues[4] }
        else { length += setValues[4] }
    }

    return length
}