import { ApiPost } from '../util/axios'

const setProject = async data => {
  const options = {
    reqId: 'SetProject',
    payload: {project: data},
    resId: 'SetProjectResponse',
    resType: 'object'
  }

  return await ApiPost(options).then( (res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const setClientHelpRequest = async (payload) => {
  const options = {
    reqId: 'SetClientHelpRequest',
    payload: {
        ...payload,
        initiateNewProjectDropout: true 
      },
    resId: 'SetClientHelpRequestResponse',
    resType: 'object'
  }

  return await ApiPost(options).then( (res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}


export default {
  setProject,
  setClientHelpRequest
}