import {preLoginJsonInstance} from '../util/axios'
import { CONST_API_JSON_URL } from '../constants'
import {ApiPost} from '../util/axios'

const getInsightsElementsApi = async (payload) => {
  const options = {
    reqId: 'GetInsightsElements',
    payload: payload,
    resId: 'GetInsightsElementsResponse',
    resType: 'array',
    getLatest: true
  }

  return await ApiPost(options).then( (res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const getStudiesApi = async (payload) => {
  const options = {
    reqId: 'GetStudies',
    payload: payload,
    resId: 'GetStudiesResponse',
    resType: 'array',
    getLatest: true
  }

  return await ApiPost(options).then( (res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const setSampleAskIAQuestionsForStudyApi = async (payload) => {
  const options = {
    reqId: 'SetSampleAskIAQuestionsForStudy',
    payload: payload,
    resId: 'SetSampleAskIAQuestionsForStudyResponse',
    resType: 'array',
    getLatest: true
  }

  return await ApiPost(options).then( (res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const setStudyQuestionAnswerApi = async (payload) => {
  const options = {
    reqId: 'SetStudyQuestionAnswer',
    payload: payload,
    resId: 'SetStudyQuestionAnswerResponse',
    resType: 'array',
    getLatest: true
  }

  return await ApiPost(options).then( (res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const setIAStudyQuestionAnswerLogApi = async (payload) => {
  const options = {
    reqId: 'IAStudyQuestionAnswerLog',
    payload: payload,
    resId: 'IAStudyQuestionAnswerLogResponse',
    resType: 'array',
    getLatest: true
  }

  return await ApiPost(options).then( (res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const askQuestionApi = async (payload) => {
  // return tempData[payload.dataIndex]
  // var query = document.getElementById('queryInput').value;

  // return await fetch('https://j2z807ulwe.execute-api.us-east-1.amazonaws.com/query', {
  return await fetch(`${CONST_API_JSON_URL}/query`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ studyId:payload.studyId, query: payload?.query || 'Network response was not ok' }),
  })
  .then(response => {
    return response.json();
  })
  .catch((error) => {
    console.error('Error:', error);
  })

  // return await preLoginJsonInstance({
  //   method: 'post',
  //   url: '/query',
  //   data: JSON.stringify({query: payload.query})
  // }).then( (res) => {
  //   return res;
  // },(error) => {
  //   console.log('error - ', error)
  //   return tempData[payload.dataIndex]
  // })

}

export default { 
  askQuestionApi,
  getInsightsElementsApi,
  getStudiesApi,
  setStudyQuestionAnswerApi,
  setSampleAskIAQuestionsForStudyApi,
  setIAStudyQuestionAnswerLogApi
}

const tempData = [
  {
    answer:[
      'Think about what you enjoy doing and what is important to you. This can help you identify potential career paths or hobbies that align with your passions.',
      'Try new things and step out of your comfort zone. This can help you discover new interests and passions that you may not have considered before.'
    ]
  },
  {
    answer:[
      'Im glad to hear that you love India! India is a country in South Asia, and it is the seventh-largest country by area and the most populous democracy in the world 1. India is known for its diverse culture, rich history, and beautiful landmarks such as the Taj Mahal, the Golden Temple, and the Red Fort 1.',
      'India is also famous for its cuisine, which varies from region to region. Some popular dishes include biryani, samosas, tandoori chicken, and dal makhani 1.',
      'India is home to many religions, including Hinduism, Islam, Christianity, and Sikhism 1. The country has a rich tradition'
    ]
  },
  {
    answer:[
      'Break down your goals into smaller, more manageable tasks. This can help you feel less overwhelmed and more motivated to take action.',
      'Remember that finding your path takes time and effort. Don’t be too hard on yourself if you don’t have everything figured out right away.'
    ]
  },
]
