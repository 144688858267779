import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import { background } from '../../assets/images'
import { MEDIA } from '../../constants'

export const PostLoginReportsOuterWrapper = styled(Box)(`
  display: flex;
  flex-direction: column;
  // background:#f1f1f1 url(${background}) no-repeat center center fixed;
  background-size: cover;
  @media (max-width: ${MEDIA.lg}) {
    height: 100%;
  }
`)

export const LhsWrapper = styled(Box)(`
  display: flex;
`)

export const RhsWrapper = styled(Box)(`
  display: flex;
  // width: calc(100% - 350px);
  width: 100%;
  flex-direction: column;
  height: calc(100vh - (60px) );
  & .footerWrapper {
    background: #fff;
    position: relative;
    z-index: 1;
    margin-top: auto;
  }
  @media (max-width: ${MEDIA.lg}) {
    height: auto;
    width: 100%;
    & .footerWrapper{
      margin-left: 0;
    }
  }
`)

export const Container = styled(Box)(`
  display: flex;
  @media (max-width: ${MEDIA.lg}) {
    height: 100%;
  }
`);

export const ContentWrapper = styled(Box)(`
  display: flex;
  flex: 1 1 auto;
  width: 100%;
  flex-direction: column;
  height: calc(100vh - (50px + 70px));
  @media (max-width: ${MEDIA.lg}) {
    height: auto;
  }
`)

export const ContentOuterWrapper = styled(Box)(`
    height:calc(100vh - 60px);
    display:flex;
    flex-direction:column;
    overflow-y:auto;
    @media (max-width: ${MEDIA.lg}) {
      height: auto;
    }
`)
