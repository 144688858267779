import React, { useState } from 'react'
// import DataGrid, { Column, Export } from 'devextreme-react/data-grid'
import domtoimage from 'dom-to-image'
// import { Workbook } from 'exceljs'
// import { saveAs } from 'file-saver-es'
import { MuiButton, MuiImage, Loader } from '../../components'
import { download, Download } from '../../assets/images/icons'

// Our demo infrastructure requires us to use 'file-saver-es'.
// We recommend that you use the official 'file-saver' package in your applications.
// import { exportDataGrid } from 'devextreme/excel_exporter'

const Excel1 = (props) => {
    const { downloadId } = props
    const [base64, setBase64] = useState()
    const [dimentions, setDimentions] = useState({})
    const [loading, setLoading] = useState(false)
    const imageWidth = 1280
    // const imageHeight = 639
    
    const getImageDimensions = (base64) => {
        const img = new Image();
        img.src = base64;
        img.onload = function() {
            setDimentions({width:img.naturalWidth, height:img.naturalHeight})
            setTimeout( () => {
                setBase64(base64)
                setTimeout( () => {
                    var link = document.querySelector(`#${downloadId}__wrapper .dx-toolbar-after div[role='button']`)
                    link.click();
                    
                    setTimeout( () => {
                        setLoading(false)
                    },1000)
                },1000)
            },10)
        }
    }

    const getJpg = () => {
        setLoading(true)
        const id = document.getElementById(downloadId)
        id.style.background = '#fff'
        id.style.width = imageWidth+'px'
        id.style.minWidth = imageWidth+'px'
        // id.style.border = '1px blue solid'

        domtoimage.toJpeg(id, { quality: 0.95 })
            .then(function (dataUrl) {
                const id = document.getElementById(downloadId)
                id.style.background = 'transparent'
                id.style.width = '100%'
                id.style.minWidth = '100%'
                getImageDimensions(dataUrl)
            }).catch(() => {
                setLoading(false)
            });
    }

    // const onExporting = (e) => {
        // const workbook = new Workbook();
        // const worksheet = workbook.addWorksheet(downloadId);

        // exportDataGrid({
        //     component: e.component,
        //     worksheet,
        //     autoFilterEnabled: false,
        //     topLeftCell: { row: 2, column: 2 },
        //     customizeCell: ({ gridCell, excelCell }) => {
        //         if (gridCell.rowType === 'data') {
        //             if (gridCell.column.dataField === 'Picture') {
        //                 excelCell.value = undefined;
                        
        //                 const image = workbook.addImage({
        //                     base64: gridCell.value,
        //                     extension: 'jpeg',
        //                 });

        //                 worksheet.getRow(excelCell.row).height = dimentions.height/2;
        //                 worksheet.getColumn(excelCell.col).width = Math.round(imageWidth/10.5);

        //                 worksheet.addImage(image, {
        //                     tl: { col: excelCell.col - 1, row: excelCell.row - 1 },
        //                     br: { col: excelCell.col, row: excelCell.row },
        //                 });
        //             }
        //         }
        //     },
        // }).then(() => {
        //     workbook.xlsx.writeBuffer().then((buffer) => {
        //         saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${downloadId}.xlsx`);
        //     });
        // }).catch(() => {
        //     setLoading(false)
        // });
        // e.cancel = true;
    // }

    // const renderGridCell = (cellData) => {
    //     return (<div><img src={cellData.value} /></div>);
    // }

    return (
        <div>

            {downloadId && 
                <MuiButton 
                    variant='standard' 
                    color='primary' 
                    sx={{ color: '#095BBD', marginLeft: '15px' }}
                    onClick={getJpg} 
                    disabled={loading}
                    // startIcon={loading ? <Loader size={15} /> : <MuiImage alt="image" src={download} />}
                    startIcon={loading ? <Loader size={15} /> : <Download />}
                    className='BtnExportToExcel'
                >
                    Export
                </MuiButton>
            }

            {/* <div style={{height:'1px', width:'1px', overflow:'hidden', display:'block', position:'absolute', top:0, left:0}}>
                {base64 && <DataGrid
                    id={`${downloadId}__wrapper`}
                    dataSource={[{ID:1, Picture: base64}]}
                    keyExpr="ID"
                    showBorders={true}
                    showRowLines={true}
                    showColumnLines={false}
                    onExporting={onExporting}>

                        <Column dataField="Picture" cellRender={renderGridCell} />

                    <Export enabled={true} />
                </DataGrid>}
            </div> */}
        </div>
    )
}

export default Excel1;
