import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { Legend, LoaderWrapper, ReportHeading, ReportOuterWrapper } from '../../../../components'
import ReportsService from '../../../../services/reports.service'
import { useUpdateLhs } from '../../../../hooks/useUpdateLhs'
import { exportExcel_addSegments, exportExcel_addPercentage } from '../../../../util'
import SegmentTable from './segmentTable'
import Header from './header'
import ComparisonTable from './comparisonTable'
import { ContentWrap, RootWrap } from './style'

const CONFIG = {
  "SearchDriversProgression": {
    type: "SearchKeywords",
    attributeColor: "#000",
    tabs: [
      { id: 0, label: "Comparison View" },
      { id: 1, label: "Periodic View" },
    ],
    showLegends: false
  },
  "DriversProgressionWithoutSentiments": {
    type: "SearchKeywords",
    attributeColor: "#000",
    tabs: [
      { id: 0, label: "Comparison View" },
      { id: 1, label: "Periodic View" },
    ],
    showLegends: false
  },
  "SegmentDriversOnlyAnalysisWithoutSentiments": {
    tabs: [
      { id: 0, label: "Comparison View" },
      { id: 1, label: "Segment View" },
    ],
    showLegends: true
  },
  "DriversProgression": {
    tabs: [
      { id: 0, label: "Comparison View" },
      { id: 1, label: "Periodic View" },
    ],
    showLegends: true
  },
  "SegmentDriversOnlyAnalysis": {
    tabs: [
      { id: 0, label: "Comparison View" },
      { id: 1, label: "Segment View" },
    ],
    showLegends: true
  },
  "SegmentDriversAnalysis": {
    tabs: [
      { id: 0, label: "Comparison View" },
      { id: 1, label: "Segment View" },
    ],
    showLegends: true
  },
  "Triangulation": {
    tabs: [
      { id: 0, label: "Comparison View" },
      { id: 1, label: "Segment View" },
    ],
    showLegends: true
  },
  "TriangulationComparisonViewOnly": {
    tabs: [
      { id: 0, label: "Comparison View" }
    ],
    showLegends: true
  }
}

const ReportSegment = (props) => {
  const [state, setState] = useState([])
  const [segmentData, setSegmentData] = useState(null)
  const [reportData, setReportData] = useState([])
  const [maxValue, setMaxValue] = useState(100)
  const [activeTab, setActiveTab] = useState(0)
  const [loading, setLoading] = useState(false)
  const {updateSubLhsApiLoaded} = useUpdateLhs()
  const [driversExcelData, setDriversExcelData] = useState()
  const [legendsData, setLegendsData] = useState([
    { label: 'Positive', value: 'Positive' },
    { label: 'Neutral', value: 'Neutral' },
    { label: 'Mixed', value: 'Mixed' },
    { label: 'Negative', value: 'Negative' }
  ])

  const getData = (dd, field = "SegmentDrivers", isInner = true) => {
    const obj = {};
    const percentArr = [];
    for (let index = 0; index < dd?.length; index++) {
      const element = dd[index];
      const driverElement = element?.[field]?.DriverElement;
      const drivers = Array.isArray(driverElement) ? driverElement : Object.keys((driverElement || {}))?.length ? [driverElement] : [];
      for (let j = 0; j < drivers.length; j++) {
        const innerElement = drivers[j];
        const key = innerElement.DriverName;

        const associationData = [{
          SearchKeywords: innerElement.Percent
        }];

        const multiBarData = [{
          Positive: innerElement.PositivePercent,
          Neutral: innerElement.NeutralPercent,
          Mixed: innerElement.MixedPercent,
          Negative: innerElement.NegativePercent
        }];

        let keyName = isInner ? innerElement.TargetSegment : element.PeriodName;
        if(['Triangulation', 'TriangulationComparisonViewOnly'].includes(props?.InsightsElementType)){
          keyName = element.TriangulationSubName
        }

        obj[key] = {
          ...(obj[key] || {}),
          [keyName]: {
            ...innerElement,
            data: CONFIG[props.InsightsElementType]?.type ? associationData : multiBarData
          }
        }

        percentArr.push(innerElement.Percent);
      }
    }
    setMaxValue(Math.max(...percentArr))
    return obj;
  }

  const updateExcelData = (data) => {
    const arr = []
    const objArr1 = Object.keys(data)
    for(let i=0; i<objArr1.length; i++){
      const name1 = objArr1[i]
      const objArr2 = Object.keys(data[name1])
      for(let j=0; j<objArr2.length; j++){
        const name2 = objArr2[j]
        const obj = data[name1][name2]
        // arr.push([{value: name1}, {value: name2}, {value: `${obj.PositivePercent}%`}, {value: `${obj.NeutralPercent}%`}, {value: `${obj.MixedPercent}%`}, {value: `${obj.NegativePercent}%`}, {value: `${obj.Percent}%`}])
        arr.push([{value: name1}, {value: name2}, ...exportExcel_addSegments(obj, true)])
      }
    }
    setDriversExcelData(arr)
  }

  const getDriverProgression = () => {
    setLoading(true)
    ReportsService.getDriverProgressionElement({ insightsElementId: props.InsightsElementId }).then(resp => {
      const sd = getData(resp, "PeriodDrivers", false)
      setSegmentData(sd)
      updateExcelData(sd)

      const dd = resp.map(item => ({
        ...item,
        SegmentDrivers: item.PeriodDrivers
      }))
      setReportData(dd)
      updateSubLhsApiLoaded(props)
    }).catch(error => (
      console.log(error)
    )).finally(() => {
      setLoading(false)
    })
  }

  const getTriangulation = () => {
    setLoading(true)
    ReportsService.GetTriangulationElement({ insightsElementId: props.InsightsElementId }).then(resp => {
      const sd = getData(resp, "TriangulationSubDrivers", false)
      setSegmentData(sd)
      updateExcelData(sd)

      const dd = resp.map(item => ({
        ...item,
        SegmentDrivers: item.PeriodDrivers
      }))
      setReportData(dd)

      updateSubLhsApiLoaded(props)
    }).catch(error => (
      console.log(error)
    )).finally(() => {
      setLoading(false)
    })
  }

  const getSegment = () => {
    setLoading(true)
    ReportsService.getSegmentDriversAnalysisElement({ insightsElementId: props.InsightsElementId }).then(resp => {
      if (!props?.isDriversProgression && !props?.hideTopBlueBar) {
        const averageStarRating = resp.map(item => {
          return ({
            SegmentType: item.SegmentType,
            SegmentName: item.SegmentName,
            SegmentMaxValue: item.SegmentMaxValue,
            SegmentValue: item.SegmentValue,
            type: 'Association',
            data: [{
              "0": item.SegmentValue,
            }]
          })
        })
        setState([{
          SegmentType: averageStarRating[0]?.SegmentType,
          data: averageStarRating
        }]);
      }
      const sd = getData(resp)
      setSegmentData(sd)
      updateExcelData(sd)

      setReportData(resp)
      updateSubLhsApiLoaded(props)
    }).catch(error => {
      console.log(error)
    }).finally(() => {
      setLoading(false)
    })
  }


  useEffect(() => {
    if(props.isDriversProgression && ['Triangulation', 'TriangulationComparisonViewOnly'].includes(props?.InsightsElementType)) {
      getTriangulation()
    } else if (props.isDriversProgression) {
      getDriverProgression()
    } else {
      getSegment()
    }
    
    if(['Triangulation', 'TriangulationComparisonViewOnly'].includes(props?.InsightsElementType)) {
      setLegendsData([...legendsData, { label: 'Search', value: 'SearchKeywords' }])
    }
  }, [])

  return (
    <>
      {driversExcelData && <ReportHeading resetInsightHeight={activeTab} downloadId={props.type} {...props} downloadIdData={driversExcelData} />}
      <ReportOuterWrapper>
        <RootWrap id={props.type} className={`${['Triangulation', 'TriangulationComparisonViewOnly'].includes(props.InsightsElementType) ? 'triangulationWrapper' : false} ${props.InsightsElementType}`}>
          <LoaderWrapper loading={loading} loadOnlyApi={props.loadOnlyApi}>
            {
              !!Object?.keys(segmentData || {})?.length &&
              <>
                <ContentWrap className='segmentWrapper'>
                  <Header options={CONFIG[props.InsightsElementType]?.tabs || undefined} onChange={setActiveTab} value={activeTab} />
                  {
                    activeTab === 0 ?
                      <ComparisonTable
                        isSearchDrivers={props.isSearchDrivers}
                        maxValue={maxValue}
                        data={state}
                        segmentData={segmentData}
                        type={CONFIG[props.InsightsElementType]?.type}
                        attributeColor={CONFIG[props.InsightsElementType]?.attributeColor}
                        insightsElementType={props.InsightsElementType}
                      /> :
                      <SegmentTable
                        data={reportData}
                        isSearchDrivers={props.isSearchDrivers}
                        showAttributes={!props?.isDriversProgression}
                        hideTopBlueBar={props.hideTopBlueBar}
                        type={CONFIG[props.InsightsElementType]?.type}
                        attributeColor={CONFIG[props.InsightsElementType]?.attributeColor}
                        insightsElementType={props.InsightsElementType}
                      />
                  }
                  {
                    CONFIG[props?.InsightsElementType]?.showLegends && 
                    <Box sx={{ display: 'flex', justifyContent: 'end', marginTop: '15px', marginBottom: '15px', marginRight: '14px'}}>
                      <Legend legends={legendsData} />
                    </Box>
                  }
                </ContentWrap>
              </>
            }
          </LoaderWrapper>
        </RootWrap>
      </ReportOuterWrapper>
    </>
  )
}

export default ReportSegment