const tab1 = [
  {
    CreatedDate: "2022-10-28T06:37:17",
    EntityId: "bfe77dca-54fe-11ed-817c-0a1ea4c5532f",
    Heading: "<p>Business Objective <sup>TM</sup> 1234</p>",
    Insights: "<ol><li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li><li>Donec ultrices sapien a massa blandit posuere.<ul><li>Vestibulum bibendum sem ac luctus interdum.</li><li>Nam malesuada justo ut justo ornare porta.</li></ul></li><li>Vestibulum semper eros id metus pretium venenatis.</li><li>Maecenas finibus metus sed nunc dapibus dignissim vel at nunc.</li><li>Nunc bibendum arcu at diam pulvinar porttitor.</li></ol>",
    InsightsElementDeepDive: false,
    InsightsElementId: "dd71d206-5689-11ed-817c-0a1ea4c5532f",
    InsightsElementOrder: 1,
    InsightsElementType: "BusinessObjective",
    InsightsElementVisible: true,
    ModifiedDate: "2023-08-18T22:45:50",
    ParentTitle: "",
    StudyId: "af4fa515-5500-11ed-817c-0a1ea4c5532f",
    TabId: "35633737-3634-6461-2d35-3638332d3131",
    Title: "Business Objective 123456",
    active: false,
    isApiLoading: true,
    tabContent: null
  },
  {
    CreatedDate: "2022-10-28T06:37:28",
    EntityId: "bfe77dca-54fe-11ed-817c-0a1ea4c5532f",
    Heading: "&lt;p&gt;Study Design&lt;/p&gt;",
    Insights: "&lt;p&gt;&lt;/p&gt;",
    InsightsElementDeepDive: false,
    InsightsElementId: "0510dd82-568a-11ed-817c-0a1ea4c5532f",
    InsightsElementOrder: 2,
    InsightsElementType: "ResearchDesign",
    InsightsElementVisible: true,
    ModifiedDate: "2024-07-05T01:11:32",
    Observations: "",
    ParentTitle: "",
    StudyId: "af4fa515-5500-11ed-817c-0a1ea4c5532f",
    TabId: "35633737-3634-6461-2d35-3638332d3131",
    Title: "Study Design",
    active: false,
    isApiLoading: true,
    tabContent: null
  },
  {
    CreatedDate: "2022-10-28T06:37:35",
    EntityId: "bfe77dca-54fe-11ed-817c-0a1ea4c5532f",
    Heading: "&lt;p&gt;Set up&lt;/p&gt;",
    Insights: "&lt;p&gt;&lt;/p&gt;",
    InsightsElementDeepDive: false,
    InsightsElementId: "e749cf01-568a-11ed-817c-0a1ea4c5532f",
    InsightsElementOrder: 3,
    InsightsElementType: "BrandHealthSetup",
    InsightsElementVisible: true,
    ModifiedDate: "2023-08-18T22:45:50",
    Observations: "",
    ParentTitle: "",
    StudyId: "af4fa515-5500-11ed-817c-0a1ea4c5532f",
    TabId: "35633737-3634-6461-2d35-3638332d3131",
    Title: "Study Setup",
    active: false,
    isApiLoading: true,
    tabContent: null
  }
]
const tab2 = [
  {
      CreatedDate: "2023-09-22T05:00:20",
      EntityId: "bfe77dca-54fe-11ed-817c-0a1ea4c5532f",
      Heading: "&lt;p&gt;Benefit vs. Ingredient 1234 &lt;/p&gt;",
      Insights: "",
      InsightsElementDeepDive: false,
      InsightsElementId: "83728080-5903-11ee-817c-0a1ea4c5532f",
      InsightsElementOrder: 5,
      InsightsElementType: "SegmentOverview",
      InsightsElementVisible: true,
      ModifiedDate: "2023-09-25T04:55:07",
      Observations: "",
      ParentTitle: "",
      StudyId: "af4fa515-5500-11ed-817c-0a1ea4c5532f",
      TabId: "35633933-3234-6461-2d35-3638332d3131",
      Title: "Benefit vs. Ingredient 123456",
      active: false,
      isApiLoading: true,
      tabContent: null
  },
  {
      CreatedDate: "2023-09-22T05:53:48",
      EntityId: "bfe77dca-54fe-11ed-817c-0a1ea4c5532f",
      Heading: "&lt;p&gt;Age&lt;/p&gt;",
      Insights: "",
      InsightsElementDeepDive: false,
      InsightsElementId: "3e243bdb-590c-11ee-817c-0a1ea4c5532f",
      InsightsElementOrder: 6,
      InsightsElementType: "SegmentAndSegmentOfSegment",
      InsightsElementVisible: true,
      ModifiedDate: "2023-09-25T04:55:07",
      Observations: "",
      ParentTitle: "",
      StudyId: "af4fa515-5500-11ed-817c-0a1ea4c5532f",
      TabId: "35633933-3234-6461-2d35-3638332d3131",
      Title: "Age",
      active: false,
      isApiLoading: true,
      tabContent: null
  }
]
const tab3 = [

]
const tab4 = [

]

const tabs = (payload) => {
  // console.log('payload ============== ', payload)
  if(payload?.tabId === '35633737-3634-6461-2d35-3638332d3131') {
    return tab1
  } else if(payload?.tabId === '35633933-3234-6461-2d35-3638332d3131') {
    return tab2
  }
  // tab1, tab2, tab3, tab4
  return []
}

export default tabs()