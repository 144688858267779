import { styled } from '@mui/material/styles'
import {
  Box, ListItemText, Icon, Avatar, Menu,
} from '@mui/material'

export const ToolbarStyled = styled(Box)(`
  justify-content: space-between;
  display: flex;
  min-height: 60px;
  padding: 0 30px;
  align-items: center;
  margin:left: 10px;
`)

export const DashboardNavbarRoot = styled(Box)(`
  position: static;
  border-bottom: 1px solid #EEEEEE;
`)

export const AvatarIcon = styled(Avatar)(`
  height: 40px;
  width: 40px;
  background: #F1F6FF;
  color: #242424;
  font-weight: bold;
`)


export const MenuWrapper = styled(Menu)(`
  margin-top:40px;

  & > .MuiPaper-root.MuiPaper-elevation {
    box-shadow: 0px 3px 20px rgba(208, 209, 209, 0.8);
    border-radius: 8px;
  }

  & li {
    opacity:1 !important;
    padding:10px 15px;
  }
  & li img{padding-right:10px;}
  & li p{
    display:flex;
    align-items: center;
  }
`)

export const AvatarWrapper = styled(Box)(`
  margin:0 15px 0 10px;
  width:auto;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  & > div {
    width:26px;
    height:26px;
    font-weight: 400;
    font-size: 14px;
    line-height: 27px;
    letter-spacing: -0.01em;
    color: #000;
  }
`)

export const ListItemTextNew = styled(ListItemText)(`
  span {
    color: #545454;
  }
  p {
    color: #242424;
  }
`)

export const IconWrapper = styled(Icon)(`
  width: unset !important;
  line-height: 0.8em;
`)

export const SelectCircularProgressWrapper = styled(Box)(`
  margin-bottom: -5px;
`)

export const SelectRenderValueWrapper = styled(Box)(`
  display: flex;
`)

export const SelectRenderValueText = styled(Box)(`
  margin-left: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: 0.2px;
  color: #536082;
`)




