import { styled } from '@mui/material/styles'
import { Box, Typography } from '@mui/material'
import { bigArrow } from '../../../../assets/images/icons'
import { exportToPPT_tableCss } from '../../../../util'
import { MEDIA } from '../../../../constants'

export const OuterWrapper = styled(Box)(`
    width: 100%;
    padding: 0;
    display: flex;
    align-items: center;

    // @media (max-width: ${MEDIA.lg}) {
    //     padding:20px 20px 40px 20px;
    // }
    @media (max-width: 980px) {
        // padding: 30px 20px;
        min-width: 100%;
        flex-direction:column;
        & > div {
            margin:0;
            width:100%;
        }
        & *:before, & *:after{display:none}
    }

    &.exportToPPT { width:1500px; padding:0; }
    &.exportToPPT > div { box-shadow:none; }
    &.exportToPPT .barChartsWrapper > div { margin-bottom:0; }
    &.exportToPPT .chartLhs { width:55%; }
    &.exportToPPT .chartRhs { width:45%; }
    &.exportToPPT .tabWrapper { display:none; }
    &.exportToPPT .brandMagnetScoreRows { padding: 0 10px; align-items:center; }
    &.exportToPPT {
        & .emojiRow { width:100%; }
        & .emoji-item {
            text-wrap: nowrap;
            white-space: nowrap;
            min-width:60px;
        }

        & .brandCategory { border:0; }
        // & .brandCategory > div { padding:0; }
    }

    ${ exportToPPT_tableCss }
    
    &.exportToPPTProgression {
        & .brandCategory {border: 0;}
        & .brandCategory > div {padding: 0;}
        & .tabWrapper, & .filterOuterWrap {display: none;}
        // & .brandHealthSectionsWrapper > div { width: 100%; background: transparent; box-shadow: none;}

        .chartLhsWrapper {
            display: flex;
            flex-wrap:wrap;
        }
        .chartLhs {
            width: 100%;
        }
        .chartRhs {
            padding: 0;
            width: 100%;
            box-shadow: none;
            background: transparent;
        }
        .brandMagnetScoreTreeWrap{
            padding-bottom:80px;
        }
        .brandMagnetScoreTreeWrap > span{
            transform: rotate(90deg);
            left: calc(50% - 30px);
            top: auto;
            bottom: -2px;
        }
        .brandMagnetScoreTreeWrap ul{
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
        }
        .brandMagnetScoreTreeWrap ul:before{
            border-top: 0;
            border-left: 1px solid #d3d3d3;
            width: 80%;
            right: 10%;
            bottom: -10px;
        }
        .brandMagnetScoreTreeWrap li{
            width: calc(20% - 20px);
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            text-align: center;
            box-shadow: 0px 10px 10px rgba(34, 47, 62, 0.1);
        }
        .brandMagnetScoreTreeWrap li:after {
            top: auto;
            bottom: -30px;
            right: 50%;
            width: 1px;
            height: 30px;
        }

    }

`)

export const ChartLhs = styled(Box)(`
    display: flex;
    width: 60%;
    position: relative;
    align-items: center;
`)

export const ChartRhs = styled(Box)(`
    width: 40%;
    background: #FFFBF8;
    padding: 20px 30px 20px 40px;
    box-shadow: 0px 0px 10px #FFC79F;

    & .barSliceLabel {padding-left:5px !important; font-size:15px;}
    & .barChartsWrapper > div {margin-bottom: 10px;}
`)

export const TitlePie = styled(Typography)(`
    margin-top:15px;
    padding:0 5px;
    height: 20px;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    text-transform: capitalize;
    color: #FFFFFF;
    background: #475467;
    border-radius: 2px;
    display: inline-block;
`)

export const TabSection1 = styled(Box)( props => `
    // padding:10px;
    display: flex;
    align-items: center;
    width: 100%;
    ${props?.hasTabs ? `
        // padding: 25px;
        align-items: center;
    ` : ''}
`)

export const TabSection2 = styled(Box)( props => `
    // padding:25px;
    display: flex;
    align-items: center;
    width: 100%;
`)
