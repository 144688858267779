import { styled } from '@mui/material/styles'
import { Box, Typography } from '@mui/material'
import {MuiButton} from '../../../../../components'

export const Wrapper = styled(Box)(`
  width:70vw;
  min-width:370px;
`)

export const LoginButton = styled(MuiButton)(`
  margin-top:30px;
`)

export const Title = styled(Typography)(`
  margin-top: -10px;
  padding-bottom: 30px;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
`)

export const RteOuterWrapper = styled(Box)(`
  & label {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    text-transform: capitalize;
    color: #000000;
  }
  & > div {
    margin-bottom:20px;
    display:flex;
    justify-content: space-between;
  }
`)

export const RteWrapper = styled(Box)(`
  width:calc(50% - 15px);
`)

