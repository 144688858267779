import { Typography } from '@mui/material';
import React from 'react';
import { Modal, MuiButton } from '../../../../components';
import {  SuccessModalWrapper} from './style';

const SuccessPopup = ({ onClose, message="", color="black" }) => {

  return (
    <Modal title='' showClose={true} onClose={onClose}>
      <SuccessModalWrapper>
        <Typography sx={{
          fontWeight: 300,
          fontSize: "20px",
          color: color
        }} variant='body1'>{message}</Typography>
        <MuiButton
          sx={{
            marginTop: "30px",
          }}
          color='primary'
          variant='contained'
          onClick={onClose}
        >
          OK
        </MuiButton>
      </SuccessModalWrapper>
    </Modal>
  );
};

export default SuccessPopup;
