import { styled } from '@mui/material/styles'
import { Box, Typography } from '@mui/material'
import MuiButton from '../../../../../components/muiButton'

export const Wrapper = styled(Box)(`
  width:800px;
`)

export const LoginButton = styled(MuiButton)(`
  margin-top:10px;
  margin-left: 0 !important;
`)

export const Title = styled(Typography)(`
  margin-top: -10px;
  padding-bottom: 30px;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
`)

export const InputWrap = styled(Box)(`
  // height: 70px;
  padding-bottom: 10px;
  & label {
    padding-bottom:5px;
  }
`)

export const ButtonWrapper = styled(Box)(`
  width:100%;
  display:flex;
  justify-content:end;
`)

export const SuggestionWrapper = styled(Box)(`
    width:100%;
    padding:14px 2px 4px 180px;
    display:flex;
    justify-content: end;
    align-items: center;
    flex-wrap:wrap;

    &:before {
        content: 'Suggestions:';
        color: #095BBD;
        font-size: 12px;
        font-weight: 500;
        line-height: 20px;
    }
`)

export const SuggestionInputWrapper = styled(Box)(`
  width:100%;
  max-height:calc(100vh - 350px);
  overflow-y: auto;
`)

export const SuggestionLink = styled('button')(`
    text-wrap:nowrap;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    background: #FFF;
    border: 1px #c3c3c3 solid;
    padding: 9px 10px;
    margin-left:10px;
    margin-bottom:10px;
    cursor:pointer;
`)

