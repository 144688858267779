import React, { useState, useEffect, useRef } from 'react'
import ReportsService from '../../services/reports.service'

import { Modal, Rte } from '..'
import { IconButton } from '@mui/material'
import { rteRemovingStyleFromTable } from '../../util'
import { IconEdit } from '../../assets/images/icons'

import { LoginButton, Title, Wrapper, RtePreviewCss } from './style'

const ReportEditHeader = (props) => {
  const {onClose, InsightsElementId, pageInsights, pageHeading, onSuccess, addTable=false} = props
  // const [anchorElUser, setAnchorElUser] = useState(null)
  const [showEditHeaderModal, setShowEditHeaderModal] = useState(false)

  const handleOnEditHeader = () => {
    // setAnchorElUser(null)
    setShowEditHeaderModal(false)
  }

  const form = useRef()
  const [loader, setLoader] = useState(false)
  const [headingValue, setHeadingValue] = useState(pageHeading || '')
  const [insightsValue, setInsightsValue] = useState(pageInsights || '')

  useEffect(() => {
    setInsightsValue(pageInsights);
  }, [pageInsights])

  useEffect(() => {
    setHeadingValue(pageHeading);
  }, [pageHeading])
  
  
  // const { enqueueSnackbar } = useSnackbar()

  const onSubmit = (e) => {
   
    e.preventDefault()
    const updatedValues = {
      insightsElementId : InsightsElementId,
      heading : headingValue,
      // insights : encodeURIComponent(insightsValue)
      // insights : encodeURIComponent(insightsValue.replaceAll('style="','_style="'))
      insights : encodeURIComponent(rteRemovingStyleFromTable(insightsValue))
    }

    setLoader(true)
    ReportsService.reportEditHeaderApi(updatedValues)
    .then((resp) => {
      setLoader(false)
      setShowEditHeaderModal(false)
      onSuccess()
    })
    .catch((error) => {
      setLoader(false)
      const resMessage = error
      console.log('ERROR : ', resMessage)
        // enqueueSnackbar(resMessage, { variant: 'error' })
    })
  }

  return (
    <>
      <IconButton aria-label="Edit" onClick={() => setShowEditHeaderModal(true)} sx={{height:'35px', margin:'-5px 0 0 5px'}}><IconEdit /></IconButton>
      {showEditHeaderModal && <Modal title='' showClose={true} onClose={handleOnEditHeader}>
        <Wrapper>
          <form ref={form}>
            <Title variant='body1'>Edit heading and insights</Title>

            <Rte title={'Heading'} value={headingValue} onChange={(e) => setHeadingValue(e)} height={170} customToolbar={'bold italic underline strikethrough | subscript superscript | removeformat undo redo | link'} />
            <Rte title={'Insights'} value={insightsValue} onChange={(e) => setInsightsValue(e)} height={320} customToolbar={`h4 | bold italic underline strikethrough | hr | subscript superscript | bullist numlist indent outdent | removeformat undo redo ${addTable ? '| table ' : ''}| fontsize | link`} content_style={RtePreviewCss} />

            <LoginButton
              color='primary'
              variant='contained'
              type='submit'
              // onClick={formik.handleSubmit}
              onClick={onSubmit}
              disabled={loader || false}
            >
              Submit
            </LoginButton>
          </form>
        </Wrapper>
      </Modal>}
    </>
  )
}

export default ReportEditHeader
