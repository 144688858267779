import React, { useEffect, useState } from "react"
import { IconButton, Box } from '@mui/material'
import { ReportHeading, DynamicLegend, LoaderWrapper, D3ForceDirectedTree, InsightsRteContent, MuiButton, ReportOuterWrapper } from "../../../../components"
import ReportsService from "../../../../services/reports.service"
import { useUpdateLhs } from '../../../../hooks/useUpdateLhs'
import { Refresh, closeMini, edit, DoubleArrow } from '../../../../assets/images/icons'
import { getObjectAsArray } from '../../../../util'
import { ROLES_MM_PRIMARY } from '../../../../constants'
import ReportEditInsights from './reportEditInsights'
import { useGlobalStore } from '../../../../store'
import PostLoginReportsEditModal from '../../../../layout/postLoginReportsEditModal/postLoginReportsEditModal'
import { Overlay, OuterWrapper, InnerWrapper, GraphWrapper, ExportToPPT, PopupOverlay, PopupWrapper, PopupHeading, MuiResetButton, PopupContent, LegendWrapper, MenuOuterWrapper, CloseWrap, EditWrap, MenuInnerWrapper, MenuWrapper, MenuHeading1, MenuHeading2 } from './style'

const ReportFactorInterLinkage = (props) => {
  const [loading, setLoading] = useState(false)
  const [state, setState] = useState([])
  const [statePreview, setStatePreview] = useState([])
  const [statePPT, setStatePPT] = useState([])
  const {updateSubLhsApiLoaded} = useUpdateLhs()
  const [reportExcelData, setReportExcelData] = useState()
  const [showExploreGraph, setShowExploreGraph] = useState(true)
  // const [clickedIndex, setClickedIndex] = useState(null)
  const [editIndex, setEditIndex] = useState(null)
  // const [showMenu, setShowMenu] = useState(null)
  const [colorPallet, setColorPallet] = useState({})
  const {user, projectIdValue} = useGlobalStore()
  const [modalOpened, setModalOpened] = useState(false)
  const [showLinkagePopup, setShowLinkagePopup] = useState(true)
  const [showEditPopup, setShowEditPopup] = useState(false)
  const [graphLegends, setGraphLegends] = useState([])
  const [eventCount, setEventCount] = useState(1)

  const getUniqueData = (arr) => {
    let outputArray = arr.filter(function (v, i, self) {
        return i == self.indexOf(v);
    })
    return outputArray;
  }

  const generatePptDataFunc = (respData) => {
    const pptIsHighlightedArray1 = respData.filter((f) => f.IsHighlighted )
    const pptIsHighlightedArray2 = []
    const pptIsHighlightedArray3 = []
    
    for (let a=0; a<pptIsHighlightedArray1.length; a++) {
      const factorLinkageArray = getObjectAsArray(pptIsHighlightedArray1[a].FactorLinkages.FactorLinkage)
      pptIsHighlightedArray2.push([])
      const tempSource = []

      for (let b=0; b<factorLinkageArray.length; b++) {
        if(tempSource.includes(factorLinkageArray[b].LinkedFactor)){continue}
        const factorGroup = respData.find(f => f.Factor === factorLinkageArray[b].LinkedFactor ).FactorGroup
        tempSource.push(factorLinkageArray[b].LinkedFactor)
        pptIsHighlightedArray2[a].push({
          ...pptIsHighlightedArray1[a],
          FactorGroup: factorGroup,
          source: factorLinkageArray[b].LinkedFactor,
          // LinkedFactorWeight: factorLinkageArray[b].LinkedFactorWeight,
          FactorWeight: factorLinkageArray[b].LinkedFactorWeight || pptIsHighlightedArray1[a].FactorWeight,
          // target: factorLinkageArray[b].LinkedFactor,
          targetValue: factorLinkageArray[b].LinkageWeight,
          targetInsights: factorLinkageArray[b].Insights,
          targetId: factorLinkageArray[b].FactorLinkageId
        })
      }

      pptIsHighlightedArray2[a].unshift({
        ...pptIsHighlightedArray1[a],
        source: pptIsHighlightedArray1[a].Factor,
        target: pptIsHighlightedArray1[a].Factor,
        targetValue: pptIsHighlightedArray1[a].FactorWeight,
        targetInsights: ' ',
        targetId: pptIsHighlightedArray1[a].InsightsElementId
      })
    }


    ////////////////////////////////////////////////
    
    for (let d=0; d<pptIsHighlightedArray2.length; d++) {
      const finalResp = pptIsHighlightedArray2[d]
      const category = finalResp.map(d => ({ id: d.FactorGroup }))
      const sourceArray = finalResp.map(r => r.source)
      const nodes = finalResp.map((d, i) => ({ id: i, name: d.source }))
      const links = finalResp.map((d, i) => {
        return {
          id: i,
          source: sourceArray.indexOf(d.source),
          // source: d.source,
          category: d.FactorGroup,
          size: d.FactorWeight + 45,
          value: d.targetValue,
          target: 0
        }
      })

      const linksDetails = finalResp.map((d, i) => {
        return {
          id: i,
          insightsElementId: d.InsightsElementId,
          isHighlighted: d.IsHighlighted,
          source: d.source,
          sourceArray: getText(d.source),
          category: d.FactorGroup,
          size: d.FactorWeight + 40,
          value: d.targetValue,
          target: 0, // sourceArray.indexOf(d.target),
          targetStr: d.target,
          targetInsights: d.targetInsights,
          targetId: d.targetId,
          clicked: (i === 0), // false,
          clickedIndex: 0,
          factorType: d.factorType,
          isRepeated: false,
          rest: d
        }
      })

      const linksDetailsTargets = generateTargetArrayList(linksDetails)
      pptIsHighlightedArray3.push({nodes, links, linksDetails, category, linksDetailsTargets})
    }

    setStatePPT(pptIsHighlightedArray3)
  }

  const generateTargetArrayList = (respData) => {
    const parentArray = respData.filter(f => f.factorType === 'parent')
    const childArray = respData.filter(f => f.factorType === 'child')
    const finalArray = []

    for(let i=0; i<parentArray.length; i++){
      finalArray.push([])
      for(let j=0; j<respData.length; j++){
        if(parentArray[i].id === respData[j].id){ finalArray[i].push(respData[j].id) }
        else if(parentArray[i].id === respData[j].target){ finalArray[i].push(respData[j].id) }
      }

      for(let j=0; j<childArray.length; j++){
        if(parentArray[i].source === childArray[j].source){ finalArray[i].push(childArray[j].id) }
      }

      const factorLinkageArray = getObjectAsArray(parentArray[i].rest.FactorLinkages.FactorLinkage).map(d => d.LinkedFactor)
      for(let j=0; j<parentArray.length; j++){
        if(factorLinkageArray.includes(parentArray[j].source) ){
          finalArray[i].push(parentArray[j].id)
        }
      }
    }

    return finalArray
  }

  const getText = (text) => {
    const labelArray = (text).replaceAll(' / ','/').split(' ')
    let finalLabel = labelArray
    if(finalLabel.length === 3){
      if( (labelArray[0].length + labelArray[1].length) < 12 ){
        finalLabel = [`${labelArray[0]} ${labelArray[1]}`, labelArray[2]]
      } else if ( (labelArray[1].length + labelArray[2].length) < 12 ) {
        finalLabel = [labelArray[0], `${labelArray[1]} ${labelArray[2]}`]
      }
    }
    return finalLabel
  }

  const getData = (getLatest=false) => {
    setLoading(true)
    ReportsService.getFactorInterLinkageElement({
      insightsElementId: props.InsightsElementId,
      getLatest:getLatest
    })
      .then((resp) => {
        const sourceArray = resp.map(r => r.Factor)
        let linkedFactorCount = 0

        const finalResp = resp.map(r => {
          r['target'] = getObjectAsArray(r.FactorLinkages.FactorLinkage)[0].LinkedFactor
          r['targetValue'] = getObjectAsArray(r.FactorLinkages.FactorLinkage)[0].LinkageWeight
          r['targetInsights'] = getObjectAsArray(r.FactorLinkages.FactorLinkage)[0].Insights
          r['targetId'] = getObjectAsArray(r.FactorLinkages.FactorLinkage)[0].FactorLinkageId
          r['factorType'] = 'parent'
          r.FactorLinkages.FactorLinkage = getObjectAsArray(r.FactorLinkages.FactorLinkage)
          return r
        })

        for(let c=0; c<finalResp.length; c++){
          const factorLinkageArray = getObjectAsArray(finalResp[c].FactorLinkages.FactorLinkage)
          linkedFactorCount = linkedFactorCount+factorLinkageArray.length
        }

        generatePptDataFunc(finalResp)

        for(let i=0; i<finalResp.length; i++){
          if(finalResp.length < linkedFactorCount){
            const factorLinkageArray = getObjectAsArray(finalResp[i].FactorLinkages.FactorLinkage)
            if(factorLinkageArray?.length > 1){
              for(let j=1; j<factorLinkageArray.length; j++){
                finalResp.push({
                  ...finalResp[i],
                  target: factorLinkageArray[j].LinkedFactor,
                  targetValue: factorLinkageArray[j].LinkageWeight,
                  targetInsights: factorLinkageArray[j].Insights,
                  targetId: factorLinkageArray[j].FactorLinkageId,
                  factorType: 'child'
                })
              }
            }
          }
        }

        const getSize = (apiSize, apiTextArray) => {
          const textMaxLength = Math.max(...apiTextArray.map(t => t.length)) * 6
          const finalSize = (apiSize < textMaxLength) ? textMaxLength : apiSize
          return finalSize
        }

        const categoryUniqueList = getUniqueData(finalResp.map(d => (d.FactorGroup)))
        const category = finalResp.map((d, i) => {
          return { id: d.FactorGroup }
        })
        const nodes = finalResp.map((d, i) => {
          return { id: i, name: d.Factor }
        })
        const links = finalResp.map((d, i) => {
          return {
            id: i,
            // source: d.Factor,
            source: sourceArray.indexOf(d.Factor),
            category: d.FactorGroup,
            // size: (d.FactorWeight < 50 ) ? 50 : d.FactorWeight, // getSize(d.FactorWeight, getText(d.Factor)),
            size: d.FactorWeight + 45, // getSize(d.FactorWeight, getText(d.Factor)),
            value: d.targetValue,
            target: sourceArray.indexOf(d.target)
          }
        })
        const linksDetails = finalResp.map((d, i) => {
          return {
            id: i,
            insightsElementId: d.InsightsElementId,
            isHighlighted: d.IsHighlighted,
            source: d.Factor,
            sourceArray: getText(d.Factor),
            category: d.FactorGroup,
            size: d.FactorWeight + 40, // getSize(d.FactorWeight, getText(d.Factor)),
            // size: getSize(d.FactorWeight, getText(d.Factor)),
            value: d.targetValue,
            target: sourceArray.indexOf(d.target),
            targetStr: d.target,
            targetInsights: d.targetInsights,
            targetId: d.targetId,
            // targetInsights: d.FactorLinkages.FactorLinkage[0]?.Insights || '',
            // targetId: d.FactorLinkages.FactorLinkage[0]?.FactorLinkageId,
            clicked: false,
            clickedIndex: null,
            factorType: d.factorType,
            isRepeated: !(sourceArray.indexOf(d.Factor) === i),
            rest: d
          }
        })

        const legend = {}
        const legends = []
        for(let l=0; l<categoryUniqueList.length; l++){
          const color = ['#C6C6F8', '#F1D794', '#C7D994', '#FCA2B5', '#96E5EE', '#CF92F2']
          legend[categoryUniqueList[l].replaceAll(' ','')] = color[l]
          legends.push({
            label: categoryUniqueList[l],
            value: categoryUniqueList[l].replaceAll(' ',''),
            color: color[l]
          })
        }

        const linksDetailsTargets = generateTargetArrayList(linksDetails)

        setColorPallet(legend)
        setGraphLegends(legends)

        reportExcelDataObjFunc(linksDetails)
        setState({nodes, links, linksDetails, category, legend, linksDetailsTargets})

        if(statePreview.length === 0) {
          setStatePreview({nodes, links, linksDetails, category, legend})
        }

        updateSubLhsApiLoaded(props)
        setEventCount(p => p+1)

        if(getLatest) {          
          setTimeout(() => {
            setShowExploreGraph(true)
          }, 500)
        }
        setTimeout(() => {
          setEventCount(p => p+1)
        }, 500)

      })
      .catch((error) => {
        console.log('ERROR : ', error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const reportExcelDataObjFunc = (res) => {
    const reportExcelDataObj = []
    for(let r=0; r<res.length; r++){
      reportExcelDataObj.push([{value: res[r].source}, {value: res[r].target}, {value: res[r].value}])
    }
    setReportExcelData(reportExcelDataObj)
  }

  const closeMenu = () => {
    setModalOpened(false)
  }

  const closeEditMenu = () => {
    setEditIndex(null)
  }
  
  const successEditMenu = () => {
    setEditIndex(null)
    closeMenu()
    setShowExploreGraph(false)
    getData(true)
  }

  const onChange = ({type, editIndex}) => {
    if(type === 'edit'){setEditIndex(editIndex);}
  }

  const modalOpenedHandler = () => {
    setModalOpened(true);
  }

  const refreshFunc = () => {
    setShowExploreGraph(false)
    const linksDetailsData = state.linksDetails.map((d) => {
      d.clicked = false
      d.clickedIndex = null
      return d
    })
    setState({...state, linksDetails: linksDetailsData})

    setTimeout(() => {
      setShowExploreGraph(true)
      setEventCount(p => p+1)
    }, 100)
  }

  const linkagePopupFunc = (type) => {
    if(type === 'yes'){
      modalOpenedHandler()
    } else if (type === 'no'){
      setShowLinkagePopup(false)
    } else if (type === 'show' && !showLinkagePopup){
      setShowLinkagePopup(true)
    }
    setEventCount(p => p+1)
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <>
      <ReportHeading resetInsightHeight={eventCount}
        downloadId={props.type} {...props} downloadIdData={reportExcelData}
        additionalMiddleButton={
          <PostLoginReportsEditModal 
            additionalButtons={<MuiResetButton variant='contained' color='primary' onClick={() => refreshFunc()}>Refresh &nbsp; <Refresh /></MuiResetButton>} 
            onLoadShow={modalOpened} modalOpened={modalOpenedHandler} modalClosed={() => {refreshFunc(); setModalOpened(false)}}
            modalTitle={props.Title} buttonName='Explore Interlinkage' spaceBottom={90} buttonClassName='BtnExploreInterLinkages'
          >
            <GraphWrapper>
              {ROLES_MM_PRIMARY.includes(user?.UserType) && !projectIdValue?.IsFreezed && !!editIndex && <ReportEditInsights data={state.linksDetails[editIndex]} onSuccess={successEditMenu} onClose={closeEditMenu} />}
              {showExploreGraph && state?.links?.length > 0 && <D3ForceDirectedTree onLoadScale={0.9} graphLegends={graphLegends} id={`containerExpanded_${props.InsightsElementId}`} type='fullscreen' data={state} color={colorPallet} isEditable={ROLES_MM_PRIMARY.includes(user?.UserType) && !projectIdValue?.IsFreezed} onChange={onChange} closeMenu={closeMenu} startZoomPaneClick={true} />}
            </GraphWrapper>
          </PostLoginReportsEditModal>
        }
      />
      
      <ReportOuterWrapper>
        <OuterWrapper id={props.type}>
          <LoaderWrapper loading={loading} loadOnlyApi={props.loadOnlyApi}>

            {!props.isPPTModalActive && <InnerWrapper className='innerWrapper'>
              <PopupOverlay onClick={() => linkagePopupFunc('show')}>
                {showLinkagePopup && <PopupWrapper>
                  <PopupHeading>Explore Interlinkage</PopupHeading>
                  <MuiButton variant='contained' color='primary' onClick={() => linkagePopupFunc('yes')}>Yes</MuiButton>
                  <MuiButton variant='contained' color='light' onClick={() => linkagePopupFunc('no')}>No</MuiButton>
                  <PopupContent>
                    <b>How to use Interlinkages:</b>
                    <ul>
                      <li>Click on insight space circles to see linkages and insights</li>
                      <li>Zoom in and zoom out using mouse scroll or touchpad pinch</li>
                      <li>Drag the circles to rearrange</li>
                      <li>Use refresh button to get back to default state</li>
                    </ul>
                  </PopupContent>
                </PopupWrapper>}
              </PopupOverlay>
              {statePreview?.links?.length > 0 && <D3ForceDirectedTree onLoadScale={0.9} graphLegends={graphLegends} id={`containerSmall_${props.InsightsElementId}`} type='preview' data={statePreview} color={colorPallet} />}
            </InnerWrapper>}

            {props.isPPTModalActive && <ExportToPPT className='outerWrapperPPT'>
              {statePPT?.map((pptData, i) => {
                if(pptData.linksDetails[0].isHighlighted) {
                  return (<D3ForceDirectedTree key={i} onLoadScale={0.8} graphLegends={graphLegends} id={`container_ppt_${i}_${props.InsightsElementId}`} type='ppt' data={pptData} color={colorPallet} onLoadClickedIndex={0} pptData={statePPT} pptDownloadIndex={i} />)
                } else {
                  console.log('NOTE: In FactorInterLinkage ========> isHighlighted is false, hence not showing in ExportToPPT. ', i, pptData.linksDetails)
                  return <> </>
                }
              })}
            </ExportToPPT>}

            {/* {!!statePPT?.length && statePPT?.map((pptData, i) => {
              return (<D3ForceDirectedTree key={i} onLoadScale={0.8} graphLegends={graphLegends} id={`container_ppt_${i}_${props.InsightsElementId}`} type='ppt' data={pptData} color={colorPallet} onLoadClickedIndex={0} pptData={statePPT} pptDownloadIndex={i} />)
            })} */}
            
          </LoaderWrapper>
        </OuterWrapper>
      </ReportOuterWrapper>
    </>
  )
}

export default ReportFactorInterLinkage
