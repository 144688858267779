import { styled } from '@mui/system'
import { Box, Typography } from '@mui/material'
import { MEDIA } from '../../constants'

export const Wrapper = styled(Box)(`
    display: flex;
    justify-content:space-between;
`)

export const Title = styled(Typography)(`
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 33px;
`)

export const ButtonWrapper = styled(Box)(`
    display: flex;
    // padding-bottom:40px;
    padding-bottom:30px;
    & > div{
        // position:relative;
    }
    & button {
        margin-left:15px;
    }
    @media (max-width: ${MEDIA.lg}) {
        padding-bottom:20px;
    }
`)
