import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import { exportToPPT_tableCss, tableCss, ulLiCss } from '../../../../util'
import { MEDIA } from '../../../../constants'

export const OuterWrapper = styled(Box)(`
    // padding:20px 30px 50px 30px;
    // width:100%;
    // @media (max-width: ${MEDIA.lg}) {
    //     padding:20px 20px 40px 20px;
    // }
`)

export const InnerWrapper = styled(Box)(`
    display:flex; flex-direction: column; width:100%; position: relative;
    &.exportToPPT {
        * {font-size:14px;}
    }
    ${exportToPPT_tableCss}
`)

export const ContentWrapper = styled(Box)(`
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    color: #000000;
    
    & b, & strong {
        font-weight:500;
    }
    & p {
        padding-bottom:10px;
    }
    & hr {
        display:none;
    }
    & ul, & ol {
        list-style-position: outside;
        margin-left: 20px;
    }
    & ul li, & ol li {
        padding-bottom:10px;
    }
    & ul li ul, 
    & ol li ul, 
    & ul li ol, 
    & ol li ol {
        margin-top:5px;
    }
    & li.ql-indent-1 {
        margin-left: 30px;
        list-style: circle;
    }
    & li.ql-indent-2 {
        margin-left: 60px;
        list-style: circle;
    }

    ${tableCss}
    ${ulLiCss}

    @media (max-width: ${MEDIA.lg}) {
        padding:0;
    }
`)
