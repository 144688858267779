import React from 'react'
import './App.css'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import { theme } from './theme'
import { CssBaseline } from '@mui/material'
import BackOfficeRouter from './router'

const App = () => {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BackOfficeRouter />
      </ThemeProvider>
    </BrowserRouter>
  )
}

export default App
