import React, { useEffect, useState } from 'react'
import { LoaderWrapper, ReportHeading, D3Pie, ReportOuterWrapper } from '../../../../components'
import ReportsService from '../../../../services/reports.service'
import { behavior } from '../../../../assets/images/icons'
import { useGlobalStore } from '../../../../store'
import { getImageUrl } from '../../../../util'
import { useUpdateLhs } from '../../../../hooks/useUpdateLhs'
import {ROLES_MM_PRIMARY} from '../../../../constants'
import ReportEditCategoryPersona from './reportEditCategoryPersona'

import { 
  ContentWrap, RootWrap, SegmentRowContent, SegmentRow, SegmentWrap, PersonaDescriptionWrapper, PersonaDescription, PersonaList, Divider,
  PropertyListingOutertWrapper, PropertyListingtWrapper, PropertyListingtHeading, PropertyList, Gender, PropertyListingHeading,
  BehaviorListingOutertWrapper, BehaviorListingtWrapper, BehaviorListingtHeading, BehaviorListUl, BehaviorList,
  DriversListingOutertWrapper, DriversListingtWrapper, DriversListingPieChart, DriversListingPie, DriversListingTitle, DriversListingValue, DriversListingtDescription,
  OpportunitiesListingOutertWrapper, OpportunitiesInnerWrapper, OpportunitiesContentWrapper
} from './style'

const ReportCategoryPersona = (props) => {
  const [loading, setLoading] = useState(false)
  const [reportData, setReportData] = useState()
  const [colorData, setColorData] = useState()
  const {user} = useGlobalStore()
  const {updateSubLhsApiLoaded} = useUpdateLhs()

  const getPersonaData = (getLatest=false) => {
    setLoading(true)
    ReportsService.getCategoryPersona({ insightsElementId: props.InsightsElementId, getLatest:getLatest }).then(resp => {
      setReportData(resp)
      // const color = (['#095BBD', '#55AAB3', '#7591CD', '#B17CCF', '#AF717E'].includes(resp?.PersonaColor)) ? resp?.PersonaColor : '#095BBD'
      const color = resp?.PersonaColor
      setColorData(color)
      updateSubLhsApiLoaded(props)
    }).catch(error => (
      console.log(error)
    )).finally(() => {
      setLoading(false)
    })
  }

  useEffect(() => {
    getPersonaData()
  }, [])

  const onSuccessEdit = () => {
    getPersonaData(true)
  }

  const CategoryPersonaEditButton = () => {
    return (
        <>{ROLES_MM_PRIMARY.includes(user?.UserType) && reportData?.PersonaDescription && <ReportEditCategoryPersona InsightsElementId={props.InsightsElementId} pageData={reportData} onSuccess={onSuccessEdit} title={props.Title} />}</>
    )
  }

  return (
    <>
      <ReportHeading downloadId={props.type} {...props} additionalMiddleButton={<CategoryPersonaEditButton />} excel={false} />
      <ReportOuterWrapper>
        <RootWrap id={props.type} className='personaSection1'>
          <LoaderWrapper loading={loading} loadOnlyApi={props.loadOnlyApi}>
            <ContentWrap>

              {reportData && <SegmentWrap className={'segmentWrap'}>
                <SegmentRow data-segment={''} segmenttop={145}>
                  <SegmentRowContent>
                    <PersonaDescriptionWrapper className='personaDescription'>
                      <Gender src={getImageUrl('categoryPersona', reportData?.PersonaPicture, 'svg')} alt={reportData?.PersonaName} />
                      <PersonaDescription dangerouslySetInnerHTML={{ __html:reportData?.PersonaDescription }} />
                    </PersonaDescriptionWrapper>
                    <Divider />
                    <PersonaDescription className='personaListWrapper'>
                      {reportData?.CategorySkew && <PersonaList>Category Skew: <b>{reportData?.CategorySkew}</b></PersonaList>}
                      {reportData?.BrandSkew && <PersonaList>Brand Skew: <b>{reportData?.BrandSkew}</b></PersonaList>}
                    </PersonaDescription>
                    {reportData?.PersonaProperties && <>
                      <Divider />
                      <PropertyListing color={colorData} data={reportData?.PersonaProperties} heading={reportData?.PersonaPropertiesHeading} />
                    </>}
                  </SegmentRowContent>
                </SegmentRow>
                <SegmentRow data-segment={reportData?.PersonaBehaviorsHeading} sx={{padding:'0 !important'}}>
                  <SegmentRowContent>
                    <BehaviorListing color={colorData} data={reportData?.PersonaBehaviors} />
                  </SegmentRowContent>
                </SegmentRow>
                <SegmentRow data-segment={'Category Drivers'} sx={{padding:'0 !important'}}>
                  <SegmentRowContent>
                    <DriversListing color={colorData} data={reportData?.PersonaDrivers} heading={reportData?.PersonaBehaviorsHeading} />
                  </SegmentRowContent>
                </SegmentRow>
                <SegmentRow data-segment={'Opportunities'} className='opportunities'>
                  <SegmentRowContent>
                    <OpportunitiesListing color={colorData} data={reportData?.PersonaOpportunities} heading={reportData?.PersonaBehaviorsHeading} />
                  </SegmentRowContent>
                </SegmentRow>
              </SegmentWrap>}

            </ContentWrap>
          </LoaderWrapper>
        </RootWrap>

        <RootWrap id={`${props.type}__recommendation`} className='personaSection2'>
          <OpportunitiesListing color={colorData} data={reportData?.PersonaOpportunities} heading={reportData?.PersonaBehaviorsHeading} />
        </RootWrap>
      </ReportOuterWrapper>
    </>
  )
}

export default ReportCategoryPersona

const PropertyListing = ({ color, data, heading }) => {
  return (
    <PropertyListingOutertWrapper>
      <PropertyListingHeading className='PropertyListingHeading'>{heading}</PropertyListingHeading>
      {data.map((persona1, i) => {
        return (
          <PropertyListingtWrapper className='PropertyList' key={i}>
            <PropertyListingtHeading color={color}>{persona1.PersonaPropertyName}</PropertyListingtHeading>
            {persona1.PersonaPropertyValuesHighlighted.PersonaPropertyValueHighlighted.map((persona2, j) => {
              return (<PropertyList key={j} color={color} selected={persona2.PersonaPropertyHighlighted}>{persona2.PersonaPropertyValue}</PropertyList>)
            })}
          </PropertyListingtWrapper>
        )
      })}
    </PropertyListingOutertWrapper>
  )
}

const BehaviorListing = ({ color, data }) => {

  return (
    <BehaviorListingOutertWrapper>
      {data?.map((persona1, i) => {
        const attributes = Array.isArray(persona1?.PersonaBehaviorAttributes?.string) ? persona1?.PersonaBehaviorAttributes?.string : [persona1?.PersonaBehaviorAttributes?.string]
        return (
          <BehaviorListingtWrapper className='BehaviorListing' key={i}>
            <BehaviorListingtHeading color={color}>
              <span><img src={behavior} alt='' /></span>
              {persona1.PersonaBehaviorName}
            </BehaviorListingtHeading>
            <BehaviorListUl>
              {attributes.map((persona2, j) => {
                return (<BehaviorList key={j}>{persona2}</BehaviorList>)
              })}
            </BehaviorListUl>
          </BehaviorListingtWrapper>
        )
      })}
    </BehaviorListingOutertWrapper>
  )
}

const DriversListing = ({ color, data }) => {
  const donutSettings = {
    width: 100,
    height: 100,
    cx: 1,
    cy: 1,
    innerRadius: 45,
    outerRadius: 49,
    textShow: false,
    colors: [color, '#F7F7F9']
  }

  const Arrow = () => {
    return (
      <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.20217 0.289734L16.8047 8.03414C17.1919 8.42006 16.9854 8.73403 16.3441 8.73403H13.1861C12.9947 8.73403 12.8403 8.85068 12.8403 8.99522L12.8398 14.1216C12.8398 14.6064 12.32 15 11.6779 15H5.32181C4.67873 15 4.15939 14.6064 4.15939 14.1216V8.99488C4.15939 8.85033 4.00451 8.73368 3.81493 8.73368H0.657361C0.0156162 8.73368 -0.191771 8.42005 0.194075 8.0338L7.79799 0.289392C8.18606 -0.0965204 8.81402 -0.0965204 9.20206 0.289728L9.20217 0.289734Z" fill={color}/>
      </svg>
    )
  }

  return (
    <DriversListingOutertWrapper>
      {data?.map((persona1, i) => {
        const piedata = [
          {name: '', value: persona1.PersonaDriverPercent},
          {name: '', value: 100-persona1.PersonaDriverPercent}
        ]
        return (
          <DriversListingtWrapper key={i}>
            <DriversListingPieChart>
              <DriversListingPie><D3Pie data={piedata} settings={donutSettings} /></DriversListingPie>
              <DriversListingTitle>{persona1.PersonaDriverName}</DriversListingTitle>
              <DriversListingValue color={color}>{persona1.PersonaDriverPercent}%</DriversListingValue>
            </DriversListingPieChart>
            {persona1?.PersonaDriverDescription && <DriversListingtDescription color={color}>
              <Arrow />
              {persona1?.PersonaDriverDescription}
            </DriversListingtDescription>}
          </DriversListingtWrapper>
        )
      })}
    </DriversListingOutertWrapper>
  )
}

const OpportunitiesListing = ({ color, data }) => {
  return (
    <OpportunitiesListingOutertWrapper>
      <OpportunitiesInnerWrapper>
        <OpportunitiesContentWrapper color={color} dangerouslySetInnerHTML={{ __html: data }} />
      </OpportunitiesInnerWrapper>
    </OpportunitiesListingOutertWrapper>
  )
}




