import { styled } from '@mui/system'
import { Box, Collapse, IconButton, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { MuiButton } from '../../../components'

export const Wrapper = styled(Grid)(`
        
`)

export const TableHeadWrapper = styled(TableHead)(`
    border-bottom: 2px #eee solid;
    background: transparent;
    padding:20px 0;
    & img {
        padding:0 10px;
    }
`)

export const TableBodyWrapper = styled(TableBody)(`
    background:transparent;
    & .tr_heading{border-bottom: 1px #F1F1F3 solid; cursor: pointer !important;}
    & .tr_heading:hover{background:#F5F5F5;}
    & .tr_true{background:#F5F5F5;}
    & td{text-transform: none !important; font-weight:300;}
    // & tr:hover{background:#F5F5F5;}
`)

export const TableRowContent = styled(TableRow)(`
    background:#F5F5F5;
    & .tableCell{padding-bottom: 0, padding-top: 0}
    & .tr_collapse{padding-top: 15px; padding-bottom: 5px;}
`)

export const TableCellHead = styled(TableCell)(`
    cursor: pointer !important;
    text-transform: none !important;
    font-weight:600 !important;
    font-size:14px !important;
    line-height:21px !important;
`)

export const CollapseContent = styled(Box)( props => `
    margin-bottom:10px;
    font-size:13px;
    font-weight:300;
    display:flex;
    align-items:center;
    color: ${props.type.toLowerCase() === 'completed' ? '#000' : '#7E7E7E'};
`)

export const TableChipCompleted = styled(MuiButton)( props => `
    padding:6px 0;
    margin-right: 5px;
    width:85px;
    text-align:center;
    
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;

    color: #008767;
    background-color: #A0E1D2;
`)

export const TableChipAdminReview = styled(MuiButton)( props => `
    padding:6px 0;
    margin-right: 5px;
    width:85px;
    text-align:center;
    
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;

    color: #429F8A;
    background-color: #D2EBE5;
`)

export const TableChipInProgress = styled(Box)( props => `
    padding:6px 0;
    margin-right: 5px;
    width:85px;
    text-align:center;
    
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;

    color: #C88F16;
    background-color: #FFE3C3;
    cursor:pointer;
`)
