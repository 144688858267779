import React from 'react'
import { LayoutHeaderMenu, TourGuide } from '../../../components'
import { useGlobalStore } from '../../../store'
import { getCompanyDetails, getImageUrl } from '../../../util'
import { Box, IconButton} from '@mui/material'
import { ROLES_CLIENT_SECONDARY } from '../../../constants'
import {
  RhsWrapper,
  DashboardNavbarRoot,
  LogoWrap,
  LHSWrap
} from './style'
import HeaderLogo from './headerLogo'
import Hamburger from './hamburger'

export const Header = () => {
  const { user, company } = useGlobalStore();
  const logoPath = getImageUrl('logos', (company?.CompanyLogo), 'svg')
  // const logoPath = getImageUrl('logos', (getCompanyDetails?.CompanyLogo)?.toLowerCase(), 'svg')
  // const logoPath = `${CONST_CLIENT_LOGOS}/${(getCompanyDetails.CompanyLogo).toLowerCase()}${CONST_CLIENT_LOGOS_EXTENSION}`

  return (
    <DashboardNavbarRoot>
      <LHSWrap>
        <Hamburger />
        <HeaderLogo parentClass='desktopMenu' />
        <LogoWrap className='brandLogo'>{company && logoPath && <img src={logoPath} alt='' />}</LogoWrap>
      </LHSWrap>
      <RhsWrapper>
        <LogoWrap className='brandLogo'>{company && logoPath && <img src={logoPath} alt='' />}</LogoWrap>
        <Box sx={{display:'flex'}}>
          {ROLES_CLIENT_SECONDARY.includes(user.UserType) && <TourGuide type='tutorialButton' />}
          <LayoutHeaderMenu />
        </Box>
      </RhsWrapper>
    </DashboardNavbarRoot>
  )
}
