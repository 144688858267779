const data = [
    {img:'Forbes2020', heading: 'Forbes', title: 'MavenMagnet study report on the top topics of interest at CES 2020...', link: 'https://www.mavenmagnet.com/upload/studies/files/MAVENMAGNET_Forbes_CES2020.pdf'},
    {img:'gulfbusiness', heading: 'Gulf Business', title: 'Motivate MavenMagnet conducted a study to uncover global...', link: 'https://www.mavenmagnet.com/upload/studies/files/MAVENMAGNET_GulfBusiness_Expo2020.pdf'},
    {img:'theeconomictimes100days', heading: 'the economic times', title: '100 days of Modi 2.0', link: 'https://www.mavenmagnet.com/upload/studies/files/MAVENMAGNET_ET_100DaysModi2.pdf'},
    {img:'Campaign', heading: 'Campaign', title: 'CSR, celebrities, and discounts dominate back-to-school buzz', link: 'https://www.mavenmagnet.com/upload/studies/files/MAVENMAGNET_Campaign_Back_to_School.pdf'},
    {img:'forbes_ces', heading: 'Forbes', title: 'topics and trends CMOs were most interested in at CES 2019', link: 'https://www.mavenmagnet.com/upload/studies/files/MAVENMAGNET_Forbes_CES2019.pdf'},
    {img:'theeconomictimesstudy', heading: 'the economic times', title: 'Study of India’s  seven largest airlines reveals that...', link: 'https://www.mavenmagnet.com/upload/studies/files/MAVENMAGNET_ET_UpInTheAir_May2018.pdf'},
    {img:'advertisingage', heading: 'advertising age', title: 'In the article titled "100 Days of Trump Fights"...', link: 'https://www.mavenmagnet.com/upload/studies/files/MAVENMAGNET_AdAge_TrumpImpact_Brands.pdf'},
    {img:'theeconomictimes200days', heading: 'the economic times', title: '200 days of Modi Government', link: 'https://www.mavenmagnet.com/upload/studies/files/MAVENMAGNET_TwoHalf_Year_Modi_Sarkar.pdf'},
    {img:'advertisingweek', heading: 'Advertising Week', title: 'how brands should react to the tense political environment...', link: 'https://www.smartbrief.com/original/2017/09/what-bud-got-right-nike-did-not'},
    {img:'automotivenews', heading: 'automotive news', title: 'Tesla crash and autonomous...', link: 'https://www.mavenmagnet.com/upload/studies/files/MAVENMAGNET_AutoNews_AutonomousCars.pdf'},
    {img:'theeconomictimesstudy2yrs', heading: 'the economic times', title: 'MavenMagnet study at 2 year anniversary of Modi government', link: 'https://www.mavenmagnet.com/upload/studies/files/MAVENMAGNET_Two_Year_Modi_Sarkar.pdf'},
    {img:'theeconomictimesunionbudget', heading: 'the economic times', title: 'An ET-MavenMagnet study analyse India"s budget', link: 'https://www.mavenmagnet.com/upload/studies/files/MAVENMAGNET_ET_UnionBudget2016.pdf'},
    {img:'theeconomictimesoldschool', heading: 'the economic times', title: 'An ET Magazine-MavenMagnet study delves into Indian CEOs...', link: 'https://www.mavenmagnet.com/upload/studies/files/MAVENMAGNET_ET_OldSchool_vs_NewAge.pdf'},
    {img:'theeconomictimesmodisarkar', heading: 'the economic times', title: 'ET Magazine-MavenMagnet study on one year of Modi...', link: 'https://www.mavenmagnet.com/upload/studies/files/MAVENMAGNET_One_Year_Modi_Sarkar.pdf'},
    {img:'theeconomictimesspecialedition', heading: 'the economic times', title: 'An ET Magazine-MavenMagnet study on global Indian women...', link: 'https://www.mavenmagnet.com/upload/studies/files/MAVENMAGNET_ET_GIW_Special_Edition.pdf'},
    {img:'theeconomictimescoverstory', heading: 'the economic times', title: 'To mark 200 days of the Modi Sarkar, ET Magazine...', link: 'https://www.mavenmagnet.com/upload/studies/files/MAVENMAGNET-ET_Modi_200Days_CoverStory.pdf'},
    {img:'mediapost', heading: 'Media Post', title: 'Brand Promise and Luxury', link: 'https://www.mediapost.com/publications/article/229517/delivering-on-the-brand-promise-is-key-in-luxury.html'},
    {img:'deccanherald', heading: 'Deccan Herald', title: 'Waking up to wonders of green tea', link: 'https://www.deccanherald.com/content/328238/waking-up-wonders-green-tea.html'},
    {img:'mediaposthotelroom', heading: 'Media Post', title: 'Most Relevant Hotel Brand', link: 'https://www.mediapost.com/publications/article/193772/hilton-marriott-four-seasons-most-relevant-ho.html#axzz2LM3Sckb9'},
    {img:'hotelonline', heading: 'Hotel Online', title: 'Study reveals most relevant hotel brands', link: 'https://www.hotel-online.com/News/PR2013_1st/Feb13_BrandRelevance.html'},
    {img:'businessstandard', heading: 'Business Standard', title: 'Power of Aspiration', link: 'https://www.business-standard.com/article/management/the-power-of-aspiration-112121000166_1.html'},
    {img:'brandequity', heading: 'Brand Equity', title: 'What makes a brand?', link: 'https://www.mavenmagnet.com/upload/studies/files/MAVENMAGNET_ET_BrandEquity_2012_10_31.pdf'},
    {img:'warc', heading: 'WARC', title: 'Brand image, trust is the key...', link: 'https://www.warc.com/NewsAndOpinion/news/N30584_Brand_image2c_trust_key_in_India/a26417ab-cc7c-4d05-bccc-1b07d27d9998'},
    {img:'TechnologyTrends', heading: '', title: 'Technology Trends in Mid-Size SUV Category: A MavenMagnet Study', link: 'https://www.mavenmagnet.com/blog/technology-trends-in-mid-size-suv-category:-a-mavenmagnet-study'},
    {img:'ACOVID19Timeline', heading: '', title: 'A COVID-19 Timeline: The Impact on Consumers Shopping Basket', link: 'https://www.mavenmagnet.com/blog/a-covid-19-timeline:-the-impact-on-consumers-shopping-basket'},
    {img:'countercategorymyths', heading: '', title: 'Counter Category Myths and Brand Misinformation: A MavenMagnet Case Study', link: 'https://www.mavenmagnet.com/blog/counter-category-myths-and-brand-misinformation:-a-mavenmagnet-case-study'},
    {img:'targetedlistening', heading: '', title: 'Targeted Listening for Actionable Insights', link: 'https://www.mavenmagnet.com/blog/targeted-listening-for-actionable-insights'},
    {img:'ethnographicinsights', heading: '', title: 'Ethnographic Insights from OTT Viewership: A MavenMagnet Case Study', link: 'https://www.mavenmagnet.com/blog/ethnographic-insights-from-ott-viewership-a-mavenmagnet-case-study'},
    {img:'integrateall', heading: '', title: 'Integrate All Data Sources for Rich Insight', link: 'https://www.mavenmagnet.com/blog/integrate-all-data-sources-for-rich-insight'},
    {img:'actionableinsights', heading: '', title: 'Actionable Insights for Market Positioning', link: 'https://www.mavenmagnet.com/blog/actionable-insights-for-market-positioning'},
    {img:'categorycampaign', heading: '', title: 'Category Campaign Audit', link: 'https://www.mavenmagnet.com/blog/category-campaign-audit-with-mavenmagnet'},
    {img:'actionableinsightsoptimize', heading: '', title: 'Actionable Insights to Optimize Communication Strategy', link: 'https://www.mavenmagnet.com/blog/actionable-insights-to-optimize-communication-strategy'},
    {img:'defineandmeasure', heading: '', title: 'Define and Measure Your Brand Purpose', link: 'https://www.mavenmagnet.com/blog/define-and-measure-your-brand-purpose-with-mavenmagnet'},
    {img:'researchwithout', heading: '', title: 'Research Without Transitory Bias', link: 'https://www.mavenmagnet.com/blog/research-without-transitory-bias'},
    {img:'actionableinsightsproduct', heading: '', title: 'Actionable Insights for Product Innovation', link: 'https://www.mavenmagnet.com/blog/actionable-insights-for-product-innovation'},
    {img:'optimizebrand', heading: '', title: 'Optimize Brand Association in Campaigns', link: 'https://www.mavenmagnet.com/blog/optimize-brand-association-in-campaigns'},
    {img:'virtualmarket', heading: '', title: 'Virtual Market Research Using the Digital Ecosystem', link: 'https://www.mavenmagnet.com/blog/virtual-market-research-using-the-digital-ecosystem'},
    {img:'trendscoping', heading: '', title: 'Trend Scoping to Deliver Actionable Insights', link: 'https://www.mavenmagnet.com/blog/trend-scoping-to-deliver-actionable-insights'},
    {img:'extractingthebeauty', heading: '', title: 'Extracting the Beauty from Chaos in Social Media', link: 'https://www.mavenmagnet.com/blog/extracting-the-beauty-from-chaos-in-social-media'},
    {img:'accountingfordark', heading: '', title: 'Accounting for Dark Social', link: 'https://www.mavenmagnet.com/blog/accounting-for-dark-social'},
    {img:'trackyour', heading: '', title: 'Track Your Global Brand Imagery', link: 'https://www.mavenmagnet.com/blog/track-your-global-brand-imagery'},
    {img:'measure', heading: '', title: 'Measure the “Purpose” of Your Brand', link: 'https://www.mavenmagnet.com/blog/measure-the-purpose-of-your-brand'},
    {img:'optimizeyourintegrated', heading: '', title: 'Optimize Your Integrated Campaign', link: 'https://www.mavenmagnet.com/blog/optimize-your-integrated-campaign'},
    {img:'decodebrandequity', heading: '', title: 'Decode Brand Equity Drivers Through Contextual Listening', link: 'https://www.mavenmagnet.com/blog/decode-brand-equity-drivers-through-contextual-listening'},
    {img:'demystify', heading: '', title: 'Demystify Your Target Consumers', link: 'https://www.mavenmagnet.com/blog/demystify-your-target-consumers'},
    {img:'decoding', heading: '', title: 'Decoding Switchers to Create Brand Stickiness', link: 'https://www.mavenmagnet.com/blog/decoding-switchers-to-create-brand-stickiness'},
    {img:'leverage', heading: '', title: 'Leverage the Power of Consumer Moments', link: 'https://www.mavenmagnet.com/blog/leverage-the-power-of-consumer-moments'},
    {img:'raiseproduct', heading: '', title: 'Raise Product Rating To Drive Sales', link: 'https://www.mavenmagnet.com/blog/raise--product-rating-to-drive-sales'},
    {img:'optimizecustomerservice', heading: '', title: 'Optimize Customer Service to Build Brand Reputation', link: 'https://www.mavenmagnet.com/blog/optimize-customer-service-to-build-brand-reputation'},
    {img:'talkinthevoice', heading: '', title: 'Talk in the Voice of Consumers', link: 'https://www.mavenmagnet.com/blog/talk-in-the-voice-of-consumers'},
    {img:'decodingcustomer', heading: '', title: 'Decoding Customer Journey from Consideration to Advocacy', link: 'https://www.mavenmagnet.com/blog/decoding-customer-journey-from-consideration-to-advocacy'},
    {img:'mitigating', heading: '', title: 'Mitigating Sampling Pitfalls', link: 'https://www.mavenmagnet.com/blog/mitigating-sampling-pitfalls'},
    {img:'emotionalovertones', heading: '', title: 'Emotional Overtones Uncover True Insights', link: 'https://www.mavenmagnet.com/blog/emotional-overtones-uncover-true-insights'},
    {img:'sociallistening', heading: '', title: 'Social Listening is in the Stone Age', link: 'https://www.mavenmagnet.com/blog/social-listening-is-in-the-stone-age'},
    {img:'privacyandunleashing', heading: '', title: 'Privacy and Unleashing the Power of Digital for Research', link: 'https://www.mavenmagnet.com/blog/privacy-and-unleashing-the-power-of-digital-for-research'},
    {img:'disruptingandreinventing', heading: '', title: 'Disrupting and Reinventing Market Research', link: 'https://www.mavenmagnet.com/blog/disrupting-and-reinventing-market-research'},
    {img:'quantifying', heading: '', title: 'Quantifying the Power of Your Brand', link: 'https://www.mavenmagnet.com/blog/quantifying-the-power-of-your-brand'},
    {img:'findinginsights', heading: '', title: 'Finding Insights In Millions Of Conversations', link: 'https://www.mavenmagnet.com/blog/finding-insights-in-millions-of-conversations'},
    {img:'mavenmagnetusessocial', heading: '', title: 'MavenMagnet Uses Social Media As Advertisement Development Canvas', link: 'https://www.mavenmagnet.com/blog/mavenmagnet-uses-social-media-as-advertisement-development-canvas'},
    {img:'socialmedia', heading: '', title: 'Social Media and Lodging a Complaint', link: 'https://www.mavenmagnet.com/blog/social-media-and-lodging-a-complaint'},
    {img:'emotions', heading: '', title: 'Emotions and MavenMagnet Insights', link: 'https://www.mavenmagnet.com/blog/emotions-and-mavenmagnet-insights'},
    {img:'approachtomarket', heading: '', title: 'MavenMagnet’s Approach to Market Research Sampling', link: 'https://www.mavenmagnet.com/blog/mavenmagnet-approach-to-market-research-sampling'},
    {img:'incentivizing', heading: '', title: 'Incentivizing Mavens in the Social World', link: 'https://www.mavenmagnet.com/blog/incentivizing-mavens-in-the-social-world'},
    {img:'puttheissue', heading: '', title: 'Put The Issue At The Center', link: 'https://www.mavenmagnet.com/blog/put-the-issue-at-the-center'},
    {img:'permission', heading: '', title: 'Permission Marketing In The Social World', link: 'https://www.mavenmagnet.com/blog/permission-marketing-in-the-social-world'},
    {img:'leveraging', heading: '', title: 'Leveraging Social Media for Public Relations', link: 'https://www.mavenmagnet.com/blog/leveraging-social-media-for-public-relations'},
    {img:'socialmediatracking', heading: '', title: 'Social Media Tracking. MavenMagnet Makes It About Rich Insights', link: 'https://www.mavenmagnet.com/blog/social-media-tracking-mavenmagnet-makes-it-about-rich-insights'},
    {img:'watercooler', heading: '', title: 'Social Media is The New Watercooler', link: 'https://www.mavenmagnet.com/blog/social-media-is-the-new-watercooler'},
    {img:'helpsyou', heading: '', title: 'MavenMagnet Helps You be a Part of Your Social World', link: 'https://www.mavenmagnet.com/blog/mavenmagnet-helps-you-be-a-part-of-your-social-world'},
    {img:'communitiesasadvertisement', heading: '', title: 'Communities As Advertisement Design Canvas', link: 'https://www.mavenmagnet.com/blog/communities-as-advertisement-design-canvas'}
]
export { data };