import { styled } from '@mui/material/styles'
import { Box, Typography, DialogContent } from '@mui/material'

export const DialogContentWrapper = styled(DialogContent)(`
  display: flex;
  align-items: center;
  flex-direction: column;
`)

export const Percentage = styled(Box)(`
  padding-top: 5px;
  width:100%;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color:#000;
`)

export const Msg = styled(Typography)(`
  margin-top: 15px;
  text-align: center;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  min-width: 200px;
  text-wrap: nowrap;
`)


