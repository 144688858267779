import { styled } from '@mui/system'
import { Button as Btn } from '@mui/material'
import { Link as Lnk } from 'react-router-dom'
import { MEDIA } from '../../constants'

export const Button = styled(Btn)((props) => `
  box-sizing: border-box;
  ${(props?.color === 'secondary' && props?.variant === 'outlined') && `color: ${props?.theme?.palette?.secondary?.color}`};
  ${(props?.color === 'light') && `color: #000`};
  ${(props?.color === 'light') && `outline: 1px solid #E7E7E7`};
  ${props?.minwidth === 'true' && 'min-width: 140px'};
  ${props?.fullwidth === 'true' && 'min-width: 100%'};
  
  @media (max-width: ${MEDIA.lg}) {
    // line-height:1;
    min-width:auto;
  }
`)

export const Link = styled(Lnk)(`
  text-decoration: none;
  & button {
    text-decoration: none;
  }
`)
