import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { goTop } from '../../../../../assets/images/icons'
import { MuiImage } from '../../../../../components'
import { SectionRowWrap } from './style'

const SectionRow = ({title}) => {

  const goToTop = () => {
    const element = document.getElementById('campaign-header-row');
    if(!element) return; 
    element.scrollIntoView({behavior: "smooth"});
  }

  return (
    <SectionRowWrap className='campaignTriggersRow headerWrap'>
      <Typography className='headerTitle pptSubHeading'>{title}</Typography>
      <Button className="iconWrap" onClick={goToTop}>
        <Typography className='rightLink'>Back to overview</Typography>
        <MuiImage src={goTop} width={25}/>
      </Button>
    </SectionRowWrap>
  )
}

export default SectionRow