import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'

export const Wrapper = styled(Box)(`
  margin: 0 auto;
  display: flex;
  text-align: center;
  box-sizing: border-box;
  position:relative;
  padding:0 !important;

  & .tooltip {
    position: absolute;
    pointer-events: none;
    width:200px;
    display:block;
    text-align:center;
  }

  & .tooltip.hidden {
    display: none;
  }

  & .tooltip p {
    padding: 5px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
    margin: 0;
    font-size: 14px;
    line-height: 1;
    text-transform: capitalize;
    white-space: nowrap;
    width:auto;
    display:inline;
  }
`)

export const SvgWrapper = styled(Box)(props => `
  width: 100%;
  display: flex;
`)
