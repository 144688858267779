import { Box } from '@mui/material'
import React, {  useState } from 'react'
import { BlockingLoader, Modal, MuiButton, MuiCheckbox, MuiDatePicker, MuiSelect, MuiTextField } from '../../../../components'
import { SENTIMENTS } from '../../../../constants'
import { AttributeName, BrandCategoryName, DriverName, SubAttributeName, TriggerName } from '../dropdowns'
import { ModalContentWrap } from './style'

const AddImageVideo = ({ onClose, insightsElementId, onSubmit }) => {
  const [state, setState] = useState({})
  const [errors, setErrors] = useState({})
  const [isLoading, setLoading] = useState(false)

  const validatePayload = (obj) => {
    const errorObj = {};
    if(!obj.entityName) errorObj.entityName = "Entity Name is a required field";
    if(!obj.driverName) errorObj.driverName = "Driver Name is a required field";
    if(!obj.sentiment) errorObj.sentiment = "Sentiment is a required field";
    if(!obj.verbatimDate) errorObj.verbatimDate = "Verbatim Date is a required field";
    if(!obj.verbatimText && !obj.verbatimMediaPrivateName) errorObj.verbatimText = "Either Image / Video Private Name or Verbatim Text is required";
    // if(!obj.verbatimAdditionalInfo) errorObj.verbatimAdditionalInfo = "Additional Info is required field";
    setErrors(errorObj);
    return Object.keys(errorObj)?.length;
  }

  const inputChangeHandler = (name, value) => {
    setState(prev => {
      const obj = {
        ...prev,
        [name]: value,
      }

      if (name === 'entityName') {
        obj.attributeName = ""
        obj.driverName = ""
        obj.subAttributeName = ""
      }
      if (name === 'driverName') {
        obj.attributeName = ""
        obj.subAttributeName = ""
      }
      if (name === 'attributeName') {
        obj.subAttributeName = ""
      }

      return obj;
    })

    setErrors(prev => {
      const obj = {
        ...prev
      }
      if(obj[name]) {
        delete obj[name];
      }
      return obj;
    })
  }

  const closeHandler = () => {
    onClose()
    setLoading(false);
  }

  const onSubmitHanlder = () => {
    const obj = {
      insightsElementId: insightsElementId,
      entityName: state.entityName || "",
      driverName: state.driverName || "",
      attributeName: state.attributeName || "",
      subAttributeName: state.subAttributeName || "",
      triggerName: state.triggerName || "",
      sentiment: state.sentiment || "",
      verbatimDate: state?.verbatimDate || null,
      verbatimMediaPrivateName: state.verbatimMediaPrivateName || "",
      // verbatimText: state.verbatimText || "",
      verbatimText: encodeURIComponent(state.verbatimText) || "", // Hindi Text fixing
      verbatimAdditionalInfo: state?.verbatimAdditionalInfo || ""
    }

    const hasError = validatePayload(obj);
    if(hasError) return;

    setLoading(true);
    onSubmit(obj, true, closeHandler);
  }

  return (
    <>
      <Modal title="Add Verbatim" showClose onClose={onClose}>
        {isLoading && <BlockingLoader />}
        <ModalContentWrap sx={{ maxWidth: 800 }}>
          <Box className="filterItemWrap">
            <BrandCategoryName
              value={state?.entityName}
              name="entityName"
              onChange={inputChangeHandler}
              insightsElementId={insightsElementId}
              isShadow
              error={errors.entityName}
              title='Brand/Category'
            />
          </Box>
          <Box className="filterItemWrap">
            <MuiSelect
              name="sentiment"
              onChange={(e) => inputChangeHandler("sentiment", e.target.value)}
              title="Sentiment"
              data={SENTIMENTS}
              value={state?.sentiment || undefined}
              variant="outlined"
              placeholder="Select"
              compact
              selectlabel="label"
              selectvalue="value"
              error={errors.sentiment}
            />
          </Box>
          <Box className="filterItemWrap">
            <DriverName
              value={state?.driverName}
              name="driverName"
              entityName={state?.entityName}
              onChange={inputChangeHandler}
              insightsElementId={insightsElementId}
              isShadow
              title='Driver'
              error={errors.driverName}
            />
          </Box>
          <Box className="filterItemWrap">
            <AttributeName
              value={state?.attributeName}
              name="attributeName"
              entityName={state?.entityName}
              driverName={state?.driverName}
              onChange={inputChangeHandler}
              insightsElementId={insightsElementId}
              isShadow
              title='Attribute'
            />
          </Box>
          <Box className="filterItemWrap">
            <SubAttributeName
              value={state?.subAttributeName}
              name="subAttributeName"
              entityName={state?.entityName}
              driverName={state?.driverName}
              attributeName={state?.attributeName}
              onChange={inputChangeHandler}
              insightsElementId={insightsElementId}
              isShadow
              title='Sub Attribute'
            />
          </Box>
          <Box className="filterItemWrap">
            <TriggerName
              name="triggerName"
              insightsElementId={insightsElementId}
              onChange={(name, value) => inputChangeHandler(name, value)}
              value={state?.triggerName || undefined}
            />
          </Box>
          <Box className="filterItemWrap">
            <MuiTextField
              compact
              shadow
              onChange={(e) => inputChangeHandler("verbatimAdditionalInfo", e.target.value)}
              title='Additional Info '
              name='verbatimAdditionalInfo'
              placeholder="Enter"
              error={errors.verbatimAdditionalInfo}
              helperText={errors.verbatimAdditionalInfo}
            />
          </Box>
          <Box className="filterItemWrap">
            <MuiDatePicker
              name="verbatimDate"
              value={state?.verbatimDate || undefined}
              onChange={(value) => inputChangeHandler("verbatimDate", value.toISOString())}
              placeholder='Select'
              label="Date"
              error={errors.verbatimDate}
            />
          </Box>
          <Box sx={{
            width: "100%"
          }}>
            <MuiTextField
              compact
              shadow
              value={state?.verbatimMediaPrivateName || ""}
              onChange={(e) => inputChangeHandler("verbatimMediaPrivateName", e.target.value)}
              title='Image / Video Private Name'
              name='verbatimMediaPrivateName'
              placeholder="Enter"
              error={errors.verbatimText}
              helperText={errors.verbatimText}
            />
          </Box>
          <Box sx={{
            width: "100%"
          }}>
            <MuiTextField
              shadow
              compact
              onChange={(e) => inputChangeHandler("verbatimText", e.target.value)}
              title='Verbatim'
              value={state?.verbatimText}
              name='verbatimText'
              inputType="area"
              placeholder="Verbatim"
            />
          </Box>
          <Box sx={{
            width: "100%",
            marginLeft: "2px"
          }}>
            <MuiCheckbox 
              disabled={true} 
              checked 
              // onChange={(e) => inputChangeHandler("addInsightsAssistant", e.target.checked)} 
              label='Add to Insights Assistant' 
            />
          </Box>
          <MuiButton
            onClick={onSubmitHanlder}
            sx={{
              minWidth: "220px",
              height: 40,
              marginTop: "10px",
            }} color='primary' variant='contained'>Save</MuiButton>
        </ModalContentWrap>
      </Modal>
    </>
  )
}

export default AddImageVideo