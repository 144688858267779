import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import { exportToPPT_tableCss } from '../../../../util'

const distance = '161'

export const RootWrap = styled(Box)(`
	// border: 1px red solid;
	&.exportToPPT {padding:0;}
	&.exportToPPT > div{box-shadow:none}
	&.exportToPPT .list > div {overflow:hidden;}
	${ exportToPPT_tableCss }
	
	&.exportToPPT {
		& .brandCategory {border: 0;}
		& .brandCategory > div {padding: 0;}
		& .tabWrapper {display:none;}
		
        & .segmentOfSegmentOverviewRow:before { 
			position: relative;
			left: 0;
			border: 0;
			width: 100%;
			padding: 0 0 5px 0;
		}
        & .segmentOfSegmentOverviewRow { 
			border: 0;
		}
	}

    &.exportToPPTProgression {
        & .segmentOfSegmentOverviewRowProgression { background: #fff !important; box-shadow: none; padding-bottom:2px; }
        & .segmentOfSegmentOverviewRowProgression > div { padding:0; height: auto; line-height: 1; }
        & .segmentOfSegmentOverviewRowProgression .graphRow { padding-bottom:0; }
        & .segmentOfSegmentOverviewRowProgression .heading { padding-bottom:2px; }
	}

`)

export const ContentWrap = styled(Box)(`
	background: #fff;
	box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
	margin-left: ${distance}px;
	border-radius: 2px;
	width: calc(100% - ${distance}px);
`)

export const HeaderWrap = styled(Box)(`
	display: flex;
	align-items: center;
	width: 100%;
`)

export const HeaderTab = styled(Box)(`
    display: flex;
	align-items: center;
	background: #F7F7F9;
	padding: 6px 10px;
	overflow: hidden;
	width: 100%;
	& .tab {
		padding: 5px 10px;
		font-size: 12px;
		font-weight: 300;
		margin-right: 2px;
		cursor: pointer;
		&.active {
			background: #fff;
			font-weight: 500;
		}
	}
`)

export const SegmentWrap = styled(Box)(`
	width: 100%;
`)

export const SegmentRow = styled(Box)(`
	font-size: 14px;
	font-weight: 300;
	margin-bottom: 5px;
	border: 1px solid #EEEEEE;
	border-left: 0;
	border-radius: 0 2px 2px 0;
	padding: 5px;
	position: relative;
	// min-height: 60px;
	display:flex;
	flex-direction: column;
	justify-content: center;
	&:before {
		content: attr(data-age);
		position: absolute;
		left: -${distance}px;
		width: ${distance}px;
		height: calc(100% + 2px);
		display: flex;
		align-items: center;
		border: 1px solid #EEEEEE;
		border-right: 0;
		padding: 5px 6px;
		line-height:13px;
		top: -1px;
		border-radius: 2px 0px 0px 2px;
	}
	& .outerText{
		font-size: 12px;
		margin-left: 6px;
		font-weight: 300;
	}
	& ul.barRoot { background: #f1f1f1;  }
`)

export const SegmentRowContent = styled(Box)(`
	display: flex;
	align-items: center;
	margin: 0;
	position: relative;
`);

export const BarWrap = styled(Box)(`
	width: 100%;
	// & .barRoot, .barItemRoot, .barSliceWrap, .barSlice {
	// 	height: 30px !important;
	// }
	& .barSlicePercentage{
		font-weight: 300;
		font-size: 14px;
	}
`);

export const IssuesWrap = styled(Box)(`
	position: absolute;
	left: calc(100% + 5px);
	display: flex;
	align-items: center;
	& .radio {
		width: 10px;
		height: 10px;
		min-width: 10px;
		min-height: 10px;
		border: 1px solid #7E7E7E;
		margin-right: 4px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		& .box {
			width: 4.5px;
			height: 4.5px;
			min-width: 4.5px;
			min-height: 4.5px;
			background: #7E7E7E;
			border-radius: 50%;
		}
	}
	& .radio-text {
		font-size: 12px;
		font-weight: 300;
		white-space: nowrap;
	}
`);

export const LegendWrap = styled(Box)(`
	width: 100%;
	display: flex;
	justify-content: end;
	padding: 5px 5px 10px 5px;
`);

export const TabSection1 = styled(Box)(`
    display: flex;
    width: 100%;
`)

export const TabSection2 = styled(Box)(`
    display: flex;
    width: 100%;
    flex-direction:column;
`)

export const ProgressionFiltersWrapper = styled(Box)(`
    display: flex;
    width: 100%;
    margin-bottom:10px;

    & button:hover {
        background-color:transparent !important;
    }
`)

export const BrandHealthSection = styled(Box)(`
	display: flex;
	border-radius: 2px;
	background: #F7F7F9;
	box-shadow: 0px 0px 5px 0px #D9D9D9;
	flex-direction:column;
	width:100%;
	// width:calc(50% - 8px);
	margin-bottom:14px;
	// &:last-of-type { margin-bottom:0;}
`)

export const HeadingSection = styled(Box)(`
    display: flex;
    height:40px;
    line-height:40px;
    padding:0 10px;
    border-bottom:1px #fff solid;
    color: #000;
    font-size: 14px;
    font-weight: 500;
`)

export const ContentSection = styled(Box)(`
    display: flex;
    flex-direction: column;
    padding:10px;
`)

export const SubHeadingSection = styled(Typography)(`
    font-size: 12px;
    font-weight: 500;
    width:100%;
    padding: 10px 0 0 120px;
`)

export const ChartRow = styled(Box)(`
    display: flex;
    width: 100%;
    padding: 5px 5px 5px 0;
	& .barRoot {max-width: 100%;}
`)

export const RowName = styled(Box)(`
    text-transform: capitalize;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 120px;
    padding-right: 10px;
    font-weight: 300;
    font-size: 14px;
    line-height: 13px;
    text-align:right;
`)
