import React, { useState, useEffect } from 'react'
import { Typography, Radio, IconButton, Box } from '@mui/material'
import { MuiButton, MuiCheckbox, CheckboxWrap, MuiRadio, MuiRadioButton } from '..'
import { ArrowDownBlue, ArrowDownBlack } from '../../assets/images/icons'
import { FilterOuterWrap, FilterExpanded, FilterCollapsed, FilterListOverlay, FilterListWrapper, FilterSearchWrap, FilterHeading, FilterSelectAll, FilterCheckboxWrap, FilterButtonsWrap } from './style'

const ProgressionFilters = ({data, settings, onChange}) => {
  const drivers = data.drivers // Object.keys(data) 
  const brands = data.brands // (drivers.map(d => Object.keys(data[d]) ).flat(1)).filter((value, index, array) => array.indexOf(value) === index)
  const periods = data.periods // (drivers.map(d => Object.keys(data[d]) ).flat(1)).filter((value, index, array) => array.indexOf(value) === index)
  const segments = data.segments // (drivers.map(d => Object.keys(data[d]) ).flat(1)).filter((value, index, array) => array.indexOf(value) === index)
  const [selected, setSelected] = useState({drivers:drivers, brands:brands, periods:periods, segments:segments})
  const [selectAll, setSelectAll] = useState({drivers:true, brands:true, periods:true, segments:true})
  // const [filterExpanded, setFilterExpanded] = useState(true)
  const [showSegments, setShowSegments] = useState(false)
  const [showPeriods, setShowPeriods] = useState(false)
  const [showBrands, setShowBrands] = useState(false)
  const [showDrivers, setShowDrivers] = useState(false)
  const [driversRadioSelectedValue, setDriversRadioSelectedValue] = useState()
  const [searchValue, setSearchValue] = useState({drivers:'', brands:'', periods:'', segments:''})

  const selectAllFunc = (type) => {
    const flag = !selectAll[type]
    let selData = []
    if(flag){ 
      // selData = (type === 'drivers') ? drivers : (type === 'periods') ? periods : brands
      if (type === 'drivers') selData = drivers
      else if (type === 'periods') selData = periods
      else if (type === 'segments') selData = segments
      else if (type === 'brands') selData = brands
    }
    
    setSelected({ ...selected, [type]:selData })
    setSelectAll({ ...selectAll, [type]: flag })
  }

  const clearAllFunc = (type) => {
    setSelected({ ...selected, [type]:[] })
    setSelectAll({ ...selectAll, [type]: false })
    setSearchValue({ ...searchValue, [type]: '' })
    setDriversRadioSelectedValue('')
  }

  const checkboxChangeFunc = (type, str) => {
    const isChecked = selected[type].includes(str)
    let data = []
    if( isChecked ) {
      data = [...selected[type]].filter(d => d !== str)
    } else {
      data = [...selected[type], str]
    }
    setSelected({ ...selected, [type]: data })
  }

  const radioChangeFunc = (type, str) => {
    setDriversRadioSelectedValue(str)
    setSelected({ ...selected, [type]: [str] })
  }

  const showSection = (e, param) => {
    e.stopPropagation()
    setTimeout(() => {
      if (param === 'showPeriods') {
        document.querySelector('body').classList.add(`showPeriods_${!showPeriods}`)
        setShowSegments(false)
        setShowPeriods(!showPeriods)
        setShowBrands(false)
        setShowDrivers(false)
      } else if (param === 'showSegments') {
        document.querySelector('body').classList.add(`showSegments_${!showSegments}`)
        setShowSegments(!showSegments)
        setShowPeriods(false)
        setShowBrands(false)
        setShowDrivers(false)
      } else if (param === 'showBrands') {
        document.querySelector('body').classList.add(`showBrands_${!showBrands}`)
        setShowSegments(false)
        setShowPeriods(false)
        setShowBrands(!showBrands)
        setShowDrivers(false)
      } else if (param === 'showDrivers') {
        document.querySelector('body').classList.add(`showDrivers_${!showDrivers}`)
        setShowSegments(false)
        setShowPeriods(false)
        setShowBrands(false)
        setShowDrivers(!showDrivers)
      } else {
        document.querySelector('body').classList.remove(`show_${param}`)
        setShowSegments(false)
        setShowPeriods(false)
        setShowBrands(false)
        setShowDrivers(false)
      }
    }, 10)
  }

  // const filteringArray = (array) => {
  //   return array.filter(s => !!(checkCase(s).match(searchValue.segments.toLowerCase())))
  // }

  useEffect(() => {
    if(!settings?.driverRadio){
      onChange(selected)
    }
    else if(settings?.driverRadio){
      const sel = {...selected, drivers: []}
      onChange(sel)
    }
  }, [])

  useEffect(() => {
    onChange(selected)
  }, [selected])

  useEffect(() => {
    document.body.onclick = function () {
      if(document.querySelector('.filterOuterWrap') && document.querySelector('.filterOuterWrap .show_true')){
        document.querySelector('.filterOuterWrap .active').click()
      }
    }
  }, [])

  // const toLowerCaseFunc = (str) => {
  //   return str.toString().toLowerCase()
  // }

  const changeCase = (s3) => {
    return s3.toLowerCase()
  }

  const checkCase = (s) => {
    return (s.toString().toLowerCase().replace(/[^a-z0-9 -_]+/ig, ''))
  }
  
  const checkSpecialChar = (s) => {
    return (s.toString().replace(/\\/g, '\\\\').replace(/[^a-z0-9 ]+/ig, ''))
  }
  

  return (
    <FilterOuterWrap className='filterOuterWrap'>

      {/* {
        (
          (segments?.length > 0 && showSegments) ||
          (periods?.length > 0 && showPeriods) ||
          (brands?.length > 0 && showBrands) ||
          (drivers?.length > 0 && showDrivers)
        ) && <FilterListOverlay className='FilterListOverlay' onClick={(e) => showSection(e, 'hideAll')} />
      } */}

      <FilterExpanded>
        {segments?.length > 0 && <FilterHeading className={showSegments ? 'active' : ''} onClick={(e) => showSection(e, 'showSegments')}>Segments<span></span></FilterHeading>}
        {segments?.length > 0 && <><FilterListWrapper className={`show_${showSegments}`} onClick={(e) => e.stopPropagation()}>
          <FilterSearchWrap>
            <MuiCheckbox styling='filterSection' className='selectAll' color='primary' checked={selected.segments.length === segments.length} onChange={() => selectAllFunc('segments')} />
            <input placeholder='Search' type='text' value={searchValue.segments} onChange={(e) => {setSearchValue(prev => ({ ...prev, segments: checkSpecialChar(e.target.value) }))}} />
            <MuiButton className='clearAll' onClick={() => {clearAllFunc('segments'); }}>Clear All</MuiButton>
          </FilterSearchWrap>
          <FilterCheckboxWrap>
            {segments.map((s, i) => {
              if(searchValue.segments !== ''){
                if( !(checkCase(s).match(changeCase(searchValue.segments))) ){ return false }
              }
              return (<MuiCheckbox styling='filterSection' color='dark' key={i} label={s} checked={selected.segments.includes(s)} onChange={() => checkboxChangeFunc('segments', s)} />)
            })}
          </FilterCheckboxWrap>
        </FilterListWrapper></>}

        {periods?.length > 0 && <FilterHeading className={showPeriods ? 'active' : ''} onClick={(e) => showSection(e, 'showPeriods')}>Periods<span></span></FilterHeading>}
        {periods?.length > 0 && <><FilterListWrapper className={`show_${showPeriods}`} onClick={(e) => e.stopPropagation()}>
          <FilterSearchWrap>
            <MuiCheckbox styling='filterSection' className='selectAll' color='primary' checked={selected.periods.length === periods.length} onChange={() => selectAllFunc('periods')} />
            <input placeholder='Search' type='text' value={searchValue.periods} onChange={(e) => {setSearchValue(prev => ({ ...prev, periods: checkSpecialChar(e.target.value) }))}} />
            <MuiButton className='clearAll' onClick={() => {clearAllFunc('periods'); }}>Clear All</MuiButton>
          </FilterSearchWrap>
          <FilterCheckboxWrap>
            {/* {periods.filter(s => !!(checkCase(s).match(searchValue.periods.toLowerCase()))).map((s, i) => { */}
            {periods.map((s, i) => {
              if(searchValue.periods !== ''){
                if( !(checkCase(s).match(changeCase(searchValue.periods))) ){ return false }
              }
              return (<MuiCheckbox styling='filterSection' color='dark' key={i} label={s} checked={selected.periods.includes(s)} onChange={() => checkboxChangeFunc('periods', s)} />)
            })}
          </FilterCheckboxWrap>
        </FilterListWrapper></>}

        {brands?.length > 0 && <FilterHeading className={showBrands ? 'active' : ''} onClick={(e) => showSection(e, 'showBrands')}>Brands<span></span></FilterHeading>}
        {brands?.length > 0 && <><FilterListWrapper className={`show_${showBrands}`} onClick={(e) => e.stopPropagation()}>
          <FilterSearchWrap>
            <MuiCheckbox styling='filterSection' className='selectAll' color='primary' checked={selected.brands.length === brands.length} onChange={() => selectAllFunc('brands')} />
            <input placeholder='Search' type='text' value={searchValue.brands} onChange={(e) => {setSearchValue(prev => ({ ...prev, brands: checkSpecialChar(e.target.value) }))}} />
            <MuiButton className='clearAll' onClick={() => {clearAllFunc('brands'); }}>Clear All</MuiButton>
          </FilterSearchWrap>
          <FilterCheckboxWrap>
            {/* {brands.filter(s => !!(checkCase(s).match(searchValue.brands.toLowerCase()))).map((s, i) => { */}
            {brands.map((s, i) => {
              if(searchValue.brands !== ''){
                if( !(checkCase(s).match(changeCase(searchValue.brands))) ){ return false }
              }
              return (<MuiCheckbox styling='filterSection' color='dark' key={i} label={s} checked={selected.brands.includes(s)} onChange={() => checkboxChangeFunc('brands', s)} />)
            })}
          </FilterCheckboxWrap>
        </FilterListWrapper></>}

        {drivers?.length > 0 && <FilterHeading className={showDrivers ? 'active' : ''} onClick={(e) => showSection(e, 'showDrivers')}>Drivers<span></span></FilterHeading>}

        {!settings?.driverRadio && drivers?.length > 0 && <><FilterListWrapper className={`show_${showDrivers}`} onClick={(e) => e.stopPropagation()}>
          <FilterSearchWrap>
            <MuiCheckbox styling='filterSection' className='selectAll' color='primary' checked={selected.drivers.length === drivers.length} onChange={() => selectAllFunc('drivers')} />
            <input placeholder='Search' type='text' value={searchValue.drivers} onChange={(e) => {setSearchValue(prev => ({ ...prev, drivers: checkSpecialChar(e.target.value) }))}} />
            <MuiButton className='clearAll' onClick={() => {clearAllFunc('drivers'); }}>Clear All</MuiButton>
          </FilterSearchWrap>
          <FilterCheckboxWrap>
            {/* {drivers.filter(s => !!(checkCase(s).match(searchValue.drivers.toLowerCase()))).map((s, i) => { */}
            {drivers.map((s, i) => {
              if(searchValue.drivers !== ''){
                if( !(checkCase(s).match(changeCase(searchValue.drivers))) ){ return false }
              }
              return (<MuiCheckbox styling='filterSection' color='dark' key={i} label={s} checked={selected.drivers.includes(s)} onChange={() => checkboxChangeFunc('drivers', s)} />)
            })}
          </FilterCheckboxWrap>
        </FilterListWrapper></>}

        {settings?.driverRadio && drivers?.length > 0 && <><FilterListWrapper className={`show_${showDrivers}`} onClick={(e) => e.stopPropagation()}>
          <FilterSearchWrap>
            <input style={{marginLeft: '52px'}} placeholder='Search' type='text' value={searchValue.drivers} onChange={(e) => {setSearchValue(prev => ({ ...prev, drivers: checkSpecialChar(e.target.value) }))}} />
            <MuiButton className='clearAll' onClick={() => {clearAllFunc('drivers'); }}>Clear All</MuiButton>
          </FilterSearchWrap>
          <FilterCheckboxWrap className='radioWrapper'>
            {/* {drivers.filter(s => !!(checkCase(s).match(searchValue.drivers.toLowerCase()))).map((s, i) => { */}
            {drivers.map((s, i) => {
              if(searchValue.drivers !== ''){
                if( !(checkCase(s).match(changeCase(searchValue.drivers))) ){ return false }
              }
              return (<MuiRadioButton checked={driversRadioSelectedValue === s} onChange={(e) => radioChangeFunc('drivers', s)} key={i} value={s} name="drivers" title={s} />)
            })}
          </FilterCheckboxWrap>
        </FilterListWrapper></>}

      </FilterExpanded>
    </FilterOuterWrap>
  )
}

const ArrowUpDownBlack = ({direction=true}) => {
  return (direction===true) ? <div style={{transform:'translate(0, 0px)'}}><ArrowDownBlack /></div> : <div style={{transform:'translate(9px, 2px) rotate(180deg)'}}><ArrowDownBlack /></div>
}

export default ProgressionFilters