import React from 'react'
import { InfoWrapper, StyledTypography } from './style';

const InfoRow = () => {

  return (
    <InfoWrapper className='infoRow'>
        <StyledTypography>
            <b>Engagement</b>
            <span>is measured by the number of discussions consumer have on a particular site</span>
        </StyledTypography>
        <StyledTypography>
            <b>Reach</b>
            <span>is defined by the number of consumers exposed to posts on a particular site. It is measured by number of consumer interactions in the form of post views, video views, sharing posts, likes, etc.
            </span>
        </StyledTypography>
    </InfoWrapper>
  )
}


export default InfoRow;