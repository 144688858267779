import styled from '@emotion/styled'
import { Box, Typography } from '@mui/material'
import MuiButton from '../muiButton'
import { LEGEND_TEXT_COLOR_MAP } from '../../constants'

export const VerbatimWrapper = styled(Box)(`
    margin-top:10px;
    width:100% !important;
    padding:0 !important;
    background: #FFFFFF;
    box-shadow: 0px 0px 5px #D9D9D9;
    border-radius: 2px;
`)

export const Wrapper = styled('ul')( props => `
    padding-left:15px;
    width:100%;
    padding: 15px 15px 10px 30px;
    list-style:none;
    background-color:${props.bg}
`)

export const VerbatimImgVidWrapper = styled('div')(`
	column-width: 320px;
	column-gap: 15px;
  width: calc(100% - 40px);
	margin: 20px auto 0;
`)

export const ImgWrapper = styled('div')(`
  border-radius: 8px;
  padding: 10px;
  font-weight: 300;
  font-size: 14px !important;
  line-height: 16px;
  color:#000 !important;

	margin: 0 2px 15px;
  display: inline-block;
  max-width:300px;
  column-break-inside: avoid;
  
  & img { 
    width: 100%; height: auto;
    // border-bottom: 1px solid #ccc;
    // padding-bottom: 15px;
    margin-bottom: 0;
  }

  & span { padding: 10px 0 10px 20px; position: relative; display: block; border-top: 1px solid #F1F1F3; margin-top:10px; overflow-wrap:anywhere; }
  & strong { font-weight: 300; color: #000; margin-left: 8px; font-size: 12px; }

  &.Positive { border: 1px solid #00B050; border-radius: 8px; }
  &.Negative { border: 1px solid #FF0000; border-radius: 8px; }
  &.Mixed { border: 1px solid #353535; border-radius: 8px; }
  
  & span:before { position: absolute; left:2px; top:10px; font-size:14px; }
  &.Positive span:before { content: '+'; color: #00B050; }
  &.Negative span:before { content: '-'; color: #FF0000; }
  &.Mixed span:before { content: '~'; color: #353535; }

`)

export const InputContainer = styled(Box)(`

`);

export const InputWrap = styled(Box)(`
    &.top {
        margin-bottom: 20px;
    }
    & label {
        margin-bottom: 2px;
    }
`);


export const ModalTitle = styled(Typography)(`
  margin-top: 10px;
  padding-bottom: 30px;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  text-align: left;
  color: #000;
`)

export const ModalWrapper = styled(Box)(`
  width:100%;
  max-width: 600px;
  width: 80vw;
`)

export const ModalButton = styled(MuiButton)(`
  float: left;
  margin-left: 0px !important;
  margin-top: 20px !important;
`)
