import { eqs, etron, ix, lyriq, mache, modelY } from "../assets/images-client/logos";
import { magnetScore } from "../assets/images/icons";
import { 
  facebookColor, forumsColor, instagramColor, linkedin, reddittColor, tiktokColor, twitterColor, gitHubColor, youtubeColor, standoutColor,
  forumsColor_standout, instagramColor_standout, facebookColor_standout, tiktokColor_standout, gitHubColor_standout, youtubeColor_standout, linkedin_standout, twitterColor_standout, reddittColor_standout,
} from "../assets/images/icons";

const isProd = !!window.location.href.match('insightsassistant.mavenmagnet.com')

// export const CONST_API_URL = `${process.env.REACT_APP_API_URL}?payload=yes`
export const CONST_API_URL = `${process.env.REACT_APP_API_URL}`
export const CONST_API_JSON_URL = `${isProd ? process.env.REACT_APP_API_JSON_URL_PROD : process.env.REACT_APP_API_JSON_URL_DEV}`

export const CONST_CLIENT_LOGOS = `${process.env.REACT_APP_IMAGES_URL}/logos` //'assets/images-client/logos/'
export const CONST_VERBATIM_PATH = `${process.env.REACT_APP_VERBATIM_URL}/` //'assets/images-client/logos/'
// export const CONST_CLIENT_LOGOS = 'assets/images-client/logos/'
export const CONST_CLIENT_LOGOS_EXTENSION = '.png'
export const CONST_CLIENT_IMAGES = 'assets/images-client/'

export const FREQUENCY = [
  { label: 'Monthly', value: 'Monthly' },
  { label: 'Quarterly', value: 'Quarterly' },
  { label: 'Biannual', value: 'Biannual' },
  { label: 'Annual', value: 'Annual' },
  { label: 'One-time', value: 'Onetime' }
];

export const LEGEND_TEXT_COLOR_MAP = {
  Positive: "#148a49",
  Neutral: "#f5bd4f",
  Negative: "#d42323",
  Mixed: "#353535",
  MixedNegative: "#353535",
  SearchKeywords: '#000',
};

export const GRAPH_COLOR_PALLET1 = [
  '#52B8C1',
  '#FB6C8B',
  '#B264DE',
  '#5C83D7',
  '#98AB5D',
  '#44A1AA',
  '#EE5575',
  '#9F53C9',
  '#4870C5',
  '#85974C',
  '#378E96',
  '#D43E5E',
  '#8A3FB4',
  '#3A5FB0',
  '#758640',
  '#2C7980',
  '#BB2D4C',
  '#6B2990',
  '#284C97',
  '#57652C',
  '#216167',
  '#A1213D',
  '#541B75',
  '#1D3D81',
  '#475323',

  // '#216167',
  // '#A1213D',
  // '#541B75',
  // '#1D3D81',
  // '#475323',
  // '#2C7980',
  // '#BB2D4C',
  // '#6B2990',
  // '#284C97',
  // '#57652C',
  // '#378E96',
  // '#D43E5E',
  // '#8A3FB4',
  // '#3A5FB0',
  // '#758640',
  // '#44A1AA',
  // '#EE5575',
  // '#9F53C9',
  // '#4870C5',
  // '#85974C',
  // '#52B8C1',
  // '#FB6C8B',
  // '#B264DE',
  // '#5C83D7',
  // '#98AB5D'

  // '#216167',
  // '#2C7980',
  // '#378E96',
  // '#44A1AA',
  // '#52B8C1',
  // '#A1213D',
  // '#BB2D4C',
  // '#D43E5E',
  // '#EE5575',
  // '#FB6C8B',
  // '#541B75',
  // '#6B2990',
  // '#8A3FB4',
  // '#9F53C9',
  // '#B264DE',
  // '#1D3D81',
  // '#284C97',
  // '#3A5FB0',
  // '#4870C5',
  // '#5C83D7',
  // '#475323',
  // '#57652C',
  // '#758640',
  // '#85974C',
  // '#98AB5D'

]

export const GRAPH_COLOR_PALLET2 = {
  '0': '#52B8C1',
  '1': '#FB6C8B',
  '2': '#B264DE',
  '3': '#5C83D7',
  '4': '#98AB5D',
  '5': '#44A1AA',
  '6': '#EE5575',
  '7': '#9F53C9',
  '8': '#4870C5',
  '9': '#85974C',
  '10': '#378E96',
  '11': '#D43E5E',
  '12': '#8A3FB4',
  '13': '#3A5FB0',
  '14': '#758640',
  '15': '#2C7980',
  '16': '#BB2D4C',
  '17': '#6B2990',
  '18': '#284C97',
  '19': '#57652C',
  '20': '#216167',
  '21': '#A1213D',
  '22': '#541B75',
  '23': '#1D3D81',
  '24': '#475323'
}

export const GRAPH_COLOR_PALLET3 = [
  '#E0ABFF',
  '#BBE9F8',
  '#FFE1BE',
  // '#F3D9DB',
  '#82C0D3',

  '#069ECE',
  '#FFB876',
  '#FA52D5',
  '#6BDCFF',  
  '#F48927',

  '#97727A',
  '#91AFED',
  '#BC6AED',
  '#4E79D2',
  '#D0AD83'
]


export const LEGEND_COLOR_MAP = {
  Positive: "#00B050",
  Neutral: "#F5BD4F",
  Negative: "#FF0000",
  Mixed: "#A6A6A6",
  Warning: "#D5732E",
  PaidInteractions: "#E64761",
  Interactions: "#5654E4",
  Association: "#4EADEA",
  // Mention: "#EEECE2",
  Mention: "#F5C342",
  NetVibe: '#475467',
  SearchKeywords: '#628BBD',
  WithoutSentiments: '#406CA1',

  Consumers: '#FDC1ED',
  NewsMedia: '#AB8BF1',
  MGPartners: '#B80D92',

  ...GRAPH_COLOR_PALLET2
  
  // '0': '#216167',
  // '1': '#A1213D',
  // '2': '#541B75',
  // '3': '#1D3D81',
  // '4': '#475323',
  // '5': '#2C7980',
  // '6': '#BB2D4C',
  // '7': '#6B2990',
  // '8': '#284C97',
  // '9': '#57652C',
  // '10': '#378E96',
  // '11': '#D43E5E',
  // '12': '#8A3FB4',
  // '13': '#3A5FB0',
  // '14': '#758640',
  // '15': '#44A1AA',
  // '16': '#EE5575',
  // '17': '#9F53C9',
  // '18': '#4870C5',
  // '19': '#85974C',
  // '20': '#52B8C1',
  // '21': '#FB6C8B',
  // '22': '#B264DE',
  // '23': '#5C83D7',
  // '24': '#98AB5D'
};

export const SOCIAL_IMAGE_MAP = {
  forum: forumsColor,
  instagram: instagramColor,
  facebook: facebookColor,
  tiktok: tiktokColor,
  youtube: youtubeColor,
  linkedin: linkedin,
  twitter: twitterColor,
  github: gitHubColor,
  reddit: reddittColor,

  forum_standout: forumsColor_standout,
  instagram_standout: instagramColor_standout,
  facebook_standout: facebookColor_standout,
  tiktok_standout: tiktokColor_standout,
  youtube_standout: youtubeColor_standout,
  linkedin_standout: linkedin_standout,
  twitter_standout: twitterColor_standout,
  github_standout: gitHubColor_standout,
  reddit_standout: reddittColor_standout,

  standout: standoutColor
}


export const FACTOR_LEGEND = {
  BrandPerception: '#C6C6F8',
  DesignandComfort: '#F1D794',
  Functional: '#C7D994',
  Funtional: '#FCA2B5',
  Performance: '#96E5EE',
  Social: '#CF92F2',
  legend_1: '#7591CD',
  legend_2: '#A69059',
  legend_3: '#27278B'
}


export const MAGNET_SCORE_IMAGE = {
  'CELESTIQ': {
    width: 80,
    hegiht: 15,
    icon: lyriq,
  },
  'e-tron': {
    width: 60,
    hegiht: 15,
    icon: etron,
  },
  'EQS': {
    width: 45,
    hegiht: 15,
    icon: eqs,
  },
  'iX': {
    width: 25,
    hegiht: 15,
    icon: ix,
  },
  'Mach-E': {
    width: 88,
    hegiht: 15,
    icon: mache,
  },
  'Model Y': {
    width: 78,
    hegiht: 15,
    icon: modelY,
  },
  'Start': {
    width: 35,
    hegiht: 33,
    icon: magnetScore,
  },
  'Cadillac CELESTIQ': {
    width: 90,
    hegiht: 15
  },
  'Bentley Flying Spur': {
    width: 90,
    hegiht: 15
  },
  'Mercedes Maybach S-Class': {
    width: 95,
    hegiht: 15
  },
  'Rolls Royce Spectre': {
    width: 60,
    hegiht: 15
  }
}


export const USER_TYPE = [
  { label: "Admin", value: "Admin" },
  { label: "Member", value: "Member" },
];

export const INTERNAL_USER_TYPE = [
  { label: "Analyst", value: "Analyst" },
  { label: "Publisher", value: "Publisher" },
]

export const PERMISSION = [
  { label: "Enabled", value: 'enabled' },
  { label: "Disabled", value: 'disabled' },
];

export const EXPORT_PPT = {
  // BrandMagnetScore
  deepDiveDrivers: [
    'SearchDrivers', 'DriversAndSubBrands', 'DriversWithoutSentimentsAndSubBrands', 'DriversAndRatingImpacts', 'Drivers', 'DriversWithoutSentiments', 'DriversAndTriggers', 
    'DriversMotivatorsDetractors','DriversAndSubBrandsMotivatorsDetractors','DriversAndTriggersMotivatorsDetractors','DriversAndRatingImpactsMotivatorsDetractors'
  ],
  deepDiveSegments: [    
    'BrandImageryMappingGraph', 'BrandImageryMappingAndProgressionGraph', // 'CompetitiveLandscape'
    // 'DriversProgression3Level', 'SearchDriversProgression3Level', 'SegmentDriversOnlyAnalysis3Level', 'DriversProgressionWithoutSentiments3Level'
  ],
  deepDiveProgression: [
    'BrandCategoryOverviewAndProgression', 'BrandMagnetScoreAndProgression', 'BrandAttributeMapAndProgression', 'SegmentOfSegmentOverviewAndProgression', 'BrandImageryMappingAndProgressionGraph', // NEW FOR PROGRESSION
  ],
  reportSegment: [
    'CategoryPersona', 'SegmentDriversAnalysis', 'DriversProgression', 'DriversProgressionWithoutSentiments', 'SegmentDriversOnlyAnalysisWithoutSentiments',
    'SearchDriversProgression', 'SegmentDriversOnlyAnalysis', 'Triangulation', 'TriangulationComparisonViewOnly', 'SegmentOfSegmentOverview',
    'DriversProgression3Level', 'SearchDriversProgression3Level', 'SegmentDriversOnlyAnalysis3Level', 'DriversProgressionWithoutSentiments3Level', 'SegmentDriversOnlyAnalysisWithoutSentiments3Level',
    'CampaignTriggers', 'DriversAndSubBrands', 'DriversWithoutSentimentsAndSubBrands', 'DriversAndTriggers', 
    'BrandCategoryOverviewAndProgression', 'BrandMagnetScoreAndProgression', 'BrandAttributeMapAndProgression', 'SegmentOfSegmentOverviewAndProgression', 'BrandImageryMappingAndProgressionGraph', // NEW FOR PROGRESSION
  ],
  deepDiveDriversAppendices: [
    'AppendicesSearchDrivers', 'AppendicesDriversAndRatingImpacts', 'AppendicesDriversAndTriggers', 'AppendicesDriversAndSubBrands', 'AppendicesDriversWithoutSentimentsAndSubBrands', 'AppendicesDrivers', 'AppendicesDriversWithoutSentiments', 'AppendicesDriversAndTriggers', 
    'AppendicesDriversMotivatorsDetractors','AppendicesDriversAndSubBrandsMotivatorsDetractors','AppendicesDriversAndTriggersMotivatorsDetractors','AppendicesDriversAndRatingImpactsMotivatorsDetractors'
  ],
  deepDiveSegmentsAppendices: [
    // 'AppendicesBrandCategoryOverviewAndProgression', 'AppendicesBrandMagnetScoreAndProgression', 'AppendicesBrandAttributeMapAndProgression', 'AppendicesSegmentOfSegmentOverviewAndProgression', 'AppendicesBrandImageryMappingAndProgressionGraph', // NEW FOR PROGRESSION
    'AppendicesBrandImageryMappingGraph', 'AppendicesBrandImageryMappingAndProgressionGraph', 
    // 'AppendicesDriversProgression3Level', 'AppendicesSearchDriversProgression3Level', 'AppendicesSegmentDriversOnlyAnalysis3Level', 'AppendicesDriversProgressionWithoutSentiments3Level'
  ],
  deepDiveSegmentsProgression: [
    'ProgressionBrandCategoryOverviewAndProgression', 'ProgressionBrandMagnetScoreAndProgression', 'ProgressionBrandAttributeMapAndProgression', 'ProgressionSegmentOfSegmentOverviewAndProgression', 'ProgressionBrandImageryMappingAndProgressionGraph', // NEW FOR PROGRESSION
  ]
};

export const SENTIMENTS = [
  {
    label: "",
    value: ""
  },
  {
    label: "Negative",
    value: 'Negative'
  },
  {
    label: "Positive",
    value: "Positive"
  },
  {
    label: 'Mixed',
    value: 'Mixed'
  },
  {
    label: 'Neutral',
    value: 'Neutral'
  }
]

export const VISIVILITY = [ 
  {
    label: "",
    value: ""
  },
  {
    label: "All",
    value: 'All'
  },
  {
    label: "Visible",
    value: "Visible"
  },
  {
    label: 'Invisible',
    value: 'Invisible'
  }
]

export const MEDIA = {
  // xs: '600px',
  // sm: '980px',
  // md: '1000px',
  lg: '1200px',
  lg2: '1300px',
  xl: '1500px',
  // xxl: '1920px'
}

// ${MEDIA.lg}

export const CUSTOM_IMG_SEGMENT_COLOR = [
  "#C5A443",
  "#006400",
  "#ED7D31",
  "#646464",
  "#4472C4"
]