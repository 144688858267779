import React, { useEffect, useState, createRef } from "react"
import { Legend, ReportHeading, SocialLegend, LoaderWrapper, ProgressionWrapper, ProgressionFilters, ReportOuterWrapper } from "../../../../components"
import ReportsService from "../../../../services/reports.service"
import * as d3 from "d3"
import * as fc from "d3fc"
import { GRAPH_COLOR_PALLET3 } from "../../../../constants"
import { useUpdateLhs } from '../../../../hooks/useUpdateLhs'
import InfoRow from "./infoRow"
import ReportEditBrandAttributeMap from './reportEditBrandAttributeMap'
import { ROLES_MM_PRIMARY } from '../../../../constants'
import { getObjectAsArray  } from '../../../../util'
import { useGlobalStore } from '../../../../store'
import { OuterWrapper, InnerWrapper, ContentWrapper, ProgressionFiltersWrapper, ImpactDestinationsWrapper, AppendicesHeading, ProgressionDataNotFoundWrapper, ProgressionDataNotFound, LegendWrapper, IsAppendicesContentWrapper } from "./style"

const ReportBrandAttributeMapElement = (props) => {
  const { InsightsElementType } = props
  const [loading, setLoading] = useState(false)
  const [socialLegend, setSocialLegend] = useState([])
  const [state, setState] = useState([])
  const [colorPallet, setColorPallet] = useState([])
  const [legends, setLegends] = useState([
    { label: "Relatively Stronger than Category", value: "Positive" },
    { label: "At Par with Category", value: "Mixed" },
    { label: "Relatively Weaker than Category", value: "Negative" }
  ])
  const [pageFlag, setPageFlag] = useState()
  const {updateSubLhsApiLoaded} = useUpdateLhs()
  const { isPPTModalActive, user } = useGlobalStore()
  const [eventCount, setEventCount] = useState(1)

	const [progressionDataL1Filtered, setProgressionDataL1Filtered] = useState({periods:[], drivers:[]})
	const [progressionDataSelectedFiltered, setProgressionDataSelectedFiltered] = useState({periods:[], drivers:[]})
  const [configTab, setConfigTab] = useState([])
	const [progressionData, setProgressionData] = useState({})
  const [rows, setRows] = useState([])
  const [tabChanged, setTabChanged] = useState(0)
  const [driverDropdown, setDriverDropdown] = useState([])
  const [graphDetails, setGraphDetails] = useState({AxisCutHorizontal:0, AxisCutVertical:0, AxisNameHorizontal:'', AxisNameVertical:'', range:{x:[0, 10], y:[1, 10]}})

  const isTriangulation = ['TriangulationMapAndProgression', 'TriangulationMapElement'].includes(InsightsElementType)
  const isTriangulationProgression = ['TriangulationMapAndProgression'].includes(InsightsElementType)
  
  let scoreImageryTypeArray = {}
  let scoreImageryTypeArrayL2 = {}
  const filterDataObj = {drivers:[], periods:[]}
  const progressionFiltersSettings = {
    driverRadio: true
  }

  const getColorForSentiments = (sentiment, shade='light') => {
    if(shade === 'light') {
      if(isTriangulation){
        return legends.find(f => f.value === sentiment).colorLight
      } else {
        return sentiment === "Positive" ? 'rgba(0, 176, 80, 0.4)' : sentiment === "Negative" ? 'rgba(255, 0, 0, 0.4)' : 'rgba(166, 166, 166, 0.4)'
      }
    } else if(shade === 'dark') {
      if(isTriangulation){
        return legends.find(f => f.value === sentiment).colorDark
      } else {
        return sentiment === "Positive" ? 'rgba(0, 176, 80, 1)' : sentiment === "Negative" ? 'rgba(255, 0, 0, 1)' : 'rgba(166, 166, 166, 1)'
      }
    }
  }

  const testGraph = (stateData, props, progression, appendicesIndex = -1, selectedDriverName = null) => {
    let id = progression ? `Svg_${props.type}_progression` : `Svg_${props.type}`
    let idWrapper = `Svg_${props.type}_Wrapper`
    const popupId = `Svg_${props.type}_Wrapper__popup`
    if ( props?.isProgressionActive ) {
      id = `Svg_${props.type}_progression____${appendicesIndex}`
      idWrapper = `Svg_${props.type}_Wrapper____${appendicesIndex}`
    }

    if(!document.getElementById(idWrapper)){
      return false
    }

    document.getElementById(idWrapper).removeAttribute('style')
    const wrapper = document.getElementById(idWrapper)

    const idWidth = isPPTModalActive ? 1100 : document.getElementById(id)?.offsetWidth // 1250
    if(!idWidth){ return false }

    const margin = {top: 20, right: 10, bottom: 50, left: 40};
    const width = idWidth;
    const height = 520 - margin.top - margin.bottom;

    document.getElementById(idWrapper).style.width = idWidth+'px'

    // append the svg object to the body of the page
    const svg = d3.select(`#${id}`)
      .html('')
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", `0 0 ${width} ${height+50}`)
      .attr("preserveAspectRatio", "xMinYMin")
      .append("g")
      .attr("transform", `translate(${margin.left}, ${margin.top})`);
  
    var x = d3.scaleLinear().range([0, width]).nice();
    var y = d3.scaleLinear().range([height, 0]).nice();
  
    var xDim = 0, yDim = 1;
  
    var data = stateData.map(d => {
      return {
        ...d,
        label: d.DriverName,
        dims: [d.BuzzVolumeIndex, d.MeanRatingIndex]
      }
    });
  
    var xMax = d3.max(data, function (d) {
        return d.dims[xDim];
    }) * 1.05;
    var xMin = d3.min(data, function (d) {
        return d.dims[xDim];
    });
    xMin = xMin > 0 ? 0 : xMin;
 
    var yMax = d3.max(data, function (d) {
        return d.dims[yDim];
    }) * 1.05;
    var yMin = d3.min(data, function (d) {
        return d.dims[yDim];
    });
    yMin = yMin > 0 ? 0 : yMin;
    
    var xOffset = 200 * Math.abs(xMax - xMin) / width,
        yOffset = 200 * Math.abs(yMax - yMin) / height;
  
    x.domain([xMin - xOffset, xMax + xOffset]);
    y.domain([yMin - yOffset, yMax + yOffset]);
  
    var returnCachedLayout = false;
  
    var labelPadding = 3;
    var textWidth = []
    var circleRadius = data.map(p => p.PercentEngagement)
    // var hoverDetails = {}

    let scalingVal = isTriangulation ? 4 : 1.5
    if(isTriangulation){
      if(wrapper.clientWidth < 1400) scalingVal = 8
      if(wrapper.clientWidth < 1200) scalingVal = 10
      if(wrapper.clientWidth < 1000) scalingVal = 13
    }
    
    // X scale and Axis
    const f =  d3.format("~s")
    const xScale = d3.scaleLinear()
      .domain([
        d3.min(data, (d) => parseInt(d.BuzzVolumeIndex-scalingVal)),
        d3.max(data, (d) => parseInt(d.BuzzVolumeIndex+scalingVal)),
        // d3.min(data, (d) => parseInt(graphDetails.range.x[0]-1.5)), // 1.5
        // d3.max(data, (d) => parseInt(graphDetails.range.x[1]+1.5)), // 1.5

      ])
      .range([0, width])
      // .domain([0, 3])
      // .range([0, width])

    const div = d3.select('div.tooltip')

    svg
      .append('g')
      .attr("transform", `translate(0, ${height})`)
      // .call(d3.axisBottom(xScale).tickSize(0).ticks(5).tickPadding(8).tickFormat(f)) // IMP DON'T DELETE
      .call(d => d.select(".domain").remove())
  
    // Y scale and Axis
    const yScale = d3.scaleLinear()
      .domain([
        d3.min(data, (d) => parseInt(d.MeanRatingIndex-scalingVal)),
        d3.max(data, (d) => parseInt(d.MeanRatingIndex+scalingVal)),
        // d3.min(data, (d) => parseInt(graphDetails.range.y[0]-0.5)),
        // d3.max(data, (d) => parseInt(graphDetails.range.y[1]+0.5)),
      ])
      .range([height, 0])
      // .domain([-3, 3])
      // .range([height, 0]);

    svg
      .append('g')
      // .call(d3.axisLeft(yScale).tickSize(0).ticks(6).tickPadding(4).tickFormat(f)) // IMP DON'T DELETE
      .call(d => d.select(".domain").remove());

    // bubble size scale
    const zScale = d3.scaleSqrt()
      .domain([5, 100])
      .range([30, 70])


    svg.append("g")
      .attr("class", "horizontalLine")
      .selectAll("line")
      .data(data)
      .join("line")
      .attr("x1", xScale(-graphDetails.range.x[1]))
      .attr("y1", yScale( graphDetails.AxisCutHorizontal ))
      .attr("x2", xScale(graphDetails.range.x[1]))
      .attr("y2", yScale( graphDetails.AxisCutHorizontal ))
      .attr("stroke-width", 1)
      .attr("stroke", "rgba(126, 126, 126, 1)")
      .attr("stroke-dasharray", "4,4")
      .style("opacity", (d, i) => i === 0 ? 0.5 : 0)


    svg.append("g")
      .attr("class", "verticalLine")
      .selectAll("line")
      .data(data)
      .join("line")
      .attr("x1", xScale( graphDetails.AxisCutVertical )) // graphDetails.range.y[0] // 1
      .attr("x2", xScale( graphDetails.AxisCutVertical )) // graphDetails.range.y[0] // 1
      .attr("y1", yScale(-graphDetails.range.y[1])) // 10
      .attr("y2", yScale(graphDetails.range.y[1])) // 10
      .attr("stroke-width", 1)
      .attr("stroke", "rgba(126, 126, 126, 1)")
      .attr("stroke-dasharray", "4,4")
      .style("opacity", (d, i) => i === 0 ? 0.5 : 0)


    svg.append("g")
      .attr("class", "circle")
      .selectAll(".dot")
      .data(data)
      .join("circle")
      .attr("cx", d => xScale(+d.BuzzVolumeIndex))
      .attr("cy", d => yScale(+d.MeanRatingIndex))
      .attr("r", d => zScale(+d.PercentEngagement))
      .attr("title", d => d.DriverName)
      // .style("fill", d => d.Sentiment === "Positive" ? 'rgba(0, 176, 80, 0.4)' : d.Sentiment === "Negative" ? 'rgba(255, 0, 0, 0.4)' : 'rgba(166, 166, 166, 0.4)')
      .style("fill", d => getColorForSentiments(d.Sentiment))
      .on('mouseover', (e, d) => {
        for(let i=0; i<data.length; i++){
          const labelNode = document.querySelectorAll(`#${id} .label`)[i]
          if(data[i].DriverName === d.DriverName){
            // document.querySelectorAll(`#${id} .circle circle`)[i].style.fill = (d.Sentiment === "Positive" ? 'rgba(0, 176, 80, 0.6)' : d.Sentiment === "Negative" ? 'rgba(255, 0, 0, 0.6)' : 'rgba(166, 166, 166, 0.6)')
            document.querySelectorAll(`#${id} .circle circle`)[i].style.fill = getColorForSentiments(d.Sentiment, 'dark')
            labelNode.querySelector('text').style.fontWeight = '500';
          } else {
            labelNode.querySelector('text').style.fontWeight = '400';
          }
        }
      })
      .on('mouseout',function(d, i) {
        // d3.select(this).style("fill", d => d.Sentiment === "Positive" ? 'rgba(0, 176, 80, 0.4)' : d.Sentiment === "Negative" ? 'rgba(255, 0, 0, 0.4)' : 'rgba(166, 166, 166, 0.4)')
        d3.select(this).style("fill", d => getColorForSentiments(d.Sentiment))
        for(let i=0; i<data.length; i++){
          const labelNode = document.querySelectorAll(`#${id} .label`)[i]
          labelNode.querySelector('text').style.fontWeight = '400';
          // labelNode.querySelector('rect').style.display = 'none';
        }
      })
      .on("click", function(events, d) {
          const thsId = events.target.parentNode.parentNode.parentNode.parentNode.parentNode.getAttribute('id')
          const thsWrapperId = document.getElementById(thsId)

          if( (d?.Insights) ){
            const wrapperDetails = wrapper.getBoundingClientRect()
            const position = {
              x : events.clientX - (window.scrollX + wrapperDetails.left),
              y : events.clientY - (window.scrollY + wrapperDetails.top),
            }
            // if( (position.x+380) > wrapper.offsetWidth ){
            //   position.x = position.x - ((position.x+380) - wrapper.offsetWidth)
            // }
            // if( (position.y+450) > wrapper.offsetHeight ){
            //   position.y = position.y - ((position.y+450) - wrapper.offsetHeight)
            // }

            if(position.y < 40) position.y = 70
            

            const content = `
              <div class='overlay' id='popupClose'></div>
              <div class='popup' id='${popupId}' style='top:${position.y}px; left:${position.x}px; max-height:${wrapperDetails.height-20}px; overflow-y:auto'>
                <a class='close' href='javascript:' id='close'>
                  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L9 9" stroke="#C3C3C3" stroke-width="1.5" stroke-linecap="round"/>
                    <path d="M9 1L1 9" stroke="#C3C3C3" stroke-width="1.5" stroke-linecap="round"/>
                  </svg>
                </a>
                <p class='driverName'>${d.DriverName}</p>
                <div class='description'>${d?.Insights}</div>
              </div>
            `
            thsWrapperId.querySelector('.tooltip').innerHTML = content
            thsWrapperId.querySelector('a#close').addEventListener("click", function() {
              thsWrapperId.querySelector('.tooltip').innerHTML = ''
            });
            thsWrapperId.querySelector('div#popupClose').addEventListener("click", function() {
              thsWrapperId.querySelector('.tooltip').innerHTML = ''
            });

            const popup = document.getElementById(popupId)
            const fullHt = position.y + popup.offsetHeight
            const fullWt = position.x + popup.offsetWidth
            if(fullHt > wrapperDetails.height){
              popup.style.top = (position.y - (fullHt-wrapperDetails.height) + 35)+'px'
            }
            if(fullWt > wrapperDetails.width){
              popup.style.left = (position.x - (fullWt-wrapperDetails.width) + 55)+'px'
            }

          }
      })
      
    svg.append("g")
      .selectAll("text")
      .data(data)
      .join("text")
      .attr("x", 50)
      .attr("y", 50)
      .style("font-size", "12px")
      .style("font-weight", "400")
      .text((d, i) => d.DriverName)
      .each(function(d,i) {
        var thisWidth = this.getComputedTextLength()
        textWidth.push(thisWidth)
        this.remove() // remove them just after displaying them
      })

    // window.fc = fc;
    // window.d3 = d3;

    var label = fc.layout.textLabel()
        .padding(labelPadding)
        .value(function (d) {
            return d.label;
        });
  
    var strategyCache = function (strategy) {
      var cachedLayout;
      
      var cache = function(layout) {
        if (!returnCachedLayout) {
          cachedLayout = strategy(layout);
          // determine the offset applied by the layout
          for (var i = 0; i< layout.length; i++) {
            cachedLayout[i].dx = layout[i].x - cachedLayout[i].x;
            cachedLayout[i].dy = layout[i].y - cachedLayout[i].y;
          }
        } else {
          // update the location of each label, including the offset
          for (var i = 0; i< layout.length; i++) {
            cachedLayout[i].x = layout[i].x - cachedLayout[i].dx;
            cachedLayout[i].y = layout[i].y - cachedLayout[i].dy;
          }
        }
        return cachedLayout;
      };
      return cache;
    };
  
    // construct a strategy that uses the "greedy" algorithm for layout, wrapped
    // by a strategy that removes overlapping rectangles.
    // var strategy = strategyCache(fc.layoutRemoveOverlaps(fc.layoutGreedy()));
    var strategy = strategyCache(fc.layout.strategy.removeOverlaps(fc.layout.strategy.greedy()));

    // create the layout that positions the labels
    var labels = fc.layout.label(strategy)
      .size(function (d, i) {
        return [textWidth[i] + labelPadding * 2, 15 + labelPadding * 2]
      })
      .position(function (d, i) {
        const dx = 0; // zScale(d.PercentEngagement/2 + 1);
        const dy = zScale(d.PercentEngagement/2 + d.PercentEngagement/4);
        const x = xScale(d.BuzzVolumeIndex) + dx;
        const y = yScale(d.MeanRatingIndex) + dy;
        return [x, y]
        // return [x(d.dims[xDim]), y(d.dims[yDim])];
      })
      .component(label);

    svg.datum(data).call(labels);

    window.fc = fc;

    d3.selectAll("g.label circle").attr('fill','red').style('display','none')
    d3.selectAll("g.label rect").attr('fill','#d3d3d3').style('display','none')
    d3.selectAll("g.label text").style("font-size", "12px").style("font-weight", "400")
    d3.selectAll("g.label").style('display','inherit')

    const columnWidth = document.getElementById(idWrapper).querySelector('.columnLine span').offsetWidth
    if( columnWidth > 0 ){
      document.getElementById(idWrapper).querySelector('.columnLine span').style.left = -(columnWidth/2) + 'px'
    }

  }

  const rangeFunc = (resp) => {
    const xValues = resp.map(d => d.BuzzVolumeIndex)
    const yValues = resp.map(d => d.MeanRatingIndex)
    const xMin = Math.min(...xValues) - 2.5
    const xMax = Math.max(...xValues) * 2
    const yMin = Math.min(...yValues) - 2.5
    const yMax = Math.max(...yValues) * 2
    return { x: [xMin, xMax], y:[yMin, yMax] }
  }

  const loadAxisDataFunc = async (resp, hasTabs) => {
    setPageFlag(true)
    setLoading(true)
    await ReportsService.reportGetTriangulationGraphConfigurationApi({ insightsElementId: props.InsightsElementId })
      .then(response => {
        if(response.length > 0) {
          const details = {...response?.[0], range:rangeFunc(resp)}
          setGraphDetails(details)
          generateProgressionGraphData(resp, hasTabs, details)
        }
      })
      .catch(error => {
        const resMessage = error
        console.log('ERROR : ', resMessage)
      }).finally(() => {
        setLoading(false)
      })
  }

  const generateProgressionGraphData = async (resp, hasTabs, details) => {
    scoreImageryTypeArray = {}
    scoreImageryTypeArrayL2 = {}


    // GetAllSentiments
    const getAllSentiments = () => {
      if(isTriangulation){
        const respArray = getObjectAsArray(resp)
        
        const array = respArray?.map(i => {
          if(isTriangulationProgression) return getObjectAsArray(i.PeriodBrandAttributeMapElements.BrandAttributeMapElement).map(j => j.Sentiment)
          return i.Sentiment
        }).flat(1) || []
        const finalArray = [...new Set(array)].map((str, i) => ({label:str, value: str}))
        let orderedArray = new Array(finalArray.length)

        if(respArray[0]?.DriverOrder){
          for(let i=0; i<respArray.length; i++){
            const d = finalArray.find(f => f.label === respArray[i].Sentiment)
            const index = respArray[i].DriverOrder-1
            orderedArray[index] = {...d, colorLight: GRAPH_COLOR_PALLET3[index]+'bf', colorDark: GRAPH_COLOR_PALLET3[index]}
          }
        } else {
          orderedArray = finalArray 
        }

        setLegends(orderedArray)
        setColorPallet(orderedArray.map(f => f.colorDark))
      }
    }
    getAllSentiments()

    const rowArrayDataFunc = (data, dataObj) => {
      filterDataObj.periods.push(dataObj?.title || '')
      filterDataObj.drivers.push(data.map((d) => (d.DriverName)))

      const finalData = data.map(d => {
        return {
          ...d,
          title: d.DriverName,
          graphs: [{
            value: d?.BrandMagnetScore,
            barData: [{ Warning: d?.BrandMagnetScore }]
          }]
        }
      })

      return finalData
    }

    const configTabObject = hasTabs 
      ? (resp.map((d, i) => {
          return {
            periodInsightsElementId: d.PeriodInsightsElementId,
            id: i,
            label: i===0 ? d.PeriodName : 'Progression',
            tutorial: i===0 ? 'CurrentPeriod' : 'Progression',
            title: d.PeriodName,
            periodOrder: d.PeriodOrder,
            data: getObjectAsArray(d.PeriodBrandAttributeMapElements.BrandAttributeMapElement)
          }
        }))
      : []

    const rowArrayData = hasTabs
      ? configTabObject.map(d => {
        d.data = rowArrayDataFunc(d.data, d)
        return d
      })
      : [rowArrayDataFunc(resp, null)]

    const rowArray = hasTabs ? (
      rowArrayData[0].data.map(d => ({...d, PeriodInsightsElementId: rowArrayData[0].periodInsightsElementId}))
    ) : rowArrayData[0]

    const removeUsingSet = (arr) => {
        let outputArray = Array.from(new Set(arr))
        return outputArray
    }

    const progressionDataL1FilteredData = {drivers:removeUsingSet(filterDataObj.drivers.flat(1)), periods:removeUsingSet(filterDataObj.periods)}
    setProgressionDataL1Filtered(progressionDataL1FilteredData)

    if(hasTabs){
      setConfigTab(configTabObject)

      const progressionObj = {}
      // for(let i=0; i<rowArray.length; i++){
      //   progressionObj[rowArray[i].DriverName] = []
      // }
      
      for(let i=0; i<configTabObject.length; i++){
        const d1 = configTabObject[i].data;
        for(let j=0; j<d1.length; j++){
          if(!progressionObj[d1[j]?.DriverName]){
            progressionObj[d1[j].DriverName] = []
          }

          progressionObj[d1[j].DriverName].push({
            PeriodName: configTabObject[i].title,
            BuzzVolumeIndex: d1[j].BuzzVolumeIndex,
            DriverId: d1[j].DriverId,
            DriverName: configTabObject[i].title,
            // DriverName: d1[j].DriverName,
            InsightsElementId: d1[j].InsightsElementId,
            PeriodInsightsElementId: configTabObject[i]?.periodInsightsElementId || null,
            MeanRatingIndex: d1[j].MeanRatingIndex,
            PercentEngagement: d1[j].PercentEngagement,
            Sentiment: d1[j].Sentiment
          })
        }
      }

      setProgressionData(progressionObj)
    }

    const driverDropdownArray = rowArray.map(d => ({
      value: d.DriverId,
      label: d.DriverName,
      DriverId: d.DriverId,
      DriverName: d.DriverName,
      InsightsElementId: d.InsightsElementId,
      PeriodInsightsElementId: d?.PeriodInsightsElementId || null
    }))
    setDriverDropdown(driverDropdownArray)

    setState(rowArray)
    setRows(rowArray)
    updateSubLhsApiLoaded(props)
    setEventCount(p => p+1)
  }

  const loadBrandAttributeGraph = async () => {
    setPageFlag(true)
    setLoading(true)

    const hasTabs = ['BrandAttributeMapAndProgression', 'TriangulationMapAndProgression'].includes(InsightsElementType)
    const payload = { insightsElementId: props.InsightsElementId, getLatest: true }

    await ((hasTabs) ? ReportsService.reportGetBrandAttributeMapProgressionElementApi(payload) : ReportsService.reportGetBrandAttributeMapElementApi(payload))
    .then(resp => {
      
        if (isTriangulation) {
          loadAxisDataFunc(resp, hasTabs)
        } else {
          const details = {
            ...graphDetails,
            AxisCutHorizontal: 0,
            AxisCutVertical: 1,
            AxisNameHorizontal:'Buzz Index (Relative Importance of the Insight Space vs. Category)',
            AxisNameVertical:'Sentiment (Positivity of the Insight Space vs. Category)',
            range:rangeFunc(resp)
          }
          setGraphDetails(details)
          generateProgressionGraphData(resp, hasTabs, details)
        }
      })
      .catch(error => {
        const resMessage = error
        console.log('ERROR : ', resMessage)
      }).finally(() => {
        setLoading(false)
      })
  }

  const filterChangeFunc = (data) => {
		setProgressionDataSelectedFiltered(data)
    if(data?.drivers?.length > 0){
      setTimeout(() => {
        // drawScatter(progressionData[data.drivers[0]].filter(f => data.periods.includes(f.PeriodName)), props, true)
        testGraph(progressionData[data.drivers[0]].filter(f => data.periods.includes(f.PeriodName)), props, true)
      }, 100)
    }
	}

  useEffect(() => {
    loadBrandAttributeGraph()
  }, [])

  useEffect(() => {
    if(!loading && state.length) {
      // if(tabChanged === 0 ) { drawScatter(state, props, false) }
      if(tabChanged === 0 ) { testGraph(state, props, false) }
      if(tabChanged === 1 ) {
        // setProgressionDataSelectedFiltered({...progressionDataSelectedFiltered, drivers:[]})
        // const driverKey = Object.keys(progressionData)[0]
        // drawScatter(progressionData[driverKey], props, true)
      }
      setEventCount(p => p+1)
    }
  }, [tabChanged])

  useEffect(() => {
    if(!loading && state.length && !props?.isProgressionActive) {
      // drawScatter(state, props)
      testGraph(state, props)
      setEventCount(p => p+1)
    }
  }, [loading, state])

  useEffect(() => {
    if(
      (props?.isProgressionActive && ['BrandAttributeMapAndProgression', 'TriangulationMapAndProgression'].includes(props.InsightsElementType)) && 
      (progressionDataL1Filtered.drivers.length > 0 && progressionDataL1Filtered.periods.length > 0) 
    ){
      const key = Object.keys(progressionData)
      for(let i=0; i<key.length; i++){
        testGraph( progressionData[key[i]], props, false, i )
      }
    }
  }, [props, progressionDataSelectedFiltered])

  const onInsightsChange = (p) => {
    document.getElementById(`Svg_${props.type}_Wrapper`).removeAttribute('style')
    document.getElementById(`Svg_${props.type}`).innerHTML = '<b>deleted</b>'
    testGraph(state, props)
  }

  const EditButton = () => {
    return (
        <>{ROLES_MM_PRIMARY.includes(user?.UserType) && driverDropdown.length>0 && <ReportEditBrandAttributeMap InsightsElementId={props.InsightsElementId} dropdownData={driverDropdown} pageData={state} onSuccess={(e) => {loadBrandAttributeGraph()}} />}</>
    )
  }

  return (
    <>
      <ReportHeading resetInsightHeight={eventCount} downloadId={props.type} downloadIdData={state} {...props} onInsightsChange={(p) => onInsightsChange(p)} additionalButton={<EditButton />} />
      <ReportOuterWrapper>
          <OuterWrapper id={props.type}>
            <LoaderWrapper loading={loading} loadOnlyApi={props.loadOnlyApi}>

              {!props?.isProgressionActive && <ProgressionWrapper configTab={configTab} onChange={setTabChanged}>
                <ContentWrapper className='brandAttributeMapRow'>
                  <ImpactDestinationsWrapper id={`Svg_${props.type}_Wrapper`}>
                    {/* <div className="svgWrapper" id={`svgWrapperTest`} style={{height:'500px'}}><svg></svg></div> */}
                    <div className="svgWrapper" id={`Svg_${props.type}`}></div>
                    <div className="columnLine"><span>{graphDetails.AxisNameVertical}</span></div>
                    <div className="rowLine"><span>{graphDetails.AxisNameHorizontal}</span></div>
                    <div className="tooltip"></div>
                  </ImpactDestinationsWrapper>
                  {!isTriangulation && <InfoRow />}
                  <LegendWrapper>
                    <Legend legends={legends} colorPallet={colorPallet} />
                    <SocialLegend legends={socialLegend} />
                  </LegendWrapper>
                </ContentWrapper>
                <ContentWrapper>
                  {progressionDataL1Filtered?.drivers?.length > 0 && <ProgressionFiltersWrapper><ProgressionFilters settings={progressionFiltersSettings} data={progressionDataL1Filtered} onChange={filterChangeFunc} /></ProgressionFiltersWrapper>}
                  <ImpactDestinationsWrapper id={`Svg_${props.type}_Wrapper`}>
                    {(progressionDataSelectedFiltered.drivers.length === 1 && progressionDataSelectedFiltered.periods.length > 0) 
                      ? <>
                        <div className="svgWrapper" id={`Svg_${props.type}_progression`}></div>
                        <div className="columnLine"><span>{graphDetails.AxisNameVertical}</span></div>
                        <div className="rowLine"><span>{graphDetails.AxisNameHorizontal}</span></div>
                      </>
                      : <ProgressionDataNotFoundWrapper><ProgressionDataNotFound>Please select periods and driver to view progression</ProgressionDataNotFound></ProgressionDataNotFoundWrapper>
                    }
                    <div className="tooltip"></div>
                  </ImpactDestinationsWrapper>
                  {!isTriangulation && <InfoRow />}
                  <LegendWrapper>
                    <Legend legends={legends} colorPallet={colorPallet} />
                    <SocialLegend legends={socialLegend} />
                  </LegendWrapper>
                </ContentWrapper>
              </ProgressionWrapper>}



              {(props?.isProgressionActive && ['BrandAttributeMapAndProgression', 'TriangulationMapAndProgression'].includes(props.InsightsElementType)) &&
                <IsAppendicesContentWrapper>
                  {
                    (progressionDataL1Filtered.drivers.length > 0 && progressionDataL1Filtered.periods.length > 0) &&
                    progressionDataL1Filtered.drivers.map((p, i) => {
                      return (
                        <>
                          <ContentWrapper className="brandAttributeMapRowProgression">
                            <AppendicesHeading className='brandAttributeMapLevel2'>{p}</AppendicesHeading>
                            <ImpactDestinationsWrapper id={`Svg_${props.type}_Wrapper____${i}`}>
                              <div className="svgWrapper" id={`Svg_${props.type}_progression____${i}`}></div>
                              <div className="columnLine"><span>{graphDetails.AxisNameVertical}</span></div>
                              <div className="rowLine"><span>{graphDetails.AxisNameHorizontal}</span></div>
                            </ImpactDestinationsWrapper>
                            {!isTriangulation && <InfoRow />}
                            <LegendWrapper>
                              <Legend legends={legends} colorPallet={colorPallet} />
                              <SocialLegend legends={socialLegend} />
                            </LegendWrapper>
                          </ContentWrapper>
                        </>
                    )})
                  }
                </IsAppendicesContentWrapper>
              }


            </LoaderWrapper>
          </OuterWrapper>
      </ReportOuterWrapper>
    </>
  )
}


export default ReportBrandAttributeMapElement
