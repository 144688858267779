import { styled } from '@mui/material/styles'

export const Wrapper = styled('ul')(`
  display:flex;
  justify-content: flex-start;
  list-style:none;
  font-size: 0.9rem;
  max-width: 100%;


  --plz-color-black-2: #fff;
  --plz-color-white: #000;
  --plz-border-radius-default: 0.25rem;
  --scale: 0;
  --arrow: 5px;


  & li {
    width:100%;
    & .barSliceWrap {
      display: flex;
    }
  }

  & li .barSlice{
    position: relative;
    height:28px;
    line-height:28px;
    position:relative;
    text-indent:5px;
    font-size:0;
    color: #fff;
    display: flex;
    align-items: center;
  }





  & li .barSlice.showToolTip::before,
  & li .barSlice.showToolTip::after {
    position: absolute;
    transform: translateX(-50%) translateY(var(--translate-y, 0)) scale(var(--scale));
    transition: 200ms transform;
    transform-origin: bottom center;
    top: -2px;
    left: 50%;
    z-index:1;
  }
  
  & li .barSlice.showToolTip::before {
    --translate-y: calc(-100% - var(--arrow));
    background-color: var(--plz-color-black-2);
    color: var(--plz-color-white);
    border-radius: var(--plz-border-radius-default);
    box-shadow: 0 0 4px 1px #d3d3d3;
    content: attr(data-tool);
    padding: 5px 5px 5px 0;
    font-size: 10px;
    width: max-content;
    line-height:1;
  }
  
  & li .barSlice.showToolTip:hover::before,
  & li .barSlice.showToolTip:hover::after { --scale: 1; }
  
  & li .barSlice.showToolTip::after {
    --translate-y: calc(-1 * var(--arrow));
    transform-origin: top center;
    content: '';
    border: var(--arrow) solid transparent;
    border-top-color: var(--plz-color-black-2);
    border-top-width: 5px;
  }

  & li .barSlice.Neutral span{opacity:0;}
  & li .barSlice.Mixed span{opacity:0;}

  & li div .barSlicePercentage{
    font-size: 14px;
    color: #000;
    padding-left:5px;
    min-width:45px;
    width:45px;
  }

  &.barRoot {
    & .barItemRoot {
      display:flex;
      flex-direction: column;
      & .barSlice {
        font-size: 14px;
      }

    }
  }

  & .barSlice {
    position:relative;
  }

`)

export const InnerBar = styled('div')(`
  // border:1px blue solid;
`)
