import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import { MEDIA } from "../../../../constants";

export const Wrapper = styled(Box)(`
    // min-height:calc(100vh - 190px);
    width: 100%;
`)

export const ContentWrapper = styled(Box)(`
    width: 100%;
    padding:0px 0px 10px 0;
    @media (max-width: ${MEDIA.lg}) {
        // padding: 20px;
    }
`)

export const ReportSandboxWrap = styled(Box)(`
    display: flex;
    & .Information {
        // padding-top: 0;
        // height: calc(100vh - 333px);
        overflow: auto;
    }
    @media (max-width: 980px) {
        flex-direction: column;
    }
`);

export const LeftGrid = styled(Box)(`
    width: 240px;
    min-width: 240px;
    @media (max-width: 980px) {
       width: 100%;
    }
`);

export const RightGrid = styled(Box)(`
    width: 100%;
    margin-top: -11px;
    padding: 0 0 0 20px;
    display: flex;
    @media (max-width: 980px) {
       margin-top: 30px;
    }
    & .noDataFound{
        padding: 0;
        margin: auto;
    }
`);


export const FiltersWrap = styled(Box)(`
    width: 100%;
    padding: 10px;
    background: #FFFFFF;
    position: sticky;
    top: 10px;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.06);
    & .formLabel {
        padding-top: 0px;
        padding-bottom: 0px;
        font-size: 10px;
    }
`);

export const FilterItemWrap = styled(Box)(`
    &.defaultItem {
        margin-bottom: 30px;
        & .selectPlaceholder {
            margin-left: 0;
            font-size: 14px;
        }
        & .MuiInputBase-root {
            box-shadow: none;
            & img {
                right: 0;
            }
        }
        & .MuiSelect-standard {
        font-size: 16px;
        font-weight: 400;
        margin-bottom: -8px;
        padding-right: 10px !important;
        }
        & .formLabel{
            display: block;
            // margin-bottom: -5px;
            color: #545454;
        }
    }
`);

export const HeadingRow = styled(Box)(`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding-top:15px;
    // padding-bottom:10px;
    & img {
        margin: 0 5px;
    }
`);

export const StyledTypography = styled(Typography)(`
    margin: 0 5px;
`);

export const FilterByHeading = styled(Typography)(`
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 20px;
`)

export const InfoToolTipWrap = styled(Box)(`
    margin-top: -10px;
    margin-left: -5px;
    svg {
        width: 15px;
        height: 14px;
        fill: #545454;
    }
`)

export const LegendWrapper = styled(Box)(`
    padding-top:20px;
    & > div {justify-content:end;}
`)
