import { styled } from '@mui/material/styles'
import { Box, Typography } from '@mui/material'
import { MuiButton } from '../../../../../components'
import { ulLiCssRecommendation } from '../../../../../util'

export const Wrapper = styled(Box)(`
  width:90vw;
  min-width:370px;
`)

export const ButtonWrapper = styled(MuiButton)(`
  margin-top:30px;
`)

export const Title = styled(Typography)(`
  margin-top: -10px;
  padding-bottom: 30px;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
`)

export const RteWrapper = styled(Box)(`
  & * {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    text-transform: capitalize;
    color: #000000;
  }
`)

export const RtePreviewCss = `

  #tinymce {
    ${ulLiCssRecommendation}
  }

  // #tinymce > ul {
  //   list-style-type:disc; // new
  //   ${ulLiCssRecommendation}
  // }
  #tinymce > ul {
    list-style-position: outside;
    margin-left: 0px;
    list-style: none;
    padding:0;
  }
  #tinymce > ul > li {
    background: #FFFFFF;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    padding: 30px 10px;
    margin: 20px 0 40px;
  }
  #tinymce > ul > li:last-child{
    margin-bottom: 0;
  }
  #tinymce > ul > li > h4 {
    background: #F7F7F9;
    border-radius: 4px;
    padding: 30px 10px;
    text-transform: capitalize;
    border-radius: 2px;
    font-weight: 500;
    width: fit-content;
    min-width:220px;
    padding: 10px 10px 10px 40px;
    font-size: 14px;
    margin: -50px 0 10px;
    text-decoration: none;
    display:block;
  }
  #tinymce > ul > li > ul {
    margin-left:15px;
    // list-style-type:disc; // new
  }
  #tinymce > ul > li > ol {
    margin-left:15px;
  }
  #tinymce > ul > li > ul > li, #tinymce > ul > li > ol > li {
    background: transparent;
    box-shadow: none;
    border-radius: 0;
    padding: 0;
    margin: 0;
  }

  #tinymce > ul ul ul, #tinymce > ul ol ul {
    // list-style-type:circle; // new
  }

  #tinymce > ul > li > ul > li > ul,
  #tinymce > ul > li > ul > li > ol,
  #tinymce > ul > li > ol > li > ul,
  #tinymce > ul > li > ol > li > ol
  {
    margin:5px 5px 5px 20px;
  }
`
