import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const BarLineWrap = styled(Box)(`
    margin-top: auto;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
`);

export const Bar = styled(Box)( props =>  `
    background: ${props.bar.color};
    height: ${props.bar.height};
    width: ${props.bar.width};
    position: relative;
    &:after {
        content: attr(cite);
        text-align: center;
        display: block;
        margin-top: -20px;
        font-weight: 300;
        font-size: 14px;
    }
`);