import { styled } from '@mui/material/styles'
import { Box, Typography } from '@mui/material'

export const OuterWrapper = styled(Box)(`
  & .postLoginReportsEditModal{
    justify-content:end;
  }
  & .closeBtn button{
    position: absolute;
    right: 0;
    top: 5px;
  }
  & .postLoginReportsEditPopup{
    border-radius: 0;
    width: calc(100% - 110px);
    align-self: normal;
    padding:0;
  }
`)

export const Wrapper = styled(Box)(`
  width:100%;
`)

export const Heading = styled(Box)(`
  padding:0 60px 0 30px;
  height:70px;
  display:flex;
  align-items:center;
  justify-content:space-between;
  border-bottom: 1px solid #E7E7E7;
`)

export const TitleWrap = styled(Typography)(`
  color:#000;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  & sup {
    font-size: 14px;
  }
`)

export const ButtonWrap = styled(Box)(`
  & > * {
    margin-left:15px !important;
  }
`)

export const ContentWrapper = styled(Box)(props => `
  display:flex;
  padding: 30px;
  width: 100%;
  height: calc(100vh - ${props.spacebottom || 70}px);
`)

