import { CheckTwoTone, Error } from '@mui/icons-material'
import { Box, IconButton, Skeleton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import React, { useState } from 'react'
import { edit } from '../../../../assets/images/icons'
import { MuiButton, MuiCheckbox, MuiImage, MuiSelect, MuiTextField, VerbatimImageVideoTag } from '../../../../components'
import { SENTIMENTS } from '../../../../constants'
import { AttributeName, BrandCategoryName, DriverName, SubAttributeName, TriggerName } from '../dropdowns'
import { TableWrap, TableEditRow, EditTableTitle, TypographyInput, TableInputWrap, CustomTableHeadCell, CustomTableBodyCell, CustomTableHead, CustomTable, MediaWrapper } from './style'

const HEADER = [
  { id: "VerbatimText", label: "Verbatim", width: "40%" },
  { id: "EntityName", label: "Brand", width: "15%" },
  { id: "VerbatimSentiment", label: "Sentiment", width: "10%" },
  { id: "DriverName", label: "Driver", width: "10%" },
  { id: "AttributeName", label: "Attribute", width: "10%" },
  { id: "SubAttributeName", label: "Sub Attribute", width: "10%" },
  { id: "InsightsAssistantVisibility", label: "IA", width: "5%" },
]

const CELL_EDIT = ["EntityName", "VerbatimSentiment", "DriverName", "AttributeName", "SubAttributeName"];

const DataTable = ({ isLoading, data, header = HEADER, update, select, selected, insightsElementId, isCollapsed }) => {

  const selectHandler = (id, isSelectAll) => {
    if (isSelectAll) {
      if (id) {
        const ids = data.map(item => item.VerbatimId)
        select(ids);
      } else {
        select([]);
      }
    } else {
      const isExist = selected.find(item => item === id);
      let ids = isExist ? selected.filter(item => item !== id) : [...selected, id];
      select(ids)
    }
  }

  return (
    <TableWrap className='TableOuterWrap'>
      <CustomTable>
        <CustomTableHead className='tableHead' collapsed={isCollapsed ? "true": ""}>
          <tr>
            <CustomTableHeadCell>
              <MuiCheckbox disabled={!data?.length} checked={data?.length === selected?.length && !!data?.length} onChange={(e) => selectHandler(e.target.checked, true)} />
            </CustomTableHeadCell>
            {
              header?.map(item => {
                return (
                  <CustomTableHeadCell width={item.width}>{item.label}</CustomTableHeadCell>
                )
              })
            }
          </tr>
          {/* {
           isLoading ? [73, 127, 222].map(item => {
              return (<tr key={item}>
                <CustomTableHeadCell>
                  <Skeleton width={20} variant="rounded" />
                </CustomTableHeadCell>
                {
                  header?.map(() => {
                    return (
                      <CustomTableHeadCell><Skeleton width="90%" variant="rounded" /></CustomTableHeadCell>
                    )
                  })
                }
              </tr>)
            }) : null
          } */}
        </CustomTableHead>
        {!isLoading && data?.length > 0 && <tbody>
          {
            data?.map((item) => {
              const modifiedObj = {
                ...item,
                InsightsAssistantVisibility: (item.InsightsAssistantVisibility) ? <CheckTwoTone fontSize='15px' /> : 'X'
              }
              const isSelected = selected?.includes(modifiedObj.VerbatimId)

              return (
                <BodyTableRow insightsElementId={insightsElementId} isSelected={isSelected} selectHandler={selectHandler} update={update} key={modifiedObj.VerbatimId} data={modifiedObj} header={header} />
              )
            })
          }
        </tbody>}
      </CustomTable>
    </TableWrap>
  )
}

export default DataTable;

const BodyTableRow = ({ data, header, update, isSelected, selectHandler = [], insightsElementId }) => {
  const [isEdit, setEdit] = useState(false);
  const [errors, setErrors] = useState({});
  const [state, setState] = useState({
    entityName: data?.EntityName || "",
    sentiment: data?.VerbatimSentiment || "",
    driverName: data?.DriverName || "",
    attributeName: data?.AttributeName || "",
    subAttributeName: data?.SubAttributeName || "",
    triggerName: data.TriggerName || "",
    verbatimMediaPrivateName: data.VerbatimMediaPrivateName,
    verbatimText: data?.VerbatimText || "",
    verbatimAdditionalInfo: data?.VerbatimAdditionalInfo || ""
  });

  const validatePayload = (obj) => {
    const errorObj = {};
    if(!obj.entityName) errorObj.entityName = "Brand/Category is a required field";
    if(!obj.driverName) errorObj.driverName = "Driver Name is a required field";
    if(!obj.sentiment) errorObj.sentiment = "Sentiment is a required field";
    if(!obj.verbatimText && !obj.verbatimMediaPrivateName) errorObj.verbatimText = "Either Image / Video Private Name or Verbatim Text is required";
    // if(!obj.verbatimAdditionalInfo) errorObj.verbatimAdditionalInfo = "Additional Info is required field";
    setErrors(errorObj);
    return Object.keys(errorObj)?.length;
  }


  const inputChangeHandler = (name, value) => {
    setState(prev => {
      const obj = {
        ...prev,
        [name]: value,
      }

      if (name === 'entityName') {
        obj.driverName = ""
        obj.attributeName = ""
        obj.subAttributeName = ""
      }
      if (name === 'driverName') {
        obj.attributeName = ""
        obj.subAttributeName = ""
      }
      if (name === 'attributeName') {
        obj.subAttributeName = ""
      }

      return obj;
    })

    setErrors(prev => {
      const obj = {
        ...prev
      }
      if(obj[name]) {
        delete obj[name];
      }
      return obj;
    })
  }

  const callBackHandler = (errorMessage="") => {
    if(!errorMessage) {
      setEdit(false)
    } else {
      setErrors({common: errorMessage})
    }
  }

  const submitHanlder = () => {
    const obj = {
      verbatimDate: data.VerbatimDate,
      verbatimId: data.VerbatimId,
      insightsElementId: insightsElementId,
      ...state,
      verbatimText: encodeURIComponent(state.verbatimText)
    }

    const hasError = validatePayload(obj);
    if(hasError) return;
    update(obj, false, callBackHandler);
  }

  const cancelHandler = () => {
    setEdit(false);
    setErrors({});
  }

  const editHandler = () => {
    setEdit(true)
  }

  return (
    <>
      <tr className={`tableBodyRow ${isEdit ? "editRow" : ""}`} id={`id_${data.VerbatimId}`}>
        <CustomTableBodyCell>
          <MuiCheckbox checked={isSelected} onChange={() => selectHandler(data.VerbatimId)} />
          <IconButton size='small' onClick={() => editHandler()}>
            <MuiImage src={edit} />
          </IconButton>
        </CustomTableBodyCell>
        {
          header.map((item, i) => {
            return (
              <CustomTableBodyCell key={i} width={item.width}>
                {isEdit && CELL_EDIT.includes(item.id) &&
                  <>
                    {
                      item.id === "EntityName" &&
                      <BrandCategoryName
                        value={state.entityName}
                        name="entityName"
                        onChange={inputChangeHandler}
                        insightsElementId={insightsElementId}
                        isShadow
                        title=''
                        error={errors?.entityName}
                      />
                    }
                    {
                      item.id === "VerbatimSentiment" &&
                      <MuiSelect
                        name="sentiment"
                        onChange={(e) => inputChangeHandler("sentiment", e.target.value)}
                        title=""
                        data={SENTIMENTS}
                        value={state?.sentiment || ""}
                        variant="outlined"
                        placeholder="Select"
                        compact
                        selectlabel="label"
                        selectvalue="value"
                        error={errors?.sentiment}
                      />
                    }
                    {
                      item.id === "DriverName" &&
                      <DriverName
                        value={state?.driverName || ""}
                        name="driverName"
                        entityName={state?.entityName || ""}
                        onChange={inputChangeHandler}
                        insightsElementId={insightsElementId}
                        isShadow
                        title=''
                        error={errors?.driverName}
                      />
                    }
                    {
                      item.id === "AttributeName" &&
                      <AttributeName
                        value={state?.attributeName || ""}
                        name="attributeName"
                        entityName={state?.entityName || ""}
                        driverName={state?.driverName || ""}
                        onChange={inputChangeHandler}
                        insightsElementId={insightsElementId}
                        isShadow
                        title=''
                      />
                    }
                    {
                      item.id === "SubAttributeName" &&
                      <SubAttributeName
                        value={state?.subAttributeName || ""}
                        name="subAttributeName"
                        entityName={state?.entityName || ""}
                        driverName={state?.driverName || ""}
                        attributeName={state?.attributeName || ""}
                        onChange={inputChangeHandler}
                        insightsElementId={insightsElementId}
                        isShadow
                        title=''
                      />
                    }
                  </>
                }
                {!(isEdit && CELL_EDIT.includes(item.id)) &&
                  <>
                    { 
                      (i === 0 && data?.VerbatimMediaPrivateName && data?.VerbatimMediaPublicLink) && <VerbatimImageVideoTag data={data} /> }
                    {(data?.[item.id] || "-")}
                  </>
                }
              </CustomTableBodyCell>
            )
          })
        }
      </tr>
      {
        isEdit &&
        <>
          <tr className={isEdit ? "editRow" : ""}>
            {/* <CustomTableBodyCell style={{ minWidth: "10%", display: 'flex', paddingTop: 0 }}></CustomTableBodyCell> */}
            <CustomTableBodyCell colSpan={header.length} style={{ padding: '0 10px 10px 100px' }}>
              <Box>
                <EditTableTitle>New Verbatim</EditTableTitle>
                <MuiTextField 
                  onChange={(e) => inputChangeHandler("verbatimText", e.target.value)} 
                  value={state?.verbatimText || ""} 
                  inputType="area" 
                  name="verbatimText"
                  error={errors?.verbatimText}
                />
              </Box>
              <TableEditRow>
                <TableInputWrap className="tableInputWrap">
                  <EditTableTitle>Image / Video Public Name</EditTableTitle>
                  <TypographyInput>{data?.VerbatimMediaPublicLink || '-'}</TypographyInput>
                </TableInputWrap>
                <TableInputWrap className="tableInputWrap">
                  <EditTableTitle>Image / Video Private Name</EditTableTitle>
                  <MuiTextField
                    compact
                    shadow
                    placeholder="Enter"
                    value={state?.verbatimMediaPrivateName || ""}
                    onChange={(e) => inputChangeHandler("verbatimMediaPrivateName", e.target.value)}
                    name='verbatimMediaPrivateName'
                    error={""}
                    helperText={""}
                  />
                </TableInputWrap>
                <TableInputWrap className="tableInputWrap">
                  <EditTableTitle>Additional Info</EditTableTitle>
                  <MuiTextField
                    compact
                    shadow
                    placeholder="Enter"
                    value={state?.verbatimAdditionalInfo || ""}
                    onChange={(e) => inputChangeHandler("verbatimAdditionalInfo", e.target.value)}
                    name='verbatimAdditionalInfo'
                    error={errors?.verbatimAdditionalInfo}
                    helperText={errors?.verbatimAdditionalInfo}
                  />
                </TableInputWrap>
                <TableInputWrap className="tableInputWrap">
                  <EditTableTitle>Trigger Name</EditTableTitle>
                  <TriggerName 
                    onChange={inputChangeHandler} 
                    insightsElementId={insightsElementId} 
                    title=''
                    name='triggerName' 
                    value={state?.triggerName || ""} 
                  />
                </TableInputWrap>
              </TableEditRow>
              <TableEditRow sx={{ gap: "20px !important", display: 'flex', alignItems: 'center'}}>
                <MuiButton onClick={submitHanlder} minwidth={'true'} variant="contained">Save</MuiButton>
                <MuiButton onClick={cancelHandler} minwidth={'true'} color="gray" variant="contained">Cancel</MuiButton>
               { !!errors?.common &&
                <>
                   <Error sx={{padding: 0}} color='error' titleAccess='common'></Error>
                <Typography sx={{color: '#D14343', marginLeft: "-10px"}}>{errors?.common}</Typography>
                </>
               }
              </TableEditRow>
            </CustomTableBodyCell>
          </tr>
        </>
      }
    </>
  )
}