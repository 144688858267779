import React, { useState, useEffect } from 'react'
// import { useParams, useLocation } from 'react-router-dom'
// import queryString from 'query-string'
// import AuthService from '../../services/auth.service'
// import { Typography } from '@mui/material'
import PreLogin from '../../layout/preLogin'
// import { useGlobalStore } from '../../store'
// import { Loader } from '../../components'
// import { ROUTE_PROJECTS } from '../../constants'
// import { Heading, ErrorMsg, LinkButton } from './style'


import { useIsAuthenticated } from '@azure/msal-react' //Azure
// import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
// import { loginRequest } from '../../AzureAuthConfig';
// import { callMsGraph } from '../../AzureGraph';


// const ProfileContent = () => {
//   const { instance, accounts } = useMsal();
//   const [graphData, setGraphData] = useState(null);

//   function RequestProfileData() {
//       // Silently acquires an access token which is then attached to a request for MS Graph data
//       instance
//           .acquireTokenSilent({
//               ...loginRequest,
//               account: accounts[0],
//           })
//           .then((response) => {
//             console.log('response -------', response)
//             // callMsGraph(response.accessToken).then((response) => setGraphData(response));
//           });
//   }

//   return (
//       <>
//           <h5 className="card-title">Welcome {accounts[0].name}</h5>
//           {graphData ? (
//               // <ProfileData graphData={graphData} />
//               <b>graphData {console.log('graphData -------- ', graphData)}</b>
//           ) : (
//               <button variant="secondary" onClick={RequestProfileData}>
//                   Request Profile Information
//               </button>
//           )}
//       </>
//   );
// };

// const MainContent = () => {
//   return (
//       <div className="App">
//           <AuthenticatedTemplate>
//               <ProfileContent />
//           </AuthenticatedTemplate>

//           <UnauthenticatedTemplate>
//               <h5 className="card-title">Please sign-in to see your profile information.</h5>
//           </UnauthenticatedTemplate>
//       </div>
//   );
// };


const AuthorizeAzure = (props) => {
  // const { companyId } = useParams();
  // const { setUser, setCompany } = useGlobalStore();

  // const [loader, setLoader] = useState(true)
  // const [serverErrorMsg, setServerErrorMsg] = useState()
  const isAuthenticated = useIsAuthenticated()

  // const loginUsingToken = (token) => {
  //   console.log('token ', token)
  //   const resp = {
  //     "email": token?.sub,
  //     "companyId" : companyId // AMAZON Company ID
  //   }
   
  //  AuthService.getSSOUserApi(resp)
  //  .then((resp) => {
  //    console.log('getSSOUserApi--------------------', resp)
  //    if(resp?.user?.UserId){
  //      setUser(resp.user)
  //      setCompany(resp.company)
  //      props.history.push(ROUTE_PROJECTS)
  //    }
  //    else{
  //      setServerErrorMsg('You do not have access to Insights Assistant. Please contact your administrator to get access.')
  //    }
  //  })
  //  .catch((error) => {
  //    const resMessage = error
  //    console.log('ERROR : ', resMessage)
  //  }).finally(() => {
  //    setLoader(false)
  //  })
  // }

  // const getTokenDetailsAzure = async () => {
  // }

  // useEffect(() => {
  //   getTokenDetailsAzure()
  // }, [])

  // useEffect(() => {
  //   localStorage.setItem('logs', JSON.stringify([]))
  //   localStorage.setItem('company', JSON.stringify({}))
  //   localStorage.setItem('user', JSON.stringify({}))
  // },[])

  // useEffect(() => {
  //   if(isAuthenticated){
  //     console.log('READY')
  //   }
  // },[isAuthenticated])

  console.log('READY')
  
  return (
    <>
      <PreLogin>

        {/* <MainContent /> */}

        {isAuthenticated && <h1>This is Azure Login-In</h1>}

        {/* <Heading type={serverErrorMsg}>
          <Typography color='textPrimary'>Authorization {serverErrorMsg ? 'Failed' : 'Page'}</Typography>
        </Heading>
        {loader && <Loader width={50} />}
        {serverErrorMsg && <ErrorMsg>{serverErrorMsg}</ErrorMsg>}
        <LinkButton href='/login'>Return in sign in</LinkButton> */}
      </PreLogin>
    </>
  )
}

export default AuthorizeAzure
