import React, { useState } from 'react'
import { Wrapper, FormLabel, TextFieldWrapper, FormError, TextFieldStyled, TextareaInput } from './style'

const MuiTextField = ({inputType, ...props}) => {
  const [value, setValue] = useState(props?.value || '')
  const [inputError, setInputError] = useState()
  const maxLen = props?.InputProps?.maxLength || props?.InputProps?.max || 0

  const validate = (event) => {
    let val = event.target.value

    if (props?.dataType === 'number') {
      val = val.replace(/[^0-9]/g, '')
    } else if (props?.dataType === 'decimal') {
      let decimalCount = 0
      val = val
        .replace(/[^0-9.]/g, '')
        .replace(/\./g, match => ++decimalCount > 1 ? '' : match)
    }

    if (maxLen > 0 && val.length > maxLen) {
      setInputError(`Max length is ${maxLen}`)
      val = val.slice(0, maxLen)
      setTimeout(() => {
        setInputError('')
      }, 4000)
    }

    setValue(val)
  }

  return (
    <Wrapper>
      {props.title && <FormLabel>{props.title}</FormLabel>}
      {props.name &&
        <TextFieldWrapper className='TextFieldWrapper'>
          {
            inputType === 'area' ? <TextareaInput minRows={3} {...props} /> :
            <TextFieldStyled
              {...props}
              shadow={props.shadow ? "true" : ''}
              compact={props.compact ? "true": ''}
              value={props?.value}
              onKeyDown={(e) => validate(e)}
              onKeyUp={(e) => validate(e)}
              autoComplete='off'
              inputProps={{ ...props?.inputProps, maxLength: maxLen || null }}
            />
          }
          
        </TextFieldWrapper>}
        { (inputError || props?.error) &&
          <FormError className='error'>{inputError || (inputType === "area" && props.error) && <FormError className='error'>{inputError || props.error}</FormError>}</FormError>
        }
    </Wrapper>
  )
}

export default MuiTextField
