import { styled } from '@mui/material/styles'
import { Box, IconButton } from '@mui/material'
import { MEDIA } from '../../../../constants'
import { MuiButton } from '../../../../components'


const fadeInEffect = `
    animation-name: fadeIn;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 1s;

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
`

export const OuterWrapper = styled(Box)(`
    padding:30px 20px 0 20px;
    position:relative;
    width:100%;

    display:flex;
    align-items:end;
    height: calc(100vh - 120px);
    

    @media (max-width: ${MEDIA.lg}) {
        padding:20px 20px 40px 20px;
    }
`)

export const InnerWrapper = styled(Box)(`
    display:flex; flex-direction: column; width:100%; position: relative; overflow:hidden;
`)

export const ChatOuterWrapper = styled(Box)(`
    display:flex;
    flex-direction:column;
    padding-top: 60px;
    max-height: calc(100vh - 305px);
    overflow-y:scroll;
    width: calc(100% - -17px);
    position:relative;

    &.stopTypingLoader:before{
        content:'';
        position: fixed;
        top: 0;
        right: 0;
        width:100%;
        height:100%;
        background:rgba(255, 255, 255, 0.1);
        z-index:2;
    }
`)

export const ChatInnerWrapper = styled(Box)(`
    display:flex;
    flex-direction:column;

    & .answerWrap:last-child{padding-bottom:30px;}
`)

export const ServerChatWrapper = styled(Box)(`
    padding:10px 0 15px;
    width:100%;
    display:flex;
    justify-content:start;

    &.answerWrap:first-of-type .copyButton{
        display:none;
    }

    &.loaderWrapper{
        width:120px;
    }

    ${fadeInEffect}
`)

export const ServerContentWrapper = styled(Box)(`
    position: relative;
    border: 1px #C3C3C3 solid;
    padding:15px 20px 15px 20px;
    width:80%;
    max-width:70%;
    border-radius:6px;
    background:#fff;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    &.loader {
        padding-right:20px;
    }
    & p {
        padding-bottom:10px;
    }
    & p:last-child {
        padding-bottom:0;
    }
    & .typingDone.none {display:none;}
`)

export const RawData = styled(Box)(`
    display:none;
`)

export const ClientChatWrapper = styled(Box)(`
    padding:10px 1px 15px;
    width:100%;
    display:flex;
    justify-content:end;

    @-moz-document url-prefix() {
        padding-right:15px;
    }

    &.questionWrap:nth-last-of-type(2) > div{
        background:#095BBD;
    }

    ${fadeInEffect}
`)

export const ClientContentWrapper = styled(Box)(`
    padding:15px 18px;
    width:auto;
    max-width:60%;
    border-radius: 6px;
    background:#7E7E7E;
    margin-right:3px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color:#fff;
    
    & p {
        padding-bottom:10px;
    }
    & > *:last-child {
        padding-bottom:0;
    }
`)

export const SearchBoxOuterWrapper = styled(Box)(`
    box-shadow: 0 0 10px 10px rgba(255, 255, 255, 1);
    // position: relative;
    width:100%;
`)

// export const SuggestionWrapper = styled(Box)(`
//     width:100%;
//     padding:14px 2px 14px 180px;
//     display:flex;
//     justify-content: end;
//     align-items: center;
//     flex-wrap:wrap;

//     &:before {
//         content: 'Suggestions:';
//         color: #095BBD;
//         font-size: 12px;
//         font-weight: 500;
//         line-height: 20px;
//     }
// `)

// export const SuggestionLink = styled('button')(`
//     text-wrap:nowrap;
//     border-radius: 4px;
//     font-size: 12px;
//     font-weight: 400;
//     line-height: 20px;
//     background: #FFF;
//     border: 1px #c3c3c3 solid;
//     padding: 9px 10px;
//     margin-left:10px;
//     cursor:pointer;
// `)

export const SearchWrapper = styled(Box)(`
    height:60px;
    width:100%;
    display:flex;
    justify-content: space-between;
    margin-bottom:15px;
    overflow:hidden;
    & > svg {
        width: 50px;
        height: 50px;
        margin-top: 5px;
    }
    & form {
        width:calc(100% - 70px);
        display:flex;
        justify-content: space-between;        
    }
`)

export const SearchInputBox = styled(Box)(`
    width: 100%;
    height: 60px;
    background: #FFF;
    border: 1px #E4E7EC solid;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    border-radius: 5px;
    overflow: hidden;

    & textarea {
        height: 53px !important;
        border-radius: 5px;
        overflow: hidden;
        padding: 0 30px 0 10px;
        font-size: 14px !important;
        line-height: 25px;
    }
    & textarea::placeholder {
        font-size: 14px !important;
    }
    & textarea.Mui-disabled{
        background:#fff;
    }
    & * {
        border-bottom:0;
    }
`)

export const SearchSubmit = styled(Box)(`
    width: 22px;
    height: 22px;
    display:block;
    background: #095BBD;
    border: 1px #E4E7EC solid;
    border:0;
    border-radius:50%;
    position:absolute;
    bottom:22px;
    right:5px;
    z-index:1;
    overflow:hidden;
    
    &:before {
        content: '';
        border: 6px transparent solid;
        border-left-color: #fff;
        position: absolute;
        top: 5px;
        left: 9px;
    }

    & button {
        width:100%;
        height:100%;
    }

    &.disabled {
        background: #d3d3d3;
    }
`)

export const LinksWrapper = styled(Box)(`
    opacity: 0;
    
    width:100%;
    display: flex;
    // flex-direction: column;
    align-items: flex-start;
    margin-right:20px;
    flex-wrap:wrap;

    &.show {
        opacity: 1;
        animation-name: slideDown;
        animation-iteration-count: 1;
        animation-timing-function: ease-in;
        animation-duration: 1s;
    }

    &.disable button {
        opacity: 0.6;
        cursor: default;
    }

    @keyframes slideDown {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    & h4 {
        padding-top:10px;
        padding-bottom:10px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        width:100%;
    }

    & > button {
        margin-right:20px;
        padding:0;
    }
    & > button:hover {
        background:none;
    }
    & > button span{
        margin-left:5px;
    }
`)

export const ServerContentLoader = styled(Box)(`
    margin-top: -15px;

    &.load-3 .line:nth-last-of-type(1) { animation: loadingC 0.6s 0.1s linear infinite; }
    &.load-3 .line:nth-last-of-type(2) { animation: loadingC 0.6s 0.2s linear infinite; margin:0 3px; }
    &.load-3 .line:nth-last-of-type(3) { animation: loadingC 0.6s 0.3s linear infinite; }
  
    @keyframes loadingC {
        0 { transform: translate(0, 0); }
        50% { transform: translate(0, 15px); }
        100% { transform: translate(0, 0); }
    } 

    @keyframes loadingJ {
        0%, 100% { transform: translate(0, 0); }
        50% { transform: translate(80px, 0); background-color: #f5634a; width: 25px; }
    }
  
    & .line {
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 10px;
        background-color: rgba(0,0,0,0.3);
    }
`)

export const IconCloseOuterWrapper = styled(Box)(`
    position: absolute;
    z-index:1;
    top: 0;
    left: 0;
    width: 100%;
    height: 45px;
    display: flex;
    justify-content: end;
    align-items: center;
    background: #fff;
    box-shadow: 0 0 10px 10px rgba(255,255,255,1);
`)

export const IconCloseWrapper = styled(Box)(`
    position: absolute;
    z-index:1;
    top: 10px;
    right: 15px;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    cursor: pointer;
`)

export const IconButtonWrapper = styled(Box)(`
    display:flex;
    width:100%;
    & .isLiked_true {}
    & .isLiked_false {}
`)

export const AllIconButton = styled(IconButton)(`
    height:30px;
    & span {
        display:block;
        overflow:hidden;
        background: rgba(0,0,0,0.01);
        width: 30px;
        padding: 0;
        height: 30px;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0.01;
    }

    &.btnThumbsUp:before {
        content: 'Like';
    }

    &.btnThumbsDown:before {
        content: 'Dislike';
    }

    &.btnCopy:before {
        content: 'Copy';
    }

    &:before, &:after {
        content: '';
        position: absolute;
        top: -28px;
        left: 50%;
        z-index: 1;
        background: #000;
        color: #fff;
        border-radius: 4px;
        padding: 4px 8px;
        display: none;
        font-size: 12px;
        transform: translate(-50%, 0);
    }
    &:after{
        display:none;
    }
    &:hover:before{
        display:block;
    }
    &.copied:hover:before{
        display:none !important;
    }
    &.copied:after{
        content: 'Copied';
        display:block;
    }

    border-radius:5px;

    // &.btnThumbsUp.isLiked_true svg path {fill: green;}
    // &.btnThumbsDown.isLiked_false svg path {fill: red;}
    &.btnThumbsUp.isLiked_true, &.btnThumbsDown.isLiked_false { background:#EEE; }
`)

export const StopTypingOverlay = styled(Box)(`
    position: fixed;
    width:100vw;
    height:100vh;
    top: 0;
    left: 0;
    z-index: 3;
    background: rgba(255,255,255,0.1);
    display:none;
`)

export const StopTyping = styled(MuiButton)(`
    margin-top:17px;
    border-radius:4px;
    position: absolute;
    &:before {
        content: '';
        background: #fff;
        width: 13px;
        height: 13px;
        margin-right:10px;
    }
`)







