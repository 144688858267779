import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import { factorBg } from '../../assets/images'

export const SvgWrapper = styled(Box)(`
    display:flex;
    position: relative;
    overflow:hidden;
    width:100%;
    margin: 0;
    padding: 0;
    & svg {
        position: relative;
    }
`)

export const FactorOuterWrapper = styled(Box)(`
    display:flex;
    flex-direction:column;
    position: relative;
    width:100%;
`)

////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

const getCircleWidth = ({size}) => {
    const s = size*2
    return s > 110 ? s : 110
}
const getCircleHeight = () => {
    return 160
}

export const PptOuterWrapper = styled(Box)(`
    height:550px;
    width:1200px;
    position:relative;
    display:flex;
`)
export const PptGraph = styled(Box)( props => `
    transform:scale(0.92);
    height:650px;
    width:800px;
    position:relative;
    margin:-30px auto 0;

    & .wrap_0 { left:calc(50% - 95px); top:calc(50% - ${getCircleHeight()/2}px); }
    & .wrap_0 .bigCircle {  margin-left:0; }
    & .wrap_0 .smallCircle { display:none; }
    & .wrap_0 .line { display:none; }

    & .wrap_1 { left:10px; top:calc(50% - ${getCircleHeight()/2}px); }
    & .wrap_2 {
        left: 90px; top: 110px; transform:rotate(60deg);
        & .circles {transform:rotate(-60deg)}
    }
    & .wrap_3 {
        left:255px; top:110px; transform:rotate(125deg);
        & .circles {transform:rotate(-125deg)}
    }
    & .wrap_4 {
        left:calc(50% - 20px); top:calc(50% - ${getCircleHeight(props)/2}px); transform:rotate(180deg);
        & .circles {transform:rotate(-180deg)}
    }
    & .wrap_5 {
        left:240px; top:370px; transform:rotate(245deg);
        & .circles {transform:rotate(-245deg)}
    }
    & .wrap_6 {
        left:105px; top:370px; transform:rotate(295deg);
        & .circles {transform:rotate(-295deg)}
    }

`)

export const PptInnerWrapper = styled(Box)(`
    width:50%;
    height:${getCircleHeight()}px;
    position: absolute;
    display: flex;
    justify-content: start;
    align-items: center;
`)

export const BigCircle = styled(Box)( props =>`
    width:${getCircleWidth(props)}px;
    height:${getCircleWidth(props)}px;
    display:flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius:50%;
    font-size:15px;
    line-height:15px;
    z-index:1;
    margin-left:20px;
    padding:10px;
`)

export const SmallCircle = styled(Box)(`
    width:30px;
    height:30px;
    display:flex;
    justify-content: center;
    align-items: center;
    border-radius:50%;
    background:#fff;
    border:1px #000 solid;
    font-size:12px;
    position: absolute;
    left:calc(50% + 15px);
    top:calc(50% - 15px);
    z-index:1;
`)

export const Line = styled(Box)(`
    width:calc(100% - 60px);
    height:1px;
    background:#000;
    position: absolute;
    left:30px;
    top:50%;
`)












