import styled from '@emotion/styled'
import { Box } from '@mui/material'

export const MediaWrapper = styled(Box)(`
  width: 100%;
  & img {
    max-width:200px;
    max-height:120px;
  }
  & video {
    max-width:300px;
    max-height:180px;
  }
  & span {
    font-size:11px;
    color:red;
    max-width:250px;
    overflow-wrap:anywhere;
  }
`)

