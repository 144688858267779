import React, { useRef, useState, useEffect } from 'react'
import { Editor } from "@tinymce/tinymce-react";
import { FormLabel, RteWrapper,  FormError } from './style'

const Rte = ({title, inputError, value, customToolbar, onChange, content_style, height=250, pastAsText=true}) => {
  const ref = useRef(value);
  const handleEditorChange = (newValue) => {
    onChange(newValue)
  };
  const toolbar = customToolbar || 'h4 | bold italic underline strikethrough | hr | subscript superscript | bullist numlist indent outdent | removeformat undo redo | link '
  const apiKeys = [
    'p3nbk0l216z1hnrgrff12wjhce2q80g3vda5k5g5zyr0lx84',
    // 'flix6vo48svxdakvekyikldh122lfib0bax7nbfsfhue3zvn',
    'mtbh6bzihvnmnn15vkp2zlyhj983xw0xqn3c03hpwyp0nno2', // ramacadab.jiqodoman@jollyfree.com // https://tempmail.email // 17/8/2024
    'l0kygs4xv01kdttyxh5bgf2qyxx2iwwnuykrvoyley13blsf', // dasivot.hahuhe@gotgel.org // https://tempmail.email // 17/8/2024
  ]
  const date = new Date()
  const apiKeyValue = date.getDate() <= 15 ? apiKeys[0] : apiKeys[1]

  // NOTE AFTER UPDATING THIS 
  return (
    <>
      {title && <FormLabel>{title}</FormLabel>}
      <RteWrapper>
        <Editor
          apiKey={apiKeyValue} // NEW
          // apiKey="flix6vo48svxdakvekyikldh122lfib0bax7nbfsfhue3zvn" // NEW
          // apiKey="p3nbk0l216z1hnrgrff12wjhce2q80g3vda5k5g5zyr0lx84" // OLD
          // initialValue={value}
          initialValue={ref.current}
          init={{
            height: height,
            menubar: false,
            browser_spellcheck: true,
            content_style: content_style,
            paste_as_text: pastAsText
          }}
          onEditorChange={handleEditorChange}
          outputFormat="html"
          selector= {'textarea'}
          // plugins= {'lists code table'}
          plugins= {'lists code table advlist'}
          table_toolbar= {'tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol'}
          // font_size_formats= {'8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt'}
          font_size_formats= {'8px 10px 12px 14px 16px'}


          // plugins: 'advcode',
          toolbar= {`${toolbar} code`}
          toolbar_={`
              bullist numlist indent outdent | 
              bold italic underline strikethrough | 
              alignleft aligncenter alignright alignjustify | 
              superscript subscript | fontsize |
              removeformat undo redo | 
              link | table
            `}
          default_link_target="_blank"
        />
      </RteWrapper>
      {inputError && <FormError>{inputError}</FormError>}
    </>
  )
}

export default Rte;