import * as React from 'react'
import { Loader } from '../'

const LoaderWrapper = ({loading, loadOnlyApi=false, children}) => {
  return (
    <>
      {
        (loading)
        ? <Loader />
        : !loadOnlyApi && <>{children}</>
      }
    </>
  )
}
export default LoaderWrapper
