import React from 'react'
import { logoMaven } from '../../assets/images'
import { useGlobalStore } from '../../store'
import { CanWeHelp, TourGuide } from '../../components'

import { FooterWrapper } from './style'

export const LayoutFooter = () => {
  const { isTourGuideActive } = useGlobalStore()
  return (
    <FooterWrapper className='footerWrapper'>
      <p> 
        © MavenMagnet  |  <a href='https://www.mavenmagnet.com/privacy-policy' target='_blank' rel='noopener noreferrer'>Privacy Policy</a>  |  <CanWeHelp />
      </p>
      <img src={logoMaven} height='22px' alt='' />
      {isTourGuideActive && <TourGuide type='reportFooter' />}
    </FooterWrapper>
  )
}
