import React, { useState, useEffect } from 'react'
import { ReportHeading, ReportOuterWrapper } from '../../../../components'
import { getImageUrl } from '../../../../util'
import { useUpdateLhs } from '../../../../hooks/useUpdateLhs'
import { OuterWrapper, ContentWrapper } from './style'

const ReportResearch = (props) => {
  const imgPath = getImageUrl('researchDesign', props.InsightsElementId, 'svg')
  const {updateSubLhsApiLoaded} = useUpdateLhs()
  const [eventCount, setEventCount] = useState(1)

  const imageLoaded = () => {
    setTimeout(() => {
      updateSubLhsApiLoaded(props)
      setEventCount(p => p+1)
    }, 4000)
  }

  useEffect(() => {
    setTimeout(() => {
      imageLoaded()
    }, 2000)
  }, [])


  return (
    <>
      {props?.type &&
        <>
          <ReportHeading resetInsightHeight={eventCount} downloadId={props.type} subHeading='' {...props} excel={false} />
          <ReportOuterWrapper>
            <OuterWrapper id={props.type}>
              {/* <LoaderWrapper loading={imageLoaded} loadOnlyApi={props.loadOnlyApi}> */}
                <ContentWrapper>
                  {imgPath && <img src={imgPath} alt='' onLoad={imageLoaded} />}
                </ContentWrapper>
              {/* </LoaderWrapper> */}
            </OuterWrapper>
          </ReportOuterWrapper>
        </>
      }
    </>
  )
}

export default ReportResearch
