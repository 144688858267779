import React from 'react';
import { BigArrow, BrandMagnetScoreTreeWrap, ChartList } from "./style"

const BrandMagnetScoreTree = ({ data = [], arrowdirection="right" }) => {

  return (
    <BrandMagnetScoreTreeWrap className='brandMagnetScoreTreeWrap' arrowdirection={arrowdirection}>
      <BigArrow arrowdirection={arrowdirection}/>
      <ChartList arrowdirection={arrowdirection} >
        {data.map((li, i) => {
          return (
            <li key={i}>
              <h3>{li.title}</h3>
              <img src={li?.imgSrc} alt={li.title} />
            </li>
          )
        })}
      </ChartList>
    </BrandMagnetScoreTreeWrap>
  )
}

export default BrandMagnetScoreTree;