import React from 'react'
import { Checkbox, FormControlLabel } from '@mui/material';
import { checkboxChecked, checkboxUnchecked, CheckBoxIcon, CheckBoxIcon2 } from '../../assets/images/icons';
import MuiImage from '../muiImage';
import styled from '@emotion/styled';

const FormGroupLabelWrap = styled(FormControlLabel)(`
  & .MuiTypography-root {
    font-size: 12px;
    // white-space: nowrap;
  }
`)

const MuiCheckbox = ({ styling='', indeterminate, className='', color="default", variant='default', onChange, checked = false, label="", disabled=false }) => {
  return (
    <>
      {styling === 'default'
        ? <FormGroupLabelWrap
            label={label}
            disabled={disabled}
            onChange={onChange}
            className={`formCheckbox ${className}`}
            control={<Checkbox
              variant={variant}
              color={color}
              indeterminate={indeterminate}
              icon={<CheckBoxIcon checked={false} />}
              checkedIcon={<CheckBoxIcon />}
              checked={checked} />}
          />
        : styling === 'filterSection'
        ? <FormGroupLabelWrap
            label={label}
            disabled={disabled}
            onChange={onChange}
            className={`formCheckbox ${className}`}
            control={<Checkbox
              variant={variant}
              color={color}
              indeterminate={indeterminate}
              icon={<CheckBoxIcon2 variant='outlined' color={color} />}
              checkedIcon={<CheckBoxIcon2 variant='contained' color={color} />}
              checked={checked} />}
          />
        : <FormGroupLabelWrap
          label={label}
          disabled={disabled}
          onChange={onChange}
          className={`formCheckbox ${className}`}
          control={<Checkbox
            indeterminate={indeterminate}
            icon={<MuiImage src={checkboxUnchecked} width="14px" />}
            checkedIcon={<MuiImage src={checkboxChecked} width="14px" />}
            checked={checked} />}
        />}
    </>
  )
}

export default MuiCheckbox