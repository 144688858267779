import React from 'react'
const IconRefresh = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
            <path d="M1.73479 13.154C1.61054 13.1541 1.48871 13.1196 1.38296 13.0543C1.27721 12.9891 1.19172 12.8957 1.13605 12.7846C0.345747 11.2122 0.0717119 9.43053 0.352969 7.69332C0.634227 5.95611 1.45644 4.35193 2.70251 3.10922C5.96749 -0.1562 11.2803 -0.1562 14.5453 3.10922C14.6672 3.23544 14.7347 3.40449 14.7331 3.57997C14.7316 3.75544 14.6612 3.92329 14.5372 4.04737C14.4131 4.17145 14.2452 4.24184 14.0697 4.24336C13.8943 4.24489 13.7252 4.17743 13.599 4.05552C10.8547 1.3121 6.39312 1.3121 3.64881 4.05552C2.60191 5.09963 1.91116 6.44744 1.67492 7.90701C1.43868 9.36658 1.669 10.8635 2.33309 12.1845C2.3843 12.2865 2.40857 12.3999 2.40359 12.514C2.39861 12.628 2.36454 12.7389 2.30463 12.836C2.24472 12.9332 2.16095 13.0134 2.06129 13.0691C1.96163 13.1247 1.84939 13.154 1.73524 13.154H1.73479Z" fill="white"/>
            <path d="M8.62389 17.3998C7.52426 17.4018 6.43508 17.1866 5.4189 16.7664C4.40272 16.3462 3.47957 15.7294 2.70249 14.9513C2.64033 14.8892 2.59101 14.8154 2.55735 14.7342C2.5237 14.653 2.50637 14.566 2.50635 14.4781C2.50633 14.3902 2.52362 14.3032 2.55723 14.222C2.59085 14.1408 2.64013 14.067 2.70227 14.0048C2.7644 13.9426 2.83817 13.8933 2.91937 13.8597C3.00056 13.826 3.08759 13.8087 3.17548 13.8087C3.26338 13.8087 3.35041 13.8259 3.43162 13.8596C3.51283 13.8932 3.58663 13.9425 3.64879 14.0046C6.3931 16.748 10.8547 16.748 13.599 14.0046C14.6459 12.9605 15.3366 11.6127 15.5729 10.1531C15.8091 8.69354 15.5788 7.19664 14.9147 5.87561C14.8351 5.71693 14.8218 5.53314 14.8778 5.36467C14.9337 5.1962 15.0543 5.05685 15.213 4.97727C15.3716 4.89769 15.5554 4.88441 15.7239 4.94035C15.8924 4.99628 16.0317 5.11685 16.1113 5.27553C16.7517 6.55214 17.0549 7.9714 16.9919 9.39827C16.9289 10.8251 16.5018 12.2121 15.7514 13.4274C15.0009 14.6426 13.952 15.6455 12.7044 16.3409C11.4569 17.0362 10.0521 17.4008 8.62389 17.3998Z" fill="white"/>
            <path d="M11.2209 4.53534C11.0497 4.53448 10.8853 4.46802 10.7616 4.34964C10.6379 4.23126 10.5643 4.06997 10.5559 3.89895C10.5475 3.72793 10.6049 3.56019 10.7164 3.43026C10.828 3.30032 10.985 3.21807 11.1553 3.20043L13.2715 2.9894L12.8472 0.862571C12.8148 0.68944 12.852 0.510526 12.9506 0.364629C13.0493 0.218732 13.2015 0.117615 13.3743 0.0832084C13.547 0.0488017 13.7263 0.0838788 13.8734 0.180833C14.0204 0.277787 14.1233 0.4288 14.1598 0.601123L14.7282 3.45341C14.7464 3.54497 14.7453 3.63935 14.7249 3.73047C14.7046 3.82159 14.6653 3.90743 14.6098 3.98248C14.5542 4.05753 14.4836 4.12012 14.4024 4.16624C14.3212 4.21235 14.2313 4.24097 14.1384 4.25024L11.2883 4.53444C11.2655 4.53444 11.2432 4.53534 11.2209 4.53534Z" fill="white"/>
            <path d="M3.74334 18.0003C3.58862 18.0002 3.43872 17.9465 3.31915 17.8483C3.19957 17.7501 3.11771 17.6135 3.08749 17.4618L2.51908 14.6117C2.50082 14.5201 2.50193 14.4257 2.52235 14.3346C2.54277 14.2434 2.58204 14.1576 2.63764 14.0825C2.69323 14.0075 2.76393 13.9449 2.84517 13.8988C2.92641 13.8527 3.0164 13.8241 3.10935 13.8149L5.9594 13.5307C6.13601 13.513 6.31243 13.5661 6.44986 13.6784C6.58729 13.7907 6.67447 13.9531 6.69222 14.1297C6.70997 14.3063 6.65683 14.4827 6.5445 14.6201C6.43218 14.7576 6.26985 14.8447 6.09325 14.8625L3.97713 15.0735L4.40098 17.2003C4.42033 17.2975 4.41787 17.3978 4.39376 17.4939C4.36966 17.59 4.32452 17.6795 4.2616 17.7561C4.19868 17.8326 4.11955 17.8942 4.02993 17.9364C3.9403 17.9787 3.84242 18.0005 3.74334 18.0003Z" fill="white"/>
        </svg>
    )
}

export default IconRefresh
