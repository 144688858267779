import React, { useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import MuiTextField from '../muiTextField';
import MuiImage from '../muiImage';
import { calendarIcon } from '../../assets/images/icons';
import { Box, Checkbox, FormControlLabel } from '@mui/material';
import { DateCalendar, DatePickerWrap } from './style';

const IconCalendar = () => {
  return(
    <Box sx={{
      position: 'absolute',
      right: '15px'
    }}>
      <MuiImage src={calendarIcon}/>
    </Box>
  )
}

const DatePicker = ({label, value, placeholder, name, error, format="DD MMMM YYYY", ...rest}) => {
  const [open, setOpen] = useState(false);

  return (
    <DatePickerWrap className='DatePickerWrap'>
      <LocalizationProvider dateAdapter={AdapterDayjs}>        
      <DesktopDatePicker
          label={label}
          open={open}
          onClose={() => setOpen(false)}
          inputFormat={format}
          ignoreInvalidInputs={true}
          placeholder={placeholder}
          value={value || null}
          renderInput={(params) => {
            delete params.label
            return <MuiTextField compact shadow {...params} error={error} helperText={error} name={name} title={label} onClick={() => setOpen(true)} inputProps={{
              ...params.inputProps,
              placeholder: placeholder,
            }} 
            InputProps={{
              ...params.InputProps,
              endAdornment: <IconCalendar />
            }}
            />
          }}
          {...rest}
        />
    </LocalizationProvider>
    </DatePickerWrap>
  );
}

export default DatePicker;