import { styled } from '@mui/system'
import { Grid, Box } from '@mui/material'

export const Wrapper = styled(Grid)(`
    // display:none;
`)

export const LhsWrapper = styled(Box)(`
    width:300px;
    padding:30px 20px;
    background-color: #F5F5F5;
    border-radius:15px 0px 0px 15px;
`)

export const ContentWrapper = styled(Box)(`
    width: calc(100% - 300px);
    padding: 30px 40px;
    position: relative;
    display:flex;
    flex-direction: column;
    & .DatePickerWrap{
        margin-top: 4px;
    }
    & .MuiTextField-root {
        height: 34px;
        & input {
            height: 34px;
            padding: 0px 12px;
        }
    }
    & .MuiFormHelperText-root.Mui-error, .selectError {
        margin-left: 0;
    }
`)

export const OuterWrapper = styled(Box)(`
    display:flex;
    min-height: 400px;
    border-radius: 15px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
`)

export const Footer = styled(Box)(`
    width: calc(100% + 80px);
    bottom: 0;
    padding: 20px 30px 0 0;
    margin-left: -40px;
    border-top: 1px solid #EEEEEE;
    text-align: right;
    margin-top: auto;
    & button {
        margin: 0 10px;
    }
`)

export const RootWrap = styled(Box)(`
    margin-top: 20px;
`);

export const IconWithTextWrap = styled(Box)(`
    display: flex;
    align-items: center;
    cursor: pointer;
    white-space: nowrap;
    width: min-content;
    margin-bottom: 20px;
`);

export const InputWrap = styled(Box)(`
    margin-bottom: 20px;
`);
