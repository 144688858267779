import React from 'react'
import Excel1 from './excel1'
import Excel3 from './excel3'
import ExcelHtml from './excelHtml'

const DownloadExcel = (props) => {
  const {downloadId, downloadPptData, InsightsElementType, downloadIdData, Title} = props

  const ImagesTypeExcelArray = ['CustomImage', 'ResearchDesign__']

  const drivers = ['SearchDrivers', 
    'DriversAndSubBrands', 'DriversWithoutSentimentsAndSubBrands', 'DriversAndTriggers', 'DriversAndRatingImpacts', 'Drivers', 'DriversWithoutSentiments',
    'DriversAndSubBrandsMotivatorsDetractors', 'DriversAndTriggersMotivatorsDetractors', 'DriversAndRatingImpactsMotivatorsDetractors', 'DriversMotivatorsDetractors', 'DriversWithoutSentimentsMotivatorsDetractors'
  ]
  const segments = ['SegmentDriversAnalysis', 'DriversProgression', 'DriversProgressionWithoutSentiments', 'SearchDriversProgression', 'Triangulation', 'TriangulationComparisonViewOnly', 'SegmentDriversOnlyAnalysis']
  const mining = ['InsightsMiningFrameworkWithSentiments', 'InsightsMiningFrameworkWithoutSentiments']
  const mix = ['BrandAttributeMapElement', 'BrandAttributeMapAndProgression', 'ImpactDestinations', 'BusinessObjective', 'Recommendation', 'Aura', 'BrandCategoryOverview', 'BrandCategoryOverviewAndProgression', 'BrandImageryMappingGraph', 'BrandImageryMappingAndProgressionGraph', 'BrandImageryMappingTable', 'CampaignTriggers', 'CampaignAndIssuesAssessmentSetup', 'FactorInterlinkage']
  const segmentation = ['SegmentOfSegmentOverview', 'SegmentOfSegmentOverview20Colors', 'SegmentAndSegmentOfSegment', 'SegmentDriversOnlyAnalysis3Level', 'SearchDriversProgression3Level', 'DriversProgression3Level', 'DriversProgressionWithoutSentiments3Level']
  const nameValueObjects = ['SegmentOverview', 'TopRelativeBrandsAnalysis', 'AllRelativeBrandsAnalysis', 'CompetitiveLandscape', 'BuzzMetrics', 'ProductRatingOptimizationSetup', 'BrandHealthSetup', 'BrandMagnetScore', 'CategoryExplorationSetup', 'MultiBrandNPS']
  const ContentExcelArray = [
     ...mix, ...drivers, ...segments, ...mining, ...segmentation, ...nameValueObjects
  ]

  return (
    <>
      {
        (downloadId && ImagesTypeExcelArray.includes(InsightsElementType))
          ? <ExcelHtml downloadId={downloadId} />
          // : ContentExcelArray.includes(InsightsElementType) ? <Excel3 type={InsightsElementType} data={downloadPptData || downloadIdData} downloadId={downloadId} /> : <Excel1 downloadId={downloadId} />
          : ContentExcelArray.includes(InsightsElementType) ? <Excel3 title={Title} type={InsightsElementType} data={downloadIdData} downloadId={downloadId} /> : <Excel1 downloadId={downloadId} />
      }
      {/* {downloadId && <Excel1 downloadId={downloadId} />} */}
    </>
  )
}

export default DownloadExcel
