import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
// import imgPath from './img-70a2369b-fafb-11ee-8482-128e718ba88f.png'
import BuyerTable from '../../../pages/reports/components/buyerMaturity/buyerTable'
import { CUSTOM_IMG_SEGMENT_COLOR } from '../../../constants'
import { D3Pie, Loader } from '../../../components'
import { useUpdateLhs } from '../../../hooks/useUpdateLhs'
// import { barGraph_data_70a2369b_fafb_11ee_8482_128e718ba88f, pieChart_data_70a2369b_fafb_11ee_8482_128e718ba88f } from './data'
import ReportsService from '../../../services/reports.service'
import { Wrapper, D3PieWrapper, RhsWrapper, Heading, Para, LineWrap } from './style'

const PieComponent = (props) => {
  const {pie, bar, id, pageUrlId} = props
  const [loading, setLoading] = useState(false)
  const [segmentData, setSegmentData] = useState([])
  const [pieData, setPieData] = useState([{},{},{},{},{}])
  const [showD3Pie, setShowD3Pie] = useState(false)
  const [pieSettings, setPieSettings] = useState()
  const {updateSubLhsApiLoaded} = useUpdateLhs()
  const domain = (window.location.host.indexOf('insightsassistant') !== -1 ? 'https://' : 'http://') + window.location.host
  const dataId = pie

  const getPersonaData = (index) => {
    setLoading(true)
    ReportsService.getCategoryPersona({ insightsElementId: dataId[index].id }).then(resp => {
      const object = {
        len: segmentData.length,
        id: dataId[index].id,
        key: dataId[index].key,
        color: resp.PersonaColor,
        url: `${domain}/${dataId[index].url}/${pageUrlId}`,
        sectionMinHeight: dataId[index].minHeight,
        personaName: resp.PersonaName,
        personaPercent: resp.PersonaPercent,
        personaDescription: resp.PersonaDescription
      }
      setSegmentData(prev => ([...prev, object]))

      const obj = {name:resp.PersonaName, value: resp.PersonaPercent, color: object.color}
      const pieArray = pieData
      if(index === 0) { pieArray[4] = obj }
      else { pieArray[index-1] = obj }

      setPieData(pieArray)

    }).catch(error => (
      console.log(error)
    )).finally(() => {
      setLoading(false)
    })
  }

  useEffect(() => {
    if(segmentData.length !== dataId.length){
      getPersonaData(segmentData.length)
    } else {
      setShowD3Pie(true)
      const settings = {
        width: 400,
        height: 400,
        cx: 1,
        cy: 1,
        outerRadius: 190,
        colors: pieData?.map(d => d.color) || []
      }
      setPieSettings(settings)
      updateSubLhsApiLoaded(props)
    }
  }, [segmentData])

  return (
    <>
      {showD3Pie ? <Wrapper className='pieWrapper'>
        <D3PieWrapper>
          <D3Pie data={pieData} settings={pieSettings} />
        </D3PieWrapper>
        <RhsWrapper style={{marginTop:'8px'}}>
          {segmentData?.length && segmentData.map((d, i) => {
            // <Link to={d.url} key={`segmentData__${d.id}`}></Link>
            return (
              <a href={d.url} key={`segmentData__${d.id}`}>
                <Lines index={i} id={id} />
                <Heading>{d.personaName} - <span>{d.personaPercent}%</span></Heading>
                <Para style={{minHeight: d.sectionMinHeight}} dangerouslySetInnerHTML={{__html: d.personaDescription}}></Para>
              </a>
            )
          })}
        </RhsWrapper>
      </Wrapper> : <Loader />}
      {showD3Pie && <Wrapper className='barWrapper'>
        <BuyerTable colorPallet={CUSTOM_IMG_SEGMENT_COLOR} data={bar} />
      </Wrapper>}
    </>
  )
}

export default PieComponent


const Lines = ({index, id}) => {
  if( ['ee0f401e-424d-11ef-8482-128e718ba88f', '70a2369b-fafb-11ee-8482-128e718ba88f', '63357b94-b2da-11ee-9f55-0a1ea4c5532f'].includes(id)){
    return (
      <LineWrap>
        { index === 0 && <svg width="237" height="81" viewBox="0 0 237 81" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 76L81.5109 1H237" stroke="black"/><circle cx="4.86677" cy="75.7013" r="4.37312" fill="white"/></svg> }
        { index === 1 && <svg style={{ top: '-5px'}} width="120" height="9" viewBox="0 0 120 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.54297 4.97656H76.2692H119.04" stroke="black"/><circle cx="4.54304" cy="4.57917" r="4.37312" fill="white"/></svg> }
        { index === 2 && <svg width="97" height="38" viewBox="0 0 97 38" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M97 1H85.1169L7 33" stroke="black"/><path d="M9.39385 33.1336C9.39385 35.5488 7.43594 37.5067 5.02073 37.5067C2.60553 37.5067 0.647614 35.5488 0.647614 33.1336C0.647614 30.7184 2.60553 28.7605 5.02073 28.7605C7.43594 28.7605 9.39385 30.7184 9.39385 33.1336Z" fill="white"/></svg> }
        { index === 3 && <svg style={{ top: '-15px'}} width="211" height="17" viewBox="0 0 211 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.5 4L194.995 16H211" stroke="black"/><circle cx="4.37312" cy="4.37312" r="4.37312" fill="white"/></svg> }
        { index === 4 && <svg style={{ top: '-151px'}} width="293" height="153" viewBox="0 0 293 153" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 5L30.3763 152H293" stroke="black"/><circle cx="5.20869" cy="4.86525" r="4.37312" fill="white"/></svg> }
      </LineWrap>
    )
  }
  else if( ['5e95d366-6bf1-11ee-817c-0a1ea4c5532f'].includes(id)){
    return (
      <LineWrap>
        { index === 0 && <svg width="237" height="81" viewBox="0 0 237 81" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 76L81.5109 1H237" stroke="black"/><circle cx="4.86677" cy="75.7013" r="4.37312" fill="white"/></svg> }
        { index === 1 && <svg width="120" height="9" viewBox="0 0 120 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.54297 4.97656H76.2692H119.04" stroke="black"/><circle cx="4.54304" cy="4.57917" r="4.37312" fill="white"/></svg> }
        { index === 2 && <svg width="97" height="38" viewBox="0 0 97 38" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M97 1H85.1169L7 33" stroke="black"/><path d="M9.39385 33.1336C9.39385 35.5488 7.43594 37.5067 5.02073 37.5067C2.60553 37.5067 0.647614 35.5488 0.647614 33.1336C0.647614 30.7184 2.60553 28.7605 5.02073 28.7605C7.43594 28.7605 9.39385 30.7184 9.39385 33.1336Z" fill="white"/></svg> }
        { index === 3 && <svg style={{ top: '-15px'}} width="211" height="17" viewBox="0 0 211 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.5 4L194.995 16H211" stroke="black"/><circle cx="4.37312" cy="4.37312" r="4.37312" fill="white"/></svg> }
        { index === 4 && <svg style={{ top: '-262px'}} width="355" height="270" viewBox="0 0 355 270" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 6L90.394 262H440" stroke="black"/><path d="M11 5.5C11 8.53757 8.53757 11 5.5 11C2.46243 11 0 8.53757 0 5.5C0 2.46243 2.46243 0 5.5 0C8.53757 0 11 2.46243 11 5.5Z" fill="white"/></svg> }
      </LineWrap>
    )
  }
  else if( ['9d0c623b-b2de-11ed-817c-0a1ea4c5532f'].includes(id)){
    return (
      <LineWrap>
        { index === 0 && <svg width="237" height="81" viewBox="0 0 237 81" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 76L81.5109 1H237" stroke="black"/><circle cx="4.86677" cy="75.7013" r="4.37312" fill="white"/></svg> }
        { index === 1 && <svg width="120" height="9" viewBox="0 0 120 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.54297 4.97656H76.2692H119.04" stroke="black"/><circle cx="4.54304" cy="4.57917" r="4.37312" fill="white"/></svg> }
        { index === 2 && <svg width="101" height="97" viewBox="0 0 101 97" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M101 0.500092H76L5 92" stroke="black"/><circle cx="5.5" cy="91.5" r="5.5" fill="white"/></svg> }
        { index === 3 && <svg style={{ top: '-15px'}} width="211" height="17" viewBox="0 0 211 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.5 4L194.995 16H211" stroke="black"/><circle cx="4.37312" cy="4.37312" r="4.37312" fill="white"/></svg> }
        { index === 4 && <svg style={{ top: '-247px'}} width="355" height="250" viewBox="0 0 355 250" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 6L89.394 248H415" stroke="black"/><path d="M11 5.5C11 8.53757 8.53757 11 5.5 11C2.46243 11 0 8.53757 0 5.5C0 2.46243 2.46243 0 5.5 0C8.53757 0 11 2.46243 11 5.5Z" fill="white"/></svg> }
      </LineWrap>
    )
  }
}
