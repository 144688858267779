import React from 'react'

const postLoginData = [
    {
      selector: '.lhsRoot .lhsWrapper .lhsLevel1_UserManagement .link',
      valign: 'bottom',
      align: 'right',
      style: {marginTop:'190px', marginLeft:'30px'},
      content: 'User management',
      // hideMedia: 1400,
      type: 'desktop'
    },
    {
      selector: '.MuiAvatar-circular.MuiAvatar-colorDefault',
      valign: 'bottom',
      align: 'left',
      content: 'Profile Information'
    },
    {
      selector: '.BtnProjectsFilter',
      valign: 'bottom',
      align: 'left',
      content: 'Click here to filter projects'
    },
    {
      selector: '.showProjectDetails',
      valign: 'bottom',
      align: 'left',
      content: 'Click here to see the project details'
    },
    {
      selector: '.BtnUserManagementFilter',
      valign: 'bottom',
      align: 'right',
      content: 'Click here to filter by user type (Admin or Member)'
    },
    {
      selector: '.UserList',
      valign: 'bottom',
      align: 'right',
      style: {marginTop:'45px', marginLeft:'130px'},
      content: 'View/Edit User Details'
    },
    {
      selector: '.BtnUserManagementAddNew',
      valign: 'bottom',
      align: 'left',
      content: 'Click here to add a new user'
    },
    // {
    //   selector: '.tabLevel2',
    //   valign: 'top',
    //   align: 'center',
    //   style: {marginTop:'20px'},
    //   content: 'Click here to jump to a specific analysis component'
    // },
    {
      selector: '.LhsInitiateNewProject',
      valign: 'top',
      align: 'right',
      arrowDirection: 'upSideDown',
      style: {marginLeft:'290px', width:'100px'},
      content: 'Select a project type'
    },
    {
      selector: '#BtnInitiateNewProject',
      valign: 'bottom',
      align: 'left',
      style: {marginLeft:'70px'},
      content: 'Click to initiate new project'
    },
    {
      selector: '#BtnDraft',
      valign: 'bottom',
      align: 'left',
      style: {marginLeft:'30px'},
      content: 'List of saved project drafts (not yet initiated)'
    }
]

const postLoginReportData = [
    {
      selector: '.desktopMenu.headerLogo img',
      valign: 'bottom',
      align: 'right',
      content: 'Click to go to project list',
      style: {marginLeft:'20px'},
      // hideMedia: 1400,
      type: 'desktop'
    },
    {
      selector: '.mobileMenu.headerLogo img',
      valign: 'bottom',
      align: 'right',
      style: {paddingTop:'40px', marginLeft:'80px'},
      content: 'Click to go to project list',
      type: 'mobile'
    },
    {
      selector: '.lhsWrapper.lhsLinks',
      valign: 'bottom',
      align: 'right',
      style: {marginTop:'110px', marginLeft:'120px'},
      content: 'Click here to go to a study section',
      // hideMedia: 1400,
      type: 'desktop'
    },
    {
      selector: '.lhsLinks',
      valign: 'bottom',
      align: 'right',
      style: {marginTop:'73px', marginLeft:'180px'},
      content: 'Collapse Study Menu',
      // hideMedia: 1400,
      type: 'desktop'
    },
    {
      selector: '.lhsHamburger',
      valign: 'bottom',
      align: 'right',
      style: {marginTop:'100px', marginLeft:'10px'},
      content: 'Expand Study Menu',
      // hideMedia: 1400,
      type: 'desktop'
    },
    {
      selector: '.MuiAvatar-circular.MuiAvatar-colorDefault',
      valign: 'bottom',
      align: 'left',
      content: 'Profile Information'
    },
    // {
    //   selector: '.tabLevel2',
    //   valign: 'top',
    //   align: 'right',
    //   style: {margin:'105px 0px 0px 140px'},
    //   content: 'Click here to jump to a specific analysis component',
    //   // hideMedia: 1400,
    //   type: 'desktop'
    // }
]

const reportData = [
    {
      selector: '.BtnExportToExcel',
      valign: 'bottom',
      align: 'left',
      style: 'min-width:100px; margin-left:40px;',
      content: 'Export to Excel'
    },
    {
      selector: '.BtnCanWeHelpButton',
      valign: 'top',
      align: 'right',
      style: 'min-width:100px; margin-left:40px;',
      content: 'Can we Help?'
    },
    {
      selector: '.BtnExploreInterLinkages',
      valign: 'bottom',
      align: 'left',
      style: 'margin-left:120px; max-width:100px;',
      content: 'Explore Interactive Interlinkages'
    },
    {
      selector: '.BtnObservations',
      valign: 'bottom',
      align: 'left',
      style: 'margin-left:130px;',
      content: 'Add observations or start a discussion'
    },
    {
      selector: '.BtnExpandAllMining',
      valign: 'bottom',
      align: 'left',
      style: 'margin-left:40px;',
      content: 'Click here to expand all deep dive sections'
    },
    {
      selector: '.BtnCollapseAllMining',
      valign: 'bottom',
      align: 'left',
      style: 'margin-left:40px;',
      content: 'Click here to close all deep dive sections'
    },
    {
      selector: '.BtnExpandAllDrivers',
      valign: 'bottom',
      align: 'left',
      content: 'Click here to expand all driver deep dive sections',
      style: 'margin-left:30px;'
    },
    {
      selector: '.BtnCollapseAllDrivers',
      valign: 'bottom',
      align: 'left',
      content: 'Click here to close all deep dive sections',
      style: 'margin-left:40px;'
    },
    // {
    //   selector: '.showBtnTrExpandIndex .BtnTrExpand',
    //   valign: 'bottom',
    //   align: 'left',
    //   style: 'min-width: 130px; margin-left:30px;',
    //   content: 'Quantitative driver deep dive'
    // },
    {
      selector: '.showBtnTrExpandIndex .BtnTrExpand',
      valign: 'bottom',
      align: 'left',
      style: 'min-width: 130px; margin-left:30px;',
      content: 'Quantitative driver deep dive'
    },
    {
      selector: '.showBtnTrQuotesIndex .BtnTrQuotes',
      valign: 'top',
      align: 'left',
      style: 'min-width: 100px; margin:5px 0px 0px 25px;',
      content: 'Sample verbatim'
    },
    {
      selector: '.sandBoxFilter',
      valign: 'top',
      align: 'center',
      content: 'Click here to view the verbatim for filter selection'
    },
    {
      selector: '.BtnExportToPowerPoint',
      valign: 'bottom',
      align: 'left',
      content: 'Export specific sections or complete report to PowerPoint',
      style: 'min-width:150px; margin-left:40px;'
      // style: 'margin-left:8px; min-width:150px;'
    },
    {
      selector: '.BtnAskIA',
      valign: 'center',
      align: 'left',
      content: 'Generative AI-powered Q&A',
      style: 'width:130px; margin-left:35px;'
      // style: 'margin-left:8px; min-width:150px;'
    },
    {
      selector: '.lhsHamburger',
      valign: 'center',
      align: 'left',
      content: 'Expand Study Menu',
      style: 'min-width:150px; margin-left:40px;'
      // style: 'margin-left:8px; min-width:150px;'
    },
    {
      selector: '.auraTitle',
      valign: 'top',
      align: 'center',
      content: 'Click on a word/phrase to see sample verbatim'
    },
    {
      selector: '.ComparisonViewSegmentView0',
      valign: 'top',
      align: 'left',
      content: 'Segment comparison for each driver'
    },
    {
      selector: '.ComparisonViewSegmentView1',
      valign: 'top',
      align: 'right',
      style: 'min-width:140px;',
      content: 'Click here to view drivers for each segment'
    },
    {
      selector: '.ComparisonViewPeriodicView0',
      valign: 'top',
      align: 'left',
      content: 'Periodic comparison for each driver'
    },
    {
      selector: '.ComparisonViewPeriodicView1',
      valign: 'top',
      align: 'right',
      style: 'min-width:140px;',
      content: 'Click here to see drivers for each period'
    },
    {
      selector: '.segmentRowExpand',
      valign: 'bottom',
      align: 'left',
      style: 'min-width:150px; margin-left:40px;',
      content: 'Click here to expand deep dive sections'
    },
    ///////////////////////////////////////
    {
      selector: '.tabTutorial_Driver',
      valign: 'bottom',
      align: 'right',
      style: 'margin-left:20px; min-width:100px;',
      content: 'Driver Analysis'
    },
    {
      selector: '.headerLastCol .tabTutorial_Motivators',
      valign: 'top',
      align: 'right',
      style: 'margin-left:20px; min-width:100px;',
      content: 'Motivators-positive drivers-analysis'
    },
    {
      selector: '.tabTutorial_Detractors',
      valign: 'bottom',
      align: 'right',
      style: 'margin-left:20px; min-width:100px;',
      content: 'Detractors-negative drivers-analysis'
    },

    {
      selector: '.tabTutorial_CurrentPeriod',
      valign: 'bottom',
      align: 'right',
      style: 'margin-left:20px; min-width:100px;',
      content: 'Current Period Analysis'
    },
    {
      selector: '.tabTutorial_Progression',
      valign: 'top',
      align: 'right',
      style: 'margin-left:20px; min-width:100px;',
      content: 'Progression Analysis'
    },
]

const arrowSvg = <svg width="40" height="40" viewBox="0 0 30 45" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M39.5786 38.1921C3.66474 33.5633 4.58252 1.56834 4.5957 1.23914L6.17728 1.76033C6.17483 1.83998 6.03041 9.40031 10.2536 17.7197C15.894 28.8314 26.4654 35.2217 41.6877 36.7094L41.5208 38.4109C40.8635 38.3467 40.2129 38.273 39.5789 38.1913L39.5786 38.1921Z" fill="white"/>
  <path d="M1.92296 9.08885C1.86205 9.081 1.8002 9.06642 1.73926 9.04474C1.29531 8.88351 1.06635 8.39286 1.22817 7.94899L3.79789 0.875748C3.89872 0.597162 4.13634 0.390906 4.42671 0.330336C4.71346 0.269898 5.01634 0.364244 5.22024 0.578101L9.55928 5.14916C9.88487 5.49172 9.87045 6.03277 9.52855 6.35784C9.18783 6.68306 8.64472 6.67078 8.32062 6.326L4.93226 2.75692L2.83464 8.53168C2.69613 8.91603 2.3115 9.13893 1.92296 9.08885Z" fill="white"/>
</svg>

const arrowSvgStr = (align, valign, arrowDirection) => {
  let style = 'width:100%;'
  if(align==='left'){style += 'transform:scaleX(-1);'}
  if(valign==='top'){style += 'transform:scaleY(-1); align-self:end;'}
  if(valign==='top' && align==='left'){style += 'transform:scaleX(-1) scaleY(-1); align-self:end;'}
  if(arrowDirection==='upSideDown'){style += 'transform:scaleY(-1);'}
  return `<svg width="40" height="40" viewBox="0 0 30 45" fill="none" xmlns="http://www.w3.org/2000/svg" style="${style}">
    <path d="M39.5786 38.1921C3.66474 33.5633 4.58252 1.56834 4.5957 1.23914L6.17728 1.76033C6.17483 1.83998 6.03041 9.40031 10.2536 17.7197C15.894 28.8314 26.4654 35.2217 41.6877 36.7094L41.5208 38.4109C40.8635 38.3467 40.2129 38.273 39.5789 38.1913L39.5786 38.1921Z" fill="white"/>
    <path d="M1.92296 9.08885C1.86205 9.081 1.8002 9.06642 1.73926 9.04474C1.29531 8.88351 1.06635 8.39286 1.22817 7.94899L3.79789 0.875748C3.89872 0.597162 4.13634 0.390906 4.42671 0.330336C4.71346 0.269898 5.01634 0.364244 5.22024 0.578101L9.55928 5.14916C9.88487 5.49172 9.87045 6.03277 9.52855 6.35784C9.18783 6.68306 8.64472 6.67078 8.32062 6.326L4.93226 2.75692L2.83464 8.53168C2.69613 8.91603 2.3115 9.13893 1.92296 9.08885Z" fill="white"/>
  </svg>`
}

export {
    postLoginData,
    postLoginReportData,
    reportData,
    arrowSvg,
    arrowSvgStr
}