import styled from "@emotion/styled";
import { Stack, Box, Typography } from "@mui/material";

export const HeaderWrap = styled(Box)(`
    width: 100%;
    flex-direction:column;
`)

export const TextNumberBoxHeading = styled(Typography)(`
    width: 100%;
    font-size: 20px;
    font-weight: 300;
    display:none;
`)

export const TextNumberBoxWrapper = styled(Stack)(`
    width: 100%;
    display:flex;
    flex-direction:row;
    margin-left: -8px;
    width: calc(100% + 16px);
    & > div {
        margin:0 8px;
    }
`)
