import React from 'react'
import { download } from '../../assets/images/icons';
import Loader from '../loader';
import MuiButton from '../muiButton/muiButton';
import MuiImage from '../muiImage';

const MuiButtonWithIcon = ({ loading = false, onClick, disabled = false, label = "label", icon, children,  ...rest }) => {
  return (
    <MuiButton
      variant='standard'
      color='primary'
      onClick={onClick}
      disabled={disabled}
      startIcon={loading ? <Loader size={15} /> : icon ? <MuiImage src={icon} /> : null }
      {...rest}
    >
      {children}
    </MuiButton>
  )
}

export default MuiButtonWithIcon