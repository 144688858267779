import React from 'react'

const GreenArrow = () => {
  return (
    <svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg" style={{transform: 'scale(1.3)'}}>
      <path d="M4.70047 1.06559C5.10048 0.532639 5.90003 0.532639 6.30005 1.06559L9.92832 5.89972C10.4231 6.55891 9.95275 7.5 9.12854 7.5H1.87198C1.04777 7.5 0.577436 6.55891 1.0722 5.89972L4.70047 1.06559Z" fill="#00B050"/>
    </svg>
  )
}

export default GreenArrow
