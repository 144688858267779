import React from 'react'

const IconUser = ({fill="black", width=18, height=23}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 18 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.93513 12.8124C7.23608 12.8127 5.6064 12.1379 4.40516 10.9365C3.20355 9.73521 2.52844 8.10587 2.52827 6.40653C2.52811 4.70747 3.20288 3.07796 4.40433 1.87656C5.60577 0.67511 7.23512 2.95824e-08 8.9343 2.95824e-08C10.6333 -0.000163151 12.2629 0.674776 13.4643 1.87622C14.6657 3.07767 15.3408 4.70718 15.3408 6.40619C15.339 8.10458 14.6637 9.73296 13.4628 10.9341C12.262 12.135 10.6336 12.8106 8.93534 12.8124L8.93513 12.8124ZM8.93513 1.17004C7.54607 1.16971 6.21397 1.72131 5.2318 2.70349C4.24946 3.6855 3.69756 5.01759 3.69756 6.40648C3.69739 7.79555 4.24917 9.12764 5.23134 10.1098C6.21352 11.092 7.54561 11.6438 8.93467 11.6436C10.3236 11.6436 11.6557 11.0917 12.6377 10.1095C13.6198 9.12718 14.1714 7.79509 14.1713 6.40602C14.1698 5.0178 13.6177 3.68683 12.636 2.70516C11.6544 1.72365 10.3234 1.17154 8.93517 1.17008L8.93513 1.17004Z" fill={fill}/>
        <path d="M14.041 23.0001H3.82743H3.8276C2.73938 22.9993 1.70274 22.5356 0.976715 21.7249C0.25086 20.9142 -0.0960083 19.8329 0.0229416 18.7512C0.24254 16.711 1.3983 14.889 3.15002 13.8207L4.26255 13.137C4.53795 12.9681 4.89819 13.0542 5.06722 13.3296C5.23625 13.605 5.14994 13.9651 4.8747 14.1341L3.76217 14.8179C2.31848 15.6979 1.366 17.1989 1.18457 18.8798C1.10104 19.6321 1.34168 20.3844 1.84648 20.9482C2.35127 21.5122 3.07224 21.8346 3.82925 21.8346H14.0407C14.7977 21.8346 15.5187 21.5122 16.0235 20.9482C16.5282 20.3844 16.7689 19.6321 16.6854 18.8798C16.5039 17.1989 15.5515 15.6979 14.1078 14.8179L12.9952 14.1341C12.72 13.9651 12.6337 13.605 12.8027 13.3296C12.9717 13.0542 13.332 12.9681 13.6074 13.137L14.7199 13.8207C16.4715 14.8888 17.627 16.7106 17.847 18.7504C17.9664 19.8325 17.6196 20.9144 16.8932 21.7256C16.1669 22.5366 15.1296 23.0001 14.0407 23.0001H14.041Z" fill={fill}/>
    </svg>
  )
}

export default IconUser