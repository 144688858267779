import { styled } from '@mui/material/styles'
import { Box, Typography, Menu } from '@mui/material'
import MuiButton from '../muiButton'

export const Wrapper = styled(Box)(`
  width:100%;
  // max-width:280px;
  // min-width:280px;
`)

export const MenuWrapper = styled(Menu)(`
  margin-top:40px;
`)

export const Title = styled(Typography)(`
  margin-top: -10px;
  padding-bottom: 30px;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
`)

export const SearchBoxOuterWrapper = styled(Box)(`
  padding:0 20px 15px 20px;
`)

export const SearchBoxWrapper = styled(Box)(`
  background: #F5F5F5;
  border-radius: 2px;
  width:240px;
  // height:25px;
  position: relative;
  display: flex;

  & .MuiBox-root {
    width: 100%;
  }

  & .MuiOutlinedInput-root {
    width: 100%;
    padding: 5px 10px;
    min-height:25px;
    line-height: 16px;
  }

  & button {
    min-width: 30px;
    // position:absolute;
    // top:0;
    // right:5px;
    // width:30px;
    // height:25px;
    // padding:0;
    // min-width:auto;
  }

  & input {
    font-size: 13px;
    padding-left: 6px;
  }
`)

export const ObservationsWrapper = styled(Box)(`
  margin-left:15px;
  position:relative;
  display:inline-block;
`)

export const MuiButtonObservations = styled(MuiButton)(`
  font-weight:300;
  width:100%;
  justify-content:space-between;
`)

export const ListItemTextWrapper = styled(Box)(`
  padding:0 5px 0 20px;
  max-height: 250px;
  overflow-y: scroll;
  width:calc(100% + 12px);
  @-moz-document url-prefix() {
    width:calc(100%);
  }

  & span {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #095BBD;
  }
  & p {
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #000000;
    padding-bottom:15px;
    white-space: pre-wrap;
  }
`)

export const CloseWrap = styled(Box)(`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  z-index:3;
`)

export const MenuOuterWrapper = styled(Box)(`
  position: absolute;
  top:40px;
  right: 0;
  z-index:10;
  width: 280px;
  background: #FFFFFF;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  padding-top:35px;
  overflow-x:hidden;
`)

