import { styled } from '@mui/material/styles'
import { Box, Typography, IconButton } from '@mui/material'
import { exportToPPT_tableCss } from '../../../../util'
import { MEDIA } from '../../../../constants'

export const OuterWrapper = styled(Box)(`
    width:100%;
`)

export const ContentInnerWrapper = styled(Box)(`
    border-bottom:1px solid #E0E0E0;
    width:100%;
    display:flex;
`)

const dynamicPadding = (length = 0) => {
    return '2px'
    // return '10px'
    // let paddingY = '0'
    
    // if(length > 12) { paddingY = '1px' }
    // else if(length > 10) { paddingY = '4px' }
    // else if(length > 8) { paddingY = '8px' }
    // else { paddingY = '10px' }
    // return paddingY
}

export const GridHeaderWrapper = styled(Box)( props =>`
    display:flex;
    width:100%;
    position:relative;
    // z-index:1;
`)

export const GridOuterWrapper = styled(Box)( props =>`
    // border:1px red solid;
    width:100%;

    // & .theme1 { border-left: 1px #eee solid; }

    // & .theme1 > thead { 
    //     border: 0 !important;
    //     & .headingTitle { border-top: 1px #eee solid !important; }
    //     & .headingSentiment, & .headingAttributes, & .headingSubBrands, & .headingActions { border-bottom: 2px #eee solid !important; }
    // }

    // & .theme1 > tbody > tr { border-bottom: 2px #eee solid !important; }
    // & .theme1 .details td { background: #fff; }

    // & .theme1 .details .tableContainerWrapper:before { display: none; }

    // & .theme1 .details .selected{background: transparent !important; }
    // & .theme1 .details .selected td{
    //     // background: red
    // }

    ${props?.motivatorsDetractors && `
        & .tableContainerWrapper {position:relative;}
        // & .tableContainerWrapper:before {
        //     content: '';
        //     width: 100%;
        //     height: calc(100% + 40px);
        //     box-shadow: 0 0 10px 0 #D9D9D9;
        //     display: block;
        //     position: absolute;
        //     left: 0;
        //     top: -40px;
        //     z-index: -1;
        // }

        // & .theme1 .tableContainerWrapper:before {
        //     display:none;
        // }
    `}

    // & .theme1 > tbody > tr.tRow:nth-of-type(2n+2) {
    //     box-shadow: none !important; background: none !important; 
    //     & .headingSentiment, & .headingAttributes, & .headingSubBrands, & .headingActions { background: #F7F7F9 !important; }
    // }

    & .typeDriversWithoutSentiments th.headingSentiment { font-size:0 !important; color: #fff;}
`)

export const ContentWrapper = styled(Box)( props =>`
    border: 1px #EEE solid;
    width: 100%;
    display: flex;
    flex-direction:column;

    & .legendRoot {padding-bottom:15px;}

    & > div > table > tbody > tr:not(.details) {
        ${props.rowsisselected === 'true' && `background: transparent; box-shadow: none;`}
    }
    & .level3IsActive .level2Wrapper > .MuiTableContainer-root{ display:none; }
    & .level3IsActive .level3OuterWrapper{ width:100%; display:flex; }
    & .level3IsActive .level3Wrap{ display:flex; flex-direction:row-reverse; }
    & .level3IsActive .level3Wrap > div{ width:50%; }
    // & .level3IsActive .level3Wrap .selected .headingSentiment, 
    // & .level3IsActive .level3Wrap .selected .headingAttributes, 
    // & .level3IsActive .level3Wrap .selected .headingSubBrands,
    // & .level3IsActive .level3Wrap .selected .headingActions { background: #F5F5F5 !important; }
    & .level3IsActive .level3Wrap p { padding-left:15px; }
    & .level3IsActive .level3Wrap tr td:first-of-type { padding-left:15px; }
    & .level3IsActive .level3Wrap tr td:last-child { padding-right:4px; }
    & .level3Wrap tr td:last-child { padding-right:4px; }
    & .level3IsActive .level3Wrapper.searchActiveButton{border:0; padding-left:0;}
    & .barRoot {max-width:100%;}
    & .DriverDownloadPPT.theme1.typeSearchDrivers .headingSentiment { font-size: 0 !important }
    // @media (max-width: ${MEDIA.lg}) { padding:20px 20px 40px 20px; }

    // DRIVERS PAGES PPT DOWNLOADING CSS
    &.exportToPPT { width:1400px !important; padding:0 !important; }
    &.exportToPPT table .actions > div {display: none !important}
    &.exportToPPT table > tbody > tr.details .showDeepDiveOnDownloadPptFalse { display:none !important; }
    &.exportToPPT table > tbody > tr.details .level2Wrapper { padding:0; }
    &.exportToPPT table > tbody > tr.details .level2Wrapper p { display:none; }
    &.exportToPPT .headingActions, .OuterWrapper .headingActions {display:none !important;}

    &.exportToPPT .DriversTrCombined { display: flex; flex-direction: column; min-width:1400px; overflow:hidden; }
    &.exportToPPT .DriversTrCombined .DriversTr { min-width:1400px; width:100%; }
    &.exportToPPT .DriversTrCombined > .DriversTr.tRow > td:nth-of-type(1) { font-weight:bold; }
    &.exportToPPT .DriversTrCombined .DriversTr.details { min-width:1400px; width:100%; padding:0 0 10px 0; }
    &.exportToPPT .DriversTrCombined .level2Wrapper { padding:0 !important; }
    &.exportToPPT .DriversTrCombined .level2Wrapper > p { display:none; }
    &.exportToPPT .DriversTrCombined .level2Wrapper > div { padding:0; width:1000px; }
    &.exportToPPT .DriversTrCombined .level2Wrapper .insightsWrapper ul { padding-bottom:10px; }
    &.exportToPPT .DriversTrCombined .level2Wrapper .level2Wrapper { padding-bottom:10px; }
    &.exportToPPT .DriversTrCombined .level2Wrapper .DriversTr *{ font-size:16px !important; }
    &.exportToPPT .DriversTrCombined .details td:nth-of-type(1){width:230px !important; padding:2px 8px !important; }
    &.exportToPPT .DriversTrCombined .details td:nth-of-type(2){width:250px !important; padding:2px 8px !important; }

    &.exportToPPT .DriversTrCombined .details .showDeepDiveOnDownloadPptFalse{display:none !important;}
    &.exportToPPT .DriversTrCombined .details .showDeepDiveOnDownloadPptTrue .isDeepDiveImageWrap{min-height:150px; width:950px !important; margin-left:230px;}
    &.exportToPPT .DriversTrCombined .details .showDeepDiveOnDownloadPptTrue .isDeepDiveImageWrap img{min-height:150px !important;}

    // new code added
    .OuterWrapper .DriversTr.MuiTableRow-head th {padding-top:0; padding-bottom:5px; border-bottom:2px #eee solid;}
    &.exportToPPT .DriversTrCombined .level2Wrapper { width:calc(1400px - 15px);}
    &.exportToPPT .DriversTrCombined .level2Wrapper table { width:calc(1400px - 20px); }
    &.exportToPPT .DriversTrCombined .level2Wrapper table thead {display:none !important;}
    &.exportToPPT .DriversTrCombined .level2Wrapper table tr td:nth-of-type(1) { max-width:150px; }
    &.exportToPPT .DriversTrCombined .level2Wrapper table tr td:nth-of-type(2) > div { max-width:280px; }

    &.exportToPPT .DriversTrDummy { display:block !important; height: 1px; width:100%; overflow:hidden; border:2px red solid; position:fixed; top:10px; left:0; z-index:15000;}
    
    &.exportToPPT {
        .DriversTr {width:1400px !important;}
        .DriversTr th, .DriversTr td{ padding: 3px 6px 4px 6px !important; }
        .DriversTr *{font-size:18px !important; line-height:17px !important; font-weight:500;}
        .DriversTr .barRoot, .DriversTr .barSliceWrap, .DriversTr .barSlice{height:29px !important; overflow: hidden;}
        .DriversTr:nth-of-type(even) .rowLevel1 { background: #F7F7F9 !important;}
        .headingTitle{ min-width:270px !important; max-width:270px !important; }
        .headingSentiment{ min-width:250px !important; max-width:250px !important;}
        .DriversTr .barSlicePercentage { min-width: 60px; }

        .headingAttributes{ width:100%; }
        .headingImpactStarRating, .headingSubBrands, .headingTriggers{ width:350px !important; max-width:350px !important; min-width:350px !important; }

        .DriversTr button{display:none !important;}
        .level2Content {padding:10px !important;}
        .hideInPPT {display:none;}
        .tabWrapper {display:none;}
        .legendRoot {padding-bottom:0px;}
    }
    &.exportToPPT.exportToPPTAppendices {
        .DriversTr.rowLevel1{background:#F7F7F9 !important;}
        .DriversTr .insightsWrapper {padding:15px 0 !important;}
    }
    &.exportToPPT .pptSearchKeywords_true .headingSentiment {font-size:0 !important;}

    &.exportToPPT th.headingSentiment { font-size:0 !important; color: #fff !important;}
    & .typeDriversWithoutSentiments th.headingSentiment { font-size:0 !important; color: #fff !important;}

    ${exportToPPT_tableCss}
`)

export const ContOuterWrap = styled(Box)(`
    display:flex;
    align-items: center;
    & > span {
        position:relative;
    }
`)

export const Level1Wrapper = styled(Box)(`
    width: 100%;
    & thead {
        padding:0;
    }
`)

export const Level2Wrapper = styled(Box)(`
    display:flex;
    flex-wrap:wrap;
    padding:5px;

    & > button {
        top:4px;
    }

    &:before{
        position: absolute;
        right: 0;
        top: -10px;
        width: 0;
        height: 0;
        border: 10px transparent solid;
        border-bottom-color: #fff;
        border-top: 0;
    }
    &.searchActiveButton:before{
        right:50px;  
    }
    &.moreActiveButton:before{
        right:10px;
    }
    & > div {
        width:50%;
        padding:0;
    }
    & > div:empty { 
        display: none;
    }
    & ul.Information {
        // padding: 35px 0;
    }
    & .actions {
        padding-right:40px;
    }
    
    & .details .selected {
        background: #F5F5F5 !important;
    }
    
    // & .details .selected .headingSentiment, 
    // & .details .selected .headingAttributes, 
    // & .details .selected .headingSubBrands,
    // & .details .selected .headingActions { background: #F5F5F5 !important; }

    & > div > table tbody tr:nth-of-type(2n+2) {
        background: transparent;
    }

    &.level2Clickedsearch > div > table tr{
        background: transparent !important;
    }
    
    & > ul.SearchKeyboard > li{
        width: calc(25% - 20px);
    }

    & * {font-size:12px;}
    &.moreActiveButton ul.Information li > span:first-of-type{font-size:14px !important;}

    & .imgWrapper {max-width:300px;}
`)

export const MuiButtonWrap = styled(Box)(`
    position:relative;
`)

export const DetailsHeading = styled(Box)(`
    width:100% !important;
    padding:0;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
    display:flex;
    flex-wrap:wrap;
    align-items:center;

    & .pptSubHeading {display:none;}
    & .subheadingL2 {font-size:14px; font-weight:500; min-height:30px;}
`)

export const InsightsRteContentWrapper = styled(Box)(`
    width:100% !important;
    padding:0 0 0 10px !important;
`)

export const InsightsLevel2Details = styled(Box)(`
    padding-top:10px !important;
    width:100% !important;

    & hr {
        display:none;
    }
    & ol, & ul {
        margin-left:15px;
    }
    & li {
        position:relative;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #000;
        line-height:18px;
        padding-bottom:5px;
    }
    
    & ul li ul {
        margin-top:5px;
    }
`)

export const IsDeepDiveImageWrap = styled(Box)(`
    padding:0;
    width:100% !important;
    margin-left:210px;
    max-width:calc(100% - 210px);
    & img {max-width: 100%;}
    @media (max-width: ${MEDIA.lg}) {
        margin-left:180px;
        max-width:calc(100% - 180px);        
    }
`)

export const Level3OuterWrapper = styled(Box)(`
    display:flex;
    & p {
        font-weight:300;
    }
`)

export const Level3Wrapper = styled(Box)(`
    display:flex;
    flex-direction: column;
    width: 100%;
    position:relative;
    &.moreActiveButton{
        background:#F5F5F5;
        padding:10px;
    }
    &.searchActiveButton{
        // padding-left:20px;
        border-left:1px #E0E0E0 solid;
    }
    &.moreActiveButton > button{
        top: 5px;
    }
    & p {
        padding:0 0 10px 15px;

    }
    & .headingL3 {
        padding-left:10px;

    }
    & > div > table tbody tr:nth-of-type(2n+2) {
        background: transparent;
    }
    & ul.Information *{
        font-size:14px;
    }
    & ul.SearchKeyboard{
        margin-left:15px;
    }

`)

export const Level4OuterWrapper = styled(Box)(`
    display:flex;
    padding:10px;
    position:relative;
    background:#F5F5F5;
    &:empty{
        display:none;
    }
`)

export const Level4Wrapper = styled(Box)(`
    display:flex;
    flex-direction: column;
    width: 100%;
    & > button {
        top:5px;
    }
`)

export const IconButtonWrapper = styled(IconButton)(`
    position: absolute;
    right: 5px;
`)

export const LegendWrapper = styled(Box)(`
    padding-top:15px;
    padding-right: 10px;
    & > div {justify-content:end;}
`)

