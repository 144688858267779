// IMPORTANT LINK
// https://www.youtube.com/watch?v=y7DxbW9nwmo&ab_channel=CurranKelleher

import React, { useState, useEffect } from 'react'
import * as d3 from 'd3'
// import { edit } from '../../assets/images/icons'
import { IconButton, Box, Typography } from '@mui/material'
import { closeMini, DoubleArrow } from '../../assets/images/icons'
import { InsightsRteContent, DynamicLegend } from '..'
import { SvgWrapper, FactorOuterWrapper, PptOuterWrapper, PptInnerWrapper, PptGraph, BigCircle, SmallCircle, Line } from './style'
import { MenuOuterWrapper, CloseWrap, MenuInnerWrapper, MenuWrapper, MenuHeading2, LegendWrapper } from '../../pages/reports/components/reportFactorInterLinkage/style'

export default function D3ForceDirectedTree (props) {
    const {
        id='container',
        data=[],
        onLoadClickedIndex = null,
        onChange,
        color,
        type='default',
        startZoomPaneClick=false,
        onLoadScale=1,
        graphLegends,
        pptDownloadIndex,
        pptData,
        // onLoadShowMenu=false,
        isEditable=false,
        closeMenu
    } = props

    const [showMenu, setShowMenu] = useState(null)
    const [clickedIndex, setClickedIndex] = useState(onLoadClickedIndex)
    const [linksDetails, setLinksDetails] = useState(data.linksDetails)
    // const [links, setLinks] = useState(data.links.map(d => Object.create(d)))
    const [links, setLinks] = useState(data.links.map(d => Object.create(d)))
    let clickedIndexTarget = []

    const nodes = data.nodes.map(d => Object.create(d));
    let widthNew = type==='fullscreen' ? window.innerWidth - 100 : window.innerWidth - 400
    let heightNew = type==='fullscreen' ? window.innerHeight - 170 : 650
    if (type==='ppt'){ widthNew = 1400; heightNew = 540}

    const centerX = widthNew / 2
    const centerY = heightNew / 2
    let simulation

    const textAtCenter = (node, axis) => {
        // const axisVal = node[axis] + (linksDetails[node.index].source.size / 2)
        // const targetVal = links[node.index].target[axis] + (linksDetails[node.index].target.size / 2)

        const sourceVal = links[node.index].source[axis]
        const targetVal = links[node.index].target[axis]
        const val = ((sourceVal + targetVal) / 2)
        return val

        // const axisVal = node[axis]
        // const targetVal = links[node.index].target[axis]
        // const val = ((axisVal + targetVal) / 2)
        // return val
    }

    const drag = simulation => {
        const dragStarted = (event, d) => {
            if(isNaN(clickedIndex)){return false}
            if (!event.active) simulation.alphaTarget(0.3).restart();
            d.fx = d.x;
            d.fy = d.y;
        }

        const dragged = (event, d) => {
            if(isNaN(clickedIndex)){return false}
            d.fx = event.x;
            d.fy = event.y;
        }

        const dragEnded = (event, d) => {
            if(isNaN(clickedIndex)){return false}
            if (!event.active) simulation.alphaTarget(0);
            d.fx = null;
            d.fy = null;
        }

        return d3.drag()
            .on('start', dragStarted)
            .on('drag', dragged)
            .on('end', dragEnded);
    }
    
    const handleZoom = (e) => { 
        if(e.transform.k > 1.5 || e.transform.k < 0.4){return false}
        if(startZoomPaneClick) d3.select('.svgInnerWrapper').attr('transform', e.transform);
    }
    const zoom = d3.zoom().on('zoom', handleZoom);

    const setCircleColor = (node) => {
        const category = linksDetails[node.index].category
        let circleColor = color[category.replaceAll(' ', '')]
        const isClicked = linksDetails.filter(f => f.clicked)
        const clickedIndexValue = linksDetails[node.index].clickedIndex

        if(type!=='ppt' && isClicked?.length > 0){
            if( data.linksDetailsTargets[clickedIndexValue].includes(linksDetails[node.index].id) ){
                circleColor = circleColor
            }
            else {
                circleColor = '#E1E1E1'
            }
        }
        return circleColor
    }

    const setLineColor = (node) => {
        const category = linksDetails[node.index].category
        let lineColor = '#000'
        const isClicked = linksDetails.filter(f => f.clicked)
        const clickedIndexValue = linksDetails[node.index].clickedIndex

        if(type!=='ppt' && isClicked?.length > 0){
            if( data.linksDetailsTargets[clickedIndexValue].includes(linksDetails[node.index].id) ){
                lineColor = lineColor
            }
            else {
                lineColor = '#E1E1E1'
            }
        }
        return lineColor
    }

    const circleClicked = (event, d) => {
        if (event?.defaultPrevented) return; // dragged
        
        let clickInd = d?.index || 0
        const linkArray = (type==='ppt') ? linksDetails : linksDetails.map((link, i) => {
            if (d.index === link.id || d.index === link.target) {link.clicked = true}
            else {link.clicked = false}

            link.clickedIndex = clickInd
            return link
        })


        const positioningToLeft = () => {
            const xPosi = 400
            const yPosi = 350
            const positionArray = [
                {x: xPosi, y: yPosi}, {x: xPosi, y: yPosi-150}, {x: xPosi+150, y: yPosi-100},
                {x: xPosi+250, y: yPosi}, {x: xPosi+75, y: yPosi+75}, {x: xPosi, y: yPosi+150}
            ]

            const lnk = links
            const targetArray = data.linksDetails[clickInd].rest.FactorLinkages.FactorLinkage.map(f => f.LinkedFactor)
            clickedIndexTarget = []
            for(let i=0; i<linksDetails.length; i++){
                if(targetArray.includes(linksDetails[i].id)) clickedIndexTarget.push(i)
            }
            
            lnk[clickInd].source.x = positionArray[0].x;
            lnk[clickInd].source.y = positionArray[0].y;

            for(let i=0; i<clickedIndexTarget.length; i++){
                lnk[clickedIndexTarget[i]].source.x = positionArray[i+1].x;
                lnk[clickedIndexTarget[i]].source.y = positionArray[i+1].y;
            }

            setLinks(lnk)
            d3.select('.svgInnerWrapper').attr('transform','translate(0) scale(1)')
        }
       
        if(type!=='ppt'){
            // if( !isNaN(clickInd) ){
                // positioningToLeft()
                // setTimeout(() => {
                //     positioningToLeft()
                //     setTimeout(() => {
                //         positioningToLeft()
                //         setTimeout(() => {
                //             positioningToLeft()
                //             // simulation.stop()
                //         }, 300)
                //     }, 300)
                // }, 300)
            // } else {
                // simulation.start()
            // }
        }
        if(type==='ppt'){
            if( !isNaN(clickInd) ){
                // positioningToLeft()
                setTimeout(() => {
                    simulation.stop()
                }, 100)
            } else {
                simulation.start()
            }
        }

        setLinksDetails(linkArray)

        // Checking if the menu has data, to show the menu ..
        const menuData = (type==='ppt') ? data.linksDetails : data.linksDetails.map((d, i) => {
            if ((d?.target === data.linksDetails[clickInd]?.id || d?.id === data.linksDetails[clickInd]?.target) && d?.targetInsights) { return d }
            else { return false }
        }).filter(f => f!==false)
        setShowMenu(menuData?.length > 0)
        
        if (type==='ppt') {
            // console.log('this is PPT')
        } else {
            // console.log('this is NOT - PPT')
            setClickedIndex(clickInd)
        }

        // if(!onLoadShowMenu) onChange({ type:'clicked', clickedIndex: clickInd })
        
    }

    const circleEditClicked = (event, d) => {
        if (event.defaultPrevented) return; // dragged
        onChange({ type:'edit', editIndex: d.index })
    }

    const getPptIndexDetails = () => {
        return linksDetails.filter((f, i) => f.isHighlighted && f.factorType === 'parent' )[pptDownloadIndex]
    }

    const setPptClassFunc = (node) => {
        if(type==='ppt' ) {
            const obj2 = linksDetails[node.index]
            if(pptDownloadIndex === obj2.id || pptDownloadIndex === obj2.target){ return 'pptShow' } // WORKING
            else { return 'pptHide' }
        } else {
            return ''
        }
    }
    const setClassFunc = (node) => {
        return linksDetails[node.index].isRepeated ? 'hide' : 'show'
    }

    const onloadFunc = () => {
        d3.select(`#${id}`).selectAll('*').remove()
        d3.select(`#${id}`).append('svg').attr('width', widthNew).attr('height', heightNew);

        const svg = d3.select(`#${id} svg`)
        
        svg.append('g').attr('class', 'svgOuterWrapper');
        svg.select('.svgOuterWrapper').append('g').attr('class', 'svgInnerWrapper').attr('transform',`translate(0,0) scale(${onLoadScale})`);
        if(startZoomPaneClick) svg.call(zoom);
        
        simulation = d3.forceSimulation(nodes)
            .force('charge', d3.forceManyBody().strength(0.5)) // Nodes are attracted one each other of value is > 0
            .force('link', d3.forceLink(links)) // .id(d => d.id))
            // .force('collide', d3.forceCollide().radius(10).strength(.1).iterations(0.1)) // Force that avoids circle overlapping1
            .force('collision', d3.forceCollide().radius(r => {
                const val1 = (links[r.index].size)
                // const val2 = ((100 - links[r.index].value)/2) + (val1/2)
                const val2 = ((100 - links[r.index].value)/3) + (val1/3)
                return val1 + val2
            })) // Force that avoids circle overlapping2 with configuration/settings
            .force('center', d3.forceCenter(centerX, centerY)) // Attraction to the center of the svg area
            .force('x', d3.forceX())
            .force('y', d3.forceY())
            // .force('y', d3.forceY().y((d) => { // IMP DON'T DELETE
            //     if(d.y < 0){return 10}
            //     if(d.y > heightNew){return heightNew}
            //     return d.y
            // }))

        const lineGroup = svg.select('.svgInnerWrapper').append('g').attr('class', 'lineGroup');
        const lines = lineGroup
            .selectAll('line')
            .data(links)
            .enter()
            .append('line')
            .attr('stroke', 'black')
            // .attr('class', node => setPptClassFunc(node))

        const lineCircle = lineGroup
            .selectAll('circle')
            .data(links)
            .enter()
            .append('circle')
            .attr('fill', '#fff')
            .attr('stroke', 'black')
            .attr('r', 15)
            .on('click', circleEditClicked)
            // .attr('class', node => setPptClassFunc(node))

        const lineEditIcon = (isEditable)
            ? lineGroup
                .selectAll('path')
                .data(links)
                .enter()
                .append('path')
                .attr('class', 'editIcon')
                .attr('d', 'M16.5752 4.25003C16.579 4.63456 16.4265 5.00428 16.1524 5.274L7.10106 14.3256C7.05417 14.372 6.99733 14.4072 6.93506 14.4284L0.559882 16.5535C0.516551 16.5677 0.471154 16.5751 0.425386 16.5752C0.288826 16.5752 0.160338 16.5096 0.0804277 16.3987C0.00051714 16.2879 -0.0214284 16.1453 0.0217131 16.0157L2.1468 9.64066C2.16819 9.57819 2.20345 9.52136 2.24978 9.47465L11.2982 0.426365C11.5712 0.154566 11.9403 0.00131707 12.3255 6.14073e-06C12.7111 -0.00111934 13.081 0.152506 13.3527 0.426365L16.1527 3.22633C16.423 3.4985 16.5747 3.86646 16.5752 4.25003ZM3.15114 9.77514L4.6754 11.299L11.7248 4.24975L10.2005 2.7259L3.15114 9.77514ZM3.46346 14.6894L1.88628 13.1123L1.09731 15.4784L3.46346 14.6894ZM6.01358 13.8394L2.73619 10.5622L2.18693 12.2104L4.36489 14.3887L6.01358 13.8394ZM13.8495 6.37523L12.3253 4.85138L5.27589 11.9001L6.80015 13.424L13.8495 6.37523ZM15.7255 4.25018C15.7238 4.09224 15.6615 3.94068 15.5516 3.82701L12.7516 1.02762C12.5135 0.798783 12.1373 0.798783 11.8992 1.02762L10.8013 2.12511L14.4503 5.77438L15.5518 4.67292C15.666 4.56225 15.7289 4.40924 15.7255 4.25018Z')
                .attr('fill', '#242424')
                .on('click', circleEditClicked)
                // .attr('class', node => setPptClassFunc(node))
            : lineGroup
                .selectAll('g')
                .data(links)
                .enter()
                .append('g')
                .attr('class', 'editIcon')
                // .attr('class', node => setPptClassFunc(node))

        const lineText = lineGroup
            .selectAll('text')
            .data(links)
            .enter()
            .append('text')
            .attr('text-anchor', 'middle')
            .attr('dy', '0.35em') // For new line or for line-height
            .style('font', '12px poppins')
            .text(node => `${links[node.index].value}%`)
            .on('click', circleEditClicked)
            // .attr('class', node => setPptClassFunc(node))


        const circleGroup = svg.select('.svgInnerWrapper').append('g').attr('class', 'circleGroup');
        const circles = circleGroup.append('g').attr('class', 'circle2')
            .selectAll('circle')
            .data(nodes)
            .enter()
            .append('circle')
            .attr('data-id', node => linksDetails[node.index].id)
            .attr('data-target', node => linksDetails[node.index].target)
            .attr('r', node => links[node.index].size)
            // .attr('class', node => setClassFunc(node))
            // .attr('fill', node => setCircleColor(node))
            // .attr('class', node => setPptClassFunc(node))
            // .call(drag(simulation))

        const text1 = circleGroup.append('g').attr('class', 'text1')
            .selectAll('text')
            .data(nodes)
            .enter()
            .append('text')
            .attr('text-anchor', 'middle')
            .attr('align-baseline', 'middle')
            .style('font', '14px poppins')
            .attr('dy', node => linksDetails[node.index].sourceArray.length===1 ? '0.3em' : '-0.3em') // For new line or for line-height
            .text(node => linksDetails[node.index].sourceArray[0])
            // .attr('class', node => setClassFunc(node))
            // .attr('class', node => setPptClassFunc(node))
            // .on('click', circleClicked);

        const text2 = circleGroup.append('g').attr('class', 'text2')
            .selectAll('text')
            .data(nodes)
            .enter()
            .append('text')
            .attr('text-anchor', 'middle')
            .attr('align-baseline', 'middle')
            .style('font', '14px poppins')
            .attr('dy', '0.8em') // For new line or for line-height
            .text(node => linksDetails[node.index].sourceArray[1])
            // .attr('class', node => setClassFunc(node))
            // .attr('class', node => setPptClassFunc(node))
            // .on('click', circleClicked);
        
        const circlesHitArea = circleGroup.append('g').attr('class', 'circle1')
            .selectAll('circle')
            .data(nodes)
            .enter()
            .append('circle')
            .attr('r', node => links[node.index].size)
            .on('click', circleClicked)
            .call(drag(simulation))
            .attr('opacity', '0.05')
            .style('cursor', 'pointer')
            // .attr('class', node => setClassFunc(node) )
            // .attr('class', node => setPptClassFunc(node) )

        // d3.selectAll('.hide').remove()
        // d3.selectAll('.pptHide').remove()

        const overlay = svg.select('.svgOuterWrapper').append('g').attr('class', 'overlayGroup')
            .append('rect').attr('x', 0).attr('y', 0).attr('width', widthNew).attr('height', heightNew).attr('fill', '#f1f1f104').style('display', (startZoomPaneClick) ? 'none' : 'block')
            .on('click', () => { 
                setClickedIndex(null);
                onChange({ type:'overlay' })
            });


        const checkPositionsFunc = (node, axis) => {
            if(type==='ppt') return node[axis]
            if(linksDetails[node.index].isRepeated){
                return 0
            } else {
                // if(node[axis] < 0){ return 15 }
                // else if(node['x'] > widthNew) {return widthNew}
                // else if(node['y'] > heightNew) {return heightNew}
                // else {return node[axis]}
                return node[axis]
            }
        }

            
        simulation.on('tick', () => {
            circles
                .attr('cx', node => checkPositionsFunc(node, 'x'))
                .attr('cy', node => checkPositionsFunc(node, 'y'))
                .attr('fill', node => setCircleColor(node))

            circlesHitArea
                .attr('cx', node => checkPositionsFunc(node, 'x'))
                .attr('cy', node => checkPositionsFunc(node, 'y'))

            text1
                .attr('x', node => checkPositionsFunc(node, 'x'))
                .attr('y', node => checkPositionsFunc(node, 'y'))

            text2
                .attr('x', node => checkPositionsFunc(node, 'x'))
                .attr('y', node => checkPositionsFunc(node, 'y'))

            lines
                .attr('x1', link => (link.source.x) )
                .attr('y1', link => (link.source.y) )
                .attr('x2', link => (link.target.x) )
                .attr('y2', link => (link.target.y) )
                .attr('stroke', node => setLineColor(node))

            lineCircle
                .attr('cx', link => textAtCenter(link, 'x'))
                .attr('cy', link => textAtCenter(link, 'y'))
                .attr('stroke', node => setLineColor(node))
                .attr('opacity', node => (setLineColor(node) === '#000' ? 1 : 0.0))
            
            lineEditIcon
                .attr('transform', link => `translate(${textAtCenter(link, 'x')-5}, ${textAtCenter(link, 'y')+2}) scale(0.8)`)
                .attr('opacity', node => (setLineColor(node) === '#000' ? 1 : 0.0))
                
            lineText
                .attr('x', link => textAtCenter(link, 'x'))
                .attr('y', link => textAtCenter(link, 'y') - (isEditable ? 3 : 0))
                .attr('opacity', node => (setLineColor(node) === '#000' ? 1 : 0.0))

        })

        setTimeout(() => {
            circles.attr('class', node => setClassFunc(node))
            circlesHitArea.attr('class', node => setClassFunc(node))
            text1.attr('class', node => setClassFunc(node))
            text2.attr('class', node => setClassFunc(node))
            if(type!=='ppt') d3.selectAll('.hide').remove()
        },5)

        if(type==='ppt'){
            d3.selectAll('.circle1').remove()
            setTimeout(() => {
                circleClicked(null, links[pptDownloadIndex])
                setTimeout(() => {
                    circleClicked(null, links[pptDownloadIndex])
                    setTimeout(() => {
                        circleClicked(null, links[pptDownloadIndex])
                    },500)
                },500)
            },2000)
        }

        if(type !== 'ppt'){
            setTimeout(() => {
                // auto resize the height of the graph
                let scale = 1
                let translateY = 0
                let round = 'start'
                const resizeFunc1 = (type) => {
                    if(type === 'scale'){
                        scale = scale - 0.005
                        d3.select('.svgInnerWrapper').attr('transform',`translate(${translateY}) scale(${scale})`)
                    } else if(type === 'y1'){
                        translateY = translateY - 5
                        d3.select('.svgInnerWrapper').attr('transform',`translate(0, ${translateY}) scale(${scale})`)
                    } else if(type === 'y2'){
                        translateY = translateY + 5
                        d3.select('.svgInnerWrapper').attr('transform',`translate(0, ${translateY}) scale(${scale})`)
                    }
                    const getSvg = svg.node().getBoundingClientRect().y
                    if(d3.select('.svgInnerWrapper')?.node()?.getBoundingClientRect()){
                        const getY = d3.select('.svgInnerWrapper').node().getBoundingClientRect().y - getSvg
                        const getH = d3.select('.svgInnerWrapper').node().getBoundingClientRect().height
                        if(getH > heightNew){
                            resizeFunc1(type)
                        } else if(getY > 10){
                            resizeFunc1('y1')
                        } else if(getY < 0){
                            resizeFunc1('y2')
                        } else if (round === 'start'){
                            round = 'end'
                            simulation.stop()
                            resizeFunc1(type)
                        }
                    }
                }
                resizeFunc1('scale')
            },2500)
        }
    }
    
    const closeMenuFunc = () => {
        setShowMenu(false)
        closeMenu()
    }

    const loadPptFunc = () => {
      

    }

    useEffect(() => {
        if(type === 'ppt'){
            loadPptFunc()
        } else { 
            onloadFunc()
        }

    }, [])

    return (
        <FactorOuterWrapper className='factorWrapper'>
            {showMenu &&
                <MenuOuterWrapper className='menuWrapper'>
                    <CloseWrap onClick={closeMenuFunc}><IconButton color='primary'><img src={closeMini} alt='Close' width='10px' /></IconButton></CloseWrap>
                    {!!data?.linksDetails?.length && <MenuInnerWrapper>
                        {data.linksDetails.map((d, i) => {
                            if (d.targetInsights && (d.target === clickedIndex) && (data.linksDetails[clickedIndex].source !== d.source)) {
                                return (
                                    <MenuWrapper key={i}>
                                        <MenuHeading2>{data.linksDetails[clickedIndex].source} <DoubleArrow /> {d.source} ({d.value}%)</MenuHeading2>
                                        <InsightsRteContent content={d.targetInsights} />
                                    </MenuWrapper>
                                )
                            }
                        })}
                    </MenuInnerWrapper>}
                </MenuOuterWrapper>
            }

            {type === 'ppt' && (data?.linksDetails?.map(d => d.targetInsights)?.filter(f => (f.trim())).length > 0) && <MenuOuterWrapper className='factorGraphList menuWrapper'>
                <Typography className='pptSubHeading'>{data.linksDetails[0].source}</Typography>
                {!!data?.linksDetails?.length && <MenuInnerWrapper>
                    {data.linksDetails.map((d, i) => {
                        if (d.targetInsights && (d.target === 0) && (data.linksDetails[0].source !== d.source)) {
                            return (
                                <MenuWrapper key={i}>
                                    <MenuHeading2>{data.linksDetails[0].source} <DoubleArrow /> {d.source} ({d.value}%)</MenuHeading2>
                                    <InsightsRteContent content={d.targetInsights} />
                                </MenuWrapper>
                            )
                        }
                    })}
                </MenuInnerWrapper>}
            </MenuOuterWrapper>}

            <Box className='factorGraphList'>
                {type === 'ppt' && data?.linksDetails && <Typography className='pptSubHeading'>{data.linksDetails[0].source}</Typography>}
                {type === 'ppt' && data?.linksDetails && <Typography className='pptSubHeading'>{data.linksDetails[0].source}</Typography>}
                <SvgWrapper className='svgWrapper' id={id} />

                {type === 'ppt' && <PptOuterWrapper>
                    <PptGraph>
                        {data?.linksDetails.map((d, i) => {
                            if(i > 6) return
                            const circleColor = color[d.category.replaceAll(' ', '')]
                            return (
                                <PptInnerWrapper key={i} className={`circleWrapper wrap_${i}`}>
                                    <BigCircle className='circles bigCircle' style={{background:circleColor}} size={d.size}>{d.source}</BigCircle>
                                    <SmallCircle className='circles smallCircle'>{d.value}%</SmallCircle>
                                    <Line className='line' />
                                </PptInnerWrapper>
                            )
                        })}
                    </PptGraph>
                </PptOuterWrapper>}

                <LegendWrapper>
                    <p>Percentages indicate the degree of overlap between the two insight spaces</p>
                    <p>Size of the circles indicate the category engagement of the insight space (relative to importance of insight spaces in the category)</p>
                </LegendWrapper>
                <LegendWrapper className='dynamicLegend'>
                    <DynamicLegend legends={graphLegends} />
                </LegendWrapper>
            </Box>
        </FactorOuterWrapper>
    )
}
