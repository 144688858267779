import React, { useEffect, useState } from 'react'
import { LoaderWrapper, ReportHeading, ReportOuterWrapper } from '../../../../components'
import { GRAPH_COLOR_PALLET1 } from '../../../../constants'
import reportsService from '../../../../services/reports.service'
import { useUpdateLhs } from '../../../../hooks/useUpdateLhs'
import BrandBar from './brandBar'
import { MultiBarContainer, RootWrap } from './style'
import { exportExcel_addPercentage } from '../../../../util'

const MultiBrandNPS = (props) => {
  const [loading, setLoading] = useState(false);
  const [state, setStage] = useState([]);
  const [max, setMax] = useState(0);
  const [reportExcelData, setReportExcelData] = useState()
  const {updateSubLhsApiLoaded} = useUpdateLhs()

  const getNps = () => {
    setLoading(true)
    reportsService.getNPSElement({ insightsElementId: props.InsightsElementId }).then(resp => {
      setStage(resp)
      const percentages = resp.map(item  => item.NPSPercent)
      setMax(Math.max(...percentages))
      setTimeout(() => {
        updateSubLhsApiLoaded(props)
      }, 2000)

      setReportExcelData({heading:['Brand', 'NPS'], values: resp.map(d => {
        return [{value: d.BrandName}, exportExcel_addPercentage(d.NPSPercent)]}
      )})
    })
    .catch(() => {
      // console.log(error)
    })
    .finally(() => {
      setLoading(false)
    })
  }

  useEffect(() => {
    getNps();
  }, [])

  useEffect(() => {
    if(!!state.length){
      const bar = document.querySelectorAll('.barWrapper .brandBarTitle')
      let titleHeight = 0
      for(let i=0; i<bar.length; i++){
        titleHeight = bar[i].clientHeight > titleHeight ? bar[i].clientHeight : titleHeight
      }
      for(let i=0; i<bar.length; i++){
        bar[i].style.height = titleHeight+'px'
      }
    }
  }, [state])

  return (
    <>
      <ReportHeading downloadId={props.type} {...props} downloadIdData={reportExcelData} />
      <ReportOuterWrapper>
        <RootWrap id={props.type}>
          <LoaderWrapper loading={loading} loadOnlyApi={props.loadOnlyApi}>
            <MultiBarContainer className='barWrapper'>
              {
                state?.map((item, index) => {
                  return (
                    <BrandBar maxValue={max} color={GRAPH_COLOR_PALLET1[index]} key={index} value={item.NPSPercent} title={item.BrandName} />
                  )
                })
              }
            </MultiBarContainer>
          </LoaderWrapper>
        </RootWrap>
      </ReportOuterWrapper>
    </>
  )
}

export default MultiBrandNPS
