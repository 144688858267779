import styled from '@emotion/styled'
import { Box } from '@mui/material'
import { exportToPPT_tableCss } from '../../../../util'

export const RowWrap = styled(Box)(`
    width: 100%;
    margin-top: 20px;
    display: flex;
    align-items: center;
`)


export const HeadingWrap = styled(Box)(`
    display: flex;
    align-items: center;
    margin-right: auto;
    & .rowHeading {
        font-size: 20px;
        font-weight: 300;
    }
    & .toolTipButtonIconWrap{
        margin-top: -15px;
    }
`)

export const RootWrap = styled(Box)(`
    width:100%;
    
    .campaignTriggersRow.tableWrap .showToolTip:before{z-index:4;}

    &.exportToPPT {
        .campaignTriggersRow{width:1200px; box-shadow:none;}
        .campaignTriggersRow.campaignTriggersRowNewSlidePPT{padding-top:0 !important; }
        
        .campaignTriggersRow.sectionWrap .titleForPPT{display:block; padding-top:0 !important;}
        .campaignTriggersRow.tableWrap > div{margin:0; padding:15px 0 0 0;}
        .campaignTriggersRow.tableWrap table{margin:0;}
        .campaignTriggersRow.tableWrap table th{position:relative; padding:4px; }
        .campaignTriggersRow.tableWrap table th .table-head-cell-html{padding:4px; font-size: 11px; line-height: 13px;}
        .campaignTriggersRow.tableWrap table td{position:relative; padding:8px; font-size:12px !important;}
        .campaignTriggersRow.tableWrap table td .campaignMagnetScore{padding:0;}
        .campaignTriggersRow.tableWrap table td .campaignMagnetScore .barSliceWrap .barSlice{height:20px !important;}
        .campaignTriggersRow.tableWrap table td .campaignMagnetScore .barSliceWrap .barSliceLabel{ margin: 0; height: 20px; line-height: 20px; top: 20px; left: 0;}
        .campaignTriggersRow.tableWrap table td .campaignMagnetScore .barRoot{background: transparent;}
        .campaignTriggersRow.tableWrap table td .campaignMagnetScore .barSliceBottom{font-size:12px;}
        .campaignTriggersRow.tableWrap table td button{display:none;}
        // .campaignTriggersRow.tableWrap > div {border:1px red solid;}
        // .campaignTriggersRow.tableWrap > div > .legendRoot {border:1px blue solid;}
        .campaignTriggersRow.tableWrap .triggerName{align-items: start;}
        
        .campaignTriggersRow.sectionWrap{margin:0; border:0; padding-top:0;}
        .campaignTriggersRow.sectionWrap p{margin-bottom:0;}
        .campaignTriggersRow.sectionWrap .campaignMagnetScore{padding:4px 0; margin-left:220px;}
        .campaignTriggersRow.sectionWrap .campaignMagnetScore .barSliceWrap .barSlice{height:26px !important;}
        .campaignTriggersRow.sectionWrap .campaignMagnetScore .barRoot{height:26px; overflow:hidden;}
        .campaignTriggersRow.sectionWrap .campaignMagnetScore > p{max-width:220px; left:-228px;}
        .campaignTriggersRow.sectionWrap .barGridItem{padding:0;}
        .campaignTriggersRow.sectionWrap .barGridItem p{padding:0; margin:0;}
        .campaignTriggersRow.sectionWrap button{display:none;}
        .campaignTriggersRow .headerTitle{font-size:0 !important}

        // .campaignTriggersRow .campaignMagnetScore .barSliceLabel{font-size:12px !important;}
        .campaignTriggersRow .campaignMagnetScore .barSliceEndIcon img{width:15px;}

        .campaignTriggersRow.headerWrap p{padding:0 0 5px 0; line-height:1; }
        .campaignTriggersRow.headerWrap button{display:none;}
        
        .campaignTriggersRow.headerWrap{border-right:10px #fff solid;}
        .campaignTriggersRow.headerWrap .titleForPPT{display:block;}
        .campaignTriggersRow .SubTriggers {padding-top:30px !important; font-weight:500;}
        .campaignTriggersRow.ImpactBrandPerception {padding-top:30px; font-weight:500;}
        .campaignTriggersRow * {font-size:14px !important; font-weight:500;}

        .graphWrapper.campaignTriggersRow {
            height: 40px;
            .campaignMagnetScore {padding:0 50% 0 0 !important; margin:4px 0; height:26px; margin-left:220px;}
            .campaignMagnetScore .barSliceWrap{margin-top:6px !important;}
            .campaignMagnetScore .barSliceWrap .barSlice{height:26px !important;}
            .campaignMagnetScore > p{max-width:220px; left:-228px;}
            button{ display:none; }
        }
        .campaignTriggersRow.pageBreakerForPPT{display:block !important;}

        .campaignTriggersRow.pageBreakerForPPT:last-child{display:none;}
    }
    ${ exportToPPT_tableCss }
`)

