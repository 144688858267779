import React, { useState, useEffect } from 'react'
import { Box } from '@mui/material'
import { ReportHeading, ProgressionWrapper, ProgressionFilters, ReportOuterWrapper } from '../../../../components'
import ReportsService from '../../../../services/reports.service'
import { useUpdateLhs } from '../../../../hooks/useUpdateLhs'
import { useGlobalStore } from '../../../../store'
import {ROLES_MM_PRIMARY} from '../../../../constants'
import { BrandImagerySimulator } from '..'
import { getObjectAsArray  } from '../../../../util'

import { LhsTree } from './__mock.js'
import {
  OuterWrapper,
  ChartList,
  ChartLhs,
  BigArrow,
  ChartRhs,
  TabSection1,
  TabSection2
} from './style'
import BrandMagnetScoreWarning from '../brandMagnetScoreWarning'
import BrandMagnetScoreWarningArray from '../brandMagnetScoreWarningArray'
import BrandMagnetScoreTree from '../brandMagnetScoreTree'

const ReportBrandMagnetScoreAndProgression = props => {
  const [loading, setLoading] = useState(true)
  const [backgroundTabsLoading, setBackgroundTabsLoading] = useState(true)
  const [rows, setRows] = useState([])
  const [pageFlag, setPageFlag] = useState()
  const [reportExcelData, setReportExcelData] = useState()
  const {updateSubLhsApiLoaded} = useUpdateLhs()
  const {user} = useGlobalStore()

	const [progressionDataL1Filtered, setProgressionDataL1Filtered] = useState({brands:[], periods:[]})
	const [progressionDataFiltered, setProgressionDataFiltered] = useState({brands:[], periods:[]})
  const [configTab, setConfigTab] = useState([])
	const [progressionData, setProgressionData] = useState()
  const [eventCount, setEventCount] = useState(1)

  let scoreImageryTypeArray = {}
  let scoreImageryTypeArrayL2 = {}
  const filterDataObj = {brands:[], periods:[]};

  const generateProgressionGraphData = async (resp, hasTabs) => {
    scoreImageryTypeArray = {}
    scoreImageryTypeArrayL2 = {}

    const rowArrayDataFunc = (data, dataObj) => {
      filterDataObj.periods.push(dataObj?.title || '')
      filterDataObj.brands.push(data.map((d) => (d.Entity.EntityName)))

      const finalData = data.map(d => {
        return {
          ...d,
          title: d.Entity?.EntityName,
          graphs: [{
            value: d?.BrandMagnetScore,
            barData: [{ Warning: d?.BrandMagnetScore }]
          }]
        }
      })

      return finalData
    }

    const configTabObject = hasTabs 
      ? (resp.map((d, i) => {
          return {
            periodInsightsElementId: d.PeriodInsightsElementId,
            id: i,
            label: i===0 ? d.PeriodName : 'Progression',
            tutorial: i===0 ? 'CurrentPeriod' : 'Progression',
            title: d.PeriodName,
            periodOrder: d.PeriodOrder,
            data: getObjectAsArray(d.PeriodBrandCategoryMetricsElements.BrandCategoryMetricsElement)
          }
        }))
      : []

    const rowArrayData = hasTabs
      ? configTabObject.map(d => {
        d.data = rowArrayDataFunc(d.data, d)
        return d
      })
      : [rowArrayDataFunc(resp, null)]

    const rowArray = hasTabs ? rowArrayData[0].data : rowArrayData[0]

    const removeUsingSet = (arr) => {
        let outputArray = Array.from(new Set(arr))
        return outputArray
    }
    const progressionDataL1FilteredData = {brands:removeUsingSet(filterDataObj.brands.flat(1)), periods:removeUsingSet(filterDataObj.periods)}
    setProgressionDataL1Filtered(progressionDataL1FilteredData)

    if(hasTabs){
      setConfigTab((configTabObject))
    }

    const finalRowArray = rowArray.map(f => ({ ...f, EntityType: f.Entity.EntityType })).filter(f => f.EntityType !== 'HiddenBrand' )
    setRows(finalRowArray)
    updateSubLhsApiLoaded(props)
  }

  const loadBrandImageryGraph = async () => {
    setPageFlag(true)
    setLoading(true)

    const hasTabs = props.InsightsElementType === 'BrandMagnetScoreAndProgression'
    const payload = { insightsElementId: props.InsightsElementId }

    await (hasTabs ? ReportsService.reportGetMagnetScoreAndProgressionApi(payload) : ReportsService.reportGetMagnetScoreApi(payload))
    .then(resp => {
        generateProgressionGraphData(resp, hasTabs)
      })
      .catch(error => {
        const resMessage = error
        console.log('ERROR : ', resMessage)
      }).finally(() => {
        setLoading(false)
      })
  }

  // const loadBrandImagery = () => {
  //   setPageFlag(true)
  //   ReportsService.reportGetMagnetScoreApi({
  //     insightsElementId: props.InsightsElementId
  //   })
  //     .then(resp => {
  //       const rowArray = resp?.map((r) => {
  //         return {
  //           ...r,
  //           title: r.Entity?.EntityName,
  //           graphs: [{
  //             value: r?.BrandMagnetScore,
  //             barData: [{ Warning: r?.BrandMagnetScore }]
  //           }]
  //         }
  //       })
  //       setRows(rowArray)
  //       updateSubLhsApiLoaded(props)

  //       setReportExcelData({heading:['Brand', 'Brand Magnet Score'], values: resp.map(d => {
  //         return [{value: d.Entity.EntityName}, {value: d.BrandMagnetScore}]}
  //       )})
  //     })
  //     .catch(error => {
  //       const resMessage = error
  //       console.log('ERROR : ', resMessage)

  //     }).finally(() => {
  //       setBackgroundTabsLoading(false);
  //     })
  // }
  
  const BrandImagerySimulatorButton = () => {
    return (
      <>{ROLES_MM_PRIMARY.includes(user?.UserType) && false && <BrandImagerySimulator {...props} brandMagnetTree={LhsTree} brandMagnetScore={rows} />}</>
    )
  }

  const filterChangeFunc = (data) => {
		setProgressionDataFiltered(data)
	}

  useEffect(() => {
    if (!pageFlag) {
      // loadBrandImagery()
      loadBrandImageryGraph()
    }
  }, [])

  return (
    <>
      <ReportHeading resetInsightHeight={eventCount} downloadId={props.type} subHeading='' {...props} additionalMiddleButton={<BrandImagerySimulatorButton />} downloadIdData={reportExcelData} />
      <ReportOuterWrapper>
        <OuterWrapper id={props.type}>
          <ProgressionWrapper configTab={configTab} onChange={() => setEventCount(p => p+1)} defaultTab={(props?.isProgressionActive && props.InsightsElementType === 'BrandMagnetScoreAndProgression') ? 1 : 0}>
          {/* <ProgressionWrapper configTab={configTab} onChange={() => setEventCount(p => p+1)}> */}
            <TabSection1 hasTabs={props.InsightsElementType === 'BrandMagnetScoreAndProgression'} className='brandMagnetScoreRows'>
              <ChartLhs className='chartLhs'>
                <BrandMagnetScoreTree data={LhsTree} />
              </ChartLhs>
              <ChartRhs className='chartRhs'>
                <BrandMagnetScoreWarning rows={rows} />
              </ChartRhs>
            </TabSection1>
            <TabSection2 hasTabs={props.InsightsElementType === 'BrandMagnetScoreAndProgression'} className='chartLhsWrapper'>
              {/* <ProgressionFiltersWrapper>
                {rows?.length > 0 && progressionDataL1Filtered?.brands?.length > 0 && <ProgressionFilters data={progressionDataL1Filtered} onChange={filterChangeFunc} />}
              </ProgressionFiltersWrapper> */}
              <ChartLhs className='chartLhs'>
                <BrandMagnetScoreTree data={LhsTree} />
              </ChartLhs>
              <ChartRhs className='chartRhs'>
                <BrandMagnetScoreWarningArray rows={rows} data={configTab} />
              </ChartRhs>
            </TabSection2>
          </ProgressionWrapper>
        </OuterWrapper>
      </ReportOuterWrapper>
    </>
  )
}

export default ReportBrandMagnetScoreAndProgression;


