import { styled } from '@mui/material/styles'
import { Box, Typography } from '@mui/material'
import { MEDIA } from '../../../constants'

export const DashboardNavbarRoot = styled(Box)(`
  position: static;
  height: 60px;
  max-height: 59px;
  display: flex;
  align-items:center;
  background-color: #000;
  @media (max-width: ${MEDIA.lg}) {
    height: auto;
    max-height: auto;
    width: 100%;
    // border-bottom: 0;
    & .headerLogo{
      display: none;
    }
  }
`)

export const ToolbarStyled = styled(Box)(`
  border-bottom: 1px solid #EEEEEE;
  justify-content: space-between;
  display: flex;
  width: 100%;
  // padding: 0 30px;
  align-items: center;

  @media (max-width: ${MEDIA.lg}) {
    padding: 10px 20px;
    height: 60px;
    border-bottom:0;
  }
  
  & .lhsLogos{
    & .headerLogo{
      display: none;
    }
    & a { display:inline-block; }

    @media (max-width: ${MEDIA.lg}) {
      display: flex;
      align-items: center;
      
      & a { display: flex; justify-content: center; align-items: center; }

      // & .brandLogo {border-left:1px solid red; height:45px;}
      // & .brandLogo {padding-left:20px; border-left:1px #D9D9D9 solid; height:45px; margin:0;}
      // & .brandLogo img {max-height:43px;}
      & .brandLogo img {margin-left:0;}

      & .headerLogo{
        display: block;
        // padding: 0;
        // width: 75px;
        // min-width: 75px;
        // margin-top:-13px;
        & img {
          // width: 50px;
          // height:auto;
        }
        & .poweredByWrap {
          top:52px;
          left:0;
          z-index:1;
          display:flex;
          flex-direction:column;
          & .text{
            padding-bottom:5px;
            display:inline;
          }
        }
      }
    }
  }
`)

export const Logo = styled(Box)(`
  // min-width: 180px;
  // width: 190px;
  display: flex;
  align-items: center;
  & img {
    margin-left: 10px;
    max-height: 45px;
    max-width: 200px;
  }
`)

export const ContentWrapper = styled(Box)(props => `
  background-color: #232323;
  color:#fff;
  padding: 0 20px;
  // width: 100%;
  height: 60px;
  display: ${props.isroot ? 'none' : "flex"};
  flex-direction: column;
  justify-content: center;
  text-align: center;
  text-transform: capitalize;
  @media (max-width: ${MEDIA.lg}) {
   height: auto;
   padding: 20px;
   display: ${props.isroot ? 'flex' : "none"};
   border-bottom:1px #EEE solid;
   flex-direction:row;
   flex-wrap:wrap;
   & p {width:100%;}
   & p:nth-of-type(3) {width:50%;}
   & p:nth-of-type(4) {width:50%; text-align:right; padding:0;}
  }
  @media (max-width: 980px) {
   & p:nth-of-type(3) {width:100%; text-align:left;}
   & p:nth-of-type(4) {width:100%; text-align:left;}
  }
`)

export const BreadCrumb = styled(Typography)(`
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  & * {
    font-weight: inherit !important;
    min-width: auto !important;
    line-height: inherit !important;
    font-size: inherit !important;
    padding: 0px !important;
    color: #000 !important;
    font-weight: normal !important;
  }
`)

export const Title = styled(Typography)(`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`)

export const SubTitle = styled(Typography)(`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
`)

export const LastUpdated = styled(Typography)(props => `
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  text-transform: capitalize;
  min-width:380px;
  padding-top:15px;
  display: ${props.isroot ? 'none' : "block"};
  text-align: right;
  @media (max-width: ${MEDIA.lg}) {
    padding-top: 5px;
    text-align: ${props.isroot ? 'left' : 'right'};
    display: ${props.isroot ? 'block' : "none"};
   }
`)

export const RhsWrapper = styled(Box)(`
  display:flex;
  flex-wrap:wrap;
  align-items:center;
  justify-content:end;

  @media (max-width: ${MEDIA.lg}) {
    align-items: center;
    min-width: 200px;
  }
  @media (max-width: 980px) {
    min-width: 80px;
  }
  & .brandLogo{
    display: none;
    @media (max-width: ${MEDIA.lg}) {
      display: flex;
      display: none !important;
      align-items: center;
      margin-right: 0;
      justify-content: flex-end;
      img {
        height: 60px;
        width: 60px;
        object-fit: contain;
      }
    }
  }

`)

export const HiddenPptLogo = styled(Box)(`
  position:absolute;
  width:1px;
  height:1px;
  overflow:hidden;
`)

export const DesktopMenu = styled(Box)(`
  display:flex;
  @media (max-width: 980px) {
    display:none;
  }
`)

export const MobileMenu = styled(Box)(`
  display:none;
  @media (max-width: 980px) {
    display:block;
  }
`)

export const LoaderOverlay = styled(Box)(`
  position: fixed;
  z-index: 1300;
  width:100vw;
  height:100vh;
  display:block;
  background: rgba(0, 0, 0, 0.5);
  top:0;
  left:0;
`)

export const IsDummyAccount = styled(Box)(`
  position: fixed;
  z-index: 1300;
  width:60%;
  display:block;
  background: red;
  top:0;
  left:20%;
  padding:5px;
  border-bottom-left-radius: 80px 80px;
  border-bottom-right-radius: 80px 80px;
  text-align:center;
  &:before {
    content: 'This is Test Account!!';
    color:#fff;
    font-weight:bold;
    text-transform:uppercase;
    font-size:25px;
  }
`)
