import React from 'react'
const IconRoundCollapse = () => {
    return (
        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_3004_87751)">
                <circle cx="11.5" cy="11.5" r="10.5" fill="white" />
                <circle cx="11.5" cy="11.5" r="10" stroke="#5BA3FA" />
            </g>
            <g clipPath="url(#clip0_3004_87751)">
                <path d="M11.8125 10.6875V11.1875H12.3125H16.375C16.5474 11.1875 16.6875 11.3276 16.6875 11.5C16.6875 11.6724 16.5474 11.8125 16.375 11.8125H12.3125H11.8125V12.3125V16.375C11.8125 16.5474 11.6724 16.6875 11.5 16.6875C11.3276 16.6875 11.1875 16.5474 11.1875 16.375V12.3125V11.8125H10.6875H6.625C6.45264 11.8125 6.3125 11.6724 6.3125 11.5C6.3125 11.3276 6.45264 11.1875 6.625 11.1875H10.6875H11.1875V10.6875V6.625C11.1875 6.45264 11.3276 6.3125 11.5 6.3125C11.6724 6.3125 11.8125 6.45264 11.8125 6.625V10.6875Z" fill="#5BA3FA" stroke="#5BA3FA" />
            </g>
            <defs>
                <filter id="filter0_d_3004_87751" x="0" y="0" width="23" height="23" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset />
                    <feGaussianBlur stdDeviation="0.5" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.32 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3004_87751" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3004_87751" result="shape" />
                </filter>
                <clipPath id="clip0_3004_87751">
                    <rect width="13" height="13" fill="white" transform="translate(5 5)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default IconRoundCollapse
