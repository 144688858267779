import styled from '@emotion/styled'

export const StyledFormLabel = styled('label')(`
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 19px;
  color: #000;
  width:100%;
  padding-bottom:10px;
`)