import React from 'react'
import { Bar, BarLineWrap } from './style'

const BarLine = ({value = 35, color="#55AAB3", maxValue=100}) => {

  const percentage = (value / maxValue) * 100;

  return (
    <BarLineWrap>
      <Bar bar={{
        color: color,
        height: percentage + '%',
        width: "60px"
      }} cite={`${value}%`} />
    </BarLineWrap>
  )
}

export default BarLine