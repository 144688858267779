import React, { useState, useEffect } from 'react'
import MuiImage from '../muiImage'
import { VerbatimGrid } from '../'
import { CONST_VERBATIM_PATH } from '../../constants'
import { imageNotLoaded } from '../../assets/images'
import Header from '../../pages/reports/components/reportSegment/header'
import { VerbatimWrapper, VerbatimImgVidWrapper, ImgWrapper } from './style'

const VerbatimImageVideoGrid = ({ data, bg, insightsElementId, showEntityName, entityType }) => {
  const data2 = data
  const data2AssetExists = data2.filter(d => !!d?.VerbatimMediaPrivateName)

  return (
    <>
      {data2AssetExists.length === 0 && <VerbatimGrid entityType={entityType} bg={bg} data={data2} insightsElementId={insightsElementId} showEntityName={showEntityName} />}
      {data2AssetExists.length > 0 && <VerbatimImageVideo entityType={entityType} bg={bg} data={data2} insightsElementId={insightsElementId} showEntityName={showEntityName} />}
    </>
  )
}


const VerbatimImageVideo = ({ data, bg, insightsElementId, showEntityName, entityType }) => {
  const [activeTab, setActiveTab] = useState(0)

  const textArray = data.filter(d => !d?.VerbatimMediaPrivateName)
  const imageVideoArray = data.filter(d => !!d?.VerbatimMediaPrivateName)

  let OPTIONS = [
    {id: 0, label: 'Text'},
    {id: 1, label: 'Images / Videos'},
  ]
  if(textArray.length === 0){
    OPTIONS = [
      {id: 0, label: 'Images / Videos'},
    ]
  }
  useEffect(() => {
    if( textArray.length > 0 && imageVideoArray.length > textArray.length ) {
      setActiveTab(1)
    }
  }, [])

  return (
    <VerbatimWrapper>
      <Header options={OPTIONS || undefined} onChange={setActiveTab} value={activeTab} />
      {activeTab === 0 && textArray.length > 0 && <VerbatimGrid entityType={entityType} bg={bg} data={textArray} insightsElementId={insightsElementId} showEntityName={showEntityName} />}
      {activeTab === OPTIONS.length-1 && imageVideoArray.length > 0 && <VerbatimImgVid entityType={entityType} bg={bg} data={imageVideoArray} insightsElementId={insightsElementId} showEntityName={showEntityName} />}
    </VerbatimWrapper>
  )
}

const VerbatimImgVid = ({ data, bg = 'transparent', insightsElementId, showEntityName=false, entityType }) => {
  return (
    <VerbatimImgVidWrapper bg={bg}>
      {data.map((v, i) => {
        const type = (v?.VerbatimMediaPrivateName)?.split('.').pop() || null
        return (
          <ImgWrapper key={i} className={`imgWrapper ${v.VerbatimSentiment} ind${i}`}>
            {type && ['jpeg'].includes(type) && <MuiImage src={`${CONST_VERBATIM_PATH}${v?.VerbatimMediaPrivateName}`} alt={v?.VerbatimMediaPrivateName} />}
            {type && ['mp4'].includes(type) && <video width="275" height="160" controls>
              <source src={`${CONST_VERBATIM_PATH}${v?.VerbatimMediaPrivateName}`} type="video/mp4" />
              <img src={imageNotLoaded} alt={imageNotLoaded} width='100%' />
            </video>}
            <span key={i}>
              {v?.VerbatimText}
              <strong>{`[${v.EntityName}${v.VerbatimAdditionalInfo ? `, ${v.VerbatimAdditionalInfo}` : ''}]`}</strong>
            </span>
          </ImgWrapper>
        )
      })}
    </VerbatimImgVidWrapper>
  )
}

export default VerbatimImageVideoGrid
