import { styled } from '@mui/material/styles'
import { Box, Typography } from '@mui/material'
import { recommendations } from '../../../../assets/images/icons'
import { exportToPPT_tableCss, tableCss, ulLiCss, ulLiCssRecommendation } from '../../../../util'
import { MEDIA } from '../../../../constants'

export const OuterWrapper = styled(Box)(`
    width:100%;
    padding:0;
    // padding:20px 30px 50px 30px;
    // @media (max-width: ${MEDIA.lg}) {
    //     padding:20px 20px 40px 20px;
    // }
`)

export const InnerWrapper = styled(Box)(`
    display:flex; flex-direction: column; width:100%; position: relative;
    &.exportToPPT {
        * {font-size: 14px;}
        .recommendationRow.li_H4 { list-style:none; }
        .recommendationRow.li_H4 h4 {
            background: #F7F7F9 url(${recommendations}) no-repeat 10px center;
            text-transform: capitalize; border-radius: 2px; font-weight: 500; width: fit-content;
            min-width:220px; padding: 10px 10px 10px 40px; display:block;
        }
        .recommendationRow.li_H4 ul {display:none;}
        .recommendationRow.li table {display:none;}
        .theadTr { background:#f1f1f1; height:auto !important;}
        .theadTr td, .tbodyTr td { padding:10px; border:1px #E0E0E0 solid; height:auto !important;}
        .tbodyTr td ul{ margin-left:10px;}
    }
    ${ exportToPPT_tableCss }

`)

export const ContentWrapper = styled(Box)(`
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    color: #000000;

    & b, & strong {
        font-weight: 500;
    }

    & p {
        padding-bottom:10px;
    }

    ${ tableCss }

    & ul {
        list-style-type: disc; // new
        ${ ulLiCss } // ulLiCssRecommendation
    }
    & > ul, & > ol {
        list-style-position: outside;
        margin-left: 0px;
        list-style: none;

        & > li {
            background: #FFFFFF;
            box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.05);
            border-radius: 4px;
            padding: 30px 10px 10px 10px;
            margin: 20px 0 40px;

            &:last-child{
                margin-bottom: 0;
            }

            & > ul > li:has(> hr), & > ol > li:has(> hr) {display:none;}
            & > ul > li > hr, & > ol > li > hr  {opacity:0.05; border:0;}

            & > s, & > h4 {
                text-transform: capitalize;
                background: #F7F7F9 url(${recommendations}) no-repeat 10px center;
                border-radius: 2px;
                font-weight: 500;
                width: fit-content;
                min-width:220px;
                padding: 10px 10px 10px 40px;
                font-size: 14px;
                margin: -50px 0 10px;
                text-decoration: none;
                display:block;
            }

            // & > ul {
            //     list-style:disc;
            // }

            & > ul, & > ol {
                margin-left:30px;
                & > li > ul {
                    margin:5px 5px 5px 20px;
                    // list-style:circle; // new
                }
                & > li > ol {
                    margin:5px 5px 5px 20px;
                }
                & > li > ul ul, & > li > ol ul {
                    margin:5px 5px 5px 20px;
                    list-style:revert;
                }
                & > li > ul ol {
                    margin:5px 5px 5px 20px;
                }
            }

        }
    }
`)
