import React from 'react'

const IconArrowLeft = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.47175 7.51053H12.5475C12.7974 7.51053 13 7.28937 13 7.01227C13 6.7371 12.7989 6.51402 12.5475 6.51402H2.49281L6.74268 1.84902C6.91892 1.65557 6.91931 1.34237 6.74081 1.14644C6.56355 0.951855 6.27673 0.95121 6.10075 1.14438L1.17551 6.55072C1.09064 6.64388 1.04655 6.76481 1.04358 6.88705C0.963765 7.07183 0.993763 7.29744 1.13209 7.44928L6.05733 12.8556C6.23331 13.0488 6.52013 13.0481 6.69739 12.8536C6.8759 12.6576 6.8755 12.3444 6.69927 12.151L2.47175 7.51053Z" fill="black" stroke="black" stroke-width="1.2"/>
    </svg>
  )
}

export default IconArrowLeft