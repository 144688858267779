import React from 'react'

const IconUser = ({fill="black", width=18, height=23}) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.3931 2.60695C18.869 6.14491 18.869 11.8551 15.3931 15.3931C11.8551 18.869 6.14491 18.869 2.60695 15.3931C-0.868982 11.8551 -0.868982 6.14491 2.60695 2.60695C6.14491 -0.868982 11.8551 -0.868982 15.3931 2.60695V2.60695ZM14.7724 3.22759C11.6069 0.0620357 6.39309 0.0620357 3.22766 3.22759C0.0621 6.39315 0.0621 11.6069 3.22766 14.7723C6.39321 17.9998 11.607 17.9998 14.7724 14.7723C17.9999 11.6068 17.9999 6.39302 14.7724 3.22759Z" fill="#D5732E"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M6.96573 6.3872C6.96573 7.34933 6.3158 8.12913 5.51417 8.12913C4.71243 8.12913 4.0625 7.34933 4.0625 6.3872C4.0625 5.4252 4.71243 4.64526 5.51417 4.64526C6.3158 4.64526 6.96573 5.4252 6.96573 6.3872Z" fill="#D5732E"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M13.9345 6.3872C13.9345 7.34933 13.2846 8.12913 12.4828 8.12913C11.6812 8.12913 11.0312 7.34933 11.0312 6.3872C11.0312 5.4252 11.6812 4.64526 12.4828 4.64526C13.2846 4.64526 13.9345 5.4252 13.9345 6.3872Z" fill="#D5732E"/>
      <path d="M5.22817 11.0677C5.1632 10.5816 6.00831 10.4602 6.07328 10.9461C6.20335 11.6752 6.52833 12.222 7.04845 12.6473C8.15368 13.5586 9.84401 13.5586 10.9491 12.6473C11.4693 12.2221 11.7943 11.6752 11.9243 10.9461C11.9893 10.4601 12.8345 10.5816 12.7694 11.0677C12.6394 11.979 12.1843 12.708 11.4692 13.2548C10.0388 14.4092 7.95835 14.4092 6.52816 13.2548C5.813 12.708 5.35796 11.979 5.2279 11.0677H5.22817Z" fill="#D5732E"/>
    </svg>
  )
}

export default IconUser