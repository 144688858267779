import styled from '@emotion/styled'
import { Box, Typography } from '@mui/material'
import MuiButton from '../muiButton'
import { LEGEND_TEXT_COLOR_MAP } from '../../constants'

export const Wrapper = styled('ul')( props => `
    padding-left:15px;
    width:100%;
    padding: 15px 15px 10px 30px;
    list-style:none;
    background-color:${props.bg}
`)

export const Content = styled('li')(`
    position: relative;
    line-height: 19px;
    padding-bottom: 5px;
    font-weight: 300;
    font-size: 14px;

    &.Positive {
        color: ${LEGEND_TEXT_COLOR_MAP.Positive};
    }

    &.Negative {
        color: ${LEGEND_TEXT_COLOR_MAP.Negative};
    }

    &.Mixed {
        color: ${LEGEND_TEXT_COLOR_MAP.Mixed};
    }

    &.Positive:before {
        content:'+';
    }
    &.Negative:before {
        content:'-';
    }
    &.Mixed:before {
        content:'~';
    }
    &:before {
        position: absolute;
        left: -18px;
        top: 0px;
    }
`)


export const IconWrap = styled(Box)(`

`);

export const InputContainer = styled(Box)(`

`);

export const InputWrap = styled(Box)(`
    &.top {
        margin-bottom: 20px;
    }
    & label {
        margin-bottom: 2px;
    }
`);


export const ModalTitle = styled(Typography)(`
  margin-top: 10px;
  padding-bottom: 30px;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  text-align: left;
  color: #000;
`)

export const ModalWrapper = styled(Box)(`
  width:100%;
  max-width: 600px;
  width: 80vw;
`)

export const ModalButton = styled(MuiButton)(`
  float: left;
  margin-left: 0px !important;
  margin-top: 20px !important;
`)

export const Text1 = styled('span')(`
    display:block;
    overflow-wrap: anywhere;
`)

export const Text2 = styled('span')(`
    color:#000;
    margin-left:8px;
    font-size:12px;
`)
