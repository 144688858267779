import { styled } from '@mui/material/styles'
import { Box, Typography } from '@mui/material'
import { bigArrow } from '../../../../assets/images/icons'
import { exportToPPT_tableCss } from '../../../../util'
import { MEDIA } from '../../../../constants'

export const OuterWrapper = styled(Box)(`
    width: 100%;
    // padding: 20px 30px 20px 30px;
    display: flex;
    align-items: center;

    @media (max-width: ${MEDIA.lg}) {
        padding:20px 20px 40px 20px;
    }
    @media (max-width: 980px) {
        padding: 30px 20px;
        min-width: 100%;
        flex-direction:column;
        & > div {
            margin:0;
            width:100%;
        }
        & *:before, & *:after{display:none}
    }

    &.exportToPPT { width:2140px; }
    &.exportToPPT .chartLhs { width:calc(100% - 500px); }
    &.exportToPPT .chartRhs { max-width:500px; }
    ${ exportToPPT_tableCss }
`)

export const ChartLhs = styled(Box)(`
    display: flex;
    width: 60%;
    position: relative;
    align-items: center;
`)

export const ChartRhs = styled(Box)(`
    width: 40%;
    background: #FFFBF8;
    padding: 40px 30px 40px 40px;
    box-shadow: 0px 0px 10px #FFC79F;
`)

export const TitlePie = styled(Typography)(`
    margin-top:15px;
    padding:0 5px;
    height: 20px;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    text-transform: capitalize;
    color: #FFFFFF;
    background: #475467;
    border-radius: 2px;
    display: inline-block;
`)
