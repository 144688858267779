import React, { useState, useEffect, useRef } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import ReportsService from '../../../../../services/reports.service'

import { Modal, Rte, MuiButton, MuiSelect } from '../../../../../components'
import { IconEdit } from '../../../../../assets/images/icons'

import { LoginButton, Title, Wrapper, RteWrapper } from './style'

const ReportEditBrandAttributeMap = (props) => {
  const {InsightsElementId, pageData='', dropdownData, onSuccess} = props
  const [anchorElUser, setAnchorElUser] = useState(null)
  const [showEditHeaderModal, setShowEditHeaderModal] = useState(false)
  const [dropdownValue, setDropdownValue] = useState('')
  const [showInsightsRte, setShowInsightsRte] = useState(true)

  const handleOnEditHeader = () => {
    setAnchorElUser(null)
    setShowEditHeaderModal((prev)=>!prev)
  }

  const form = useRef()
  const [loader, setLoader] = useState(false)
  const [insightsValue, setInsightsValue] = useState('')
  
  // const { enqueueSnackbar } = useSnackbar()

  const onSubmit = (e) => {
    e.preventDefault()
    
    const updatedValues = {
      insightsElementId : dropdownValue?.PeriodInsightsElementId || InsightsElementId,
      driverId : dropdownValue.value,
      insights : insightsValue,
    }

    setLoader(true)
    ReportsService.setBrandAttributeMapInsightsApi(updatedValues)
    .then((resp) => {
      setLoader(false)
      setShowEditHeaderModal(false)
      onSuccess()
    })
    .catch((error) => {
      setLoader(false)
      const resMessage = error
      console.log('ERROR : ', resMessage)
        // enqueueSnackbar(resMessage, { variant: 'error' })
    })
  }

  const driverChangeFunc = (value) => {
    setDropdownValue(dropdownData.find(f => f.value === value))
    const insightData = pageData.find(f => f.DriverId === value)?.Insights || ''
    setInsightsValue(insightData)
    setShowInsightsRte(false)
    setTimeout(() => {
      setShowInsightsRte(true)
    }, 10)
  }

  return (
    <>
      <MuiButton color='secondary' variant='outlined' startIcon={<IconEdit />}  onClick={() => setShowEditHeaderModal(true)}>Edit Brand Attribute Map</MuiButton>
      {showEditHeaderModal && <Modal title='' showClose={true} onClose={handleOnEditHeader}>
        <Wrapper>
          <form ref={form}>
            <Title variant='body1'>Edit Brand Attribute Map</Title>

            <RteWrapper>
              <MuiSelect
                name="driverName"
                onChange={(e) => driverChangeFunc(e.target.value)}
                title="Drivers"
                data={dropdownData}

                // menuprops={{ style: { maxHeight: '200px', maxWidth: '180px' } }}
                // compact
                placeholder="Select"
                variant={"outlined"}
                // variant={isShadow ? "outlined" : "standard"}
                value={dropdownValue.value || null}
              />
              {showInsightsRte && <Rte title='' value={insightsValue} onChange={(e) => setInsightsValue(e)} />}
            </RteWrapper>

            <LoginButton
              color='primary'
              variant='contained'
              type='submit'
              onClick={onSubmit}
              disabled={loader || false}
            >
              Submit
            </LoginButton>
          </form>
        </Wrapper>
      </Modal>}
    </>
  )
}

export default ReportEditBrandAttributeMap
