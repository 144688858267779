// import './styles.css';
import React from 'react';
import { RadialBarChart, RadialBar, Legend, Tooltip } from 'recharts';
import { List, ListItem, Box, Typography } from '@mui/material'
import { Wrapper, LegendWrapper } from './style'

const colorCodes = ['#C1DDFF', '#99C5FA', '#67A5F0', '#4890E7', '#2775D3', '#125db7']
// const colorCodes = ['#125db7', '#2775D3', '#4890E7', '#67A5F0', '#99C5FA', '#C1DDFF']

export default function ChartRadialBar(props) {
  const {SourceSplit=[]} = props
  // let chartData = [...SourceSplit].map((d, i) => {
  let chartData = [...SourceSplit].sort((a, b) => a.Percent - b.Percent).map((d, i) => {
    return {
      name: `${d.Platform}  ${d.Percent}%`,
      uv: d.Percent,
      fill: colorCodes[i]
    }
  })
  chartData.push({
    name: '',
    uv: 100,
    fill: 'transparent'
  })

  const lineHeight = `calc(100% / ${chartData.length})` 

  return (
    <Wrapper>
      <RadialBarChart
        width={350}
        height={350}
        cx={175}
        cy={175}
        innerRadius={55}
        outerRadius={210}
        barSize={16}
        barGap={7}
        data={chartData}
        startAngle={90} 
        endAngle={-270}
      >
        <RadialBar
          minAngle={45}
          // label={{ position: 'insideStart', fill: '#fff' }}
          background
          clockWise
          dataKey='uv'
        />
      </RadialBarChart>
      <LegendWrapper>
        {[...chartData].reverse().map((d, i) =>{ return (<ListItem key={i} sx={{height:lineHeight}}>{d.name}</ListItem>)} )}
      </LegendWrapper>
    </Wrapper>
  );
}
