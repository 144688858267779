import { Box, Typography } from '@mui/material'
import React from 'react'
import { MuiImage } from '../../../../components'
import { MAGNET_SCORE_IMAGE } from '../../../../constants'
import { getImageUrl } from '../../../../util'
import { StyledCellWrap } from './style'

const LandScapeHeader = ({ scores = [], showScore }) => {
  
  // const listWidth = (scores.length < 1) ? '300px' : `${100 / (scores.length)}%`
  return (
    <>
      <StyledCellWrap
        className='listHeading'
        padding={'10px'}
        sx={{
          display: 'flex',
          flexDirection: 'row !important',
          justifyContent: 'flex-start !important',
          marginLeft: '0 !important',
          border: '0 !important',
          paddingLeft: 0,
          marginBottom: showScore ? 0 : "20px",
          alignItems: 'center !important',
          '& img': {
            maxHeight: '35px !important'
          }
        }}
        width={`${100 / (scores.length + 1)}%`}
        // width={listWidth}
      >
        {showScore &&
          <>
            <Box
              dangerouslySetInnerHTML={{ __html: 'Magnet<br/>Score' }}
              sx={{
                textAlign: 'left',
                marginRight: '10px',
                fontWeight: '500',
                fontSize: '16px',
                lineHeight: '16px'
              }}
            />
            <MuiImage src={MAGNET_SCORE_IMAGE['Start'].icon} width='35px' height='33px' />
          </>
        }
      </StyledCellWrap>
      {scores?.map((item) => {
        // const imgObj = MAGNET_SCORE_IMAGE?.[item?.logo]
        const imgObj = getImageUrl('logos', item?.logo, 'png')
        return (
          <StyledCellWrap
            className='listHeading'
            alignItems='center'
            padding='10px'
            key={item.name}
            border='0 !important'
            // width={listWidth}
            width={`${100 / (scores.length + 1)}%`}
            sx={{
              background: 'transparent !important',
            }}
          >
            <MuiImage src={imgObj} alt={imgObj} />
            {/* <MuiImage src={imgObj?.icon} width={imgObj.width} height={imgObj.height} /> */}
            <Typography fontWeight={500} marginTop='auto'>{item.score}</Typography>
          </StyledCellWrap>
        )
      })}
    </>
  )
}

export default LandScapeHeader
