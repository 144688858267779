import { styled } from '@mui/system'
import { Box, IconButton } from '@mui/material'

export const Wrapper = styled(Box)(`
    position:relative;
    margin-right:10px;
    width: 230px;
    height: 34px;
    line-height: 34px;
    // &>div:first-of-type{
    &>div:first-of-type{
        width: 230px;
        height: 34px;
        line-height: 34px;
    }
    & .searchBox input{
        padding-top:0;
        padding-bottom:0;
        height: 34px;
        line-height: 34px;
    }
`)

export const IconButtonWrapper = styled(IconButton)(`
    position:absolute;
    right: 15px;
    top:8px;
    width: 19px;
    height: 19px;
`)
