import axios from 'axios'
import { CONST_API_URL, CONST_API_JSON_URL } from '../constants'
import {soapEnvelopeAdding, soapEnvelopeRemoving} from './soapEnvelope'
import { checkLocalStorageSize } from '../util'
import { isTestingOn, isTestingOnFlag, isTestingOnFunc } from '../testingComponent/testingComponents'
import { getUserDetails } from '.'

const preLoginInstance = axios.create({
    baseURL: CONST_API_URL
})

// preLoginInstance.defaults.headers.common['Origin'] = '*'
preLoginInstance.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
preLoginInstance.defaults.headers.common['mode'] = 'cors'
// preLoginInstance.defaults.headers.common['Authorization'] = 'Bearer Token'
preLoginInstance.defaults.headers.common['CompanyId'] = getUserDetails?.CompanyId || ''
preLoginInstance.defaults.headers.common['UserId'] = getUserDetails?.UserId || ''
preLoginInstance.defaults.headers.post['Content-Type'] = 'text/xml'

const ApiPost = async (options) => {
    const {reqId, payload, resId, resType='object', getLatest=false, getFromLocal=true} = options
    checkLocalStorageSize()
    // if(reqId === 'GetDriverElements'){
        // console.log('reqId, payload', reqId, payload)
    // }
    // if (getLatest && window.localStorage.logs) {
    //     const data1 = JSON.parse(window.localStorage.getItem('logs'))
    //     const data2 = data1.filter(id => id.reqId === reqId).filter(p => JSON.stringify(p.payload) === JSON.stringify(payload) )
    //     if (data2.length) {
    //         // REMOVING ADDITIONAL OLD LOG DATA
    //     }
    // }

    if (!getLatest && window.localStorage.logs && getFromLocal) {
        let data1 = JSON.parse(window.localStorage.getItem('logs'))
        if(reqId === 'GetStudies'){
            data1 = JSON.parse(window.localStorage.getItem('GetStudies'))
        } else if(reqId === 'GetProjects'){
            data1 = JSON.parse(window.localStorage.getItem('GetProjects'))
        } else if(reqId === 'GetCompany'){
            data1 = JSON.parse(window.localStorage.getItem('GetCompany'))
        } else if(['GetStudyLayoutTabs', 'GetInsightsElementsForTab', 'GetInsightsElementHeading', 'GetInsightsElementInsights', 'GetBusinessObjectiveElement', 'GetRecommendationElement'].includes(reqId)){
            data1 = JSON.parse(window.localStorage.getItem('GetSmallApiLogs'))
        }

        if(data1 !== null){
            // const data1 = JSON.parse(window.localStorage.getItem('logs'))
            const data2 = data1.filter(id => id.reqId === reqId).filter(p => JSON.stringify(p.payload) === JSON.stringify(payload) )
            if (data2.length) {
                const index = data2.length - 1
                const onSuccess = async () => data2[index].response
                const promise =  new Promise((resolve, reject) => {
                    resolve(onSuccess())
                    // reject(onError())
                })
                return promise
            }
        }
    }

    const soapDataPayload = soapEnvelopeAdding(reqId, payload)
    // const axiosPost = await preLoginInstance.post(`&tag=${reqId}&getLatest=${getLatest}`, soapDataPayload)
    const axiosPost = await preLoginInstance.post('', soapDataPayload)
    
    const onSuccess = async () => {
        const resp1 = axiosPost.request.response
        let resp2 = await soapEnvelopeRemoving(resId, resp1, resType)

        // if(payload.insightsElementId === 'b5698222-268f-11ee-817c-0a1ea4c5532f') {
        //     console.log('reqId, payload', reqId, payload)
        //     console.log('InsightsElementId b5698222-268f-11ee-817c-0a1ea4c5532f ---------> ', resp1)
        // }

        // console.log('before = resId, resp2, payload = ', resId, resp2, payload)
        if (isTestingOnFlag()) {
            resp2 = isTestingOnFunc(reqId, resp2, payload)
        }


        if (window.localStorage.getItem('logs') && getFromLocal) {
            if(reqId === 'GetStudies'){
                const oldData = JSON.parse(window.localStorage.getItem('GetStudies')) || []
                resp2 = isTestingOn(reqId, resp2, payload) // THIS IS BYPASS CODE
                window.localStorage.setItem('GetStudies', JSON.stringify([{reqId:reqId, payload:payload, response:resp2}, ...oldData]))
            } else if(reqId === 'GetProjects'){
                resp2 = isTestingOn(reqId, resp2) // THIS IS BYPASS CODE
                window.localStorage.setItem('GetProjects', JSON.stringify([{reqId:reqId, payload:payload, response:resp2}]))
            } else if(reqId === 'GetCompany'){
                window.localStorage.setItem('GetCompany', JSON.stringify([{reqId:reqId, payload:payload, response:resp2}]))
            } else if(['GetStudyLayoutTabs', 'GetInsightsElementsForTab', 'GetInsightsElementHeading', 'GetInsightsElementInsights', 'GetBusinessObjectiveElement', 'GetRecommendationElement'].includes(reqId)){
                const oldData = JSON.parse(window.localStorage.getItem('GetSmallApiLogs')) || []
                let finalData = [{reqId:reqId, payload:payload, response:resp2}, ...oldData]
                for(let i=0; i<oldData.length; i++){
                    const d = oldData[i]
                    if(d.reqId === reqId && JSON.stringify(d.payload) === JSON.stringify(payload)){
                        oldData[i] = {reqId:reqId, payload:payload, response:resp2}
                        finalData = oldData
                        break
                    }
                }

                window.localStorage.setItem('GetSmallApiLogs', JSON.stringify(finalData))
            } else {
                let data1 = JSON.parse(window.localStorage.getItem('logs'))
                data1.push({reqId:reqId, payload:payload, response:resp2})
                window.localStorage.setItem('logs', JSON.stringify(data1))    
            }
        }

        checkLocalStorageSize()
        return resp2
    }
    
    // const onError = async () => {
    //     console.log('Error Handeling WIP -------- ')
    //     const resp1 = axiosPost.request.response
    //     const resp2 = await soapEnvelopeRemoving('soap:Text', resp1)
    //     return resp2
    // }

    const promise =  new Promise((resolve, reject) => {
        resolve(onSuccess())
        // reject(onError())
    })
    
    return promise
}




const preLoginJsonInstance = axios.create({
    baseURL: CONST_API_JSON_URL
})
preLoginJsonInstance.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
preLoginJsonInstance.defaults.headers.post['Content-Type'] = 'application/json'
// preLoginJsonInstance.defaults.headers.post['Content-Type'] = 'text/json'
preLoginJsonInstance.defaults.headers.common['mode'] = 'cors'

 

export {preLoginInstance, ApiPost, preLoginJsonInstance}
