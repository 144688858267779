import { styled } from '@mui/system'
import { Box, Typography, FormControlLabel } from '@mui/material'
import MuiButton from '../../components/muiButton'

export const LoginButton = styled(MuiButton)(`
  margin-bottom:15px;
  margin-top:30px;
  width:160px;
  height:40px;
  text-transform: uppercase;
  background: #545454;
`)

export const LoginContent1 = styled(Box)(`
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  justify-content: space-between;
  & * {
    font-size: 14px !important;
    line-height: 21px;
    color: #000 !important;
  }
`)

export const LoginContent2 = styled(Typography)(`
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  & * {
    color: #000 !important;
  }
`)

export const ErrorMsg = styled(Typography)(`
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;  
  color: #FF0000;
`)

export const FormControlLabelWrapper = styled(FormControlLabel)(`
  margin-left: 0;
  & > span:first-of-type {
    width: 15px;
    height: 15px;
    padding-right: 12px !important;
  }
  & > span:first-of-type * {
    width: 18px;
    height: 18px;
  }
  & > span:last-child {
    font-weight: 300;
    line-height: 21px;
    color: #000;
  }
`)

export const EmailInputWrap = styled(Box)(`
  height: 80px;
  margin-top: 10px;
  & .MuiFormLabel-root.MuiInputLabel-root{
    font-weight: 300;
  }
`)

export const PasswordInputWrap = styled(Box)(`
  min-height: 60px;
  & .MuiFormLabel-root.MuiInputLabel-root{
    font-weight: 300;
  }
  & input {
    font-size: 16px !important;
  }
`)