import { Box, Checkbox, Divider, Drawer, IconButton, Skeleton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { checkboxChecked, checkboxUnchecked, close, download, iconDownloadWhite } from '../../../../assets/images/icons'
import { BlockingLoader, MuiButton, MuiImage, Loader } from '../../../../components'
import reportsService from '../../../../services/reports.service'
import { useGlobalStore } from '../../../../store'
import { EXPORT_PPT } from '../../../../constants'
import { FormGroupLabelWrap, Heading, SubHeading, PPTBody, PPTHeader, PPTWrap, SubLHSItemWrapper, SubLHSOuterWrap, SubLHSItem, SubLHSWrap, HeadingForDrawer, Breaker, PPTBodyTabs, TabLink } from './styles'
import Listing from './pptDrawerListing'

const loaderData = [
  {
    subLhs: [
      {}, {}, {}
    ]
  },
  {
    subLhs: [
      {}, {}, {}
    ]
  }
]

const PptDrawer = ({ handleDownload }) => {
  const [state, setState] = useState(loaderData)
  const [isLoading, setLoading] = useState(false)
  const [isDownloading, setDownloading] = useState(false)
  const [showTab, setShowTab] = useState(0)
  const [total, setTotal] = useState(0)
  const [totalAppendices, setTotalAppendices] = useState(0)
  const [totalProgression, setTotalProgression] = useState(0)
  const [pptSubLhsLoading, setPptSubLhsLoading] = useState(0)
  const [showProgression, setShowProgression] = useState(false)
  const {lhs, setPptIdsType, isPPTModalActive, setPPTModalActive, setPPTModalActiveAndClicked, setPPTSubLhs, pptSubLhs, pptIdsType, setPPTSubLhsAppendices, pptSubLhsAppendices, setPPTSubLhsProgression, pptSubLhsProgression, subLhsApiLoaded, setSubLhsApiLoaded } = useGlobalStore();

  const getSubHeadings = async (tabId, studyId) => {
    const resp = await reportsService.reportTabsApi({ studyId: studyId, tabId });
    const lhsTabsRes = resp.sort((a, b) => a.InsightsElementOrder - b.InsightsElementOrder).filter(t => t.InsightsElementVisible);
    return lhsTabsRes;
  }

  const attachAppendices = (data) => {
    const DriversList = [...EXPORT_PPT.deepDiveDrivers, ...EXPORT_PPT.deepDiveSegments]
    return data
      ?.filter(f => DriversList.includes(f.InsightsElementType))
      ?.map(m => ({
        ...m,
        InsightsElementTypeAppendices: 'Appendices'+m.InsightsElementType
      }))
  }

  const attachProgression = (data) => {
    const DriversList = EXPORT_PPT.deepDiveProgression
    return data
      ?.filter(f => DriversList.includes(f.InsightsElementType))
      ?.map(m => ({
        ...m,
        InsightsElementTypeProgression: 'Progression'+m.InsightsElementType
      }))
  }

  useEffect(() => {
    const prep = async () => {
      if (isPPTModalActive) {
        const arr = [];
        setLoading(true)
        for (let index = 0; index < lhs.length; index++) {
          const element = lhs[index];
          if (element.title === "Sandbox") continue;
          const resp = await getSubHeadings(element.TabId, element.StudyId);
          const obj = {
            ...element,
            subLhs: resp,
            subLhsAppendices: attachAppendices(resp.filter(f => f?.InsightsElementDeepDive)),
            subLhsProgression: attachProgression(resp.filter(f => f?.InsightsElementDeepDive))
          }
          arr.push(obj)
        }
        setLoading(false)
        setState(arr)
      }
    }
    prep()
  }, [isPPTModalActive])

  const sortData = (sl) => {
    const arr = [];
    const allData = [];
    for (let index = 0; index < state.length; index++) {
      const element = state[index];
      for (let jIndex = 0; jIndex < element.subLhs.length; jIndex++) {
        const innerElement = element.subLhs[jIndex];
        allData.push(innerElement);
        const item = sl.find(pptItem => pptItem.InsightsElementId === innerElement.InsightsElementId)
        if (item) {
          arr.push(item);
        }
      }
    }
    return {
      selected: arr,
      all: allData
    }
  }

  const sortDataAppendices = (sl) => {
    const arr = [];
    const allData = [];
    for (let index = 0; index < state.length; index++) {
      const element = state[index];
      for (let jIndex = 0; jIndex < element.subLhs.length; jIndex++) {
        const innerElement = element.subLhs[jIndex];
        allData.push(innerElement);
        const item = sl.find(pptItem => pptItem.InsightsElementId === innerElement.InsightsElementId)
        if (item) {
          arr.push(item);
        }
      }
    }
    return {
      selected: arr,
      all: attachAppendices(allData)
    }
  }

  const sortDataProgression = (sl) => {
    const arr = [];
    const allData = [];
    for (let index = 0; index < state.length; index++) {
      const element = state[index];
      for (let jIndex = 0; jIndex < element.subLhs.length; jIndex++) {
        const innerElement = element.subLhs[jIndex];
        allData.push(innerElement);
        const item = sl.find(pptItem => pptItem.InsightsElementId === innerElement.InsightsElementId)
        if (item) {
          arr.push(item);
        }
      }
    }
    return {
      selected: arr,
      all: attachProgression(allData)
    }
  }

  const subLhsChangeHandler = (subLhsData) => {
    const isPresent = pptSubLhs.find(item => item.InsightsElementId === subLhsData.InsightsElementId);
    if (!!isPresent) {
      const filteredData = pptSubLhs.filter(item => item.InsightsElementId !== subLhsData.InsightsElementId);
      const sortedData = sortData(filteredData);
      setPPTSubLhs(sortedData.selected);

      let updatingSubLhs = subLhsApiLoaded
      delete updatingSubLhs['id-'+isPresent.InsightsElementId]
      setSubLhsApiLoaded(updatingSubLhs)

    } else {
      const sortedData = sortData([...pptSubLhs, subLhsData]);
      setPPTSubLhs(sortedData.selected);
    }
  }

  const subLhsAppendicesChangeHandler = (subLhsDataAppendices) => {
    const isPresent = pptSubLhsAppendices.find(item => item.InsightsElementId === subLhsDataAppendices.InsightsElementId);
    if (!!isPresent) {
      const filteredData = pptSubLhsAppendices.filter(item => item.InsightsElementId !== subLhsDataAppendices.InsightsElementId);
      const sortedData = sortDataAppendices(filteredData);
      setPPTSubLhsAppendices(sortedData.selected);

      let updatingSubLhs = subLhsApiLoaded
      delete updatingSubLhs['id-'+isPresent.InsightsElementId]
      setSubLhsApiLoaded(updatingSubLhs)

    } else {
      const sortedData = sortDataAppendices([...pptSubLhsAppendices, subLhsDataAppendices]);
      setPPTSubLhsAppendices(sortedData.selected);
    }
  }

  const subLhsProgressionChangeHandler = (subLhsDataProgression) => {
    const isPresent = pptSubLhsProgression.find(item => item.InsightsElementId === subLhsDataProgression.InsightsElementId);
    if (!!isPresent) {
      const filteredData = pptSubLhsProgression.filter(item => item.InsightsElementId !== subLhsDataProgression.InsightsElementId);
      const sortedData = sortDataProgression(filteredData);
      setPPTSubLhsProgression(sortedData.selected);

      let updatingSubLhs = subLhsApiLoaded
      delete updatingSubLhs['id-'+isPresent.InsightsElementId]
      setSubLhsApiLoaded(updatingSubLhs)

    } else {
      const sortedData = sortDataProgression([...pptSubLhsProgression, subLhsDataProgression]);
      setPPTSubLhsProgression(sortedData.selected);
    }
  }

  const selectAllHandler = () => {
    const sortedData = sortData([]);
    setTotal(sortedData.all.length);
    if (sortedData.all.length === pptSubLhs.length) {
      setPPTSubLhs([]);
    } else {
      setPPTSubLhs(sortedData.all);
    }
  }

  const selectIncludeAppendices = () => {
    const sortedData = sortDataAppendices([]);
    setTotalAppendices(sortedData.all.length);
    if (sortedData.all.length === pptSubLhsAppendices.length) {
      setPPTSubLhsAppendices([]);
    } else {
      setPPTSubLhsAppendices(sortedData.all.filter(f => f.InsightsElementDeepDive))
    }
  }

  const selectIncludeProgression = () => {
    const sortedData = sortDataProgression([]);
    setTotalProgression(sortedData.all.length);
    if (sortedData.all.length === pptSubLhsProgression.length) {
      setPPTSubLhsProgression([]);
    } else {
      setPPTSubLhsProgression(sortedData.all.filter(f => f.InsightsElementDeepDive))
    }
  }

  const selectIncludeAppendicesProgression = () => {
    const sortedDataA = sortDataAppendices([]);
    const sortedDataP = sortDataProgression([]);
    setTotalAppendices(sortedDataA.all.length);
    setTotalProgression(sortedDataP.all.length);
    if (sortedDataA.all.length === pptSubLhsAppendices.length && sortedDataP.all.length === pptSubLhsProgression.length) {
      setPPTSubLhsAppendices([]);
      setPPTSubLhsProgression([]);
    } else {
      setPPTSubLhsAppendices(sortedDataA.all.filter(f => f.InsightsElementDeepDive))
      setPPTSubLhsProgression(sortedDataP.all.filter(f => f.InsightsElementDeepDive))
    }
  }

  const loadingCB = () => {
    setDownloading(false);
  }

  const downloadHandler = (e) => {
    e.preventDefault()
    if (isLoading || !(pptSubLhs?.length + pptSubLhsAppendices?.length + pptSubLhsProgression?.length) ) {
      return;
    }
    setDownloading(true)
    // handleDownload(loadingCB)
  }

  useEffect(() => {
    if(isDownloading){
      handleDownload()
      setDownloading(false)
    }
  }, [isDownloading, pptSubLhs, subLhsApiLoaded])

  const handleClose = () => {
    setPPTModalActive(false);
    setPPTModalActiveAndClicked(false)
    setPPTSubLhs([])
    setPPTSubLhsAppendices([])
    setPPTSubLhsProgression([])
  }

  const addSubHeading = (data) => {
    const groupNames = []
    const d = data.map(i => {
      const data = {...i, showListContent:true}
      if(data?.ParentTitle && !groupNames.includes(data.ParentTitle)){
        groupNames.push(data.ParentTitle)
        data.groupName = data.ParentTitle;
        data.groupClass = 'nestedLinkStart';
      }
      else if(data?.ParentTitle){
        data.groupClass = 'nestedLinkMid';
      }
      return data
    })

    for(let i=1; i<d.length; i++){
      if((d[i].groupClass === 'nestedLinkStart' || d[i].groupClass === '') && d[i-1].groupClass === 'nestedLinkMid'){
        d[i-1].groupClass = 'nestedLinkEnd'
      } else if(d[i].groupClass === 'nestedLinkMid' && i === (d.length-1)){
        d[i].groupClass = 'nestedLinkEnd'
      }
    }
    return(d)
  }

  // const getProgressionLength = (array) => {
  //   const finalArray = []
  //   const finalArr = array.map((lhsItem, index) => {
  //     if( !lhsItem?.subLhsAppendices?.length ) return lhsItem
  //     const data = (lhsItem.subLhsAppendices).filter(f => (EXPORT_PPT.deepDiveSegmentsProgression.includes(f.InsightsElementTypeAppendices)))
  //     finalArray.push(data)
  //     return lhsItem
  //   }) || []
  //   return finalArray.flat(1)
  // }

  const tabClickedFunc = (tabId) => {
    setShowTab(tabId)

    if(tabId === 0) {
      setPPTSubLhsAppendices([]); setPPTSubLhsProgression([])
    } else if (tabId === 1) {
      setPPTSubLhs([]); setPPTSubLhsProgression([])
    } else if (tabId === 2) {
      setPPTSubLhs([]); setPPTSubLhsAppendices([]);
    }
  }

  const showProgressionFunc = () => {
    const progressionCount = state.map(lhsItem => lhsItem?.subLhsProgression?.length || 0 ).filter(f => f>0).length
    if(progressionCount>0) setShowProgression(true)
  }

  useEffect(() => {
    showProgressionFunc()
  },[state])

  useEffect(() => {
    const pptSlideLoading = pptSubLhs?.map(p => p?.status)?.filter(f => f===true).length || 0
    setPptSubLhsLoading(pptSlideLoading)
  },[pptSubLhs])

  return (
    <>
      <Drawer
        anchor="right"
        open={isPPTModalActive}
        onClose={handleClose}
      >
        <PPTWrap>
          <PPTHeader>
            {/* <MuiButton
              variant='contained'
              color={(pptSubLhs?.length + pptSubLhsAppendices?.length) > 0 ? "primary" : "light"}
              onClick={downloadHandler}
              startIcon={<MuiImage src={(pptSubLhs?.length + pptSubLhsAppendices?.length) > 0 ? iconDownloadWhite : download} />}
            >
              Export to PowerPoint
            </MuiButton> */}
            
            <PPTBodyTabs>
              <TabLink className={`${showTab===0 ? 'active' : ''}`} onClick={() => tabClickedFunc(0) }>Study Report</TabLink>
              <TabLink className={`${showTab===1 ? 'active' : ''}`} onClick={() => tabClickedFunc(1) }>Appendices</TabLink>
            </PPTBodyTabs>
            
            <IconButton onClick={handleClose}>
              <MuiImage src={close} />
            </IconButton>
          </PPTHeader>
          {/* <PPTBodyTabs>
            <TabLink className={`${showTab===0 ? 'active' : ''}`} onClick={() => { setShowTab(0); setPPTSubLhsAppendices([]) }}>Study Report</TabLink>
            <TabLink className={`${showTab===1 ? 'active' : ''}`} onClick={() => { setShowTab(1); setPPTSubLhs([]) }}>Appendices</TabLink>
          </PPTBodyTabs> */}
          <MuiButton
              variant='contained'
              color={(pptSubLhs?.length + pptSubLhsAppendices?.length + pptSubLhsProgression?.length) > 0 ? "primary" : "light"}
              onClick={downloadHandler}
              startIcon={<MuiImage src={(pptSubLhs?.length + pptSubLhsAppendices?.length + pptSubLhsProgression?.length) > 0 ? iconDownloadWhite : download} />}
              // disabled={loaderApi}
              // endIcon={loaderApi && <Loader />}
              style={{marginLeft:'25px', padding:'10px 16px'}}
            >
              Export to PowerPoint
          </MuiButton>
          <PPTBody>
            {
              !isLoading &&
              <SubLHSItemWrapper>
                {showTab===0 && <SubLHSItem>
                  <FormGroupLabelWrap
                    className='select-all'
                    label="Select All"
                    onChange={() => selectAllHandler()}
                    control={<Checkbox checked={total === pptSubLhs.length && pptSubLhs.length !== 0} />}
                  />
                </SubLHSItem>}
                {showTab===1 && <SubLHSItem>
                  <FormGroupLabelWrap
                    className='select-all'
                    label="Select All"
                    onChange={() => selectIncludeAppendicesProgression()}
                    control={<Checkbox checked={(totalAppendices+totalProgression) === (pptSubLhsAppendices.length+pptSubLhsProgression.length) && (pptSubLhsAppendices.length+pptSubLhsProgression.length) !== 0} />}
                  />
                </SubLHSItem>}
                {/* {showTab===1 && <SubLHSItem>
                  <FormGroupLabelWrap
                    className='select-all'
                    label="Select All"
                    onChange={() => selectIncludeAppendices()}
                    control={<Checkbox checked={totalAppendices === pptSubLhsAppendices.length && pptSubLhsAppendices.length !== 0} />}
                  />
                </SubLHSItem>}
                {showTab===1 && <SubLHSItem>
                  <FormGroupLabelWrap
                    className='select-all'
                    label="Select All"
                    onChange={() => selectIncludeProgression()}
                    control={<Checkbox checked={totalProgression === pptSubLhsProgression.length && pptSubLhsProgression.length !== 0} />}
                  />
                </SubLHSItem>} */}
              </SubLHSItemWrapper>
            }

            {
              showTab===0 && state.map((lhsItem, index) => {
                const subLhsHeading = addSubHeading(lhsItem.subLhs)
                return (
                  <Listing key={index} isLoading={isLoading} data={subLhsHeading} onChange={(p) => subLhsChangeHandler(p)} lhsItem={lhsItem} subLhsArray={pptSubLhs} />
                )
              })
            }

            {showTab===1 && state.length > 0 && <SubLHSOuterWrap className='type_deepDive'>
              {showProgression && <HeadingForDrawer>Deep Dive</HeadingForDrawer>}
              {
                state.map((lhsItem, index) => {
                  if( !lhsItem?.subLhsAppendices?.length ) return false
                  const data = (lhsItem.subLhsAppendices).filter(f => !(EXPORT_PPT.deepDiveSegmentsProgression.includes(f.InsightsElementTypeAppendices)))
                  const subLhsHeading = addSubHeading(data)
                  return (
                    <Listing key={index} isLoading={isLoading} data={subLhsHeading} onChange={(p) => subLhsAppendicesChangeHandler(p)} lhsItem={lhsItem} subLhsArray={pptSubLhsAppendices} />
                  )
                })
              }
            </SubLHSOuterWrap>}

            {/* {showTab===1 && state.length > 0 && getProgressionLength(state).length > 0 && <SubLHSOuterWrap className='type_progression'>
              <HeadingForDrawer>Progression</HeadingForDrawer>
              {
                state.map((lhsItem, index) => {
                  if( !lhsItem?.subLhsAppendices?.length ) return false
                  const data = (lhsItem.subLhsAppendices).filter(f => (EXPORT_PPT.deepDiveSegmentsProgression.includes(f.InsightsElementTypeAppendices)))
                  const subLhsHeading = addSubHeading(data)
                  return (
                    <Listing key={index} isLoading={isLoading} data={subLhsHeading} onChange={(p) => subLhsAppendicesChangeHandler(p)} lhsItem={lhsItem} subLhsArray={pptSubLhsAppendices} />
                  )
                })
              }
            </SubLHSOuterWrap>} */}

            {showProgression && showTab===1 && state.length > 0 && <SubLHSOuterWrap className='type_progression'>
              <Divider sx={{mb:2}} />
              <HeadingForDrawer>Progression</HeadingForDrawer>
              {
                state.map((lhsItem, index) => {
                  if( !lhsItem?.subLhsProgression?.length ) return false
                  const data = (lhsItem.subLhsProgression).filter(f => (EXPORT_PPT.deepDiveSegmentsProgression.includes(f.InsightsElementTypeProgression)))
                  const subLhsHeading = addSubHeading(data)
                  return (
                    <Listing key={index} isLoading={isLoading} data={subLhsHeading} onChange={(p) => subLhsProgressionChangeHandler(p)} lhsItem={lhsItem} subLhsArray={pptSubLhsProgression} />
                  )
                })
              }
            </SubLHSOuterWrap>}


          </PPTBody>
        </PPTWrap>
      </Drawer>

    </>
  )
}

export default PptDrawer

