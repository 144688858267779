import React, { useState, useRef, useEffect } from 'react'
import { Box } from '@mui/material'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import canWeHelpService from '../../services/canWeHelp.service'
import { MuiTextField, MuiButton, Modal } from '..'
import { InputWrap, LoginButton, Title, Wrapper } from './style'
import { useGlobalStore } from '../../store'


const CanWeHelp = (props) => {
  const { onClose, onLoadShow = false, showButton = true } = props
  const [anchorElUser, setAnchorElUser] = useState(null)
  const [showCanWeHelpModal, setShowCanWeHelpModal] = useState(false)

  const handleOnCanWeHelp = () => {
    setAnchorElUser(null)
    setShowCanWeHelpModal((prev) => !prev)
    onClose && onClose()
  }

  const form = useRef()
  const [loader, setLoader] = useState(false)
  const {user} = useGlobalStore();
  const [showMessage, setShowMessage] = useState(false)
  // const { enqueueSnackbar } = useSnackbar()

  const onSubmit = (data) => {
    setLoader(true)
    canWeHelpService.canWeHelpApi(data)
      .then((resp) => {
        setLoader(false)
        setShowMessage(true)
      })
      .catch((error) => {
        setLoader(false)
        const resMessage = error
        console.log('ERROR : ', resMessage)
        // enqueueSnackbar(resMessage, { variant: 'error' })
      })
  }

  const validation = (values) => {
    const errors = {}
    return errors
  }

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      userName: user?.UserName || '',
      email: user?.UserEmail || '',
      phoneNumber: '',
    },
    validationSchema: Yup.object({
      // userName: Yup.string().max(25).required('Name is required'),
      // email: Yup.string().max(25).required('Email is required'),
      phoneNumber: Yup.number() //.max(25).required('Phone Number is required')
    }),
    validate: (values) => validation(values),
    onSubmit: (values) => {
      const updatedValues = {
        clientName: user?.UserName,
        clientEmail: user?.UserEmail,
        clientPhoneNumber: values.phoneNumber,
        initiateNewProjectDropout: false,
      }
      onSubmit(updatedValues)
    }
  })

  useEffect(() => {
    setShowCanWeHelpModal(onLoadShow)
  }, [onLoadShow])

  return (
    <>
      {showButton && <Box className='BtnCanWeHelpButton'><MuiButton className="canWeHelpButton" variant='contained' color='light' onClick={() => setShowCanWeHelpModal(true)} style={{marginLeft:'15px'}}>Can We Help?</MuiButton></Box>}
      {showCanWeHelpModal && <Modal title='' showClose={true} onClose={handleOnCanWeHelp}>
        <Wrapper>
          {!showMessage &&
            <form ref={form} onSubmit={formik.handleSubmit}>
              <Title variant='body1'>Please confirm your contact information</Title>
              <InputWrap>
                <MuiTextField
                  error={Boolean(formik.touched.userName && formik.errors.userName)}
                  fullWidth
                  helperText={formik.touched.userName && formik.errors.userName}
                  label='Name'
                  name='userName'
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type='text'
                  value={formik.values.userName}
                  variant='standard'
                  disabled
                />
              </InputWrap>
              <InputWrap>
                <MuiTextField
                  error={Boolean(formik.touched.email && formik.errors.email)}
                  fullWidth
                  helperText={formik.touched.email && formik.errors.email}
                  label='Email'
                  name='email'
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type='email'
                  value={formik.values.email}
                  variant='standard'
                  disabled
                />
              </InputWrap>
              <InputWrap>
                <MuiTextField
                  error={Boolean(formik.touched.phoneNumber && formik.errors.phoneNumber)}
                  fullWidth
                  helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                  label='Phone Number'
                  name='phoneNumber'
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type='tel'
                  value={formik.values.phoneNumber}
                  variant='standard'
                />
              </InputWrap>
              <LoginButton
                color='primary'
                variant='contained'
                type='submit'
                onClick={formik.handleSubmit}
                disabled={loader || false}
              >
                Submit
              </LoginButton>

            </form>
          }

          {showMessage &&
            <>
              <Title variant='body1'>Thanks for reaching out. We look forward to speaking with you soon.</Title>
              <LoginButton color='primary' variant='contained' type='button' onClick={handleOnCanWeHelp}>OK, got it!</LoginButton>
            </>
          }
        </Wrapper>
      </Modal>}
    </>
  )
}

export default CanWeHelp
