import { Box } from '@mui/material'
import React, { useState } from 'react'
import { iconDeleteBlue } from '../../../../assets/images/icons'
import { MuiButtonWithIcon } from '../../../../components'
import AddImageVideo from './addImageVideo'
import { ActionWrap } from './style'

const Action = ({insightsElementId, disabled, onSubmit, addRemoveInsightsAssistant}) => {
  const [isModalActive, setModalActive] = useState(false);
 
  return (
    <ActionWrap disabled={disabled}>
      <MuiButtonWithIcon onClick={() => setModalActive(true)} variant='outlined' color='primary'>+ Add Verbatim</MuiButtonWithIcon>
      <Box sx={{ opacity: disabled ? 0.5 : 1, display: 'flex', gap: "10px"}}>
        <MuiButtonWithIcon onClick={() => addRemoveInsightsAssistant(true)} variant='outlined' color='primary'>+ Add to Insights Assistant</MuiButtonWithIcon>
        <MuiButtonWithIcon onClick={() => addRemoveInsightsAssistant(false)} variant='outlined' icon={iconDeleteBlue} color='primary'>Remove from Insights Assistant</MuiButtonWithIcon>
      </Box>
      {
       isModalActive &&
        <AddImageVideo onSubmit={onSubmit} insightsElementId={insightsElementId} onClose={() => setModalActive(false)} />
      }
    </ActionWrap>
  )
}

export default Action