import React, { useState, useEffect } from 'react'
import { Legend, ReportHeading, ReportOuterWrapper } from '../../../../components'
import ReportsService from '../../../../services/reports.service'
import { D3BarVerticalStacked } from '../../../../components'
import { LEGEND_COLOR_MAP } from '../../../../constants'
import { useUpdateLhs } from '../../../../hooks/useUpdateLhs'
import { setCurrency, exportExcel_addPercentage } from '../../../../util'
import {
  OuterWrapper,
  ChartBarWrapper,
  ChartsWrapper,
  ChartBarHead,
  ChartBarBody,
  ChartContent,
  ChartBarTitle,
  LegendWrapper,
  ChartBarTitleWrapper,
  ChartBarCount
} from './style'

const ReportBuzzMetrics = props => {
  // const { enqueueSnackbar } = useSnackbar()
  const [backgroundTabsLoading, setBackgroundTabsLoading] = useState(true)
  const [rows, setRows] = useState([])
  const [pageFlag, setPageFlag] = useState()
  const [reportExcelData, setReportExcelData] = useState()
  const {updateSubLhsApiLoaded} = useUpdateLhs()
  
  const loadBrandImagery = () => {
    setPageFlag(true)
    ReportsService.reportBuzzMetricsApi({
      insightsElementId: props.InsightsElementId
    })
    .then(resp => {
      const rowArray = resp?.map((r) => {
        const totalInter = setCurrency(r?.EarnedInteractions + r?.OrganicInteractions + r?.PaidInteractions)
        const totalViews = setCurrency(r?.EarnedVideoViews + r?.OrganicVideoViews + r?.PaidVideoViews)
        return {
          bar1: {
            TotalInteractions: totalInter,
            head: [
              { title: 'Interactions', count: totalInter }
            ],
            legend: [
              [
                (r?.PaidInteractions && { label: 'Paid', value: 'Negative' }),
                (r?.EarnedInteractions && { label: 'Earned', value: 'Interactions' }),
                (r?.OrganicInteractions && { label: 'Organic', value: 'Neutral' })
              ]
            ],
            colors: {
              OrganicInteractions: LEGEND_COLOR_MAP.Neutral,
              EarnedInteractions: LEGEND_COLOR_MAP.Interactions,
              PaidInteractions: LEGEND_COLOR_MAP.Negative
            },
            data: [
              {
                key: 'Interactions',
                OrganicInteractions: r?.OrganicInteractions,
                EarnedInteractions: r?.EarnedInteractions,
                PaidInteractions: r?.PaidInteractions
              }
            ],
            noText: true,
            keys: [
              'OrganicInteractions',
              'EarnedInteractions',
              'PaidInteractions'
            ]
          },
          bar2: {
            head: [
              { title: 'Total Posts', count: setCurrency(r?.Posts) },
              { title: 'Unique Users', count: setCurrency(r?.Users) }
            ],
            legend: [
              [
                (r?.Posts && { label: 'Total Posts', value: 'Interactions' }),
                (r?.Users && { label: 'Unique Users', value: 'Warning' })
              ]
            ],
            colors: {
              Posts: LEGEND_COLOR_MAP.Interactions,
              Users: LEGEND_COLOR_MAP.Warning
            },
            data: [
              {
                key: 'posts',
                Posts: r?.Posts,
                Users: 0
              },
              {
                key: 'users',
                Posts: 0,
                Users: r?.Users
              }
            ],
            noText: true,
            keys: [
              'Posts',
              'Users'
            ]
          },
          bar3: {
            head: [
              { title: 'video views', count: setCurrency(totalViews) },
              { title: 'video count', count: setCurrency(r?.VideoCount) }
            ],
            legend: [
              [
                (r?.PaidVideoViews && { label: 'Paid', value: 'Negative' }),
                (r?.EarnedVideoViews && { label: 'Earned', value: 'Interactions' }),
                (r?.OrganicVideoViews && { label: 'Organic', value: 'Neutral' })
              ],
              [
                (r?.VideoCount && { label: 'Count', value: 'Positive' })
              ]
            ],
            colors: {
              OrganicVideoViews: LEGEND_COLOR_MAP.Neutral,
              EarnedVideoViews: LEGEND_COLOR_MAP.Interactions,
              PaidVideoViews: LEGEND_COLOR_MAP.Negative,
              VideoCount: LEGEND_COLOR_MAP.Positive
            },
            data: [
              {
                key: 'posts',
                OrganicVideoViews: r?.OrganicVideoViews,
                EarnedVideoViews: r?.EarnedVideoViews,
                PaidVideoViews: r?.PaidVideoViews,
                VideoCount: 0
              },
              {
                key: 'views',
                OrganicVideoViews: 0,
                EarnedVideoViews: 0,
                PaidVideoViews: 0,
                VideoCount: r?.VideoCount
              }
            ],
            noText: true,
            keys: [
              'OrganicVideoViews',
              'EarnedVideoViews',
              'PaidVideoViews',
              'VideoCount'
            ]
          }
        }
      })
      setRows(rowArray)
      setBackgroundTabsLoading(false)
      updateSubLhsApiLoaded(props)

      const getHeadData = (head) => {
        const headData = []
        for(let i=0; i<head.length; i++){
          headData.push([{value: head[i].title}, {value: (head[i].count).replaceAll(',','')*1}])
          // headData.push([ {value: head[i].title}, exportExcel_addPercentage(head[i].count) ])
        }
        return headData
      }
      const reportExcelObj = [
        ...getHeadData(rowArray[0].bar1.head),
        ...getHeadData(rowArray[0].bar2.head),
        ...getHeadData(rowArray[0].bar3.head)
      ]
      // setReportExcelData( reportExcelObj )
      setReportExcelData({heading:['Buzz Metrics', ' '], values: reportExcelObj })

    })
    .catch(error => {
      const resMessage = error
      console.log('ERROR : ', resMessage)
      setBackgroundTabsLoading(false)
    })
  }

  useEffect(() => {
    if (!pageFlag) {
      loadBrandImagery()
    }
  }, [])

  return (
    <>
      <ReportHeading downloadId={props.type} subHeading='' {...props} downloadIdData={reportExcelData} />
      <ReportOuterWrapper>
        <OuterWrapper id={props.type}>
          <ChartsWrapper>
            {!!rows.length && <ChartBarVertical sx={{ width: '25%' }} barWidth={30} {...rows[0].bar1} />}
            {!!rows.length && <ChartBarVertical sx={{ width: '25%' }} barWidth={30} {...rows[0].bar2} />}
            {!!rows.length && <ChartBarVertical sx={{ width: '45%' }} barWidth={30} {...rows[0].bar3} />}
          </ChartsWrapper>

          <ChartContent>
            <div>
              <p>
                <span>
                  Interactions:
                </span>
                This is any touch point with the brand. Interaction can be in form of post views, video views, shares, likes, clicks and conversations. Interactions are classified as
              </p>
              <ul>
                <li>
                  <span>
                    Paid:
                  </span>
                  TV/Print ads, PR initiatives, videos, events, etc. paid or sponsored by the brand
                </li>
                <li>
                  <span>
                    Earned:
                  </span>
                  Articles, expert reviews, etc. non-sponsored and non-paid by the brand. This includes consumer comments on paid and earned content and activities
                </li>
                <li>
                  <span>
                    Organic:
                  </span>
                  General consumer engagement with the brand including social media conversations
                </li>
              </ul>
              <p>
                <span>
                  Engagement:
                </span>
                A measure by number of consumer posts. This is the total buzz. It includes total conversations from comments, tweets, forums, Facebook, YouTube., etc.
              </p>
            </div>
          </ChartContent>
        </OuterWrapper>
      </ReportOuterWrapper>
    </>
  )
}

// const ChartBarVertical = (props) => {
const ChartBarVertical = (props) => {
  return (
    <ChartBarWrapper sx={props?.sx}>
      <ChartBarHead>
        <ChartBarTitleWrapper>
          {props?.head && props?.head.map((b, i) => {
            return (
              <div key={i}>
                <ChartBarTitle>
                  {b?.title}
                </ChartBarTitle>
                <ChartBarCount>
                  {b?.count}
                </ChartBarCount>
              </div>
            )
          })}
        </ChartBarTitleWrapper>
        <LegendWrapper>
          {props?.legend && props?.legend.map((leg, i) => {
            return (
              <Legend legends={leg} key={i} />
            )
          })}
        </LegendWrapper>
      </ChartBarHead>
      <ChartBarBody sx={{ justifyContent: props?.legend.length > 1 ? 'space-around' : 'center' }}>
        {props?.legend.length > 1
          ? props.data.map((D, i) => {
              return (
                <D3BarVerticalStacked key={i} barWidth={props?.barWidth} colors={props?.colors} data={[D]} keys={props?.keys} noText={props?.noText} />
              )
            })
          : <D3BarVerticalStacked barWidth={props?.barWidth} colors={props?.colors} data={props?.data} keys={props?.keys} noText={props?.noText} />}
        {/* <img src={props?.src} /> */}
      </ChartBarBody>
    </ChartBarWrapper>
  )
}

export default ReportBuzzMetrics
