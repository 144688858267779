import { styled } from '@mui/material/styles'
import { Button, Box } from '@mui/material'
import { MuiButton } from '../../components'

export const ButtonWrapper = styled(Box)(`
  margin-bottom:15px;
  margin-top:30px;
  display:flex;
  justify-content:space-between;
  & > * {
    width: 47%;
    display:flex;
    justify-content:center;
    align-items:center;
  }
  & button {
    height:40px;
    padding-left:20px;
    padding-right:20px;
  }
  & .resetButton {
    background: #545454;
  }
  & .backToLogin{
    font-weight: 300;
  }
`)

export const ButtonWrap = styled(Box)(`
  & .resetButton {
    background: #545454;
    width: 200px
  }
`)

export const Heeding = styled(Box)(`
  & p {
    font-weight:300;
    font-size:16px;
  }
`)

export const ResetPasswordStep1Wrapper = styled(Box)(`
  & .TextFieldWrapper input {
    font-size:16px;
  }
`)

export const MuiTextFieldWrapper = styled(Box)(`
  display:flex;
  justify-content: space-between;
  padding:10px 15px 10px 0;
  & > div {
    margin:0 5px;
    height:90px;
  }
  & input {
    height:75px;
    font-style: normal;
    font-weight: 500;
    font-size: 30px !important;
    line-height: 45px;
    color: #242424;
    text-align:center;
  }

  & input::-webkit-outer-spin-button,
  & input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  & input[type=number] {
    -moz-appearance: textfield;
  }

  & .error {
    display:none;
  }

`)

export const SuccessMessageBox = styled(Box)(`
  & p {
    padding-bottom:25px;
  }
`)

export const FormError = styled(Box)(`
  width:100%;
  display:block;
  line-height: 20px;
  font-size: 0.75rem;
  font-weight: 400;
  margin: 4px 14px 0 14px;
  color: #D14343;
  background-color: transparent !important;
`)

export const WrapperResetPassStep3 = styled(Box)(`
  width:100%;
  max-width:370px;
  min-width:370px;
  & .MuiFormControl-root {
    height: auto;
    margin-bottom: 20px;
    & input {
      font-size: 16px;
    }
  }
  & .MuiFormLabel-root.MuiInputLabel-root {
    font-weight: 300 !important;
  }
`)

export const InputWrap = styled(Box)(`
  height: 80px;
`);


export const OtpWrapper = styled(Box)(`
  margin-top: 20px;
  & .otpInput  {
    & input::-webkit-outer-spin-button,
    & input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    & input[type=number] {
      -moz-appearance: textfield;
    }
    & input {
      border: 0;
      width: 50px !important;
      height: 50px !important;
      max-width: 50px;
      border-bottom: 2px solid #C3C3C3;
      margin-right: 20px;
      font-size: 30px;
      font-weight: 500;
      color: #000000;
      outline: none;
      text-align: center;
      &:not([value=""]) {
        border-color: #000000;
      }
      &::focus {
        outline: none;
      }
    }
  }
  & +.codeError{
    margin-left: 0 !important;
  }
`);


