import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { MuiButton, MuiPagination, NoDataFound } from '../../../../components'
import VerbatimFilter from './verbatimFilter'
import Search from './search'
import Action from './action'
import DataTable from './dataTable'
import reportsService from '../../../../services/reports.service'
import SuccessPopup from './successPopup'
import PostLoginReportsEditModal from '../../../../layout/postLoginReportsEditModal/postLoginReportsEditModal'
import { VerbatimFilterOuterWrapper, VerbatimFilterInnerWrapper } from './style'
import { Box } from '@mui/material'

const SearchBox = ({ searchText, setSearchText, isCollapsed, setCollapsed }) => {
  return (
    <Box sx={{
      display: "flex",
      gap: "10px"
    }}>
      <MuiButton onClick={() => setCollapsed(prev => !prev)} sx={{ textDecoration: "underline" }}>{`${isCollapsed ? "Show" : "Hide"} Advance Search`}</MuiButton>
      <Search value={searchText} onChange={(e) => setSearchText(e.target.value)} />
    </Box>
  )
}


const PER_PAGE = 60;

const ReportVerbatim = (props) => {
  const [isLoading, setLoading] = useState(false)
  const [isModalOpend, setModalOpened] = useState(false)
  const [isCollapsed, setCollapsed] = useState(false)
  const [searchText, setSearchText] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [selected, setSelected] = useState([])
  const [isSuccessModalActive, setSuccessModalActive] = useState(false)
  const [state, setState] = useState([])
  const [searchedData, setSearchedData] = useState([])
  const [totalPage, setTotalPage] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [scrollVal, setScrollVal] = useState('')
  const [filters, setFilters] = useState()
  const [messageType, setMessageType] = useState('success')

  const filterChangeHandler = (filterObj) => {
    setFilters(filterObj);
    setSearchText("");
  }

  const fetchVerbatimForManagement = useCallback((payload, scrVal='') => {
    if(scrVal !== 'id_undefined') autoSectionScroll(scrVal)
    setLoading(true)
    reportsService.getVerbatimForManagement(payload).then(resp => {
      setState(resp || [])
      setTotalPage(resp?.[0]?.TotalVerbatimCount || 0);
    }).catch(error => {
      console.log(error)
    }).finally(() => {
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    if (props?.InsightsElementId && isModalOpend) {
      setState([]);
      const obj = {
        insightsElementId: props.InsightsElementId,
        pageNumber: 1,
        ...filters
      }
      fetchVerbatimForManagement(obj)
      setCurrentPage(1);
    }
  }, [filters, isModalOpend, fetchVerbatimForManagement, props?.InsightsElementId])

  const setVerbatimInInsightsAssistant = (isAdd = false) => {
    if (!selected.length) return;

    const obj = {
      verbatimIds: selected.map(item => ({ int: item })),
      addToInsightsAssistant: isAdd
    }
    reportsService.setVerbatimInInsightsAssistant(obj).then(() => {
      const message = `Selected verbatim were marked to be ${isAdd ? "added to" : "removed from"} the Insights Assistant`;
      setSuccessMessage(message);
      setSuccessModalActive(true);
      setSelected([]);
    }).catch(error => {
      console.log(error);
    })
  }

  const addUpdateVerbitimHandler = (obj, isAdd = false, cb) => {
    setScrollVal(`id_${obj.verbatimId}`)
    reportsService.setVerbatimInManagement(obj).then((resp) => {
      cb && cb()
      setSuccessMessage(`Verbatim ${isAdd ? 'added' : 'updated'} in the Insights Assistant`);
      setMessageType('success')
    }).catch(error => {
      cb && cb(error?.message);
      setSuccessMessage(`Verbatim ${isAdd ? 'not added' : 'did not update'} in the Insights Assistant`);
      setMessageType('failed')
    }).finally(() => {
      setSuccessModalActive(true)
    })
  }

  const fetchVerbatimByText = useCallback((payload) => {
    setLoading(true)
    setSearchedData([])
    reportsService.getVerbatimByTextSearch(payload).then(resp => {
      setSearchedData(resp || [])
      setTotalPage(resp?.[0]?.TotalVerbatimCount || 0);
      setLoading(false)
    }).catch(error => {
      console.log(error)
      setLoading(false)
    })
  }, [])


  useEffect(() => {
    let ref;
    setLoading(true)
    setSearchedData([])

    if (searchText && isModalOpend) {
      ref = setTimeout(() => {
        const payload = {
          insightsElementId: props?.InsightsElementId,
          verbatimText: searchText,
          pageNumber: 1,
        }
        fetchVerbatimByText(payload)
      }, 1000);
    } else if (searchText === '' && isModalOpend) {
      // console.log('WIP')
      // filterChangeHandler(filters)
    }

    return () => {
      setLoading(false)
      clearTimeout(ref)
    };
  }, [searchText, isModalOpend])

  const modalOpenedHandler = () => {
    setModalOpened(true);
  }

  const closeOkButton = () => {
    setState([])
    const filterObj = {
      insightsElementId: props.InsightsElementId,
      pageNumber: currentPage,
      ...filters
    }
    fetchVerbatimForManagement(filterObj, scrollVal)
    setSuccessModalActive(false)
  }

  const onPageChange = (pageNumber) => {
    setState([])
    setSearchedData([])
    const filterObj = {
      insightsElementId: props.InsightsElementId,
      pageNumber: pageNumber,
      ...filters
    }
    setCurrentPage(pageNumber);
    if (searchText) {
      const payload = {
        insightsElementId: props?.InsightsElementId,
        verbatimText: encodeURIComponent(searchText), // Hindi Text fixing
        pageNumber: pageNumber
      }
      fetchVerbatimByText(payload);
      return;
    }
    fetchVerbatimForManagement(filterObj)
  }

  const autoSectionScroll = (scrVal) => {
    if(scrVal?.length > 0){
      document.getElementById(scrVal).scrollIntoView({ block: "center" })
      setTimeout(() => {
        document.getElementById(scrVal).scrollIntoView({ block: "center" })
      }, 1500)
    }
  }

  const data = useMemo(() => searchText ? searchedData : state, [state, searchText, searchedData]);

  return (
    <PostLoginReportsEditModal spaceBottom={90} modalOpened={modalOpenedHandler} modalTitle='Verbatim Management' buttonName='Verbatim Management' additionalButtons={<SearchBox searchText={searchText} setSearchText={setSearchText} isCollapsed={isCollapsed} setCollapsed={setCollapsed} />}>
      <VerbatimFilterOuterWrapper id='verbatimWrapper' className={`gridFormatting isCollapsed_${isCollapsed}`}>
        <VerbatimFilterInnerWrapper>
          <VerbatimFilter onChange={filterChangeHandler} insightsElementId={props.InsightsElementId} />
          {/* {
            useMemo(() => isCollapsed ? null : <VerbatimFilter onChange={filterChangeHandler} insightsElementId={props.InsightsElementId} />, [isCollapsed])
          } */}
          <Action addRemoveInsightsAssistant={setVerbatimInInsightsAssistant} onSubmit={addUpdateVerbitimHandler} disabled={!selected?.length} insightsElementId={props.InsightsElementId} />
        </VerbatimFilterInnerWrapper>
        
        {data?.length > 0 && <DataTable isCollapsed={isCollapsed} isLoading={isLoading} insightsElementId={props.InsightsElementId} selected={selected} select={setSelected} update={addUpdateVerbitimHandler} data={data} />}
        {
          data?.length === 0 && !isLoading &&
          <NoDataFound title="No data found" />
        }
        {
          isSuccessModalActive &&
          <SuccessPopup message={successMessage} onClose={closeOkButton} color={messageType==='success' ? 'green' : 'red'} />
        }
      </VerbatimFilterOuterWrapper>
      {data?.length > 0 && !isLoading && currentPage && totalPage && (totalPage >= PER_PAGE) &&
      <MuiPagination onPageChange={onPageChange} totalPage={Math.ceil(totalPage / PER_PAGE)} currentPage={currentPage} />}
    </PostLoginReportsEditModal>
  )
}

export default ReportVerbatim

