import React from 'react';
import queryString from 'query-string'
import PostLogin from '../../layout/postLogin';
import { Box } from '@mui/material'
import { CanWeHelp, Header, MuiButton } from '../../components';
import { NAME_INITIATE_PROJECTS, ROUTE_INITIATE_PROJECTS_DRAFTS } from '../../constants';

import {
  Wrapper,
  LhsWrapper,
  ContentWrapper,
  OuterWrapper
} from './style';
import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import AppNavLink from '../../components/AppNavLink';
import CategoryExploration from './sections/CategoryExploration';
import Home from './sections/Home';
import ProductRating from './sections/ProductRating';
import CampaignIssuesAssessment from './sections/CampaignIssuesAssessment';
import BrandHealth from './sections/BrandHealth';
import Draft from './sections/Draft';
import { draft } from '../../assets/images/icons';
import TrendScoping from './sections/TrendScoping';

const InitiateProjects = () => {
  let { path, url } = useRouteMatch();
  const { pathname, search } =  useLocation();
  const { projectId } = queryString.parse(search)

  const paths = [
    {
      path: 'category-exploration',
      title: 'Category Exploration',
    },
    {
      path: 'brand-health',
      title: 'Brand Health',
    },
    {
      path: 'product-rating',
      title: 'Product Rating Optimization',
    },
    {
      path: 'campaign-issues-assessment',
      title: ' Campaign and Issues Assessment',
    },
    {
      path: 'trend-scoping',
      title: 'Trend Scoping ',
    },
  ];

  return (
    <PostLogin>
      <Wrapper>
        <Header title={NAME_INITIATE_PROJECTS}>
          <Box id='BtnDraft'><MuiButton color='light' variant='contained' to={ROUTE_INITIATE_PROJECTS_DRAFTS}><img src={draft} width='24px' alt='' /> &nbsp; Draft</MuiButton></Box>
          <CanWeHelp />
        </Header>
        {
          pathname.includes('initiate-projects/draft') ? 
          <Route exact path={`${path}/draft`}>
            <Draft />
          </Route> :
          <OuterWrapper>
          <LhsWrapper className='LhsInitiateNewProject'>
            {
              paths.map((pathItem) => (
                <AppNavLink key={pathItem.path} to={`${url}/${pathItem.path}`}>
                  {pathItem.title}
                </AppNavLink>
              ))
            }
          </LhsWrapper>
          <ContentWrapper>
            <Switch>
              <Route exact path={path}>
                <Home />
              </Route>
              <Route exact path={`${path}/category-exploration`}>
                <CategoryExploration projectId={projectId} />
              </Route>
              <Route exact path={`${path}/brand-health`}>
                <BrandHealth projectId={projectId} />
              </Route>
              <Route exact path={`${path}/product-rating`}>
                <ProductRating projectId={projectId} />
              </Route>
              <Route exact path={`${path}/campaign-issues-assessment`}>
                <CampaignIssuesAssessment projectId={projectId} />
              </Route>
              <Route exact path={`${path}/trend-scoping`}>
                <TrendScoping projectId={projectId} />
              </Route>
            </Switch>
          </ContentWrapper>
        </OuterWrapper>
        }
      </Wrapper>
    </PostLogin>
  );
};

export default InitiateProjects;
