import React, { useState } from 'react'
import { Chip, Checkbox, ListItemText, OutlinedInput, IconButton } from '@mui/material'
import { FormLabel, FormSelectWrap, SelectWrapper, FormError, InputLabel, StyledMenuItem, StyledSelect} from './style'
import { arrowDownIos, checkboxChecked, checkboxUnchecked } from '../../assets/images/icons'
import MuiImage from '../muiImage'

const MuiSelect = (props) => {
  return (
    <>
      {props?.title && <FormLabel className='formLabel'>{props.title}</FormLabel>}
      <FormSelectWrap className='selectWrap'>
        {props?.data &&
          <>
            {props?.type !== 'MULTIPLE_SELECT' && <SingleSelect {...props} />}
            {props?.type === 'MULTIPLE_SELECT' && <MultiSelect {...props} />}
          </>}
      </FormSelectWrap>
      {props?.error && <FormError className='selectError'>{props?.error}</FormError>}
    </>
  )
}

export default MuiSelect

const SingleSelect = (props) => {
  const {
    data,
    name,
    onChange,
    selectlabel="label",
    selectvalue="value",
    size,
    placeholder,
    compact=false,
    menuprops={}
  } = props

  const [selectedValue, setSelectedValue] = useState()

  const handleChange = (event) => {
    setSelectedValue(event.target.value)
    const selectedData = { value: event.target.value, label: event.target.name }
    onChange(event, selectedData)
  }

  return (
    <>
      {!selectedValue && placeholder && !props?.value && <InputLabel className='selectPlaceholder'>{placeholder}</InputLabel>}
      <SelectWrapper>
        <StyledSelect menuprops={menuprops} data-placeholder={selectedValue || props.value ? '' : placeholder} {...props} value={props.value || ""} compact={ compact ? "true": ""} name={name} onChange={handleChange} size={size} IconComponent={(p) => <MuiImage src={arrowDownIos} {...p}/>}>
          {data.map((s, i) =>
            <StyledMenuItem single="true" compact={ compact ? "true": ""} key={i} value={s[selectvalue]}>
              {s[selectlabel] || ''}
            </StyledMenuItem>)}
        </StyledSelect>
      </SelectWrapper>
    </>
  )
}

const MultiSelect = (props) => {
  const {
    data,
    name,
    onChange,
    selectlabel,
    selectvalue,
    size,
    placeholder,
    compact=false,
    isCommaSeparatedValue=false,
  } = props

  const ITEM_HEIGHT = 48
  const ITEM_PADDING_TOP = 8

  const menuprops = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    }
  }

  const [selectedValue, setSelectedValue] = useState()
  const [selectedOptionValue, setSelectedOptionValue] = useState([])
  const [selectedOptionObject, setSelectedOptionObject] = useState([])

  const handleChange = (event) => {
    const { target: { value } } = event
    setSelectedOptionValue(typeof value === 'string' ? value.split(',') : value)
    setSelectedValue(event.target.value)

    const selectedData = data.filter(d => (value.indexOf(_value(d)) !== -1))
    setSelectedOptionObject(selectedData)

    onChange(event, selectedData)
  }

  const _value = (option) => {
    return option?.[selectvalue] || option?.value
  }
  const _label = (option) => {
    return option?.[selectlabel] || option?.label
  }

  const selectedChip = (selected) => {

    return (
      <>
        {selectedOptionObject.map((sData, iIndex) => {
          return isCommaSeparatedValue ? <span>{`${_label(sData)}${iIndex !== (selectedOptionObject.length -1) ? ', ' : ''}`}</span> : <Chip sx={{ height: '24px', mr: 1 }} key={_value(sData)} label={_label(sData)} />
        })}
      </>
    )
  }

  return (
    <>
      {!selectedValue && placeholder && <InputLabel>{placeholder}</InputLabel>}
      <SelectWrapper>
        <StyledSelect
          multiple
          value={selectedOptionValue}
          name={name}
          className="multiSelect"
          onChange={handleChange}
          input={ <OutlinedInput label='Tag' />}
          renderValue={selectedChip}
          menuprops={menuprops}
          label=''
          compact={compact}
          size={size}
        >
          {data.map((option, index) => (
            <StyledMenuItem compact={compact} key={index} value={_value(option)}>
              <ListItemText primary={_label(option)} />
              <Checkbox 
                checked={selectedOptionValue.indexOf(_value(option)) > -1} 
                {...(
                  compact ? {
                    icon: <MuiImage src={checkboxUnchecked} width="14px"/>,
                    checkedIcon: <MuiImage src={checkboxChecked} width="14px"/>
                  } : {}
                )}
              />
            </StyledMenuItem>
          ))}
        </StyledSelect>
      </SelectWrapper>
    </>
  )
}
