import { styled } from '@mui/system'
import { Grid, Box, Typography } from '@mui/material'
import { MEDIA } from '../../constants'

export const Wrapper = styled(Grid)(`
    display:flex;
`)

export const ListLink = styled('a')(`
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    padding: 10px 0;
`)

export const Heading = styled(Typography)(`
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    position: relative;
`)

export const LhsMenuWrapper = styled(Box)(`
    padding:15px 20px;
    background: #F7F7F9;
    box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.08);
    min-width:240px;
    width:240px;
    display:flex;
    flex-direction:column;
    & li {
        padding:0;
    }
`)

export const ContentOuterWrapper = styled(Box)(`
    width:calc(100vw - 350px);
    height:calc(100vh - 200px);
    display:flex;
    flex-direction:column;
    overflow-y:auto;
`)

export const ContentInnerWrapper = styled(Box)(`
    width:100%;
    display:flex;
    position:relative;
    padding:30px 20px 0 20px;

    &.lastPageSection {
        padding-bottom: 20px;
    }

    &.isPPTModalActive, &.isPPTModalActive *{
        font-family:Verdana !important;
    }

    &.isPPTModalActive .loadingWrapper {
        display:none !important;
        position:fixed;
        top:100px;
        left:calc(100vw - 600px);
        z-index:2000;
        width:280px;
        height:50px;
        padding-left:30px !important;
        padding-top:5px !important;
        background:#fff;
        display:flex;
        justify-content:flex-start;
        &:after {
            content:'Preparing Report';
            height:40px;
            padding: 5px 0 0 10px !important;
            display:block;
            text-align:left;
            font-size:13px;
        }
        & img{
            width:24px !important;
            height:24px !important;
        }
    }

    &.onlyOneComponent {
        // min-height: calc(100vh - 190px); // THIS IS CREATING PROBLEM
        align-items: baseline;
    }

    &.askIaDetailPage {
        padding-top: 45px;
    }

    & .dynamicDriverWrapper > .DriversTr {border-bottom: 1px solid rgb(247, 247, 247); display: flex; width:100%; }
    & .dynamicDriverWrapper > .DriversTr * {box-shadow:none; }
    & .dynamicDriverWrapper > .DriversTr td {padding-top:2px; padding-bottom:2px; }
    & .dynamicDriverWrapper > .DriversTr > .DriversTr {padding:0; width:100%; }
    & .dynamicDriverWrapper .DriversTr button{display:none !important; }


    @media (max-width: ${MEDIA.lg}) {
        &:after{
            width:calc(100% - 40px);
            left:20px;
        }
    }

`)

export const ContentWrapper = styled(Box)( props => `
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    min-width: 850px;
    // overflow: auto; // Not required
    // & .reportHeading:first-of-type {
    //     padding-top: 20px;
    // }

    // // & > .reportHeading { order: 1; }
    // // & > .insightsOuterWrapper { order: 3; }
    // // & > .reportOuterWrapper { order: 2; width:calc(100% - 300px); }

    ${!props?.isPPTModalActive && `
        &.moveInsightsToRight > .reportHeading { order: 1; }
        &.moveInsightsToRight > .insightsOuterWrapper { order: 3; }
        &.moveInsightsToRight > .reportOuterWrapper { order: 2; }

        &.moveInsightsToRight > .reportHeading { }
        &.moveInsightsToRight > .insightsOuterWrapper { max-width: 1px;}
        // &.moveInsightsToRight > .insightsOuterWrapper .InsightsInnerWrapper{ display:none; border:1px red solid;}
        &.moveInsightsToRight > .insightsOuterWrapper .btnShow{ display:block; transform:rotate(0deg); margin-right:-8px; margin-top:-3px;}
        &.moveInsightsToRight.hideRhsInsights > .reportOuterWrapper { max-width:calc(100% - 20px); }
        
        &.moveInsightsToRight.hideRhsInsights .btnHide { display:none; }
        &.moveInsightsToRight.showRhsInsights .btnHide { display:block; }

        &.moveInsightsToRight.showRhsInsights > .reportHeading { }
        // &.moveInsightsToRight.showRhsInsights > .InsightsInnerWrapper { }
        &.moveInsightsToRight.showRhsInsights > .insightsOuterWrapper { max-width: 300px;}
        &.moveInsightsToRight.showRhsInsights > .insightsOuterWrapper .InsightsInnerWrapper{ display:flex; border-left: 1px #EEE solid;}
        &.moveInsightsToRight.showRhsInsights > .insightsOuterWrapper .btnShow{ display:none;}
        &.moveInsightsToRight.showRhsInsights > .reportOuterWrapper { max-width:calc(100% - 300px); }


        
        // &.moveInsightsToRight .reportHeading { border: 1px red solid; }
        // &.moveInsightsToRight .insightsOuterWrapper { border: 1px blue solid; }
        // &.moveInsightsToRight .reportOuterWrapper { border: 1px green solid; }

        &.moveInsightsToRight .ready {
            // outline: 1px #f1f1f1 solid;
        }
        &.moveInsightsToRight .stickySidebarDefault {
        }
        &.moveInsightsToRight .stickySidebarStatic {
            right: 0;
            position: relative;
            z-index: 2;
            // outline: 1px blue solid;
        }
        &.moveInsightsToRight .stickySidebar {
            height: calc(100vh - 59px);
            right: 0;
            position: relative;
            z-index: 2;
            // outline: 1px red solid;
        }
        &.moveInsightsToRight .stickySidebar.fixed {
            position: fixed;
            top: 59px;
            right: 37px;
        }
        &.moveInsightsToRight .stickySidebar.bottom {
            bottom: 0;
            right: 20px;
            position: absolute;
        }
    `}
    @media (max-width: 980px) {
        min-width: 100%;
    }
`)

export const LhsWrapper = styled(Box)(`
    width: calc(100% - 420px);
    padding:20px 30px;
`)

export const RhsWrapper = styled(Box)(`
    padding:20px 30px;
    min-width:420px;
    width:420px;
    display: flex;
    flex-direction:column;
`)

export const CardWrapper = styled(Box)(`
    display:flex;
    flex-wrap:wrap;
    justify-content:space-between;
    & > div:nth-of-type(1) {width:calc(50% - 15px)}
    & > div:nth-of-type(2) {width:calc(50% - 15px)}
`)

export const PptLoadingStatus = styled(Box)(`
    position: fixed;
    bottom: 10px;
    left: 10px;
    z-index: 2000;
    background: #000;
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    display: none;
    max-height: 95vh;
    overflow: auto;
    font-size: 12px;
    & ol {
        margin-left: 13px;
        max-height: calc(90vh - 100px);
        overflow: auto;
        padding-right: 10px;
        list-style-position: inside;
        list-style-type: decimal;
    }
`)

// export const IconCloseWrapper = styled(Box)(`
//     position: absolute;
//     top: 0;
//     right: 0;
//     width: 25px;
//     height: 25px;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     background: #fff;
//     cursor: pointer;
// `)



