import React, { useState, useEffect } from 'react'
import { Box } from '@mui/material'
import {ReportHeading, ChartRadialBar, LoaderWrapper, ReportOuterWrapper } from '../../../../components'
import { useParams } from 'react-router-dom';

import ReportsService from '../../../../services/reports.service'
import { useUpdateLhs } from '../../../../hooks/useUpdateLhs'
import { exportExcel_addPercentage } from '../../../../util'

import { reportsTimePeriod, reportsBrands, reportsSampling, reportsTargetSegment, reportsSku, reportsGeographic, reportsCategory, reportsDataCleansing, reportsPostsAggregated} from '../../../../assets/images/icons'

import { ContentWrapper, LhsWrapper, RhsWrapper, CardsOuterWrapper, CardsInnerWrapper, CardWrapper, AvatarWrapper, ListItemTextWrapper, ChartRadialBarWrapper, ChartRadialBarHeading } from './style'

const ReportStudySetup1 = (props) => {

  // const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)
  const [backgroundStudySetupData, setBackgroundStudySetupData] = useState()
  const [subHeadingValue, setSubHeadingValue] = useState()
  const [cardData, setCardData] = useState([])
  const [reportExcelData, setReportExcelData] = useState()
  const { id } = useParams();
  const {updateSubLhsApiLoaded} = useUpdateLhs()

  const cards = [
    {
      key: 'geographic',
      icon: reportsGeographic,
      title: 'Geography',
      width: 'calc(50% - 15px)',
      show: false,
      description: ''
    },
    {
      key: 'timePeriod',
      icon: reportsTimePeriod,
      title: 'Time Period',
      width: 'calc(50% - 15px)',
      show: false,
      description: ''
    },
    {
      key: 'category',
      icon: reportsCategory,
      title: 'Category',
      width: 'calc(50% - 15px)',
      show: false,
      description: ''
    },
    {
      key: 'brands',
      icon: reportsBrands,
      title: 'Brands',
      width: 'calc(50% - 15px)',
      show: false,
      description: ''
    },
    {
      key: 'postsAggregated',
      icon: reportsPostsAggregated,
      title: 'Posts Aggregated',
      width: 'calc(50% - 15px)',
      show: false,
      description: ''
    },
    {
      key: 'sku',
      icon: reportsSku,
      title: `SKU's`,
      width: '100%',
      show: false,
      description: ''
    },
    {
      key: 'targetSegment',
      icon: reportsTargetSegment,
      title: 'Target Segment',
      width: '100%',
      show: false,
      description: ''
    },
    {
      key: 'sampling',
      icon: reportsSampling,
      title: 'Sampling',
      width: '100%',
      show: false,
      description: '100% of the reviews on the e-commerce platforms were considered for the research'
    },
    {
      key: 'dataCleansing',
      icon: reportsDataCleansing,
      title: 'Data Cleansing',
      width: '100%',
      show: false,
      description: [
        'Filtered out any irrelevant content such as spam, sponsored content, advertising, duplication, and bots',
        'Filtered out any content not contextual to the research objectives',
        'The sanitized data consisting of relevant, useful, and valuable conversations were analyzed in the study'
      ]
    }
  ]

  const typeObjects = {
    BrandHealthSetup : [ 'geographic', 'timePeriod', 'brands', 'postsAggregated', 'dataCleansing' ],
    CategoryExplorationSetup : [ 'geographic', 'timePeriod', 'category', 'postsAggregated', 'dataCleansing' ],
    ProductRatingOptimizationSetup : [ 'geographic', 'timePeriod', 'sku', 'targetSegment', 'sampling' ],
  }

  const loadStudySetup = () => {
    setLoading(true)
    ReportsService.reportStudySetupApi({insightsElementId:props.InsightsElementId, type:props.type})
    .then((resp) => {
      const isArray = (d) => {
        return Array.isArray(d) ? d.join('  | ') : d
      }
      const reportExcelObj = []

      const data = cards.map(card => {
        if(card.key === 'geographic') { card.description = (resp.Geography) }
        else if(card.key === 'timePeriod') { card.description = (resp.TimePeriod) }
        else if(card.key === 'brands') { card.description = isArray(resp.CategoryBrandsSKUs) }
        else if(card.key === 'category') { card.description = isArray(resp.CategoryBrandsSKUs) }
        else if(card.key === 'sku') { card.description = isArray(resp.CategoryBrandsSKUs) }
        else if(card.key === 'targetSegment') { card.description = (resp.TargetSegment) }
        else if(card.key === 'individualsPostsAnalyzed') { card.description = (resp.IndividualsPostsAnalyzed) }
        else if(card.key === 'postsAggregated') { card.description = (resp.PostsAggregated) }

        card.show = typeObjects[(props.type).split('_')[0]].includes(card.key)

        if(card.show){
          const desc = Array.isArray(card.description) ? card.description : [card.description]
          for(let a=0; a<desc.length; a++){
            reportExcelObj.push([{value: cards.find(f => f.key === card.key).title}, {value: desc[a]}])
          }
        }
        return card
      })
      
      const excelHeading2 = (props?.InsightsElementType === 'ProductRatingOptimizationSetup') ? 'Breakup of Reviews' : 'Distribution of Analysed Data'
      reportExcelObj.push([{value: ''}, {value: '' }])
      reportExcelObj.push([{value: excelHeading2}, {value: 'Percent' }])

      for(let j=0; j<resp.SourceSplit.length; j++) {
        reportExcelObj.push([{value: resp.SourceSplit[j].Platform}, exportExcel_addPercentage(resp.SourceSplit[j].Percent) ])
      }

      let subHeading = (props?.InsightsElementType === 'ProductRatingOptimizationSetup')
      ? `${resp?.IndividualsPostsAnalyzed} reviews were ANALYZED`
        : `Conversations among ${resp?.IndividualsPostsAnalyzed} consumers were analyzed for the study`
        setSubHeadingValue(subHeading)
      setBackgroundStudySetupData(resp)
      setCardData(data)
      setLoading(false)
      updateSubLhsApiLoaded(props)

      setReportExcelData({heading:[' ', ' '], values: reportExcelObj })

    })
    .catch((error) => {
      const resMessage = error
      console.log('ERROR : ', resMessage)
        // enqueueSnackbar(resMessage, { variant: 'error' })
      setLoading(false)
    })
  }

  useEffect(() => {
    if(cardData.length === 0) loadStudySetup()
  },[])

  return (
    <>
      <ReportHeading downloadId={props.type} {...props} downloadIdData={reportExcelData} />
        <ContentWrapper id={props.type}>
          <Box className="dynamicRow" sx={{display: 'flex'}}>
            <LhsWrapper>
              <LoaderWrapper loading={loading} loadOnlyApi={props.loadOnlyApi}>
                <CardsOuterWrapper className={props.type}>
                  {cardData.map((card, i) => {
                    const isLastElement = (i === cardData.length-1) ? 'isLast' : ''
                    if (card.show)
                    return (
                      <CardsInnerWrapper key={i} sx={{width:card.width, display:'flex'}} className={isLastElement}>
                        <CardContent className={card.key} icon={card.icon} title={card.title} description={card.description} type={card.key} />
                      </CardsInnerWrapper>
                    )
                  })}
                </CardsOuterWrapper>
                </LoaderWrapper>
              </LhsWrapper>
            <RhsWrapper>
              <LoaderWrapper loading={loading} loadOnlyApi={props.loadOnlyApi}>
                <ChartRadialBarWrapper>
                  {backgroundStudySetupData &&
                    <>
                      {props?.InsightsElementType === 'BrandHealthSetup' && <ChartRadialBarHeading>Distribution of Analysed Data</ChartRadialBarHeading>}
                      {props?.InsightsElementType === 'CategoryExplorationSetup' && <ChartRadialBarHeading>Distribution of Analysed Data</ChartRadialBarHeading>}
                      {props?.InsightsElementType === 'ProductRatingOptimizationSetup' && <ChartRadialBarHeading>Breakup of Reviews</ChartRadialBarHeading>}
                      <ChartRadialBar {...backgroundStudySetupData} />
                    </> 
                  }
                </ChartRadialBarWrapper>
              </LoaderWrapper>
            </RhsWrapper>
          </Box>
        </ContentWrapper>
    </>
  )
}

const CardContent = (props) => {
  const { icon, title, description, type } = props;

  const finalDescription = () => {
    // const desc = (type === 'sku') ? description.join(' | ') : description
    const desc = description
    return (Array.isArray(desc))
      ? <ul>{desc?.map((d, i) => { return (<li key={i}>{d}</li>)})}</ul>
      : desc+''
      // : <pre style={{whiteSpace:'pre-wrap'}}>{desc}</pre>
  }

  return (
    <CardWrapper>
      { icon && <AvatarWrapper alt={title} src={icon} sx={{height:'30px', width:'auto'}} />}
      <ListItemTextWrapper secondaryTypographyProps={{
        component: "div",
        className: "paragraph"
      }} primary={title} secondary={finalDescription()} />
    </CardWrapper>
  )
}

export default ReportStudySetup1
