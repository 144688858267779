import { styled } from '@mui/material/styles'
import { Box, Menu, Accordion, AccordionDetails, AccordionSummary, Button, Chip } from '@mui/material'
import { MuiButton } from '../../../components'
import { filter } from '../../../assets/images/icons'
import { MEDIA } from '../../../constants'

export const OuterWrapper = styled(Box)(`
  width:100%;
  padding-bottom:40px;
  @media (max-width: ${MEDIA.lg}) {
      padding-bottom:20px;
  }
`)

export const InnerWrapper = styled(Box)(`
  width:100%;
  display:flex;
  justify-content: space-between;
`)

export const SelectedFilters = styled(Box)(`
  width:calc(100% - 370px);
  display:flex;
  flex-wrap:wrap;
  align-items:center;
`)

export const ChipLabel = styled('span')(`
  padding-right:10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
`)

export const SelectedChip = styled(Box)(`
  cursor:pointer;
  display:flex;
  align-items:center;
  background-color:#E7F0FF;
  border-radius:2px;
  margin-left:10px;
  height: 20px;
  line-height: 20px;
  padding: 0 6px;
  border-radius: 2px;
  font-weight: 400;
  font-size: 10px;
  color: #242424;
  & img{ padding-left:5px;}
`)

export const ActionFilters = styled(Box)(`
  width:calc(370px - 30px);
  display:flex;
  justify-content:end;
`)

export const FilterButton = styled(MuiButton)(`
  height: 34px;
  background: #F1F6FF url(${filter}) no-repeat 15px center;
  border: 1px solid #F1F6FF;
  background-size: 18px;
  padding:0 15px 0 40px;
  color:#000;
  font-weight:300;
  &:hover{
    background-color: #F1F6FF;
  }
`)

export const MenuOuterWrapper = styled(Menu)(`
  & .MuiPaper-elevation {
    border-radius:0 !important;
  }
  & .MuiMenu-paper {
    margin-left:-20px !important;
    margin-top:5px !important;
  }
  & ul {
    padding: 12px 0 !important
    // padding:0 !important;
  }
`)

export const MenuInnerWrapper = styled(Box)(`
  width: 360px;
`)

export const AccordionWrapper = styled(Accordion)(`
  margin: 0 !important;
  box-shadow:none;
  &:before {display:none;}
`)

export const AccordionHeading = styled(AccordionSummary)(`
  padding: 0px 20px;
  & p {
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
  }
`)

export const AccordionContent = styled(AccordionDetails)(`
  display:flex;
  flex-direction:column;
  background:#F5F5F5;
  max-height:180px;
  overflow-y:auto;
  & span {
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
  }
`)

export const FilterButtonWrap = styled(Box)(`
  position:relative;
`)


