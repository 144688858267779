import React from 'react';
import { DrfatButton, Title, Wrapper } from './style';
import { Modal } from '../../../../components';
import {insightsAssistant} from '../../../../assets/images/icons'
import { useHistory } from 'react-router-dom';

const ProjectSuccessModal = ({ onClose }) => {
  const history = useHistory()
  const confirmHandler = () => {
    history.push("/projects")
    onClose();
  }
 
  return (
        <Modal title='' showClose={true} onClose={onClose}>
          <Wrapper>
          {/* <LogoDiv> */}
        <img src={insightsAssistant} height='65' width='76' />
      {/* </LogoDiv> */}
          <Title variant='body1'>The Project has been initiated successfully. 
          <br/>Your Insights Assistant is on it!</Title>
            <DrfatButton
              color='primary'
              variant='contained'
              onClick={confirmHandler}
            >
              OK, got it!
            </DrfatButton>
          </Wrapper>
        </Modal>
  );
};

export default ProjectSuccessModal;
