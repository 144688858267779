import styled from '@emotion/styled'
import { Box, Typography } from '@mui/material'
import { exportToPPT_tableCss } from '../../../../util'
import { MEDIA } from '../../../../constants'

export const RootWrap = styled(Box)( props => `
    display: flex;
    flex-direction: column;
    // padding: 20px 30px 50px 30px;
    width: 100%;
    min-height: 50vh;

    &.exportToPPT {padding:0; min-height: 650px;}
    &.exportToPPT .list p{ font-size:14px !important; line-height:15px !important;}
    &.exportToPPT .list p label{ width:100%;} // Mozilla FIX
    
    &.exportToPPT .pptListWrapper {
        padding: 0;
        padding-right:10px;
        & > div {
            padding: 0;
            margin: 0;
        }
        & .listHeading {
            padding: 5px 0;
        }
        & .list > div {
            margin: 0 5px;
            padding: 7.5px 5px;
        }
        & img {
            max-width:90%;
        }
    }
    ${ exportToPPT_tableCss }

    @media (max-width: ${MEDIA.lg}) {
        padding:20px 20px 40px 20px;
    }
`)

export const TableWrap = styled(Box)(`
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1.5px solid #E7E7E7;
    width: 100%;
    overflow-x:auto;
    max-width: calc(100vw - 255px);
`)

export const StyledCellWrap = styled(Box)(`
    min-width:100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    border-top: 0.7px solid #E7E7E7;
    border-bottom: 0.7px solid #E7E7E7;
    // border-top: 1.5px solid #E7E7E7;
    & img {
        max-height:18px;
        max-width:100%;
    }
`)

export const StyledTypography = styled(Typography)(props => `
    padding: ${props?.background ? '2px' : 0} 0 !important;
    font-size: 12px;
    line-height: 12px;
    font-weight: 300;
    width:100%;
    display:flex;
    justify-content:space-between;
    align-items:center;
    color: ${props.fontcolor};
    position: relative;
    // background: ${props?.background ? '#f1f1f1' : 'none'};
    
    // background: ${props?.background ? "url('data:image/gif;base64,R0lGODlhBQAEAIcAAP///6ampvT09Pr6+sLCwsfHxwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH/C05FVFNDQVBFMi4wAwEAAAAh+QQEAgAAACwAAAAABQAEAAAIEgABCBxIIECAAgAGCBAwYKDAgAA7') repeat-x 50%" : 'none'};
    background: ${props?.background ? "url('data:image/gif;base64,R0lGODlhBQAEAIcAAPv8/s7P0fX2+Pj5+9zd397g4QAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH/C05FVFNDQVBFMi4wAwEAAAAh+QQEAgAAACwAAAAABQAEAAAIEgABCBxIIECAAgAGCBAwYKDAgAA7') repeat-x 50%" : 'none'};

    ${props?.background && `
        &:after{
            content: '';
            background-color: red;
            height: auto;
            min-height: 15px;
            width: inherit;
            display:none;
        }`
    }

    & label {
        background: #fff;
        display: inline-block;
        padding-right: 5px;
    }

    & span {
        width:20px;
        height:20px;
        display:flex;
        background: #fff;
        padding:5px;
        position: absolute;
        right:0;
    }
`)

export const ShadowWrap = styled('div')(props => `
    background: #FFFFFF;
    border-left: ${`3px solid ${props.leftBorderColor}`};
    padding: ${props.isSentiment ? '10px' : '0'};
    display: flex;
    box-shadow: ${props.isSentiment ? '0px 0px 6px rgba(0, 0, 0, 0.15)' : ''};
    height: 100%;
    margin: ${props.isSentiment && !props.isLastColumn ? '0 10px' : !props.isSentiment ? '0' : '0 0 0 10px'};
    min-height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    & .textItemShadow.legends_1 {
        padding-right:15px !important;
    }
    & .textItemShadow {
        // padding-right:15px !important;
        font-size:  ${props.isSentiment ? '12px' : '14px'};
        line-height:  ${props.isSentiment ? '14px' : '18px'}
    }
    & .textItemShadow label {
        overflow-wrap: anywhere !important;
    }
`)

export const LegendWrap = styled(Box)(`
    margin-top: 30px;
    margin-left: auto;
`)

export const ListHeaderWrapper = styled(Box)(`
    width: 100%;
    display: flex;
`)

export const ListDetailsWrapper = styled(Box)(`
    width: 100%;
    display: flex;
`)

