import React from 'react'
import { RootWrap } from './style'
import CampaignGraphs from '../campaignGraphs'
import SectionRow from '../sandBoxDataTable/sectionRow'

const prepareVerbatim = (verbatims) => {
  const arr = []
  for (let index = 0; index < verbatims.length; index++) {
    const element = verbatims[index]
    const obj = {
      ...(element || {}),
      VerbatimSentiment: element?.VerbatimSentiment,
      VerbatimText: element?.VerbatimText,
    }
    arr.push(obj)
  }
  return arr
}

const prepareGraphs = (graphs) => {
  const arr = []
  for (let index = 0; index < graphs.length; index++) {
    const element = graphs[index]
    const positive = parseInt(element.PositivePercent)
    const neutral = parseInt(element.NeutralPercent)
    const mixed = parseInt(element.MixedPercent)
    const negative = parseInt(element.NegativePercent)

    const obj = {
      heading: element.SubTriggerName || element?.DriverName,
      element: element,
      valueInPercent: true,
      maxWidth: '90%',
      id: element.SubTriggerId || element?.DriverId,
      maxPercentRatio: graphs?.[0].Percent || parseInt(graphs[0].PositivePercent) + parseInt(graphs[0].NeutralPercent) + parseInt(graphs[0].MixedPercent) + parseInt(graphs[0].NegativePercent),
      barData: [
        {
          Positive: positive,
          Neutral: neutral,
          Mixed: mixed,
          Negative: negative,
        },
      ],
    }
    if (element?.Verbatim?.VerbatimElement) {
      obj.verbatim = prepareVerbatim(element?.Verbatim?.VerbatimElement)
    }
    arr.push(obj)
  }
  return arr
}

const CampaignMagnetDetail = ({ row, rowsIsLast }) => {
  const data = {
    id: row?.TriggerId,
    heading: row?.TriggerName,
    sections: [
      {
        id: 1110,
        heading: '',
        type: 'Triggers',
        graphs: [
          {
            id: 112,
            heading: 'Campaign Magnet Score',
            valueInPercent: false,
            maxWidth: '100%',
            barData: row.barData['CampaignMagnetScore'].bar,
            endIcon: row.barData['CampaignMagnetScore'].icon,
          },
          {
            id: 113,
            heading: 'Sentiment & Engagement',
            valueInPercent: true,
            maxWidth: '100%',
            barData: row.barData['SentimentEngagement'].bar,
            maxPercentRatio: row.barData['SentimentEngagement'].maxPercentRatio,
          },
          {
            id: 114,
            heading: 'Brand Association',
            valueInPercent: true,
            maxWidth: '100%',
            barData: row.barData['BrandAssociation'].bar,
            background: row.barData['BrandAssociation'].background
          },
        ],
      },
      {
        id: 1111,
        heading: 'Sub Triggers',
        type: 'SubTriggers',
        name: '',
        graphs: prepareGraphs(Array.isArray(row.SubTriggers) ? row.SubTriggers : [row.SubTriggers] || []),
      },
      {
        id: 112211,
        heading: 'Impact on Brand Perception',
        type: 'ImpactBrandPerception',
        name: '',
        graphs: prepareGraphs(Array.isArray(row.DriverElements) ? row.DriverElements : [row.DriverElements] || []),
      },
    ],
  }

  return (
    <RootWrap className="mani campaignTriggersRowWrapper" id={data.id}>
      <SectionRow title={data.heading} />
      {/* {[data.sections[0], data.sections[1]].map((section, index) => { */}
      {data.sections.map((section, index) => {
        if(!section.graphs.length) return null

        const isLast = (rowsIsLast && index === data.sections.length-1)
        return <CampaignGraphs key={index + data.id} section={section} row={row} title={data.heading} isLast={isLast} />
      })}
    </RootWrap>
  )
}

export default CampaignMagnetDetail
