import React from 'react'
import { Menu } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { humbergerMenu } from '../../../assets/images/icons'
import { MuiImage } from '../../../components'
import { HamburgerWrap } from './style'

const Hamburger = () => {
    const showMenu = () => {
        const element = document.querySelector(".lhsRoot");
        element.classList.add("show");
      }
    
  return (
    <HamburgerWrap onClick={showMenu} className='menu'><MuiImage src={humbergerMenu} /></HamburgerWrap>
  )
}

export default Hamburger