import React from 'react'
import { Modal } from '..'
import { Button, DialogActions, Wrapper, Title } from './style'

const Confirmation = ({
  title,
  onSubmit,
  open = false,
  submitMsg = 'yes',
  cancelMsg = 'no',
  showSubmitMsg = true,
  showCancelMsg = true,
  onClose,
  onCancel,
  ...rest
}) => {
  return (
    <>
      {open && <Modal {...rest} title='' showClose={true} onClose={onClose || onCancel}>
        <Wrapper>
          <Title id='alert-dialog-title' variant='body1'>{title}</Title>
          <DialogActions>
            {showCancelMsg && <Button variant='contained' onClick={onCancel || onClose} color='primary'> {cancelMsg}</Button>}
            {showSubmitMsg && <Button color='gray' variant='contained' onClick={onSubmit} autoFocus>{submitMsg}</Button>}
          </DialogActions>
        </Wrapper>
      </Modal>}
    </>
  )
}

export default Confirmation
