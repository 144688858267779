import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup'
import { MuiButton, MuiDatePicker, MuiSelect, MuiTextField, BlockingLoader, LeavePageBlocker } from '../../../../components';
import { FREQUENCY } from '../../../../constants';
import AdditionalInformation from '../../components/AdditionalInformation';
import DraftModalForm from '../../components/DraftModalForm/draftModalForm';
import ProjectSuccessModal from '../../components/ProjectSuccessModal';
import { Footer } from '../../style';
import InitiateProjectService from '../../../../services/initiate.service';
import ProjectsService from '../../../../services/projects.service';
import { useHistory } from 'react-router-dom';
import Competitors from '../../components/Competitors';
import { isDirty } from '../../../../util';
import dayjs from 'dayjs';
import NoEndDate from '../../components/NoEndDate';
import { useGlobalStore } from '../../../../store';

const RootWrap = styled('form')(`
  display: flex;
  flex-direction: column;
  height: 100%;
`);

const schema = Yup.object({
  BrandName: Yup.string().max(100).required('Brand name is required'),
  MarketName: Yup.string().max(150).required('Market is required'),
  ProjectFrequency: Yup.string().required('Frequency is required'),
  ProjectStartDate: Yup.date().nullable().required('Start date is required').nullable(),
  ProjectEndDate: Yup.date().when('NoProjectEndDate', {
    is: (NoProjectEndDate) => !NoProjectEndDate,
    then: Yup.date().typeError("End date is required").test('ProjectEndDate', 'End date is required', (value, context) => {
      const { createError } = context || {};
      const { ProjectStartDate } = context?.from[0]?.value || {};
      if (!dayjs(value).isValid()) {
        return createError({ message: 'End Date is not valid' });
      } else if (dayjs(ProjectStartDate).isAfter(value)) {
        return createError({ message: `End date can't be before Start date` });
      }
      return true;
    })
  }).nullable(),
  TargetSegment: Yup.string().max(150)
});

const validation = (values) => {
  const errors = {}
  return errors
}

const setInitialValue = (projectData) => {
  const obj = {
    CategoryName: projectData?.CategoryName || '',
    MarketName: projectData?.MarketName || '',
    ProjectFrequency: projectData?.ProjectFrequency || '',
    ProjectStartDate: projectData?.ProjectStartDate || null,
    ProjectEndDate: projectData?.ProjectEndDate || null,
    ProjectType: projectData?.ProjectType || 'Brand Health',
    BrandName: projectData?.BrandName || '',
    TargetSegment: projectData?.TargetSegment || '',
    NoProjectEndDate: projectData?.NoProjectEndDate || false,
    KeyQuestions: projectData?.KeyQuestions || '',
    CompetitorsOption: projectData?.CompetitorsOption || 'NA',
    Competitors: projectData?.Competitors || [],
    IsDraft: projectData?.IsDraft || false,
    DraftName: projectData?.DraftName || ''
  }


  if (projectData?.ProjectId) {
    obj['ProjectId'] = projectData?.ProjectId
  }

  return obj;
}

const BrandHealth = ({ projectId }) => {
  const form = useRef();
  const history = useHistory();
  const {user} = useGlobalStore();
  // const { enqueueSnackbar } = useSnackbar()
  const [isDraftActive, setDraftActive] = useState(false);
  const [isSuccessActive, setSuccessActive] = useState(false);
  const [isLoaderActive, setLoaderActive] = useState(false);

  useEffect(() => {
    if (projectId) {
      setLoaderActive(true)
      ProjectsService.projectsByIdApi({ companyId: user?.CompanyId, userId: user?.UserId, projectId }, true).then(res => {
        const resData = setInitialValue(res);
        formik.setValues(resData);
      }).catch(error => {
        const resMessage = error
        console.log('ERROR : ', resMessage)
        // enqueueSnackbar(resMessage, { variant: 'error' })
      }).finally(() => {
        setLoaderActive(false)
      })
    }
  }, [])

  const onSubmit = (data) => {
    setLoaderActive(true);
    InitiateProjectService.setProject({...data, 
      CompanyId: user.CompanyId,
      ProjectCreatorEmailAddress: user.UserEmail
    }).then((res) => {
      formik.resetForm();
      // enqueueSnackbar('Success', { variant: 'success' });
      if (data.IsDraft) {
        history.push('/initiate-projects/draft');
      } else {
        setSuccessActive(true);
      }
    }).catch(error => {
      const resMessage = error
      console.log('ERROR : ', resMessage)
        // enqueueSnackbar(resMessage, { variant: 'error' })
    }).finally(() => {
      setLoaderActive(false)
    })
  };

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: setInitialValue(),
    validationSchema: schema,
    validate: (values) => validation(values),
    onSubmit: (values) => {
      const updatedValues = { ...values };
      if (!updatedValues.ProjectEndDate) {
        delete updatedValues.ProjectEndDate
      }
      onSubmit(updatedValues);
    },
  });

  const draftConfrimHandler = (draftName) => {
    formik.setFieldValue('DraftName', draftName);
    formik.setFieldValue('IsDraft', !!draftName);
    formik.handleSubmit();
    setDraftActive(false);
  };

  const draftModalHandler = async () => {
    const errors = await formik.validateForm(formik.values);
    formik.setErrors(errors)

    if (Object.keys(errors).length) return;

    setDraftActive(true);
  }

  return (
    <RootWrap ref={form} onSubmit={formik.handleSubmit} autoComplete='off'>
      <Grid container spacing={3} wrap='wrap'>
        <Grid item sm={6}>
          <MuiTextField
            {...formik.getFieldProps('BrandName')}
            name='BrandName'
            onChange={formik.handleChange}
            placeholder='Enter'
            title='Brand Name'
            maxLength="100"
            compact
            shadow
            error={formik.errors.BrandName}
            helperText={formik.errors.BrandName}
            value={formik.values.BrandName || ''}
          />
        </Grid>
        <Grid item sm={6}>
          <MuiTextField
            {...formik.getFieldProps('MarketName')}
            name='MarketName'
            onChange={formik.handleChange}
            placeholder='Enter'
            maxLength="150"
            compact
            shadow
            title='Market Name'
            error={formik.errors.MarketName}
            helperText={formik.errors.MarketName}
            value={formik.values.MarketName || ''}
          />
        </Grid>
        <Grid item sm={12}>
          <Competitors
            value={formik?.values?.Competitors || ''}
            companyList={formik.values.CompanyList}
            companyListChange={(value) => formik.setFieldValue('CompanyList', value)}
            onChange={(value) => formik.setFieldValue('Competitors', value)}
          />
        </Grid>
        <Grid item sm={3}>
          <MuiSelect
            name='ProjectFrequency'
            placeholder={formik.values.ProjectFrequency ? "" : 'Enter'}
            onChange={formik.handleChange}
            title='Frequency'
            data={FREQUENCY}
            compact
            selectlabel='label'
            selectvalue='value'
            error={formik.errors.ProjectFrequency}
            value={formik.values.ProjectFrequency || ''}
          />
        </Grid>
        <Grid item sm={3}>
          <MuiDatePicker
            name='ProjectStartDate'
            value={formik.values.ProjectStartDate || null}
            onChange={(value) => formik.setFieldValue('ProjectStartDate', value.toISOString())}
            placeholder='Start'
            label='Start Date'
            error={formik.errors.ProjectStartDate}
          />
        </Grid>
        <Grid item sm={6}>
          <NoEndDate formik={formik} />
        </Grid>
      </Grid>
      <AdditionalInformation errors={formik.errors} onChange={formik.handleChange} values={formik.values} />
      <Footer>
        <MuiButton
          color='secondary'
          onClick={draftModalHandler}
          variant='outlined'
        >
          Save as Draft
        </MuiButton>
        <MuiButton
          onClick={() => {
            formik.setFieldValue('DraftName', "")
            formik.setFieldValue('IsDraft', false)
          }}
          type='submit' color='primary' variant='contained'>
          Initiate project
        </MuiButton>
      </Footer>
      {
        isDraftActive &&
        <DraftModalForm draftName={formik.values.DraftName} onClose={() => setDraftActive(false)} onConfirm={draftConfrimHandler} />
      }
      {isSuccessActive &&
        <ProjectSuccessModal onClose={() => setSuccessActive(false)} />
      }
      {
        isLoaderActive &&
        <BlockingLoader />
      }
      <LeavePageBlocker when={isDirty(formik.values)} cb={InitiateProjectService.setClientHelpRequest} />
    </RootWrap>
  );
};

export default BrandHealth;
