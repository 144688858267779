import { styled } from '@mui/system'
import { Box } from '@mui/material'
import { exportToPPT_tableCss } from '../../../../util'
import { MEDIA } from '../../../../constants'

export const RootWrap = styled(Box)(`
	width: 100%;
	padding: 0;
    &.exportToPPT {padding:0 0 20px 0; border:0; max-width:1200px;}
    &.exportToPPT div {box-shadow:none;}
    &.exportToPPT .content {display:flex;}
    &.exportToPPT .content .relativeBrands { display:flex; flex-direction:column; padding:0; width:45%;}
    &.exportToPPT .content .relativeBrands > div:last-child { padding:0; min-width:90%; flex-wrap: wrap; justify-content: space-between; }
    &.exportToPPT .content .relativeBrands .MuiListItemText-root { margin-bottom:0; }
    &.exportToPPT .divider { width: 1px; height: 350px; border-left: 1px solid #E7E7E7; margin: auto 2%;}
    &.exportToPPT .content .barGraph { padding:30px 0 0 0; width:50%;}
    &.exportToPPT .content .barGraph .list { padding: 0px 10px; min-height: 50px;}
    ${ exportToPPT_tableCss }
    
    // @media (max-width: ${MEDIA.lg}) {
    //     padding:20px 20px 40px 20px;
    // }
`)

export const ContentWrap = styled(Box)(`
    background: #FFFFFF;
    // border: 1px #EEE solid;
    // box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
    & .relativeBrands {
        border: 0;
    //     box-shadow: none;
    }
`)

export const SegmentOfSegmentWrap = styled(Box)(`
    // padding: 30px;
    // padding-top: 20px;
`)

export const Divider = styled(Box)(`
    border-top: 1px solid #E7E7E7;
    margin: 0 30px;
`)