import { styled } from '@mui/system'
import { Box, MenuItem, Select } from '@mui/material'

export const SelectWrapper = styled(Box)(({ theme }) => `
  width:100%;
  display:flex;
  flex-direction:column;
`)

export const FormLabel = styled('label')(`
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 19px;
  color: #000;
  width:100%;
  padding-bottom:10px;
`)

export const FormError = styled(Box)(`
  font-size: 0.75rem;
  font-weight: 400;
  margin: 4px 14px 0 14px;
  color: #D14343;
`)

export const FormSelectWrap = styled(Box)(`
  position: relative;
`)

export const InputLabel = styled(Box)(props => `
  margin-left: 15px;
  margin-bottom: -30px;
  margin-top: 0;
  opacity: 50%;
  font-size: 12px;
  z-index: 0;
  position: absolute;
  width: fit-content;
  line-height: 40px;
  height: 100%;
  display: flex;
  align-items: center;
`)

export const StyledMenuItem  = styled(MenuItem)( props => `
  min-height: ${props.compact ? "42px" : 'auto'};
  flex-direction: ${props.compact && !props.single ? 'row-reverse' : 'row'};
  paddingLeft: ${props.compact ? '10px' : '20px'};
  font-size: ${props.compact ? '12px' : '16px'};
  white-space: normal;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  & span {
    font-size: ${props.compact  ? '12px' : '16px'};
  }
`);

export const StyledSelect  = styled(Select)( props => `
  background: transparent;
  font-size: ${props.compact ? '12px' : '16px'};
  height: ${props.compact ? '34px' : 'auto'};
  box-shadow: ${props.compact ? '0px 1px 2px rgba(16, 24, 40, 0.12)' : '' };
  & legend{
    width: ${props.compact ? '0' : 'auto' };
  }
  &.MuiOutlinedInput-root{
    &.Mui-disabled {
      background: #F7F7F9 !important;
    }
  }
  & img {
    margin-top: 3px;
    width: 12px;
    right: 13px;
  }
`);


