import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { CanWeHelp } from '../../components'
import { useGlobalStore } from '../../store'
import { MenuItem, Typography } from '@mui/material'
import { changePassword, logout } from '../../assets/images/icons'
import ChangePassword from '../changePassword'
import { Modal } from '..'
import { ListItemTextNew, AvatarWrapper, AvatarIcon, MenuWrapper } from './style'

const LayoutHeaderMenu = () => {
  const navigate = useHistory()
  const { user } = useGlobalStore();
  const [anchorElUser, setAnchorElUser] = useState(null)
  const [userimageUrl, setUserimageUrl] = useState()
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false)
  const [showCloseButton, setShowCloseButton] = useState(true)
  // const [showChangePasswordModal, setShowChangePasswordModal] = useState(false)

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setShowChangePasswordModal(false)
    setAnchorElUser(null)
  }

  const handleOnLogout = () => {
    localStorage.clear()
    navigate.push('/')
  }

  const handleOnChangePassword = () => {
    setAnchorElUser(null)
    setShowChangePasswordModal((prev) => !prev)
  }

  useEffect(() => {
    if (user?.UsingDefaultPassword) {
      setShowCloseButton(false)
      setShowChangePasswordModal(true)
    }
  }, [])

  return (
    <>

      {user?.UserName && <AvatarWrapper>
        {userimageUrl && user
          ? <AvatarIcon alt={user?.UserName || ''} button='true' onClick={handleOpenUserMenu} src={userimageUrl} />
          : <AvatarIcon alt={user?.UserName || ''} button='true' onClick={handleOpenUserMenu}>{user?.UserName?.charAt(0) || ''}</AvatarIcon>
        }
      </AvatarWrapper>}

      <MenuWrapper
        className='menuWrapper'
        anchorEl={anchorElUser}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {user && <MenuItem disabled><ListItemTextNew primary='Name' secondary={user?.UserName || ''} /></MenuItem>}
        {user && <MenuItem disabled><ListItemTextNew primary='Email' secondary={user?.UserEmail || ''} /></MenuItem>}
        <MenuItem onClick={handleOnChangePassword}><Typography><img src={changePassword} alt='' />Change Password</Typography></MenuItem>
        <MenuItem onClick={handleOnLogout}><Typography><img src={logout} alt='' />Logout</Typography></MenuItem>
        {/* <CanWeHelp /> */}
      </MenuWrapper>

      {showChangePasswordModal && <Modal title='' showClose={showCloseButton} onClose={handleOnChangePassword}>
        <ChangePassword onClose={handleCloseUserMenu} />
      </Modal>}
    </>
  )
}

export default LayoutHeaderMenu
