import React from 'react';
import TextNumberBox from '../textNumberBox';
import { HeaderWrap, TextNumberBoxHeading, TextNumberBoxWrapper } from './style';

const HeaderRow = ({ data }) => {

  const dataFormat = [
    {
      id: 'CampaignMagnetScore',
      label: 'Magnet Score',
      postFix: '',
    },
    {
      id: 'Engagement',
      label: 'Engagement',
      postFix: '',
    },
    {
      id: 'NetVibe',
      label: 'Net Vibe',
      postFix: '%',
    },
    {
      id: 'EarnedOrganicReach',
      label: 'Organic + Earned Interactions',
      postFix: '',
    },
    {
      id: 'BrandAssociation',
      label: 'Brand Association',
      postFix: '%',
    },
  ];

  return (
    <HeaderWrap className='campaignTriggersRow headerWrap' id='campaign-header-row' direction='row' spacing={2}>
      <TextNumberBoxHeading className='titleForPPT'>Average</TextNumberBoxHeading>
      <TextNumberBoxWrapper>
      {dataFormat.map((item) => (
        <TextNumberBox
          key={item.id}
          primary={item.label}
          secondary={`${parseInt(data[item.id]).toLocaleString('en-US')}${item.postFix}`}
        />
      ))}
      </TextNumberBoxWrapper>
    </HeaderWrap>
  );
};

export default HeaderRow;
