import { styled } from '@mui/system'
import { Box } from '@mui/material'

export const Wrapper = styled(Box)( props => `
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 50px;
    text-transform: none;
    font-size: 20px;
    line-height: 30px;
    color:#000;
    margin: ${props.position === 'center' ? '0 auto' : '0'};
    & img {
        padding-bottom:20px;
        width:90px;
    }
`)
