import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { exportToPPT_tableCss } from '../../../../util'

export const RootWrap = styled(Box)(`
	width: 100%;
	// padding: 20px 30px 50px;
	// & > div {padding: 0 20px 0 0;}
    &.exportToPPT { width:1450px !important; padding:0 !important; }
    &.exportToPPT > div{background: transparent; box-shadow: none; width:100%; border:0; margin:0; }
    &.exportToPPT .list{padding:3px; border:0; margin:0;}
    &.exportToPPT .list *{font-size:14px; margin:0;}
    &.exportToPPT .list .barSlice{height:23px!important;}
    &.exportToPPT .list:before{ position: relative !important; width: 100% !important; top: 0 !important; left: 0 !important; margin: 0; padding: 0 !important; border: 0 !important;}
	${ exportToPPT_tableCss }
`)

export const ContentWrap = styled(Box)(`
	background: #fff;
	box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
	// border: 1px #EEE solid;
	margin-left: 141px;
	border-radius: 2px;
	width: calc(100% - 141px);
`)

export const SegmentWrap = styled(Box)(`
	width: 100%;
`)

export const SegmentRow = styled(Box)(`
	font-size: 14px;
	font-weight: 300;
	margin-bottom: 5px;
	border: 1px solid #EEEEEE;
	border-left: 0;
	border-radius: 0 2px 2px 0;
	padding: 5px 20px 5px 5px;
	position: relative;
	min-height: 30px;
	display:flex;
	flex-direction: column;
	justify-content: center;
	&:before {
		content: attr(data-age);
		position: absolute;
		left: -141px;
		width: 141px;
		height: calc(100% + 2px);
		display: flex;
		align-items: center;
		border: 1px solid #EEEEEE;
		border-right: 0;
		padding: 5px 6px;
		line-height: 14px;
		top: -1px;
		border-radius: 2px 0px 0px 2px;
	}
	& .outerText{
		font-size: 12px;
		margin-left: 6px;
		font-weight: 300;
	}
`)

export const SegmentRowContent = styled(Box)(`
	display: flex;
	align-items: center;
	// margin: 4px 0;
	position: relative;
`);

export const BarWrap = styled(Box)(`
	width: calc(100% - 150px);
	& .barRoot, .barItemRoot, .barSliceWrap, .barSlice {
		height: 30px !important;
	}
	& .barSlicePercentage{
		font-weight: 300;
		font-size: 14px;
	}
`);

export const IssuesWrap = styled(Box)(`
	position: absolute;
	left: calc(100% + 5px);
	display: flex;
	align-items: center;
	& .radio {
		width: 10px;
		height: 10px;
		min-width: 10px;
		min-height: 10px;
		border: 1px solid #7E7E7E;
		margin-right: 4px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		& .box {
			width: 4.5px;
			height: 4.5px;
			min-width: 4.5px;
			min-height: 4.5px;
			background: #7E7E7E;
			border-radius: 50%;
		}
	}
	& .radio-text {
		font-size: 12px;
		font-weight: 300;
		white-space: nowrap;
	}
`);