import React, { useState, useEffect } from 'react'
import { Typography, IconButton, Box } from '@mui/material'
import { IconEdit, graphLegendArrow } from '../../../../assets/images/icons'
import { IconReportSearch, IconLeftArrow, IconRightArrow } from '../../../../assets/images/icons'
import ReportsService from '../../../../services/reports.service'
import { useUpdateLhs } from '../../../../hooks/useUpdateLhs'
import { getImageUrl, exportExcel_addPercentage, getObjectAsArray } from '../../../../util'
import { ROLES_MM_PRIMARY } from '../../../../constants'
import { useGlobalStore } from '../../../../store'
import { ReportHeading, LoaderWrapper, MuiImage, ReportBrandImageryEditHeaderLevel2, InsightsRteContent, MuiButton, ProgressionWrapper, ProgressionFilters, ReportOuterWrapper } from '../../../../components'
import Header from '../reportSegment/header'
import Filter from './filter'
import Slider from 'react-slick'

import {
  OuterWrapper,
  InnerWrapper,
  GraphOuterWrapper,
  GraphInnerWrapper,
  Graph,
  GraphRow,
  GraphScaleWrapper,
  GraphInfoWrapper,
  SliderWrap,
  GraphContentWrapper,
  GraphContent,
  ContentText,
  GraphContentLevel2,
  GraphRowDetails,
  BrandImageryRowInsights,
  BrandImageryRowInsightsHeading,
  MuiButtonWrap,
  FilterOuterWrap,
  GridHeaderWrapper,
  GraphContentRow,
  SliderOuterWrapper,
  TabCarousel,
  TabCarouselWrapper,
  ClientLogo
} from './style'

const sortingDataEntityWise = (data) => {
  const arrBrand = data.filter(f => f.entityType === 'Brand')
  const arrCompetitorBrand = data.filter(f => f.entityType === 'CompetitorBrand')
  const arrRest = data.filter(f => !['Brand', 'CompetitorBrand'].includes(f.entityType))
  return [...arrBrand, ...arrCompetitorBrand, ...arrRest]
}

const ReportBrandImageryGraphLevel2AndProgressionGraph = (props) => {
  const { showHeader = true, canMove=false, InsightsElementType } = props
  const [loading, setLoading] = useState(true)
  const [rows, setRows] = useState([])
  const [pageFlag, setPageFlag] = useState()
  const [reportExcelData, setReportExcelData] = useState()
  const [editTitleData, setEditTitleData] = useState()
  const [expandedStatus, setExpandedStatus] = useState(false)
  const [showExpandAllCTA, setShowExpandAllCTA] = useState(false)
  const {updateSubLhsApiLoaded} = useUpdateLhs()
  const { user, projectIdValue } = useGlobalStore()
  const editTitleFlag = ROLES_MM_PRIMARY.includes(user?.UserType) && !projectIdValue?.IsFreezed
	const [progressionDataL1Filtered, setProgressionDataL1Filtered] = useState({drivers:[], brands:[], periods:[]})
	const [progressionDataFiltered, setProgressionDataFiltered] = useState({drivers:[], brands:[], periods:[]})
  const [eventCount, setEventCount] = useState(1)

  const [configTab, setConfigTab] = useState([])
  const [isExportToPptWithAppendices, setIsExportToPptWithAppendices] = useState(false)
  const [isExportToPptWithProgression, setIsExportToPptWithProgression] = useState(false)

  const excelSheetFunc = (rowArray) => {
    const reportExcelSheet1DataObject = {heading:[{title: 'Driver', width: { wch: 30 }}, {title: 'Percent'}], values:[]}
    const reportExcelSheet2DataObject = {heading:[{title: 'Driver', width: { wch: 30 }}, {title: 'Sub Driver', width: { wch: 30 }}, {title: 'Percent'}], values:[]}
    for(let i=0; i<rowArray[0].data.length; i++){
      reportExcelSheet1DataObject.heading.push({title: rowArray[0].data[i].logoName })
      reportExcelSheet2DataObject.heading.push({title: rowArray[0].data[i].logoName })
    }

    for(let i=0; i<rowArray.length; i++){
      const rowValues1 = [{ value: rowArray[i].title }, { value: (rowArray[i].per)+'%' }]

      for(let j=0; j<rowArray[i].data.length; j++){
        rowValues1.push({ value: rowArray[i]?.data[j]?.scale || 0 })
        // rowValues1.push({ value: 222 })
      }
      reportExcelSheet1DataObject.values.push(rowValues1)
      
      for(let j=0; j<rowArray[i].nestedData.length; j++){
        const rowValues2 = [{ value: rowArray[i].title }]
        rowValues2.push({ value: rowArray[i].nestedData[j].title })
        rowValues2.push({ value: (rowArray[i].nestedData[j].per)+'%' })
        for(let k=0; k<rowArray[i].nestedData[j].data.length; k++){
          rowValues2.push({ value: rowArray[i]?.nestedData[j]?.data[k]?.scale || 0 })
        }
        reportExcelSheet2DataObject.values.push(rowValues2)
      }
    }
    setReportExcelData({sheet1:reportExcelSheet1DataObject, sheet2:reportExcelSheet2DataObject})
  }

  let scoreImageryTypeArray = {}
  let scoreImageryTypeArrayL2 = {}
  const filterDataObj = {drivers:[], brands:[], periods:[]};

  const generateProgressionGraphData = (resp, hasTabs) => {
    let isNestedData = false
    scoreImageryTypeArray = {}
    scoreImageryTypeArrayL2 = {}
    let brandsList = []

    const rowArrayDataFunc = (data, dataObj) => {
      filterDataObj.periods.push(dataObj?.title || '')
      const filterDataObjBrandsArr = []

      const finalArray1 = data.map((row, ind) => {
        const nestedData = getObjectAsArray(row?.BrandImageryMappingAttributes?.BrandImageryMappingAttribute)
        const nestedDataArray = nestedData?.map((b, index) => {
          return {
            driverId: b.DriverId,
            attributeId: b.AttributeId,
            title: b.AttributeName,
            show: index===0,
            per: b.AttributePercent,
            data: getObjectAsArray(b.EntityIndexScoreImageryType.EntityIndexScoreImageryType).map((b) => {
              return {
                logoImg: b.Entity.EntityLogo,
                logoName: b.Entity.EntityName,
                entityType: b?.Entity?.EntityType,
                scale: b.IndexScore,
                type: b.ImageryType
              }
            }) || []
          }
        }) || []

        if(!isNestedData && (!!nestedData.length || !!row?.Insights)){
          isNestedData = true
        }

        filterDataObj.drivers.push(row.DriverName)
        // filterDataObj.drivers.push({[row.DriverName]:false})

        const finalData = {
          driverId: row?.DriverId,
          title: row?.DriverName,
          per: row?.DriverPercent,
          insights: row?.Insights,
          showLevel2: false,
          nestedData: nestedDataArray.map(m => {
              // sortingDataEntityWise()
            m.data = sortingDataEntityWise(m.data).map(n => {
              const id = `id_${row.DriverId}____${(row.DriverName).replaceAll(' ','')}____${m.attributeId}____${(m.title).replaceAll(' ','')}____${n.logoImg}____${(n.logoName).replaceAll(' ','')}`
              const obj = {val:n.scale, periodLabel:dataObj?.title || ''}
              if(scoreImageryTypeArrayL2[id] === undefined){
                scoreImageryTypeArrayL2[id] = [obj]
              } else {
                scoreImageryTypeArrayL2[id].push(obj)
              }
              // filterDataObj.drivers.push(m.title)
              return n
            })
            return m
          }),
          data: sortingDataEntityWise( getObjectAsArray(row.EntityIndexScoreImageryType?.EntityIndexScoreImageryType) ).map((b) => {
            const id = `id_${row.DriverId}____${(row.DriverName).replaceAll(' ','')}____${b?.Entity?.EntityLogo}____${(b?.Entity?.EntityName).replaceAll(' ','')}`
            const obj = {val:b?.IndexScore, periodLabel:dataObj?.title || ''}
            if(scoreImageryTypeArray[id] === undefined){
              scoreImageryTypeArray[id] = [obj]
            } else {
              scoreImageryTypeArray[id].push(obj)
            }
            filterDataObj.brands.push(b?.Entity?.EntityName)
            brandsList.push(b?.Entity?.EntityName)

            // filterDataObjBrandsArr = [...filterDataObjBrandsArr, {entityName:b?.Entity?.EntityName, entityType:b?.Entity?.EntityType}]
            if(filterDataObjBrandsArr.filter(f => f.entityName === b?.Entity?.EntityName).length === 0){
              filterDataObjBrandsArr.push({entityName:b?.Entity?.EntityName, entityType:b?.Entity?.EntityType})
            }

            return {
              logoImg: b?.Entity?.EntityLogo,
              logoName: b?.Entity?.EntityName,
              entityType: b?.Entity?.EntityType,
              scale: b?.IndexScore,
              type: b?.ImageryType,
              scaleArray:[]
            }
          }) || []
        }

        filterDataObj.brands = sortingDataEntityWise(filterDataObjBrandsArr).map(m => m.entityName)
        
        return finalData
      })

      return finalArray1
    }

    const configTabObject = hasTabs ? (resp.map((d, i) => ( { periodInsightsElementId: d.PeriodInsightsElementId, id: i, label: i===0 ? d.PeriodName : 'Progression', tutorial: i===0 ? 'CurrentPeriod' : 'Progression', title: d.PeriodName, periodOrder: d.PeriodOrder, data: getObjectAsArray(d.PeriodBrandImageryMappingElements.BrandImageryMappingElement) } ))) : []
    const rowArrayData = hasTabs 
      ? configTabObject.map(d => {
        d.data = rowArrayDataFunc(d.data, d)
        return d
      })
      : [rowArrayDataFunc(resp, null)]

    const rowArray = hasTabs ? rowArrayData[0].data : rowArrayData[0]
    const addScore = (d0) => {
      return d0.map(d1 => {
        d1.data.map(d2 => {
          d2.data.map(d3 => {
            const id = `id_${d2.driverId}____${(d2.title).replaceAll(' ','')}____${d3.logoImg}____${(d3.logoName).replaceAll(' ','')}`
            d3.scaleArray = scoreImageryTypeArray[id]
            return d3
          })
          d2.nestedData.map(d3 => {
            d3.data.map(d4 => {
              const id = `id_${d2.driverId}____${(d2.title).replaceAll(' ','')}____${d3.attributeId}____${(d3.title).replaceAll(' ','')}____${d4.logoImg}____${(d4.logoName).replaceAll(' ','')}`
              d4.scaleArray = scoreImageryTypeArrayL2[id]
              return d4
            })
            return d3
          })
          return d2
        })
        return d1
      })
    }

    if(hasTabs){
      setConfigTab(addScore(configTabObject))
    }

    const removeUsingSet = (arr) => {
        let outputArray = Array.from(new Set(arr))
        return outputArray
    }

    setProgressionDataL1Filtered({drivers:removeUsingSet(filterDataObj.drivers), brands:removeUsingSet(brandsList), periods:removeUsingSet(filterDataObj.periods)})
    setShowExpandAllCTA(isNestedData)
    setRows(rowArray)
    excelSheetFunc(rowArray)
    updateSubLhsApiLoaded(props)
    setEventCount(p => p+1)
  }

  const loadBrandImageryGraph = async (getLatest=false) => {
    setPageFlag(true)
    setLoading(true)

    const hasTabs = InsightsElementType === 'BrandImageryMappingAndProgressionGraph'
    const payload = { insightsElementId: props.InsightsElementId, getLatest: getLatest }

    await (hasTabs ? ReportsService.reportGetBrandImageryMappingAndProgressionGraphApi(payload) : ReportsService.reportGetBrandImageryGraphMappingApi(payload))
    .then(resp => {
        generateProgressionGraphData(resp, hasTabs)
      })
      .catch(error => {
        const resMessage = error
        console.log('ERROR : ', resMessage)
      }).finally(() => {
        setLoading(false)
      })
  }

  const changeHandler = (row) => {
    const newRows = rows.map(item => {
      if (item.title === row.title) {
        return {
          ...item,
          data: row.data
        }
      }
      return item;
    })
    setRows(newRows)
  }

  const editTitleClicked = (row) => {
    setEditTitleData(row)
  }
  
  const updateDriversHeading = () => {
    setEditTitleData(null)
    loadBrandImageryGraph(true)
  }
  
  const showLevel2Func = (row, i) => {
    const updateRow = [...rows]
    updateRow[i].showLevel2 = !updateRow[i].showLevel2
    setRows(updateRow)
  }
  
  const showTabContent = (row, index) => {
    const r = [...rows];
    r[index].nestedData = r[index].nestedData.map(s => {
      s.show = s.attributeId === row.attributeId
      return s
    })

    setRows(r);
  }

  const expandCollapseFunc = (flag, rows) => {
    setExpandedStatus(flag)
    const data = rows.map(d => {
      d.showLevel2 = flag
      return d
    })
    setRows(data)
    setEventCount(p => p+1)
  }
  
  const AdditionalButtons = ({ expandedStatus, expandCollapseFunc, rows, expanded }) => {
    const eColor = expandedStatus && { color: '#095BBD' }
    const cColor = expandedStatus === false && { color: '#095BBD' }
    return (
      <>
        <MuiButtonWrap className='BtnExpandAllDrivers'><MuiButton variant='standard' color='primary' sx={eColor} onClick={() => expandCollapseFunc(true, rows)}>Expand All</MuiButton></MuiButtonWrap>
        <span style={{ lineHeight: 2 }}>|</span>
        <MuiButtonWrap className='BtnCollapseAllDrivers'><MuiButton variant='standard' color='primary' sx={cColor} onClick={() => expandCollapseFunc(false, rows)}>Collapse All</MuiButton></MuiButtonWrap>
        <span style={{lineHeight:2}}>|</span>
      </>
    )
  }

  const filterChangeFunc = (data) => {
		setProgressionDataFiltered(data)
	}
  
  useEffect(() => {
    if (!pageFlag) {
      loadBrandImageryGraph()
    }
  }, [])
  
  useEffect(() => {
    // setIsExportToPptWithAppendices(props?.isAppendicesActive === 'Appendices__' && props.InsightsElementType === 'BrandImageryMappingAndProgressionGraph')
    setIsExportToPptWithProgression(props?.isProgressionActive === 'Progression__' && props.InsightsElementType === 'BrandImageryMappingAndProgressionGraph')
  }, [props])
  
  useEffect(() => {
    setEventCount(p => p+1)
  }, [rows])

  return (
    <>
      {showHeader && <ReportHeading  resetInsightHeight={eventCount}
        downloadId={props.type} subHeading='' {...props} downloadIdData={reportExcelData} addTable={true} 
        additionalButton={showExpandAllCTA && <AdditionalButtons expandedStatus={expandedStatus} expandCollapseFunc={expandCollapseFunc} rows={rows} expanded={expandedStatus} />}
      />}

      {editTitleFlag && editTitleData && <ReportBrandImageryEditHeaderLevel2 pageInsights={editTitleData?.insights} insightsElementId={(configTab?.filter(f => f?.periodOrder === 1)?.[0]?.periodInsightsElementId) || props.InsightsElementId} driverId={editTitleData.driverId} driverName={editTitleData.title} onSuccess={updateDriversHeading} onClose={() => setEditTitleData(null)} />}

      <ReportOuterWrapper>
        <OuterWrapper id={props.type} className={`brandImageryOuterWrapper showExpandAllCTA_${showExpandAllCTA}`}>
          <LoaderWrapper loading={loading} loadOnlyApi={props.loadOnlyApi}>
            <ProgressionWrapper configTab={configTab} onChange={() => setEventCount(p => p+1)} defaultTab={(props?.isProgressionActive && props.InsightsElementType === 'BrandImageryMappingAndProgressionGraph') ? 1 : 0}>
                {/* <ProgressionWrapper configTab={configTab} type={InsightsElementType}> */}

                <GraphOuterWrapper>
                  <GraphInfoWrapper className='brandImageryRowAppendices brandImageryRow tRow header'>
                    <span><img src={graphLegendArrow} alt='' />Relatively weaker: over-indexed: negatively</span>
                    <span><img src={graphLegendArrow} alt='' />Relatively stronger: over-indexed: positively</span>
                  </GraphInfoWrapper>
                  <GraphInnerWrapper className='GraphScaleWrapper'>
                    <Graph simulator={canMove ? "yes" : "no"}>
                      {rows.map((rowData, index) => {
                        const rData = rowData;
                        rData.data = sortingDataEntityWise(rData.data)
                        return (
                          <BrandImageryRow showExpandAllCTA={showExpandAllCTA} canMove={canMove} key={index} index={index} onChange={changeHandler} row={rData} editTitleFlag={editTitleFlag} editTitleClicked={editTitleClicked} showLevel2Func={showLevel2Func} />
                        )
                      })}
                    </Graph>
                  </GraphInnerWrapper>
                  <GraphScaleWrapper className='brandImageryRowAppendices brandImageryRow tRow footer footerPPT tab2'>
                    {[-3, -2, -1, 0, 1, 2, 3].map((item, index) => <span key={`scale_${index}`} className={`txt${index + 1}`}>{item}</span>)}
                  </GraphScaleWrapper>
                </GraphOuterWrapper>
        
                <GraphOuterWrapper>
                  <GraphInnerWrapper>
                    {rows?.length > 0 && progressionDataL1Filtered?.drivers?.length > 0 && <ProgressionFilters data={progressionDataL1Filtered} onChange={filterChangeFunc} />}
                    {/* {rows?.length > 0 && progressionDataL1Filtered?.drivers?.length > 0 && <Filter data={progressionDataL1Filtered} onChange={filterChangeFunc} />} */}
                  </GraphInnerWrapper>
                  <GraphInfoWrapper className='brandImageryRowProgression brandImageryRow tRow header'>
                    <span><img src={graphLegendArrow} alt='' />Relatively weaker: over-indexed: negatively</span>
                    <span><img src={graphLegendArrow} alt='' />Relatively stronger: over-indexed: positively</span>
                  </GraphInfoWrapper>
                  <GraphInnerWrapper className='GraphScaleWrapper'>
                    <Graph simulator={canMove ? "yes" : "no"}>
                      {rows.map((rowData, index) => {
                        return <BrandImageryRowNew isExportToPptWithProgression={isExportToPptWithProgression} showExpandAllCTA={showExpandAllCTA} canMove={canMove} key={index} index={index} onChange={changeHandler} row={rowData} editTitleFlag={editTitleFlag} editTitleClicked={editTitleClicked} showLevel2Func={showLevel2Func} showTabContent={showTabContent} progressionDataFiltered={progressionDataFiltered} />
                      })}
                    </Graph>
                  </GraphInnerWrapper>
                  <GraphScaleWrapper className='brandImageryRowProgression brandImageryRow tRow footer footerPPT tab2'>
                    {[-3, -2, -1, 0, 1, 2, 3].map((item, index) => <span key={`scale_${index}`} className={`txt${index + 1}`}>{item}</span>)}
                  </GraphScaleWrapper>
                </GraphOuterWrapper>

            </ProgressionWrapper>
          </LoaderWrapper>
        </OuterWrapper>
      </ReportOuterWrapper>

      {/* <OuterWrapper id={props.type} className={`brandImageryOuterWrapper showExpandAllCTA_${showExpandAllCTA}`}>
        <InnerWrapper hasTabs={configTab?.length > 1}>
          {configTab?.length > 1 && <GridHeaderWrapper className='tabWrapper'><Header options={[...configTab.filter(f => f.periodOrder === 1), ...configTab.filter(f => f.periodOrder === 2)] || undefined} onChange={setActiveTab} value={activeTab} /></GridHeaderWrapper>}
          <LoaderWrapper loading={loading} loadOnlyApi={props.loadOnlyApi}>
          </LoaderWrapper>
        </InnerWrapper>
      </OuterWrapper> */}
    </>
  )
}

export default ReportBrandImageryGraphLevel2AndProgressionGraph

const BrandImageryRow = ({ row, index, onChange, canMove=false, editTitleFlag, editTitleClicked, showLevel2Func, showExpandAllCTA}) => {
  const imageryChangeHandler = (value, imagery) => {
    if(!canMove) return;
    const newData = row?.data?.map(item => {
      if (item.logoName === imagery.logoName) {
        return {
          ...item,
          scale: value
        }
      }
      return item;
    })
    onChange({
      ...row,
      data: newData
    })
  }
  
  return (
    <GraphContentWrapper className={`${row?.showLevel2 ? 'showRowLevel2' : ''}`}>
      <GraphContent className={`brandImageryRowAppendices brandImageryRow tRow ${(row?.nestedData?.length > 0 || row?.insights?.length > 0) ? 'brandImageryLevel2' : 'brandImageryLevel2'}`}>
        <ContentText className={`contentText showEdit_${editTitleFlag}`}>
          {showExpandAllCTA && <Typography className={`hideP ${showExpandAllCTA ? 'pptSubHeading' : ''}`}>{row.title}</Typography>}
          <Typography className='title'>{row.title}</Typography>
          <Typography className='percent'>{row.per + '%'}</Typography>
          {editTitleFlag && <IconButton aria-label="Edit" onClick={() => editTitleClicked(row)} sx={{height:'35px', margin:'-5px 0 0 5px'}}><IconEdit /></IconButton>}
        </ContentText>
        <GraphRow className="list">
          {row?.data?.map((rowItem, index) => {
            const percent = ((rowItem.scale + 3) / 6 * 100);
            // if(percent > 95){ percent }
            return (
              <SliderWrap dataEntityType={rowItem.entityType} key={index} percent={percent} disabled={!canMove} logoname={rowItem.logoName} logo={getImageUrl('logos', rowItem?.logoImg, 'png')}>
                {canMove ?  <input disabled={!canMove} onChange={(e) => imageryChangeHandler(e.target.value, rowItem)} step={0.01} type="range" value={rowItem.scale} min={-3} max={3} className="slider" /> : <MuiImage src={getImageUrl('logos', rowItem?.logoImg, 'png')} />}
              </SliderWrap>
            )
          })}
        </GraphRow>
        <GraphRowDetails className='graphRowDetails'>
          {(row?.nestedData?.length > 0 || row?.insights?.length > 0) && <IconButton size='small' onClick={() => showLevel2Func(row, index)}><IconReportSearch /></IconButton>}
          {/* {row?.showLevel2 && row?.nestedData?.length > 0 && <IconButton size='small' onClick={() => showLevel2Func(row, index)}><IconReportSearch /></IconButton>} */}
        </GraphRowDetails>
      </GraphContent>
      {row?.nestedData && <Box className='brandImageryRow details' style={{display:row?.showLevel2 ? 'block' : 'none'}}>
        {/* {row?.insights && <BrandImageryRowInsights className='brandImageryRowAppendices'><BrandImageryRowInsightsHeading>{row.title}</BrandImageryRowInsightsHeading><InsightsRteContent content={row?.insights} /></BrandImageryRowInsights>} */}
        <BrandImageryRowInsights className='brandImageryRowAppendices'>
          {showExpandAllCTA && <Typography className={`hideP ${showExpandAllCTA ? 'pptSubHeading' : ''}`}>{row.title}</Typography>}
          <BrandImageryRowInsightsHeading>{row.title}</BrandImageryRowInsightsHeading>
          {row?.insights && <InsightsRteContent content={row?.insights} />}</BrandImageryRowInsights>
        {row?.nestedData?.map((rowDataL2, index) => <BrandImageryRowL2 canMove={canMove} key={index} row={rowDataL2} parentHeading={row.title} />)}
      </Box>}
    </GraphContentWrapper>
  )
}

const BrandImageryRowL2 = ({ row, onChange, canMove=false, parentHeading }) => {
  const imageryChangeHandler = (value, imagery) => {
    if(!canMove) return;
    const newData = row?.data?.map(item => {
      if (item.logoName === imagery.logoName) {
        return {
          ...item,
          scale: value
        }
      }
      return item;
    })
    onChange({
      ...row,
      data: newData
    })
  }

  return (
    <GraphContentLevel2 className='brandImageryRowAppendices'>
      <ContentText className='contentText'>
        <Typography className='percent'><span className='title'>{row.title}</span> <b>{row.per + '%'}</b></Typography>
        <Typography className='pptSubHeading'>{parentHeading}</Typography>
      </ContentText>
      <GraphRow className="list">
        {row?.data?.map((rowItem, index) => {
          const percent = ((rowItem.scale + 3) / 6 * 100);
          // if(percent > 80){ percent }
          return (
            <SliderWrap key={index} percent={percent} disabled={!canMove} logoname={rowItem.logoName} logo={getImageUrl('logos', rowItem?.logoImg, 'png')}>
              {
                canMove ? <input disabled={!canMove} onChange={(e) => imageryChangeHandler(e.target.value, rowItem)} step={0.01} type="range" value={rowItem.scale} min={-3} max={3} className="slider" /> : <MuiImage src={getImageUrl('logos', rowItem?.logoImg, 'png')} />
              }
            </SliderWrap>
          )
        })}
      </GraphRow>
      <GraphRowDetails className='graphRowDetails'></GraphRowDetails>
    </GraphContentLevel2>
  )
}

const BrandImageryRowNew = ({ isExportToPptWithProgression, row, index, canMove=false, editTitleFlag, editTitleClicked, showLevel2Func, showExpandAllCTA, showTabContent, progressionDataFiltered}) => {
  const { showLhsMenu } = useGlobalStore();

  if(!progressionDataFiltered.drivers.includes(row.title)){ return false }
  const hasBrands = row?.data.map(d => progressionDataFiltered.brands.includes(d.logoName)).filter(f => f)
  if(hasBrands?.length === 0){ return false }
  
  const settings = {
    className: 'slider variable-width',
    dots: false,
    infinite: false,
    centerMode: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true
  }
  
  return (
    <GraphContentWrapper className={`${(row?.showLevel2 && row?.nestedData?.length > 0) ? 'showRowLevel2' : ''}`}>
      <GraphContent className={`brandImageryRowAppendices brandImageryRowProgression brandImageryRow tRow ${(row?.nestedData?.length > 0) ? 'brandImageryLevel2' : 'brandImageryLevel1'}`}>
        <ContentText className={`contentText showEdit_${editTitleFlag}`}></ContentText>
        <GraphRow className="list">
          <GraphContentRow>
            {/* {showExpandAllCTA && <Typography className={`hideP ${showExpandAllCTA ? 'pptSubHeading' : ''}`}>{row.title}</Typography>} */}
            <Typography className='title'>{row.title}</Typography>
            <Typography className='percent'>{row.per + '%'}</Typography>
            {editTitleFlag && false && <IconButton aria-label="Edit" onClick={() => editTitleClicked(row)} sx={{height:'35px', margin:'-5px 0 0 5px'}}><IconEdit /></IconButton>}            
          </GraphContentRow>
          {row?.data && sortingDataEntityWise(row?.data, 'bbbbb').map((rowItem, index2) => {
            if(!progressionDataFiltered.brands.includes(rowItem.logoName)){
              return false
            }
            return (
              <SliderOuterWrapper className='bbbbb' key={index2} dataEntityType={rowItem.entityType}>
                <ClientLogo><img src={getImageUrl('logos', rowItem?.logoImg, 'png')} alt='' /></ClientLogo>
                <Box className='textOuterWrapper'>
                  {rowItem.scaleArray.map((scaleVal, scaleInd) => {
                    if(!progressionDataFiltered.periods.includes(scaleVal.periodLabel)){ return false }
                    const percent = ((scaleVal.val + 3) / 6 * 100);
                    let pxVal = 40;
                    if(percent < 7){ pxVal = 0; }
                    else if(percent > 95){ pxVal = 50; }
                    return (<Box key={scaleInd} className='textWrapper' dataVal={scaleVal.val}><Box className='text' style={{left:`calc(${percent}% - ${pxVal}px)`}}>{scaleVal.periodLabel}</Box></Box>)
                  })}
                </Box>
              </SliderOuterWrapper>
            )
          })}
        </GraphRow>
        <GraphRowDetails className='graphRowDetails'>
          {(row?.nestedData?.length > 0) && <IconButton size='small' onClick={() => showLevel2Func(row, index)}><IconReportSearch /></IconButton>}
        </GraphRowDetails>
      </GraphContent>
      {row?.nestedData && <Box className='brandImageryRow details' style={{display:row?.showLevel2 ? 'block' : 'none'}}>
        {/* <BrandImageryRowInsights className='brandImageryRowAppendices'>
          {showExpandAllCTA && <Typography className={`hideP ${showExpandAllCTA ? 'pptSubHeading' : ''}`}>{row.title}</Typography>}
          {row?.insights && <InsightsRteContent content={row?.insights} />}
        </BrandImageryRowInsights> */}

        {!isExportToPptWithProgression && row.nestedData.length > 0 && <Box>
          {/* <TabCarouselWrapper><TabCarousel><Slider {...settings}>{[...row.nestedData].map((rowDataL2, index3) => <Typography className={`${rowDataL2.show ? 'active' : ''}`} onClick={() => showTabContent(rowDataL2, index)} key={index3}>{rowDataL2.title} {rowDataL2.per + '%'}</Typography>)}</Slider></TabCarousel></TabCarouselWrapper> */}
          <TabCarouselWrapper lhsMenu={showLhsMenu}><TabCarousel>{[...row.nestedData].map((rowDataL2, index3) => <Typography className={`${rowDataL2.show ? 'active' : ''}`} onClick={() => showTabContent(rowDataL2, index)} key={index3}>{rowDataL2.title} {rowDataL2.per + '%'}</Typography>)}</TabCarousel></TabCarouselWrapper>
          {row.nestedData.filter(f => f.show).map((rowDataL2, index4) => <BrandImageryRowNewL2 key={index4} parentRow={row} row={rowDataL2} progressionDataFiltered={progressionDataFiltered} />)}
        </Box>}

        {isExportToPptWithProgression && row.nestedData.length > 0 && <Box>
          {row.nestedData.map((rowDataL2, index4) => <BrandImageryRowNewL2 isExportToPptWithProgression={isExportToPptWithProgression} key={index4} parentRow={row} row={rowDataL2} progressionDataFiltered={progressionDataFiltered} />)}
        </Box>}
      </Box>}
    </GraphContentWrapper>
  )
}

const BrandImageryRowNewL2 = ({ isExportToPptWithProgression, parentRow, row, progressionDataFiltered }) => {
  return (
    <GraphContentLevel2 className='brandImageryRowProgression brandImageryRowProgression'>
      <Box className='titleOnlyForProgression' style={{display: `${isExportToPptWithProgression ? 'block' : 'none'}`}}>{parentRow.title} - {row.title} {row.per + '%'}</Box>
      <ContentText className='contentText'></ContentText>
      <GraphRow className="list">
        {row?.data?.map((rowItem, index) => {
          if(!progressionDataFiltered.brands.includes(rowItem.logoName)){ return false }
          return (
            <SliderOuterWrapper key={index} dataEntityType={rowItem.entityType}>
              <ClientLogo><img src={getImageUrl('logos', rowItem?.logoImg, 'png')} alt='' /></ClientLogo>
              <Box className='textOuterWrapper'>
                {rowItem.scaleArray.map((scaleVal, scaleInd) => {
                  if(!progressionDataFiltered.periods.includes(scaleVal.periodLabel)){ return false }
                  const percent = ((scaleVal.val + 3) / 6 * 100);
                  let pxVal = 40;
                  if(percent < 7){ pxVal = 0; }
                  else if(percent > 95){ pxVal = 50; }
                  return (<Box key={scaleInd} className='textWrapper' dataVal={scaleVal.val}><Box className='text' style={{left: `calc(${percent}% - ${pxVal}px)`, transform: `translate(-${percent}%, 0px)`}}>{scaleVal.periodLabel}</Box></Box>)
                })}
              </Box>
            </SliderOuterWrapper>
          )
        })}
      </GraphRow>
      <GraphRowDetails className='graphRowDetails'></GraphRowDetails>
    </GraphContentLevel2>
  )
}

