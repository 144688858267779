import React, { useState, useRef, useEffect } from 'react'
import { ListItemText, IconButton, Box } from '@mui/material'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import observationsService from '../../services/observations.service'
import { MuiTextField, MuiButton, Loader, MuiImage } from '..'
import { observations, Observation, send, close, deleteIcon } from '../../assets/images/icons'
import { useGlobalStore } from '../../store'
// import { Title, MenuWrapper, Wrapper, ObservationsWrapper, MuiButtonObservations, ListItemTextWrapper, SearchBoxOuterWrapper, SearchBoxWrapper, CloseWrap } from './style'
import {  Wrapper,  MenuOuterWrapper, ObservationsWrapper, MuiButtonObservations, ListItemTextWrapper, SearchBoxOuterWrapper, SearchBoxWrapper, CloseWrap } from './style'
import Confirmation from '../confirmation'

const Observations = (props) => {
  const { onClose, insightsElementId } = props
  const [anchorElUser, setAnchorElUser] = useState(null)
  const [showObservationsModal, setShowObservationsModal] = useState(false)
  const [listLoading, setListLoading] = useState(true)
  const [commentsList, setCommentsList] = useState([])
  const [confirmationModal, setConfirmationModal] = useState({
    isActive: false,
    item: null
  });
  const {user} = useGlobalStore()

  const handleOnObservations = () => {
    setAnchorElUser(null)
    setShowObservationsModal((prev) => !prev)
  }

  const form = useRef()
  const [submitloader, setSubmitLoader] = useState(false)
  // const [showMessage, setShowMessage] = useState(false)
  // const { enqueueSnackbar } = useSnackbar()

  const onSubmit = (data) => {
    setSubmitLoader(true)
    observationsService.setObservationsApi(data)
      .then((resp) => {
        setSubmitLoader(false)
        // setShowMessage(true)
        getObservations(true)
      })
      .catch((error) => {
        setSubmitLoader(false)
        const resMessage = error
        console.log('ERROR : ', resMessage)
        // enqueueSnackbar(resMessage, { variant: 'error' })
      })
  }

  const validation = (values) => {
    const errors = {}
    return errors
  }

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      comments: ''
    },
    validationSchema: Yup.object({
      comments: Yup.string().required()
    }),
    validate: (values) => validation(values),
    onSubmit: (values) => {
      const updatedValues = {
        userObservation: (values.comments).replaceAll('\n',' #####'),
        insightsElementId: insightsElementId,
        userName: user?.UserName,
        delete: false
      }
      onSubmit(updatedValues)
    }
  })

  const getObservations = (getLatest = false) => {
    setListLoading(true)
    observationsService.getObservationsApi({ insightsElementId: insightsElementId }, getLatest)
      .then((resp) => {
        setCommentsList(resp)
        setListLoading(false)
        formik.setFieldValue('comments', '');
      })
      .catch((error) => {
        formik.setFieldValue('comments', '');
        setListLoading(false)
        const resMessage = error
        console.log('ERROR : ', resMessage)
        // enqueueSnackbar(resMessage, { variant: 'error' })
      })
  }

  useEffect(() => {
    if (!showObservationsModal) {
      formik.errors = {}
    }
    // if(showObservationsModal){
    //   getObservations(false)
    // }
  }, [showObservationsModal])

  useEffect(() => {
    if(!!commentsList?.length){
      setShowObservationsModal(true)
    }
  },[commentsList])

  useEffect(() => {
    if(props?.Observations){
      const comments = Array.isArray(props.Observations?.InsightsElementObservation) ? props.Observations?.InsightsElementObservation : [props.Observations?.InsightsElementObservation]
      setCommentsList(comments)
    }
  },[props?.Observations])

  const Text = ({list}) => {
    const data = (list?.UserObservation?.length > 5) ? list.UserObservation.replace(/#####/g,'<br />') : list.UserObservation
    return (<div dangerouslySetInnerHTML={{__html:(data)}} />)
  }

  const deleteHandler = () => {
    const listItem = confirmationModal?.item || {};
    setConfirmationModal(null)
    const obj = {
      insightsElementId: listItem.InsightsElementId,
      userName: user.UserName,
      userObservation: listItem.UserObservation,
      delete: true
    }
    setSubmitLoader(true)
    observationsService.setorDeleteInsightsElementObservation(obj).then(resp => {
      getObservations(true);
    }).catch(error => {

    }).finally(() => {
      setSubmitLoader(false)
  })
}

  return (
    <ObservationsWrapper className='BtnObservations'>

      {insightsElementId && <MuiButtonObservations variant='contained' color='primary' onClick={(event) => { setAnchorElUser(event.currentTarget); setShowObservationsModal(true) }}>Observations &nbsp; <Observation /></MuiButtonObservations>}
      {/* {showObservationsModal && <MenuOuterWrapper></MenuOuterWrapper>} */}

      {showObservationsModal &&
        <MenuOuterWrapper open={showObservationsModal} onClose={handleOnObservations}
          // anchorEl={anchorElUser}
          // anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          // transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <CloseWrap onClick={() => setShowObservationsModal(false)}><IconButton color='primary'><img src={close} alt='Close' width='24px' /></IconButton></CloseWrap>
          <Wrapper>

            <ListItemTextWrapper>
              {submitloader && <ListItemText><Loader /></ListItemText>}
              {!submitloader && !!commentsList?.length && commentsList.map((list, i) => {
                return (
                  <Box key={i} sx={{display: "flex", alignItems: "center"}}>
                    <ListItemText key={i} primary={list.UserName} secondary={<Text list={list} />} />
                    {
                      list.UserName === user.UserName &&
                      <IconButton sx={{width: "22px", height: "22px", marginBottom: "auto", marginTop: "3px"}} size='small' onClick={() => setConfirmationModal({
                        item: list,
                        isActive: true
                      })}>
                        <MuiImage src={deleteIcon}/>
                    </IconButton>
                    }
                  </Box>
                )
              })}
              {!submitloader && !commentsList?.length && <ListItemText secondary={'No Observations ..'} />}
            </ListItemTextWrapper>

            <SearchBoxOuterWrapper>
              {submitloader ? <Loader /> :
                <form ref={form} onSubmit={formik.handleSubmit}>
                  <SearchBoxWrapper>
                    <MuiTextField
                      error={Boolean(formik.touched.comments && formik.errors.comments)}
                      fullWidth
                      multiline
                      helperText={formik.touched.comments && formik.errors.comments}
                      name='comments'
                      {...formik.getFieldProps("comments")}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type='text'
                      // inputType="area"
                      // minRows="1"
                      value={formik.values.comments}
                      variant='outlined'
                    />
                    <MuiButton
                      className='button'
                      color='primary'
                      variant='text'
                      type='submit'
                      onClick={formik.handleSubmit}
                      sx={{
                        marginTop: "auto"
                      }}
                    >
                      <img src={send} alt='send' width='15px' />
                    </MuiButton>
                  </SearchBoxWrapper>
                </form>
              }
            </SearchBoxOuterWrapper>
          </Wrapper>
        </MenuOuterWrapper>
      }
      <Confirmation 
        open={confirmationModal?.isActive} 
        title={'Are you sure you want to delete the observation?'}
        onClose={()=>setConfirmationModal(null)} 
        onCancel={() => setConfirmationModal(null)} 
        onSubmit={deleteHandler}
      />
    </ObservationsWrapper>
  )
}

export default Observations
