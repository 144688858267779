import * as React from 'react'

const IsLocalHost = ({children, type}) => {
  let isLocalHost = ((window.location.host).replace(/[0-9]/gi,'').replace(':','')) === 'localhost'
  if (type === 'localStorage_dev_true' && window.localStorage.getItem('dev')) {
    isLocalHost = true
  }
  return (
    <section>
      {isLocalHost && <>{children}</>}
    </section>
  )
}
export default IsLocalHost
