import styled from '@emotion/styled'
import { Box } from '@mui/material'
import { MEDIA } from '../../../constants'

export const Wrapper = styled(Box)(`
  // max-width: 1040px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  // flex-wrap: wrap;
`)

export const D3PieWrapper = styled(Box)(`
  width: 410px;
  height: 480px;
  overflow: hidden;
  // border: 1px red solid;
  display: block;
  padding-top: 50px;
  position: relative;

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    left:0;
    background: #fff;
    opacity:0.01;
    z-index: 1;
  }
`)

export const SvgWrapper = styled(Box)(`
  width: 430px;
  height: 450px;
  overflow: hidden;
  // border: 1px red solid;
  & svg {
    margin-top: -130px;
    margin-left: -165px;
    transform: scale(0.98);
  }
`)

export const RhsWrapper = styled(Box)(`
  width: calc(100% - 425px);
  display: flex;
  flex-direction:column;
  & a {color:#000; text-decoration:none; position:relative;}
`)

export const LineWrap = styled(Box)(`
  position:absolute;
  top:15px;
  left:-420px;
  width:400px;
  display:flex;
  justify-content:end;

  @media (max-width: ${MEDIA.lg2}) {
    top:11px;
  }

  & svg {
    position: relative;
    top:0;
    right:0;
  }
`)

export const Heading = styled(Box)(`
  width:100%;
  font-size:20px;
  font-weight:500;
  @media (max-width: ${MEDIA.lg2}) {
    font-size:16px;
  }
`)
  
export const Para = styled(Box)(`
  width:100%;
  font-size: 14px;
  font-weight: 300;
  line-height: 16px;
  padding-bottom: 10px;
  @media (max-width: ${MEDIA.lg2}) {
    font-size:11px;
  }
`)
