import React, { useEffect, useState } from 'react';
import ReactExport from 'react-data-export';
import { Download } from '../../assets/images/icons';
import { MuiButton, IsLocalHost } from '../../components';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const DownloadExcelContent = ({ type, downloadId, data, title }) => {
    const [sheetCount, setSheetCount] = useState(1)
    const [state, setState] = useState([])
    const [excelSheetName, setExcelSheetName] = useState()
    const [state2, setState2] = useState([[]])
    const [excelSheetName2, setExcelSheetName2] = useState('Sheet 2')
    const [state3, setState3] = useState([[]])
    const [excelSheetName3, setExcelSheetName3] = useState('Sheet 3')
    const [state4, setState4] = useState([[]])
    const [excelSheetName4, setExcelSheetName4] = useState('Sheet 4')

    // const defaultCss = { alignment:{wrapText: true, vertical:'top'}, font: { sz: "12", color: { rgb: "333" }} }
    const defaultHeaderCss = { alignment:{wrapText: true, vertical:'top'}, font: { sz: "12", bold:true, color: { rgb: "333" }} }
    const sentimentsArray = [
        { title: 'Positive Percent', width: { wch: 15 }, style: defaultHeaderCss },
        { title: 'Neutral Percent', width: { wch: 15 }, style: defaultHeaderCss },
        { title: 'Mixed Percent', width: { wch: 15 }, style: defaultHeaderCss },
        { title: 'Negative Percent', width: { wch: 15 }, style: defaultHeaderCss }
    ]

    const filename = 'download--'+title?.toLowerCase()?.replaceAll(' ','-') || title || 'download'
    const getSearchDriversKeywordsList = (data) => {
        const dataObj = data
        let objNew = []
        for(let i=0; i<dataObj?.length; i++){
            const item = dataObj[i]
            objNew.push([{ value: `${item.name}`, style: defaultHeaderCss }])
            if(item?.details?.length > 0){
                for(let j=0; j<item?.details?.length; j++){
                    objNew.push([{ value: `${item?.details[j]}` }])
                }
            }
            objNew.push([{ value:' ' }])
            objNew.push([{ value:' ' }])
        }
        return [{
            columns: [{ title: 'The words/phrases are arrayed in decreasing order of search volume.', width: { wch: 60 }, style: {font:{italic:true}} }],
            data: objNew
        }]
    }

    const generateExcelData = () => {
        if(type === 'SearchDrivers__'){
            let objNew = getSearchDriversKeywordsList(data.pptVerbatims)            
            setState([{
                columns: [
                    { title: 'Driver', width: { wch: 30 }, style: defaultHeaderCss },
                    { title: 'Total Percent', width: { wch: 15 }, style: defaultHeaderCss },
                ],
                data: data.searchDrivers
            }])
            setExcelSheetName('Search Drivers')
            setState2([{
                columns: [{ title: 'The words/phrases are arrayed in decreasing order of search volume.', width: { wch: 60 }, style: {font:{italic:true}} }],
                data: objNew
            }])
            setExcelSheetName2('Verbatims')
            setSheetCount(2)
        }
        else if(type === 'ImpactDestinations'){
            let objNew = []
            let isExpertPost = (data?.filter(f => (f?.ExpertPostTitle?.length > 0 && f?.ExpertPostSentiment?.length > 0 && f?.ExpertPostURL?.length > 0)))?.length
            for(let i=0; i<data?.length; i++){
                const item = data[i]
                objNew.push([
                    { value: `${item.Name}` },
                    { value: `${item.Platform}` },
                    { value: `${item.URL}` },
                    { value: `${isExpertPost ? item.ConsumerCommentsAnalysis : ''}` },
                    { value: `${isExpertPost ? item.NetSentiment : ''}` },
                    { value: `${isExpertPost ? item.ExpertPostTitle : ''}` },
                    { value: `${isExpertPost ? item.ExpertPostSentiment : ''}` },
                    { value: `${isExpertPost ? item.ExpertPostURL : ''}` },
                ])
            }
            setState([{
                columns: [
                    { title: 'Name', width: { wch: 40 }, style: defaultHeaderCss },
                    { title: 'Platform', width: { wch: 20 }, style: defaultHeaderCss },
                    { title: 'URL', width: { wch: 60 }, style: defaultHeaderCss },
                    { title: `${isExpertPost ? 'Consumer Comments Analysis' : ''}`, width: { wch: 20 }, style: defaultHeaderCss },
                    { title: `${isExpertPost ? 'Net Sentiment' : ''}`, width: { wch: 20 }, style: defaultHeaderCss },
                    { title: `${isExpertPost ? 'Expert Post Title' : ''}`, width: { wch: 40 }, style: defaultHeaderCss },
                    { title: `${isExpertPost ? 'Expert Post Sentiment' : ''}`, width: { wch: 20 }, style: defaultHeaderCss },
                    { title: `${isExpertPost ? 'Expert Post URL' : ''}`, width: { wch: 60 }, style: defaultHeaderCss },
                ],
                data: objNew
            }])
            setExcelSheetName('Impact Destinations')
        }
        else if( ['BrandAttributeMapElement', 'BrandAttributeMapAndProgression'].includes(type) ){
            let objNew = []
            let isExpertPost = (data?.filter(f => (f?.ExpertPostTitle?.length > 0 && f?.ExpertPostSentiment?.length > 0 && f?.ExpertPostURL?.length > 0)))?.length
            for(let i=0; i<data?.length; i++){
                const item = data[i]
                objNew.push([
                    { value: `${item.DriverName}` },
                    { value: `${item.PercentEngagement}` },
                    { value: `${item.Sentiment}` }
                ])
            }
            setState([{
                columns: [
                    { title: 'Name', width: { wch: 40 }, style: defaultHeaderCss },
                    { title: 'Percent Engagement', width: { wch: 20 }, style: defaultHeaderCss },
                    { title: 'Net Sentiment', width: { wch: 20 }, style: defaultHeaderCss }
                ],
                data: objNew
            }])
            setExcelSheetName('Impact Destinations')
        }
        else if(type === 'BusinessObjective'){
            const objNew = data.map((d) => {
                let val = (d.value.indexOf('#####') !== -1) ? {value:d.value.replace('######',''), style:defaultHeaderCss} : d
                return [val]
            })
            setState([{
                columns: [{ title: '', width: { wch: 130 }, style: defaultHeaderCss }],
                data: objNew
            }])
            setExcelSheetName('Business Objective')
        }
        else if(type === 'Recommendation'){
            const objNew = data.map((d) => {
                const css = d.tag === 'H4' ? defaultHeaderCss : {}
                return [{...d, style: css }]
            })
            setState([{
                columns: [{ title: '', width: { wch: 130 }, style: defaultHeaderCss }],
                data: objNew
            }])
            setExcelSheetName('Recommendation')
        }
        else if(type === 'Aura'){
            const objNew = data;
            setState([{
                columns: [
                    { title: 'Impact Words', width: { wch: 50 }, style: defaultHeaderCss }, 
                    { title: 'Sentiment', width: { wch: 30 }, style: defaultHeaderCss 
                }],
                data: objNew
            }])
            setExcelSheetName('Aura')
        }
        else if(['InsightsMiningFrameworkWithSentiments', 'InsightsMiningFrameworkWithoutSentiments'].includes(type)){
            const sentimentsHeader = ['InsightsMiningFrameworkWithoutSentiments'].includes(type) ? [] : [
                ...sentimentsArray,
                ...[{ title: 'Total Percent', width: { wch: 15 }, style: defaultHeaderCss }]
            ]
            const dataWithoutSentiments = data?.map(d => { return [{value:d[0].value}, {value:d[1].value}, {value:d[2].value}] })
            const objNew = ['InsightsMiningFrameworkWithoutSentiments'].includes(type) ? dataWithoutSentiments : data
            setState([{
                columns: [
                    { title: 'Driver', width: { wch: 50 }, style: defaultHeaderCss },
                    { title: 'Attribute', width: { wch: 50 }, style: defaultHeaderCss },
                    { title: 'Sub Attribute', width: { wch: 50 }, style: defaultHeaderCss },
                    ...sentimentsHeader
                ],
                data: objNew
            }])
            setExcelSheetName('Insights Mining Framework Without Sentiments')
        }
        else if([
                'SearchDrivers', 'DriversAndSubBrands', 'DriversWithoutSentimentsAndSubBrands', 'DriversWithoutSentiments', 'DriversAndTriggers', 'Drivers',
                'DriversAndSubBrandsMotivatorsDetractors', 'DriversAndTriggersMotivatorsDetractors', 'DriversAndRatingImpactsMotivatorsDetractors',
                'DriversMotivatorsDetractors', 'DriversWithoutSentimentsMotivatorsDetractors'
            ].includes(type)){
            const sentimentsHeader = ['SearchDrivers', 'DriversWithoutSentiments'].includes(type) ? [] : sentimentsArray

            const drivers = data.drivers?.map(d => { return [{value:d[0].value}, {value:d[5].value, style:d[5].style || {}}] })
            // const data_drivers = ['SearchDrivers', 'DriversWithoutSentiments'].includes(type) ? drivers : data.drivers
            let data_drivers = data.drivers
            if(['SearchDrivers', 'DriversWithoutSentiments'].includes(type)){ data_drivers = drivers }
            else if(['DriversAndSubBrands', 'DriversWithoutSentimentsAndSubBrands', 'DriversAndSubBrandsMotivatorsDetractors'].includes(type)){ data_drivers = data.driverSubBrandStandouts }
            else if(['DriversAndTriggers', 'DriversAndTriggersMotivatorsDetractors'].includes(type)){ data_drivers = data.driverTriggerStandouts }

            const heading_subBrands = ['DriversAndSubBrands', 'DriversWithoutSentimentsAndSubBrands', 'DriversAndSubBrandsMotivatorsDetractors'].includes(type) ? [{ title: 'Sub-brands', width: { wch: 25 }, style: defaultHeaderCss }] : []
            const heading_triggers = ['DriversAndTriggers', 'DriversAndTriggersMotivatorsDetractors'].includes(type) ? [{ title: 'Triggers', width: { wch: 40 }, style: defaultHeaderCss }] : []

            setState([{
                columns: [
                    ...[{ title: 'Driver', width: { wch: 30 }, style: defaultHeaderCss }],
                    ...sentimentsHeader,
                    ...[{ title: 'Total Percent', width: { wch: 15 }, style: defaultHeaderCss }],
                    ...heading_subBrands,
                    ...heading_triggers
                ],
                data: data_drivers
            }])
            setExcelSheetName('Drivers')
            
            if(!!data?.attributes?.length){
                const attributes = data.attributes?.map(d => { return [{value:d[0].value}, {value:d[1].value}, {value:d[6].value, style:d[5].style || {}}] })
                const data_attributes = ['SearchDrivers', 'DriversWithoutSentiments'].includes(type) ? attributes : data.attributes
                setState2([{
                    columns: [
                        ...[{ title: 'Driver', width: { wch: 30 }, style: defaultHeaderCss }],
                        ...[{ title: 'Attributes', width: { wch: 30 }, style: defaultHeaderCss }],
                        ...sentimentsHeader,
                        ...[{ title: 'Total Percent', width: { wch: 15 }, style: defaultHeaderCss }]
                    ],
                    data: data_attributes
                }])
                setExcelSheetName2('Attributes')
                
                if(!!data?.subAttributes?.length){
                    const subAttributes = data.subAttributes?.map(d => { return [{value:d[0].value}, {value:d[1].value}, {value:d[2].value}, {value:d[7].value, style:d[5].style || {}}] })
                    const data_subAttributes = ['SearchDrivers', 'DriversWithoutSentiments'].includes(type) ? subAttributes : data.subAttributes
                    setState3([{
                        columns: [
                            ...[{ title: 'Driver', width: { wch: 30 }, style: defaultHeaderCss }],
                            ...[{ title: 'Attributes', width: { wch: 30 }, style: defaultHeaderCss }],
                            ...[{ title: 'Sub Attribute', width: { wch: 30 }, style: defaultHeaderCss }],
                            ...sentimentsHeader,
                            ...[{ title: 'Total Percent', width: { wch: 15 }, style: defaultHeaderCss }]
                        ],
                        data: data_subAttributes
                    }])
                    setExcelSheetName3('Sub Attribute')

                    if(['SearchDrivers'].includes(type)){
                        setState4(getSearchDriversKeywordsList(data.pptVerbatims))
                        setExcelSheetName4('Search Words')
                        setSheetCount(4)
                    } else {
                        setSheetCount(3)
                    }
                } else {
                    if(['SearchDrivers'].includes(type)){
                        setState3(getSearchDriversKeywordsList(data.pptVerbatims))
                        setExcelSheetName3('Search Words')
                        setSheetCount(3)
                    } else {
                        setSheetCount(2)
                    }
                }
            } else {
                if(['SearchDrivers'].includes(type)){
                    setState2(getSearchDriversKeywordsList(data.pptVerbatims))
                    setExcelSheetName2('Search Words')
                    setSheetCount(2)
                } else {
                    setSheetCount(1)
                }
            }
        }
        else if(['DriversAndRatingImpacts'].includes(type)){
            setState([{
                columns: [
                    ...[{ title: 'Driver', width: { wch: 30 }, style: defaultHeaderCss }],
                    ...[{ title: 'Impact on Star Rating', width: { wch: 30 }, style: defaultHeaderCss }],
                    ...sentimentsArray,
                    ...[{ title: 'Total Percent', width: { wch: 15 }, style: defaultHeaderCss }],
                ],
                data: data.driversAndRatingImpacts
            }])
            setExcelSheetName('Drivers and Rating Impacts')
        }
        else if(['BrandCategoryOverview'].includes(type)){
            setState([{
                columns: [
                    ...[{ title: 'Brand/Category', width: { wch: 30 }, style: defaultHeaderCss }],
                    ...sentimentsArray,
                    ...[{ title: 'Engagement Index', width: { wch: 20 }, style: defaultHeaderCss }],
                ],
                data: data
            }])
            setExcelSheetName('Brand Category Overview')
        }
        else if(['SegmentDriversAnalysis', 'DriversProgression', 'Triangulation', 'TriangulationComparisonViewOnly', 'SegmentDriversOnlyAnalysis'].includes(type)){

            const heading2 = ["SearchDriversProgression", "DriversProgressionWithoutSentiments", "DriversProgression"].includes(type) ? 'Period' : 'Segment'
            setState([{
                columns: [
                    ...[{ title: 'Driver', width: { wch: 30 }, style: defaultHeaderCss }],
                    ...[{ title: heading2, width: { wch: 30 }, style: defaultHeaderCss }],
                    ...sentimentsArray,
                    ...[{ title: 'Total Percent', width: { wch: 15 }, style: defaultHeaderCss }]
                ],
                data: data
            }])
            setExcelSheetName('Segments')
        }
        else if(['DriversProgressionWithoutSentiments', 'SearchDriversProgression'].includes(type)){
            const finalData = data?.map(d => {
                return [{value:d[0].value}, {value:d[1].value}, {value:d[6].value, style:d[6].style}]
            })
            const heading2 =  ["SearchDriversProgression", "DriversProgressionWithoutSentiments", "DriversProgression"].includes(type) ? 'Period' : 'Segment'
            setState([{
                columns: [
                    { title: 'Driver', width: { wch: 30 }, style: defaultHeaderCss },
                    { title: heading2, width: { wch: 30 }, style: defaultHeaderCss },
                    { title: 'Total Percent', width: { wch: 15 }, style: defaultHeaderCss }
                ],
                data: finalData
            }])
            setExcelSheetName('Segments')
        }
        else if(['SegmentOfSegmentOverview', 'SegmentOfSegmentOverview20Colors', 'SegmentAndSegmentOfSegment'].includes(type)){
            setState([{
                columns: [
                    { title: 'Type', width: { wch: 30 }, style: defaultHeaderCss },
                    { title: 'Segment', width: { wch: 30 }, style: defaultHeaderCss },
                    { title: 'Percent', width: { wch: 15 }, style: defaultHeaderCss }
                ],
                data: data
            }])
            setExcelSheetName('Segments')
        }
        else if(['SegmentOverview', 'TopRelativeBrandsAnalysis', 'AllRelativeBrandsAnalysis', 'BuzzMetrics', 'ProductRatingOptimizationSetup', 'BrandHealthSetup', 'BrandMagnetScore', 'CategoryExplorationSetup', 'MultiBrandNPS'].includes(type)){
            // nameValueObjects ...
            setState([{
                columns: [
                    { title: data?.heading[0] || 'Segment', width: { wch: 30 }, style: defaultHeaderCss },
                    { title: data?.heading[1] || 'Percent', width: { wch: 20 }, style: defaultHeaderCss }
                ],
                data: data.values
            }])
            setExcelSheetName(data?.sheetName || type)
        }
        else if(['BrandImageryMappingTable', 'CompetitiveLandscape'].includes(type)){
            setState([{
                columns: data.heading.map((d) => { return {...d, width: { wch: 25 }, style: {...defaultHeaderCss } } }),
                data: data.values
            }])
            setExcelSheetName(data?.sheetName || type)
        }
        else if(['CampaignAndIssuesAssessmentSetup'].includes(type)){
            setState([{ columns: data.heading, data: data.values }])
            setExcelSheetName('Campaign And Issues Assessment Setup')
        }
        else if(['BrandImageryMappingGraph', 'BrandImageryMappingAndProgressionGraph'].includes(type)){
            setState([{ columns: data.sheet1.heading, data: data.sheet1.values }])
            setExcelSheetName('Brand Imagery Mapping Graph')
            if(data?.sheet2?.values?.length > 0){
                setState2([{ columns: data.sheet2.heading, data: data.sheet2.values }])
                setExcelSheetName2('Brand Imagery Mapping Graph Details')
                setSheetCount(2)
            }
        }
        else if(['CampaignTriggers'].includes(type)){
            setState([{ columns: data.sheet1.heading, data: data.sheet1.values }])
            setExcelSheetName('Campaign Triggers')
            setState2([{ columns: data.sheet2.heading, data: data.sheet2.values }])
            setExcelSheetName2('Campaign Triggers Details')
            setSheetCount(2)
        }
        else if(['SegmentDriversOnlyAnalysis3Level', 'SearchDriversProgression3Level', 'DriversProgression3Level', 'DriversProgressionWithoutSentiments3Level' ].includes(type)){
            const sentimentsHeader = ['SearchDriversProgression3Level', 'DriversProgressionWithoutSentiments3Level'].includes(type) ? [] : sentimentsArray

            const drivers = data.drivers?.map(d => { return [{value:d[0].value}, {value:d[1].value}, {value:d[6].value, style:d[5].style || {}}] })
            const data_drivers = ['SearchDriversProgression3Level', 'DriversProgressionWithoutSentiments3Level'].includes(type) ? drivers : data.drivers

            setState([{
                columns: [
                    ...[{ title: 'Driver', width: { wch: 30 }, style: defaultHeaderCss }],
                    ...[{ title: 'Segment', width: { wch: 30 }, style: defaultHeaderCss }],
                    ...sentimentsHeader,
                    ...[{ title: 'Total Percent', width: { wch: 15 }, style: defaultHeaderCss }]
                ],
                data: data_drivers
            }])
            setExcelSheetName('Drivers')
            
            if(!!data?.attributes?.length){
                // const data_attributes = data.attributes
                const attributes = data.attributes?.map(d => { return [{value:d[0].value}, {value:d[1].value}, {value:d[2].value}, {value:d[7].value, style:d[5].style || {}}] })
                const data_attributes = ['SearchDriversProgression3Level', 'DriversProgressionWithoutSentiments3Level'].includes(type) ? attributes : data.attributes
                setState2([{
                    columns: [
                        ...[{ title: 'Driver', width: { wch: 30 }, style: defaultHeaderCss }],
                        ...[{ title: 'Segment', width: { wch: 30 }, style: defaultHeaderCss }],
                        ...[{ title: 'Attributes', width: { wch: 30 }, style: defaultHeaderCss }],
                        ...sentimentsHeader,
                        ...[{ title: 'Total Percent', width: { wch: 15 }, style: defaultHeaderCss }]
                    ],
                    data: data_attributes
                }])
                setExcelSheetName2('Attributes')
                
                if(!!data?.subAttributes?.length){
                    const subAttributes = data.subAttributes?.map(d => { return [{value:d[0].value}, {value:d[1].value}, {value:d[2].value}, {value:d[3].value}, {value:d[8].value, style:d[5].style || {}}] })
                    const data_subAttributes = ['SearchDriversProgression3Level', 'DriversProgressionWithoutSentiments3Level'].includes(type) ? subAttributes : data.subAttributes
                    // const data_subAttributes = data.subAttributes
                    setState3([{
                        columns: [
                            ...[{ title: 'Driver', width: { wch: 30 }, style: defaultHeaderCss }],
                            ...[{ title: 'Segment', width: { wch: 30 }, style: defaultHeaderCss }],
                            ...[{ title: 'Attributes', width: { wch: 30 }, style: defaultHeaderCss }],
                            ...[{ title: 'Sub Attribute', width: { wch: 30 }, style: defaultHeaderCss }],
                            ...sentimentsArray,
                            ...[{ title: 'Total Percent', width: { wch: 15 }, style: defaultHeaderCss }]
                        ],
                        data: data_subAttributes
                    }])
                    setExcelSheetName3('Sub Attribute')
                    setSheetCount(3)
                } else {
                    setSheetCount(2)
                }
            } else {
                setSheetCount(1)
            }
        }
        else if(['FactorInterlinkage'].includes(type)){
            setState([{
                columns: [
                    { title: 'Factor', width: { wch: 30 }, style: defaultHeaderCss },
                    { title: 'Linked Factor', width: { wch: 30 }, style: defaultHeaderCss },
                    { title: 'Linkage Percent', width: { wch: 15 }, style: defaultHeaderCss }
                ],
                data: data
            }])
            setExcelSheetName('Factor Interlinkage')
        }

    }

    useEffect(() => {
        if(data){
            const dataLength = Array.isArray(data) ? data?.length : Object.keys(data)?.length
            if(dataLength > 0){
                generateExcelData()
            }
        }
    }, [data])

    return (
        <>
            {sheetCount === 1 && <ExcelFile element={<DownloadButton />} filename={filename}>
                <ExcelSheet dataSet={state} name={excelSheetName} />
            </ExcelFile>}
            {sheetCount === 2 && <ExcelFile element={<DownloadButton />} filename={filename}>
                <ExcelSheet dataSet={state} name={excelSheetName} />
                {state2?.length > 0 && <ExcelSheet dataSet={state2} name={excelSheetName2} />}
            </ExcelFile>}
            {sheetCount === 3 && <ExcelFile element={<DownloadButton />} filename={filename}>
                <ExcelSheet dataSet={state} name={excelSheetName} />
                {state2?.length > 0 && <ExcelSheet dataSet={state2} name={excelSheetName2} />}
                {state3?.length > 0 && <ExcelSheet dataSet={state3} name={excelSheetName3} />}
            </ExcelFile>}
            {sheetCount === 4 && <ExcelFile element={<DownloadButton />} filename={filename}>
                <ExcelSheet dataSet={state} name={excelSheetName} />
                {state2?.length > 0 && <ExcelSheet dataSet={state2} name={excelSheetName2} />}
                {state3?.length > 0 && <ExcelSheet dataSet={state3} name={excelSheetName3} />}
                {state4?.length > 0 && <ExcelSheet dataSet={state4} name={excelSheetName4} />}
            </ExcelFile>}
        </>
    )
}

export default DownloadExcelContent;



const DownloadButton = () => {
    return (<>
        {/* <IsLocalHost><div style={{background:'green', height:'100%', color:'#fff', padding:'0 15px', lineHeight:'32px'}}>Excel-3</div></IsLocalHost> */}
        <MuiButton className='BtnExportToExcel' variant='standard' color='primary' sx={{color:'#095BBD'}}><Download /> &nbsp; Export</MuiButton>
    </>)
}