import React, {useState, useEffect} from 'react'
import { OuterWrapper, ContentWrapper } from './style'
import { LoaderWrapper, MuiImage } from '../../../../components'
import { getImageUrl } from '../../../../util'
import ReportsService from '../../../../services/reports.service'
import { Typography } from '@mui/material'

const ReportTabsPptSlide = (props) => {
  const [tabIsLoading, setTabIsLoading] = useState(false)
  const [tabsData, setTabsData] = useState()

  const loadTabsData = async () => {
    setTabIsLoading(true)
    try{
      const allTabs = await ReportsService.reportLhsApi({ studyId: props.StudyId })
      const allTabsObject = allTabs.map((t) => {
        if(t.TabId === props.TabId){
          t.icon = getImageUrl('icons', t.TabIcon, 'svg')
        }
        return t
      }).find(t => t.TabId === props.TabId)
      setTabsData(allTabsObject)
      setTabIsLoading(false)
    }
    catch(error){
      console.log('ERROR - ', error)
      setTabIsLoading(false)
    }
  }

  useEffect(() => {
    if(props?.slideType === 'tabs'){
      loadTabsData()
    }
    else if(props?.slideType === 'appendices'){
      setTabsData({TabName: 'Appendices'})
    }
    else if(props?.slideType === 'progression'){
      setTabsData({TabName: 'progression'})
    }
  }, [props?.slideType])

  return (
    <>
      {props?.type &&
        <OuterWrapper>
          <OuterWrapper id={props.type}>
            <LoaderWrapper loading={tabIsLoading} loadOnlyApi={props.loadOnlyApi}>
              {tabsData?.TabName && <p>{tabsData.TabName}</p>}
            </LoaderWrapper>
          </OuterWrapper>
        </OuterWrapper>
      }
    </>
  )
}

export default ReportTabsPptSlide
