import React from 'react'

const Standout = () => {
  return (
    <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg" style={{transform: 'scale(1.3)'}}>
      <circle cx="4" cy="4" r="3.7" fill="url(#paint0_linear_8831_5297)" stroke="url(#paint1_linear_8831_5297)" strokeWidth="0.6"/>
      <defs>
        <linearGradient id="paint0_linear_8831_5297" x1="4" y1="2.5" x2="4" y2="8" gradientUnits="userSpaceOnUse">
          <stop stopColor="#095BBD"/>
          <stop offset="1" stopColor="#4298FF"/>
        </linearGradient>
        <linearGradient id="paint1_linear_8831_5297" x1="4" y1="0" x2="4" y2="8" gradientUnits="userSpaceOnUse">
          <stop stopColor="#3489EE"/>
          <stop offset="1" stopColor="#095CBE"/>
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Standout
