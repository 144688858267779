import { styled } from '@mui/system'
import { Grid, Box, Typography } from '@mui/material'
import { NavLink } from 'react-router-dom'

export const Wrapper = styled(Grid)(`
    display:flex;
`)

export const ListLink = styled(NavLink)(`
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 15px;
    padding: 7px 0;
    text-decoration:none;
    color:#000;
    cursor:pointer;
    &.active{
        font-weight: 500;
    }
    &.activated{
        font-weight: 500;
    }
`)

export const Heading = styled(Typography)(`
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    position: relative;
    z-index: 1;
`)

export const LhsMenuWrapper = styled(Box)( props =>`
    padding:5px 20px 5px 50px;
    width: 100%;
    display:flex;
    flex-direction:column;
    position: relative;
    z-index: 2;
    overflow-y:auto;
    // max-height: calc(100vh - ${props.tabLength*40}px - 60px);



    & ul {
        padding-bottom:0;
    }
    & li {
        padding:0;
        flex-direction:column;
    }
    & .MuiList-root{
        z-index: 1;
    }
    &.excessLength li a {
        padding:7px 0;
    }
    & li a {
        width:100%;
    }
    & .subHeading {
        width:100%;
        display:flex;
        cursor:pointer;
        font-size:13px;
        background: #fff;
        position: relative;
        font-weight:500;
        z-index: 1;
        padding: 7px 15px 7px 0;
        &.headingHide{
            padding: 6px 15px 6px 0;
            font-weight:300;
        }
        &.headingShow:before,
        &.headingHide:before{
            content: '';
            width: 8px;
            height: 8px;
            border-top: 1px #000 solid;
            border-right: 1px #000 solid;
            display: block;
            position: absolute;
            right: 0;
            top: 17px;
            transform: rotate(-45deg);
        }
        &.headingHide:before{
            top: 10px;
            transform: rotate(135deg);
        }
    }
    & .nestedLink {
        position:relative;
        padding-left:18px !important;
        font-size:12px;
    }
    & .nestedLink:after {
        content: '';
        width: 1px;
        height: 100%;
        border-left: 1px #D9D9D9 solid;
        display: block;
        position: absolute;
        left: 4px;
        top: 0;
    }
    & .nestedLink.nestedLinkEnd:after {
        top: 15px;
        transform: translate(0, -100%);
    }
    & .nestedLink:before {
        content: '';
        width: 8px;
        height: 8px;
        border-bottom: 1px #D9D9D9 solid;
        display: block;
        margin-left: -14px;
        float: left;
    }
    & .nestedLink.nestedLinkEnd:before {
        border-left: 1px #D9D9D9 solid;
    }
`)

export const ContentOuterWrapper = styled(Box)(`
    width:calc(100vw - 350px);
    height:calc(100vh - 200px);
    display:flex;
    flex-direction:column;
    overflow-y:auto;
`)

export const ContentInnerWrapper = styled(Box)(`
    border-bottom:1px solid #E0E0E0;
    width:100%;
    display:flex;
`)

export const ContentWrapper = styled(Box)(`
    min-width:100%;
    display: flex;
    flex-wrap: wrap;
`)

export const LhsWrapper = styled(Box)(`
    width:calc(100% - 420px);
    padding:20px 30px;
`)

export const RhsWrapper = styled(Box)(`
    padding:20px 30px;
    min-width:420px;
    width:420px;
    display: flex;
    flex-direction:column;
`)

export const CardWrapper = styled(Box)(`
    display:flex;
    flex-wrap:wrap;
    justify-content:space-between;
    & > div:nth-of-type(1) {width:calc(50% - 15px)}
    & > div:nth-of-type(2) {width:calc(50% - 15px)}
`)
