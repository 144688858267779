import React, { useState, useEffect } from 'react'
import {ReportHeading, LoaderWrapper, ReportOuterWrapper } from '../../../../components'
import ReportsService from '../../../../services/reports.service'
import { useUpdateLhs } from '../../../../hooks/useUpdateLhs'
import { useExportToPptObjects } from '../../../../hooks/useExportToPptObjects'
import { useGlobalStore } from '../../../../store'
import ReportEditBusiness from './reportEditBusiness'
import {ROLES_MM_PRIMARY} from '../../../../constants'
import { defaultPptxSettings, convertHtmlToPptJson } from '../../../../util/ppt'
import decode from 'unescape'
// import { HTMLToJSON } from 'html-to-json-parser'; // ES6
import pptxgen from 'pptxgenjs';
import { OuterWrapper, InnerWrapper, ContentWrapper } from './style'


let count = 0
const removeTags = (str) => {
    let data = str
    const replaceArray = [ '<STRONG>', '</STRONG>', '<strong>', '</strong>', '<STRIKE>', '</STRIKE>', '<strike>', '</strike>', '<S>', '</S>', '<s>', '</s>', '<EM>', '</EM>', '<em>', '</em>', '<SUB>', '</SUB>', '<sub>', '</sub>', '<SUP>', '</SUP>', '<sup>', '</sup>', '<UNDERLINE>', '</UNDERLINE>', '<underline>', '</underline>' ]
    if(typeof data === 'string') {
        data = str.split('<ul>')[0]
        data = data.replaceAll('<sup>TM</sup>', ' ').replaceAll('<SUP>TM</SUP>', ' ')
        for(let r=0; r<replaceArray.length; r++){
            data = data.replaceAll(replaceArray[r],'')
        }
        data = data.replaceAll('<BR />', ' ').replaceAll('<br />', ' ').replaceAll('<BR>', ' ').replaceAll('<br>', ' ')
        data = data.replaceAll('&nbsp;',' ').replaceAll('&amp;','&').replaceAll('&lt;','<').replaceAll('&gt;','>').replaceAll('&quot;','\/').replaceAll('&apos;',"'")
        data = data.replace(/<\/?span[^>]*>/g,"").replace(/<\/?div[^>]*>/g,"").replace(/<\/?p[^>]*>/g,"")
    }
    return data
}
const nodeJsonMainFunc = async (nodes) => {
    let excelData = []
    const nodeJsonFunc = (nodes, parentNode) => {
        let obj = {}
        if(parentNode === 'UL' || parentNode === 'ULUL' || parentNode === 'ULULUL') {obj = []}

        for (let i = 0; i < nodes.length; i++) {
            const loopNode = nodes[i]
            let tag = loopNode.nodeName;

            if(tag === 'DIV'){
                const txt = (loopNode?.childNodes[0]?.nodeValue)
                if(txt?.trim().length !== 0){
                    count++
                    excelData.push(txt)
                    obj[tag+count] = {
                        tag: tag,
                        text: txt,
                        content: (loopNode?.childNodes?.length > 0) ? [nodeJsonFunc(loopNode.childNodes, tag)] : []
                    }
                }
            }
            if(tag === 'UL'){
                count++
                const tagNew = parentNode==='LILI' ? 'ULULUL' : parentNode==='LI' ? 'ULUL' : tag
                obj[tag+count] = {
                    tag: tagNew,
                    content: (loopNode?.childNodes?.length > 0) ? nodeJsonFunc(loopNode.childNodes, tagNew) : []
                }
            }
            if(tag === 'LI'){
                const txt = removeTags(loopNode.innerHTML)
                if(txt?.trim().length !== 0){
                    const tagNew = parentNode==='ULULUL' ? 'LILILI' : parentNode==='ULUL' ? 'LILI' : tag
                    const txtDash = parentNode==='ULULUL' ? '----- ' : parentNode==='ULUL' ? '-- ' : ''
                    excelData.push(txtDash+txt)
                    obj.push({
                        tag: tagNew,
                        text: txt,
                        content: (loopNode?.childNodes?.length > 1) ? nodeJsonFunc(loopNode.childNodes, tagNew) : ''
                    })
                }
            }
            if(tag === 'P'){

                const txt = removeTags(loopNode.innerHTML)+''
                if(txt?.trim().length !== 0){
                    count++
                    if(!(parentNode === 'LI' || parentNode === 'LILI' || parentNode === 'LILILI')){
                        excelData.push(txt)
                    }
                    obj[tag+count] = {
                        tag: tag,
                        text: txt,
                        content: ''
                    }
                }
            }
            if(tag === 'H4'){
                const txt = removeTags(loopNode.innerHTML)+''
                if(txt?.trim().length !== 0){
                    count++
                    excelData.push(txt+'######')
                    obj[tag+count] = {
                        tag: tag,
                        text: txt,
                        content: ''
                    }
                }
            }
            if(tag === 'HR'){
                count++
                obj[tag+count] = {
                    tag: tag,
                    text: '',
                    content: ''
                }
            }

        }
        return obj
    }
    await nodeJsonFunc(nodes)
    return excelData
}


const ReportBusiness = (props) => {
    // const { enqueueSnackbar } = useSnackbar()
    const [backgroundTabsLoading, setBackgroundTabsLoading] = useState(true)
    const [pageData, setPageData] = useState()
    const [pageHtmlData, setPageHtmlData] = useState()
    const [htmlToJsonData, setHtmlToJsonData] = useState()
    const {user, projectIdValue, isPPTModalActive, exportToPptObject, setExportToPptObject} = useGlobalStore()
    const {updateSubLhsApiLoaded} = useUpdateLhs()
    const {updatingExportToPptObject} = useExportToPptObjects()

    const removingTags = (str) => {
        let d1 = str
        if(isPPTModalActive){
            const htmlNode = document.querySelectorAll(`#${props.type} *`)
            for(let i=0; i<htmlNode.length; i++){
                htmlNode[i].removeAttribute('style')
            }
            const htmlStr = (document.getElementById(props.type).innerHTML).replaceAll('<ol','<ul').replaceAll('</ol>','</ul>')
            document.getElementById(props.type).innerHTML = htmlStr
        }
        return d1
    }

    const loadBusinessObjective = (getLatest=false) => {
        setBackgroundTabsLoading(true)
        ReportsService.reportGetBusinessObjectiveApi({insightsElementId:props.InsightsElementId}, getLatest)
        .then((resp) => {
            setPageData(decode(resp.replace(/<\/?div[^>]*>/g, "")));
            setBackgroundTabsLoading(false)

            setTimeout(() => {
                generatePptObjectFunc()
            }, 2000)
        })
        .catch((error) => {
            const resMessage = error
            console.log('ERROR : ', resMessage)
            setBackgroundTabsLoading(false)
        })
    }

    const htmlToJson = () => {
        const data = pageData
        if(props.type && data.length > 10){
            const rte = document.getElementById(props.type).childNodes[0].childNodes
            const jsonObj = nodeJsonMainFunc(rte)
            jsonObj.then((data) => {
                const dataArray = data.map((v) => {
                    return {value: v}
                })
                setHtmlToJsonData(dataArray)
            })
        }
        return null
    }

    const onSuccessEdit = () => {
        loadBusinessObjective(true)
    }

    const BusinessEditButton = () => {
        return (
            <>{ROLES_MM_PRIMARY.includes(user?.UserType) && !backgroundTabsLoading && !projectIdValue?.IsFreezed && <ReportEditBusiness InsightsElementId={props.InsightsElementId} pageData={pageData || ''} onSuccess={onSuccessEdit} title={props.Title} />}</>
        )
    }

    const generatePptObjectFunc = async () => {
        // if(isPPTModalActive && !backgroundTabsLoading){
        // if(isPPTModalActive){
            const key = props.InsightsElementType+'__'+props.InsightsElementId.replaceAll('-','_')
            // if(!exportToPptObject?.[key]){
                const id = document.getElementById(props.type)
                // id.style.visibility = 'hidden'

                const originalHTML = id.innerHTML
                const json = convertHtmlToPptJson(id)
                id.innerHTML = originalHTML
                json.then(function(resultArray) {
                    const obj = {[key]:resultArray}
                    updatingExportToPptObject(obj)
                    setTimeout(() => {
                        updateSubLhsApiLoaded(props)
                        id.innerHTML = originalHTML
                        // id.style.visibility = 'visible'
                    }, 2000)
                });
            // }
        // }
    }

    useEffect(() => {
        if(!pageData){
            loadBusinessObjective()
        }
    }, [])

    useEffect(() => {
        if(!!pageData?.length){
            htmlToJson()
            removingTags()
        }
    }, [pageData])

    // useEffect(() => {
    //     generatePptObjectFunc()
    // }, [isPPTModalActive && backgroundTabsLoading])

  return (
    <>
        <ReportHeading downloadId={props.type} downloadIdData={htmlToJsonData} subHeading='' {...props} additionalMiddleButton={<BusinessEditButton />} addTable={false} />
        <ReportOuterWrapper>
            <OuterWrapper>
                <LoaderWrapper loading={backgroundTabsLoading} loadOnlyApi={props.loadOnlyApi}>
                    <InnerWrapper id={props.type}>
                        <ContentWrapper dangerouslySetInnerHTML={{__html: pageData}} />
                    </InnerWrapper>
                </LoaderWrapper>
            </OuterWrapper>
        </ReportOuterWrapper>
    </>
  )
}

export default ReportBusiness
