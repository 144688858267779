import React, { useEffect, useState } from "react";
import { MuiSelect } from "../../../../components";
import reportsService from "../../../../services/reports.service";

const DriverName = ({ insightsElementId, onChange, entityName, value, name = "driverName", isShadow, title = "Driver", ...rest }) => {
  const [state, setstate] = useState([]);

  const getSandboxDriverNamesElementHandler = async () => {
    try {
      const resp = await reportsService.getSandboxDriverNamesElement({
        insightsElementId: insightsElementId,
        entityName
      });
      const data = resp.map((item) => ({
        label: item,
        value: item,
      }));
      const filteredData = data.filter(item => !!item.value)
      if (!filteredData?.length) return;
      setstate([
        { label: '', value: '' },
        ...filteredData
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (insightsElementId) {
      getSandboxDriverNamesElementHandler();
    }
  }, [entityName]);

  return (
    <MuiSelect
      name={name}
      onChange={(e) => onChange(name, e.target.value)}
      title={title}
      data={state}
      compact
      placeholder="Select"
      variant={isShadow ? "outlined" : "standard"}
      selectlabel="label"
      selectvalue="value"
      value={value || null}
      {...rest}
    />
  );
};

export default DriverName;