import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const LegendsWrap  = styled(Box)(`
    display: flex;
    align-items: center;
    @media (max-width: 980px) {
        padding-top:5px;
        flex-wrap:wrap;
        & > div {
            margin-left:0;
            margin-right:10px;
        }
    }
`)

export const LegendItem  = styled(Box)(`
    display: flex;
    align-items: center;
    margin: 0 5px;
    & .sociallegendText{
       font-size: 10px; 
       font-weight: 300;
       margin-left: 4px;
    }
    & .socialLegendBox{
        display: flex;
        align-items: center;
    }
`)