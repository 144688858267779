import React, { useContext, useEffect, useMemo } from 'react'

//Initial State and Actions
const initialState = {
  user: null,
  company: null,
  subLhs: [],
  loadAllLhs: [],
  lhs: [],
  isLoading: true,
  isAuthenticated: false,
  isPPTModalActive: false,
  isPPTModalActiveAndClicked: false,
  isAskIaActiveState: 'none', // [none, active, ]
  subLhsApiLoaded: {},
  projectIdValue: {},
  pptSubLhs: [],
  pptSubLhsAppendices: [],
  pptSubLhsProgression: [],
  pptIdsType: '',
  exportToPptObject: {},
  isTourGuideActive: false,
  showLhsMenu: 'projectPage',
  tourGuideData:[]
};

const actions = {
  SET_USER: "SET_USER",
  SET_COMPANY: "SET_COMPANY",
  SET_SUB_LHS: "SET_SUB_LHS",
  SET_LOAD_ALL_LHS: "SET_LOAD_ALL_LHS",
  SET_LHS: "SET_LHS",
  SET_PPT_MODAL: "SET_PPT_MODAL",
  SET_PPT_MODAL_CLICKED: "SET_PPT_MODAL_CLICKED",
  SET_ASK_IA: "SET_ASK_IA",
  SET_PPT_SUB_LHS: "SET_PPT_SUB_LHS",
  SET_PPT_SUB_LHS_APPENDICES: "SET_PPT_SUB_LHS_APPENDICES",
  SET_PPT_SUB_LHS_PROGRESSION: "SET_PPT_SUB_LHS_PROGRESSION",
  SET_PPT_IDS_TYPE: "SET_PPT_IDS_TYPE",
  SET_EXPORT_TO_PPT: "SET_EXPORT_TO_PPT",
  SET_TOUR_GUIDE_ACTIVE: "SET_TOUR_GUIDE_ACTIVE",
  SET_TOUR_GUIDE: "SET_TOUR_GUIDE",
  SET_SUB_LHS_API_LOADED: "SET_SUB_LHS_API_LOADED",
  SET_SHOW_LHS_MENU: "SET_SHOW_LHS_MENU",
  SET_PROJECT_ID_VALUE: "SET_PROJECT_ID_VALUE"
}


//Reducer to Handle Actions
const reducer = (state, action) => {
  switch (action.type) {
    case actions.SET_USER: {
      return {
        ...state,
        user: action.user,
        isLoading: false,
        isAuthenticated: !!action?.user?.UserId,
      }
    }
    case actions.SET_COMPANY: {
      return {
        ...state,
        company: action.company,
        isLoading: false,
        isAuthenticated: !!action?.company?.CompanyId,
      }
    }
    case actions.SET_SUB_LHS: {
      return {
        ...state,
        subLhs: action.subLhs
      }
    }
    case actions.SET_LOAD_ALL_LHS: {
      return {
        ...state,
        loadAllLhs: action.loadAllLhs
      }
    }
    case actions.SET_LHS: {
      return {
        ...state,
        lhs: action.lhs
      }
    }
    case actions.SET_PPT_MODAL: {
      return {
        ...state,
        isPPTModalActive: action.payload
      }
    }
    case actions.SET_PPT_MODAL_CLICKED: {
      return {
        ...state,
        isPPTModalActiveAndClicked: action.payload
      }
    }
    case actions.SET_ASK_IA: {
      return {
        ...state,
        isAskIaActiveState: action.payload
      }
    }
    case actions.SET_PPT_SUB_LHS: {
      return {
        ...state,
        pptSubLhs: action.payload
      }
    }
    case actions.SET_PPT_SUB_LHS_APPENDICES: {
      return {
        ...state,
        pptSubLhsAppendices: action.payload
      }
    }
    case actions.SET_PPT_SUB_LHS_PROGRESSION: {
      return {
        ...state,
        pptSubLhsProgression: action.payload
      }
    }
    case actions.SET_PPT_IDS_TYPE: {
      return {
        ...state,
        pptIdsType: action.payload
      }
    }
    case actions.SET_EXPORT_TO_PPT: {
      const ret = {
        ...state,
        exportToPptObject: {...state.exportToPptObject, ...action.payload}
        // exportToPptObject: action.payload
      }
      return ret
    }
    case actions.SET_TOUR_GUIDE_ACTIVE: {
      return {
        ...state,
        isTourGuideActive: action.payload
      }
    }
    case actions.SET_TOUR_GUIDE: {
      return {
        ...state,
        tourGuideData: action.payload
      }
    }
    case actions.SET_SUB_LHS_API_LOADED: {
      return {
        ...state,
        subLhsApiLoaded: {...state.subLhsApiLoaded, ...action.payload}
      }
    }
    case actions.SET_SHOW_LHS_MENU: {
      return {
        ...state,
        showLhsMenu: action.payload
      }
    }
    case actions.SET_PROJECT_ID_VALUE: {
      return {
        ...state,
        projectIdValue: {...state.projectIdValue, ...action.payload}
      }
    }
    default:
      return state;
  }
};


//Context and Provider
export const TodoListContext = React.createContext();

const Provider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    dispatch({ type: actions.SET_USER, user: user})

    const company = JSON.parse(localStorage.getItem("company"));
    dispatch({ type: actions.SET_COMPANY, company: company})
  }, [])

  const value = useMemo(() => ({
    user: state.user,
    company: state.company,
    subLhs: state.subLhs,
    loadAllLhs: state.loadAllLhs,
    isAuthenticated: state.isAuthenticated,
    isLoading: state.isLoading,
    lhs: state.lhs,
    pptSubLhs: state.pptSubLhs,
    pptSubLhsAppendices: state.pptSubLhsAppendices,
    pptSubLhsProgression: state.pptSubLhsProgression,
    pptIdsType: state.pptIdsType,
    exportToPptObject: state.exportToPptObject,
    isTourGuideActive: state.isTourGuideActive,
    tourGuideData: state.tourGuideData,
    isPPTModalActive: state.isPPTModalActive,
    isPPTModalActiveAndClicked: state.isPPTModalActiveAndClicked,
    isAskIaActiveState: state.isAskIaActiveState,
    subLhsApiLoaded: state.subLhsApiLoaded,
    projectIdValue: state.projectIdValue,
    showLhsMenu: state.showLhsMenu,
    setUser: (user) => {
      dispatch({ type: actions.SET_USER, user: user });
    },
    setCompany: (company) => {
      dispatch({ type: actions.SET_COMPANY, company: company });
    },
    setLhs: (lhs) => {
      dispatch({ type: actions.SET_LHS, lhs: lhs });
    },
    setSubLhs: (subLhs) => {
      dispatch({ type: actions.SET_SUB_LHS, subLhs: subLhs });
    },
    setLoadAllLhs: (loadAllLhs) => {
      dispatch({ type: actions.SET_LOAD_ALL_LHS, loadAllLhs: loadAllLhs });
    },
    setPPTModalActive: (payload) => {
      dispatch({ type: actions.SET_PPT_MODAL, payload: payload });
    },
    setPPTModalActiveAndClicked: (payload) => {
      dispatch({ type: actions.SET_PPT_MODAL_CLICKED, payload: payload });
    },
    setAskIaActiveState: (payload) => {
      dispatch({ type: actions.SET_ASK_IA, payload: payload });
    },
    setPPTSubLhs: (subLhs) => {
      dispatch({ type: actions.SET_PPT_SUB_LHS, payload: subLhs });
    },
    setPPTSubLhsAppendices: (param) => {
      dispatch({ type: actions.SET_PPT_SUB_LHS_APPENDICES, payload: param });
    },
    setPPTSubLhsProgression: (param) => {
      dispatch({ type: actions.SET_PPT_SUB_LHS_PROGRESSION, payload: param });
    },
    setPptIdsType: (param) => {
      dispatch({ type: actions.SET_PPT_IDS_TYPE, payload: param });
    },
    setExportToPptObject: (param) => {
      dispatch({ type: actions.SET_EXPORT_TO_PPT, payload: param });
    },
    setTourGuideActive: (param) => {
      dispatch({ type: actions.SET_TOUR_GUIDE_ACTIVE, payload: param });
    },
    setTourGuideData: (param) => {
      dispatch({ type: actions.SET_TOUR_GUIDE, payload: param });
    },
    setSubLhsApiLoaded: (param) => {
      dispatch({ type: actions.SET_SUB_LHS_API_LOADED, payload: param });
    },
    setProjectIdValue: (param) => {
      dispatch({ type: actions.SET_PROJECT_ID_VALUE, payload: param });
    },
    setShowLhsMenu: (param) => {
      dispatch({ type: actions.SET_SHOW_LHS_MENU, payload: param });
    }
  }), [state]);

  return (
    <TodoListContext.Provider value={value}>
      {children}
    </TodoListContext.Provider>
  );
};

export default Provider;

export const useGlobalStore = () => {
  const globalStore = useContext(TodoListContext);
  return globalStore;
}

