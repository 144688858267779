import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const LegendsWrap  = styled(Box)(`
    display: flex;
    align-items: center;
    justify-content: end;
    @media (max-width: 980px) {
        flex-wrap:wrap;
    }
`)

export const LegendItem  = styled(Box)(`
    display: flex;
    align-items: center;
    margin-left: 10px;
    & .legendBox{
        width: 8px;
        height: 8px;
        margin-right: 5px;
    }
    & .legendText{
       font-size: 10px; 
       font-weight: 300;
       padding: 0 5px 0 0;
       white-space: nowrap;
    }
`)