import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import { exportToPPT_tableCss } from '../../../../util'

export const OuterWrapper = styled(Box)(`
    width:100%;
    // height:10px;
    // overflow:hidden;
    &.exportToPPT {padding:0;}
    // &.exportToPPT img{width:1800px;}
    ${ exportToPPT_tableCss }
`)

export const InnerWrapper = styled(Box)(`
    display:flex; flex-direction: column; width:100%; position: relative;
`)

export const ContentWrapper = styled(Box)(`
    padding:50px 30px;
    width: 100%;
    min-wdith:1200px;
    min-height:300px;
    display:flex; flex-direction: column;
    justify-content:center;
    align-items:center;
    & p {
        font-size:40px;
        padding-top:30px;
    }
`)


