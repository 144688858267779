import {ApiPost} from '../util/axios'
import reportsService from './reports.service'
import { ROUTE_REPORTS } from '../constants'


const projectsApi = async (extraData = {}, getLatest=false, ids) => {
  // Study ID for the Garniar Project
  // 66f1b755-6ae4-11ed-817c-0a1ea4c5532f
  const {user, IsDraft} = extraData;

  const payload =  { companyId: user?.CompanyId || ids?.CompanyId, userId: user?.UserId || ids?.UserId}

  if(IsDraft) {
    payload.IsDraft = IsDraft;
  }

  const options = {
    reqId: 'GetProjects',
    payload: payload,
    resId: 'GetProjectsResult',
    resType: 'array',
    getLatest: getLatest
  }

  return await ApiPost(options).then( (res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const projectsByIdApi = async (payload, getLatest) => {
  const options = {
    reqId: 'GetProject',
    payload: payload,
    resId: 'GetProjectResult',
    resType: 'object',
    getLatest: getLatest
  }
  

  return await ApiPost(options).then( (res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const projectsDetailApi = async (payload, getLatest=false) => {
  const options = {
    reqId: 'GetStudies',
    payload: payload,
    resId: 'GetStudiesResult',
    resType: 'array',
    // getLatest: getLatest
    getLatest: true
  }

  return await ApiPost(options).then( async (res) => {
    const finalResp = []
    if (res.length === 1 && res[0]?.ProjectId === '3f70cc98-9999-1111-4444-000000000000') { return res } // THIS IS BYPASS CODE
    for(let r=0; r<res.length; r++) {
      let data = res[r]
      await reportsService.reportLhsApi({ studyId: data.StudyId }).then((res2) => {
        if(!!res2?.length){
          data['lhs'] = res2
          const tabObj = res2.find(h => h.TabOrder === 1)
          data['href'] = `${ROUTE_REPORTS}/${tabObj?.TabIcon || 'background'}/${tabObj.StudyId}`
          finalResp.push(data)
        }
      })
      .catch((error) => {
        data['lhs'] = error
        finalResp.push(data)
        const resMessage = error
        console.log('ERROR ', resMessage)
      })
    }
    return finalResp
  },
  (error) => {
    console.log('error - ', error)
  })
}

const projectsSetPublishStudy = async (payload) => {
  const options = {
    reqId: 'SetPublishStudy',
    payload: payload,
    resId: 'SetPublishStudyResponse',
    resType: 'object',
    getLatest: true
  }

  return await ApiPost(options).then( (res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const projectsSetFreezeStudy = async (payload) => {
  const options = {
    reqId: 'SetFreezeStudy',
    payload: payload,
    resId: 'SetFreezeStudyResponse',
    resType: 'object',
    getLatest: true
  }

  return await ApiPost(options).then( (res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const projectsSetAdminReviewStudy = async (payload) => {
  const options = {
    reqId: 'SetAdminReviewStudy',
    payload: payload,
    resId: 'SetAdminReviewStudyResponse',
    resType: 'object',
    getLatest: true
  }

  return await ApiPost(options).then( (res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const projectsSetAskIAForStudy = async (payload) => {
  const options = {
    reqId: 'SetAskIAForStudy',
    payload: payload,
    resId: 'SetAskIAForStudyResponse',
    resType: 'object',
    getLatest: true
  }

  return await ApiPost(options).then( (res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}


export default { projectsApi, projectsDetailApi, projectsByIdApi, projectsSetPublishStudy, projectsSetFreezeStudy, projectsSetAdminReviewStudy, projectsSetAskIAForStudy }


