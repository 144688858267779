import React, { useState, useEffect } from 'react'
import { Box } from '@mui/material'
import { ChartBarHorizontalCustom, D3Pie, ReportHeading, LoaderWrapper, ProgressionWrapper, ProgressionFilters, ReportOuterWrapper } from '../../../../components'
import ReportsService from '../../../../services/reports.service'
import { useUpdateLhs } from '../../../../hooks/useUpdateLhs'
import { getObjectAsArray, exportExcel_addPercentage, exportExcel_addSegments  } from '../../../../util'
import {
  OuterWrapper,
  PieChartWrapper,
  BarChartsWrapper,
  SentimentChartWrapper,
  SentimentChartLhs,
  SentimentChartRhs,
  ChartRow,
  PieChartHeading,
  PieChartBody,
  RowName,
  ProgressionFiltersWrapper,
  BrandHealthSectionsWrapper,
  SubHeadingSection,
  BrandHealthSection,
  HeadingSection,
  ContentSection,
  TabSection1,
  TabSection2
} from './style'

const ReportBrandHealthOverviewAndProgression = props => {
  const [loading, setLoading] = useState(true)
  const [rows, setRows] = useState([])
  const [pageFlag, setPageFlag] = useState()
  const [perPie, setPerPie] = useState()
  const [state, setState] = useState()
  const [pieChartSettings, setPieChartSettings] = useState()
  const {updateSubLhsApiLoaded} = useUpdateLhs()
  const [reportExcelData, setReportExcelData] = useState()
  
	const [progressionDataL1Filtered, setProgressionDataL1Filtered] = useState({brands:[], periods:[]})
	const [progressionDataFiltered, setProgressionDataFiltered] = useState({brands:[], periods:[]})
  const [configTab, setConfigTab] = useState([])
	const [progressionData, setProgressionData] = useState()
  const [eventCount, setEventCount] = useState(1)

  const pieWrap = 310
  const pieRad = 100
  const coorXY = ((pieWrap - (pieRad * 2)) / 2)
  const pieSettings = {
    colors: ['#00B050', '#FF0000', '#ABABAB', '#F5BD4F'],
    width: pieWrap,
    height: pieWrap,
    cx: coorXY,
    cy: coorXY,
    outerRadius: pieRad,
    // innerRadius: 10,
    textPosition: 'outside',
    textLocation: 1.5,
    textSize: '20px',
    textShow: true
  }


  let scoreImageryTypeArray = {}
  let scoreImageryTypeArrayL2 = {}
  const filterDataObj = {brands:[], periods:[]};

  const generateProgressionGraphData = async (resp, hasTabs) => {
    scoreImageryTypeArray = {}
    scoreImageryTypeArrayL2 = {}

    const rowArrayDataFunc = (data, dataObj) => {
      filterDataObj.periods.push(dataObj?.title || '')
      filterDataObj.brands.push(data.map((d) => (d.Entity.EntityName)))
      return data
    }

    const configTabObject = hasTabs 
      ? (resp.map((d, i) => {
          return {
            periodInsightsElementId: d.PeriodInsightsElementId,
            id: i,
            label: i===0 ? d.PeriodName : 'Progression',
            tutorial: i===0 ? 'CurrentPeriod' : 'Progression',
            title: d.PeriodName,
            periodOrder: d.PeriodOrder,
            data: getObjectAsArray(d?.PeriodBrandCategoryMetricsElements?.BrandCategoryMetricsElement || []).map(f => ({ ...f, EntityType: f.Entity.EntityType })).filter(f => f.EntityType !== 'HiddenBrand' )
          }
        }))
      : []

    const rowArrayData = hasTabs
      ? configTabObject.map(d => {
        d.data = rowArrayDataFunc(d.data, d)
        return d
      })
      : [rowArrayDataFunc(resp, null)]

    const rowArray = hasTabs ? rowArrayData[0].data : rowArrayData[0]

    const removeUsingSet = (arr) => {
        let outputArray = Array.from(new Set(arr))
        return outputArray
    }
    const progressionDataL1FilteredData = {brands:removeUsingSet(filterDataObj.brands.flat(1)), periods:removeUsingSet(filterDataObj.periods)}
    setProgressionDataL1Filtered(progressionDataL1FilteredData)

    if(hasTabs){
      setConfigTab((configTabObject))
      const finalData = {sentimentSplit:[], sentimentSplitTemp:[], categorySentimentSplit:[]}
      
      for(let i=0; i<rowArrayData.length; i++){
        const d0 = rowArrayData[i].data;
        for(let j=0; j<d0.length; j++){
          const d1 = d0[j]
          finalData.sentimentSplitTemp.push({
            positivePercent: d1.PositivePercent,
            mixedPercent: d1.MixedPercent,
            neutralPercent: d1.NeutralPercent,
            negativePercent: d1.NegativePercent,
            engagementIndex: d1.EngagementIndex,
            title: rowArrayData[i].title,
            name: d1.Entity.EntityName
          })
        }
        const category = {
          title: rowArrayData[i].title,
          name: rowArrayData[i].title,
          PositiveMax: (d0.map(r => (r.PositivePercent)).reduce((prev, next) => prev + next, 0)),
          MixedMax: (d0.map(r => (r.MixedPercent)).reduce((prev, next) => prev + next, 0)),
          NeutralMax: (d0.map(r => (r.NeutralPercent)).reduce((prev, next) => prev + next, 0)),
          NegativeMax: (d0.map(r => (r.NegativePercent)).reduce((prev, next) => prev + next, 0)),
        }
        category.total = category?.PositiveMax + category?.NegativeMax + category?.MixedMax + category?.NeutralMax

        category.positivePercent = Math.round(category.PositiveMax / category.total * 100);
        category.mixedPercent = Math.round(category.MixedMax / category.total * 100);
        category.neutralPercent = Math.round(category.NeutralMax / category.total * 100);
        category.negativePercent = Math.round(category.NegativeMax / category.total * 100);

        finalData.categorySentimentSplit.push(category)
        
      }

      for(let m=0; m<progressionDataL1FilteredData.brands.length; m++){
        const m1 = progressionDataL1FilteredData.brands[m];
        const m2 = finalData.sentimentSplitTemp.filter(f => f.name === m1)
        finalData.sentimentSplit.push(m2)
      }
      setPerPie(finalData.categorySentimentSplit[0])

      setProgressionData(finalData)
    } else {
      const maxPercents = {
        PositiveMax: rowArray?.map(r => (r?.PositivePercent)).reduce((prev, next) => prev + next, 0),
        MixedMax: rowArray?.map(r => (r?.MixedPercent)).reduce((prev, next) => prev + next, 0),
        NeutralMax: rowArray?.map(r => (r?.NeutralPercent)).reduce((prev, next) => prev + next, 0),
        NegativeMax: rowArray?.map(r => (r?.NegativePercent)).reduce((prev, next) => prev + next, 0)
      }
      maxPercents.total = maxPercents?.PositiveMax + maxPercents?.NegativeMax + maxPercents?.MixedMax + maxPercents?.NeutralMax
      setPerPie(maxPercents)  
    }

    const rowArray2 = rowArray?.map((r, i) => {
      return {
        entityName: r?.Entity?.EntityName,
        entityLogo: r?.Entity?.EntityLogo,
        mixedPercent: r?.MixedPercent,
        negativePercent: r?.NegativePercent,
        neutralPercent: r?.NeutralPercent,
        positivePercent: r?.PositivePercent,
        engagementIndex: r?.EngagementIndex,
        brandMagnetScore: r?.BrandMagnetScore
      }
    })
    setRows(rowArray2)

    setLoading(false)
    updateSubLhsApiLoaded(props)
    setState(rowArray)
    setEventCount(p => p+1)
  }

  const loadBrandImageryGraph = async () => {
    setPageFlag(true)
    setLoading(true)

    const hasTabs = props.InsightsElementType === 'BrandCategoryOverviewAndProgression'
    const payload = { insightsElementId: props.InsightsElementId }

    await (hasTabs ? ReportsService.reportGetMagnetScoreAndProgressionApi(payload) : ReportsService.reportGetMagnetScoreApi(payload))
    .then(resp => {
        generateProgressionGraphData(resp, hasTabs)
      })
      .catch(error => {
        const resMessage = error
        console.log('ERROR : ', resMessage)
      }).finally(() => {
        setLoading(false)
      })
  }

  const reportExcelDataObjFunc = (resp, pieChartData) => {
    const reportExcelDataObj = []
    for(let r=0; r<resp.length; r++){
      reportExcelDataObj.push([{value: resp[r]?.Entity?.EntityName}, ...exportExcel_addSegments(resp[r]), {value: resp[r].EngagementIndex}])
    }

    reportExcelDataObj.push([{value:''}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''}])

    reportExcelDataObj.push([
      {value:'Category Sentiment Split'},
      exportExcel_addPercentage(pieChartData[0].value),
      exportExcel_addPercentage(pieChartData[1].value),
      exportExcel_addPercentage(pieChartData[2].value),
      exportExcel_addPercentage(pieChartData[3].value),
      {value:0}
    ])

    setReportExcelData(reportExcelDataObj)
  }

  const filterChangeFunc = (data) => {
		setProgressionDataFiltered(data)
    setEventCount(p => p+1)
	}

  useEffect(() => {
    if (!pageFlag) {
      loadBrandImageryGraph()
    }
  }, [])

  useEffect(() => {
    if(!!perPie){
      const pieChartSett = {
        data: [
          { name: 'Positive', value: (perPie?.PositiveMax / perPie?.total * 100) },
          { name: 'Negative', value: (perPie?.NegativeMax / perPie?.total * 100) },
          { name: 'Mixed', value: (perPie?.MixedMax / perPie?.total * 100) },
          { name: 'Neutral', value: (perPie?.NeutralMax / perPie?.total * 100) }
        ]
      }
      setPieChartSettings(pieChartSett)
    }
  }, [perPie])
 
  useEffect(() => {
    if(!!pieChartSettings && !!state){
      reportExcelDataObjFunc(state, pieChartSettings.data)
    }
  },[pieChartSettings, state])

  return (
    <>
      <ReportHeading resetInsightHeight={eventCount} downloadId={props.type} subHeading='' {...props} downloadIdData={reportExcelData} />
      <ReportOuterWrapper>
        <OuterWrapper id={props.type}>
          <LoaderWrapper loading={loading} loadOnlyApi={props.loadOnlyApi}>
            <ProgressionWrapper configTab={configTab} onChange={() => setEventCount(p => p+1)} defaultTab={(props?.isProgressionActive && props.InsightsElementType === 'BrandCategoryOverviewAndProgression') ? 1 : 0}>
              <TabSection1 hasTabs={props.InsightsElementType === 'BrandCategoryOverviewAndProgression'}>
                  <PieChartWrapper>
                    <PieChartHeading>Category<br/>Sentiment Split</PieChartHeading>
                    <PieChartBody>
                      {pieChartSettings && <D3Pie {...pieChartSettings} settings={pieSettings} />}
                    </PieChartBody>
                    <PieChartHeading></PieChartHeading>
                  </PieChartWrapper>
                  <BarChartsWrapper>
                    <SentimentChartWrapper>
                      <SentimentChartLhs>Sentiment Split</SentimentChartLhs>
                      <SentimentChartRhs>
                        {[...rows].map((bar1, i) => {
                          return (
                            <ChartRow key={i}>
                              <RowName 
                                title={bar1?.positivePercent + bar1?.neutralPercent + bar1?.mixedPercent + bar1?.negativePercent}
                              >
                                {bar1?.entityName}
                              </RowName>
                              <ChartBarHorizontalCustom
                                maxWidth='75%'
                                valueInPercent
                                data={[{
                                  Positive: bar1?.positivePercent,
                                  Neutral: bar1?.neutralPercent,
                                  Mixed: bar1?.mixedPercent,
                                  Negative: bar1?.negativePercent
                                }]}
                                maxPercentRatio={1}
                                // height='25px'
                              />
                            </ChartRow>
                          )
                        })}
                      </SentimentChartRhs>
                    </SentimentChartWrapper>

                    <SentimentChartWrapper>
                      <SentimentChartLhs>Consumer<br />Engagement Index</SentimentChartLhs>
                      <SentimentChartRhs>
                        {[...rows].map((bar2, j) => {
                          return (
                            <ChartRow key={j}>
                              <RowName>{bar2?.entityName}</RowName>
                              <ChartBarHorizontalCustom
                                maxWidth='75%'
                                showToolTip={false}
                                showInside={false}
                                data={[{engagementIndex: bar2?.engagementIndex * 100}]}
                                valueInPercent={false}
                                bG='#4EADEA'
                                total={bar2?.engagementIndex}
                                maxPercentRatio={100}
                                // height='30px'
                              />
                            </ChartRow>
                          )
                        })}
                      </SentimentChartRhs>
                    </SentimentChartWrapper>
                  </BarChartsWrapper>
              </TabSection1>
              <TabSection2>
                <ProgressionFiltersWrapper>
                  {rows?.length > 0 && progressionDataL1Filtered?.brands?.length > 0 && <ProgressionFilters data={progressionDataL1Filtered} onChange={filterChangeFunc} />}
                </ProgressionFiltersWrapper>

                {progressionData?.sentimentSplit?.length > 0 && <BrandHealthSectionsWrapper className='brandHealthSectionsWrapper'>
                  <SubHeadingSection className='brandHealthExportRowMainHeading brandHealthExportRowProgression'>
                    Category Sentiment Split
                  </SubHeadingSection>

                  <BrandHealthSections heading='Category Sentiment Split'>
                    {progressionData?.categorySentimentSplit.map((g, i) => {
                      if( !progressionDataFiltered?.periods?.includes(g.title) ){ return false }
                      return (
                        <Box key={i} style={{width: 'calc(50% - 25px)'}} className='brandHealthExportRowProgression'>
                          <ChartRow className=''>
                            <RowName 
                              title={g?.positivePercent + g?.neutralPercent + g?.mixedPercent + g?.negativePercent}
                            >
                              {g?.title}
                            </RowName>
                            <ChartBarHorizontalCustom
                              maxWidth='90%'
                              valueInPercent
                              data={[{
                                Positive: g?.positivePercent,
                                Neutral: g?.neutralPercent,
                                Mixed: g?.mixedPercent,
                                Negative: g?.negativePercent
                              }]}
                              maxPercentRatio={1}
                              // height='25px'
                            />
                          </ChartRow>
                        </Box>
                      )
                    })}
                  </BrandHealthSections>
                  
                  {progressionDataFiltered.brands.length > 0 && <>
                  <BrandHealthSections heading='Sentiment Split'>

                    <SubHeadingSection className='brandHealthExportRowProgression brandHealthExportRowMainHeading'>
                      <span>Sentiment Split</span>
                      <span style={{ paddingLeft:'20px' }}>Consumer Engagement Index</span>
                    </SubHeadingSection>

                    {progressionData?.sentimentSplit.map((d, i) => {
                      if( !progressionDataFiltered?.brands?.includes(d[0].name) ){ return false }
                      return (
                        <Box key={i} className='exportToPPTAppendicesWrapper brandHealthExportRowProgression'>
                          <SubHeadingSection className='brandHealthExportRowSubHeading'>{d[0].name} <span>{d[0].name}</span></SubHeadingSection>
                          {d.map((g, i) => {
                            if( !progressionDataFiltered?.periods?.includes(g.title) ){ return false }
                            return (
                              <Box key={'graph1_'+i} className='brandHealthExportRowGraph'>
                                <ChartRow className='graph1'>
                                  <RowName 
                                    title={g?.positivePercent + g?.neutralPercent + g?.mixedPercent + g?.negativePercent}
                                  >
                                    {g?.title}
                                  </RowName>
                                  <ChartBarHorizontalCustom
                                    maxWidth='90%'
                                    valueInPercent
                                    data={[{
                                      Positive: g?.positivePercent,
                                      Neutral: g?.neutralPercent,
                                      Mixed: g?.mixedPercent,
                                      Negative: g?.negativePercent
                                    }]}
                                    maxPercentRatio={1}
                                    // height='25px'
                                  />
                                </ChartRow>
                              
                                <ChartRow className='graph2' style={{display:'none'}}>
                                <RowName 
                                  title={g?.positivePercent + g?.neutralPercent + g?.mixedPercent + g?.negativePercent}
                                >
                                  {g?.title}
                                </RowName>
                                <ChartBarHorizontalCustom
                                  maxWidth='90%'
                                  showToolTip={false}
                                  showInside={false}
                                  data={[{engagementIndex: g?.engagementIndex * 100}]}
                                  valueInPercent={false}
                                  bG='#4EADEA'
                                  total={g?.engagementIndex}
                                  maxPercentRatio={100}
                                  // height='25px'
                                />
                                </ChartRow>
                              </Box>
                            )
                          })}
                        </Box>
                      )
                    })}
                  </BrandHealthSections>
                  <BrandHealthSections heading='Consumer Engagement Index' className='graph3Wrapper'>
                    {progressionData?.sentimentSplit.map((d, i) => {
                      if( !progressionDataFiltered?.brands?.includes(d[0].name) ){ return false }

                      return (
                        <Box key={'graph2_'+i}>
                          <SubHeadingSection>{d[0].name}</SubHeadingSection>
                          {d.map((g, i) => {
                            if( !progressionDataFiltered?.periods?.includes(g.title) ){ return false }
                            return (
                              <ChartRow key={i}>
                                <RowName 
                                  title={g?.positivePercent + g?.neutralPercent + g?.mixedPercent + g?.negativePercent}
                                >
                                  {g?.title}
                                </RowName>
                                <ChartBarHorizontalCustom
                                  maxWidth='90%'
                                  showToolTip={false}
                                  showInside={false}
                                  data={[{engagementIndex: g?.engagementIndex * 100}]}
                                  valueInPercent={false}
                                  bG='#4EADEA'
                                  total={g?.engagementIndex}
                                  maxPercentRatio={100}
                                  // height='25px'
                                />
                              </ChartRow>
                            )
                          })}
                        </Box>
                      )
                    })}
                  </BrandHealthSections>
                </>}
                </BrandHealthSectionsWrapper>}
              </TabSection2>
            </ProgressionWrapper>
          </LoaderWrapper>
        </OuterWrapper>
      </ReportOuterWrapper>
    </>
  )
}

export default ReportBrandHealthOverviewAndProgression

const BrandHealthSections = ({heading, className, children}) => {
  return (
    <BrandHealthSection className={className}>
      <HeadingSection>{heading}</HeadingSection>
      <ContentSection>{children}</ContentSection>
    </BrandHealthSection>
  )
}
