import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { bigArrow } from "../../../../assets/images/icons";

export const BrandMagnetScoreTreeWrap = styled(Box)(`
    display: flex;
    width: 100%;
    position: relative;
    align-items: center;
`);

export const BigArrow = styled('span')(props => `
    width: 60px;
    height: 86px;
    background: url(${bigArrow}) no-repeat;
    position: absolute;
    left: ${props.arrowdirection === 'left' ? "25px" : "calc(100% - 75px)"};
    z-index: 2;
    transform: rotate(${props.arrowdirection === 'left' ? "180deg" : "0deg"});
`)


export const ChartList = styled('ul')(props => `
    display: flex;
    flex-direction: column;
    position: relative;
    margin-${props.arrowdirection}: auto;
    justify-content: center;
    width: calc(100% - ${props.arrowdirection === "left" ? "140px" : "130px"} );
    padding: ${props.arrowdirection === "left" ? "10px 0px" : "10px 0" };

    &:before{
        content:'';
        position: absolute;
        border: 1px solid #d3d3d3;
        border-radius: 4px;
        border-left: 0;
        height: calc(100% - 135px);
        width: 90px;
        ${props.arrowdirection} : -56px; 
        transform: scaleX(${props.arrowdirection === "left" ? -1 : 1});
    }
    & li {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 12px 0;
        background: #FFFFFF;
        box-shadow: 0px 10px 30px rgba(34, 47, 62, 0.1);
        align-items: center;
        padding: 20px;
        min-height: 60px;
        width: 100%;

        &:first-of-type{
            margin-top: 10px;
            &::after{content: unset !important;}
        }
        &:last-child{
            &::after{content: unset !important;}
        }
        &::after{
            content: '';
            position: absolute;
            background: #d3d3d3;
            height: 1px;
            width: 55px;
            top: unset;
            ${props.arrowdirection}: -55px;
        }
        & h3 {
            font-weight: 300;
            width: calc(100% - 80px);
            font-size: 20px;
            line-height: 20px;
        }
        & img {
            width: 52px;
        }
    }
    
    @media (max-width: 980px) {
        width:100%;
        padding:0 20px 10px 20px;
        & li {width:100%;}
        & div {z-index:0 !important;}
    }
`)
