import React, {useState, useEffect} from 'react'
import PostLogin from '../../layout/postLogin'
import { Header, MuiButton } from '../../components'
import DataGridTable from './dataGridTable'
import Filter from './filter'
import { NAME_PROJECTS, ROUTE_INITIATE_PROJECTS, ROUTE_INITIATE_PROJECTS_DRAFTS } from '../../constants'
import { plusWhite, draft } from '../../assets/images/icons'
import { Box } from '@mui/material'
import { ROLES_JUNIOR_ANALYST, ROLES_MEMBER } from '../../constants'
import { clearLocalStorageOldData } from '../../util'

import ProjectsService from '../../services/projects.service'

import { Wrapper } from './style'
import { useGlobalStore } from '../../store'


const Projects = () => {
  // const { enqueueSnackbar } = useSnackbar()
  const { user, setLoadAllLhs, setPPTSubLhs, setPPTSubLhsAppendices, setPPTSubLhsProgression, setShowLhsMenu, setAskIaActiveState, setExportToPptObject } = useGlobalStore();
  const browseWidth = Math.round((window.screen.width - (110 + 60 + 50)) / 4);

  const [projectsLoading, setProjectsLoading] = useState(false)
  const [projectsLoadingDetails, setProjectsLoadingDetails] = useState(false)
  const [gridRowOriginal, setGridRowOriginal] = useState([])
  const [gridRow, setGridRow] = useState([])

  const [gridColumns, setGridColumns] = useState([
    { field: 'Brand/Category', width: browseWidth, align: 'left', sortKey: 'BrandName', sort: null},
    { field: 'Project Type', width: browseWidth, align: 'left', sortKey: 'ProjectType', sort: null},
    { field: 'Market', width: browseWidth, align: 'left', sortKey: 'MarketName', sort: null},
    { field: 'Last Updated', width: browseWidth, align: 'left', sortKey: 'ModifiedDate', sort: null}
  ])

  const [accordionHeading, setAccordionHeading] = useState([
    { field: 'Brand/Category', sortKey: 'BrandName', data:[], filterIndex:[] },
    { field: 'Project Type', sortKey: 'ProjectType', data:[], filterIndex:[] },
    { field: 'Market', sortKey: 'MarketName', data:[], filterIndex:[] }
  ])

  const updateFilter = (obj={}) => {

    const heading = accordionHeading.map(a => {
      if( Object.keys(obj).includes(a.sortKey) ){
        a.data.map(d => {
          if(d[a.sortKey] === obj[a.sortKey]){
            d.checked = obj.checked
            if(obj.checked){
              a.filterIndex.push(d[a.sortKey])
            }
            else {
              const ind = a.filterIndex.indexOf(d[a.sortKey])
              a.filterIndex.splice(ind,1)
            }
          }
          return d
        })
      }
      return a
    })
    // setAccordionHeading(heading)

    let tempRow = []
    for(let r = 0; r<gridRowOriginal.length; r++){
      if (
        heading[0].filterIndex.includes(gridRowOriginal[r].BrandName) || 
        heading[1].filterIndex.includes(gridRowOriginal[r].ProjectType) || 
        heading[2].filterIndex.includes(gridRowOriginal[r].MarketName)
      ) {
        tempRow.push(gridRowOriginal[r])
      }
    }
    setGridRow(tempRow.length ? tempRow : gridRowOriginal)

    const gridColumn = gridColumns.map(c => {
      c.sort = null
      return c
    })
    setGridColumns(gridColumn)
    setAccordionHeading(heading)
  }

  const searchFilter = (searchValue) => {
    updateFilter()
    if (!!searchValue) {
      const searchData = gridRow.filter(s => (s.BrandName.match(searchValue) || s.ProjectType.match(searchValue) || s.MarketName.match(searchValue)))
      setGridRow(searchData)
    }
  }
  
  const gettingUniqueFilterCategoryData = () => {
    const heading = accordionHeading.map((a) => {
      a.data = gridRowOriginal.map(f => f[a.sortKey]).filter((value, index, self) => self.indexOf(value) === index).map(d => {return {[a.sortKey]:d, checked:false} })
      return a
    })
    setAccordionHeading(heading)
  }

  const getRowDetailData = (param) => {
    const dataLength = (gridRowOriginal.find(data => data.ProjectId === param.projectId)).details

    const applyRoles = (data) => {
      if(data?.length > 0){
        return data?.map(d => {
          if( d?.lhs?.length > 0 && [ROLES_JUNIOR_ANALYST].includes(user.UserType) ){
            d.lhs = d.lhs.filter(f => f.TabIcon === 'sandbox')
            d.href = `/reports/${d.lhs[0].TabIcon}/${d.lhs[0].StudyId}`
          }
          return d
        })
      }
      return data
    }

    if (dataLength.length === 0) {
      setProjectsLoadingDetails(true)
      ProjectsService.projectsDetailApi(param)
      .then((resp) => {
        const rowDataOriginal = gridRowOriginal.map(d => {
          if (d.ProjectId === param.projectId) { d.details = applyRoles(resp) }
          return d
        })
        setGridRowOriginal(rowDataOriginal)

        const rowData = gridRow.map(d => {
          if (d.ProjectId === param.projectId) { d.details = applyRoles(resp) }
          return d
        })
        setGridRow(rowData)

        const heading = accordionHeading.map(a => {
          a.data.map(d => {
            if(a.filterIndex.includes(d.BrandName)) {d.checked = true}
            return d
          })
          return a
        })
        setAccordionHeading(heading)

        setProjectsLoadingDetails(false)
      })
      .catch((error) => {
        const resMessage = error
        console.log('ERROR : ', resMessage)
        // enqueueSnackbar(resMessage, { variant: 'error' })
        setProjectsLoadingDetails(false)
      })
    }
  }

  useEffect(() => {
    if (gridRowOriginal?.length === 0 && user?.UserId) {
      clearLocalStorageOldData()
      setProjectsLoading(true)
      ProjectsService.projectsApi({user: user}, true)
      .then((resp) => {
        const data = resp.map(r => {
          r.details=[];
          r.BrandName = (r.BrandName === '') ? r.CategoryName : r.BrandName
          return r
        })
        const filteredData = data.filter(item => !item?.IsDraft);
        setGridRowOriginal(filteredData)
        setGridRow(filteredData)
        setProjectsLoading(false)
      })
      .catch((error) => {
        const resMessage = error
        console.log('ERROR : ', resMessage)
        setProjectsLoading(false)
      })
    }
  },[user])

  useEffect(() => {
    gettingUniqueFilterCategoryData()  
  },[gridRowOriginal])
  
  useEffect(() => {
    setLoadAllLhs([])
    setPPTSubLhs([])
    setPPTSubLhsAppendices([])
    setPPTSubLhsProgression([])
    
    setShowLhsMenu('projectPage')

    window.localStorage.removeItem('AskIaData')
    setAskIaActiveState('none')
    
    setExportToPptObject({})
  }, [])
  
  // let memberAccess = (user.UserType === ROLES_MEMBER) ? user.CanInitiateNewProject : true
  let memberAccess = user.CanInitiateNewProject

  if( [ROLES_JUNIOR_ANALYST].includes(user.UserType) ){
    memberAccess = false
  }

  return (
    <PostLogin>
      <Wrapper>
        <Header title={NAME_PROJECTS}>
          {memberAccess && <Box id='BtnDraft'><MuiButton color='light' variant='contained' to={ROUTE_INITIATE_PROJECTS_DRAFTS}><img src={draft} width='24px' alt='' /> &nbsp; Draft</MuiButton></Box>}
          {memberAccess && <Box id='BtnInitiateNewProject'><MuiButton color='primary' variant='contained' to={ROUTE_INITIATE_PROJECTS}><img src={plusWhite} width='18px' alt='' /> &nbsp; Initiate New Project</MuiButton></Box>}
        </Header>

        {!!gridRowOriginal?.length && <Filter accordionHeading={accordionHeading} onChange={updateFilter} onSearch={searchFilter} />}
        <DataGridTable columns={gridColumns} rows={gridRow} loading={projectsLoading} loadingDetails={projectsLoadingDetails} sortingBy='' getRowDetailData={getRowDetailData} />

      </Wrapper>
    </PostLogin>
  )
}

export default Projects
