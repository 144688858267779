import { styled } from '@mui/material/styles'
import { Box, Typography } from '@mui/material'

export const OuterWrapper = styled(Box)(`
    display: flex;
    flex-direction: column;
    width: 100%;
    // padding: 0 30px;
`)

export const ChartsWrapper = styled(Box)(`
    display: flex;
    justify-content: space-between;
    width: 100%;
`)

export const ChartBarWrapper = styled(Box)(`
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 3px #F7F7F9 solid;
    justify-content: space-between;
`)

export const ChartBarHead = styled(Box)(`
    display: flex;
    flex-direction: column;
    background: #F7F7F9;
    padding: 15px 20px;

`)

export const LegendWrapper = styled(Box)(`
    display: flex;
    padding-top: 15px;
    > div {
        &:first-of-type{
            width: 50%;
            justify-content: start;
        }
        &:last-child{
            width: auto;
        }
        > div:first-of-type {
            margin-left: 0;
        }
    }
`)

export const ChartBarBody = styled(Box)(`
    display: flex;
    position: relative;
    padding: 15px 20px;
    background: #FFFFFF;
    justify-content: center;
    > img {
        width: 100%;
    }
    > div {
        margin: 0;
        padding-left: 50px;
    }
`)

export const ChartBarList = styled('ul')(`
    display: flex;
    flex-direction: column;
    // list-style: none;
    width: 100%;
    overflow: hidden;

    // min-height: 500px;
    justify-content: space-between;
    padding: 40px 0;
    > li {
        // margin: 25px 0;
        position: relative;
        width: 20%;
        display: flex;
        align-items: center;
        font-weight: 300;
        font-size: 10px;
        line-height: calc(15 / 10);
        text-align: end;
        justify-content: end;
        &:before{
            // content: '';
            position: absolute;
            left: calc(100% + 20%);
            width: 500%;
            height: 1px;
            border: 1px #D9D9D9 dashed;
        }
        &:last-child:before{
            border: 1px #D9D9D9 solid;
        }
    }
`)

export const Chart = styled(Box)(`
    display: flex;
    position: absolute;
    margin-left: 20%;
    flex-direction: column;
    height: 100%;
    width: 50px;
    bottom: 0;
    // padding: 45px 0;
    justify-content: end;
    overflow: hidden;

    padding: 60px 0;
`)

export const ChartBar = styled(Box)(props => `
    width: 100%;
    height: ${props?.DataProp?.height}%;
    background: ${props?.DataProp?.background};
    border-top: 1px white solid;
    border-bottom: 1px white solid;
    display: flex;
    justify-content: center;
    > span {
        width: 100%;
        text-align: center;
        padding-top: 6px;
        font-weight: 500;
        font-size: 10px;
        line-height: calc(15 / 10);
        color: white;
    }
`)

export const ChartBarTitleWrapper = styled(Box)(`
    display: flex;
    > div {
        display: flex;
        flex-direction: column;
        width: 50%;
    }
`)

export const ChartBarTitle = styled(Typography)(`
    display: flex;
    text-transform: capitalize;
    font-weight: 300;
    font-size: 14px;
    line-height: calc(21 / 14);
    text-transform: capitalize;
`)

export const ChartBarCount = styled(Typography)(`
    display: flex;
    font-weight: 500;
    font-size: 20px;
    line-height: calc(30 / 20);
`)

export const ChartContent = styled(Box)(`
    padding: 40px 0;
    > div {
        width: 100%;
        > p {
            display: flex;
            flex-direction: column;
            font-size: 12px;
            line-height: calc(18 / 12);
            > span {
                font-weight: 500;
            }
        }
        > ul {
            display: flex;
            flex-direction: column;
            width: 100%;
            list-style: disc;
            padding-left: 25px;
            font-size: 12px;
            line-height: calc(18 / 12);
            > li {
                // display: flex;
                align-items: center;
                position: relative;
                width: 100%;
                &:before{
                    // content:'.';
                    position: absolute;
                    width: 10px;
                    height: 10px;
                }
                > span {
                    font-weight: 500;
                    padding-right: 5px;
                }
            }
        }
    }
`)
