import React, { useState, useEffect, useRef } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import ReportsService from '../../../../../services/reports.service'

import { Modal, Rte, MuiButton, MuiTextField } from '../../../../../components'
// import { IconButton } from '@mui/material'
import { IconEdit } from '../../../../../assets/images/icons'
import { Box } from '@mui/material'

import { LoginButton, Title, Wrapper, RteOuterWrapper, RteWrapper } from './style'

const ReportEditCategoryPersona = (props) => {
  const {InsightsElementId, pageData, onSuccess, title} = props
  const form = useRef()
  const [anchorElUser, setAnchorElUser] = useState(null)
  const [showEditHeaderModal, setShowEditHeaderModal] = useState(false)

  const [loader, setLoader] = useState(false)
  const [descriptionValue, setDescriptionValue] = useState(pageData.PersonaDescription)
  const [opportunitiesValue, setOpportunitiesValue] = useState(pageData.PersonaOpportunities)

  const handleOnEditHeader = () => {
    setAnchorElUser(null)
    setShowEditHeaderModal((prev)=>!prev)
  }

  const onSubmit = (e) => {
    setLoader(true)
    const updatedValues = {
      insightsElementId : InsightsElementId,
      personaDescription : descriptionValue,
      personaOpportunities : opportunitiesValue,
      personaPropertiesHeading : e?.propertiesHeadingValue || '',
      personaBehaviorsHeading : e?.behaviorsHeadingValue || '',
    }
    
    ReportsService.SetPersonaDescOppPropHeadBehHead(updatedValues)
    .then((resp) => {
      setLoader(false)
      setShowEditHeaderModal(false)
      onSuccess()
    })
    .catch((error) => {
      setLoader(false)
      const resMessage = error
      console.log('ERROR : ', resMessage)
    })
  }

  const formik = useFormik({
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: {
      descriptionValue: pageData?.PersonaDescription,
      opportunitiesValue: pageData?.PersonaOpportunities,
      propertiesHeadingValue: pageData?.PersonaPropertiesHeading,
      behaviorsHeadingValue: pageData?.PersonaBehaviorsHeading,
    },
    validationSchema: Yup.object({
      // propertiesHeadingValue: Yup.string().max(25).required(),
      // behaviorsHeadingValue: Yup.string().max(25).required()
    }),
    // validate: (values) => validation(values),
    onSubmit: (values) => {
      onSubmit(values)
    }
  })

  return (
    <>
      <MuiButton color='secondary' variant='outlined' startIcon={<IconEdit />} sx={{marginLeft:'15px', fontWeight:300}} onClick={() => setShowEditHeaderModal(true)}>Edit {title}</MuiButton>
      {showEditHeaderModal && <Modal title='' showClose={true} onClose={handleOnEditHeader}>
        <Wrapper>
          <form ref={form} onSubmit={formik.handleSubmit}>
            <Title variant='body1'>Edit {title}</Title>

            <RteOuterWrapper>
              <Box>
                <RteWrapper><Rte title='Description' value={formik.values.descriptionValue} onChange={(e) => setDescriptionValue(e)} height={400} /></RteWrapper>
                <RteWrapper><Rte title='Opportunities' value={formik.values.opportunitiesValue} onChange={(e) => setOpportunitiesValue(e)} height={400} /></RteWrapper>
              </Box>
              <Box>
                <RteWrapper>
                  <MuiTextField
                    error={Boolean(formik.touched.propertiesHeadingValue && formik.errors.propertiesHeadingValue)}
                    fullWidth
                    title='Properties Heading'
                    name='propertiesHeadingValue'
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type='text'
                    value={formik.values.propertiesHeadingValue}
                  />
                </RteWrapper>
                <RteWrapper>
                  <MuiTextField
                    error={Boolean(formik.touched.behaviorsHeadingValue && formik.errors.behaviorsHeadingValue)}
                    fullWidth
                    title='Behaviors Heading'
                    name='behaviorsHeadingValue'
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type='text'
                    value={formik.values.behaviorsHeadingValue}
                  />
                </RteWrapper>
              </Box>
        
            </RteOuterWrapper>

            <LoginButton
              color='primary'
              variant='contained'
              type='submit'
              onClick={formik.handleSubmit}
              // onClick={onSubmit}
              disabled={loader || false}
            >
              Submit
            </LoginButton>
          </form>
        </Wrapper>
      </Modal>}
    </>
  )
}

export default ReportEditCategoryPersona
