import {ApiPost} from '../util/axios'

const setObservationsApi = async (payload) => {
  const options = {
    reqId: 'SetInsightsElementObservation',
    payload: payload,
    resId: 'SetInsightsElementObservationResponse',
    resType: 'object'
  }

  return await ApiPost(options).then( (res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const setorDeleteInsightsElementObservation = async (payload) => {
  const options = {
    reqId: 'SetorDeleteInsightsElementObservation',
    payload: payload,
    resId: 'SetorDeleteInsightsElementObservationResponse',
    resType: 'object'
  }

  return await ApiPost(options).then( (res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const getObservationsApi = async (payload, getLatest) => {
  const options = {
    reqId: 'GetInsightsElementObservations',
    payload: payload,
    resId: 'GetInsightsElementObservationsResult',
    resType: 'array',
    getLatest: getLatest
  }

  return await ApiPost(options).then( (res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}


export default { 
  getObservationsApi,
  setObservationsApi,
  setorDeleteInsightsElementObservation
}
