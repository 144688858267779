import { styled } from '@mui/material/styles'
import { Box, IconButton } from '@mui/material'
import { MEDIA } from '../../../constants';

export const PoweredByWrap = styled(Box)(`
  position: absolute;
  z-index: 4;
  left: 15px;
  top: 100px;
 
  & .text {
    font-size: 8px;
    line-height: 2px;
  }
`);

export const RhsWrapper = styled(Box)(`
  justify-content: space-between;
  display: flex;
  width: 100%;
  min-height: 60px;
  // padding: 0 30px;
  align-items: center;
  & img {
    margin-left: 20px;
    max-height: 45px;
    max-width: 200px;
    // filter: brightness(0) invert(1);
  }
  
  @media (max-width: ${MEDIA.lg}) {
    width: auto;
    margin-left: auto;
    padding: 0 20px;
    min-height: 60px;
    & img {
      max-width: 100px;
      height: auto;
    }
    & .brandLogo {
      display:none;
    }
  }
  & .headerLogo{
    display: flex;
  }
`)

export const DashboardNavbarRoot = styled(Box)(`
  background-color: #000;
  position: static;
  border-bottom: 1px solid #EEEEEE;
  display: flex;
  align-items: center;
  height: 60px;
`)

export const LogoWrap = styled(Box)(`
  display: flex;
  align-items: center;
  justify-content: center;
  & img {
    height:50px;
    max-width:200px;
  }
`);

export const LogoDiv = styled(Box)(`
  width: 60px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  & img{
    width:45px;
    height:50px;
  }
  & a {
    width:45px;
    height:50px;
    // padding-left:5px;
  }
  @media (max-width: ${MEDIA.lg}) {
    box-shadow: none;
    background: transparent;
  }
`)

export const HamburgerWrap = styled(IconButton)(`
  margin-right: 5px;
  margin-left: -10px;
  display: none;
  width: 40px;
  height: 40px;
  @media (max-width: ${MEDIA.lg}) {
    display: block;
  }
`)

export const LHSWrap = styled(Box)(`

  & .brandLogo {display:none; padding-left:10px; }

  @media (max-width: ${MEDIA.lg}) {
    display: flex;
    align-items: center;
    padding: 0 20px;

    & .brandLogo {display:flex;}
    & .brandLogo img {max-height:43px; margin-left:0;}

    & .headerLogo{
      // padding: 0;
      // margin-top: -13px;
      // min-width:75px;
      // width:75px;
      
      & img {
        // width: 50px;
      }

      & .poweredByWrap{
        left: 0;
        top: 63px;
        z-index:1;
        display: flex;
        flex-direction: column;
      }
      & .poweredByWrap p {
        padding:0px 0 5px;
      }
      & .poweredByWrap img {
        width: 50px;
        height: auto;
      }
    }
  }
`);