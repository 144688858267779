import React, { useEffect, useState } from "react";
import { MuiSelect } from "../../../../components";
import reportsService from "../../../../services/reports.service";

const SubAttributeName = ({ title="Sub Attribute", name="subAttributeName", insightsElementId, onChange, entityName, driverName, attributeName, value, isShadow, ...rest }) => {
  const [state, setstate] = useState([]);

  const getSandboxSubAttributeNamesElementHandler = async () => {
    try {
      const resp = await reportsService.getSandboxSubAttributeNamesElement({
        insightsElementId: insightsElementId,
        entityName,
        driverName,
        attributeName
      });
      const data = resp.map((item) => ({
        label: item,
        value: item,
      }));
      const filteredData = data.filter(item => !!item.value)
      if (!filteredData?.length) return;
      setstate([
        { label: '', value: '' },
        ...filteredData
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSandboxSubAttributeNamesElementHandler();
  }, [entityName, driverName, attributeName]);

  return (
    <MuiSelect
      name={name}
      onChange={(e) => onChange(name, e.target.value)}
      title={title}
      data={state}
      compact
      value={value || null}
      placeholder="Select"
      variant={isShadow ? "outlined" : "standard"}
      selectlabel="label"
      selectvalue="value"
      {...rest}
    />
  );
};

export default SubAttributeName;