import React from 'react'
import { ChartBarHorizontalCustom, EmojiRow } from '../../../../components'
import { BarChartsWrapper, BrandMagnetScoreWarningWrap, ChartRow, RowName, RowSubName, ProgressionStyling } from './style'

const BrandMagnetScoreWarningArray = ({ rows = [], data = [] }) => {
  return (
    <BrandMagnetScoreWarningWrap className='brandMagnetScoreWarningWrap'>
      <BarChartsWrapper className='barChartsWrapper'>
        {rows.map((rowItem, index) => {
          return (
            <ChartRow key={index} className='brandMagnetScoreRowProgression'>
              <RowName>{rowItem.title}</RowName>

              {data.map((d, index) => {
                const obj = d.data.filter(f => f.title === rowItem.title).map(d => {
                  return {value: d.BrandMagnetScore, barData: [{ Warning: d?.BrandMagnetScore }]}
                })?.[0] || {}
                if(!obj?.barData){return false}

                return (<ProgressionStyling key={index}>
                  <RowSubName>{d.title}</RowSubName>
                  <ChartBarHorizontalCustom
                      data={obj.barData}
                      // height="20px"
                      key={index}
                      showInside
                      maxWidth='inside'
                      valueInPercent={false}
                      endIcon={<EmojiRow number={obj.value} />}
                      maxPercentRatio={100}
                    />
                </ProgressionStyling>)
              })}
            </ChartRow>
          )
        })}
        <EmojiRow style={{ justifyContent: 'space-between' }} type='multi' />
      </BarChartsWrapper>
    </BrandMagnetScoreWarningWrap>)
}

export default BrandMagnetScoreWarningArray;