import { useGlobalStore } from '../store'

const useUpdateLhs = () => {
    const { setSubLhsApiLoaded, isPPTModalActive } = useGlobalStore()
    const updateSubLhsApiLoaded = (id) => {
        if(isPPTModalActive){
            const updatingSubLhs = {['id-'+id.InsightsElementId]:true}
            setSubLhsApiLoaded(updatingSubLhs)
        }
    }
    return {updateSubLhsApiLoaded}
}

export {useUpdateLhs}