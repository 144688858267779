import React, { useState, useEffect } from 'react'
import { ReportHeading, LoaderWrapper, ReportOuterWrapper } from '../../../../components'
import ReportsService from '../../../../services/reports.service'
import { useUpdateLhs } from '../../../../hooks/useUpdateLhs'
import { CardOuterWrapper } from './style'
import RelativeBrands from './relativeBrands'
import { exportExcel_addPercentage } from '../../../../util'

const RelativeBrandsAnalysis = (props) => {
  const [loading, setLoading] = useState(false)
  const [graphPieData, setGraphPieData] = useState([])
  const [reportExcelData, setReportExcelData] = useState()
  const [eventCount, setEventCount] = useState(1)
  const {updateSubLhsApiLoaded} = useUpdateLhs()

  const loadStudySetup = () => {
    setLoading(true)

    if(props.InsightsElementType === 'SegmentOverview'){
      ReportsService.getSegmentOverviewElement({ insightsElementId: props.InsightsElementId })
        .then((resp) => {
          const pieData = resp.map(d => { return {name: d.SegmentName, value: d.SegmentValue} })
          const checkPieTotal = pieData.map(d => d.value).reduce((a, b) => (a + b), 0)
          if(checkPieTotal < 100){
            pieData.push({name: 'REMAINING_VALUE', value: 100-checkPieTotal})
          }

          setGraphPieData(pieData)
          setLoading(false)
          setTimeout(() => {
            updateSubLhsApiLoaded(props)
          }, 2000)
          // setReportExcelData(resp.map(d => { return [{value: d.SegmentName}, {value: d.SegmentValue}]} ))
          setReportExcelData({heading:['Segment Names'], values: resp.map(d => { return [{value: d.SegmentName}, exportExcel_addPercentage(d.SegmentValue)]} )})
        })
        .catch((error) => {
          const resMessage = error
          console.log('ERROR : ', resMessage)
          setLoading(false)
        })
    }

    if(props.InsightsElementType === 'AllRelativeBrandsAnalysis'){
      ReportsService.getRelativeBrandsAnalysis({ insightsElementId: props.InsightsElementId })
      .then((resp) => {
        const pieData = resp.map(d => { return {name: d.BrandName, value: d.BrandPercent} })
        setGraphPieData(pieData)
        setLoading(false)
        updateSubLhsApiLoaded(props)
        // setReportExcelData(resp.map(d => { return [{value: d.BrandName}, {value: d.BrandPercent}]} ))
        setReportExcelData({heading:['Brand Names'], values: resp.map(d => { return [{value: d.BrandName}, exportExcel_addPercentage(d.BrandPercent)]} )})
      })
      .catch((error) => {
        const resMessage = error
        console.log('ERROR : ', resMessage)
        setLoading(false)
      })
    }
  }

  useEffect(() => {
    if (graphPieData.length === 0) loadStudySetup()
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setEventCount(p => p+1)
    }, 2000)
  }, [graphPieData])

  return (
    <>
      <ReportHeading resetInsightHeight={eventCount} downloadId={props?.type} {...props} downloadIdData={reportExcelData} />
      <ReportOuterWrapper>
        <CardOuterWrapper id={props.type}>
          <LoaderWrapper loading={loading} loadOnlyApi={props.loadOnlyApi}>
            <RelativeBrands isExtraColor={props.isExtraColor} graphPieData={graphPieData} />
          </LoaderWrapper>
        </CardOuterWrapper>
      </ReportOuterWrapper>
    </>
  )
}

export default RelativeBrandsAnalysis
