import { Grid, Typography } from '@mui/material'
import React from 'react'
import { CampaignBarWrap } from './style'

const TextGrid = ({ row }) => {

  const data = [
    {
      label: 'Organic + Earned Interactions',
      value: parseInt(row.EarnedOrganicReach).toLocaleString()
    },
    {
      label: 'Paid interactions',
      value: parseInt(row.PaidReach).toLocaleString()
    },
    {
      label: 'Posts',
      value:  parseInt(row.Engagement).toLocaleString()
    },
    {
      label: 'News, blog posts and videos',
      value: parseInt(row.Coverage).toLocaleString()
    },
  ]
  
  return (
    <CampaignBarWrap container wrap='wrap' sx={{height: '100%'}}>
      {
        data.map(item => {
          if(+item?.value === 0) return false
          return (
            <Grid className='barGridItem' key={item.label} item sm='6'>
              <Typography sx={{
                fontSize: '14px',
                fontWeight: '300'
              }}>{item.label}</Typography>
              <Typography sx={{
                fontSize: '20px',
                fontWeight: '500',
                marginTop: '8px'
              }}>{item.value}</Typography>
            </Grid>
          )
        })
      }
    </CampaignBarWrap>
  )
}

export default TextGrid