const data = [
    {
        type: 'answer',
        content: [
            {text: "Hello, I'm your Insights Assistant! How can I help you today?"},
            {text: "Ask me a question, or pick a suggestion."}
        ],
        showCopy:false
    },
    // {
    //     type: 'question',
    //     content: [
    //         {text: 'What are the top Haircare Category Drivers and Comparison? What are the top Haircare Category Drivers and Comparison?'},
    //     ]
    // },
    // {
    //     type: 'answer',
    //     content: [
    //         {text: 'Pricing registers 37% engagement, with 21% positive and 14% negative.'},
    //         {text: 'This indicates that pricing is an important factor for consumers when it comes to cleansers.'},
    //         {text: 'The engagement level is relatively high compared to other factors such as safety'},
    //         {text: 'product sensorial, and skin type compatibility. However, the percentage of positive engagement is slightly lower than negative engagement'},
    //         {text: 'suggesting that while pricing is a consideration'},
    //         {text: 'it may not be the primary driver for purchasing decisions in this category.'},
    //     ],
    //     insights: [
    //         // {InsightsElementId:'0e4283d8-5688-11ed-817c-0a1ea4c5532f', InsightsElementType:'BusinessObjective', title:'Business Objective'},
    //         // {InsightsElementId:'3bc5245a-5688-11ed-817c-0a1ea4c5532f', InsightsElementType:'BrandHealthSetup', title:'Brand Health Setup'},
    //         // {InsightsElementId:'6308f1db-89d3-11ee-9f55-0a1ea4c5532f', InsightsElementType:'BrandAttributeMapElement', title:'Brand Attribute Map Element'},
    //         {InsightsElementId:'9e25c7b6-7b7b-11ed-817c-0a1ea4c5532f', InsightsElementType:'SegmentOverview', title:'Segment Overview'}
    //     ]
    // },
    // {
    //     type: 'question',
    //     content: [
    //         {text: 'What are the top Haircare Category Drivers and Comparison?'}
    //     ]
    // }
]

export default data