import React, { useEffect, useState } from 'react';
import ReactExport from 'react-data-export';
import { Download } from '../../../../assets/images/icons';
import { MuiButton } from '../../../../components';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const DownloadExcel = ({ data, breadCrumb }) => {
    const [state, setState] = useState([])

    useEffect(() => {
        let breadCrumbData = breadCrumb?.entityName
        
        if(breadCrumb?.driverName?.trim()) {
            if(breadCrumb?.entityName?.trim()) { breadCrumbData += ' > ' }
            breadCrumbData += breadCrumb?.driverName
        }
        if(breadCrumb?.attributeName?.trim()) {
            if((breadCrumb?.driverName || breadCrumb?.entityName)?.trim()){ breadCrumbData += ' > ' }
            breadCrumbData += breadCrumb?.attributeName
        }
        if(breadCrumb?.searchText?.trim()) {
            if((breadCrumb?.driverName || breadCrumb?.entityName || breadCrumb?.attributeName)?.trim()){ breadCrumbData += ' > ' }
            breadCrumbData += breadCrumb?.searchText
        }

        const headingEntityName = []
        const entityName = (item) => {
            return (`${item.EntityName}, ${item.VerbatimAdditionalInfo}`).split(',').map((v, i) => {
                headingEntityName.push({ title: `Property ${i+1}`, width: { wch: 30 }  })
                return { value: v, width: { wch: 30 }  }
            })
        }

        const mappedData = data.map(item => {
            if(item.VerbatimSentiment === 'Positive'){
                return ([
                    { value: `+ ${item.VerbatimText}`, style: { font: { sz: "12", color: { rgb: "008000" }} } },
                    ...entityName(item)
                    // { value: `${item.EntityName}, ${item.VerbatimAdditionalInfo}`}
                ])
            }
            else if(item.VerbatimSentiment === 'Negative'){
                return ([
                    { value: `- ${item.VerbatimText}`, style: { font: { sz: "12", color: { rgb: "ff0000" }} } },
                    ...entityName(item)
                    // { value: `${item.EntityName}, ${item.VerbatimAdditionalInfo}`}
                ])
            }
            else if(item.VerbatimSentiment === 'Mixed'){
                return ([
                    { value: `~ ${item.VerbatimText}`, style: { font: { sz: "12", color: { rgb: "000000" }} } },
                    ...entityName(item)
                    // { value: `${item.EntityName}, ${item.VerbatimAdditionalInfo}`}
                ])
            }
            else if(item.VerbatimSentiment === 'Neutral'){
                return ([
                    { value: `  ${item.VerbatimText}`, style: { font: { sz: "12", color: { rgb: "000000" }} } },
                    ...entityName(item)
                    // { value: `${item.EntityName}, ${item.VerbatimAdditionalInfo}`}
                ])
            }
       })

        setState([
            {
                columns: [
                    { title: breadCrumbData, width: { wch: 180 } },
                    { title: "", width: { wch: 30 } }
                ],
                data: []
            },
            {
                columns: [
                    { title: "Verbatim", width: { wch: 180 } },
                    { title: "Entity Name", width: { wch: 30 } },
                    ...headingEntityName
                ],
                data: mappedData
            }
        ])
    }, [data])

    return (
        <ExcelFile element={<DownloadButton />}>
            <ExcelSheet dataSet={state} name="Organization" />
        </ExcelFile>
    );
}

export default DownloadExcel;



const DownloadButton = () => {
  return  <MuiButton className='BtnExportToExcel' variant='standard' color='primary' sx={{color:'#095BBD'}}><Download /> &nbsp; Export</MuiButton>
}