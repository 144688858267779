import React from 'react'
import PostLogin from '../../layout/postLogin'
import { Header, MuiButton } from '../../components'
import { NAME_RESOURCES } from '../../constants'
import { data } from './__mock'
import { ListWrapper, List, Link } from './style'
import { getImageUrl } from '../../util'

const Resources = () => {
  return (
    <PostLogin>
      <Header title={NAME_RESOURCES} />
      <ListWrapper>
          {data?.map((li, i) => {
            const img = getImageUrl('resources', li.img, 'png')
              return (
                  <List key={i}>
                      <Link href={li.link} target='_blank'>
                          <img src={img} />
                          <p>{li.title}</p>
                      </Link>
                  </List>
              )
          })}
      </ListWrapper>
    </PostLogin>
  )
}

export default Resources
