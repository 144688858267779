import React, { useState, useEffect } from 'react'
import { Box } from '@mui/material'
import {
  ChartBarHorizontalCustom,
  D3Pie,
  ReportHeading,
  LoaderWrapper
} from '../../../../components'
import {
  OuterWrapper,
  PieChartWrapper,
  BarChartsWrapper,
  SentimentChartWrapper,
  SentimentChartLhs,
  SentimentChartRhs,
  ChartRow,
  PieChartHeading,
  PieChartBody,
  RowName
} from './style'
import ReportsService from '../../../../services/reports.service'
import { useUpdateLhs } from '../../../../hooks/useUpdateLhs'
import { exportExcel_addPercentage, exportExcel_addSegments  } from '../../../../util'


const ReportBrandHealthOverview = props => {
  // const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(true)
  const [rows, setRows] = useState([])
  const [pageFlag, setPageFlag] = useState()
  const [perPie, setPerPie] = useState()
  const [state, setState] = useState()
  const [pieChartSettings, setPieChartSettings] = useState()
  const {updateSubLhsApiLoaded} = useUpdateLhs()
  const [reportExcelData, setReportExcelData] = useState()

  const pieWrap = 310
  const pieRad = 100
  const coorXY = ((pieWrap - (pieRad * 2)) / 2)
  const pieSettings = {
    colors: ['#00B050', '#FF0000', '#ABABAB', '#F5BD4F'],
    width: pieWrap,
    height: pieWrap,
    cx: coorXY,
    cy: coorXY,
    outerRadius: pieRad,
    // innerRadius: 10,
    textPosition: 'outside',
    textLocation: 1.5,
    textSize: '20px',
    textShow: true
  }

  const loadBrandImagery = () => {
    setPageFlag(true)
    ReportsService.reportGetMagnetScoreApi({
      insightsElementId: props.InsightsElementId
    })
      .then(resp => {
        const maxPercents = {
          PositiveMax: resp?.map(r => (r?.PositivePercent)).reduce((prev, next) => prev + next, 0),
          MixedMax: resp?.map(r => (r?.MixedPercent)).reduce((prev, next) => prev + next, 0),
          NeutralMax: resp?.map(r => (r?.NeutralPercent)).reduce((prev, next) => prev + next, 0),
          NegativeMax: resp?.map(r => (r?.NegativePercent)).reduce((prev, next) => prev + next, 0)
        }
        maxPercents.total = maxPercents?.PositiveMax + maxPercents?.NegativeMax + maxPercents?.MixedMax + maxPercents?.NeutralMax
        setPerPie(maxPercents)

        const rowArray = resp?.map((r, i) => {
          return {
            entityName: r?.Entity?.EntityName,
            entityLogo: r?.Entity?.EntityLogo,
            mixedPercent: r?.MixedPercent,
            negativePercent: r?.NegativePercent,
            neutralPercent: r?.NeutralPercent,
            positivePercent: r?.PositivePercent,
            engagementIndex: r?.EngagementIndex,
            brandMagnetScore: r?.BrandMagnetScore
          }
        })
        setRows(rowArray)

        setLoading(false)
        updateSubLhsApiLoaded(props)
        setState(resp)
      })
      .catch(error => {
        const resMessage = error
        console.log('ERROR : ', resMessage)
        // enqueueSnackbar(resMessage, { variant: 'error' })
        setLoading(false)
      })
  }

  useEffect(() => {
    if (!pageFlag) {
      loadBrandImagery()
    }
  }, [])

  useEffect(() => {
    if(!!perPie){
      const pieChartSett = {
        data: [
          { name: 'Positive', value: (perPie?.PositiveMax / perPie?.total * 100) },
          { name: 'Negative', value: (perPie?.NegativeMax / perPie?.total * 100) },
          { name: 'Mixed', value: (perPie?.MixedMax / perPie?.total * 100) },
          { name: 'Neutral', value: (perPie?.NeutralMax / perPie?.total * 100) }
        ]
      }
      setPieChartSettings(pieChartSett)
    }
  }, [perPie])
 
  useEffect(() => {
    if(!!pieChartSettings && !!state){
      reportExcelDataObjFunc(state, pieChartSettings.data)
    }
  },[pieChartSettings, state])

  const reportExcelDataObjFunc = (resp, pieChartData) => {
    const reportExcelDataObj = []
    for(let r=0; r<resp.length; r++){
      reportExcelDataObj.push([{value: resp[r]?.Entity?.EntityName}, ...exportExcel_addSegments(resp[r]), {value: resp[r].EngagementIndex}])
    }

    reportExcelDataObj.push([{value:''}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''}])
    // reportExcelDataObj.push([{value:''}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''}])
    // reportExcelDataObj.push([{value:'Category Sentiment Split'}, {value:''}, {value:''}, {value:''}, {value:''}, {value:''}])
    // reportExcelDataObj.push([{value:'Positive'}, exportExcel_addPercentage(pieChartData[0].value), {value:''}, {value:''}, {value:''}, {value:''}])
    // reportExcelDataObj.push([{value:'Negative'}, exportExcel_addPercentage(pieChartData[1].value), {value:''}, {value:''}, {value:''}, {value:''}])
    // reportExcelDataObj.push([{value:'Mixed'}, exportExcel_addPercentage(pieChartData[2].value), {value:''}, {value:''}, {value:''}, {value:''}])
    // reportExcelDataObj.push([{value:'Neutral'}, exportExcel_addPercentage(pieChartData[3].value), {value:''}, {value:''}, {value:''}, {value:''}])

    reportExcelDataObj.push([
      {value:'Category Sentiment Split'},
      exportExcel_addPercentage(pieChartData[0].value),
      exportExcel_addPercentage(pieChartData[1].value),
      exportExcel_addPercentage(pieChartData[2].value),
      exportExcel_addPercentage(pieChartData[3].value),
      {value:0}
    ])

    setReportExcelData(reportExcelDataObj)
  }

  return (
    <>
      <ReportHeading downloadId={props.type} subHeading='' {...props} downloadIdData={reportExcelData} />
      <OuterWrapper id={props.type}>
        <Box className="dynamicRow" style={{ display: 'flex', width: '100%' }}>
          <LoaderWrapper loading={loading} loadOnlyApi={props.loadOnlyApi}>
            <PieChartWrapper>
              <PieChartHeading>Category <br/> Sentiment Split</PieChartHeading>
              <PieChartBody>
                {pieChartSettings && <D3Pie {...pieChartSettings} settings={pieSettings} />}
              </PieChartBody>
              <PieChartHeading></PieChartHeading>
            </PieChartWrapper>
            <BarChartsWrapper>
              <SentimentChartWrapper>
                <SentimentChartLhs>Sentiment Split</SentimentChartLhs>
                <SentimentChartRhs>
                  {[...rows].map((bar1, i) => {
                    return (
                      <ChartRow key={i}>
                        <RowName 
                          title={bar1?.positivePercent + bar1?.neutralPercent + bar1?.mixedPercent + bar1?.negativePercent}
                        >
                          {bar1?.entityName}
                        </RowName>
                        <ChartBarHorizontalCustom
                          maxWidth='75%'
                          valueInPercent
                          data={[{
                            Positive: bar1?.positivePercent,
                            Neutral: bar1?.neutralPercent,
                            Mixed: bar1?.mixedPercent,
                            Negative: bar1?.negativePercent
                          }]}
                          maxPercentRatio={1}
                          height='28px'
                        />
                      </ChartRow>
                    )
                  })}
                </SentimentChartRhs>
              </SentimentChartWrapper>

              <SentimentChartWrapper>
                <SentimentChartLhs>Consumer<br />Engagement Index</SentimentChartLhs>
                <SentimentChartRhs>
                  {[...rows].map((bar2, j) => {
                    return (
                      <ChartRow key={j}>
                        <RowName>{bar2?.entityName}</RowName>
                        <ChartBarHorizontalCustom
                          maxWidth='75%'
                          showToolTip={false}
                          showInside={false}
                          data={[{engagementIndex: bar2?.engagementIndex * 100}]}
                          valueInPercent={false}
                          bG='#4EADEA'
                          total={bar2?.engagementIndex}
                          maxPercentRatio={100}
                          height='28px'
                        />
                      </ChartRow>
                    )
                  })}
                </SentimentChartRhs>
              </SentimentChartWrapper>
            </BarChartsWrapper>
          </LoaderWrapper>
        </Box>
      </OuterWrapper>
    </>
  )
}

export default ReportBrandHealthOverview
