import React from 'react'
import { ChartBarHorizontalCustom } from '../../../../components'
import { BarWrap, SegmentRow, SegmentRowContent, SegmentWrap } from './style'

const CompetitionConsiderationTable = ({ data, maxValue=100 }) => {

	return (
		<SegmentWrap>
			{
				data.map((item, index) => {
					const isRating = item.BrandMeasurement === "Rating";
					return (
						<SegmentRow key={index} data-age={item.BrandName} className={'list'}>
							<SegmentRowContent>
							<BarWrap className={item?.type?.toLowerCase()}>
								<ChartBarHorizontalCustom
									showInside={false}
									bottomText={false}
									isDecimal={isRating}
									data={[{ "0": item.BrandPercent}]}
									valueInPercent={!isRating}
									total={item.BrandPercent}
									maxPercentRatio={isRating ? 5 : maxValue}
									maxWidth="100%"
								/>
							</BarWrap>
						</SegmentRowContent>
						</SegmentRow>
					)
				})
			}
		</SegmentWrap>
	)
}

export default CompetitionConsiderationTable