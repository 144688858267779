import React, { useRef, useEffect } from 'react'
import { Box, Menu, Divider } from '@mui/material'
import { LayoutHeaderMenu, CanWeHelp, MuiButton, MuiImage, TourGuide } from '../../../components'
import { getImageUrl } from '../../../util'
import { useGlobalStore } from '../../../store'
import { ROUTE_PROJECTS } from '../../../constants'
import { IconLogoAskIa } from '../../../assets/images/icons';
import HeaderLogo from '../../postLogin/header/headerLogo'
import Hamburger from '../../postLogin/header/hamburger'
import DownloadPpt from './download-ppt'
import PublishProject from './publish-project'
import { ROLES_PUBLISHER, ROLES_CLIENT_PRIMARY, ROLES_MM_PRIMARY, ROLES_CLIENT_SECONDARY } from '../../../constants'
import MoreVertIcon from '@mui/icons-material/MoreVert';

import {
  ToolbarStyled,
  DashboardNavbarRoot,
  Logo,
  ContentWrapper,
  BreadCrumb,
  Title,
  SubTitle,
  RhsWrapper,
  LastUpdated,
  DesktopMenu,
  MobileMenu,
  IsDummyAccount
} from './style'

export const Header = (props) => {
  const logoRef = useRef()
  const { breadCrumb, title, subTitle, lastUpdated } = props
  const { user, company, projectIdValue } = useGlobalStore();
  const logoPath = getImageUrl('logos', (company?.CompanyLogo), 'svg')
  const pptLogoPath = getImageUrl('ppt', (company?.CompanyLogo), 'png')
  // const logoPath = getImageUrl('logos', (getCompanyDetails.CompanyLogo), 'svg')
  // const logoPath = `${CONST_CLIENT_LOGOS}/${(getCompanyDetails.CompanyLogo).toLowerCase()}${CONST_CLIENT_LOGOS_EXTENSION}`

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [dummyAccount, setDummyAccount] = React.useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const isDummyAcc = company.CompanyId === 'e0804536-45e9-11ed-bca4-0a1ea4c5532f'
    setDummyAccount(isDummyAcc)
  }, []) 

  return (
    <DashboardNavbarRoot>
      {dummyAccount && <IsDummyAccount />}
      <HeaderLogo parentClass='desktopMenu' />
      <ToolbarStyled>
        <Box display="flex" className='lhsLogos'>
          <Hamburger />
          <HeaderLogo parentClass='mobileMenu' />
          <Logo ref={logoRef} className='brandLogo'><MuiImage src={logoPath} /></Logo>
        </Box>
        <HeaderProjectInfo breadCrumb={breadCrumb} title={title} subTitle={subTitle} isroot={false} />
        
        <RhsWrapper className={`userType_${[ROLES_PUBLISHER].includes(user.UserType)}`}>
          <Logo className='brandLogo'><MuiImage src={logoPath} /></Logo>
          
          <MobileMenu className='toggleMenu'>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
              <MoreVertIcon
                onClick={handleClick}
                size="small"
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
              >
              </MoreVertIcon>
            </Box>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  width: '230px',
                  mt: 1.5,
                  '& ul': {
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '10px'
                  },
                  '& ul button': {
                    margin: '5px 0 !important',
                    width: '100%'
                  },
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              {/* {ROLES_CLIENT_PRIMARY.includes(user.UserType) && <PublishProject {...props} userType={user.UserType} />} */}
              {ROLES_MM_PRIMARY.includes(user.UserType) && !projectIdValue.IsAskIAForStudy && <LogoAskIa {...props} />}
              {ROLES_CLIENT_SECONDARY.includes(user.UserType) && projectIdValue.IsAskIAForStudy && <LogoAskIa {...props} />}
              {ROLES_CLIENT_SECONDARY.includes(user.UserType) && <DownloadPpt {...props} logoDetails={{ logoImg: pptLogoPath, logoW: logoRef?.current?.offsetWidth, logoH: logoRef?.current?.offsetHeight }} />}
              {ROLES_CLIENT_SECONDARY.includes(user.UserType) && <TourGuide type='tutorialButton' />}
            </Menu>
          </MobileMenu>

          <DesktopMenu className='canWeHelpWrap'>
            {/* {ROLES_CLIENT_PRIMARY.includes(user.UserType) && <PublishProject {...props} userType={user.UserType} />} */}
            {ROLES_MM_PRIMARY.includes(user.UserType) && !projectIdValue.IsAskIAForStudy && <LogoAskIa {...props} />}
            {ROLES_CLIENT_SECONDARY.includes(user.UserType) && projectIdValue.IsAskIAForStudy && <LogoAskIa {...props} />}
            {ROLES_CLIENT_SECONDARY.includes(user.UserType) && <DownloadPpt {...props} logoDetails={{ logoImg: pptLogoPath, logoW: logoRef?.current?.offsetWidth, logoH: logoRef?.current?.offsetHeight }} />}
            {ROLES_CLIENT_SECONDARY.includes(user.UserType) && <TourGuide type='tutorialButton' />}
            {/* <CanWeHelp /> */}
          </DesktopMenu>
          <LayoutHeaderMenu />
          {/* {lastUpdated && <LastUpdated>Last Updated: {lastUpdated}</LastUpdated>} */}
        </RhsWrapper>
        
      </ToolbarStyled>
    </DashboardNavbarRoot>
  )
}

export const HeaderProjectInfo = ({ breadCrumb, title, subTitle, lastUpdated, isroot = true }) => {
  return (
    <ContentWrapper isroot={+!!isroot}>
      {/* {breadCrumb && <BreadCrumb><MuiButton to={ROUTE_PROJECTS}>Projects</MuiButton> / {breadCrumb}</BreadCrumb>} */}
      {title && <Title>{title}</Title>}
      {subTitle && <SubTitle>{subTitle}</SubTitle>}
      {/* {lastUpdated && <LastUpdated isroot={+!!isroot}>Last Updated: {lastUpdated}</LastUpdated>} */}
      {lastUpdated && <LastUpdated>Last Updated: {lastUpdated}</LastUpdated>}
    </ContentWrapper>
  )
}

const LogoAskIa = () => {
  const { isAskIaActiveState, setAskIaActiveState, setShowLhsMenu } = useGlobalStore()
  const askIaActiveStateFunc = () => {
    if(isAskIaActiveState === 'none'){
        setAskIaActiveState('active')
        setShowLhsMenu('reportPage_hideLhs')
    } else {
        // setAskIaActiveState('none')
    }
  }

  return (
    <Box className="BtnAskIA" style={{position:'relative'}}>
      <MuiButton
        variant='text'
        onClick={() => askIaActiveStateFunc()}
        sx={{height:'40px'}}
        // startIcon={<IconLogoAskIa />}
      ><IconLogoAskIa /></MuiButton>
    </Box>
  )
}