import React, { useRef, useState, useEffect } from 'react'
import { IconButton } from '@mui/material'
import { useParams } from "react-router-dom";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { LoaderWrapper, MuiTextField, MuiButton, TourGuide } from '../../../../components'
import GenerativeService from '../../../../services/generative.service'
import { useUpdateLhs } from '../../../../hooks/useUpdateLhs'
import { useGlobalStore } from '../../../../store'
import { getObjectAsArray } from '../../../../util'
import { GenerativeSearchIcon, IconArrowRight, IconClose, IconCopyButton, IconThumbsUpButton, IconThumbsDownButton } from '../../../../assets/images/icons'
import TypingEffect from './typingEffect'
import Suggestions from './suggestions'

import {
    OuterWrapper, InnerWrapper, ChatOuterWrapper, ChatInnerWrapper, ServerChatWrapper, ClientChatWrapper, ServerContentWrapper, RawData, ServerContentLoader, ClientContentWrapper, 
    SearchBoxOuterWrapper, SuggestionWrapper, SearchWrapper, SuggestionLink, SearchInputBox, SearchSubmit, LinksWrapper, IconCloseOuterWrapper, IconCloseWrapper, IconButtonWrapper, AllIconButton, StopTypingOverlay, StopTyping
} from './style'

import jsonData from './data'

const ReportGenerative = (props) => {
    const [pageData, setPageData] = useState()
    const [loader, setLoader] = useState(false)
    // const [reportsDetails, setReportsDetails] = useState({})
    const [chatData, setChatData] = useState([])
    const [typingStatus, setTypingStatus] = useState('typingDone')
    const [dataFromStorage, setDataFromStorage] = useState(false)
    const {setAskIaActiveState, setShowLhsMenu, isTourGuideActive } = useGlobalStore()
    const interval = useRef(null);
    const form = useRef()
    const { id } = useParams()

    const onSubmit = (data) => {
        if(loader) return false

        setLoader(true)
        let check2minsFlag = true

        formik.setFieldValue('userName', '')
        const question = {
            type: 'question',
            content: [{text: data.query}],
            status: 'new'
        }
        updateAskIaDataFunc([...chatData, question])

        setTimeout(() => {
            const wrap = document.querySelector('#AskIaWrapper')
            wrap.scrollTo(0, wrap.scrollHeight);
        }, 500)
        setTimeout(() => {
            document.getElementById('AskIaWrapper').scrollIntoView(false);
        },1000)


        localStorage.setItem('askIaLoading', JSON.stringify({flag:true, payload:{query:data.query, studyId:id}}))

        setTimeout(() => {
            GenerativeService.askQuestionApi({query:data.query, studyId:id})
            .then((resp) => {
                if(check2minsFlag){
                    check2minsFlag = false
                    if( JSON.parse(localStorage.getItem('askIaLoading')).flag ){
                        const respData = resp || null
                        if(respData?.file_names?.length > 0){
                            const insightsIds = (respData?.file_names?.map(d => {
                                const len = d.split('/').length - 1
                                const data = d.split('/')[len].split('.pdf')[0]
                                return data.split('_')[0]
                            }))
                            const insightsIdsArray = []
                            for(let i = 0; i<insightsIds.length; i++){
                                if(!insightsIdsArray.includes(insightsIds[i])){
                                    insightsIdsArray.push(insightsIds[i])
                                }
                            }

                            const replaceAdditionalText = (txt) => {
                                const d1 = (txt.indexOf('Answer:') !== -1) ? txt?.split('Answer:')[1] : txt
                                const d2 = (d1.indexOf('answer:') !== -1) ? d1?.split('answer:')[1] : d1
                                return d2
                            }

                            const payload = {studyId:id, insightsElementIds:insightsIdsArray.filter(f => f.indexOf('-') > 2 ).map(d => ({guid:d})) }

                            GenerativeService.getInsightsElementsApi(payload)
                            .then((respSoap) => {

                                const res1 = getObjectAsArray(respSoap)?.[0]
                                const res2 = getObjectAsArray(res1.InsightsElement)
                                const insightsArray = res2.map(i => ({
                                    InsightsElementId:i.InsightsElementId,
                                    InsightsElementType:i.InsightsElementType,
                                    title:i.Title,
                                    parentTitle:i.ParentTitle,
                                    EntityId:i.EntityId,
                                    StudyId:i.StudyId,
                                    TabId:i.TabId,
                                    type:'testing 1',
                                    status:''
                                }))

                                const ans = (replaceAdditionalText(respData?.answer) || '').split('\n')
                                const answer = {
                                    type: 'answer',
                                    raw: replaceAdditionalText(respData?.answer) || '',
                                    // content: [{text:'none'}, ...ans.map(a => ({text: a})) ],
                                    content: [...ans.map(a => ({text: a})) ],
                                    insights: insightsArray,
                                    showCopy: true,
                                    question: question,
                                    isLiked: null
                                }
                                updateAskIaDataFunc([...chatData, question, answer])
                                setDataFromStorage(false)
                                setLoader(false)
                            })
                            .catch((error) => {
                                setLoader(false)
                                const resMessage = error
                                console.log('ERROR : ', resMessage)
                            })

                            updateLogs(id, data.query, resp.answer)
                        // } else if(respData?.message){
                        //     const answer = {
                        //         type: 'answer',
                        //         raw: 'Please try again later or ask a different question.',
                        //         content: [{text: 'Please try again later or ask a different question.'}],
                        //         showCopy: false
                        //     }
                        //     updateAskIaDataFunc([...chatData, question, answer])
                        //     setDataFromStorage(false)
                        //     setLoader(false)
                        //     updateLogs(id, data.query, resp.message)
                        } else {
                            const answer = {
                                type: 'answer',
                                raw: 'Please try again later or ask a different question.',
                                content: [{text: 'Please try again later or ask a different question.'}],
                                showCopy: false
                            }
                            updateAskIaDataFunc([...chatData, question, answer])
                            setDataFromStorage(false)
                            setLoader(false)
                            const mess = isNaN(respData) ? respData?.message : 'timeout'
                            updateLogs(id, data.query, mess)
                        }
                    }
                }
            })
            .catch((error) => {
                if( JSON.parse(localStorage.getItem('askIaLoading')).flag ){
                    onSubmitCatch(chatData)
                }
            })
        }, 5000)

        setTimeout(() => {
            if(check2minsFlag){
                check2minsFlag = false
                onSubmitCatch(chatData)
            }
        }, 150000)
    }

    const onSubmitCatch = (chatData) => {
        const localData = JSON.parse(localStorage.getItem('askIaLoading'))
        const data = localData.payload
        const id = localData.payload.studyId

        const answer = {
            type: 'answer',
            raw: 'Please try again later or ask a different question.',
            content: [{text: 'Please try again later or ask a different question.'}],
            showCopy: false
        }

        updateLogs(id, data.query, answer.content)

        const logs = {
            studyId: id,
            question: data.query,
            answer: answer.content
        }
        GenerativeService.setStudyQuestionAnswerApi(logs)
        .then((resp) => {
        }).catch((error) => {
        })

        updateAskIaDataFunc([...chatData, data.query, answer])
        setLoader(false)
    }

    const updateLogs = (id, q, a, isLiked=null) => {
        const logs = { studyId: id, question: q, answer: a, isLiked: isLiked }
        GenerativeService.setStudyQuestionAnswerApi(logs)
        .then((resp) => {
        }).catch((error) => {
        })
    }

    const formik = useFormik({
        // validateOnChange: false,
        // validateOnBlur: false,
        initialValues: {
          userName: ''
        },
        validationSchema: Yup.object({
        //   userName: Yup.string().max(25).required('Keyword / Search is required'),
        }),
        onSubmit: (values) => {
          if(!loader){
            const updatedValues = {
                query: values.userName,
            }
            onSubmit(updatedValues)
            }
        }
    })

    const showReport = (d) => {
        if(typingStatus !== 'typingDone' && chatData?.length > 1) return false;
        setAskIaActiveState(`${d.InsightsElementId}___${d.InsightsElementType}___${d.title}___${d.EntityId||'none'}`)
    }

    const typingChanged = (d, index) => {
        if(d === 'typingStarted' && chatData[index].status !== 'typingStarted'){
            setTypingStatus(d)
        }
        else if(d === 'typingDone'){
            setTypingStatus(d)
            const chatDataTemp = chatData;
            chatDataTemp[index].status = d;
            updateAskIaDataFunc(chatDataTemp)

            const typeInsightsArr = document.querySelectorAll('.typeInsights')
            for(let i=0; i<typeInsightsArr.length; i++){
                typeInsightsArr[i].classList.add('show');
            }
        }
        setTimeout(() => {
            document.getElementById('AskIaWrapper').scrollIntoView(false);
        }, 500)
    }
    
    const closeAskIaDetailPage = () => {
        setAskIaActiveState('none')
        setShowLhsMenu('reportPage_showLhs')
    }

    const updateAskIaDataFunc = (data=jsonData) => {
        localStorage.setItem('AskIaData', JSON.stringify(data))
        setChatData(data)
        setTypingStatus('typingDone')
    }

    const copyButtonClicked = (id) => {
        document.getElementById(id).classList.add('copied');
        setTimeout(() => {
            document.getElementById(id).classList.remove('copied');
        }, 2000)
    }

    const likeDislikeButtonClicked = (data, like) => {
        console.log('data, like -------- ', data, like)
        updateLogs(id, data.question.content[0].text, data.raw, like)

        const localStorageArray = JSON.parse(localStorage.getItem('AskIaData')).map(d => {
            if(data.raw === d.raw){ d.isLiked = like }
            return d
        })
        updateAskIaDataFunc(localStorageArray)
    }

    const stopLoadingFunc = () => {
        const payload = JSON.parse(localStorage.getItem('askIaLoading')).payload
        localStorage.setItem('askIaLoading', JSON.stringify({flag:false, payload:payload}))
        setLoader(false)
        onSubmitCatch(chatData)
    }

    const stopTypingFunc = () => {
        clearInterval(interval.current)
        setTypingStatus('typingStop')

        const baseId = document.querySelector('#AskIaWrapper .answerWrap:last-of-type')
        const contentArray = []
        const data1 = baseId.querySelectorAll('p')
        let copyData = ''

        for(let i=0; i<data1.length; i++){
            if(data1[i].classList.contains('typing_pending')){
                data1[i].classList.add('typing_deleted')
                data1[i].classList.remove('typing_pending')
            }
            else if(data1[i].querySelector('span')){
                if(data1[i].classList.contains('typing')){
                    data1[i].classList.remove('typing')
                }
                const d = data1[i].querySelector('span').innerHTML
                contentArray.push({text:d}) // json
                copyData = copyData + d // copy data
            }
        }

        const askIaData = JSON.parse(localStorage.getItem('AskIaData')) // AskIaData
        askIaData[askIaData.length-1].content = contentArray
        askIaData[askIaData.length-1].raw = copyData.replace('Answer:','')
        askIaData[askIaData.length-1].status = 'typingDone'
        askIaData[askIaData.length-1].isLiked = null

        const wrap = document.querySelector('#AskIaWrapper')
        wrap.classList.add('stopTypingLoader');
        wrap.scrollTo(0, wrap.scrollHeight);
        // setChatData(askIaData)
        setTimeout(() => {
            updateAskIaDataFunc(askIaData)
            // localStorage.setItem('AskIaData', JSON.stringify(askIaData))
            // setChatData(askIaData)
            // const wrap = document.querySelector('#AskIaWrapper')
            // wrap.scrollTo(0, wrap.scrollHeight);
            
            setTimeout(() => {
                wrap.classList.remove('stopTypingLoader');
            },500)
            
            setTimeout(() => {document.getElementById('AskIaWrapper').scrollIntoView(false);}, 500)
        },2000)
    }

    const suggestionClickedFunc = (str) => {
        onSubmit({ query: str })
        setTimeout(() => {document.getElementById('AskIaWrapper').scrollIntoView(false);}, 500)
    }

    const onEnterPress = (e) => {
        setTimeout(() => {document.getElementById('AskIaWrapper').scrollIntoView(false);}, 500)
        if(e.charCode == 13 && e.shiftKey == false) {
            e.preventDefault();
            formik.handleSubmit();
        }
    }

    useEffect(() => {
        document.getElementById("userName").focus();
        // document.getElementById('AskIaWrapper').scrollTo(0, 5000);
        setTimeout(() => {
            document.getElementById('AskIaWrapper').scrollIntoView(false);
        },2000)
    }, [])

    useEffect(() => {
        const askIaData = JSON.parse(localStorage.getItem('AskIaData'))
        if(askIaData?.length > 0){
            setDataFromStorage(true)
            setChatData(askIaData)
            setTypingStatus('typingDone')

            document.getElementById('AskIaWrapper').scrollIntoView(false);
            document.getElementById('AskIaWrapper').scrollTo(0, 5000);
        } else {
            updateAskIaDataFunc()
        }
    }, [])

  return (
    <>
        <OuterWrapper>
            <IconCloseOuterWrapper><IconCloseWrapper onClick={() => closeAskIaDetailPage()}><IconClose /></IconCloseWrapper></IconCloseOuterWrapper>
            <InnerWrapper>
                <ChatOuterWrapper id='AskIaOuterWrapper'>
                    <ChatInnerWrapper id='AskIaWrapper'>

                        {chatData.map((d, index) => {
                            if(d.type === 'answer'){
                                return (
                                    <ServerChatWrapper className='answerWrap' key={index}>
                                        <ServerContentWrapper>
                                            <TypingEffect onChange={e => typingChanged(e, index)} typingStatus={typingStatus} typingId={index} interval={interval}>
                                                {d.content.map((c, j) => {
                                                    let isPending = (chatData.length-1 === index && !dataFromStorage) ? 'typing_pending' : ''
                                                    return (<p key={j} className={isPending}>{c.text}</p>)
                                                })}
                                                {d?.insights && <LinksWrapper className={`typeInsights ${d.status==='typingDone' ? 'show' : ''} ${(typingStatus !== 'typingDone' && chatData?.length > 1) ? 'disable' : '' }`}>
                                                    <h4>Here are some resources you might find useful:</h4>
                                                    {d.insights.map((e, j) => {
                                                        return (<LinkWithArrow key={j} title={`${e.parentTitle} ${e.title}`} onClick={()=>{ showReport(e) }} />)
                                                    })}
                                                </LinksWrapper>}
                                            </TypingEffect>

                                            {d.showCopy && d.status==='typingDone' && <IconButtonWrapper>
                                                <AllIconButton id={`idThumbsUpButton_${index}`} className={`btnThumbsUp isLiked_${d.isLiked}`} color='primary' onClick={() => likeDislikeButtonClicked(d, true)}><IconThumbsUpButton isClicked={d.isLiked} /></AllIconButton>
                                                <AllIconButton id={`idThumbsDownButton_${index}`} className={`btnThumbsDown isLiked_${d.isLiked}`} color='primary' onClick={() => likeDislikeButtonClicked(d, false)}>{d.isLiked===null ? <IconThumbsDownButton isClicked={false} /> : <IconThumbsDownButton isClicked={!d.isLiked} />}</AllIconButton>
                                                <AllIconButton id={`idCopyButton_${index}`} className='btnCopy' color='primary' onClick={() => copyButtonClicked('idCopyButton_'+index)}>
                                                    <IconCopyButton />
                                                    <CopyToClipboard text={d.raw}><span>{d.raw}</span></CopyToClipboard>
                                                </AllIconButton>
                                            </IconButtonWrapper>}
                                            {d.showCopy && d.status==='typingDone' && <IconButtonWrapper className={`isLiked_${d.isLiked}`}>
                                                {d.isLiked && <>Thanks for your feedback!</>}
                                                {d.isLiked === false && <>Apologies for the error in the answer. We’re on it and will rectify it as soon as possible.</>}
                                            </IconButtonWrapper>}

                                        </ServerContentWrapper>
                                    </ServerChatWrapper>
                                )
                            }
                            else if(d.type === 'question'){
                                return (
                                    <ClientChatWrapper className='questionWrap' key={index}>
                                        <ClientContentWrapper>
                                            {d.content.map((c, j) => {
                                                return (
                                                    <p key={j} className='notType'>{c.text}</p>    
                                                )
                                            })}
                                        </ClientContentWrapper>
                                    </ClientChatWrapper>
                                )
                            }
                        })}
                        
                        {loader && <ServerChatWrapper className='loaderWrapper'>
                            <ServerContentWrapper className='loader'>
                                <ServerLoader />
                            </ServerContentWrapper>
                        </ServerChatWrapper>}
                    </ChatInnerWrapper>
                </ChatOuterWrapper>

                <SearchBoxOuterWrapper>
                    {loader && <StopTyping onClick={() => stopLoadingFunc()} variant="contained">Stop Responding</StopTyping>}
                    {typingStatus !== 'typingDone' && chatData?.length > 1 && <StopTyping onClick={() => stopTypingFunc()} variant="contained">Stop Responding</StopTyping>}
                    {/* {typingStatus !== 'typingDone' && chatData?.length > 1 && <StopTypingOverlay onClick={() => stopTypingFunc()} />} */}
                    <Suggestions disabledLinks={loader || typingStatus !== 'typingDone'} parentLoader={loader} typing={typingStatus} suggestionClicked={(d) => suggestionClickedFunc(d) } />

                    <SearchWrapper>
                        <GenerativeSearchIcon />
                        <form ref={form} onSubmit={formik.handleSubmit}>
                            <SearchInputBox>
                                <MuiTextField
                                    id='userName'
                                    fullWidth
                                    name='userName'
                                    onChange={formik.handleChange}
                                    type='text'
                                    value={formik.values.userName}
                                    variant='standard'
                                    multiline
                                    placeholder='Ask me anything about the study...'
                                    disabled={loader || typingStatus !== 'typingDone'}
                                    rows={2}
                                    onKeyPress={e => onEnterPress(e)}
                                />
                            </SearchInputBox>
                            {( (!loader) && formik.values.userName.length > 2) ? <SearchSubmit>
                                <MuiButton
                                    type='submit'
                                    disabled={loader || false}
                                    onClick={formik.handleSubmit}
                                />
                            </SearchSubmit> : <SearchSubmit className='disabled'></SearchSubmit>}
                        </form>
                    </SearchWrapper>
                </SearchBoxOuterWrapper>
            </InnerWrapper>
            {isTourGuideActive && <TourGuide type='askIa' />}
        </OuterWrapper>
    </>
  )
}

export default ReportGenerative

const LinkWithArrow = ({title, onClick}) => {
    return (
        <MuiButton variant="text" endIcon={<IconArrowRight />} onClick={onClick}>{title}</MuiButton>
    )
}

const ServerLoader = () => {
    return (
        <ServerContentLoader className="load-3">
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
        </ServerContentLoader>
    )
}