import React from 'react'
import { CampaignMagnetScoreWrap, StyledHeading } from './style'
import { ChartBarHorizontalCustom, MuiImage } from '../../../../../components'

const CampaignMagnetScore = ({
  endIcon,
  startIcon,
  bottomText = '',
  barData = [],
  heading = '',
  valueInPercent = false,
  maxPercentRatio = 100,
  showInside = false,
  maxWidth,
  customPercentage,
  ...rest
}) => {
  return (
    <CampaignMagnetScoreWrap sx={{ marginLeft: heading ? '150px' : 0 }} className='campaignMagnetScore'>
      {heading && <StyledHeading>{heading}</StyledHeading>}
      <ChartBarHorizontalCustom
        showInside={showInside}
        bottomText={bottomText}
        data={barData}
        valueInPercent={valueInPercent}
        endIcon={endIcon}
        customPercentage={customPercentage}
        // background='teal'
        // total={null}
        startIcon={startIcon}
        maxPercentRatio={maxPercentRatio}
        maxWidth={maxWidth}
        {...rest}
      />
    </CampaignMagnetScoreWrap>
  )
}

export default CampaignMagnetScore
