import React, { useEffect, useState } from 'react'
import { MuiSelect } from '../../../../components'
import reportsService from '../../../../services/reports.service'

const TriggerName = ({onChange, insightsElementId="", name="triggerName", value, title="Trigger Name"}) => {
  const [triggers, setTriggers] = useState([])
  
  const getTriggers = () => {
    const payload = {
      insightsElementId: insightsElementId
    }
    reportsService.getTriggerNamesElement(payload).then(resp => {
      const mappedData = resp?.map(item  => ({label: item, value: item}));
      setTriggers(mappedData);
    }).catch(error => {
      console.log(error)
    })
  }

  useEffect(() => {
    getTriggers();
  }, [])

  return (
    <MuiSelect
      name={name}
      onChange={(e) => onChange(name, e.target.value)}
      title={title}
      data={triggers || []}
      value={value}
      variant="outlined"
      placeholder="Select"
      compact
      shadow
      selectlabel="label"
      selectvalue="value"
    />
  )
}

export default TriggerName