import React from 'react'
// import CircularProgress from '@mui/material/CircularProgress'
// import { Box } from '@mui/material'
// import { loader } from '../../assets/images/icons'
// import { MuiImage } from '../'
import { Wrapper, ImgWrap, ImageWrapper } from './style'

const Loader = ({isCenter=false, width, height, size, padding=0}) => {
  return (
    <>
      {
      !isCenter 
      ? <ImgWrap className='loadingWrapper' size={width || height || size} style={{padding: padding}}><ImageWrapper size={width || height || size} style={{padding: padding}} /></ImgWrap>
      : <Wrapper sx={isCenter ? {
          width: '100vw',
          height: '100vh',
          position: 'fixed',
          top: 0,
          left: 0,
          padding: padding
        } : null}>
          <ImageWrapper size={width || height || size} />
        </Wrapper>
      }
    </>
  )
}
export default Loader
