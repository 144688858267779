import { Box } from "@mui/material";
import React, { useRef, useState } from "react";
import { arrowRightIos, filter } from "../../../../assets/images/icons";
import {
  BlockingLoader,
  MuiImage,
  NoDataFound,
  ReportHeading,
  ToolTip,
  VerbatimGrid,
  VerbatimImageVideoGrid,
  Legend,
  Loader
} from "../../../../components";
import ReportsService from "../../../../services/reports.service";
import { useGlobalStore } from "../../../../store";
import ReportVerbatim from "../reportVerbatim";
import DownloadExcel from "./downloadExcel";
import Fitlers from "./filters";
import {ROLES_CLIENT_SECONDARY, ROLES_MM_SECONDARY} from '../../../../constants'

import {
  Wrapper,
  ContentWrapper,
  HeadingRow,
  LeftGrid,
  RightGrid,
  ReportSandboxWrap,
  StyledTypography,
  InfoToolTipWrap,
  FiltersWrap,
  FilterByHeading,
  LegendWrapper
} from "./style";

const ReportSandbox = (props) => {
  const excelRef = useRef();
  const { user, projectIdValue } = useGlobalStore()
  const [state, setState] = useState([]);
  const [filters, setFilters] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const gotoView = () => {
    const ele = document.querySelector(".Information");
    if (ele) {
      ele.scrollTo(0, 0)
    }
  }

  const getSandboxVerbatimElementWithSearch = async (filterPayload) => {
    const method = filterPayload?.searchText?.trim() ? ReportsService.getSandboxVerbatimElementWithSearch : ReportsService.getSandboxVerbatimElement;
    try {
      setLoading(true);
      setFilters({...filterPayload, searchText: filterPayload?.searchText || ''});
      const resp = await method(filterPayload, true);
      const mp4 = resp.filter(f => f.VerbatimMediaPrivateName.indexOf('.mp4') !== -1)

      setState(resp || []);
      gotoView()
    } catch (error) {
    } finally {
      setLoading(false);
     
    }
  };

  const legendsArray = [ { label: 'Positive', value: 'Positive' }, { label: 'Neutral', value: 'Neutral' }, { label: 'Mixed', value: 'Mixed' }, { label: 'Negative', value: 'Negative' }]

  return (
    <Wrapper>
      <ReportHeading afterHeadingComponent={<InfoToolTip />} additionalButton={ROLES_CLIENT_SECONDARY.includes(user.UserType) && <DownloadExcel data={state} breadCrumb={filters} />} additionalMiddleButton={ROLES_MM_SECONDARY.includes(user.UserType) && !projectIdValue?.IsFreezed && <ReportVerbatim {...props} />} subHeading="" {...props} />
      <ContentWrapper>
        <ReportSandboxWrap>
          <LeftGrid>
            <FiltersWrap>
              <FilterByHeading>Filter By</FilterByHeading>
              <Fitlers
                insightsElementId={props.InsightsElementId}
                onFilterChange={getSandboxVerbatimElementWithSearch}
              />
            </FiltersWrap>
          </LeftGrid>
          <RightGrid>
            {!isLoading && !!state.length && (
              <Box ref={excelRef} style={{width:'100%'}}>
                {(filters?.driverName || filters?.entityName || filters?.attributeName || filters?.searchText)?.trim() && <HeadingRow>
                  <StyledTypography variant="body1">{filters?.entityName}</StyledTypography>
                  {filters?.driverName?.trim() && (
                    <>
                      {filters?.entityName?.trim() && <MuiImage src={arrowRightIos} />}
                      <StyledTypography variant="body1">{filters?.driverName}</StyledTypography>
                    </>
                  )}
                  {filters?.attributeName?.trim() && (
                    <>
                      {(filters?.driverName || filters?.entityName)?.trim() && <MuiImage src={arrowRightIos} />}
                      <StyledTypography variant="body1">{filters?.attributeName}</StyledTypography>
                    </>
                  )}
                  {filters?.searchText?.trim() && (
                    <>
                      {(filters?.driverName || filters?.entityName || filters?.attributeName)?.trim() && <MuiImage src={arrowRightIos} />}
                      <StyledTypography variant="body1">{filters?.searchText?.trim()}</StyledTypography>
                    </>
                  )}
                </HeadingRow>}

                <VerbatimImageVideoGrid showEntityName insightsElementId={props?.InsightsElementId} data={[...state]} entityType={props?.EntityType} />

                {/* <VerbatimGrid showEntityName insightsElementId={props?.InsightsElementId} data={[...state]} showImageVideo={true} /> */}
                {!!state.length && <LegendWrapper><Legend legends={legendsArray} /></LegendWrapper>}

              </Box>
            )}
            {!isLoading && !state.length && (
              <NoDataFound img={filter} title="Select Filter" />
            )}
            {isLoading && !state.length && (
              <Loader />
            )}
          </RightGrid>
        </ReportSandboxWrap>
        {
          isLoading &&
          <BlockingLoader />
        }
      </ContentWrapper>
    </Wrapper>
  );
};

export default ReportSandbox;


const InfoToolTip = () => {
  return (
    <InfoToolTipWrap >
      <ToolTip sx={{
        width: '900px',
        '& .tooltip-wrap': {
          fontSize: '14px',
          fontWeight: '300 !important',
        }
      }}>Verbatim Insights provides access to a large sample of actual unedited consumer speak that are arrayed by sentiments and can be filtered by brands, drivers and attributes.</ToolTip>
    </InfoToolTipWrap>
  )
}