import React, { useEffect, useState } from 'react'
import { LoaderWrapper, ReportHeading } from '../../../../components'
import ReportsService from '../../../../services/reports.service'
import { useUpdateLhs } from '../../../../hooks/useUpdateLhs'

import { ChartBarHorizontalCustom, Legend, D3Pie } from '../../../../components'
import { RootWrap, GraphWrapper, Graph1, Graph2, Graph2InnerWrap, BarGraphTitle, LegendWrapper  } from './style'

const CampaignSegmentsEngagementOverview = (props) => {
  const [loading, setLoading] = useState(false);
  const [graphData1, setGraphData1] = useState([]);
  const [graphData2, setGraphData2] = useState([]);
  const [graphData3, setGraphData3] = useState([]);
  const [legendsArray, setLegendsArray] = useState([]);
  const {updateSubLhsApiLoaded} = useUpdateLhs()
  const [eventCount, setEventCount] = useState(1)
  
  const pieWrap = 220
  const pieRad = 60
  const coorXY = ((pieWrap - (pieRad * 2)) / 2)
  const pieSettings = {
    colors: ['#FDC1ED', '#AB8BF1', '#B80D92'],
    width: pieWrap,
    height: pieWrap,
    cx: coorXY,
    cy: coorXY,
    outerRadius: pieRad,
    textPosition: 'outside',
    textLocation: 2.0,
    textSize: '12px',
    textShow: true,
    textLineShow: true
  }

  const donutSettings = {
    ...pieSettings,
    colors: ['#00B050', '#FF0000', '#ABABAB', '#F5BD4F'],
    innerRadius: pieRad - 15,
    textCenterTitle: 'Overall'
  }

  const getSegment = () => {
    setLoading(true)
    ReportsService.getCampaignSegmentElement({ insightsElementId: props.InsightsElementId })
      .then((resp) => {
        const graph1FilterData = resp?.filter(f => f.SegmentName !== 'Overall') || []
        const graph1Array = graph1FilterData?.length ? [
          { name: graph1FilterData[0]?.SegmentName, value: graph1FilterData[0]?.Percent },
          { name: graph1FilterData[1]?.SegmentName, value: graph1FilterData[1]?.Percent },
          { name: graph1FilterData[2]?.SegmentName, value: graph1FilterData[2]?.Percent }
        ] : []
        setGraphData1(graph1Array)

        const graph2FilterData = resp?.filter(f => f.SegmentName === 'Overall') || []
        const graph2Array = graph2FilterData?.length ? [
          { name: 'Positive', value: (graph2FilterData[0]?.PositivePercent) },
          { name: 'Negative', value: (graph2FilterData[0]?.NegativePercent) },
          { name: 'Mixed', value: (graph2FilterData[0]?.MixedPercent) },
          { name: 'Neutral', value: (graph2FilterData[0]?.NeutralPercent) }
        ] : []
        setGraphData2(graph2Array)
        setGraphData3(graph1FilterData)
        
        updateSubLhsApiLoaded(props)

        const legendNames = resp?.filter(f => f.SegmentName !== 'Overall').map(m => m.SegmentName)
        setLegendsArray([ 
          { label: 'Positive', value: 'Positive' }, { label: 'Neutral', value: 'Neutral' }, { label: 'Mixed', value: 'Mixed' }, { label: 'Negative', value: 'Negative' },
          { label: legendNames[0], value: 'Consumers' }, { label: legendNames[1], value: 'NewsMedia' }, { label: legendNames[2], value: 'MGPartners' }
        ])
        setEventCount(p => p+1)
      })
      .catch((error) => {
        const resMessage = error
        console.log('ERROR : ', resMessage)
        setLoading(false)
      }).finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    getSegment()
  }, [])

  return (
    <>
      <ReportHeading resetInsightHeight={eventCount} downloadId={props.type} {...props} />
      <RootWrap id={props.type}>
        <LoaderWrapper loading={loading} loadOnlyApi={props.loadOnlyApi}>
          <GraphWrapper>
            <Graph1>
              {!!graphData1?.length && <D3Pie data={graphData1} settings={pieSettings} />}
            </Graph1>
            <Graph1>
              {!!graphData2?.length && <D3Pie data={graphData2} settings={donutSettings} />}
            </Graph1>
            {!!graphData3?.length && <Graph2>
              {graphData3.map((row, i) => {
                return (
                  <Graph2InnerWrap key={i}>
                    <BarGraphTitle>{row.SegmentName}</BarGraphTitle>
                    <ChartBarHorizontalCustom
                      showInside={true}
                      bottomText={false}
                      data={[{ Positive:row.PositivePercent, Neutral:row.NeutralPercent, Mixed:row.MixedPercent, Negative:row.NegativePercent }]}
                      valueInPercent={true}
                      maxPercentRatio={100}
                      maxWidth="100%" 
                    />
                  </Graph2InnerWrap>
                )
              })}
            </Graph2>}
          </GraphWrapper>
          {legendsArray && <LegendWrapper><Legend legends={legendsArray} /></LegendWrapper>}
        </LoaderWrapper>
      </RootWrap>
    </>
  )
}

export default CampaignSegmentsEngagementOverview