import React, { useState, useEffect } from 'react'
import { Legend, ReportHeading, ReportOuterWrapper } from '../../../../components'
import ReportsService from '../../../../services/reports.service'
import { useUpdateLhs } from '../../../../hooks/useUpdateLhs'
import { key, atom } from '../../../../assets/images/icons'
import { getImageUrl, exportExcel_addPercentage } from '../../../../util'
import { TableBody, TableHead } from '@mui/material'
import { OuterWrapper, TableOuterWrapper, LegendWrapper, Mode, Table, TableRow, TableCell, TableHeading } from './style'

const ReportBrandImagery = props => {
  // const { enqueueSnackbar } = useSnackbar()
  const [backgroundTabsLoading, setBackgroundTabsLoading] = useState(true)
  const [rows, setRows] = useState([])
  const [pageFlag, setPageFlag] = useState()
  const [reportExcelData, setReportExcelData] = useState()
  const {updateSubLhsApiLoaded} = useUpdateLhs()

  const loadBrandImagery = () => {
    setPageFlag(true)
    ReportsService.reportGetBrandImageryGraphMappingApi({
      // ReportsService.reportGetBrandImageryMappingApi({
      insightsElementId: props.InsightsElementId
    })
      .then(resp => {
        const rowArray = resp?.map((r, i) => {
          return {
            title: r?.DriverName,
            per: r?.DriverPercent,
            data: r?.EntityIndexScoreImageryType?.EntityIndexScoreImageryType?.map((b) => {
              return {
                logoImg: b?.Entity?.EntityLogo, logoName: b?.Entity?.EntityName, scale: b?.IndexScore, type: b?.ImageryType
              }
            }) || []
          }
        })
        setRows(rowArray)
        setBackgroundTabsLoading(false)
        updateSubLhsApiLoaded(props)

        const reportExcelDataObj = { heading:[{title: 'Driver'}, {title: 'Percent'}], values:[] }
        const reportExcelDataObjHeadingIndex = resp[0].EntityIndexScoreImageryType.EntityIndexScoreImageryType.map(v => {
          reportExcelDataObj.heading.push({title:v.Entity.EntityName})
          return v.Entity.EntityName
        })

        for(let i=0; i<resp.length; i++){
          const arr = [{value: resp[i].DriverName}, exportExcel_addPercentage(resp[i].DriverPercent)]
          for(let j=0; j<reportExcelDataObjHeadingIndex.length; j++){ arr.push({value:''}) }

          for(let j=0; j<resp[i].EntityIndexScoreImageryType.EntityIndexScoreImageryType.length; j++){
            const entityObj = resp[i].EntityIndexScoreImageryType.EntityIndexScoreImageryType[j]
            const getIndex = reportExcelDataObjHeadingIndex.indexOf(entityObj.Entity.EntityName)+2
            arr[getIndex] = {value:(entityObj.ImageryType !== 'None') ? entityObj.ImageryType.replace('Owned', ' Owned').replace('Shared', ' Shared') : ''}
          }
          reportExcelDataObj.values.push(arr)
        }
        setReportExcelData(reportExcelDataObj)

      })
      .catch(error => {
        const resMessage = error
        console.log('ERROR : ', resMessage)
        // enqueueSnackbar(resMessage, { variant: 'error' })
        setBackgroundTabsLoading(false)
      })
  }

  useEffect(() => {
    if (!pageFlag) {
      loadBrandImagery()
    }
  }, [])

  const legends = [
    { label: 'Positive', value: 'Positive' },
    { label: 'Neutral', value: 'Neutral' },
    { label: 'Mixed', value: 'Mixed' },
    { label: 'Negative', value: 'Negative' }
  ]
  const icons = [
    { label: 'Owned', icon: key },
    { label: 'Shared', icon: atom }
  ]

  return (
    <>
      <ReportHeading downloadId={props.type} subHeading='' {...props} downloadIdData={reportExcelData} addTable={true} />
      <ReportOuterWrapper>
        <OuterWrapper id={props.type}>
          <TableOuterWrapper>
            <Table>
              <TableBody>
                <TableRow>
                  <TableHeading />
                  {!backgroundTabsLoading && rows[0]?.data?.map((c, index) => {
                    let imgSrc = getImageUrl('logos', c.logoImg, 'png')
                    return (
                      <TableHeading key={index}>
                        <img title={c?.logoName} src={imgSrc} alt={c?.logoName} />
                      </TableHeading>
                    )
                  })}
                </TableRow>
                {rows?.map((r, index) => {
                  const firstRow = rows[0]?.data;
                  return (
                    <TableRow key={index} BoxHeight={rows.length} className='brandRows'>
                      <TableCell title={r?.per + '%'}>{r?.title}</TableCell>
                      {firstRow?.map((c, i) => {
                        const item = r.data.find(dd => dd.logoName === c.logoName)
                        let src = 'key'
                        let imgClass = 'positive'
                        let type = item?.type
                        imgClass = type

                        if (['PositiveShared', 'NegativeShared', 'MixedShared', 'NeutralShared'].includes(type)) {
                          src = 'atom'
                        } else if (['PositiveOwned', 'NegativeOwned', 'MixedOwned', 'NeutralOwned'].includes(type)) {
                          src = 'key'
                        }

                        return (
                          <TableCell key={i}>
                            {
                              item && type !== 'None' ? <GetSvg src={src} type={type} /> : null
                            }
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableOuterWrapper>

          <LegendWrapper>
            <Mode>
              {icons.map((ico) => {
                return (
                  <div key={ico.label}>
                    <img src={ico?.icon} />
                    <p>{ico.label}</p>
                  </div>
                )
              })}
            </Mode>
            <Legend legends={legends} />
          </LegendWrapper>
        </OuterWrapper>
      </ReportOuterWrapper>
    </>
  )
}

export default ReportBrandImagery

const GetSvg = ({ src, type }) => {
  let color = '#F5C342'
  if (['PositiveShared', 'PositiveOwned'].includes(type)) {
    color = '#00B050'
  } else if (['NegativeShared', 'NegativeOwned'].includes(type)) {
    color = '#FF0000'
  } else if (['MixedShared', 'MixedOwned'].includes(type)) {
    color = '#A6A6A6'
  }

  return (
    src === 'atom' ? <svg width='24' height='24' viewBox='0 0 26 27' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M23.9709 15.8769C24.0382 15.3963 24.0689 14.9126 24.0689 14.4228C24.0689 9.41794 20.6222 4.99133 15.8226 3.73355C15.7705 2.21837 14.5277 1 13.0002 1C11.4421 1 10.1748 2.26729 10.1748 3.82539C10.1748 5.38348 11.4421 6.65077 13.0002 6.65077C14.2645 6.65077 15.3357 5.81827 15.697 4.67036C20.0283 5.86118 23.1294 9.88334 23.1294 14.4199C23.1294 14.8056 23.1049 15.1914 23.0622 15.574C23.3712 15.6413 23.6771 15.7392 23.9709 15.8769ZM13.0002 5.70807C11.9625 5.70807 11.1176 4.86315 11.1176 3.82544C11.1176 2.78773 11.9625 1.94282 13.0002 1.94282C14.0379 1.94282 14.8828 2.78773 14.8828 3.82544C14.8828 4.86315 14.0379 5.70807 13.0002 5.70807Z' stroke={color} strokeWidth='1.2' />
      <path d='M1.37751 21.131C1.90086 22.037 2.85296 22.5422 3.82939 22.5422C4.30684 22.5422 4.79369 22.4198 5.2376 22.1657C6.58749 21.3883 7.04977 19.6555 6.27236 18.3057C5.89592 17.6506 5.28675 17.1853 4.5552 16.9895C4.1053 16.8701 3.646 16.8609 3.20531 16.9619C2.99093 16.1386 2.87776 15.2875 2.87776 14.4212C2.87776 10.3621 5.35424 6.70729 8.97535 5.13411C8.87136 4.83411 8.80709 4.51577 8.78254 4.19141C4.72342 5.86888 1.93499 9.91237 1.93499 14.4187C1.93499 15.4076 2.06972 16.3808 2.32681 17.3238C1.04416 18.1253 0.615603 19.809 1.37771 21.1313L1.37751 21.131ZM2.88373 18.0853C3.17455 17.917 3.4959 17.8312 3.82343 17.8312C3.98572 17.8312 4.15098 17.8526 4.31326 17.8955C4.79988 18.027 5.20409 18.3362 5.45816 18.7739C5.97852 19.6739 5.66934 20.8278 4.76931 21.3484C4.33457 21.5995 3.8266 21.6667 3.33978 21.5382C2.85316 21.4067 2.44894 21.0975 2.19487 20.6598C1.67452 19.7595 1.9837 18.6057 2.88373 18.0853L2.88373 18.0853Z' stroke={color} strokeWidth='1.2' />
      <path d='M24.6228 21.1339C25.4003 19.784 24.9382 18.0545 23.5881 17.2738C22.933 16.8974 22.1739 16.7964 21.4454 16.9922C20.7168 17.1882 20.1076 17.6535 19.7282 18.3084C19.0945 19.4042 19.2843 20.7541 20.0987 21.6389C18.2192 23.4941 15.6569 24.5501 13.0002 24.5501C10.8146 24.5501 8.69939 23.84 6.9726 22.5543C6.76143 22.793 6.51651 23.0104 6.24707 23.194C8.16621 24.6695 10.5417 25.4898 13.0028 25.4898C15.9537 25.4898 18.791 24.299 20.8578 22.2114C21.2771 22.4349 21.727 22.5451 22.1739 22.5451C23.1476 22.5451 24.0993 22.0399 24.6228 21.1339ZM23.8085 20.6625C23.5574 21.0972 23.1504 21.4094 22.6636 21.5409C22.177 21.6724 21.6688 21.6052 21.234 21.3511C20.334 20.8307 20.0249 19.6766 20.5452 18.7766C20.7963 18.3418 21.2033 18.0297 21.6901 17.8981C22.1767 17.7666 22.6849 17.8339 23.1196 18.088C24.0166 18.6085 24.3258 19.7624 23.8085 20.6625Z' stroke={color} strokeWidth='1.2' />
    </svg> :
      <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M19 5.5C19 6.32842 18.3284 7 17.5 7C16.6715 7 16 6.32842 16 5.5C16 4.67157 16.6715 4 17.5 4C18.3284 4 19 4.67157 19 5.5Z' fill={color} />
        <path d='M16.1791 0C11.8636 0 8.35822 3.52295 8.35822 7.86C8.35822 8.32465 8.41812 8.77244 8.49011 9.18482C8.51138 9.30659 8.4725 9.41237 8.40746 9.47773L0.961732 16.9607C0.345947 17.5795 0 18.4189 0 19.2941V21.9C0 23.0598 0.935523 24 2.08955 24H5.07463C6.22866 24 7.16419 23.0598 7.16419 21.9V20.4H9.25374C10.0781 20.4 10.7463 19.7285 10.7463 18.9V16.8H12.8358C13.6417 16.8 14.2984 16.1582 14.3274 15.3554C14.9205 15.5088 15.5459 15.6 16.1791 15.6C20.4946 15.6 24 12.077 24 7.74C24 3.38263 20.4741 0 16.1791 0ZM10.1492 7.86C10.1492 4.51706 12.8528 1.8 16.1791 1.8C19.5258 1.8 22.209 4.41738 22.209 7.74C22.209 11.083 19.5054 13.8 16.1791 13.8C15.368 13.8 14.533 13.5947 13.7857 13.2727C13.5089 13.1536 13.1912 13.1821 12.9399 13.3487C12.6886 13.5152 12.5373 13.7975 12.5373 14.1V15H10.4477C9.62351 15 8.95523 15.6715 8.95523 16.5V18.6H6.86568C6.04137 18.6 5.37314 19.2715 5.37314 20.1V21.9C5.37314 22.0657 5.23949 22.2 5.07463 22.2H2.08955C1.92469 22.2 1.79105 22.0657 1.79105 21.9V19.2941C1.79105 18.8963 1.94829 18.5148 2.22821 18.2334L9.6739 10.7506C10.1735 10.2485 10.371 9.5424 10.2542 8.87377C10.1901 8.50662 10.1492 8.17488 10.1492 7.86Z' fill={color} />
      </svg>
  )
}