import React, { useState, useEffect, useRef } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import ReportsService from '../../../../../services/reports.service'

import { Modal, Rte, MuiButton } from '../../../../../components'
import { IconButton } from '@mui/material'
import { IconEdit } from '../../../../../assets/images/icons'

import { LoginButton, Title, Wrapper, RteWrapper } from './style'

const ReportEditKeyTakeaways = (props) => {
  const {InsightsElementId, pageData, onSuccess} = props
  const [anchorElUser, setAnchorElUser] = useState(null)
  const [showEditHeaderModal, setShowEditHeaderModal] = useState(false)

  const handleOnEditHeader = () => {
    setAnchorElUser(null)
    setShowEditHeaderModal((prev)=>!prev)
  }

  const form = useRef()
  const [loader, setLoader] = useState(false)
  const [headingValue, setHeadingValue] = useState(pageData || '')
  
  // const { enqueueSnackbar } = useSnackbar()

  const onSubmit = (e) => {
    e.preventDefault()
    const updatedValues = {
      insightsElementId : InsightsElementId,
      keyTakeaways : headingValue,
    }

    setLoader(true)
    ReportsService.reportSetKeyTakeawaysApi(updatedValues)
    .then((resp) => {
      setLoader(false)
      setShowEditHeaderModal(false)
      onSuccess()
    })
    .catch((error) => {
      setLoader(false)
      const resMessage = error
      console.log('ERROR : ', resMessage)
        // enqueueSnackbar(resMessage, { variant: 'error' })
    })
  }

  return (
    <>
      <MuiButton color='secondary' variant='outlined' startIcon={<IconEdit />}  onClick={() => setShowEditHeaderModal(true)}>Edit Business Objective</MuiButton>
      {showEditHeaderModal && <Modal title='' showClose={true} onClose={handleOnEditHeader}>
        <Wrapper>
          <form ref={form}>
            <Title variant='body1'>Edit Business Objective</Title>

            <RteWrapper>
              <Rte title='' value={headingValue} onChange={(e) => setHeadingValue(e)} />
            </RteWrapper>

            <LoginButton
              color='primary'
              variant='contained'
              type='submit'
              onClick={onSubmit}
              disabled={loader || false}
            >
              Submit
            </LoginButton>
          </form>
        </Wrapper>
      </Modal>}
    </>
  )
}

export default ReportEditKeyTakeaways
