import React, { useState, useRef, useEffect } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import AuthService from '../../services/auth.service'
import { Checkbox } from '@mui/material'
import PreLogin from '../../layout/preLogin'
import { MuiTextField, MuiButton, Loader } from '../../components'
import { ROUTE_PROJECTS, ROUTE_RESET_PASSWORD } from '../../constants'
import { LoginButton, LoginContent1, LoginContent2, FormControlLabelWrapper, ErrorMsg, PasswordInputWrap, EmailInputWrap } from './style'
import { getRememberUser, rememberMe, removeRememberMe, removeUserDetails } from '../../util'
import { useGlobalStore } from '../../store'


const Login = (props) => {
  const form = useRef();
  const { setUser, setCompany } = useGlobalStore();

  const [loader, setLoader] = useState(false)
  const [serverErrorMsg, setServerErrorMsg] = useState()
  // const { enqueueSnackbar } = useSnackbar()

  const onSubmit = (data, isRemember) => {
    setLoader(true)
    AuthService.loginApi(data)
      .then((resp) => {
        if(resp?.user?.UserId){
          setUser(resp.user)
          setCompany(resp.company)
          props.history.push(ROUTE_PROJECTS)
          // enqueueSnackbar('Logged in Successfully', {variant: 'success'})
        }
        else{
          setServerErrorMsg('Invalid Credentials')
        }
        if(isRemember) {
          rememberMe(data)
        } else {
          removeRememberMe()
        }
      })
      .catch((error) => {
        const resMessage = error
        console.log('ERROR : ', resMessage)
      }).finally(() => {
        setLoader(false)
      })
  }

  const validation = (values) => {
    const errors = {}
    if (!values.password) {
      errors.password = 'Password is required'
    }
    return errors
  }
  
  const isLocal = window.location.host.indexOf('localhost') !== -1

  
  const dummyCreds = [
    { email: 'sewantil@mavenmagnet.com', password: 'test2'},
    // { email: 'accentureanalyst@mmtest.com', password: 'X3Bsdzed'}, // NEW
    // { email: 'testpublisher@mmtest.com', password: 'testing1'}, // NEW
    // { email: 'mahindraanalyst@mmtest.com', password: 'mm4mahindra'}, // NEW
    // { email: 'mmanalyst@mmtest.com', password: 'mavenmagnetnews'}, // NEW
    // { email: 'msilanalyst@mmtest.com', password: 'analyst4maruti'}, // NEW
    // { email: 'civicplusanalyst@mmtest.com', password: 'cplusmmr0cks!'}, // NEW
    // { email: 'mmpublisher@mmtest.com', password: 'mmpublish1'}, // NEW
    // { email: 'lorealanalyst@mmtest.com', password: 'lorealsupport123'}, // NEW
    // { email: 'amazonanalyst@mmtest.com', password: 'amznana1'}, // NEW
    // { email: 'gafanalyst@mmtest.com', password: 'roofingrocks'}, // NEW
    // { email: 'cadillacanalyst@mmtest.com', password: 'caddyrocks'}, // NEW
    // { email: 'amazonpublisher@mmtest.com', password: 'mahi1'},
    // { email: 'mahindrapublisher@mmtest.com', password: 'pubmahi1'},
    // { email: 'mahindraadmin@mavenmagnet.com', password: 'MMr0cks!'},
    // { email: 'mganalyst@mmtest.com', password: 'mganalyst2'},
    // { email: 'itcanalyst@mmtest.com', password: 'Ztg3hSnA'},
  ]

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      email: isLocal ? dummyCreds[0].email : '',
      password: isLocal ? dummyCreds[0].password : '',
      rememberMe: false,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .max(45)
        .required('Email is required'),
      password: Yup.string().max(45).required('Password is required')
    }),
    validate: (values) => validation(values),
    onSubmit: (values) => {
      const updatedValues = { ...values }
      const isRemember = updatedValues.rememberMe;
      delete updatedValues.removeRememberMe;
      onSubmit(updatedValues, isRemember)
    }
  })

  useEffect(() => {
    removeUserDetails();
    const rememberData = getRememberUser();
    if(rememberData?.userName && rememberData?.password) {
      formik.setValues({
        email: rememberData.userName,
        password: rememberData.password,
        rememberMe: true
      })
    }
  },[])

  useEffect(() => {
    localStorage.clear();
    localStorage.setItem('logs', JSON.stringify([]))
    localStorage.setItem('company', JSON.stringify({}))
    localStorage.setItem('user', JSON.stringify({}))
  },[])

  return (
    <>
      <PreLogin>
        <form onSubmit={formik.handleSubmit} ref={form} autoComplete='off'>
          <EmailInputWrap>
            <MuiTextField
              error={Boolean(
                formik.touched.email && formik.errors.email
              )}
              fullWidth
              helperText={formik.touched.email && formik.errors.email}
              name='email'
              label='Email'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type='text' 
              value={formik.values.email || ''}
              variant='standard'
              color='dark'
              sx={{'& input': {
                fontSize: '16px !important'
              } }}
            />
          </EmailInputWrap >

          <PasswordInputWrap>
          <MuiTextField
            error={Boolean(
              formik.touched.password && formik.errors.password
            )}
            fullWidth
            helperText={formik.touched.password && formik.errors.password}
            name='password'
            label='Password'
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            type='password'
            value={formik.values.password || ''}
            variant='standard'
            color='dark'
            // sx={{marginBottom:'15px', '& input': {
            //   fontSize: '16px !important'
            // }}}
          />
        </PasswordInputWrap>
          {serverErrorMsg && <ErrorMsg>{serverErrorMsg}</ErrorMsg>}

          <LoginContent1>
            <FormControlLabelWrapper name='rememberMe' checked={formik.values.rememberMe} onChange={formik.handleChange} control={<Checkbox />} label='Remember me' size='medium' />
            <MuiButton to={ROUTE_RESET_PASSWORD} variant='text' color='gray' size='small' style={{fontWeight: 300}}>Forgot Password?</MuiButton>
          </LoginContent1>
          
          <LoginButton
            variant='contained'
            color='secondary'
            type='submit'
            onClick={formik.handleSubmit}
            disabled={loader}
            endIcon={loader && <Loader />}
          >
            Sign In
          </LoginButton>

          <LoginContent2><a href='/sso'>Sign in with SSO</a> <br/><br/> Don't have an account yet?  <a href='https://www.mavenmagnet.com/contact' target='_blank'>Contact Us</a></LoginContent2>

        </form>
      </PreLogin>
    </>
  )
}

export default Login
