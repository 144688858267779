import React from 'react'
import { CloseButton, SearchInput } from '../../../../components'
import { SearchInputWrap, SearchWrap } from './style'

const Search = ({onChange, value}) => {

  return (
    <SearchWrap>
      <SearchInputWrap sx={{marginLeft: "auto"}}>
        <SearchInput value={value} onChange={onChange} placeholder="Search by Verbatim" />
      </SearchInputWrap>
    </SearchWrap>
  )
}

export default Search