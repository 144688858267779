import React from 'react'
const IconArrowRight = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
            <g filter="url(#filter0_d_10790_2768)">
                <circle cx="14" cy="14" r="10" fill="white"/>
                <circle cx="14" cy="14" r="9.5" stroke="#F7F7F9"/>
            </g>
            <path fillRule="evenodd" clipRule="evenodd" d="M17.7735 14.4254H9.37709C9.16883 14.4254 9 14.2411 9 14.0102C9 13.7809 9.16757 13.595 9.37709 13.595H17.756L14.2144 9.70752C14.0676 9.54631 14.0672 9.28531 14.216 9.12203C14.3637 8.95988 14.6027 8.95934 14.7494 9.12032L18.8537 13.6256C18.9245 13.7032 18.9612 13.804 18.9637 13.9059C19.0302 14.0599 19.0052 14.2479 18.8899 14.3744L14.7856 18.8797C14.6389 19.0407 14.3999 19.0401 14.2522 18.878C14.1034 18.7147 14.1038 18.4537 14.2506 18.2925L17.7735 14.4254Z" fill="#095BBD" stroke="#095BBD" strokeWidth="0.5"/>
            <defs>
                <filter id="filter0_d_10790_2768" x="0" y="0" width="28" height="28" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset/>
                <feGaussianBlur stdDeviation="2"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_10790_2768"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_10790_2768" result="shape"/>
                </filter>
            </defs>
        </svg>
    )
}

export default IconArrowRight