
const data = [
  {
    "StudyId": "af4fa515-5500-11ed-817c-0a1ea4c5532f",
    "TabId": "35633737-3634-6461-2d35-3638332d3131",
    "InsightsElementId": "dd71d206-5689-11ed-817c-0a1ea4c5532f",
    "EntityId": "bfe77dca-54fe-11ed-817c-0a1ea4c5532f",
    "InsightsElementType": "BusinessObjective",
    "Title": "Business Objective 123 456 789",
    "ParentTitle": "",
    "Heading": "&lt;p&gt;Business Objective 123 456 789 &lt;sup&gt;TM&lt;/sup&gt;&lt;/p&gt;",
    "Insights": "&lt;ol&gt;&lt;li&gt;Lorem ipsum dolor sit amet, consectetur adipiscing elit.&lt;/li&gt;&lt;li&gt;Donec ultrices sapien a massa blandit posuere.&lt;ul&gt;&lt;li&gt;Vestibulum bibendum sem ac luctus interdum.&lt;/li&gt;&lt;li&gt;Nam malesuada justo ut justo ornare porta.&lt;/li&gt;&lt;/ul&gt;&lt;/li&gt;&lt;li&gt;Vestibulum semper eros id metus pretium venenatis.&lt;/li&gt;&lt;li&gt;Maecenas finibus metus sed nunc dapibus dignissim vel at nunc.&lt;/li&gt;&lt;li&gt;Nunc bibendum arcu at diam pulvinar porttitor.&lt;/li&gt;&lt;/ol&gt;",
    "InsightsElementOrder": 1,
    "InsightsElementVisible": true,
    "InsightsElementDeepDive": false,
    "CreatedDate": "2022-10-28T06:37:17",
    "ModifiedDate": "2023-08-18T22:45:50"
  },
  {
    "StudyId": "af4fa515-5500-11ed-817c-0a1ea4c5532f",
    "TabId": "35633737-3634-6461-2d35-3638332d3131",
    "InsightsElementId": "0510dd82-568a-11ed-817c-0a1ea4c5532f",
    "EntityId": "bfe77dca-54fe-11ed-817c-0a1ea4c5532f",
    "InsightsElementType": "ResearchDesign",
    "Title": "Study Design",
    "ParentTitle": "",
    "Heading": "&amp;lt;p&amp;gt;Study Design&amp;lt;/p&amp;gt;",
    "Insights": "&amp;lt;p&amp;gt;&amp;lt;/p&amp;gt;",
    "Observations": "",
    "InsightsElementOrder": 2,
    "InsightsElementVisible": true,
    "InsightsElementDeepDive": false,
    "CreatedDate": "2022-10-28T06:37:28",
    "ModifiedDate": "2024-07-05T01:11:32"
  },
  {
    "StudyId": "af4fa515-5500-11ed-817c-0a1ea4c5532f",
    "TabId": "35633737-3634-6461-2d35-3638332d3131",
    "InsightsElementId": "e749cf01-568a-11ed-817c-0a1ea4c5532f",
    "EntityId": "bfe77dca-54fe-11ed-817c-0a1ea4c5532f",
    "InsightsElementType": "BrandHealthSetup",
    "Title": "Study Setup",
    "ParentTitle": "",
    "Heading": "&amp;lt;p&amp;gt;Set up&amp;lt;/p&amp;gt;",
    "Insights": "&amp;lt;p&amp;gt;&amp;lt;/p&amp;gt;",
    "Observations": "",
    "InsightsElementOrder": 3,
    "InsightsElementVisible": true,
    "InsightsElementDeepDive": false,
    "CreatedDate": "2022-10-28T06:37:35",
    "ModifiedDate": "2023-08-18T22:45:50"
  },
  {
    "StudyId": "af4fa515-5500-11ed-817c-0a1ea4c5532f",
    "TabId": "35636363-3635-3232-2d35-3638332d3131",
    "InsightsElementId": "d2f53d3f-5725-11ed-817c-0a1ea4c5532f",
    "EntityId": "bfe77dca-54fe-11ed-817c-0a1ea4c5532f",
    "InsightsElementType": "Aura",
    "Title": "Aura",
    "ParentTitle": "",
    "Heading": "&amp;lt;p&amp;gt;Aura&amp;lt;/p&amp;gt;",
    "Insights": "&amp;lt;p&amp;gt;&amp;lt;/p&amp;gt;",
    "Observations": "",
    "InsightsElementOrder": 2,
    "InsightsElementVisible": true,
    "InsightsElementDeepDive": false,
    "CreatedDate": "2022-10-29T01:08:26",
    "ModifiedDate": "2023-08-18T22:45:50"
  },
  {
    "StudyId": "af4fa515-5500-11ed-817c-0a1ea4c5532f",
    "TabId": "35643134-6365-3335-2d35-3638332d3131",
    "InsightsElementId": "f66f06ac-5da1-11ed-817c-0a1ea4c5532f",
    "EntityId": "bfe77dca-54fe-11ed-817c-0a1ea4c5532f",
    "InsightsElementType": "DriversAndSubBrandsMotivatorsDetractors",
    "Title": "Drivers",
    "ParentTitle": "",
    "Heading": "&amp;lt;p&amp;gt;Brand Drivers&amp;lt;/p&amp;gt;",
    "Insights": "&amp;lt;p&amp;gt;&amp;lt;/p&amp;gt;",
    "Observations": "",
    "InsightsElementOrder": 1,
    "InsightsElementVisible": true,
    "InsightsElementDeepDive": true,
    "CreatedDate": "2022-11-06T07:10:47",
    "ModifiedDate": "2023-10-30T04:40:51"
  },
  {
    "StudyId": "af4fa515-5500-11ed-817c-0a1ea4c5532f",
    "TabId": "35636363-3635-3232-2d35-3638332d3131",
    "InsightsElementId": "84f803d1-5e71-11ed-817c-0a1ea4c5532f",
    "EntityId": "bfe77dca-54fe-11ed-817c-0a1ea4c5532f",
    "InsightsElementType": "ImpactDestinations",
    "Title": "Impact Destinations",
    "ParentTitle": "",
    "Heading": "&amp;lt;p&amp;gt;Impact Destinations&amp;lt;/p&amp;gt;",
    "Insights": "&amp;lt;p&amp;gt;&amp;lt;/p&amp;gt;",
    "Observations": "",
    "InsightsElementOrder": 1,
    "InsightsElementVisible": true,
    "InsightsElementDeepDive": false,
    "CreatedDate": "2022-11-07T07:59:03",
    "ModifiedDate": "2023-08-18T22:45:50"
  },
  {
    "StudyId": "af4fa515-5500-11ed-817c-0a1ea4c5532f",
    "TabId": "35636363-3635-3232-2d35-3638332d3131",
    "InsightsElementId": "afa7a377-630f-11ed-817c-0a1ea4c5532f",
    "EntityId": "bfe77dca-54fe-11ed-817c-0a1ea4c5532f",
    "InsightsElementType": "BrandImageryMappingGraph",
    "Title": "Brand Imagery Mapping",
    "ParentTitle": "",
    "Heading": "&amp;lt;p&amp;gt;Brand Imagery Mapping&amp;lt;/p&amp;gt;",
    "Insights": "&amp;lt;p&amp;gt;&amp;lt;/p&amp;gt;",
    "Observations": "",
    "InsightsElementOrder": 3,
    "InsightsElementVisible": true,
    "InsightsElementDeepDive": true,
    "CreatedDate": "2022-11-13T05:07:03",
    "ModifiedDate": "2022-11-15T04:38:47"
  },
  {
    "StudyId": "af4fa515-5500-11ed-817c-0a1ea4c5532f",
    "TabId": "35633933-3234-6461-2d35-3638332d3131",
    "InsightsElementId": "83728080-5903-11ee-817c-0a1ea4c5532f",
    "EntityId": "bfe77dca-54fe-11ed-817c-0a1ea4c5532f",
    "InsightsElementType": "SegmentOverview",
    "Title": "Benefit vs. Ingredient",
    "ParentTitle": "",
    "Heading": "&amp;lt;p&amp;gt;Benefit vs. Ingredient&amp;lt;/p&amp;gt;",
    "Insights": "",
    "Observations": "",
    "InsightsElementOrder": 5,
    "InsightsElementVisible": true,
    "InsightsElementDeepDive": false,
    "CreatedDate": "2023-09-22T05:00:20",
    "ModifiedDate": "2023-09-25T04:55:07"
  },
  {
    "StudyId": "af4fa515-5500-11ed-817c-0a1ea4c5532f",
    "TabId": "35633933-3234-6461-2d35-3638332d3131",
    "InsightsElementId": "3e243bdb-590c-11ee-817c-0a1ea4c5532f",
    "EntityId": "bfe77dca-54fe-11ed-817c-0a1ea4c5532f",
    "InsightsElementType": "SegmentAndSegmentOfSegment",
    "Title": "Age",
    "ParentTitle": "",
    "Heading": "&amp;lt;p&amp;gt;Age&amp;lt;/p&amp;gt;",
    "Insights": "",
    "Observations": "",
    "InsightsElementOrder": 6,
    "InsightsElementVisible": true,
    "InsightsElementDeepDive": false,
    "CreatedDate": "2023-09-22T05:53:48",
    "ModifiedDate": "2023-09-25T04:55:07"
  },
  {
    "StudyId": "af4fa515-5500-11ed-817c-0a1ea4c5532f",
    "TabId": "35636363-3635-3232-2d35-3638332d3131",
    "InsightsElementId": "4837d9ee-836c-11ee-9f55-0a1ea4c5532f",
    "EntityId": "bfe77dca-54fe-11ed-817c-0a1ea4c5532f",
    "InsightsElementType": "BrandMagnetScore",
    "Title": "Brand Magnet Score 2",
    "ParentTitle": "",
    "Heading": "&amp;lt;p&amp;gt;Brand Magnet Score&amp;lt;/p&amp;gt;",
    "Insights": "",
    "Observations": "",
    "InsightsElementOrder": 15,
    "InsightsElementVisible": true,
    "InsightsElementDeepDive": false,
    "CreatedDate": "2023-11-15T04:07:27",
    "ModifiedDate": "2023-11-28T01:16:44"
  },
  {
    "StudyId": "af4fa515-5500-11ed-817c-0a1ea4c5532f",
    "TabId": "35636363-3635-3232-2d35-3638332d3131",
    "InsightsElementId": "6c02d888-836c-11ee-9f55-0a1ea4c5532f",
    "EntityId": "bfe77dca-54fe-11ed-817c-0a1ea4c5532f",
    "InsightsElementType": "BrandCategoryOverview",
    "Title": "Overview 2",
    "ParentTitle": "",
    "Heading": "&amp;lt;p&amp;gt;Brand Health Overview&amp;lt;/p&amp;gt;",
    "Insights": "",
    "Observations": "",
    "InsightsElementOrder": 16,
    "InsightsElementVisible": true,
    "InsightsElementDeepDive": false,
    "CreatedDate": "2023-11-15T04:07:52",
    "ModifiedDate": "2023-11-28T01:16:44"
  }
]

export default data
