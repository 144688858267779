import { styled } from '@mui/material/styles'
import { Box, Typography } from '@mui/material'
import { MuiButton } from '../../../../components'

export const OuterWrapper = styled(Box)(`
  & .modalWrapper{
    justify-content:end;
  }
  & .closeBtn button{
    position: absolute;
    right: 0;
    top: 0px;
  }
  & .PopupWrapper{
    border-radius: 0;
    width: calc(100% - 110px);
    align-self: normal;
    padding:0;
  }
`)

export const Wrapper = styled(Box)(`
  width:100%;
`)

export const Heading = styled(Box)(`
  padding:30px 70px 30px 30px;
  display:flex;
  justify-content:space-between;
  border-bottom: 1px solid #E7E7E7;
`)

export const TitleWrap = styled(Typography)(`
  font-weight: 300;
  font-size: 20px;
  color:#000;
  & sup {
    font-size: 14px;
  }
`)

export const ButtonWrap = styled(Box)(`
  
`)

export const ContentWrapper = styled(Box)(`
  display:flex;
  overflow: auto;
  width: 100%;
  min-width: 100%;
  overflow-x: hidden;
`)

export const SectionInnerWrapper = styled(Box)(`
  padding-top:15px;
  height:calc(100vh - 150px);
  overflow: auto;
  & > div { padding:0; }
`)

export const Section1 = styled(Box)(`
  width: calc(75% - 30px);
  min-width: calc(75% - 30px);
  margin-right: 30px;
  &.firstPage {
    width: calc(75% - 80px);
    min-width: calc(75% - 80px);
  }
  &.secondPage {
    margin-right: 80px;
  }
`)

export const Section2 = styled(Box)(`
  width: 25%;
  min-width: 25%;
  & .sectionInnerWrapper {
    background: #FFFBF8;
    padding: 20px;
    width: 100%;
    box-shadow: 0px 0px 10px #FFC79F;
    margin-top: 20px;
    & .brandMagnetScoreWarningWrap {
      height: 100%;
    }
    & .barChartsWrapper{
      height: 100%;
      padding-bottom: 20px;
    }
  }
  & .emojiRow {
    margin-top: auto;
  }
`)

export const Section3 = styled(Box)(`
  width: calc(75% - 50px);
  min-width: calc(75% - 50px);
  & .sectionInnerWrapper {
    display: flex;
    aligin-items: center;
    & .brandMagnetScoreTreeWrap {
      padding-right: 20px;
    }
  }
`)

export const HistoryContentWrapper = styled(Box)(`
  display:flex;
  padding: 30px;
  overflow: auto;
  width: 100%;
`)

export const TableWrap = styled(Box)(`
  width: 100%;
  & .tableRoot {
    & .table-body-row{
      box-shadow: none;
      background: #fff;
    }
    & .table-body-cell {
      padding: 15px 0;
      border-bottom: 1px solid #F1F1F3;
    }
    & .MuiTableHead-root {
      background: #fff;
      & .table-head-cell {
        padding: 15px 0;
        border-bottom: 2px solid #F1F1F3;
      }
    }
  }
`)
export const SearchRowWrap = styled(Box)(`
  margin-bottom: 10px;
  display:flex;
  aligin-items: center;
`)
export const ArrowWrap = styled(Box)(`
  position: absolute;
  top: 50%;
  z-index: 11;
  left: -25px;
  &.left {
    right: -25px;
    left: unset;
    transform: rotate(180deg);
  }
`)


export const ModalTitle = styled(Typography)(`
  margin-top: -10px;
  padding-bottom: 30px;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  text-align: left;
`)

export const ModalWrapper = styled(Box)(`
  width:100%;
  max-width:370px;
  min-width:370px;
  & input {
    padding-left: 0 !important;
  }
`)

export const ModalButton = styled(MuiButton)(`
  float: left;
  margin-left: 0px !important;
  margin-top: 32px !important;
`)
