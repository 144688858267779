
/////////////////////////////////////////////

const Q2_2024 = {
    PrimarySegmentName:"Q2 2024",
    PrimarySegmentValue: 100,
    SecondarySegmentSet: {
    SecondarySegment: [
            {SegmentName: 'Monetize', SegmentValue: 19},
            {SegmentName: 'App Performance', SegmentValue: 24},
            {SegmentName: 'Development Resources', SegmentValue: 21},
            {SegmentName: 'Simplified App Publishing', SegmentValue: 21},
            {SegmentName: 'Growth', SegmentValue: 15}
        ]
    }
}

const Q1_2024 = {
    PrimarySegmentName:"Q1 2024",
    PrimarySegmentValue: 100,
    SecondarySegmentSet: {
    SecondarySegment: [
            {SegmentName: 'Monetize', SegmentValue: 17},
            {SegmentName: 'App Performance', SegmentValue: 19},
            {SegmentName: 'Development Resources', SegmentValue: 22},
            {SegmentName: 'Simplified App Publishing', SegmentValue: 25},
            {SegmentName: 'Growth', SegmentValue: 17}
        ]
    }
}

const Q4_2023 = {
    PrimarySegmentName:"Q4 2023",
    PrimarySegmentValue: 100,
    SecondarySegmentSet: {
    SecondarySegment: [
            {SegmentName: 'Monetize', SegmentValue: 14},
            {SegmentName: 'App Performance', SegmentValue: 25},
            {SegmentName: 'Development Resources', SegmentValue: 24},
            {SegmentName: 'Simplified App Publishing', SegmentValue: 24},
            {SegmentName: 'Growth', SegmentValue: 13}
        ]
    }
}

const Q3_2023 = {
    PrimarySegmentName:"Q3 2023",
    PrimarySegmentValue: 100,
    SecondarySegmentSet: {
    SecondarySegment: [
            {SegmentName: 'Monetize', SegmentValue: 17},
            {SegmentName: 'App Performance', SegmentValue: 19},
            {SegmentName: 'Development Resources', SegmentValue: 26},
            {SegmentName: 'Simplified App Publishing', SegmentValue: 26},
            {SegmentName: 'Growth', SegmentValue: 12}
        ]
    }
}

const H2_2022 = {
    PrimarySegmentName:"H2 2022",
    PrimarySegmentValue: 100,
    SecondarySegmentSet: {
    SecondarySegment: [
            {SegmentName: 'Monetize', SegmentValue: 17},
            {SegmentName: 'App Performance', SegmentValue: 12},
            {SegmentName: 'Development Resources', SegmentValue: 32},
            {SegmentName: 'Simplified App Publishing', SegmentValue: 30},
            {SegmentName: 'Growth', SegmentValue: 8}
        ]
    }
}

/////////////////////////////////////////////

export const pieChart_data_ee0f401e_424d_11ef_8482_128e718ba88f = [
    {id: 'd98d0164-4275-11ef-8482-128e718ba88f', url: 'reports/segmentprofile3', minHeight: '68px'},
    {id: '8b7365c0-427e-11ef-8482-128e718ba88f', url: 'reports/segmentprofile4', minHeight: '60px'},
    {id: '136baf0a-4283-11ef-8482-128e718ba88f', url: 'reports/segmentprofile5', minHeight: '77px'},
    {id: 'b35c6108-4255-11ef-8482-128e718ba88f', url: 'reports/segmentprofile1', minHeight: '74px'},
    {id: 'ae985dee-426d-11ef-8482-128e718ba88f', url: 'reports/segmentprofile2', minHeight: '75px'}
]

export const barGraph_data_ee0f401e_424d_11ef_8482_128e718ba88f = [
    Q2_2024, Q1_2024, Q4_2023, Q3_2023, H2_2022
]

/////////////////////////////////////////////

export const pieChart_data_70a2369b_fafb_11ee_8482_128e718ba88f = [
    {id: '3acf17ea-fb09-11ee-8482-128e718ba88f', url: 'reports/segmentprofile2', minHeight: '68px'},
    {id: 'db6aaade-fb6b-11ee-8482-128e718ba88f', url: 'reports/segmentprofile4', minHeight: '60px'},
    {id: '05ebc06c-fb69-11ee-8482-128e718ba88f', url: 'reports/segmentprofile3', minHeight: '77px'},
    {id: '62b7ce47-fb00-11ee-8482-128e718ba88f', url: 'reports/segmentprofile1', minHeight: '74px'},
    {id: '9a77b7c5-fb6d-11ee-8482-128e718ba88f', url: 'reports/segmentprofile5', minHeight: '75px'}
]

export const barGraph_data_70a2369b_fafb_11ee_8482_128e718ba88f = [
    Q1_2024, Q4_2023, Q3_2023, H2_2022
]

/////////////////////////////////////////////

export const pieChart_data_63357b94_b2da_11ee_9f55_0a1ea4c5532f = [
    {id: 'cabffcd1-b2db-11ee-9f55-0a1ea4c5532f', url: 'reports/segmentprofile3', minHeight: '82px'},
    {id: '8ab9b83e-b3a4-11ee-9f55-0a1ea4c5532f', url: 'reports/segmentprofile4', minHeight: '70px'},
    {id: 'f3f5082d-b3b2-11ee-9f55-0a1ea4c5532f', url: 'reports/segmentprofile5', minHeight: '71px'},
    {id: '3d7b33b0-b3a1-11ee-9f55-0a1ea4c5532f', url: 'reports/segmentprofile1', minHeight: '70px'},
    {id: 'c72cb1be-b3a2-11ee-9f55-0a1ea4c5532f', url: 'reports/segmentprofile2', minHeight: '85px'}
]

export const barGraph_data_63357b94_b2da_11ee_9f55_0a1ea4c5532f = [
    Q4_2023, Q3_2023, H2_2022
]

/////////////////////////////////////////////

export const pieChart_data_5e95d366_6bf1_11ee_817c_0a1ea4c5532f = [
    {id: 'eade11ab-6bf4-11ee-817c-0a1ea4c5532f', url: 'reports/segmentprofile1', minHeight: '85px'},
    {id: '8548059d-6bfa-11ee-817c-0a1ea4c5532f', url: 'reports/segmentprofile3', minHeight: '81px'},
    {id: '1d0704d6-6c58-11ee-817c-0a1ea4c5532f', url: 'reports/segmentprofile4', minHeight: '87px'},
    {id: 'ffc3e2d1-6bf7-11ee-817c-0a1ea4c5532f', url: 'reports/segmentprofile2', minHeight: '78px'},
    {id: '0e2f187d-6c62-11ee-817c-0a1ea4c5532f', url: 'reports/segmentprofile5', minHeight: '75px'}
]

export const barGraph_data_5e95d366_6bf1_11ee_817c_0a1ea4c5532f = [
    Q3_2023, H2_2022
]

/////////////////////////////////////////////

export const pieChart_data_9d0c623b_b2de_11ed_817c_0a1ea4c5532f = [
    {id: '07ca549b-59be-11ee-817c-0a1ea4c5532f', url: 'reports/segmentprofile1', minHeight: '75px'},
    {id: '2335f92b-59d6-11ee-817c-0a1ea4c5532f', url: 'reports/segmentprofile2', minHeight: '78px'},
    {id: '3f7e2b5d-b18a-11ed-817c-0a1ea4c5532f', url: 'reports/segmentprofile3', minHeight: '81px'},
    {id: '3783a34a-b1b5-11ed-817c-0a1ea4c5532f', url: 'reports/segmentprofile4', minHeight: '77px'},
    {id: '66a47969-b1b9-11ed-817c-0a1ea4c5532f', url: 'reports/segmentprofile5', minHeight: '75px'}
]

/////////////////////////////////////////////
/////////////////////////////////////////////
///////////////////////////////////////////// COMMON OBJECTS

