import React, { useState, useEffect } from 'react'
import Header from './header'
import { Lhs, LayoutFooter, Loader, TourGuide } from '../../components'
import { useParams, useHistory, useLocation } from "react-router-dom";
import { Box } from '@mui/material'
// import ReportsService from '../../services/reports.service'
import { getLogData, getImageUrl, timeStampToDate } from '../../util'
import { useGlobalStore } from '../../store'

import {
  PostLoginReportsOuterWrapper,
  LhsWrapper,
  RhsWrapper,
  ContentWrapper,
  ContentOuterWrapper,
  Container
} from './style'
import { HeaderProjectInfo } from './header/header'

const PostLoginReports = (props) => {
  const { children, onChange } = props
  const [ headerData, setHeaderData ] = useState({})
  const { pathname } = useLocation()
  const { id } = useParams()
  const { isTourGuideActive, setProjectIdValue } = useGlobalStore()

  const getHeaderInfo = () => {
    const projectIdValue = getLogData('GetStudies', 'GetStudies', {}, { StudyId: id })[0]
    const headerLogData = projectIdValue.ProjectId ? getLogData('GetProjects', 'GetProjects', {}, { ProjectId: projectIdValue.ProjectId })[0] : {}

    projectIdValue['IsAskIAForStudy'] = !!projectIdValue?.AskIA || !!projectIdValue?.IsAskIAEnabled

    setProjectIdValue(projectIdValue)

    const headerObj = {
      // isFreezed: projectIdValue.IsFreezed,
      // isPublished: projectIdValue.IsPublished,
      studyId: projectIdValue.StudyId,

      breadCrumb: headerLogData.ProjectType,
      title: `${headerLogData.BrandName || headerLogData.CategoryName} ${headerLogData.ProjectType}`,
      // subTitle: `${headerLogData.MarketName} | ${headerLogData.ProjectFrequency} | ${projectIdValue.Period}`,
      subTitle: `${headerLogData.MarketName} | ${projectIdValue.Period}`,
      lastUpdated: timeStampToDate(projectIdValue.ModifiedDate)
    }
    
    setHeaderData(headerObj)
  }

  const onChangeLhs = (param) => {
    onChange({...param, studyId:id})
  }

  useEffect(() => {
    getHeaderInfo()
  }, [pathname])

  return (
    <PostLoginReportsOuterWrapper>
      {isTourGuideActive && <TourGuide type='postLoginReports' />}

      {headerData && <Header {...headerData} />}
      <Container>
        <LhsWrapper>
          <Lhs color='#F7F7F9' isreportpage onChange={onChangeLhs}/>
        </LhsWrapper>
        <RhsWrapper>
          <ContentWrapper>
            <ContentOuterWrapper className='rhsContent'>
              <HeaderProjectInfo {...headerData}/>
              {children}
              <LayoutFooter />
            </ContentOuterWrapper>
          </ContentWrapper>
        </RhsWrapper>
      </Container>
    </PostLoginReportsOuterWrapper>
  )
}

export default PostLoginReports
