

export const ROLES_ADMIN = 'Admin'
export const ROLES_MEMBER = 'Member'
export const ROLES_ANALYST = 'Analyst'
export const ROLES_PUBLISHER = 'Publisher'
export const ROLES_JUNIOR_ANALYST = 'JuniorAnalyst'

export const ROLES_ALL = ['Admin', 'Member', 'Analyst', 'Publisher', 'JuniorAnalyst']

export const ROLES_MM_PRIMARY = ['Analyst', 'Publisher']
export const ROLES_MM_SECONDARY = ['Analyst', 'Publisher', 'JuniorAnalyst']

export const ROLES_CLIENT = ['Admin', 'Member']
export const ROLES_CLIENT_PRIMARY = ['Admin', 'Analyst', 'Publisher']
export const ROLES_CLIENT_SECONDARY = ['Admin', 'Member', 'Analyst', 'Publisher']



