import { styled } from '@mui/material/styles'
import { Box, FormControlLabel, Typography } from '@mui/material'

export const PPTWrap = styled(Box)(`
  width: 600px;
  height: 100vh;
`)

export const PPTHeader = styled(Box)(`
  display: flex;
  justify-content: space-between;
  padding:20px 30px;
  
  border-bottom: 1px solid #E7E7E7;
  margin-bottom:20px;
`)

export const PPTBody = styled(Box)(`
  // border-top: 1px solid #E7E7E7;
  padding:20px 30px;
  height: calc(100vh - 150px);
  overflow: auto;
`)

export const PPTBodyTabs = styled(Box)(`
  width:calc(100% - 60px);
  margin:0 10px 0 0;
  border:1px #E0E0E0 solid;
`)

export const TabLink = styled('button')(`
  width:50%;
  color: #000;
  text-align: center;
  font-size: 14px;
  font-weight: 300;
  text-transform: capitalize;
  height:40px;
  line-height:40px;
  background-color:#fff;
  cursor:pointer;
  border:0;

  &.active{
    background-color:#F1F1F3;
    color: #095BBD;
    font-weight: 600;
  }
`)

export const Heading = styled(Typography)(`
  font-weight: 500;
  font-size: 14px;
  color: #000;
  margin-bottom: 15px;
`)

export const SubHeading = styled(Typography)(`
  font-weight: 500;
  font-size: 12px;
  color: #000;
  margin: 10px 0 5px;
  width:100%;
`)

export const SubLHSWrap = styled(Box)(`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;

  & > p:first-of-type {
    margin-top:0;
  }
`)

export const SubLHSItemWrapper = styled(Box)(`
  margin-left:-4px;
  display:flex;
  & label {
    align-items: center !important;
  }
`)

export const SubLHSItem = styled(Box)(`
  width: 33%;
  display:flex;
  justify-content:space-between;
  margin-bottom: 10px;
  padding-right: 10px;
  & > label {
    margin-right:0;
    align-items: self-start;
  }
  & .select-all {
    color: #095BBD;
    & .MuiTypography-root {
      font-size: 12px;
      font-weight: 500;
      margin-top: 2px;
    }
    & path {
      fill: #095BBD;
    }
  }
`)

export const FormGroupLabelWrap = styled(FormControlLabel)(`
  & .MuiTypography-root {
    font-size: 12px;
    font-weight: 300;
  }
  & .MuiCheckbox-root{
    padding: 0;
    margin: 3px 10px auto 12px;
  }
`)

export const Breaker = styled(Box)(`
  width:100%;
  background:#d3d3d3;
  height:1px;
  display:block;
  margin:10px 0 20px;
`)

export const SubLHSOuterWrap = styled(Box)(`
  width:100%;
  &.type_progression {
    // margin-top:20px;
    // padding-top:20px;
    // border-top:1px #d3d3d3 solid;
  }
`)

export const HeadingForDrawer = styled(Typography)(`
  font-size: 16px;
  font-weight: 500;
  padding-bottom:20px;
`)
