import React, { useEffect, useState } from 'react'
import { LoaderWrapper, ReportHeading, ReportOuterWrapper } from '../../../../components'
import MiningTable from './miningTable'
import reportsService from '../../../../services/reports.service'
import { useUpdateLhs } from '../../../../hooks/useUpdateLhs'
import { getObjectAsArray, exportExcel_addSegments } from '../../../../util'
import OpenInFullIcon from '@mui/icons-material/OpenInFull'
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen'
import { ContentWrap, ExpandButton, RootWrap, ExpandButtonWrap, Pipe } from './style'

const ReportMining = (props) => {
  const [isExpendedAll, setExpendedAll] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [state, setState] = useState([])
  const [miningExcelData, setMiningExcelData] = useState([])
  const {updateSubLhsApiLoaded} = useUpdateLhs()

	const processData = (data) => {
		const attributeData = getObjectAsArray(data?.DriverAttributes?.DriverAttributeElement)
		const arr = attributeData.map(item => {
			const mappedData = getObjectAsArray(item.DriverSubAttributes.DriverSubAttributeElement);
			return ({
				...item,
				data: mappedData
			})
		})
		return arr;
	}
  
	const getMiningData = () => {
		setLoading(true)
		const payload = {
			insightsElementId: props.InsightsElementId
		}
		reportsService.getInsightsMiningFramework(payload).then(resp => {
			const arr = resp.map(item => {
				return ({
					...item,
					data: processData(item)
				})
			})
			setState(arr);

			let excelData = []
			for(let a=0; a<arr.length; a++){
				const data1 = {value: arr[a].DriverName}
				const dataL2 = Array.isArray(arr[a]?.DriverAttributes?.DriverAttributeElement) ? arr[a].DriverAttributes?.DriverAttributeElement : (arr[a]?.DriverAttributes?.DriverAttributeElement ? [arr[a]?.DriverAttributes?.DriverAttributeElement] : [])
				if(!!dataL2?.length){
					for(let b=0; b<dataL2.length; b++){
						if(dataL2[b]?.AttributeName){
							const data2 = {value: dataL2[b].AttributeName}
							const dataL3 = dataL2[b].DriverSubAttributes
							const dataL3Array = Array.isArray(dataL3?.DriverSubAttributeElement) ? dataL3.DriverSubAttributeElement : [dataL3.DriverSubAttributeElement]							
							if(!!dataL3Array){
								for(let c=0; c<dataL3Array.length; c++){
									const data3 = {value: dataL3Array[c]?.SubAttributeName}
									// excelData.push([data1, data2, data3, {value: `${dataL3Array[c]?.PositivePercent}%`}, {value: `${dataL3Array[c]?.NeutralPercent}%`}, {value: `${dataL3Array[c]?.MixedPercent}%`}, {value: `${dataL3Array[c]?.NegativePercent}%`}, {value: `${dataL3Array[c]?.Percent}%`}])
									excelData.push([data1, data2, data3, ...exportExcel_addSegments(dataL3Array[c], true)])
								}
							}
							else{
								// excelData.push([data1, data2, {value: '-'}, {value: `${dataL2[b].PositivePercent}%`}, {value: `${dataL2[b].NeutralPercent}%`}, {value: `${dataL2[b].MixedPercent}%`}, {value: `${dataL2[b].NegativePercent}%`}, {value: `${dataL2[b].Percent}%`}])
								excelData.push([data1, data2, {value: '-'}, ...exportExcel_addSegments(dataL2[b], true)])
							}
						} else {
							// excelData.push([data1, {value: dataL2[b].AttributeName}, {value: '-=3'}, {value: arr[a].PositivePercent}, {value: arr[a].NeutralPercent}, {value: arr[a].MixedPercent}, {value: arr[a].NegativePercent}, {value: arr[a].Percent}])
						}
					}
				} else {
					// excelData.push([data1, {value: '-'}, {value: '-'}, {value: arr[a].PositivePercent}, {value: arr[a].NeutralPercent}, {value: arr[a].MixedPercent}, {value: arr[a].NegativePercent}, {value: arr[a].Percent}])
				}
			}

            updateSubLhsApiLoaded(props)
			setMiningExcelData(excelData)
		}).catch(error => {
			console.log(error)
		}).finally(() => {
			setLoading(false)
		})
	}

	useEffect(() => {
		getMiningData()
	}, [])

  return (
    <>
    <ReportHeading additionalButton={<AdditionalButtons isExpendedAll={isExpendedAll} expandCollapseFunc={setExpendedAll} />} downloadId={props.type} downloadIdData={miningExcelData} {...props} excel={false} />
    <ReportOuterWrapper>
		<RootWrap className={ props.InsightsElementType === 'InsightsMiningFrameworkWithoutSentiments' ? 'withoutSentiment' : '' } id={props.type}>
		<LoaderWrapper loading={isLoading} loadOnlyApi={props.loadOnlyApi}>
			<ContentWrap>
				<MiningTable data={state} isExpendedAll={isExpendedAll} />
			</ContentWrap>
		</LoaderWrapper>
		</RootWrap>
    </ReportOuterWrapper>
  </>
  )
}

export default ReportMining;

const AdditionalButtons = ({ isExpendedAll, expandCollapseFunc }) => {
  return (
    <>
      <ExpandButtonWrap className='BtnExpandAllMining'><ExpandButton expended={isExpendedAll? "true": ""} variant='standard' color='primary' onClick={() => expandCollapseFunc(true)}><OpenInFullIcon />Expand All</ExpandButton></ExpandButtonWrap>
      <Pipe className='pipe' style={{ lineHeight: 2 }}>|</Pipe>
      <ExpandButtonWrap className='BtnCollapseAllMining'><ExpandButton expended={!isExpendedAll ? "true": ""} variant='standard' color='primary' onClick={() => expandCollapseFunc(false)}><CloseFullscreenIcon />Collapse All</ExpandButton></ExpandButtonWrap>
      <Pipe className='pipe' style={{lineHeight:2}}>|</Pipe>
    </>
  )
}