import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import { exportToPPT_tableCss } from '../../../../util'
import { MEDIA } from "../../../../constants";

export const RootWrap = styled(Box)(`
	width: 100%;
	// padding: 20px 30px 50px;

	&.personaSection1 {display:block;}
	&.personaSection2 {display:none;}
	&.exportToPPT {padding:0; width:1200px;}
	&.exportToPPT > div {margin:0; width:100%;}
	&.exportToPPT .tabsWrapper {display:none;}
	&.exportToPPT .segmentWrap {width: 100% !important;}
	&.exportToPPT .segmentWrap .segmentTitle { width:170px; }
	&.exportToPPT .segmentWrap > div {border:0; width: 100% !important; }
	&.exportToPPT .segmentWrap > div:before {position:relative; left:auto; padding:0 0 10px 0; top:auto; border:0; font-weight:bold; width:auto;}
	&.exportToPPT .PropertyListingHeading {position:relative; left:auto; padding:0 0 10px 0; top:auto; border:0; font-weight:bold; min-width:100%;}
	&.exportToPPT .PropertyList {width: 20% !important;}
	&.exportToPPT .BehaviorListing {width: 25% !important;}
	&.exportToPPT .personaDescription {display:flex;}
	&.exportToPPT .personaDescription img {position:relative; top:0; left:0; padding-right:20px;}
	&.exportToPPT.personaSection1 .opportunities{display:none}
	&.exportToPPT.personaSection2{display:block}
	&.exportToPPT .personaListWrapper > div {
		min-width: 1100px;
	}
	${ exportToPPT_tableCss }

	&.removedExportToPPT .segmentWrap { width:100% !important; }
	&.removedExportToPPT .OuterWrapper{
		background: #fff;
		box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
		margin-left: 141px;
		border-radius: 2px;
		width: calc(100% - 141px);
	}
	
	@media (max-width: ${MEDIA.lg}) {
		padding: 30px 20px 40px;
	}
`)

export const ContentWrap = styled(Box)(`
	background: #fff;
	box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
	margin-left: 141px;
	border-radius: 2px;
	width: calc(100% - 141px);
`)

export const Divider = styled(Box)(`
	border-bottom:1px #EEEEEE solid;
	width:calc(100% + 16px);;
	padding-top: 8px;
	margin-left: -8px;
	margin-bottom: 8px;
`)

export const PersonaDescriptionWrapper = styled(Box)(`
	position:relative;
`)

export const PersonaDescription = styled(Box)(`
	width:100%;
	font-weight: 300;
	font-size: 14px;
	display:flex;
	& > div:last-child {
		border-right:0;
		padding-right:0;
		margin-right:0;
	} 
`)

export const PersonaList = styled(Box)(`
	border-right:1px #EEE solid;
	padding-right: 10px;
	margin-right: 10px;
	// min-width: 1100px;
	& b {
		font-weight:500;
	}
`)

export const SegmentWrap = styled(Box)(`
	width: calc(100% - 0px);
`)

export const SegmentRow = styled(Box)( props => `
	font-weight: 300;
	font-size: 14px;
	margin-bottom: 5px;
	border: 1px solid #EEEEEE;
	border-left: 0;
	border-radius: 0 2px 2px 0;
	padding: 10px;
	position: relative;
	min-height: 60px;
	display:flex;
	flex-direction: column;
	justify-content: center;
	&:before {
		content: attr(data-segment);
		position: absolute;
		left: -141px;
		width: 141px;
		height: calc(100% + 2px);
		display: flex;
		align-items: start;
		border: 1px solid #EEEEEE;
		border-right: 0;
		padding: 10px;
		top: -1px;
		border-radius: 2px 0px 0px 2px;
		font-weight: 500;
		font-size: 14px;
		padding-top: ${props?.segmenttop ? props?.segmenttop : 10}px
	}
	& .outerText{
		min-width: 32px;
		font-size: 12px;
		margin-left: 6px;
		font-weight: 300;
	}
`)

export const SegmentRowContent = styled(Box)(`
	display: flex;
	flex-direction:column;
	position: relative;
`);

export const SegmentTitle = styled(Typography)(`
	font-size: 12px;
	font-weight: 300;
	width: 65px;
	min-width: 100px;
	text-align: right;
	margin-right: 10px;
	line-height: 12px;
`)

export const SegmentSubTitle = styled(Typography)(`
	width: auto;
	min-width: 100px;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	margin: 0 0 0 -10px;
`)

export const BarWrap = styled(Box)(`
	width: 100%;
	&.association {
		background: #F1F1F3;
		border-radius: 0px 20px 20px 0px;
		padding: 4px;
		& .barSlice {
			border-radius: 0px 20px 20px 0px;
			height: 8px !important;
		}
	}
	& .barRoot, .barItemRoot, .barSliceWrap, .barSlice {
		height: 5px !important;
	}
	& .barSlicePercentage, & .barSliceLabel{
		font-size: 12px !important;
	}
`);

export const IssuesWrap = styled(Box)(`
	position: absolute;
	left: calc(100% + 5px);
	display: flex;
	align-items: center;
	& .radio {
		width: 10px;
		height: 10px;
		min-width: 10px;
		min-height: 10px;
		border: 1px solid #7E7E7E;
		margin-right: 4px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		& .box {
			width: 4.5px;
			height: 4.5px;
			min-width: 4.5px;
			min-height: 4.5px;
			background: #7E7E7E;
			border-radius: 50%;
		}
	}
	& .radio-text {
		font-size: 12px;
		font-weight: 300;
		white-space: nowrap;
	}
`);

export const ComparisonViewWrap = styled(Box)(`
	& .segment-row {
		// width: 65% !important;
	}
	& .segment-row-content {
		margin-left: -130px;
		padding-right: 40px;
		& .segment-title {
			margin-right: 20px;
		}
		& .barRoot, .barItemRoot, .barSliceWrap, .barSlice {
			height: 20px !important;
		}
	}
`)

export const PropertyListingOutertWrapper = styled(Box)(`
	width:100%;
	display:flex;
	flex-wrap:wrap;
	justify-content:flex-start;
	position:relative;
`)

export const PropertyListingHeading = styled(Box)(`
	position:absolute;
	top:0;
	left:-140px;
	font-weight: 500;
    font-size: 14px;
	max-width: 115px;
    width: 100%;
`)

export const PropertyListingtWrapper = styled(Box)(`
	width:calc(33.3%);
`)

export const PropertyListingtHeading = styled(Box)( props => `
	max-width:185px;
	width:100%;
	font-weight: 500;
	font-size: 14px;
	color:${props?.color};
	padding-bottom:10px;
`)

export const Gender = styled('img')(`
	width: 98px;
	position: absolute;
	top: -13px;
	left: -128px;
`)

const propertyListSelected = (props) => `
	display: flex;
	align-items: center;
	color:#fff;
	background: ${props.color};
	position: relative;
	font-weight:400;
	&:after{
		position: absolute;
		content: '';
		// top: 50%;
		right: 10px;
		border-bottom: 1px white solid;
		border-left: 1px white solid;
		height: 6px;
		width: 11px;
		transform: translate(0, -30%) rotate(-45deg);
	}
`

export const PropertyList = styled(Box)(props => `
	max-width:185px;
	width:100%;
	font-weight: 300;
	font-size: 14px;
	color:#000;
	border-radius: 4px;
	padding:0 28px 0 10px;
	line-height: 26px;
	margin:2px 0;

	${props?.selected ? propertyListSelected(props) : null}
`)

export const BehaviorListingOutertWrapper = styled(Box)(`
    display: flex;
    flex-wrap: wrap;
`)

export const BehaviorListingtWrapper = styled(Box)(`
	width:calc(33.3%);
	padding:10px;
`)

export const BehaviorListingtHeading = styled(Box)( props => `
	font-weight: 500;
	font-size: 14px;
	display: flex;
    align-items: center;

	& span {
		background: ${props?.color || 'none'};
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.07);
		height:40px;
		width:40px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		margin-right: 10px;
	}
`)

export const BehaviorListUl = styled('ul')(`
	width: 100%;
	padding-left: 70px;
`)

export const BehaviorList = styled('li')(`

`)

export const DriversListingOutertWrapper = styled(Box)(`
	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;
	padding:0;
`)

export const DriversListingtWrapper = styled(Box)(`
	width:150px;
	padding:10px;
	display:flex;
	justify-content:center;
	flex-direction:column;
`)

export const DriversListingPieChart = styled(Box)(`
	width:100px;
	height:100px;
	margin:0 14px;
	display:flex;
	justify-content:center;
	align-items: center;
	flex-direction: column;
	position:relative;
	padding:10px;
	& p {
		text-align: center;
		position:relative;
		z-index:1;
	}
`)

export const DriversListingPie = styled(Box)(`
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;
`)

export const DriversListingTitle = styled(Typography)(`
	font-weight: 300;
	line-height: 12px;
	color: #000000;
	font-size: 12px;
	padding-bottom:5px;
`)

export const DriversListingValue = styled(Typography)( props => `
	font-weight: 600;
	line-height: 14px;
	font-size: 14px;
	color: ${props?.color};
`)

export const DriversListingtDescription = styled(Box)( props => `
	padding-top:5px;
	font-weight: 300;
	font-size: 14px;
	line-height: 16px;
	text-align: center;
	color: ${props?.color};
	
	display:flex;
	justify-content:center;
	align-items: center;
	flex-direction: column;

	& svg {
		margin-bottom:5px;
	}
`)

export const OpportunitiesListingOutertWrapper = styled(Box)( props => `

`)

export const OpportunitiesInnerWrapper = styled(Box)( props => `

`)

export const OpportunitiesContentWrapper = styled(Box)( props => `
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    // text-transform: capitalize;
    color: #000000;

    & b, & strong {
        font-weight: 500;
    }

    & p {
        padding-bottom:10px;
    }

    & > ul {
        list-style-position: outside;
        margin-left: 0px;
        list-style: none;

        > li {
            background: #FFFFFF;
            border-radius: 4px;
            padding: 30px 0 20px;
            margin: 20px 0 0;

            &:last-child{
                margin-bottom: 0;
                padding-bottom: 0;
            }

            > ul > li:has(> hr) {display:none;}

            > h4 {
				color: ${props.color};
                text-transform: capitalize;
                background: #F7F7F9;
                border-radius: 2px;
                font-weight: 500;
                width: fit-content;
                min-width:220px;
                padding: 10px 10px 10px 40px;
                font-size: 14px;
                margin: -50px 0 10px;
                text-decoration: none;
                display:block;
				position:relative;
            }
            > h4::before, > h4::after {
				content:'';
				position:absolute;
				left:10px;
				top:12px;
				z-index:1;
				display:block;
            }
            > h4::before {
				width:22px;
				height:22px;
				border:2px ${props.color} solid;
				border-radius:14px;
            }
            > h4::after {
				left: 19px;
				top: 11px;
				width: 4px;
				height: 4px;
                background: red;
                background: #F7F7F9;
				box-shadow: 10px 9px 0 0 #F7F7F9, 7px 17px 0 0 #F7F7F9;
            }

            & > ul {
                margin-left:30px;
                list-style:disc;
                & > li > ul {
                    margin:5px 5px 5px 20px;
                    list-style-type:circle; // new
                }
            }

        }
    }
`)



