import React, {useState } from 'react'
import Popover from '@mui/material/Popover'
import { IconButton } from '@mui/material'
import { InfoOutlined } from '@mui/icons-material'
import {ToolTipRoot, ToolTipWrap} from './style'
import CloseButton from '../Atom/CloseButton'

const ToolTip = ({children, sx}) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <ToolTipRoot>
      <IconButton className='toolTipButtonIconWrap' aria-describedby={id} variant="contained" onClick={handleClick}>
        <InfoOutlined />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={{
          width: "90%",
          ...(sx || {})
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
       
        <ToolTipWrap className='tooltip-wrap'>
         <CloseButton onClick={handleClose} />
          {children}
        </ToolTipWrap>
      </Popover>
    </ToolTipRoot>
  )
}

export default ToolTip