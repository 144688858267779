import { styled } from '@mui/system'
import { Box, TextField } from '@mui/material'

export const FormLabel = styled('label')(`
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 19px;
  color: #000;
  width:100%;
  margin-bottom: 10px;
`)

export const RteWrapper = styled(Box)(({ theme }) => `
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  & .tox-statusbar__text-container {
    display:none !important;
    opacity:0 !important;
  }
`)

export const FormReq = styled('span')(`
  font-size: 12px;
  color: #DA2A52;
  padding-left:5px;
`)

export const FormError = styled(Box)(`
  width:100%;
  display:block;
  line-height: 20px;

  font-size: 0.75rem;
  font-weight: 400;
  margin: 4px 14px 0 14px;
  color: #D14343;
  background-color: transparent !important;
`)
