import React from 'react'
import { InsightsWrapper } from './style'

const InsightsRteContent = ({content, id}) => {
  return (
    // <InsightsWrapper id={id} className='insightsWrapper' dangerouslySetInnerHTML={{__html: content }} />
    <InsightsWrapper id={id} className='insightsWrapper' dangerouslySetInnerHTML={{__html: content }} />
  )
}
export default InsightsRteContent
