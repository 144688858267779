import React from 'react'
import PropTypes from 'prop-types'
import IconButton from '@mui/material/IconButton'
import {close} from '../../assets/images/icons'

import {
  ModalWrapper,
  Overlay,
  Popup,
  CloseWrap,
  Title
} from './style'

const Modal = ({ children, title, showClose = false, onClose, modalClass="", popupClass=""}) => {
  const closeModal = () => {
    if (showClose) onClose()
  }
  return (
    <ModalWrapper className={`modalWrapper ${modalClass}`}>
      <Overlay onClick={closeModal} />
      <Popup className={`PopupWrapper ${popupClass}`}>
        {showClose && <CloseWrap className={'closeBtn'} onClick={closeModal}><IconButton color='primary'><img src={close} alt='Close' width='24px' /></IconButton></CloseWrap>}
        {title && <Title>{title}</Title>}
        {children}
      </Popup>
    </ModalWrapper>
  )
}

export default Modal
