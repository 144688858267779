import React, { useState } from 'react'

import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import { cross, plus, minus } from '../../../assets/images/icons'
import { SearchBox } from '../../../components';

import { OuterWrapper, InnerWrapper, ChipLabel, SelectedFilters, SelectedChip, ActionFilters, MenuOuterWrapper, MenuInnerWrapper, FilterButton, AccordionWrapper, AccordionContent, AccordionHeading, FilterButtonWrap } from './style'

const Filter = (props) => {
  const { accordionHeading, onChange, onSearch } = props

  const [expanded, setExpanded] = useState (false);
  const [anchorElUser, setAnchorElUser] = useState(null)

  const filterLen = accordionHeading.map(a => a.filterIndex).flat(1)

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  const HandlerChangeCheckBox = (e, o) => {
    onChange({...o, checked:e.target.checked})
  }

  const HandlerDeleteClick = (e, o) => {
    onChange({...o, checked:false})
  }

  const handlerSearchBox = (e) => {
    onSearch(e)
  }

  const applyFilter = () => {
    handleCloseUserMenu()
  }

  return (
    <OuterWrapper>
      <InnerWrapper>
        <SelectedFilters>
          {filterLen.length !== 0 && <ChipLabel>Filter By:</ChipLabel>}
          {accordionHeading.map(a => {
            return a.data.map((b,i) => {
              return (<div key={i}>{b.checked && <SelectedChip onClick={(e)=>HandlerDeleteClick(e,{...b, key:a.sortKey})}>{b[a.sortKey]}<img src={cross} height='14px' /></SelectedChip>}</div>)
            })
          })}
        </SelectedFilters>
        <ActionFilters>
          <SearchBox onClick={handlerSearchBox} />
          <FilterButtonWrap className='BtnProjectsFilter'>
            <FilterButton color='primary' variant='contained' onClick={handleOpenUserMenu}>Filter</FilterButton>
          </FilterButtonWrap>
        </ActionFilters>
      </InnerWrapper>

      <MenuOuterWrapper
        className='MenuWrapper'
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <MenuInnerWrapper>
          {accordionHeading.map((a,i) => {
            return (
              <AccordionWrapper key={i} expanded={expanded === a.sortKey} onChange={handleChange(a.sortKey)}>
                <AccordionHeading expandIcon={<img src={expanded === a.sortKey ? minus : plus} width='11px' />} >
                  <Typography sx={{ flexShrink: 0 }}>{a.field}</Typography>
                </AccordionHeading>
                <AccordionContent>
                  {a.data.map((object,j) => (
                    <FormControlLabel key={j} control={<Checkbox size='medium' variant='outlined' color='dark' defaultChecked={object.checked} />} label={object[a.sortKey]} onClick={(e)=>HandlerChangeCheckBox(e, object)} />
                  ))}
                </AccordionContent>
              </AccordionWrapper>
            )
          })}
          {/* <MuiButton variant='contained' color='primary' sx={{margin:'20px', width:'calc(100% - 40px)'}} onClick={applyFilter}>Apply</MuiButton> */}
        </MenuInnerWrapper>
      </MenuOuterWrapper>
    </OuterWrapper>
  );
}

export default Filter

