import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const DatePickerWrap = styled(Box)(`
    & .MuiBox-root{
        display: flex;
        img {
            width: 14px;
            height: 13px;
        }
    }
`)