
import { dashboard, projects, userManagement, resources, help, reportsBackground, reportsExecutiveSummary, reportsKeyFindings, reportsResearchSection1, reportsResearchSection2, reportsDeepDiveAnalysis, reportsSandBox } from '../../assets/images/icons'

import { 
  ROUTE_PROJECTS, ROUTE_INITIATE_PROJECTS, ROUTE_USER_MANAGEMENT, ROUTE_RESOURCES, ROUTE_HELP,
  NAME_PROJECTS, NAME_INITIATE_PROJECTS, NAME_USER_MANAGEMENT, NAME_RESOURCES, NAME_HELP, ROUTE_REPORTS_BACKGROUND, NAME_REPORTS_BACKGROUND, ROUTE_REPORTS_EXECUTIVE_SUMMARY, NAME_REPORTS_EXECUTIVE_SUMMARY, ROUTE_REPORTS_KEY_FUNDINGS, NAME_REPORTS_KEY_FUNDINGS, ROUTE_REPORTS_RESEARCH_SECTION1, NAME_REPORTS_RESEARCH_SECTION1, ROUTE_REPORTS_RESEARCH_SECTION2, NAME_REPORTS_RESEARCH_SECTION2, ROUTE_REPORTS_DEEP_DIVE_ANALYSIS, NAME_REPORTS_DEEP_DIVE_ANALYSIS, ROUTE_REPORTS_SANDBOX, NAME_REPORTS_SANDBOX
} from '../../constants'

export const ROUTE_DATA = {
  background: {
    id: 'background',
    href: ROUTE_REPORTS_BACKGROUND,
    title: NAME_REPORTS_BACKGROUND,
    label: NAME_REPORTS_BACKGROUND,
    icon: reportsBackground,
    userType: ['Admin']
  },
  executivesummary: {
    id: 'executivesummary',
    href: ROUTE_REPORTS_EXECUTIVE_SUMMARY,
    title: NAME_REPORTS_EXECUTIVE_SUMMARY,
    label: NAME_REPORTS_EXECUTIVE_SUMMARY,
    icon: reportsExecutiveSummary,
    userType: ['Admin']
  },
  keyfindings: {
    id: 'keyfindings',
    href: ROUTE_REPORTS_KEY_FUNDINGS,
    title: NAME_REPORTS_KEY_FUNDINGS,
    label: NAME_REPORTS_KEY_FUNDINGS,
    icon: reportsKeyFindings,
    userType: ['Admin']
  },
  researchsection_misc1: {
    id: 'researchsection_misc1',
    href: ROUTE_REPORTS_RESEARCH_SECTION1,
    title: NAME_REPORTS_RESEARCH_SECTION1,
    label: NAME_REPORTS_RESEARCH_SECTION1,
    icon: reportsResearchSection1,
    userType: ['Admin']
  },
  researchsection_misc2: {
    id: 'researchsection_misc2',
    href: ROUTE_REPORTS_RESEARCH_SECTION2,
    title: NAME_REPORTS_RESEARCH_SECTION2,
    label: NAME_REPORTS_RESEARCH_SECTION2,
    icon: reportsResearchSection2,
    userType: ['Admin']
  },
  deepdive: {
    id: 'deepdive',
    href: ROUTE_REPORTS_DEEP_DIVE_ANALYSIS,
    title: NAME_REPORTS_DEEP_DIVE_ANALYSIS,
    label: NAME_REPORTS_DEEP_DIVE_ANALYSIS,
    icon: reportsDeepDiveAnalysis,
    userType: ['Admin']
  },
  sandbox: {
    id: 'sandbox',
    href: ROUTE_REPORTS_SANDBOX,
    title: NAME_REPORTS_SANDBOX,
    label: NAME_REPORTS_SANDBOX,
    icon: reportsSandBox,
    userType: ['Admin']
  },
}

const DEFAULT_ROUTES = [
  {
    href: ROUTE_PROJECTS,
    title: NAME_PROJECTS,
    id: 'dashboard',
    icon: dashboard,
    userType: ['Admin', 'Member', 'Analyst', 'Publisher', 'JuniorAnalyst']
  },
  {
    href: ROUTE_INITIATE_PROJECTS,
    title: NAME_INITIATE_PROJECTS,
    id: 'initiateProjects',
    icon: projects,
    userType: ['Admin', 'Member', 'Analyst', 'Publisher']
  },
  {
    href: ROUTE_USER_MANAGEMENT,
    title: NAME_USER_MANAGEMENT,
    id: 'userManagement',
    icon: userManagement,
    userType: ['Admin', 'Publisher']
  },
  {
    href: ROUTE_RESOURCES,
    title: NAME_RESOURCES,
    id: 'resources',
    icon: resources,
    userType: ['Admin', 'Member', 'Analyst', 'Publisher']
  },
  // {
  //   href: ROUTE_HELP,
  //   title: NAME_HELP,
  //   icon: help,
  //   userType: ['Admin', 'Member', 'Analyst', 'Publisher']
  // }
]

export default DEFAULT_ROUTES
