import React, { useState, useEffect } from 'react'
import { ReportHeading, LoaderWrapper, VerbatimGrid, VerbatimImageVideoGrid, ReportOuterWrapper } from '../../../../components'
import ReportsService from '../../../../services/reports.service'
import { useUpdateLhs } from '../../../../hooks/useUpdateLhs'
// import { useParams } from 'react-router-dom'
import {  Typography } from '@mui/material'
import { LEGEND_COLOR_MAP } from '../../../../constants'
import { close } from '../../../../assets/images/icons'
import { getImageUrl, getObjectAsArray } from '../../../../util'

import { Wrapper, OuterWrapper, InnerWrapper, Row, Lhs, Rhs, Logo, GraphDescription, GraphLegendWrapper, GraphLegend, MenuWrapper, MenuContent, MenuClose, MenuTitle } from './style'
const ReportAura = (props) => {

    // const { enqueueSnackbar } = useSnackbar()
    const [auraLoading, setAuraLoading] = useState(true)
    const [auraData, setAuraData] = useState([])
    const [auraEntityData, setAuraEntityData] = useState({})
    const [auraDetailData, setAuraDetailData] = useState([])
    const [auraExcelData, setAuraExcelData] = useState([])

    const [anchorElUser, setAnchorElUser] = useState(null)
    const [auraTitle, setAuraTitle] = useState(null)
    const [auraTitleColor, setAuraTitleColor] = useState(null)
    const {updateSubLhsApiLoaded} = useUpdateLhs()
    const [eventCount, setEventCount] = useState(1)

    const loadAura = () => {
        setAuraLoading(true)
        ReportsService.reportAuraApi({ insightsElementId: props.InsightsElementId, entityId: props.EntityId })
            .then((resp) => {
                let excelData = []
                for(let a=0; a<resp.aura.length; a++){
                    excelData.push([{value: resp.aura[a].Word}, {value: resp.aura[a].Sentiment}])
                }
                setAuraEntityData(resp?.entity)
                setAuraData(resp.aura)
                setAuraExcelData(excelData)
                setAuraLoading(false)
                updateSubLhsApiLoaded(props)
                setEventCount(p => p+1)
            })
            .catch((error) => {
                const resMessage = error
                console.log('ERROR : ', resMessage)
                setAuraLoading(false)
            })
    }

    const loadAuraDetail = (data) => {
        setAuraDetailData(data?.VerbatimElement || [])
    }

    const auraClicked = (event, paramArray) => {
        const array = {
            ...paramArray,
            Verbatim:{VerbatimElement:getObjectAsArray(paramArray?.Verbatim?.VerbatimElement)}
        }

        // if (!array?.Verbatim) return
        if (array.Verbatim.VerbatimElement.length === 0) return
        loadAuraDetail([])
        setAnchorElUser(null)

        setAuraTitleColor(array.Sentiment)
        setAuraTitle(array?.Word)
        loadAuraDetail(array?.Verbatim)
        // loadAuraDetail(verbatimData)
        handleOpenUserMenu(event)

    }

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget)
    }

    const handleCloseUserMenu = () => {
        loadAuraDetail([])
        setAnchorElUser(null)
    }

    useEffect(() => {
        if (auraData.length === 0) loadAura()
    }, [])

    useEffect(() => {
        if (Boolean(anchorElUser) === true) {
            setTimeout(() => {
                const menuWrap = document.getElementById('auraMenuWrapper').querySelector('.MuiMenu-paper')
                menuWrap.style.height = `${menuWrap.offsetHeight}px`;
            }, 1000)
        }
    }, [anchorElUser])
    

    return (
        <>
            <ReportHeading resetInsightHeight={eventCount} downloadId={props.type} downloadIdData={auraExcelData} subHeading='' {...props} />
            <ReportOuterWrapper>
                <Wrapper id={props.type}>
                    <LoaderWrapper loading={auraLoading} loadOnlyApi={props.loadOnlyApi}>
                        <OuterWrapper>
                            {!!auraData?.length && <InnerWrapper className={`auraTitle ${props.InsightsElementType}`}>
                                {!!auraEntityData?.EntityLogo.length && <Logo className='logoWrapper'><img src={`${getImageUrl('auraLogo',(auraEntityData.EntityLogo), 'png' )}`} alt={auraEntityData.EntityName} /></Logo>}

                                <Row className='box1' sx={{ fontSize: '45px' }}>
                                    <Lhs sx={{ width: 'calc(50% - 24px)' }}><span onClick={(e) => auraClicked(e, auraData[0])} className={auraData[0]?.Sentiment}>{auraData[0]?.Word}</span></Lhs>
                                    <Rhs sx={{ width: 'calc(50% - -6px)' }}><span onClick={(e) => auraClicked(e, auraData[1])} className={auraData[1]?.Sentiment}>{auraData[1]?.Word}</span></Rhs>
                                </Row>

                                <Row className='box2' sx={{ fontSize: '40px' }}>
                                    <Lhs sx={{ width: 'calc(50% - 66px)' }}><span onClick={(e) => auraClicked(e, auraData[2])} className={auraData[2]?.Sentiment}>{auraData[2]?.Word}</span></Lhs>
                                    <Rhs sx={{ width: 'calc(50% - 44px)' }}><span onClick={(e) => auraClicked(e, auraData[3])} className={auraData[3]?.Sentiment}>{auraData[3]?.Word}</span></Rhs>
                                </Row>

                                <Row className='box3' sx={{ fontSize: '35px' }}>
                                    <Lhs sx={{ width: 'calc(50% - 141px)', fontSize: '40px', lineHeight: '53px', paddingBottom: '8px' }}><span onClick={(e) => auraClicked(e, auraData[4])} className={auraData[4]?.Sentiment}>{auraData[4]?.Word}</span></Lhs>
                                    <Rhs sx={{ width: 'calc(50% - 119px)' }}><span onClick={(e) => auraClicked(e, auraData[5])} className={auraData[5]?.Sentiment}>{auraData[5]?.Word}</span></Rhs>
                                </Row>

                                <Row className='box4' sx={{ fontSize: '35px', lineHeight: '14px', paddingBottom: '13px' }}>
                                    <Lhs sx={{ width: 'calc(50% - 175px)' }}><span onClick={(e) => auraClicked(e, auraData[6])} className={auraData[6]?.Sentiment}>{auraData[6]?.Word}</span></Lhs>
                                    <Rhs sx={{ width: 'calc(50% - 159px)', marginTop: '-16px' }}><span onClick={(e) => auraClicked(e, auraData[7])} className={auraData[7]?.Sentiment}>{auraData[7]?.Word}</span></Rhs>
                                </Row>

                                <Row className='box1' sx={{ fontSize: '35px' }}>
                                    <Lhs sx={{ width: 'calc(50% - 191px)' }}><span onClick={(e) => auraClicked(e, auraData[8])} className={auraData[8]?.Sentiment}>{auraData[8]?.Word}</span></Lhs>
                                    <Rhs sx={{ width: 'calc(50% - 178px)', marginTop: '-11px' }}><span onClick={(e) => auraClicked(e, auraData[9])} className={auraData[9]?.Sentiment}>{auraData[9]?.Word}</span></Rhs>
                                </Row>

                                <Row className='box2' sx={{ fontSize: '35px' }}>
                                    <Lhs sx={{ width: 'calc(50% - 197px)' }}><span onClick={(e) => auraClicked(e, auraData[10])} className={auraData[10]?.Sentiment}>{auraData[10]?.Word}</span></Lhs>
                                    <Rhs sx={{ width: 'calc(50% - 185px)', margin: '-5px' }}><span onClick={(e) => auraClicked(e, auraData[11])} className={auraData[11]?.Sentiment}>{auraData[11]?.Word}</span></Rhs>
                                </Row>

                                <Row className='box3' sx={{ fontSize: '30px', lineHeight: '37px' }}>
                                    <Lhs sx={{ width: 'calc(50% - 198px)' }}><span onClick={(e) => auraClicked(e, auraData[12])} className={auraData[12]?.Sentiment}>{auraData[12]?.Word}</span></Lhs>
                                    <Rhs sx={{ width: 'calc(50% -  192px)', marginTop: '-8px' }}><span onClick={(e) => auraClicked(e, auraData[13])} className={auraData[13]?.Sentiment}>{auraData[13]?.Word}</span></Rhs>
                                </Row>

                                <Row className='box4' sx={{ fontSize: '30px' }}>
                                    <Lhs sx={{ width: 'calc(50% - 195px)' }}><span onClick={(e) => auraClicked(e, auraData[14])} className={auraData[14]?.Sentiment}>{auraData[14]?.Word}</span></Lhs>
                                    <Rhs sx={{ width: 'calc(50% - 184px)', marginTop: '-7px' }}><span onClick={(e) => auraClicked(e, auraData[15])} className={auraData[15]?.Sentiment}>{auraData[15]?.Word}</span></Rhs>
                                </Row>

                                <Row className='box1' sx={{ fontSize: '30px', lineHeight: '34px' }}>
                                    <Lhs sx={{ width: 'calc(50% - 187px)' }}><span onClick={(e) => auraClicked(e, auraData[16])} className={auraData[16]?.Sentiment}>{auraData[16]?.Word}</span></Lhs>
                                    <Rhs sx={{ width: 'calc(50% - 179px)', marginTop: '-8px' }}><span onClick={(e) => auraClicked(e, auraData[17])} className={auraData[17]?.Sentiment}>{auraData[17]?.Word}</span></Rhs>
                                </Row>

                                <Row sx={{ lineHeight: '30px' }}>
                                    <Lhs sx={{ width: 'calc(50% - 172px)', fontSize: '25px' }}><span onClick={(e) => auraClicked(e, auraData[18])} className={auraData[18]?.Sentiment + ' box2'}>{auraData[18]?.Word}</span><span onClick={(e) => auraClicked(e, auraData[19])} className={auraData[19]?.Sentiment + ' box3'}>{auraData[19]?.Word}</span></Lhs>
                                    <Rhs sx={{ width: 'calc(50% - 164px)', fontSize: '30px', marginTop: '-9px' }}><span onClick={(e) => auraClicked(e, auraData[20])} className={auraData[20]?.Sentiment + ' box2'}>{auraData[20]?.Word}</span></Rhs>
                                </Row>

                                <Row sx={{ fontSize: '25px', lineHeight: '29px' }}>
                                    <Lhs sx={{ width: 'calc(50% - 153px)' }}><span onClick={(e) => auraClicked(e, auraData[21])} className={auraData[21]?.Sentiment + ' box4'}>{auraData[21]?.Word}</span><span onClick={(e) => auraClicked(e, auraData[22])} className={auraData[22]?.Sentiment + ' box1'}>{auraData[22]?.Word}</span></Lhs>
                                    <Rhs sx={{ width: 'calc(50% - 146px)', marginTop: '-5px' }}><span onClick={(e) => auraClicked(e, auraData[23])} className={auraData[23]?.Sentiment + ' box3'}>{auraData[23]?.Word}</span><span onClick={(e) => auraClicked(e, auraData[24])} className={auraData[24]?.Sentiment + ' box4'}>{auraData[24]?.Word}</span></Rhs>
                                </Row>

                                <Row sx={{ fontSize: '20px', lineHeight: '29px' }}>
                                    <Lhs sx={{ width: 'calc(50% - 138px)' }}><span onClick={(e) => auraClicked(e, auraData[25])} className={auraData[25]?.Sentiment + ' box2'}>{auraData[25]?.Word}</span></Lhs>
                                    <Rhs sx={{ width: 'calc(50% - 121px)', marginTop: '-5px' }}><span onClick={(e) => auraClicked(e, auraData[26])} className={auraData[26]?.Sentiment + ' box1'}>{auraData[26]?.Word}</span><span onClick={(e) => auraClicked(e, auraData[27])} className={auraData[27]?.Sentiment + ' box2'}>{auraData[27]?.Word}</span></Rhs>
                                </Row>

                                <Row sx={{ lineHeight: '17px' }}>
                                    <Lhs sx={{ width: 'calc(50% - 88px)', fontSize: '20px' }}><span onClick={(e) => auraClicked(e, auraData[28])} className={auraData[28]?.Sentiment + ' box3'}>{auraData[28]?.Word}</span><span onClick={(e) => auraClicked(e, auraData[29])} className={auraData[29]?.Sentiment + ' box4'}>{auraData[29]?.Word}</span></Lhs>
                                    <Rhs sx={{ width: 'calc(50% - 81px)', fontSize: '15px', marginTop: '-5px' }}><span onClick={(e) => auraClicked(e, auraData[30])} className={auraData[30]?.Sentiment + ' box3'}>{auraData[30]?.Word}</span><span onClick={(e) => auraClicked(e, auraData[31])} className={auraData[31]?.Sentiment + ' box4'}>{auraData[31]?.Word}</span></Rhs>
                                </Row>

                                <Row sx={{ fontSize: '15px', lineHeight: '28px' }}>
                                    <Lhs sx={{ width: 'calc(50% - 34px)' }}><span onClick={(e) => auraClicked(e, auraData[32])} className={auraData[32]?.Sentiment + ' box1'}>{auraData[32]?.Word}</span><span onClick={(e) => auraClicked(e, auraData[33])} className={auraData[33]?.Sentiment + ' box2'}>{auraData[33]?.Word}</span></Lhs>
                                    <Rhs sx={{ width: 'calc(50% - 24px)' }}><span onClick={(e) => auraClicked(e, auraData[34])} className={auraData[34]?.Sentiment + ' box1'}>{auraData[34]?.Word}</span><span onClick={(e) => auraClicked(e, auraData[35])} className={auraData[35]?.Sentiment + ' box2'}>{auraData[35]?.Word}</span></Rhs>
                                </Row>

                                <Row sx={{ fontSize: '15px' }}>
                                    <Lhs sx={{ width: 'calc(50% - 11px)' }}><span onClick={(e) => auraClicked(e, auraData[36])} className={auraData[36]?.Sentiment + ' box3'}>{auraData[36]?.Word}</span><span onClick={(e) => auraClicked(e, auraData[37])} className={auraData[37]?.Sentiment + ' box4'}>{auraData[37]?.Word}</span></Lhs>
                                    <Rhs sx={{ width: 'calc(50% - 12px)' }}><span onClick={(e) => auraClicked(e, auraData[38])} className={auraData[38]?.Sentiment + ' box3'}>{auraData[38]?.Word}</span><span onClick={(e) => auraClicked(e, auraData[39])} className={auraData[39]?.Sentiment + ' box4'}>{auraData[39]?.Word}</span></Rhs>
                                </Row>

                            </InnerWrapper>}
                        </OuterWrapper>

                        <GraphDescription className='graphDesc'>
                            <Typography>Aura is a MavenMagnet technology to highlight the most impactful words around the brand or a category. The bigger the size of the word, the more impact it generated. Impact is calculated as a measure of reach (passive readers/viewers) and engagement (active participants in conversations).</Typography>
                            {props.InsightsElementType === 'Aura' && <GraphLegendWrapper>
                                <GraphLegend color={LEGEND_COLOR_MAP.Positive}>Positive</GraphLegend>
                                <GraphLegend color={LEGEND_COLOR_MAP.Mixed}>Mixed</GraphLegend>
                                <GraphLegend color={LEGEND_COLOR_MAP.Negative}>Negative</GraphLegend>
                            </GraphLegendWrapper>}
                        </GraphDescription>

                        <MenuWrapper
                            id='auraMenuWrapper'
                            className='menuWrapper'
                            anchorEl={anchorElUser}
                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                            // BackdropProps={{ invisible: true }}
                        >

                        {/* {!!auraDetailData.length && <MenuWrapper
                            className='menuWrapper'
                            open={true}
                            onClose={handleCloseUserMenu}
                            sx={menuPosition}
                            ref={menuRef}
                        > */}
                            <MenuContent id='menuWrapper'>
                                <MenuTitle className={`${auraTitleColor} ${props.InsightsElementType}`}>{auraTitle}</MenuTitle>
                                <MenuClose onClick={handleCloseUserMenu}>
                                    <img src={close} alt='close' />
                                </MenuClose>
                                {/* {!!auraDetailData?.length && <VerbatimGrid data={auraDetailData} />} */}
                                {!!auraDetailData?.length && <VerbatimImageVideoGrid data={auraDetailData} />}
                            </MenuContent>
                        </MenuWrapper>
                    </LoaderWrapper>
                </Wrapper>
            </ReportOuterWrapper>
        </>
    )
}

export default ReportAura
