import React, {useState} from 'react'
import { Typography, IconButton, Box } from '@mui/material'
import Header from './header'
// import Filter from './filter'

import {
  InnerWrapper,
  GridHeaderWrapper,
  ContentWrapper
} from './style'

const ProgressionWrapper = ({configTab, children, onChange, type, defaultTab=0}) => {
  const [activeTab, setActiveTab] = useState(defaultTab)
  const tabChanged = (value) => {
    setActiveTab(value)
    onChange(value)
  }
  return (
    <InnerWrapper hasTabs={configTab?.length > 1} className='brandCategory'>
      {configTab?.length > 1 && <GridHeaderWrapper className='tabWrapper'>
        <Header options={[...configTab.filter(f => f.periodOrder === 1), ...configTab.filter(f => f.periodOrder === 2)] || undefined} onChange={(v) => tabChanged(v)} value={activeTab} />
      </GridHeaderWrapper>}
      <ContentWrapper hasTabs={configTab?.length > 1} type={type}>{children[activeTab]}</ContentWrapper>
    </InnerWrapper>
  )
}

export default ProgressionWrapper

