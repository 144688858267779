import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import PostLogin from '../../layout/postLogin'
import { Header, MuiButton, NoDataFound } from '../../components'
import { INTERNAL_USER_TYPE, NAME_USER_MANAGEMENT, USER_TYPE, ROUTE_PROJECTS } from '../../constants'
import {
  ContentWrapper,
  LhsWrapper,
  NoDataFoundWrap,
  OuterWrapper,
  Wrapper,
} from './style'
import Users from './users'
import UserForm from './userForm'
import UserManagementService from '../../services/userManagement.service'
import FilterRow from './filterRow'
import { userManagementColor } from '../../assets/images/icons'
import { useGlobalStore } from '../../store'

const UserManagement = () => {
  const [activeUser, setActiveUser] = useState(null)
  const [isLoading, setLoading] = useState(true)
  const [filters, setFilters] = useState({searchText: '', role: ''})
  const [isUserFormActive, setUserFormActive] = useState(false)
  const [users, setUsers] = useState([])
  const [userTypes, setUserTypes] = useState([])
  const {user} = useGlobalStore()
  const navigate = useHistory()

  useEffect(() => {
    if (user.UserType === 'Admin' || user.UserType === 'Member') {
      setUserTypes(USER_TYPE);
    } else {
      setUserTypes([...USER_TYPE, ...INTERNAL_USER_TYPE])
    }
  }, [user])

  useEffect(() => {
    if (user.UserType === 'Analyst' || user.UserType === 'Member') {
      navigate.push(ROUTE_PROJECTS)
    }
  }, [])
  
  const getUserData = async () => {
    UserManagementService.getUsers(user.CompanyId, true)
      .then((resp) => {
        const roles = userTypes.map(item => item.value);
        const excludedCurrentUser = resp?.filter(resItem => (user.UserId !== resItem.UserId && roles.includes(resItem.UserType)))
        const grouped = {};
        for (let index = 0; index < excludedCurrentUser.length; index++) {
          const element = excludedCurrentUser[index];
          if(grouped[element.UserType]) {
            grouped[element.UserType] = [...grouped[element.UserType], element];
          } else {
            grouped[element.UserType] =  [element]
          }
        }

        const sortedGroup = {};
        for (const key in grouped) {
          if (Object.hasOwnProperty.call(grouped, key)) {
            const element = grouped[key];
            sortedGroup[key] = element.sort((a, b) => a.UserName.localeCompare(b.UserName))
          }
        }
        setUsers(sortedGroup)
      })
      .catch((error) => {
        console.log(error)
      }).finally(() => setLoading(false))
  }

  const chackActiveUser = (data) => {
    // console.log('chackActiveUser1 > data -------- ', data)
    // if(!data?.UserId && data?.UserEmail){
    //   console.log('chackActiveUser1 > filters ---- ', filters)
    // }
  }

  useEffect(() => {
    if(user.UserId && userTypes?.length) {
      getUserData();
    }
  }, [user, userTypes])

  return (
    <PostLogin>
      <Wrapper>
        <Header title={NAME_USER_MANAGEMENT}>
          <MuiButton
            onClick={() => {
              setUserFormActive(true)
              setActiveUser(null)
            }}
            color='primary'
            variant='contained'
            className='BtnUserManagementAddNew'
          >
            + Add New User
          </MuiButton>
        </Header>
        <OuterWrapper>
          <LhsWrapper>
            <FilterRow userTypes={userTypes} disabled={isUserFormActive} setFilters={setFilters} filters={filters}/>
            <Users
              disabled={isUserFormActive}
              filters={filters}
              activeUser={activeUser}
              setActiveUser={setActiveUser}
              users={users}
              refetch={getUserData}
              isLoading={isLoading}
            />
          </LhsWrapper>
          <ContentWrapper>
            {activeUser || isUserFormActive ? (
              <UserForm
                userTypes={userTypes}
                onClose={(data=null) => {
                  setActiveUser(data?.UserId ? data : null)
                  setUserFormActive(false)
                  chackActiveUser(data)
                }}
                refetch={getUserData}
                data={activeUser}
              />
            ) : (
              <NoDataFoundWrap>
                <NoDataFound img={userManagementColor} width="101px" height="101px" title='Select a User' />
              </NoDataFoundWrap>
            )}
          </ContentWrapper>
        </OuterWrapper>
      </Wrapper>
    </PostLogin>
  )
}

export default UserManagement
