import React, { useState } from 'react'
import ReportsService from '../../services/reports.service'
import BlockingLoader from '../blockingLoader'
import Modal from '../modal'
import MuiTextField from '../muiTextField'
import { InputContainer, InputWrap, ModalButton, ModalTitle, ModalWrapper } from './style'

const EditVerbatimModal = ({ onClose, text = '', insightsElementId='', updateContent }) => {
	const [newText, setNewText] = useState(text)
	const [isLoading, setLoading] = useState(false)

  const submitHandler = () => {
		const obj = {
			insightsElementId: insightsElementId,
			currentVerbatim: encodeURIComponent(text),
			newVerbatim: encodeURIComponent(newText)
		}
		setLoading(true)
		
		ReportsService.setSandboxVerbatim(obj).then(resp => {
			updateContent(resp);
			onClose();
		}).catch(error => {
			console.log(error, 'rsp')
		}).finally(() => {
			setLoading(false);
		})
  }

	return (
		<Modal title='Edit Verbatim' showClose={true} onClose={onClose}>
			<ModalWrapper>
				<InputContainer>
					<InputWrap className='top'>
						<MuiTextField title='Current Verbatim' disabled name='oldInput' value={text} inputType='area' />
					</InputWrap>
					<InputWrap>
						<MuiTextField title='New Verbatim' name='newInput' onChange={(e) => setNewText(e.target.value)} value={newText} inputType='area' />
					</InputWrap>
				</InputContainer>
				<ModalButton onClick={submitHandler} disabled={text === newText} color='primary' variant='contained'>
					Change
				</ModalButton>
			</ModalWrapper>
			{
				isLoading &&
				<BlockingLoader />
			}
		</Modal>
	)
}

export default EditVerbatimModal
