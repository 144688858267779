import React from 'react'

const IconClose = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
      <path d="M0.485658 9.93547H5.09941L0 15.0162L1.08339 16.0996L6.16412 11.0002V15.6139H7.67713V8.42245H0.485658V9.93547Z" fill="black"/>
      <path d="M16.101 1.08339L15.0176 0L9.93684 5.09941V0.485658H8.42383V7.67714H15.6153V6.16412H11.0016L16.101 1.08339Z" fill="black"/>
    </svg>
  )
}

export default IconClose