import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import { exportToPPT_tableCss } from '../../../../util'
import { MEDIA } from '../../../../constants'

export const OuterWrapper = styled(Box)(`
    width:100%;
    &.exportToPPT {padding:0;}
    &.exportToPPT img{width:1800px;}
    ${ exportToPPT_tableCss }
`)

export const InnerWrapper = styled(Box)(`
    display:flex; flex-direction: column; width:100%; position: relative;
`)

export const ContentWrapper = styled(Box)(`
    // padding:20px 30px;
    & img {
        max-width: calc(100%);
    }
    @media (max-width: ${MEDIA.lg}) {
        // padding: 20px;
    }
`)

export const Hidden = styled(Box)(`
    display:none;
`)

