import React, { useState, useRef } from 'react'
// import { useFormik } from 'formik'
// import * as Yup from 'yup'
import ReportsService from '../../../../../services/reports.service'

import { Modal, Rte, MuiButton } from '../../../../../components'
// import { IconButton } from '@mui/material'
import { IconEdit } from '../../../../../assets/images/icons'
import { rteRemovingStyleFromTable } from '../../../../../util'

import { ButtonWrapper, Title, Wrapper, RteWrapper, RtePreviewCss } from './style'
// import { RtePreviewCss } from '../style'

const ReportEditRecommendations = (props) => {
  const {InsightsElementId, pageData, onSuccess, title} = props
  const [anchorElUser, setAnchorElUser] = useState(null)
  const [showEditHeaderModal, setShowEditHeaderModal] = useState(false)

  const handleOnEditHeader = () => {
    setAnchorElUser(null)
    setShowEditHeaderModal((prev)=>!prev)
  }

  const form = useRef()
  const [loader, setLoader] = useState(false)
  const [headingValue, setHeadingValue] = useState(pageData || '')
  
  // const { enqueueSnackbar } = useSnackbar()

  const onSubmit = (e) => {
    e.preventDefault()
    const updatedValues = {
      insightsElementId : (InsightsElementId), // Hindi conversion
      // recommendation : encodeURIComponent(headingValue),
      // recommendation : encodeURIComponent(headingValue.replaceAll('style="','_style="')),
      recommendation : encodeURIComponent(rteRemovingStyleFromTable(headingValue)),
    }

    setLoader(true)
    ReportsService.reportSetRecommendationElement(updatedValues)
    .then((resp) => {
      setLoader(false)
      setShowEditHeaderModal(false)
      onSuccess()
    })
    .catch((error) => {
      setLoader(false)
      const resMessage = error
      console.log('ERROR : ', resMessage)
        // enqueueSnackbar(resMessage, { variant: 'error' })
    })
  }

  const removeStyleTagsFunc = (e) => {
    const id = 'recommendationId'
    document.getElementById(id).innerHTML = e;
    const htmlNode = document.querySelectorAll(`#${id} *`)
    // console.log('htmlNode -----------------------------------------------')
    for(let i=0; i<htmlNode.length; i++){
      // console.log('htmlNode -----------> ', htmlNode[i].tagName)

      if(['table', 'tr', 'td'].includes((htmlNode[i].tagName).toLowerCase())){
        // console.log('htmlNode -----------> ', htmlNode[i])
        htmlNode[i].removeAttribute('style')
      }
    }
    const htmlStr = (document.getElementById(id).innerHTML)
    // document.getElementById(id).innerHTML = htmlStr
    // console.log('e ======= ', e)
    // console.log('htmlStr -----------> ', htmlStr)
    setHeadingValue(htmlStr)
  }

  return (
    <>
      <MuiButton color='secondary' variant='outlined' startIcon={<IconEdit />}  onClick={() => setShowEditHeaderModal(true)} sx={{fontWeight:300, marginLeft:'15px'}}>Edit {title}</MuiButton>
      {showEditHeaderModal && <Modal title='' showClose={true} onClose={handleOnEditHeader}>
        <Wrapper>
          <form ref={form}>
            <Title variant='body1'>Edit {title}</Title>
            <div id='recommendationId' style={{display:'none'}}></div>
            <RteWrapper>
              <Rte 
                title='' 
                value={headingValue} 
                onChange={(e) => removeStyleTagsFunc(e)}
                customToolbar={'H4 | bold italic underline strikethrough | bullist numlist indent outdent | table | fontsize | hr | undo redo'}
                content_style={RtePreviewCss}
                height={550}
              />
            </RteWrapper>

            <ButtonWrapper
              color='primary'
              variant='contained'
              type='submit'
              onClick={onSubmit}
              disabled={loader || false}
            >
              Submit
            </ButtonWrapper>
          </form>
        </Wrapper>
      </Modal>}
    </>
  )
}

export default ReportEditRecommendations
