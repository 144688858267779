import { styled } from '@mui/material/styles'
import { Box, Typography } from '@mui/material'
import { MuiButton } from '../../../../components'

export const TitleWrap = styled(Typography)(`
  font-weight: 300;
  font-size: 20px;
  color:#000;
  & sup {
    font-size: 14px;
  }
`)

export const SectionInnerWrapper = styled(Box)(`
  padding-top:15px;
  height:calc(100vh - 150px);
  overflow:auto;
`)

export const ContentWrapper = styled(Box)(`
  width:100%;
  display:flex;
  flex-direction:column;
  & > div > div {
    padding:0;
  }
`)

// DRAFT GRID CSS
export const DraftContentWrapper = styled(Box)(`
  display:flex;
  padding: 30px;
  overflow: auto;
  width: 100%;
`)

export const DraftSearchRowWrap = styled(Box)(`
  margin-bottom: 10px;
  display:flex;
  aligin-items: center;
`)

export const DraftTableWrap = styled(Box)(`
  width: 100%;
  & .tableRoot {
    & .table-body-row{
      box-shadow: none;
      background: #fff;
    }
    & .table-body-cell {
      padding: 15px 0;
      border-bottom: 1px solid #F1F1F3;
    }
    & .MuiTableHead-root {
      background: #fff;
      & .table-head-cell {
        padding: 15px 0;
        border-bottom: 2px solid #F1F1F3;
      }
    }
  }
`)

export const DraftTitleWrap = styled(Typography)(`
  font-weight: 300;
  font-size: 20px;
  color:#000;
  & sup {
    font-size: 14px;
  }
`)


