import React, {useState, useEffect} from 'react'
import { Typography, IconButton, Box } from '@mui/material'
import { ChartBarHorizontalCustom, ProgressionFilters } from '../../../../components'
import { LEGEND_TEXT_COLOR_MAP } from '../../../../constants'
import { getObjectAsArray } from '../../../../util'
import Filter from './filter'
import { ArrowDown, IconReportSearch, ClosePopup } from '../../../../assets/images/icons'

import { 
	BarWrap, IssuesWrap, SegmentRow, SegmentRowContent, SegmentTitle, SegmentWrap, SegmentRowHeading, SegmentRowDetails, SegmentDetailsLevel2, 
	SegmentDetailsLevel2ListingLHS, SegmentAccordionLevel2Wrap, SegmentAccordionLevel2Heading, SegmentAccordionLevel2Details,
	SegmentDetailsLevel2ListingRHS, SegmentAccordionLevel3Heading, SegmentAccordionLevel3Wrap, SegmentAccordionLevel3SubHeading, SegmentAccordionLevel3Details,
	ProgressionWrapper
} from './style'

const ComparisonTable = ({ keyOrdering, data, segmentData = {}, maxValue, type, filterType, insightsElementType, expanded, filterUpdated }) => {
	const [segmentDataLevel1, setSegmentDataLevel1] = useState()
	const [segmentDataL1Filtered, setSegmentDataL1Filtered] = useState({drivers:[], segments:[], periods:[]})
	const [segmentDataLevel3, setSegmentDataLevel3] = useState()
	const [segmentDataLevel3MaxValue, setSegmentDataLevel3MaxValue] = useState()
	
	const getData = (dd, field = "SegmentDrivers", field2 = "SegmentDrivers", level=0, isInner = true) => {
		const obj = {};
		const percentArr = [];
		for (let index = 0; index < dd?.length; index++) {
		  const element = dd[index];
  		  const keyName = element.parentDriveName

		  const driverElement = element?.[field]?.[field2];
		  const drivers = Array.isArray(driverElement) ? driverElement : Object.keys((driverElement || {}))?.length ? [driverElement] : [];

		  for (let j = 0; j < drivers.length; j++) {
			const innerElement = drivers[j];
			const key = (level === 1) ? innerElement.AttributeName : innerElement.SubAttributeName;
	
			const associationData = [{
			  SearchKeywords: innerElement.Percent
			}];
	
			const multiBarData = [{
			  Positive: innerElement.PositivePercent,
			  Neutral: innerElement.NeutralPercent,
			  Mixed: innerElement.MixedPercent,
			  Negative: innerElement.NegativePercent
			}]

			obj[key] = {
			  ...(obj[key] || {}),
			  [keyName]: {
				...innerElement,
				data: type ? associationData : multiBarData
				// data: multiBarDataCount === 0 ? associationData : multiBarData
			  }
			};
			percentArr.push(innerElement.Percent);
		  }
		}
		
		const maxPercent = (Math.max(...percentArr))
		return {object:obj, maxVal:maxPercent}
	}
	const expandLevel1Func = (key) => {
		let dataLevel1 = {...segmentDataLevel1}
		dataLevel1[key].expandedLevel1 = !dataLevel1[key]?.expandedLevel1
		setSegmentDataLevel1(dataLevel1)
	}
	const expandLevel2Func = (key, index, key2, showButton=false) => {
		const dataLev2 = {...segmentDataLevel1}
		const getDataLevel3FuncData = getDataLevel3Func(dataLev2[key].dataLevel2[key2], key2) // {object:obj, maxVal:maxPercent}

		let dataLev3 = getDataLevel3FuncData.object
		if(Object.keys(dataLev3).length === 0){ dataLev3 = null }
		if(showButton) return isNaN(dataLev3)

		setSegmentDataLevel3(dataLev3)
		setSegmentDataLevel3MaxValue(getDataLevel3FuncData.maxVal)

		const dataLev2ObjectKeys = Object.keys(dataLev2)
		for(let i=0; i<dataLev2ObjectKeys.length; i++){
			dataLev2[dataLev2ObjectKeys[i]].expandedLevel2 = false	
		}

		dataLev2[key].expandedLevel2 = true
		dataLev2[key].expandedLevel2Index = index
		dataLev2[key].expandedLevel2Key = key2

		setSegmentDataLevel1(dataLev2)
	}
	const getDataLevel1Func = (key) => {
		const objKeys = Object.keys(segmentData[key])
		const level2Array = []
		for(let i=0; i<objKeys.length; i++){
			const finalObject = {...segmentData[key][objKeys[i]], parentDriveName:objKeys[i]}
			level2Array.push(finalObject)
		}
		return getData(level2Array, 'DriverAttributes', 'DriverAttributeElement', 1)
	}
	const getDataLevel3Func = (segmentData, key) => {
		const objKeys = Object.keys(segmentData)
		const level3Array = []
		for(let i=0; i<objKeys.length; i++){
			const finalObject = {...segmentData[objKeys[i]], parentDriveName:objKeys[i]}
			level3Array.push(finalObject)
		}
		return getData(level3Array, 'DriverSubAttributes', 'DriverSubAttributeElement', 2)
	}
	const closeLevel3 = (key) => {
		let dataLev2 = {...segmentDataLevel1}
		dataLev2[key].expandedLevel2 = false
		dataLev2[key].expandedLevel2Index = null
		dataLev2[key].expandedLevel2Key = null
		setSegmentDataLevel3(null)
		setSegmentDataLevel1(dataLev2)
	}

	const filterChangeFunc = (data) => {
		setSegmentDataL1Filtered(data)
		filterUpdated()
	}

	const getDriversFunc = () => {
		return Object.keys(segmentData)
	}
	const getSegmentsFunc = () => {
		const drivers = Object.keys(segmentData)
		return (drivers.map(d => Object.keys(segmentData[d]) ).flat(1)).filter((value, index, array) => array.indexOf(value) === index)
	}

	useEffect(() => {
		const objectKeys = Object.keys(segmentData)
		const segData = {}
		for(let i=0; i<objectKeys.length; i++){
			const getDataLevel1FuncData = getDataLevel1Func(objectKeys[i]) // {object:obj, maxVal:maxPercent}
			segData[objectKeys[i]] = {
				dataLevel1:segmentData[objectKeys[i]],
				expandedLevel1:false,
				dataLevel2:getDataLevel1FuncData.object,
				expandedLevel2:false,
				expandedLevel2Index:null,
				expandedLevel2MaxValue:getDataLevel1FuncData.maxVal,
				expandedLevel2Key:'',
			}
		}
		setSegmentDataLevel1(segData)
	}, [segmentData])

	useEffect(() => {
		if(segmentDataLevel1){
			const dataLev2 = {...segmentDataLevel1}
			const dataLev2ObjectKeys = Object.keys(dataLev2)
			for(let i=0; i<dataLev2ObjectKeys.length; i++){
				dataLev2[dataLev2ObjectKeys[i]].expandedLevel1 = expanded	
				dataLev2[dataLev2ObjectKeys[i]].expandedLevel2 = false	
			}
			setSegmentDataLevel1(dataLev2)
			filterUpdated()
		}
	}, [expanded])

	useEffect(() => {
		filterUpdated()
	}, [segmentDataLevel1])

	return (
		<SegmentWrap className={'segmentWrap'}>
			{(Object.keys(segmentData))?.length > 0 && <ProgressionWrapper><ProgressionFilters data={{drivers:getDriversFunc(), [filterType]:getSegmentsFunc()}} onChange={filterChangeFunc} /></ProgressionWrapper>}
			{/* {(Object.keys(segmentData))?.length > 0 && <Filter data={segmentData} onChange={filterChangeFunc} />} */}
			{
				segmentData && segmentDataLevel1 && segmentDataL1Filtered[filterType] && (segmentDataL1Filtered.drivers).map((key, i) => {
					const item = segmentData[key];
					const itemLevel1 = segmentDataLevel1[key];
					const selectedItemsL1 = {}
					const arr1 = segmentDataL1Filtered[filterType]
					for(let s=0; s<arr1.length; s++){
						if(item[arr1[s]]){
							selectedItemsL1[arr1[s]] = item[arr1[s]]
						}
					}
					if(Object.keys(selectedItemsL1).length === 0){ return false }

					return (
						<SegmentRow key={key} className={'segmentRows LEVEL1'}>
							<SegmentRowHeading className={itemLevel1?.expandedLevel1?'selected':''}>
								{key}
								{(Object.keys(itemLevel1?.dataLevel2))?.length>0 && <IconButton size='small' onClick={(e) => expandLevel1Func(key)}><IconReportSearch /></IconButton>}
							</SegmentRowHeading>
							<SegmentRowDetails>
								<BarGraphsWrapper keyOrdering={keyOrdering} item={selectedItemsL1} maxValue={maxValue} />
							</SegmentRowDetails>
							{itemLevel1?.expandedLevel1 && <SegmentDetailsLevel2 className='segmentRowsLevel2'>
								<SegmentDetailsLevel2ListingLHS>
									{(Object.keys(itemLevel1?.dataLevel2)).map((data, j) => {
										const showLevel2Button = expandLevel2Func(key, j, data, true)
										const selected = itemLevel1.expandedLevel2 && (itemLevel1?.expandedLevel2Index === j)
										const selectedItemsL2 = {}
										const arr2 = segmentDataL1Filtered[filterType]
										for(let s=0; s<arr2.length; s++){
											if(itemLevel1.dataLevel2[data][arr2[s]]){
												selectedItemsL2[arr2[s]] = itemLevel1.dataLevel2[data][arr2[s]]
											}
										}
										let hadNextLevel = false
										const itemL2 = Object.keys(selectedItemsL2)
										for(let i=0; i<itemL2.length; i++){
											if(selectedItemsL2[itemL2[i]]?.DriverSubAttributes){
												hadNextLevel = true
												break
											}
										}
										// DriverSubAttributes
										if(Object.keys(selectedItemsL2).length === 0){ return false }
										// if(){ return false }

										return <SegmentAccordionLevel2Wrap key={j} className='segmentRowsLevel2List'>
											{hadNextLevel && showLevel2Button && <SegmentAccordionLevel2Heading className={selected?'selected':''} onClick={(e) => expandLevel2Func(key, j, data)}>{data}<IconReportSearch /></SegmentAccordionLevel2Heading>}
											{!hadNextLevel && showLevel2Button && <SegmentAccordionLevel2Heading style={{cursor: 'default'}}>{data}</SegmentAccordionLevel2Heading>}
											{!showLevel2Button && <SegmentAccordionLevel2Heading >{data}</SegmentAccordionLevel2Heading>}
											<SegmentAccordionLevel2Details>
												<BarGraphsWrapper keyOrdering={keyOrdering} item={selectedItemsL2} maxValue={itemLevel1?.expandedLevel2MaxValue} />
											</SegmentAccordionLevel2Details>
										</SegmentAccordionLevel2Wrap>
									})}
								</SegmentDetailsLevel2ListingLHS>
								{segmentDataLevel3 && itemLevel1.expandedLevel2 && <SegmentDetailsLevel2ListingRHS className='segmentRowsLevel3'>
									{
										itemLevel1.dataLevel2[itemLevel1.expandedLevel2Key] &&
										<SegmentLevel3DetailSection keyOrdering={keyOrdering} index={i} segmentDataL1Filtered={segmentDataL1Filtered} subTitle={Object.keys(segmentDataLevel3)} itemData={segmentDataLevel3} heading={itemLevel1.expandedLevel2Key} maxValue={segmentDataLevel3MaxValue} closeLevel3={() => closeLevel3(key)} />
									}
								</SegmentDetailsLevel2ListingRHS>}
							</SegmentDetailsLevel2>}
						</SegmentRow>
					)
				})
			}
		</SegmentWrap>
	)
}

export default ComparisonTable


const BarGraphsWrapper = ({keyOrdering, item, maxValue}) => {
	const itemKeys = Object.keys(item)
	const finalKeys = keyOrdering.filter(f => itemKeys.includes(f))

	return <Box>
		{
			finalKeys.map((productKey, ind) => {
				const productItem = item[productKey];
				let chartData = productItem.data
				if(productItem.Percent === 0) return false

				return (
					<SegmentRowContent key={productKey + ind}>
						<SegmentTitle className='segmentTitle'>{productItem.TargetSegment || productKey}</SegmentTitle>
						<BarWrap sx={{ paddingRight: "30px" }} className={productItem?.type?.toLowerCase()}>
							<ChartBarHorizontalCustom
								showInside={false}
								showToolTip={true}
								bottomText={false}
								data={chartData}
								total={productItem.Percent}
								valueInPercent
								maxPercentRatio={maxValue}
								maxWidth="100%"
							/>
						</BarWrap>
					</SegmentRowContent>
				)
			})
		}
	</Box>
} 

const SegmentLevel3DetailSection = ({keyOrdering, index, segmentDataL1Filtered, subTitle, heading, itemData, maxValue, closeLevel3}) => {
	const selectedItemsL3Array = []

	for(let r=0; r<subTitle.length; r++){
		const selectedItemsL3 = []
		const data = subTitle[r]
		const arr3 = segmentDataL1Filtered?.segments || segmentDataL1Filtered?.periods
		for(let s=0; s<arr3.length; s++){
			if(itemData[data][arr3[s]]){
				selectedItemsL3.push({
					data: {[arr3[s]] : itemData[data][arr3[s]]},
					title: data
				})
			}
		}
		
		if(Object.keys(selectedItemsL3).length > 0){
			selectedItemsL3Array.push(selectedItemsL3)
		}
	}
	
	if(Object.keys(selectedItemsL3Array).length === 0) return false

	return (
		<>
			<SegmentAccordionLevel3Heading>{heading} <IconButton size='small' onClick={(e) => closeLevel3()}><ClosePopup /></IconButton></SegmentAccordionLevel3Heading>
			{selectedItemsL3Array.map((data, k) => {
				const title = data[0].title;
				const finalData = data.map(d => d.data)
				
				return <SegmentAccordionLevel3Wrap key={k}>
					{finalData.length > 0 && <SegmentAccordionLevel3SubHeading>{title}</SegmentAccordionLevel3SubHeading>}
					{finalData.length > 0 && <SegmentAccordionLevel3Details>
						{finalData.map((d2, j)=>(<BarGraphsWrapper keyOrdering={keyOrdering} key={j} item={d2} maxValue={maxValue} />))}
					</SegmentAccordionLevel3Details>}
				</SegmentAccordionLevel3Wrap>
			})}
		</>
	)
}
