import React from 'react'

const RedArrow = () => {
  return (
    <svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg" style={{transform: 'scale(1.3)'}}>
      <path d="M4.70047 6.93441C5.10048 7.46736 5.90003 7.46736 6.30005 6.93441L9.92832 2.10028C10.4231 1.44109 9.95275 0.5 9.12854 0.5H1.87198C1.04777 0.5 0.577436 1.44109 1.0722 2.10028L4.70047 6.93441Z" fill="#FF0000"/>
    </svg>
  )
}

export default RedArrow
