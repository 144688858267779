import { styled } from '@mui/material/styles'
import { Box, Typography, FormControlLabel } from '@mui/material'
import { imageryBg, imageryBgPlain } from '../../../../assets/images'
import { MAGNET_SCORE_IMAGE } from '../../../../constants'
import { exportToPPT_tableCss } from '../../../../util'
import { MEDIA } from '../../../../constants'

const RIGHT_CONTENT = "242px"
const SEARCH_ICON = "50px"

export const OuterWrapper = styled(Box)(`
    width: 100%;
    padding: 0;

    &.showExpandAllCTA_false {
        .brandImageryRow.header{
            width: calc(100% - ${RIGHT_CONTENT});
            margin-right:0;
        }
        .brandImageryRow.footer{
            width: calc(100% - ${RIGHT_CONTENT});
            margin-right:0;
        }
        .GraphScaleWrapper > div {
            background-size:calc(100% - ${RIGHT_CONTENT});
        }
        .GraphScaleWrapper .brandImageryRow .graphRowDetails {
            display:none;
        }
        .GraphScaleWrapper .brandImageryRow .list {
            width: calc(100% - ${RIGHT_CONTENT});
        }
    }

    &.exportToPPT {padding:0;}
    &.exportToPPT .list {min-height:65px;}

    &.exportToPPT.brandImageryOuterWrapper { display: flex; flex-direction: column; min-width:1200px; max-width:1200px; }
    &.exportToPPT.brandImageryOuterWrapper { 
        .brandImageryRowAppendices *, .brandImageryRow * {font-size: 15px !important; font-weight:500;}
        .brandImageryRow .contentText { padding-right: 12px; }
        // .brandImageryRow .contentText * { font-size:13px !important; }
        .brandImageryRow .contentText button { display:none !important;}
        .brandImageryRow .graphRowDetails { display:none !important;}
        .brandImageryRow.header{ width: calc(100% - ${RIGHT_CONTENT}); margin-left: ${RIGHT_CONTENT}; margin-right: 0;}
        .brandImageryRow.footer{ width: 100%; padding-left: ${RIGHT_CONTENT} !important; margin: 0; border-top:2px #f5f5f5 solid;}
        .list{
            background: linear-gradient(to right, #fbd6d3 0%,#fbd6d3 33.33%,#dddddd 33.34%,#dddddd 66.66%,#e4fedd 66.67%,#e4fedd 100%) !important;
            width: calc(100% - ${RIGHT_CONTENT});
        }
        .contentText .pptSubHeading { display:none !important; }
        .tabWrapper { display:none !important; }
        .tab2:before {display:none;} 

        .brandCategory > div:last-child {padding: 20px 0;}
    }
    &.exportToPPTAppendices, &.exportToPPTProgression {
        .brandImageryRow.details { display:block !important; }
        .insightsWrapper { padding-bottom:10px; }
        .footer { padding:0 !important; }
        .footer span { font-size: 13px; }

        .brandImageryRowAppendices.brandImageryRow.tRow { padding-top:8px; }
        .brandImageryRowAppendices .textWrapper .text{ font-size:11px !important; }
        .brandImageryRowAppendices .list{ border-bottom: 8px #fff solid !important; }

        .brandImageryRowProgression.brandImageryRow.tRow { padding-top:8px; }
        .brandImageryRowProgression .textWrapper .text{ font-size:11px !important; }
        .brandImageryRowProgression .list{ border-bottom: 8px #fff solid !important; }
        .brandImageryRowProgression .pptSubHeading {display:block}
        
    }

    ${ exportToPPT_tableCss }

    @media (max-width: ${MEDIA.lg}) {
        padding:20px 20px 40px 20px;
    }
`)

export const InnerWrapper = styled(Box)( props => `
    position:relative;
`)

export const GraphOuterWrapper = styled(Box)( props => `
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    align-items: end;
    background: #fff;

    ${ props.hasTabs && `
        marginLeft: '-12px';
        width: 'calc(100% + 24px)';
        border: 1px red solid;
    `}
    
`)

export const MuiButtonWrap = styled(Box)(`
    position:relative;
`)

export const GraphInnerWrapper = styled(Box)(`
    display:flex;
    width:100%;
    position: relative;

    // & > div > div:last-child > div > div:first-of-type {
    //     border-bottom: 2px solid #F5F5F5;
    // }
`)

export const Graph = styled(Box)(props => `
    position: relative;
    display: flex;
    margin-left: auto;
    flex-direction: column;
    width: 100%;
    align-items: end;
    z-index: 1;
    background-image: url('${props.simulator === "yes" ? imageryBg : imageryBgPlain }');
    background-position: ${RIGHT_CONTENT} 0;
    background-repeat-x: no-repeat;
    background-size: calc(100% - (${RIGHT_CONTENT} + ${SEARCH_ICON}));
`)


export const GraphScaleWrapper = styled(Box)(`
    width: calc(100% - ${RIGHT_CONTENT} - ${SEARCH_ICON});
    margin-right:${SEARCH_ICON};
    display: flex;
    justify-content: space-between;
    
    & span {
        width: calc(100% / 7);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    & .txt1 { justify-content: start;}
    & .txt2 { justify-content: start;}
    & .txt3 { justify-content: start;}
    & .txt4 { justify-content: center; width:20px;}
    & .txt5 { justify-content: end;}
    & .txt6 { justify-content: end;}
    & .txt7 { justify-content: end;}

    &.tab2 {
        position: relative;
        &:before {
            content: '';
            position:absolute;
            top:0;
            left:-242px;
            border-top: 2px solid #F5F5F5;
            width:calc(100% + 293px);
            height:1px;
            display:flex;
        }
    }
`
)

export const GraphInfoWrapper = styled(Box)(`
    width: calc(100% - ${RIGHT_CONTENT} - ${SEARCH_ICON});
    margin-right:${SEARCH_ICON};
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    > span {
        min-width:300px;
        font-size: 12px;
        line-height: calc(14 / 12);
        &:first-of-type {
            display: flex;
            align-items: center;
        }
        &:last-child {
            display: flex;
            align-items: center;
            flex-direction: row-reverse;
            >img{
                transform: rotateY(180deg);
                filter: hue-rotate(-245deg) brightness(1.5);
            }
        }
        >img{
            padding-right: 10px;
        }
    }
`)

export const SliderWrap = styled(Box)(props => `
  height: 12px;
  display: flex;
  align-items: center;
  & img {
    position: relative;
    left: ${props.percent}%;
    transform: translate(-${props.percent}%, 0px);
    // transform: ${props.percent > 90  ? `translate(-${props.percent}%, 0px)` : ''};
    width: ${MAGNET_SCORE_IMAGE[props?.logoname || ""]?.width || 'auto'}px;
    height: 12px;
  }
  & .slider {
    -webkit-appearance: none;
    width: 100%;
    height: 12px;
    border-radius: 5px;
    background: transparent;
    outline: none;
    -webkit-transition: .2s;
    transition: opacity .2s;
    border-radius: 0;
  }
  
  & .slider:hover {
    background: ${props.disabled ? "transparent" : "#ffffff50"};
  }
  
  & .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: ${MAGNET_SCORE_IMAGE[props?.logoname || ""]?.width || 80}px;
    height: 15px;
    object-fit: contain;
    border: 0;
    background-image: url(${props?.logo});
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    cursor: ${props.disabled ? "default" : "poiner"};
  }
  
  & .slider::-moz-range-thumb {
    appearance: none;
    width: ${MAGNET_SCORE_IMAGE[props?.logoname || ""]?.width || 80}px;
    height: 15px;
    object-fit: contain;
    border: 0;
    background-image: url(${props?.logo});
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    cursor: pointer;
  }
`)

export const GraphContentWrapper = styled(Box)(`
  width: 100%;
  display: flex;
  flex-direction: column;
  & .contentText {
    background:#fff;
  }

  &.showRowLevel2 {
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.15);
    border-bottom: 10px #fff solid;
    position:relative;
  }
//   &.showRowLevel2:before {
//     // background: #FFF;
//     // border-bottom: 20px #fff solid;
//     box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.15);
//     top:0;
//     left:0;
//     content:'';
//     position:absolute;
//   }
  &.showRowLevel2 .graphRowDetails {
    background: #fff;
  }
  &.showRowLevel2 .contentText {
    border-bottom:0 !important;
  }
  &.showRowLevel2 > div:last-child > div {
    border-bottom:0 !important;
  }
`)

export const GraphContent = styled(Box)(`
    width: 100%;
    display: flex;
    &:first-of-type {
       & .list {
        // border-color: transparent;
       }
    }
    &:last-child {
       & .contentText {
        //   border-bottom: 2px solid #F5F5F5;
       }
    }
`);

export const ContentText = styled(Box)(props => `
    width: ${RIGHT_CONTENT};
    min-width: ${RIGHT_CONTENT};
    text-align: right;
    display: flex;
    flex-direction:column;
    justify-content: center;
    border-top: 2px solid #F5F5F5;
    padding-right: 10px;
    & .title {
        font-size: 1rem;
        font-weight: 300;
    }
    & .percent {
        font-size: 1rem;
        font-weight: 500;
    }
    & .percent b {
        font-weight: 600;
    }
    &.showEdit_true {
        padding: 8px 40px 8px 0;
        position:relative;
        justify-content: flex-start;
    }
    &.showEdit_true button {
        position:absolute;
        top: 10px;
        right: 3px;
    }
    & .hideP {
        display:none !important;
    }
`);

export const GraphRow = styled(Box)(`
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: calc(100% - ${RIGHT_CONTENT} - ${SEARCH_ICON});
    min-height: 50px;
    border-top: 2px solid #F5F5F5;
    `)
    
export const GraphRowDetails = styled(Box)(`
    border-top: 2px solid #F5F5F5;
    display: flex;
    width: ${SEARCH_ICON};
    position: relative;
    justify-content: center;
    align-items: center;
    background:#fff; // Fixing Mozilla Issue

    & > button {
        height:35px;
    }
`)

export const GraphContentLevel2 = styled(Box)(`
    display: flex;
    flex-wrap: wrap;
    background:#fff;

    & .contentText {
        padding:10px 0 0 240px;
        width: 100%;
        border:0;
        background: #fff;
    }
    & .contentText p {
        text-align:left;
        font-size: 12px;
        font-weight: 300;
    }
    & .list {
        margin-left:242px;
        background-image: url('${imageryBgPlain}');
        background-position: 0;
        background-repeat-x: no-repeat;
        background-size:100%; 
    }
    & .titleOnlyForProgression {
        width: 100%;
        outline: 1px red solid;
        border-bottom: 1px #F3F3F3 solid;
    }
    & .pptSubHeading {display:none}
`)

export const BrandImageryRowInsights = styled(Box)(`
    padding-left: 240px;
    padding-right: 50px;
    background: #fff;
    & * {
        font-size:14px;
    }
    & .pptSubHeading {
        display:none;
    }
`)

export const BrandImageryRowInsightsHeading = styled(Box)(`
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    padding-top:10px;
`)

export const GridHeaderWrapper = styled(Box)(`
    display:flex;
    width:100%;
    position:relative;
    z-index:1;
`)

export const GraphContentRow = styled(Box)(`
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    display:flex;
    width:100%;
    background: #fff;
    padding:5px 0;
    & p {
        padding-right:10px;
    }
    & p.percent {
        font-weight:500;
    }
`)

export const SliderOuterWrapper = styled(Box)(`
	display: flex;
	width: 100%;
	justify-content: flex-start;
    position:relative;
    border-bottom: 1px #fff solid;
    padding:2px 0;
    // & .clientLogo{position:absolute; top:0; left:0;}
    & .textOuterWrapper {
        width:100%;
        display:flex;
        flex-direction:column;
        // margin-left:20px;
    }
    & .textWrapper {
        height:12px;
        width:100%;
        display:block;
        font-size:12px !important;
    }
    & .textWrapper .text {
        position: relative;
        width:100%;
        display:inline;
        font-size:12px !important;
    }
`)

export const TabCarouselWrapper = styled(Box)( props => `
	display: flex;
    padding:35px 50px 0 240px;
    background:#FFF;
    overflow:hidden;
    ${props.lhsMenu === 'reportPage_showLhs' ? `width: calc(100vw - 285px);` : `width: calc(100vw - 75px);`}
    
    @-moz-document url-prefix() {
        ${props.lhsMenu === 'reportPage_showLhs' ? `width: calc(100vw - 240px);` : `width: calc(100vw - 75px);`}
    }
`)

export const TabCarousel = styled(Box)(`
	display: flex;
	border: 1px #fff solid;
    background: #F3F3F3;
	width: 100%;
    padding: 10px;
    overflow: auto;
    
    & .slider > div {
        padding: 0 5px;
    }
    & p {
        padding: 0 10px;
        margin: 0;
        height: 30px;
        cursor: pointer;
        line-height: 30px;
        font-size: 14px;
        display: inline-block;
        white-space: nowrap;
    }
    & p.active {
        font-weight: 500;
        background:#FFF;
    }
    & .slick-slider {
        width:100%;
        height: 30px;
        display:flex;
        align-items:center;
    }
    & .slick-slider button {
        width:30px;
        height:20px;
        margin-top: 20px;
        position: static;
        top:0;
        left:0;
        right:0;
    }
    & .slick-slider svg {
        width:30px;
        height:20px;
    }
    & .slick-slider .slick-list {
        width:calc(100% - 60px);
    }
`)

export const ClientLogo = styled(Box)(`

    width: 230px;
    margin-left: -230px;
    display: flex;
    align-items: center;
    justify-content: end;
    padding-right:10px;

    & img {
        max-height:16px;
    }
`)











// Filter Section CSS
// Filter Section CSS
// Filter Section CSS
// Filter Section CSS
// Filter Section CSS
export const FilterOuterWrap = styled(Box)(`
    padding: 10px 30px 0;
	display: flex;
	width: 100%;
	margin-top:-40px;

	.filterButton {
		color: #095BBD;
		font-size: 14px;
		font-weight: 500;
		background: none;
		padding: 0;
		min-width: auto;
		margin-bottom:10px;
	}
`)

export const FilterExpanded = styled(Box)(`
	display: flex;
	width: 100%;
	flex-direction: column;
    align-items: flex-start;
	padding-bottom: 15px;
	& label span {
		line-height: 15px !important;
		font-size: 14px !important;
	}
	& .selectAll {
		margin-left:-16px;
		color:#095BBD;
	}
	& svg {
		margin-left:10px;
	}
`)

export const FilterCollapsed = styled(Box)(`
	display: flex;
	width: 100%;

	& svg {
		margin-left:10px;
		transform: rotate(180deg);
	}
`)

export const FilterHeading = styled(Typography)(`
	display: flex;
	width: 100%;
	color: #000;
	font-size: 14px;
	font-weight: 500;
`)

export const FilterSelectAll = styled(FormControlLabel)(`
  & .MuiTypography-root {
    font-size: 14px;
    font-weight: 400;
	color: #095BBD;
  }
  & .MuiCheckbox-root{
    padding: 0;
    margin: 3px 10px auto 12px;
  }
`)

export const FilterCheckboxWrap = styled(Box)(`
	display: flex;
	padding: 5px;
	flex-wrap: wrap;
	width: 100%;
	& label {
		width: 180px;
		align-items: flex-start;
		padding-bottom: 10px !important;
	}
	& label span {
		// line-height: 15px !important;
		// font-size: 14px !important;
		padding-top:0 !important;
		white-space: break-spaces !important;
	}

`)

export const FilterButtonsWrap = styled(Box)(`
	display: flex;
	width: 100%;
	justify-content: end;
	padding: 15px 0 30px 0;
`)



