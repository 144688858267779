import React, { StrictMode } from 'react'
import { createRoot } from 'react-dom/client';

import App from './App'
import * as serviceWorker from './serviceWorker'
import Provider from './store'

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './AzureAuthConfig';

const msalInstance = new PublicClientApplication(msalConfig);

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  // <StrictMode>
    <Provider>
      <MsalProvider instance={msalInstance}>
        <App />
      </MsalProvider>
    </Provider>
  // </StrictMode>
)

serviceWorker.unregister()
