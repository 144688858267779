import styled from "@emotion/styled";
import { Box, Typography, FormControlLabel } from "@mui/material";
import { exportToPPT_tableCss } from '../../../../util'
import { MEDIA } from "../../../../constants";

export const RootWrap = styled(Box)(`	
	&.exportToPPT {padding:0; width:1200px;}
	&.exportToPPT .tabsWrapper {display:none;}
	&.exportToPPT .segmentWrap {width: 100% !important; border:1px #fff solid;}
	&.exportToPPT .segmentRows * {font-size:15px !important; font-weight:500 !important; }
	&.exportToPPT .segmentWrap .segmentTitle { width:170px; }
	&.exportToPPT .segmentWrap .segmentRows { margin-bottom:10px !important; box-shadow:none;}
	&.exportToPPT .segmentWrap .segmentRows > div {padding:8px 20px;}
	&.exportToPPT .segmentWrap .segmentRows > div > button {display:none}
	&.exportToPPT .segmentRowsLevel2 {padding:0 !important;}
	&.exportToPPT .segmentRowsLevel2 > div {padding:3px 0 !important;}
	&.exportToPPT .segmentRowsLevel2 .segmentRowsLevel2List {padding:5px 0; }
	&.exportToPPT .segmentRowsLevel2 .segmentRowsLevel2List div{font-size:12px !important; }
	&.exportToPPT .segmentRowsLevel2 .segmentRowsLevel2List svg{display:none;}
	&.exportToPPT .legendRoot { justify-content:end; }
	&.exportToPPT .filterOuterWrap { display: none; }
    ${exportToPPT_tableCss}
	
	@media (max-width: ${MEDIA.lg}) {
		padding:20px 20px 40px 20px;
	}
`)

export const MuiButtonWrap = styled(Box)(`
    position:relative;
`)

export const ContentWrap = styled(Box)(`
	width: 100%;
`)

export const HeaderWrap = styled(Box)(`
	display: flex;
	align-items: center;
	width: 100%;
`)

export const HeaderTab = styled(Box)(`
    display: flex;
	align-items: center;
	background: #F7F7F9;
	padding: 6px 10px;
	// overflow: hidden; // showing error when we click on Global Tutorial Button
	width: 100%;
	& .tab {
		padding: 5px 10px;
		font-size: 12px;
		font-weight: 300;
		margin-right: 2px;
		cursor: pointer;
		position:relative;

		&.active {
			background: #fff;
			font-weight: 500;
		}
	}
`)

export const SegmentWrap = styled(Box)(`
	width: 100%;
`)

export const SegmentRowDetails = styled(Box)(`
	width: 70%;
	padding:5px 0;
	@media (max-width: ${MEDIA.lg}) {
		width:90% !important;
	}
	@media (max-width: 1000px) {
		width:100% !important;
	}
`)

export const SegmentRowHeading = styled(Box)(`
	padding: 1px 5px 1px 14px;
	width: 100%;
	font-weight: 500;
	font-size: 14px;
	// line-height: 21px;
	line-height: 32px;
	color: #000000;
	display:flex;
	justify-content:space-between;
	border-bottom:1px #fff solid;
	align-items: center;
	// &.selected button{
	// 	transform:rotate(180deg);
	// }
`)

export const SegmentRow = styled(Box)(`
	font-size: 12px;
	font-weight: 300;
	position: relative;
	display:flex;
	flex-direction: column;
	justify-content: center;

	background: #F7F7F9;
	box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.08);
	margin-bottom:20px;

	& .outerText{
		min-width: 32px;
		font-size: 12px;
		margin-left: 6px;
		font-weight: 300;
	}
`)

export const SegmentRowContent = styled(Box)(`
	display: flex;
	align-items: center;
	margin: 6px 0;
	position: relative;
	padding:0 20px;
`);

export const SegmentTitle = styled(Typography)(`
	font-size: 12px;
	font-weight: 300;
	width: 65px;
	min-width: 100px;
	text-align: right;
	margin-right: 10px;
	line-height: 12px;
`)

export const SegmentSubTitle = styled(Typography)(`
	width: auto;
	min-width: 100px;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	margin: 0 0 0 -10px;
`)

export const BarWrap = styled(Box)(`
	width: 100%;
	&.association {
		background: #F1F1F3;
		border-radius: 0px 20px 20px 0px;
		padding: 4px;
		& .barSlice {
			border-radius: 0px 20px 20px 0px;
			height: 8px !important;
		}
	}
	& .barRoot, .barItemRoot, .barSliceWrap, .barSlice {
		height: 5px !important;
	}
	& .barSlicePercentage, & .barSliceLabel{
		font-size: 12px !important;
	}
`);

export const IssuesWrap = styled(Box)(`
	position: absolute;
	left: calc(100% + 5px);
	display: flex;
	align-items: center;

	& .radio {
		width: 10px;
		height: 10px;
		min-width: 10px;
		min-height: 10px;
		border: 1px solid #7E7E7E;
		margin-right: 4px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		& .box {
			width: 4.5px;
			height: 4.5px;
			min-width: 4.5px;
			min-height: 4.5px;
			background: #7E7E7E;
			border-radius: 50%;
		}
	}
	& .radio-text {
		font-size: 12px;
		font-weight: 300;
		white-space: nowrap;
	}
	
	@media (max-width: ${MEDIA.lg}) {
		overflow: hidden;
		width: 250px;
		flex-wrap: wrap;
		height: 20px;
	}
`);


export const ComparisonViewWrap = styled(Box)(`
	& .segment-row {
		// width: 65% !important;
		width: 65%;
		@media (max-width: ${MEDIA.lg}) {
			// width:calc(100% - 250px) !important;
			width:calc(100% - 250px);
		}
	}
	& .segment-row-content {
		margin-left: -130px;
		padding-right: 40px;
		& .segment-title {
			margin-right: 20px;
		}
		& .barRoot, .barItemRoot, .barSliceWrap, .barSlice {
			height: 20px !important;
		}
	}
`)

export const SegmentDetailsLevel2 = styled(Box)(`
	background:#fff;
	width:100%;
	display:flex;
`)

export const SegmentDetailsLevel2ListingLHS = styled(Box)(`
	width:50%;
	border-right:1px #eee solid;
	& > div:last-child{
		border-bottom:0;
	}
`)

export const SegmentAccordionLevel2Wrap = styled(Box)(`
	border-bottom:1px #eee solid;
	padding:10px 0;
`)

export const SegmentAccordionLevel2Heading = styled(Box)(`
	width:100%;
	display:flex;
	justify-content:space-between;
	align-items:center;
	padding:0 10px;
	font-weight: 300;
	font-size: 14px;
	line-height: 14px;
	cursor:pointer;
	&.selected{
		font-weight:500;
	}
`)

export const SegmentAccordionLevel2Details = styled(Box)(`
	width:100%;
	display:flex;
	flex-direction:column;
	padding-top:5px;
`)

export const SegmentDetailsLevel2ListingRHS = styled(Box)(`
	width:50%;
	padding:10px;

	& > div > div > div > div {
		margin-bottom:0;
	}

	& button { padding:0; }
`)

export const SegmentAccordionLevel3Heading = styled(Box)(`
	width:100%;
	padding:0 0 8px 0;
	border-bottom: 2px solid #EEEEEE;
	font-weight: 500;
	font-size: 14px;
	line-height: 14px;
	display: flex;
    justify-content: space-between;
    align-items: center;
`)

export const SegmentAccordionLevel3Wrap = styled(Box)(`
	padding:5px 0;
`)

export const SegmentAccordionLevel3SubHeading = styled(Box)(`
	font-weight: 300;
	font-size: 14px;
	line-height: 14px;
`)

export const SegmentAccordionLevel3Details = SegmentAccordionLevel2Details

export const ProgressionWrapper = styled(Box)(`
	padding-bottom:10px;
`)

// Filter Section CSS
export const FilterOuterWrap = styled(Box)(`
	display: flex;
	width: 100%;
	margin-top:-40px;

	.filterButton {
		color: #095BBD;
		font-size: 14px;
		font-weight: 500;
		background: none;
		padding: 0;
		min-width: auto;
		margin-bottom:10px;
	}
`)

export const FilterExpanded = styled(Box)(`
	display: flex;
	width: 100%;
	flex-direction: column;
    align-items: flex-start;
	padding-bottom: 15px;
	& label span {
		line-height: 15px !important;
		font-size: 14px !important;
	}
	& .selectAll {
		margin-left:-16px;
		color:#095BBD;
	}
	& svg {
		margin-left:10px;
	}
`)

export const FilterCollapsed = styled(Box)(`
	display: flex;
	width: 100%;

	& svg {
		margin-left:10px;
		transform: rotate(180deg);
	}
`)

export const FilterHeading = styled(Typography)(`
	display: flex;
	width: 100%;
	color: #000;
	font-size: 14px;
	font-weight: 500;
`)

export const FilterSelectAll = styled(FormControlLabel)(`
  & .MuiTypography-root {
    font-size: 14px;
    font-weight: 400;
	color: #095BBD;
  }
  & .MuiCheckbox-root{
    padding: 0;
    margin: 3px 10px auto 12px;
  }
`)

export const FilterCheckboxWrap = styled(Box)(`
	display: flex;
	padding: 5px;
	flex-wrap: wrap;
	width: 100%;
	& label {
		width: 180px;
		align-items: flex-start;
		padding-bottom: 10px !important;
	}
	& label span {
		// line-height: 15px !important;
		// font-size: 14px !important;
		padding-top:0 !important;
		white-space: break-spaces !important;
	}

`)

export const FilterButtonsWrap = styled(Box)(`
	display: flex;
	width: 100%;
	justify-content: end;
	padding: 15px 0 30px 0;
`)


