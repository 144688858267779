import React from 'react'
import { InfoWrapper, StyledTypography } from './style';

const InfoRow = () => {

  return (
    <InfoWrapper className='infoRow'>
        <StyledTypography>
            <span>Size of the bubble indicates the Category engagement of the Insight Space (Relative importance of the insight space in the Category)</span>
        </StyledTypography>
    </InfoWrapper>
  )
}


export default InfoRow;