import { styled } from '@mui/system'
import { Box } from '@mui/material'
import { loader } from '../../assets/images/icons'

export const Wrapper = styled(Box)(`
    background-color: rgba(255, 255, 255, 0.02);
`)

export const ImgWrap = styled(Box)( props => `
    width:${props.size || 24}px;
    height:${props.size || 24}px;
`)

export const ImageWrapper = styled(Box)( props => `
    @keyframes example {
        0% {opacity: 0;}
        9%  {opacity: 0;}
        10%  {opacity: 1;}
        100% {opacity: 1;}
    }

    // clip-path: polygon(26% 75%, 27% 99%, 1% 100%, 0% 0%, 100% 0%, 100% 100%, 74% 100%, 74% 76%, 73% 47%, 71% 40%, 67% 33%, 61% 28%, 54% 26%, 46% 26%, 40% 29%, 35% 32%, 30% 37%, 27% 45%, 26% 51%);
    background: url(${loader}) no-repeat center center;
    background-size: cover;
    width:${props.size || 24}px;
    height:${props.size || 24}px;

    opacity: 1;
    animation-name: example;
    animation-duration: 10s;
`)
