import styled from "@emotion/styled";
import { Box, TableBody, Typography } from "@mui/material";

export const CampaignMagnetScoreWrap = styled(Box)(`
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    position: relative;
    width: 100%;
    padding: 20px;
`);

export const StyledHeading = styled(Typography)(`
    font-size: 12px;
    max-width: 110px;
    text-align: right;
    line-height: 14px;
    font-weight: 300;
    margin-right: 8px;
    position: absolute;
    left: -120px;
    width: 100%;
`);

export const SectionRowWrap = styled(Box)(`
   display: flex;
   justify-content: space-between;
   align-items: center;
   & .headerTitle {
    font-size: 20px;
    font-weight: 300;
   }
   & .iconWrap {
    display: flex;
    align-items: center;
        & .rightLink {
            font-size: 14px;
            font-weight: 300;
            color: #095BBD;
            margin-right: 5px;
        }
   }
`);


export const LegendRowWrap = styled(Box)(`
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 16px 0 60px 0;
   border-bottom: 1px solid #EEEEEE;
   margin-bottom: 50px;
`);


export const InteractionWrap = styled(Box)(`
    & .barSlice {
      & .barSliceLabel {
        //   font-size: 12px;
        //   font-size: 14px;
          font-weight: 300;
          top: 28px;
          position: absolute;
          white-space: nowrap;
          z-index: 1;
          color: #000;
          left: -5px;
      }
    }
`);

export const TableWrap = styled(Box)(`
    & th {
        text-transform:none !important;
        background: #F1F1F3;
        padding:5px;
    }
    & th > div{
        padding:11px;
    }
    & th:nth-of-type(1){
        background:#fff !important;
        font-size:0 !important;
        visibility:visible;
    }
    & th:nth-of-type(2) > div{
        background:#fff;
        line-height:18px;
    }
    & td {
        padding-top:16px;
        padding-bottom:12px;
        vertical-align:top;
    }
    & td .triggerName{
        width: 80%;
        margin-left: 20%;
        line-height: 18px;
        min-height: 40px;
        display: flex;
        justify-content: end;
        align-items: center;
    }
    & td .campaignMagnetScore{
        padding-top:0;
        padding-left:12px;
    }
`);