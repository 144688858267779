import React, {useState, useEffect} from 'react'
import { useHistory } from 'react-router-dom'

import { Collapse, IconButton, Table, TableCell, TableContainer, TableRow, Typography } from '@mui/material'
import { arrowDown, arrowUp, ellipsis } from '../../../assets/images/icons'

import { NoDataFound, Loader, PreLoadReportsData } from '../../../components'
import { timeStampToDate, dateDifference, checkLocalStorageSize } from '../../../util'

import {TableHeadWrapper, TableBodyWrapper, TableCellHead, TableRowContent, CollapseContent, TableChipInProgress, TableChipAdminReview, TableChipCompleted } from './style'
import {ROLES_MM_PRIMARY, ROLES_CLIENT_PRIMARY, ROLES_CLIENT, ROLES_JUNIOR_ANALYST} from '../../../constants'

import {fire} from '../../../assets/images/icons'

import { useGlobalStore } from '../../../store'

const Row = (props) => {
  const { user, setLoadAllLhs } = useGlobalStore()
  const navigate = useHistory()
  const { row, getRowDetailDataFunc, loading, loadingDetails } = props
  const [open, setOpen] = useState(false)
  const [showLoaderData, setShowLoaderData] = useState()
  const openDetails = () => {
    getRowDetailDataFunc({projectId: row.ProjectId})
    setOpen(!open)
  }

  return (
    <>
      {showLoaderData && <PreLoadReportsData data={showLoaderData} page='projects' />}

      <TableRow onClick={openDetails} className={`tr_heading tr_${open}`}>
        <TableCell>{row?.BrandName}</TableCell>
        <TableCell>{row.ProjectType}</TableCell>
        <TableCell>{row.MarketName}</TableCell>
        <TableCell>{timeStampToDate(row.ModifiedDate)} {dateDifference(row.ModifiedDate) <= 10 && <img src={fire} alt='Latest Project' style={{verticalAlign:'top', paddingLeft:'10px'}} />}</TableCell>
        <TableCell className='showProjectDetails' sx={{position:'relative'}}>
          <IconButton aria-label='expand row' size='small'>
            {open ? <img src={ellipsis} alt='Arrow Up' /> : <img src={ellipsis} alt='Arrow Down' />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRowContent className='tr_content'>
        <TableCell className='tableCell' style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse className='tr_collapse' in={open} timeout='auto' unmountOnExit>

            {loadingDetails && row?.details && row.details.length === 0 && <Loader />}

            {!loadingDetails && row?.details && row.details.length === 0 && <Typography varient='body2'>No data found</Typography>}

            {row?.details && !!row?.details.length && row.details.map((d, i) => (
              <CollapseContent key={i} type={d.StudyStatus} className={d.StudyStatus}>
                {d.StudyStatus === 'InProgress' && [...ROLES_MM_PRIMARY, ROLES_JUNIOR_ANALYST].includes(user.UserType) && <><TableChipInProgress type={d.StudyStatus} onClick={() => {setShowLoaderData(d)}}>In Progress</TableChipInProgress> | {d.Period}</>}
                {d.StudyStatus === 'InProgress' && [...ROLES_CLIENT, ROLES_JUNIOR_ANALYST].includes(user.UserType) && <><TableChipInProgress type={d.StudyStatus} style={{cursor:'default'}}>In Progress</TableChipInProgress> | {d.Period}</>}
                {d.StudyStatus === 'AdminReview' && [...ROLES_CLIENT_PRIMARY, ROLES_JUNIOR_ANALYST].includes(user.UserType) && <><TableChipAdminReview type={d.StudyStatus} onClick={() => {setShowLoaderData(d)}}>Admin Review</TableChipAdminReview> | {d.Period}</>}
                {d.StudyStatus === 'Completed' && <><TableChipCompleted type={d.StudyStatus} onClick={() => {setShowLoaderData(d)}}>Completed</TableChipCompleted> | {d.Period}</>}
              </CollapseContent>
            ))}
            
          </Collapse>
        </TableCell>
      </TableRowContent>
    </>
  )
}

const DataGridTable = (props) => {
  const { columns, rows, loading, loadingDetails, sortingBy, getRowDetailData } = props

  const [sortedRowData, setSortedRowData] = useState([])
  const [columnData, setColumnData] = useState(columns)
  const [sortBy, setSortBy] = useState('')
  
  const sortArray = (key) => {
    const selectedKey = columnData.find(c => c.sortKey === key)
    const selectedSorting = () => {
      if (selectedKey.sort === null) return true
      if (selectedKey.sort) return false
      if (!selectedKey.sort) return null
    }

    const compare = ( a, b ) => {
      if ( a[key] < b[key] ){ return -1 }
      if ( a[key] > b[key] ){ return 1 }
      return 0
    }

    if(selectedSorting() === null) {
      setSortBy('')
      setSortedRowData(rows)
    }
    else{
      setSortBy(key)
      const d = sortedRowData.sort( compare )
      if (selectedSorting()) { setSortedRowData( sortedRowData.sort(compare) ) }
      else { setSortedRowData( sortedRowData.reverse() ) }
    }

    const updatingColumnData = columnData.map((c) => {
      c.sort = (c.sortKey === key) ? selectedSorting() : null
      return c
    })
    setColumnData(updatingColumnData)  
  }

  const getRowDetailDataFunc = (param) => {
    getRowDetailData(param)
  }

  useEffect(() => {
    setSortedRowData([...rows])
    checkLocalStorageSize()
  },[rows])

  return (
    <>
      <TableContainer>
        {loading && <Loader />}
        {!loading && <Table>
          <TableHeadWrapper>
            <TableRow>
              { columnData.map((c, i) => (
                  <TableCellHead key={i} onClick={() => sortArray(c.sortKey)} width={c.width}>
                      {c.field}
                      {c.sort !== null && <img src={c.sort ? arrowUp : arrowDown} alt='' />}
                  </TableCellHead>
              ))}
              <TableCell width='50px' />
            </TableRow>
          </TableHeadWrapper>
          <TableBodyWrapper>
            {sortedRowData.length === 0 && <TableRow><TableCell colSpan={columnData.length+1}><NoDataFound title='No Projects Yet'></NoDataFound></TableCell></TableRow>}
            {sortedRowData.length !== 0 && sortedRowData.map((row,i) => {
              return <Row key={i} {...props} row={row} getRowDetailDataFunc={getRowDetailDataFunc} />
            })}
          </TableBodyWrapper>
        </Table>}
      </TableContainer>
    </>
  )
}

export default DataGridTable
