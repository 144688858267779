import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import { imageryBg, imageryBgPlain } from '../../../../assets/images'
import { MAGNET_SCORE_IMAGE } from '../../../../constants'
import { exportToPPT_tableCss } from '../../../../util'
import { MEDIA } from '../../../../constants'

const RIGHT_CONTENT= "242px"


export const OuterWrapper = styled(Box)(`
    width: 100%;
    padding: 20px 30px 50px 30px;
    &.exportToPPT {padding:0;}
    &.exportToPPT .list {min-height:65px;}
    &.exportToPPT * {font-size:20px !important;}
    ${ exportToPPT_tableCss }
    @media (max-width: ${MEDIA.lg}) {
        padding:20px 20px 40px 20px;
    }
`)

export const GraphOuterWrapper = styled(Box)(`
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    align-items: end;
`)

export const GraphInnerWrapper = styled(Box)(`
    display:flex;
    width:100%;
    position: relative;
`)

export const Graph = styled(Box)(props => `
    position: relative;
    display: flex;
    margin-left: auto;
    flex-direction: column;
    width: 100%;
    align-items: end;
    overflow: hidden;
    z-index: 1;
    background-image: url('${props.simulator === "yes" ? imageryBg : imageryBgPlain }');
    background-position: top right;
    background-repeat-x: no-repeat;
    background-size: calc(100% - ${RIGHT_CONTENT});
`)


export const GraphScaleWrapper = styled(Box)(`
    width: calc(100% - ${RIGHT_CONTENT});
    display: flex;
    justify-content: space-between;
    & span {
        width: calc(100% / 7);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    & .txt1 { justify-content: start;}
    & .txt2 { justify-content: start;}
    & .txt3 { justify-content: start;}
    & .txt4 { justify-content: center; width:20px;}
    & .txt5 { justify-content: end;}
    & .txt6 { justify-content: end;}
    & .txt7 { justify-content: end;}
`
)

export const GraphInfoWrapper = styled(Box)(`
    width: calc(100% - ${RIGHT_CONTENT});
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    > span {
        font-size: 12px;
        line-height: calc(14 / 12);
        &:first-of-type {
            display: flex;
            align-items: center;
        }
        &:last-child {
            display: flex;
            align-items: center;
            flex-direction: row-reverse;
            >img{
                transform: rotateY(180deg);
                filter: hue-rotate(-245deg) brightness(1.5);
            }
        }
        >img{
            padding-right: 10px;
        }
    }
`)

export const SliderWrap = styled(Box)(props => `
  height: 15px;
  display: flex;
  align-items: center;
  & img {
    position: relative;
    left: ${props.percent}%;
    transform: translate(-${props.percent}%, 0px);
    width: ${MAGNET_SCORE_IMAGE[props?.logoname || ""]?.width || 80}px;
    height: 15px;
  }
  & .slider {
    -webkit-appearance: none;
    width: 100%;
    height: 12px;
    border-radius: 5px;
    background: transparent;
    outline: none;
    -webkit-transition: .2s;
    transition: opacity .2s;
    border-radius: 0;
  }
  
  & .slider:hover {
    background: ${props.disabled ? "transparent" : "#ffffff50"};
  }
  
  & .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: ${MAGNET_SCORE_IMAGE[props?.logoname || ""]?.width || 80}px;
    height: 15px;
    object-fit: contain;
    border: 0;
    background-image: url(${props?.logo});
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    cursor: ${props.disabled ? "default" : "poiner"};
  }
  
  & .slider::-moz-range-thumb {
    appearance: none;
    width: ${MAGNET_SCORE_IMAGE[props?.logoname || ""]?.width || 80}px;
    height: 15px;
    object-fit: contain;
    border: 0;
    background-image: url(${props?.logo});
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    cursor: pointer;
  }
`)

export const GraphContent = styled(Box)(`
    width: 100%;
    display: flex;
    &:first-of-type {
       & .list {
        border-color: transparent;
       }
    }
    &:last-child {
       & .contentText {
        border-bottom: 2px solid #F5F5F5;
       }
    }
`);

export const ContentText = styled(Box)(props => `
    width: ${RIGHT_CONTENT};
    min-width: ${RIGHT_CONTENT};
    text-align: right;
    display: flex;
    flex-direction:column;
    justify-content: center;
    border-top: 2px solid #F5F5F5;
    padding-right: 10px;
    & .title {
        font-size: 1rem;
        font-weight: 300;
    }
    & .percent {
        font-size: 1rem;
        font-weight: 500;
    }
`);

export const GraphRow = styled(Box)(`
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: calc(100% - ${RIGHT_CONTENT});
    min-height: 50px;
    border-top: 2px solid #FFFFFF;
`)