import React from 'react'
import { ChartBarHorizontalCustom, Legend } from '../../../../components'
import { LEGEND_COLOR_MAP, GRAPH_COLOR_PALLET2 } from '../../../../constants'
import { BarWrap, ContentWrap, LegendWrap, SegmentRow, SegmentRowContent, SegmentWrap } from './style'

const BuyerTable = ({ data, isExtraColor=false, colorPallet=[] }) => {
	const rowData = (rowItem) => {
		const obj = {};
		for (let index = 0; index < rowItem.length; index++) {
			const element = rowItem[index];
			obj[index+''] = element?.SegmentValue
		}
		return [obj]
	}

	const legendData = () => {
		const labelArray = data?.[0].SecondarySegmentSet?.SecondarySegment || []
		const arr = []
		for (let index = 0; index < labelArray.length; index++) {
			const obj = {
				label: labelArray[index]?.SegmentName || '',
				value: index
			}
			arr.push(obj);
		}
		return arr;
	}

	let colors = isExtraColor ? GRAPH_COLOR_PALLET2 : LEGEND_COLOR_MAP;
	if(colorPallet.length > 0){
		colors = colorPallet
	}

	return (
		<ContentWrap>
		{
			!!data?.length &&
			<SegmentWrap>
				{
					data.map((item, index) => {
						return (
							<SegmentRow className='list segmentOfSegmentOverviewRow' key={index} data-age={item.PrimarySegmentName}>
								<SegmentRowContent>
								<BarWrap className={item?.type?.toLowerCase()}>
									<ChartBarHorizontalCustom
										showInside={true}
										bottomText={false}
										data={rowData(item?.SecondarySegmentSet?.SecondarySegment)}
										valueInPercent
										colorMap={colors}
										maxPercentRatio={100}
										maxWidth="100%"
									/>
								</BarWrap>
							</SegmentRowContent>
							</SegmentRow>
						)
					})
				}
				<LegendWrap className='legend'>
					<Legend legends={legendData()} colorPallet={colorPallet} />
				</LegendWrap>
			</SegmentWrap>
		}
		</ContentWrap>
	)
}

export default BuyerTable