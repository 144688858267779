import React, { useState, useEffect, useRef } from 'react'
import ReportsService from '../../../../../services/reports.service'

import { Modal, Rte, MuiButton } from '../../../../../components'
import { IconButton } from '@mui/material'
import { edit } from '../../../../../assets/images/icons'

import { LoginButton, Title, Wrapper, RteWrapper, EditWrap } from './style'

const ReportEditInsights = (props) => {
  const {InsightsElementId, factorLinkageId, pageData, onSuccess, title, data, onClose} = props
  const [anchorElUser, setAnchorElUser] = useState(null)
  const [showEditHeaderModal, setShowEditHeaderModal] = useState(true)

  const handleOnEditHeader = () => {
    setAnchorElUser(null)
    setShowEditHeaderModal((prev)=>!prev)
    onSuccess()
  }

  const handleClose = () => {
    setAnchorElUser(null)
    setShowEditHeaderModal((prev)=>!prev)
    onClose()
  }

  const form = useRef()
  const [loader, setLoader] = useState(false)
  // const [headingValue, setHeadingValue] = useState(pageData || '')
  const [headingValue, setHeadingValue] = useState(data?.targetInsights || '')
  
  // const { enqueueSnackbar } = useSnackbar()

  const onSubmit = (e) => {
    e.preventDefault()
    const updatedValues = {
      insightsElementId : data.insightsElementId,
      // factorLinkageId : data.rest.targetId,
      // factorLinkageId : data.rest.FactorLinkages.FactorLinkage[0].FactorLinkageId,
      factorLinkageId : data.targetId,
      insights : headingValue || pageData,
    }

    setLoader(true)
    ReportsService.setFactorLinkageInsights(updatedValues)
    .then((resp) => {
      setLoader(false)
      setShowEditHeaderModal(false)
      onSuccess()
    })
    .catch((error) => {
      setLoader(false)
      const resMessage = error
      console.log('ERROR : ', resMessage)
        // enqueueSnackbar(resMessage, { variant: 'error' })
    })
  }

  return (
    <>
      {/* <EditWrap><IconButton color='primary' onClick={() => setShowEditHeaderModal(true)}><img src={edit} alt='Close' width='10px' /></IconButton></EditWrap> */}
      {/* <MuiButton color='secondary' variant='outlined' startIcon={<IconEdit />} onClick={() => setShowEditHeaderModal(true)}></MuiButton> */}
      {showEditHeaderModal && <Modal title='' showClose={true} onClose={handleClose}>
        <Wrapper>
          <form ref={form}>
            {console.log('form data = ', data)}
            <Title variant='body1'>Edit {data.source} - {data.targetStr}</Title>

            <RteWrapper>
              <Rte title='' value={headingValue} onChange={(e) => setHeadingValue(e)} height={250} customToolbar='h4 | bold italic underline | undo redo' />
            </RteWrapper>

            <LoginButton
              color='primary'
              variant='contained'
              type='submit'
              onClick={onSubmit}
              disabled={loader || false}
            >
              Submit
            </LoginButton>
          </form>
        </Wrapper>
      </Modal>}
    </>
  )
}

export default ReportEditInsights
