import { Box, Typography } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import HeaderRow from './headerRow'
import SandBoxDataTable from './sandBoxDataTable'
import ReportsService from '../../../../services/reports.service'
import { useUpdateLhs } from '../../../../hooks/useUpdateLhs'
import { EmojiRow, Loader, LoaderWrapper, NoDataFound, ReportHeading, ReportOuterWrapper } from '../../../../components'
import { exportExcel_addSegments, exportExcel_addPercentage } from '../../../../util'
import CampaignMagnetDetail from './campaignMagnetDetail'
import { HeadingWrap, RowWrap, RootWrap } from './style'
import Info from './info'

const ReportCampaignOverview = (props) => {
  const [state, setState] = useState([])
  const [loading, setLoading] = useState(false)
  const [reportExcelData, setReportExcelData] = useState()
  const {updateSubLhsApiLoaded} = useUpdateLhs()
  // const { enqueueSnackbar } = useSnackbar()

  const prepareData = useCallback((data) => {
    const mappedArray = data.map((dataItem) => {
      const Warning = dataItem.CampaignMagnetScore
      const Positive = dataItem.PositivePercent
      const Neutral = dataItem.NeutralPercent
      const Mixed = dataItem.MixedPercent
      const Negative = dataItem.NegativePercent
      const Interactions = dataItem.EarnedOrganicReach
      const PaidInteractions = dataItem.PaidReach
      const Association = dataItem.BrandAssociation
      const Mention = dataItem.BrandMention

      const firstItem = data[1]
      
      const obj = {
        ...dataItem,
        barData: {
          CampaignMagnetScore: {
            bar: [{ Warning }],
            icon: <EmojiRow number={dataItem.CampaignMagnetScore} />,
            maxPercentRatio: 100,
            title: 'Campaign Magnet Score',
          },
          SentimentEngagement: {
            bar: [{ Positive, Neutral, Mixed, Negative }],
            maxPercentRatio:
              parseInt(firstItem.PositivePercent) +
              parseInt(firstItem.NeutralPercent) +
              parseInt(firstItem.MixedPercent) +
              parseInt(firstItem.NegativePercent),
            title: 'SENTIMENT & ENGAGEMENT',
          },
          InteractionsPaidInteractions: {
            bar: [{ Interactions, PaidInteractions }],
            maxPercentRatio:
              parseInt(firstItem.EarnedOrganicReach) +
              parseInt(firstItem.PaidReach),
            title: 'INTERACTIONS & PAID INTERACTIONS',
          },
          BrandAssociation: {
            bar: [{ Association, Mention }],
            maxPercentRatio: 100,
            background: '#EEECE2',
            title: 'BRAND ASSOCIATION',
          },
        },
        SubTriggers: dataItem?.SubTriggers?.CampaignSubTrigger || [],
        DriverElements: dataItem?.DriverElements?.DriverElement || [],
        SentimentEngagement: [],
      }
      return obj
    })

    return mappedArray // 
  }, [])

  const sheet1Css = {width: { wch: 30 }, style: {...{font:{bold:true}} } } 
  const sheet2Css = {width: { wch: 30 }, style: {...{font:{bold:true}} } } 
  const getSentimentsFunc = (p) => {
    return (!!p)
      // ? [{value: `${p?.PositivePercent || 0}%`}, {value: `${p?.NeutralPercent || 0}%`}, {value: `${p?.MixedPercent || 0}%`}, {value: `${p?.NegativePercent || 0}%`}]
      ? exportExcel_addSegments(p)
      : [{value: 'Positive Percent', ...sheet2Css}, {value: 'Neutral Percent', ...sheet2Css}, {value: 'Mixed Percent', ...sheet2Css}, {value: 'Negative Percent', ...sheet2Css}]
  }

  const updateExportExcelFunc = (data) => {
    const reportExcelData = {sheet1:{heading:[], values:[]}, sheet2:{heading:[], values:[]}}
    const defaultArrayLength = 5
    const defaultArray = []

    reportExcelData.sheet1.heading = [
      {title:'Trigger Name', ...sheet1Css},
      {title:'Campaign Magnet Score', ...sheet1Css},
      {title:'Engagement', ...sheet1Css},
      {title:'Net Sentiment', ...sheet1Css},
      {title:'Interactions', ...sheet1Css},
      {title:'Paid Interactions', ...sheet1Css},
      {title:'Brand Association', ...sheet1Css},
      {title:'Brand Mention', ...sheet1Css}
    ]

    for(let i=0; i<data.length; i++){
      reportExcelData.sheet1.values.push([
        {value:data[i].TriggerName},
        {value:data[i].CampaignMagnetScore*1},
        {value:data[i].Engagement*1},
        // {value:`${data[i].PositivePercent - data[i].NegativePercent}%`},
        exportExcel_addPercentage(data[i].PositivePercent - data[i].NegativePercent),
        {value:data[i].EarnedOrganicReach*1},
        {value:data[i].PaidReach*1},
        // {value:`${data[i].BrandAssociation}%`},
        exportExcel_addPercentage(data[i].BrandAssociation),
        // {value:`${data[i].BrandMention}%`}
        exportExcel_addPercentage(data[i].BrandMention)
        
      ])
    }

    for(let i=0; i<defaultArrayLength; i++){ defaultArray.push({value:''}) }
    reportExcelData.sheet2.heading = defaultArray.map((v, i) => {
      return {title:' ', value:' ', width:{wch:(i===0) ? 35 : 15}}
    })
    for(let i=0; i<data.length; i++){
      const heading = [...defaultArray]
      heading[0] = {value: data[i].TriggerName, style:{ font: { sz: "12", bold:true, color: { rgb: "333" }} }}
      reportExcelData.sheet2.values.push(heading)
      
      const SubTriggersData = Array.isArray(data[i]?.SubTriggers) ? data[i]?.SubTriggers : [data[i]?.SubTriggers]
      if(!!SubTriggersData?.length){
        reportExcelData.sheet2.values.push([{value: 'Sub Trigger Name', ...sheet2Css}, ...getSentimentsFunc()])
        for(let j=0; j<SubTriggersData.length; j++){
          reportExcelData.sheet2.values.push([{value: SubTriggersData[j].SubTriggerName}, ...getSentimentsFunc(SubTriggersData[j])])
        }
        reportExcelData.sheet2.values.push(defaultArray)
      }

      const DriverElementsData = Array.isArray(data[i]?.DriverElements) ? data[i]?.DriverElements : [data[i]?.DriverElements]
      if(!!DriverElementsData?.length){
        reportExcelData.sheet2.values.push([{value: 'Driver Name', ...sheet2Css}, ...getSentimentsFunc()])

        for(let j=0; j<DriverElementsData.length; j++){
          reportExcelData.sheet2.values.push([{value: DriverElementsData[j].DriverName}, ...getSentimentsFunc(DriverElementsData[j])])
        }
        reportExcelData.sheet2.values.push(defaultArray)
      }
      
      reportExcelData.sheet2.values.push(reportExcelData.sheet2.heading)
      reportExcelData.sheet2.values.push(defaultArray)
    }
    setReportExcelData(reportExcelData)
  }

  const getData = useCallback(() => {
    setLoading(true)
    ReportsService.campaignTriggerElement({
      insightsElementId: props.InsightsElementId
    })
      .then((resp) => {
        const resp1 = resp.filter(f => f.TriggerName !== '[AVERAGE]').sort((a, b) => (b.CampaignMagnetScore*1) - (a.CampaignMagnetScore*1))
        const data = (prepareData(resp1)) //.sort((a, b) => (b.CampaignMagnetScore*1) - (a.CampaignMagnetScore*1))
        setState(data)
        updateSubLhsApiLoaded(props)
        updateExportExcelFunc(data)
      })
      .catch((error) => {
        const resMessage = error
        console.log('ERROR : ', resMessage)
        // enqueueSnackbar(resMessage, { variant: 'error' })
      })
      .finally(() => setLoading(false))
  }, [])

  useEffect(() => {
    getData()
  }, [])

  return (
    <>
      <ReportHeading downloadId={props.type} {...props} downloadIdData={reportExcelData} />
      <ReportOuterWrapper>
        <LoaderWrapper loading={loading} loadOnlyApi={props.loadOnlyApi}>
          <RootWrap id={props.type} minHeight='100vh' width='100%' className={`${props.InsightsElementType}`}>
            {state?.[0] && <HeaderRow data={{...(state?.[0] || {}), NetVibe: (state?.[0]?.PositivePercent || 0) - (state?.[0]?.NegativePercent || 0)}} />}
            {!!state?.length && <SandBoxDataTable data={state} />}

            {state?.map((page, i) => <Box key={i}><CampaignMagnetDetail row={page} rowsIsLast={(state.length-1 === i)} /></Box>)}

            {!state?.length && <NoDataFound
              title='We are working on this ...'
              loader={loading ? <Loader /> : null}
            />}
          </RootWrap>
        </LoaderWrapper>
      </ReportOuterWrapper>
    </>
   
  )
}

export default ReportCampaignOverview
