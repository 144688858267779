import { styled } from '@mui/material/styles'
import { Box, IconButton } from '@mui/material'
import { NavLink } from 'react-router-dom';
import { MEDIA } from '../../constants';

export const LhsRoot = styled(Box)( props => `
  display: flex;
  overflow: hidden;
  width: 200px;
  height: calc(100vh - 60px);
  position:relative;
  z-index:1;
  border-right: 1px solid #23232333;
  
  &.lhsHamburgerWrap{
    width: 40px;

    & .link{
      width:40px;
      padding:15px 10px;
    }

    & .lhsWrapper{
      width:40px;
      min-width:40px;
      overflow:hidden;
      padding:0;

    }
  }

  &.show {
    z-index: 1;
    @media (max-width: ${MEDIA.lg}) {
      display: flex;
    }
  }

  @media (max-width: ${MEDIA.lg}) {
    width: 300px;
    display: none;
    position: fixed;
    height: 100vh;
    top: 0;

    & > .lhsWrapper > button, & > .lhsWrapper > .desktopMenu {
      display:none;
    }
    & .headerLogo {
      margin-bottom: 20px;
      height: auto;
    }

    &.lhsHamburgerWrap.show {
      width:60px !important;
      & .lhsWrapper {
        width:60px !important;
      }
      & .link {
        width: 60px;
        padding: 15px 18px;
      }
    }
  
  }

`);

export const LhsWrapper = styled(Box)(props => `
  display: flex;
  flex-direction: column;
  background:#fff;
  z-index: 3;
  // padding-top: ${props.isreportpage ? "10px" : "40px"};
  padding-top: 30px;
  padding-bottom: 20px;
  
  height: calc(100vh - 60px);
  min-width: 213px;
  width: 213px;
  overflow-y:scroll;

  &.lhsHamburger {
    min-width:40px;
    width:40px;
    overflow:hidden;
    display:flex;
    align-items:center;
    padding-top:10px;
    & button {
      position: static;
      left: auto;
      top: auto;
      margin:10px 0 0 0;
    }
  }
  
  @-moz-document url-prefix() {
    min-width: 205px;
    width: 205px;
  }

  @media (max-width: ${MEDIA.lg}) {
    // padding-top: ${props.isreportpage ? "0" : "40px"};
    overflow-x: hidden;
    overflow-y: auto;
    height: 100vh;
    width: 300px;
    & .headerLogo {
      margin-bottom: 35px
    }
  }
  & .headerLogo{
    display: none;
    @media (max-width: ${MEDIA.lg}) {
      display: block;
      padding-top: 10px;
      padding-left: 10px;
      & a{padding:0 !important}
      & img{width:90px; height:auto; max-height:90px!important}
      & .poweredByWrap{left:10px;}
    }
  }

`)

export const LinkLabel = styled('span')(`
  width:100%;
  display:block;
  color: #000;
  line-height:15px;
  font-size: 14px;
  font-weight: 300;
  padding-left:10px;
`)

export const ListWrapper = styled(Box)((props) => `
  display: flex;
  position:relative;
  & img {
    max-height: 20px;
    max-width: 23px;
  }
`)


export const BoxShadow = styled(Box)(`
  display: none;
  @media (max-width: ${MEDIA.lg}) {
    display: block;
    position: fixed;
    left: 0;
    right: 0;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    z-index: -1;
  }
`);

export const NavLinkWrap = styled(NavLink)(props => `
  text-decoration: none;
  width:100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  padding:5px 15px;
  min-height:40px;


  &.activeName {
    // background: ${props.color || 'linear-gradient(to right, #F4F9FF 0%,#F4F9FF 80%,#f4f9ff00 100%)'} ;
    background: #EEE;
  }

  & img {
    max-height: 20px;
    max-width: 23px;
  }
`);

export const NavLinkHelpWrap = styled(Box)(props => `
  cursor:pointer;
  text-decoration: none;
  width:100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  padding:5px 15px;
  min-height:40px;

  &.activeName {
    background: ${props.color || 'linear-gradient(to right, #F4F9FF 0%,#F4F9FF 80%,#f4f9ff00 100%)'} ;
  }
`);

export const IconButtonStyled = styled(IconButton)(`
  background: #fff;
  position: fixed;
  right: 20px;
  top: 20px;
  width: 40px;
  height: 40px;
  display: none;
  @media (max-width: ${MEDIA.lg}) {
    display: block;
  }
`)

export const MenuIconButton = styled(IconButton)(`
  position: fixed;
  left: 172px;
  top: 60px;
  width: 30px;
  height: 30px;
  z-index: 4;
`)


