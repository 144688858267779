import React, { useEffect, useState } from "react";
import { MuiSelect } from "../../../../components";
import reportsService from "../../../../services/reports.service";

const AttributeName = ({ insightsElementId, onChange, entityName, driverName, value, isShadow, name = "attributeName", title = "Attribute", ...rest }) => {
  const [state, setstate] = useState([]);

  const getSandboxAttributeNamesElementHandler = async () => {
    try {
      const resp = await reportsService.getSandboxAttributeNamesElement({
        insightsElementId: insightsElementId,
        entityName,
        driverName
      });
      const data = resp.map((item) => ({
        label: item,
        value: item,
      }));
      const filteredData = data.filter(item => !!item.value)
      if (!filteredData?.length) return;
      setstate([
        { label: '', value: '' },
        ...filteredData
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSandboxAttributeNamesElementHandler();
  }, [entityName, driverName]);

  return (
    <MuiSelect
      name={name}
      onChange={(e) => onChange(e.target.name, e.target.value)}
      title={title}
      data={state}
      compact
      value={value || null}
      placeholder="Select"
      variant={isShadow ? "outlined" : "standard"}
      selectlabel="label"
      selectvalue="value"
      {...rest}
    />
  );
};

export default AttributeName;