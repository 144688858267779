import React, { useState, useEffect } from 'react'
import { Box } from '@mui/material'
import domtoimage from 'dom-to-image'
import { useGlobalStore } from '../../../store'
import { MuiButton, MuiImage, BlockingLoader, AudioTag } from '../../../components'
import { download, Download } from '../../../assets/images/icons';
import PptDrawer from '../../../pages/reports/components/pptDrawer/pptDrawer'
import { pptWidth, pptHeight, pxToInch, inchToPx, findHeight, findWidth, writePPT } from './download-ppt-write'
import { EXPORT_PPT } from '../../../constants'
import { HiddenPptLogo, LoaderOverlay } from './style'

const DownloadPpt = (props) => {
    const { subLhs, setSubLhs, pptSubLhs, setPPTSubLhs, projectIdValue, setSubLhsApiLoaded, pptSubLhsAppendices, setPPTSubLhsAppendices, pptSubLhsProgression, setPPTSubLhsProgression, setPPTModalActive, setPPTModalActiveAndClicked, isPPTModalActiveAndClicked, pptIdsType, setPptIdsType, subLhsApiLoaded, setAskIaActiveState, exportToPptObject } = useGlobalStore()

    const [loaderDetails_show, setLoaderDetails_show] = useState(false)
    const [loaderDetails_total, setLoaderDetails_total] = useState(0)
    const [loaderDetails_count, setLoaderDetails_count] = useState(0)
    
    const [oldPptSubLhsLength, setOldPptSubLhsLength] = useState(0)
    const [appendicesIdsLoaded, setAppendicesIdsLoaded] = useState(0)
    const [progressionIdsLoaded, setProgressionIdsLoaded] = useState(0)
    const [logoImgIdBase64, setLogoImgIdBase64] = useState()
    const [showOverlay, setShowOverlay] = useState(false)
    const [pptIds, setPptIds] = useState([])
    // const [pptIdsType, setPptIdsType] = useState('')
    const [pptIdsTypes, setPptIdsTypes] = useState([])
    // const [pptIdsTypeApiLoaded, setPptIdsTypeApiLoaded] = useState()
    const [pptIdsTemp, setPptIdsTemp] = useState()
    
    const onLoadFunc = (data, type) => {
        const pptIdsNew = ({ filteredData, isProgression=false }) => {
            const returnObject = (s, i, id) => {
                return {
                    id: id,
                    object: {},

                    idHeadingInfo: `reportHeadingRte__${id}`,
                    objectHeadingInfo: {},

                    idHeadingInsightsInfo: `reportHeadingRteInsights__${id}`,
                    objectHeadingInsightsInfo: {},

                    idHeadingTabsInfo: `reportTabs__${id}`,
                    objectHeadingTabsInfo: {},

                    idHeadingAppendicesInfo: `reportAppendices__${id}`,
                    objectHeadingAppendicesInfo: {},

                    idHeadingProgressionInfo: `reportProgression__${id}`,
                    objectHeadingProgressionInfo: {},
                    
                    isProgression: isProgression,
                    title: s.Title,
                    headingArray: [],
                    base64: '', base64Arr: [], base64InsightsArr: [],
                    height: 0, width: 0,
                    heightArr: [], widthArr: [],
                    hasHeaderClassForNewSlideArr: [],
                    // hasFooterClassToShowItOnEachSlideStr: [],

                    type: s.InsightsElementType,
                    exportToPptSlideId: s.InsightsElementType+'__'+s.InsightsElementId.replaceAll('-','_'),
                    InsightsElementType: s.InsightsElementType,
                    InsightsElementTypeAppendices: s?.InsightsElementTypeAppendices || '',
                    InsightsElementTypeProgression: s?.InsightsElementTypeProgression || '',

                    pptSubHeadingFlag: false,
                    headerPptFlag: false,
                    footerPptFlag: false,
                    pptSubHeadingArray: [],

                    loadingDetails: {
                        isLoading: false,
                        svgImgLoaded:[]
                    }
                }
            }

            return filteredData.map((s, i) => {
                const isAppendices = (!!s?.InsightsElementTypeAppendices) ? 'Appendices__' : ''
                const isProgression = (!!s?.InsightsElementTypeProgression) ? 'Progression__' : ''
                const id = `${isAppendices}${isProgression}${s.InsightsElementType}__${(s.InsightsElementId).replaceAll('-','')}__${i}`

                // if(!!s?.InsightsElementTypeAppendices) {
                //     if(isProgression === false && !EXPORT_PPT.deepDiveSegmentsProgression.includes(s.InsightsElementTypeAppendices)){
                //         const id = `${isAppendices}${s.InsightsElementType}__${(s.InsightsElementId).replaceAll('-','')}__${i}`
                //         return returnObject(s, i, id)
                //     }
                //     else if(isProgression === true && EXPORT_PPT.deepDiveSegmentsProgression.includes(s.InsightsElementTypeAppendices)){
                //         const id = `${isAppendices}${s.InsightsElementType}__${(s.InsightsElementId).replaceAll('-','')}__${i+finalPptIdsArray_withoutProgression.length}`
                //         return returnObject(s, i, id)
                //     }
                // } else {
                //     const id = `${isAppendices}${s.InsightsElementType}__${(s.InsightsElementId).replaceAll('-','')}__${i}`
                //     return returnObject(s, i, id)
                // }
                return returnObject(s, i, id)
            })
        }

        let finalPptIdsArray = []
        if(type === 'study'){
            finalPptIdsArray = pptIdsNew({filteredData:data, isProgression:false})
        } else if(type === 'appendices'){
            const finalPptIdsArray_withoutProgression = data.filter(s => !EXPORT_PPT.deepDiveSegmentsProgression.includes(s.InsightsElementTypeAppendices))
            finalPptIdsArray = pptIdsNew({filteredData:finalPptIdsArray_withoutProgression, isProgression:false})
        } else if(type === 'progression'){
            const finalPptIdsArray_withProgression = data.filter(s => EXPORT_PPT.deepDiveSegmentsProgression.includes(s.InsightsElementTypeProgression))
            finalPptIdsArray = pptIdsNew({filteredData:finalPptIdsArray_withProgression, isProgression:true})
        }

        setPptIds(finalPptIdsArray)
    }

    const loadingDetailsFunc = () => {
        const totalImgLength = (pptIds.map((d) => ( d.loadingDetails.svgImgLoaded ))).flat(1).filter(f => f===true).length
        setLoaderDetails_count(totalImgLength)
    }

    const getTypeValidated = (p, _id) => {
        return (
            [...EXPORT_PPT.reportSegment, ...EXPORT_PPT.deepDiveDrivers, ...EXPORT_PPT.deepDiveSegments, 
                'CompetitiveLandscape', 'FactorInterlinkage', 'SegmentDriversOnlyAnalysis', 'InsightsMiningFrameworkWithoutSentiments', 
                'TopRelativeBrandsAnalysis', 'BrandCategoryOverview', // 'BrandCategoryOverviewAndProgression'
                // 'BrandCategoryOverviewAndProgression', 'BrandMagnetScoreAndProgression', 'BrandAttributeMapAndProgression', 'SegmentOfSegmentOverviewAndProgression', 'BrandImageryMappingAndProgressionGraph', // NEW FOR PROGRESSION
                ...EXPORT_PPT.deepDiveProgression
            ].includes(p.InsightsElementType)
            || [...EXPORT_PPT.deepDiveSegmentsAppendices, ...EXPORT_PPT.deepDiveDriversAppendices, 'Segments____'].includes(p?.InsightsElementTypeAppendices)
            || [...EXPORT_PPT.deepDiveProgression, 'Segments____'].includes(p?.InsightsElementTypeProgression)
            // || (['BusinessObjective', 'Recommendation'].includes(p.type) && _id?.querySelectorAll('table').length > 0 ) // NEW CODE
        )
        // _id?.querySelector('.legendRoot')
    }

    const getQuerySelectorDetails = (_id, p) => {
        const queryObject = {
            queryIds: _id.querySelectorAll('.segmentWrap > div'),
            addClassToDiv: 'segmentWrap',
            hasHeaderClassForNewSlide: '',
            pptSubHeadingFlag: false,
            headerPptFlag: false
        }

        if(p.InsightsElementType === 'TopRelativeBrandsAnalysis'){
            queryObject.queryIds = _id.querySelectorAll('.list')
            queryObject.addClassToDiv = 'list'
        }
        else if(p.InsightsElementType === 'CampaignTriggers'){
            queryObject.queryIds = _id.querySelectorAll('.campaignTriggersRow')
            queryObject.addClassToDiv = 'campaignTriggersRow'
            queryObject.hasHeaderClassForNewSlide = 'campaignTriggersRowNewSlidePPT'
            queryObject.pptSubHeadingFlag = true
        }
        else if(p.InsightsElementType === 'DriversAndTriggers'){
            queryObject.queryIds = _id.querySelectorAll('.DriversTr')
            queryObject.addClassToDiv = 'driversAndTriggersRows'
        }
        else if(p.InsightsElementType === 'BrandCategoryOverview' || p.InsightsElementType === 'BrandCategoryOverviewAndProgression'){
            queryObject.queryIds = _id.querySelectorAll('.brandCategory')
            queryObject.addClassToDiv = 'brandCategoryRows'
        }
        else if(p.InsightsElementType === 'BrandMagnetScore' || p.InsightsElementType === 'BrandMagnetScoreAndProgression'){
            queryObject.queryIds = _id.querySelectorAll('.brandMagnetScoreRows')
            queryObject.addClassToDiv = 'brandMagnetScoreRows'
        }
        else if(p.InsightsElementType === 'BrandAttributeMapElement' || p.InsightsElementType === 'BrandAttributeMapAndProgression'){
            queryObject.queryIds = _id.querySelectorAll('.brandAttributeMapRow')
            queryObject.addClassToDiv = 'brandAttributeMapRow'
        }
        else if(p.InsightsElementType === 'SegmentOfSegmentOverview' || p.InsightsElementType === 'SegmentOfSegmentOverviewAndProgression'){
            queryObject.queryIds = _id.querySelectorAll('.segmentOfSegmentOverviewRow')
            queryObject.addClassToDiv = 'segmentOfSegmentOverviewRow'
        }
        else if(p.InsightsElementType === 'DriversAndSubBrands'){
            queryObject.queryIds = _id.querySelectorAll('.DriversTr')
            queryObject.addClassToDiv = 'driversAndSubBrandsRows'
        }
        else if(p.InsightsElementType === 'DriversWithoutSentimentsAndSubBrands'){
            queryObject.queryIds = _id.querySelectorAll('.DriversTr')
            queryObject.addClassToDiv = 'driversWithoutSentimentsAndSubBrandsRows'
        }
        else if(p.InsightsElementType === 'BrandHealthSetup'){
            queryObject.queryIds = _id.querySelectorAll('.dynamicRow')
            queryObject.addClassToDiv = 'dynamicRow'
        }
        else if(p.InsightsElementType === 'BrandImageryMappingGraph'){
            queryObject.queryIds = _id.querySelectorAll('.brandImageryRow.tRow')
            queryObject.addClassToDiv = 'brandImageryRow'
        }
        else if(p.InsightsElementType === 'BrandImageryMappingAndProgressionGraph'){
            queryObject.queryIds = _id.querySelectorAll('.brandImageryRow.tRow')
            queryObject.addClassToDiv = 'brandImageryRow'
        }
        else if(p.InsightsElementType === 'CompetitiveLandscape'){
            queryObject.queryIds = _id.querySelectorAll('.pptListWrapper')
            queryObject.addClassToDiv = 'pptListWrapper'
            queryObject.hasHeaderClassForNewSlide = 'pptListHeader'
            queryObject.headerPptFlag = true
            // queryObject.pptSubHeadingFlag = true
        }
        else if(p.InsightsElementType === 'FactorInterlinkage'){
            queryObject.queryIds = _id.querySelectorAll('.factorGraphList')
            queryObject.addClassToDiv = 'factorGraphList'
            queryObject.pptSubHeadingFlag = true
        }
        else if(p.InsightsElementType === 'BusinessObjective'){
            queryObject.queryIds = _id.querySelectorAll('.businessRow')
            queryObject.addClassToDiv = 'businessRow'
        }
        else if(p.InsightsElementType === 'Recommendation'){
            queryObject.queryIds = _id.querySelectorAll('.recommendationRow')
            queryObject.addClassToDiv = 'recommendationRow'
            queryObject.hasHeaderClassForNewSlide = 'li_H4'
        }
        if(p.InsightsElementTypeAppendices === 'AppendicesBrandImageryMappingGraph'){
            queryObject.queryIds = _id.querySelectorAll('.brandImageryRowAppendices')
            queryObject.addClassToDiv = 'brandImageryRowAppendices'
            queryObject.hasHeaderClassForNewSlide = 'brandImageryLevel2'
            queryObject.pptSubHeadingFlag = true
            // hasFooterClassToShowItOnEachSlide = 'footer'
        }
        else if(p.InsightsElementTypeAppendices === 'AppendicesBrandImageryMappingAndProgressionGraph'){
            queryObject.queryIds = _id.querySelectorAll('.brandImageryRowAppendices')
            queryObject.addClassToDiv = 'brandImageryRowAppendices'
            queryObject.hasHeaderClassForNewSlide = 'brandImageryLevel2'
            queryObject.pptSubHeadingFlag = true
            // hasFooterClassToShowItOnEachSlide = 'footer'
        }
        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        else if(p.InsightsElementTypeProgression === 'ProgressionBrandCategoryOverviewAndProgression'){
            queryObject.queryIds = _id.querySelectorAll('.brandHealthExportRowProgression')
            queryObject.addClassToDiv = 'brandHealthExportRowProgression'
            // queryObject.hasHeaderClassForNewSlide = 'brandImageryLevel2'
            // queryObject.pptSubHeadingFlag = true
            // hasFooterClassToShowItOnEachSlide = 'footer'
        }
        else if(p.InsightsElementTypeProgression === 'ProgressionBrandMagnetScoreAndProgression'){
            queryObject.queryIds = _id.querySelectorAll('.brandMagnetScoreRowProgression')
            queryObject.addClassToDiv = 'brandMagnetScoreRowProgression'
            // queryObject.hasHeaderClassForNewSlide = 'brandImageryLevel2'
            // queryObject.pptSubHeadingFlag = true
            // hasFooterClassToShowItOnEachSlide = 'footer'
        }
        else if(p.InsightsElementTypeProgression === 'ProgressionBrandAttributeMapAndProgression'){
            queryObject.queryIds = _id.querySelectorAll('.brandAttributeMapRowProgression')
            queryObject.addClassToDiv = 'brandAttributeMapRowProgression'
            queryObject.hasHeaderClassForNewSlide = 'brandAttributeMapLevel2'
            queryObject.pptSubHeadingFlag = true
            // hasFooterClassToShowItOnEachSlide = 'footer'
        }
        else if(p.InsightsElementTypeProgression === 'ProgressionSegmentOfSegmentOverviewAndProgression'){
            queryObject.queryIds = _id.querySelectorAll('.segmentOfSegmentOverviewRowProgression')
            queryObject.addClassToDiv = 'segmentOfSegmentOverviewRowProgression'
            // queryObject.hasHeaderClassForNewSlide = 'brandImageryLevel2'
            // queryObject.pptSubHeadingFlag = true
            // hasFooterClassToShowItOnEachSlide = 'footer'
        }
        else if(p.InsightsElementTypeProgression === 'ProgressionBrandImageryMappingAndProgressionGraph'){
            queryObject.queryIds = _id.querySelectorAll('.brandImageryRowProgression')
            queryObject.addClassToDiv = 'brandImageryRowProgression'
            queryObject.hasHeaderClassForNewSlide = 'brandImageryLevel2'
            queryObject.pptSubHeadingFlag = true
            // hasFooterClassToShowItOnEachSlide = 'footer'
        }

        if(EXPORT_PPT.deepDiveDrivers.includes(p.InsightsElementType)){
            queryObject.queryIds = _id.querySelectorAll('.DriversTr')
            queryObject.addClassToDiv = 'DriversTr'
            queryObject.hasHeaderClassForNewSlide = 'rowLevel1'
            queryObject.pptSubHeadingFlag = true
            queryObject.headerPptFlag = true
            queryObject.headerPptDataIndex = {class:'headerPPT', indexInBase64Array: 0}
        }
        return queryObject;
    }

    const getImageDetailLogs = () => {
        // ADDITIONAL CHECKING OF NULL VALUE
        let isNotNull = true

        for(let d=0; d<pptIds.length; d++) {
            let p = pptIds[d]
            let _id = document.getElementById(p.id)
            if(_id === null){
                isNotNull = false
                break
            }
        }

        if(isNotNull){
            let totalValue = 0
            let countValue = 0
            for(let d=0; d<pptIds.length; d++) {
                let p = pptIds[d]
                let _id = document.getElementById(p.id)
                if(getTypeValidated(p)){
                    const queryObject = getQuerySelectorDetails(_id, p)
                    if(queryObject?.queryIds?.length > 0){
                        for(let i=0; i<queryObject.queryIds.length; i++){
                            p.loadingDetails.svgImgLoaded.push(false)
                            totalValue = totalValue+1
                        }
                    }
                } else if (['BusinessObjective', 'Recommendation'].includes(p.type) ) {
                    // p.loadingDetails.svgImgLoaded.push(false)
                    // totalValue = totalValue+1
                    // totalValue = totalValue+1
                    // countValue = countValue+1
                    // totalValue = totalValue+1
                } else {
                    p.loadingDetails.svgImgLoaded.push(false)
                    totalValue = totalValue+1
                }
            }
            
            // if(totalValue-4 > 0){ totalValue = totalValue-4 }
            setLoaderDetails_total(totalValue)
            setLoaderDetails_count(countValue)
            setLoaderDetails_show(true)
        }

        return isNotNull

    }

    const getSelectedData_subLhsApiLoaded = (param='', type='') => {
        if(param === 'getLen'){
            let data = []
            if(type==='study') { data=pptSubLhs }
            else if(type==='appendices') { data=pptSubLhsAppendices }
            else if(type==='progression') { data=pptSubLhsProgression }

            const apiIds = Object.keys(subLhsApiLoaded)
            const value = data.map(d => ('id-'+d.InsightsElementId)).filter(f => apiIds.includes(f)).length
            return value
        }
    }

    const onSuccess = (param = 'none') => {
        // return false
        // setLoaderDetails_show(false)

        if(param === 'studyDone') {
            const subLhsApiLoadedKeys = Object.keys(subLhsApiLoaded)
            const appendicesIds = pptSubLhsAppendices.map(d => ('id-'+d.InsightsElementId))
            const appendicesIdsCount = []
            let pptIdsTypeTemp = ''

            for(let i=0; i<appendicesIds.length; i++){
                if(subLhsApiLoadedKeys.includes(appendicesIds[i])){
                    appendicesIdsCount.push(appendicesIds[i])
                    pptIdsTypeTemp = 'appendices'
                }
            }

            const progressionIds = pptSubLhsProgression.map(d => ('id-'+d.InsightsElementId))
            const progressionIdsCount = []

            for(let i=0; i<progressionIds.length; i++){
                if(subLhsApiLoadedKeys.includes(progressionIds[i])){
                    progressionIdsCount.push(progressionIds[i])
                    pptIdsTypeTemp = 'progression'
                }
            }

            setOldPptSubLhsLength(pptSubLhs.length)
            setAppendicesIdsLoaded(appendicesIdsCount?.length || 0)
            setProgressionIdsLoaded(progressionIdsCount?.length || 0)
            setPPTSubLhs([])
            setTimeout(() => {
                setOldPptSubLhsLength(pptSubLhs.length)
                setAppendicesIdsLoaded(appendicesIdsCount?.length || 0)
                setProgressionIdsLoaded(progressionIdsCount?.length || 0)
                setPptIdsType(pptIdsTypeTemp)
                setLoaderDetails_show(false)
                setPPTModalActiveAndClicked(false)
            },2500)
        } else {
            const subLhsData = subLhs
            setSubLhs([])
            setTimeout(() => {
                setLoaderDetails_total(0)
                setLoaderDetails_count(0)
                setPptIdsType('')
                setPPTSubLhs([])
                setPPTSubLhsAppendices([])
                setPPTSubLhsProgression([])
                
                setPPTModalActive(false)
                
                setLoaderDetails_show(false)
                setPPTModalActiveAndClicked(false)
                setShowOverlay(false)
                setSubLhs(subLhsData)
            },2500)
        }
    }

    const downloadPPT = async (clientLogo) => {
        // e.preventDefault()

        let imageWidth = Math.round(inchToPx(pptWidth) - 80)
        let count = 0
        let mergedSlidesCount = 1

        const addImageProcess = (strBase64Img, type) => {
            // const data={height:0, width:0}
            return new Promise((resolve, reject) => {
            let img = new Image()
            img.src = strBase64Img
            img.onload = () => {
                // setTimeout(() => {
                    const data={}
                    // const d = {w:id.clientWidth, h:id.clientHeight}
                    const d = {w:img.naturalWidth, h:img.naturalHeight}
                    const maxHt = inchToPx(pptHeight) - 195
                    data.height = (d.h > maxHt) ? maxHt : d.h
                    data.width = (d.h > maxHt) ? findWidth(d.w, d.h, maxHt) : d.w

                    if(['Drivers', 'DriversWithoutSentiments', 'DriversAndRatingImpacts', 'ProductRatingOptimizationSetup', 'CategoryExplorationSetup', 'DriversAndTriggers', 'SearchDrivers', 'DriversAndSubBrands', 'DriversWithoutSentimentsAndSubBrands', 'TopRelativeBrandsAnalysis', 'BrandHealthSetup', 'BrandImageryMappingTable', 'BrandImageryMappingGraph', 'BrandImageryMappingAndProgressionGraph', 'DriversMotivatorsDetractors','DriversAndSubBrandsMotivatorsDetractors','DriversWithoutSentimentsAndSubBrands','DriversAndTriggersMotivatorsDetractors','DriversAndRatingImpactsMotivatorsDetractors'].includes(type)) {
                        const maxW = inchToPx(pptWidth) - 70
                        data.height = findHeight(d.w, d.h, maxW)
                        data.width = maxW
                    }
                    resolve({ w:data.width, h:data.height })
                // }, 100)
            }
            img.onerror = reject
            })
        }

        const pptIdsFunc = async (index) => {

            if(index > (pptIds.length - mergedSlidesCount)){
                // if( pptIdsTypes.length === 1 || (pptIdsTypes.length === 2 && pptIdsType==='progression') ){
                const logoDetails = {
                    logoImg: props.logoDetails.logoImg,
                    logoImgBase64: clientLogo,
                    logoH: 25,
                    logoW: document.getElementById('downloadLogo').width
                }

                if( pptIdsTypes.length === 1 ){
                    writePPT(pptIds, props, logoDetails, mergedSlidesCount, pptIdsType, pptIdsTypes, exportToPptObject, onSuccess)
                    return false
                } else if(pptIdsTypes.length === 2 && pptIdsType==='appendices'){
                    setPptIdsTemp([pptIds, props, logoDetails, mergedSlidesCount, pptIdsType, pptIdsTypes, exportToPptObject])
                    setPptIdsType('progression')
                    setPPTSubLhsAppendices([])
                    
                    checkDownloadPptApi()
                    return false
                } else if(pptIdsTypes.length === 2 && pptIdsType==='progression'){
                    writePPT([...pptIdsTemp[0], ...pptIds], props, logoDetails, mergedSlidesCount, pptIdsType, pptIdsTypes, exportToPptObject, onSuccess)
                    return false
                }
            }
            
            let p = pptIds[index]
            
            if(pptIdsType === 'study'){
                const status = pptSubLhs.map((p, i) => { 
                    p.status = (i<=index);
                    return p
                })
                setPPTSubLhs(status)
            } else if(pptIdsType === 'appendices'){
                const status = pptSubLhsAppendices.map((p, i) => { 
                    p.status = (i<=index);
                    return p
                })
                setPPTSubLhsAppendices(status)
            } else if(pptIdsType === 'progression'){
                const status = pptSubLhsProgression.map((p, i) => { 
                    p.status = (i<=index);
                    return p
                })
                setPPTSubLhsProgression(status)
            }

            p.loadingDetails.isLoading = true
            loadingDetailsFunc()

            let _id = document.getElementById(p.id)
            document.querySelector(`#${p.id}`).classList.add('exportToPPT');

            const AppendicesList = [...EXPORT_PPT.deepDiveDriversAppendices, ...EXPORT_PPT.deepDiveSegmentsAppendices]
            if(AppendicesList.includes(p.InsightsElementTypeAppendices)) {
                document.querySelector(`#${p.id}`).classList.add('exportToPPTAppendices');
            }

            const ProgressionList = [...EXPORT_PPT.deepDiveSegmentsProgression]
            if(ProgressionList.includes(p.InsightsElementTypeProgression)) {
                document.querySelector(`#${p.id}`).classList.add('exportToPPTProgression');
            }

            imageWidth = Math.round(inchToPx(pptWidth) - 80)
            
            if(['DriversAndTriggers', 'SearchDrivers', 'DriversAndSubBrands', 'DriversWithoutSentimentsAndSubBrands', 'Drivers', 'DriversWithoutSentiments', 'DriversAndRatingImpacts', 'ProductRatingOptimizationSetup', 'CategoryExplorationSetup', 'TopRelativeBrandsAnalysis', 'BrandHealthSetup'].includes(p.InsightsElementType)) {
                imageWidth = 1400
            }
            else if(['CompetitiveLandscape'].includes(p.InsightsElementType)) { imageWidth = 1200 }
            // else if(['BrandImageryMappingGraph'].includes(p.InsightsElementType)) { imageWidth = 2400 - 80 }
            else if(['BrandImageryMappingGraph', 'BrandImageryMappingAndProgressionGraph'].includes(p.InsightsElementType)) { imageWidth = 1200 }
            else if(['BrandImageryMappingTable'].includes(p.InsightsElementType)) { imageWidth = 1400 }
            else if(['Aura'].includes(p.InsightsElementType)) { imageWidth = 1200 }
            else if([...EXPORT_PPT.reportSegment, 'SegmentDriversOnlyAnalysis' ].includes(p.InsightsElementType)) { imageWidth = 1200 }
            else if(['InsightsMiningFrameworkWithoutSentiments'].includes(p.InsightsElementType)) { imageWidth = 1200 }
            else if(['BrandAttributeMapElement'].includes(p.InsightsElementType)) { imageWidth = 1700 }
            else if(['ImpactDestinations'].includes(p.InsightsElementType)) { imageWidth = 1550 }
            else if(['BrandAttributeMapAndProgression'].includes(p.InsightsElementType)) { imageWidth = 1200 }
            else if(['CampaignTriggers'].includes(p.InsightsElementType)) { imageWidth = 1200 }
            else if(['FactorInterlinkage'].includes(p.InsightsElementType)) { imageWidth = 1400 }

            const removeTags = (str) => {
                let data = str

                const replaceArray = [ '<STRONG>', '</STRONG>', '<strong>', '</strong>', '<STRIKE>', '</STRIKE>', '<strike>', '</strike>', '<S>', '</S>', '<s>', '</s>', '<EM>', '</EM>', '<em>', '</em>', '<SUB>', '</SUB>', '<sub>', '</sub>', '<SUP>', '</SUP>', '<sup>', '</sup>', '<UNDERLINE>', '</UNDERLINE>', '<underline>', '</underline>' ]
                // const replaceArray = [ '<STRIKE>', '</STRIKE>', '<strike>', '</strike>', '<S>', '</S>', '<s>', '</s>', '<EM>', '</EM>', '<em>', '</em>', '<SUB>', '</SUB>', '<sub>', '</sub>', '<SUP>', '</SUP>', '<sup>', '</sup>', '<UNDERLINE>', '</UNDERLINE>', '<underline>', '</underline>' ]
                if(typeof data === 'string') {
                    // data = str.replaceAll('<ol','<ul').replaceAll('</ol>','</ul>')
                    data = str.split('<ul>')[0]
                    data = data.replaceAll('<sup>TM</sup>', ' ').replaceAll('<SUP>TM</SUP>', ' ')
                    for(let r=0; r<replaceArray.length; r++){
                        data = data.replaceAll(replaceArray[r],'')
                    }
                    data = data.replaceAll('<BR />', ' ').replaceAll('<br />', ' ').replaceAll('<BR>', ' ').replaceAll('<br>', ' ')
                    data = data.replaceAll('&nbsp;',' ').replaceAll('&amp;','&').replaceAll('&lt;','<').replaceAll('&gt;','>').replaceAll('&quot;','\/').replaceAll('&apos;',"'")
                    data = data.replace(/<\/?span[^>]*>/g,"").replace(/<\/?div[^>]*>/g,"").replace(/<\/?p[^>]*>/g,"")
                }
                return data
            }

            const nodeJsonFunc = (nodes, parentNode) => {
                let obj = {}
                try {
                    if(parentNode === 'UL' || parentNode === 'ULUL' || parentNode === 'ULULUL' || parentNode === 'ULULULUL' || parentNode === 'ULULULULUL' || parentNode === 'ULULULULULUL') {obj = []}
                    for (let i = 0; i < nodes.length; i++) {
                        const loopNode = nodes[i]
                        let tag = loopNode.nodeName;
                        if(tag === 'DIV'){
                            const txt = (loopNode?.childNodes[0]?.nodeValue)
                            if(txt?.trim().length !== 0){
                                count++
                                obj[tag+count] = {
                                    tag: tag,
                                    text: txt,
                                    content: (loopNode?.childNodes?.length > 0) ? [nodeJsonFunc(loopNode.childNodes, tag)] : []
                                }
                            }
                        }
                        if(tag === 'UL'){
                            count++
                            const tagNew = parentNode==='LILILILILI' ? 'ULULULULULUL' : parentNode==='LILILILI' ? 'ULULULULUL' : parentNode==='LILILI' ? 'ULULULUL' : parentNode==='LILI' ? 'ULULUL' : parentNode==='LI' ? 'ULUL' : tag
                            obj[tag+count] = {
                                tag: tagNew,
                                content: (loopNode?.childNodes?.length > 0) ? nodeJsonFunc(loopNode.childNodes, tagNew) : []
                            }
                        }
                        if(tag === 'LI'){
                            const txt = removeTags(loopNode.innerHTML)
                            if(txt?.trim().length !== 0){
                                const tagNew = parentNode==='ULULULULULUL' ? 'LILILILILILI' : parentNode==='ULULULULUL' ? 'LILILILILI' : parentNode==='ULULULUL' ? 'LILILILI' : parentNode==='ULULUL' ? 'LILILI' : parentNode==='ULUL' ? 'LILI' : tag
                                const txt2 = txt;
                                obj.push({
                                    tag: tagNew,
                                    text: txt,
                                    content: (loopNode?.childNodes?.length > 1) ? nodeJsonFunc(loopNode.childNodes, tagNew) : ''
                                })
                            }
                        }
                        if(tag === 'P'){
                            const txt = removeTags(loopNode.innerHTML)+'\n'
                            if(txt?.trim().length !== 0){
                                count++
                                obj[tag+count] = {
                                    tag: tag,
                                    text: txt,
                                    content: ''
                                }
                            }
                        }
                        if(tag === 'H4'){
                            const txt = removeTags(loopNode.innerHTML)+'\n'
                            if(txt?.trim().length !== 0){
                                count++
                                obj[tag+count] = {
                                    tag: tag,
                                    text: txt,
                                    content: ''
                                }
                            }
                        }
                        if(tag === 'HR'){
                            count++
                            obj[tag+count] = {
                                tag: tag,
                                text: '',
                                content: ''
                            }
                        }

                    }
                } catch (error) {
                    console.log('TCF 2 > Error --------- ', error)
                }
                return obj
            }

            const generateSingleImageFunc = async(paramId) => {
                const id = paramId
                const id_heading = document.getElementById(p.idHeadingInfo)
                const id_headingInsight = document.getElementById(p.idHeadingInsightsInfo)
                const id_headingTabs = document.getElementById(p.idHeadingTabsInfo)
                const id_headingAppendices = document.getElementById(p.idHeadingAppendicesInfo)
                const id_headingProgression = document.getElementById(p.idHeadingProgressionInfo)
                id.style.width = (imageWidth)+'px'
                id.style.minWidth = (imageWidth)+'px'
                id.style.background = '#fff'
                let jsonObject_body = {}

                count = 0
                const jsonObject_heading = id_heading?.parentNode?.childNodes ? nodeJsonFunc(id_heading.parentNode.childNodes, 'jsonObject_heading') : {}
                const jsonObject_headingInsights = id_headingInsight?.childNodes ? nodeJsonFunc(id_headingInsight.parentNode.childNodes, 'jsonObject_headingInsights') : {}
                const jsonObject_headingTabs = id_headingTabs?.childNodes ? nodeJsonFunc(id_headingTabs.parentNode.childNodes, 'jsonObject_headingTabs') : {}
                const jsonObject_headingAppendices = id_headingAppendices?.childNodes ? nodeJsonFunc(id_headingAppendices.parentNode.childNodes, 'jsonObject_headingAppendices') : {}
                const jsonObject_headingProgression = id_headingProgression?.childNodes ? nodeJsonFunc(id_headingProgression.parentNode.childNodes, 'jsonObject_headingProgression') : {}

                if(['BusinessObjective', 'Recommendation'].includes(p.type) ) {
                    count = 0
                    jsonObject_body = nodeJsonFunc(id.childNodes, 'jsonObject_body')

                    id.style.width = '100%'
                    id.style.minWidth = '100%'
                    p.object = jsonObject_body
                    p.objectHeadingInfo = jsonObject_heading
                    p.objectHeadingInsightsInfo = jsonObject_headingInsights
                    p.objectHeadingTabsInfo = jsonObject_headingTabs
                    p.objectHeadingAppendicesInfo = jsonObject_headingAppendices
                    p.objectHeadingProgressionInfo = jsonObject_headingProgression
                    p.base64 = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAIAQMAAAD+wSzIAAAABlBMVEX///+/v7+jQ3Y5AAAADklEQVQI12P4AIX8EAgALgAD/aNpbtEAAAAASUVORK5CYII'
                    p.width = 200
                    p.height = 200
                    pptIds[index] = p
                    // document.querySelector(`#${p.id}`).classList.remove('exportToPPT')
                    // document.querySelector(`#${p.id}`).classList.add('removedExportToPPT')

                    // p.loadingDetails.svgImgLoaded[x] = true

                    pptIdsFunc(index+1)
                    updateLoadingStatus('show', 'insightsId', p.id, `done (1)`, 1)
                    // loadingDetailsFunc('htmlFormat')
                }
                else {
                    await domtoimage.toJpeg(id, { quality: 0.95 })
                        .then( async (dataUrl) => {
                            id.style.width = '100%'
                            id.style.minWidth = '100%'
                            id.style.maxWidth = '100%'
                            p.object = jsonObject_body

                            p.objectHeadingInfo = jsonObject_heading
                            p.objectHeadingInsightsInfo = jsonObject_headingInsights
                            p.objectHeadingTabsInfo = jsonObject_headingTabs
                            p.objectHeadingAppendicesInfo = jsonObject_headingAppendices
                            p.objectHeadingProgressionInfo = jsonObject_headingProgression
                            p.base64 = dataUrl
                            
                            await addImageProcess(dataUrl, p.type).then((data) => {
                                // console.log('single image val = ', data.w, dataUrl)
                                p.width = data.w
                                p.height = data.h
                                p.loadingDetails.svgImgLoaded[0] = true // DANGER JUST ADDED
                                pptIds[index] = p
                                loadingDetailsFunc()
                                // console.log('PASSED 2 :', p)
                                updateLoadingStatus('show', 'insightsId', p.id, `done (${p.base64.length>10 ? 1 : 0})`, p.base64.length>10 ? 1 : 0)
                                pptIdsFunc(index+1)
                                
                            })
                            .catch(() => {  })

                        })
                        .catch((e) => {
                            id.style.width = '100%'
                            id.style.minWidth = '100%'
                            id.style.maxWidth = '100%'
                            p.loadingDetails.svgImgLoaded[0] = true // DANGER JUST ADDED
                            p.object = jsonObject_body

                            p.objectHeadingInfo = jsonObject_heading
                            p.objectHeadingInsightsInfo = jsonObject_headingInsights
                            p.objectHeadingTabsInfo = jsonObject_headingTabs
                            p.objectHeadingAppendicesInfo = jsonObject_headingAppendices
                            p.objectHeadingProgressionInfo = jsonObject_headingProgression

                            const dummyUrl = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                            p.base64 = dummyUrl
                            p.width = 1
                            p.height = 1
                            pptIds[index] = p
                            loadingDetailsFunc()
                            console.log('FAILED 2 :', e, p)
                            updateLoadingStatus('show', 'insightsId', p.id, `FAILED (${p.base64Arr.length})`, 0)
                            pptIdsFunc(index+1)
                        })
                }
            }

            const generateMultipleImageFunc = async(paramId, hasHeaderClassForNewSlideArray=[]) => {
                const id_heading = document.getElementById(p.idHeadingInfo)
                const id_headingInsight = document.getElementById(p.idHeadingInsightsInfo)
                const id_headingTab = document.getElementById(p.idHeadingTabsInfo)
                const id_headingAppendices = document.getElementById(p.idHeadingAppendicesInfo)
                const id_headingProgression = document.getElementById(p.idHeadingProgressionInfo)
                
                count = 0
                const jsonObject_heading = nodeJsonFunc(id_heading.parentNode.childNodes, 'jsonObject_heading')
                const jsonObject_headingInsights = id_headingInsight?.childNodes ? nodeJsonFunc(id_headingInsight.parentNode.childNodes, 'jsonObject_headingInsights') : {}
                const jsonObject_headingTabs = id_headingTab?.childNodes ? nodeJsonFunc(id_headingTab.parentNode.childNodes, 'jsonObject_headingTabs') : {}
                const jsonObject_headingAppendices = id_headingAppendices?.childNodes ? nodeJsonFunc(id_headingAppendices.parentNode.childNodes, 'jsonObject_headingAppendices') : {}
                const jsonObject_headingProgression = id_headingProgression?.childNodes ? nodeJsonFunc(id_headingProgression.parentNode.childNodes, 'jsonObject_headingProgression') : {}

                p.headingArray = []
                // p.html = ''
                p.base64InsightsArr = []
                p.base64Arr = []
                p.widthArr = []
                p.heightArr = []
                p.hasHeaderClassForNewSlideArr = hasHeaderClassForNewSlideArray
                // p.hasFooterClassToShowItOnEachSlideStr = ''
                p.object = {}
                p.objectHeadingInfo = jsonObject_heading
                p.objectHeadingInsightsInfo = jsonObject_headingInsights
                p.objectHeadingTabsInfo = jsonObject_headingTabs
                p.objectHeadingAppendicesInfo = jsonObject_headingAppendices
                p.objectHeadingProgressionInfo = jsonObject_headingProgression

                const loopingImgFunc = async (x) => {
                    let id = paramId[x]
                    if(!!id){
                        id.style.background = '#fff'
                        id.style.width = (imageWidth)+'px'
                        id.style.minWidth = (imageWidth)+'px'

                        if( id.classList.contains('legendRoot') ){
                            p.legendFlag = true
                        }

                        if( id.classList.contains('footerPPT') ){
                            p.footerPptFlag = true
                        }

                        await domtoimage.toJpeg(id, { quality: 0.95 })
                            .then( async (dataUrl) => {
                                await addImageProcess(dataUrl, p.type).then( async (data) => {
                                    p.base64Arr.push(dataUrl)
                                    p.widthArr.push(data.w)
                                    p.heightArr.push(data.h)
                                    p.loadingDetails.svgImgLoaded[x] = true
                                    loadingDetailsFunc()
                                    
                                    if(x<paramId.length-1){
                                        await loopingImgFunc(x+1)
                                    }
                                    else{
                                        // console.log('PASSED 1 :', p)
                                        updateLoadingStatus('show', 'insightsId', p.id, `done (${p.base64Arr.length})`, p.base64Arr.length)
                                        // if( p.InsightsElementType !== 'CategoryPersona'){
                                            pptIds[index] = p
                                            pptIdsFunc(index+1)
                                        // }
                                    }
                                })
                                .catch(async (err) => { 
                                    console.log('FAILED 1 :', err, p)
                                    p.base64Arr.push('data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEASABIAAD/2wBDAAoHBwgHBgoICAgLCgoLDhgQDg0NDh0VFhEYIx8lJCIfIiEmKzcvJik0KSEiMEExNDk7Pj4+JS5ESUM8SDc9Pjv/2wBDAQoLCw4NDhwQEBw7KCIoOzs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozv/wAARCAAKAAoDASIAAhEBAxEB/8QAFQABAQAAAAAAAAAAAAAAAAAAAAf/xAAUEAEAAAAAAAAAAAAAAAAAAAAA/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/EABQRAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhEDEQA/ALMAD//Z')

                                    p.widthArr.push(100)
                                    p.heightArr.push(1)
                                    p.loadingDetails.svgImgLoaded[x] = true
                                    loadingDetailsFunc()

                                    if(x<paramId.length-1){
                                        await loopingImgFunc(x+1)
                                    }
                                    else{
                                        updateLoadingStatus('show', 'insightsId', p.id, `FAILED (${p.base64Arr.length})`, 0)
                                        // if( p.InsightsElementType !== 'CategoryPersona'){
                                            pptIds[index] = p
                                            pptIdsFunc(index+1)
                                        // }
                                    }

                                })

                            })
                            .catch(() => { console.log('FAILED 2 :', p) })
                    } else {
                        pptIds[index] = p
                        pptIdsFunc(index+1)
                    }
                }

                await loopingImgFunc(0)
            }

            // const p_idHeadingInsightsInfo1 = p.idHeadingInsightsInfo.split('__')
            // p_idHeadingInsightsInfo1.pop()
            // const p_idHeadingInsightsInfo2 = document.getElementById(p_idHeadingInsightsInfo1.join('__'))
            const insightsId = document.getElementById(p.idHeadingInsightsInfo)
            if(['Drivers____'].includes(p?.InsightsElementTypeAppendices)) {
                const legendRoot = _id?.querySelector('.legendRoot')
                const footerPPT = _id?.querySelector('.footerPPT')
                let queryIds = [_id.querySelectorAll('.DriverDownloadPPT > thead > tr.DriversTr')[0], ..._id.querySelectorAll('.DriverDownloadPPT > tbody > tr.DriversTr')]
                // if( p?.InsightsElementTypeAppendices === 'AppendicesBrandImageryMappingGraph') {
                //     queryIds = _id.querySelectorAll('.brandImageryOuterWrapper .brandImageryRow')
                // }

                const array=[]
                const tempIds = []
            
                const outerDiv = document.createElement('div')
                outerDiv.id = `${p.id}__tempWrapper`
                outerDiv.className = 'dynamicDriverWrapper ' + p?.InsightsElementTypeAppendices
                outerDiv.setAttribute("style", "width:100%;")
                document.getElementById(p.id).appendChild(outerDiv)

                for(let i=0; i<Math.ceil(queryIds.length); i++){
                    array.push([])
                    const innerDiv = document.createElement('div')
                    innerDiv.id = `${p.id}__tempWrapper__${i}`
                    innerDiv.className = 'DriversTr'
                    // innerDiv.setAttribute("style", "width:100%;")
                    document.getElementById(`${p.id}__tempWrapper`).appendChild(innerDiv)
                }

                for(let j=0; j<array.length; j++){
                    for(let i=0; i<queryIds.length; i++){
                        if(i>=j && i<(j+1)){
                            const row = queryIds[i].classList.contains('tRow')
                            const details = queryIds[i+1]?.classList?.contains('details')
                            if(row && details){
                                const combineDiv = document.createElement('div')
                                combineDiv.className = 'DriversTrCombined'
                                combineDiv.appendChild(queryIds[i]) // row
                                combineDiv.appendChild(queryIds[i+1]) // details
                                array[j].push(combineDiv)
                                document.getElementById(`${p.id}__tempWrapper__${j}`).appendChild(combineDiv)
                            }
                            else if (row){
                                array[j].push(queryIds[i])
                                document.getElementById(`${p.id}__tempWrapper__${j}`).appendChild(queryIds[i])
                            }
                        }
                    }
                }

                for(let j=0; j<array.length; j++){
                    if(document.getElementById(`${p.id}__tempWrapper__${j}`)?.innerHTML?.length > 10){
                        p.loadingDetails.svgImgLoaded.push(false)
                        tempIds.push(document.getElementById(`${p.id}__tempWrapper__${j}`))
                    }
                }
                
                if(legendRoot){
                    tempIds.push(legendRoot)
                }
                if(footerPPT){
                    tempIds.push(footerPPT)
                }

                await generateMultipleImageFunc(tempIds)
            }
            else if(getTypeValidated(p, _id) || insightsId?.querySelectorAll('table')?.length > 0) {
                if(['BusinessObjective'].includes(p.type) && _id?.querySelector('table') ){
                    const li = _id.querySelectorAll('li')
                    for(let t=0; t<li.length; t++){
                        if ( li[t].querySelectorAll('li').length > 0 ) {
                            li[t].classList.add("businessRow")
                            li[t].classList.add("li")
                        } else {
                            li[t].classList.add("businessRow")
                            li[t].classList.add("liLi")
                        }
                        li[t].style.paddingBottom = '2px'
                    }
                }
                else if(['Recommendation'].includes(p.type) && _id?.querySelector('table') ){
                    const li = _id.querySelectorAll('li')
                    for(let t=0; t<li.length; t++){
                        if ( li[t].querySelectorAll('li h4').length > 0 ) {
                            li[t].classList.add("recommendationRow")
                            li[t].classList.add("li_H4")
                        } else {
                            li[t].classList.add("recommendationRow")
                            li[t].classList.add("li")
                        }
                        li[t].style.paddingBottom = '2px'
                    }
                }

                if(['BusinessObjective', 'Recommendation'].includes(p.type) && _id?.querySelector('table') ){
                    try {
                        const insertAfter = (referenceNode, newNode) => {
                            const newEl = newNode || document.createElement("td");
                            referenceNode.parentNode.insertBefore(newEl, referenceNode.nextSibling);
                        }

                        const classN = ['BusinessObjective'].includes(p.type) ? 'businessRow' : 'recommendationRow'
                        const theadTr = _id.querySelectorAll('thead tr')
                        for(let t=0; t<theadTr.length; t++){
                            theadTr[t].classList.add(classN);
                            theadTr[t].classList.add("theadTr")
                            theadTr[t].style.display = 'flex'

                            const tdLen = (theadTr[t].querySelectorAll('td'))
                            for(let u=0; u<tdLen.length; u++){ tdLen[u].style.width = (100 / tdLen.length) + '%' }
                        }

                        const tbodyTr = _id.querySelectorAll('tbody tr')
                        for(let s=0; s<tbodyTr.length; s++){ // ADDING MISSING COLUMNS
                            for(let t=0; t<tbodyTr[s].querySelectorAll('td').length; t++){
                                if(tbodyTr[s].querySelectorAll('td')[t].getAttribute('colspan')){
                                    const len = tbodyTr[s].querySelectorAll('td')[t].getAttribute('colspan');
                                    if( len !== null ){
                                        for(var u=1; u<len*1; u++){
                                            insertAfter(tbodyTr[s].querySelectorAll('td')[t])
                                        }
                                    }
                                }
                            }
                        }

                        const addingRowsFunc = () => {
                            for(let s=0; s<tbodyTr.length; s++){ // ADDING MISSING ROWS
                                for(let t=0; t<tbodyTr[s].querySelectorAll('td').length; t++){
                                    if(tbodyTr[s].querySelectorAll('td')[t].getAttribute('rowspan')){
                                        const len = tbodyTr[s].querySelectorAll('td')[t].getAttribute('rowspan');
                                        if( len !== null && len*1 >= 2){
                                            for(var u=1; u<len*1; u++){
                                                const newEl = document.createElement("td");
                                                newEl.innerText = tbodyTr[s].querySelectorAll('td')[t].innerText;
                                                if(t === 0){
                                                    tbodyTr[s+u].prepend(newEl)
                                                } else {
                                                    if(tbodyTr[s+u].querySelectorAll('td')[t-1]){
                                                        insertAfter(tbodyTr[s+u].querySelectorAll('td')[t-1], newEl)
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        addingRowsFunc()

                        
                        for(let t=0; t<tbodyTr.length; t++){
                            tbodyTr[t].classList.add(classN);
                            tbodyTr[t].classList.add("tbodyTr")
                            tbodyTr[t].style.display = 'flex'

                            const tdLen = (tbodyTr[t].querySelectorAll('td'))
                            for(let u=0; u<tdLen.length; u++){ tdLen[u].style.width = (100 / tdLen.length) + '%' }
                        }

                        const trLi = _id.querySelectorAll('tr li')
                        for(let t=0; t<trLi.length; t++){ trLi[t].classList.remove(classN) }
                    } catch (error) {
                        console.log('TCF 1 > Error --------- ', error)
                    }
                }

                const legendRoot = _id?.querySelector('.legendRoot')
                const footerPPT = _id?.querySelector('.footerPPT')

                const queryObject = getQuerySelectorDetails(_id, p)

                const array = []
                const tempIds = []
    
                let isOpportunitiesNode = null
                let imgGrp = 1 // TEMP

                ////////////////////////////////////////////////////////////////////////// SULAMAN
                if(insightsId?.querySelectorAll('table')?.length > 0){
                    const classN = queryObject.addClassToDiv

                    const node = document.createElement("ul");
                    node.innerHTML = `<li class='dynamicRowNewSlide ${classN}' style="font-size:0.01px !important; height:1px; overflow:hidden;">Insights Page Breaker section</li>`
                    insightsId.prepend(node)
                    insightsId.appendChild(node)

                    insightsId.classList.add('insightsExportToPPT');

                    const li = insightsId.querySelectorAll('li')
                    for(let t=0; t<li.length; t++){
                        li[t].classList.add('insightsRow')
                        li[t].classList.add(classN)
                        li[t].classList.add("li")
                        li[t].style.paddingBottom = '2px'
                    }

                    for(let t=0; t<li.length; t++){
                        if ( li[t].querySelectorAll('li').length > 0 ) {
                            li[t].classList.add('insightsRow')
                            li[t].classList.add(classN)
                            // li[t].classList.add("liLi")
                            for(let s=0; s<li[t].querySelectorAll('li').length; s++){
                                li[t].querySelectorAll('li')[s].classList.add("liLi")
                                li[t].querySelectorAll('li')[s].classList.remove('li')
                            }
                        }
                    }

                    const theadTr = insightsId.querySelectorAll('thead tr')
                    for(let t=0; t<theadTr.length; t++){
                        theadTr[t].classList.add('insightsRow');
                        theadTr[t].classList.add(classN);
                        theadTr[t].classList.add("theadTr")
                        theadTr[t].style.display = 'flex'

                        const tdLen = (theadTr[t].querySelectorAll('td'))
                        for(let u=0; u<tdLen.length; u++){ tdLen[u].style.width = (100 / tdLen.length) + '%' }
                    }

                    const tbodyTr = insightsId.querySelectorAll('tbody tr')
                    for(let t=0; t<tbodyTr.length; t++){
                        tbodyTr[t].classList.add('insightsRow');
                        tbodyTr[t].classList.add(classN);
                        tbodyTr[t].classList.add("tbodyTr")
                        tbodyTr[t].style.display = 'flex'

                        const tdLen = (tbodyTr[t].querySelectorAll('td'))
                        for(let u=0; u<tdLen.length; u++){ tdLen[u].style.width = (100 / tdLen.length) + '%' }
                    }

                    const trLi = insightsId.querySelectorAll('tr li')
                    for(let t=0; t<trLi.length; t++){ trLi[t].classList.remove('liLi') }
                    for(let t=0; t<trLi.length; t++){ trLi[t].classList.remove('li') }
                    for(let t=0; t<trLi.length; t++){ trLi[t].classList.remove('insightsRow') }
                    for(let t=0; t<trLi.length; t++){ trLi[t].classList.remove(classN) }

                }
                queryObject.queryIdsInsights = insightsId?.querySelectorAll('.insightsRow')
                queryObject.hasHeaderClassForNewSlideForInsights = 'dynamicRowNewSlide'

                // queryObject.hasHeaderClassForNewSlide = queryObject.hasHeaderClassForNewSlide === '' ? 'dynamicRowNewSlide' : queryObject.hasHeaderClassForNewSlide
                // queryObject.hasHeaderClassForNewSlide = 'dynamicRowNewSlide'

                if(!queryObject.queryIdsInsights){ queryObject.queryIdsInsights = [] }

                if(queryObject.queryIds.length === 0){
                    // document.getElementById(p.id).classList.add('dynamicRow')
                    queryObject.queryIds = _id.querySelectorAll('.dynamicRow')
                    queryObject.addClassToDiv = 'dynamicRow'
                    // queryObject.queryIds = p.id.querySelectorAll('.dynamicRow')
                }
                ////////////////////////////////////////////////////////////////////////// SULAMAN

                const outerDiv = document.createElement('div')
                outerDiv.id = `${p.id}__tempWrapper`
                outerDiv.className = 'OuterWrapper'
                document.getElementById(p.id).appendChild(outerDiv)
                const hasHeaderClassForNewSlideArray = []
                p.pptSubHeadingFlag = queryObject.pptSubHeadingFlag
                p.headerPptFlag = queryObject.headerPptFlag

                const combinedQueryIds = [...queryObject.queryIdsInsights, ...queryObject.queryIds]

                for(let i=0; i<Math.ceil(combinedQueryIds.length); i++){
                    if(combinedQueryIds[i]?.getAttribute('data-segment') === 'Opportunities'){
                        isOpportunitiesNode = combinedQueryIds[i]
                    }
                    else {
                        array.push([])
                        const innerDiv = document.createElement('div')
                        innerDiv.id = `${p.id}__tempWrapper__${i}`
                        innerDiv.className = `__tempWrapper__ ${queryObject.addClassToDiv}`
                        // if( ['InsightsMiningFrameworkWithSentiments'].includes(p.InsightsElementType)) { innerDiv.className = 'segmentRow' }
                        document.getElementById(`${p.id}__tempWrapper`).appendChild(innerDiv)
                    }
                }

                for(let j=0; j<array.length; j++){
                    for(let i=0; i<combinedQueryIds.length; i++){
                        if(i>=imgGrp*j && i<imgGrp*(j+1)){
                            array[j].push(combinedQueryIds[i])
                            let flag = combinedQueryIds[i]?.classList?.contains(queryObject.hasHeaderClassForNewSlideForInsights)
                            if([...EXPORT_PPT.deepDiveSegmentsAppendices, ...EXPORT_PPT.deepDiveDriversAppendices, 'Segments____'].includes(p?.InsightsElementTypeAppendices)){
                                // This is used for only > AppendicesBrandImageryMappingGraph, CompetitiveLandscape & All Drivers to make it more Complex
                                flag = combinedQueryIds[i]?.classList?.contains(queryObject.hasHeaderClassForNewSlide)
                                hasHeaderClassForNewSlideArray.push(flag)
                            } else if([...EXPORT_PPT.deepDiveSegmentsProgression, 'Segments____'].includes(p?.InsightsElementTypeProgression)){
                                // This is used for only > ProgressionBrandImageryMappingGraph, CompetitiveLandscape & All Drivers to make it more Complex
                                flag = combinedQueryIds[i]?.classList?.contains(queryObject.hasHeaderClassForNewSlide)
                                hasHeaderClassForNewSlideArray.push(flag)
                            } else if(['CompetitiveLandscape', 'CampaignTriggers', 'Segments____', 'Recommendation', 'BusinessObjective'].includes(p?.InsightsElementType)){
                                // This is used for only > AppendicesBrandImageryMappingGraph, CompetitiveLandscape & All Drivers to make it more Complex
                                // let flag = combinedQueryIds[i]?.classList?.contains(queryObject.hasHeaderClassForNewSlideForInsights)
                                if(!flag){
                                    flag = combinedQueryIds[i]?.classList?.contains(queryObject.hasHeaderClassForNewSlide)
                                }
                                hasHeaderClassForNewSlideArray.push(flag)
                            } else {
                                hasHeaderClassForNewSlideArray.push(flag)
                            }
                            
                            if(queryObject?.headerPptDataIndex?.class){
                                const headerFlag = combinedQueryIds[i]?.classList?.contains(queryObject.headerPptDataIndex.class)
                                if(headerFlag){
                                    queryObject.headerPptDataIndex.indexInBase64Array = i
                                    p.headerPptDataIndex = i
                                }
                            }
                            
                            document.getElementById(`${p.id}__tempWrapper__${j}`).appendChild(combinedQueryIds[i])
                            if(queryObject?.pptSubHeadingFlag){
                                if( !!(combinedQueryIds[i]?.querySelector('.pptSubHeading'))  ) {
                                    const txt = combinedQueryIds[i]?.querySelector('.pptSubHeading')?.textContent
                                    p.pptSubHeadingArray.push(txt)
                                } else { p.pptSubHeadingArray.push('') }
                            } else { p.pptSubHeadingArray.push('') }
                        }
                    }
                }
                
                for(let j=0; j<array.length; j++){
                    tempIds.push(document.getElementById(`${p.id}__tempWrapper__${j}`))
                    // p.loadingDetails.svgImgLoaded.push(false)
                }

                if(footerPPT){
                    tempIds.push(footerPPT)
                }

                if(legendRoot){
                    tempIds.push(legendRoot)
                }
                
                await generateMultipleImageFunc(tempIds, hasHeaderClassForNewSlideArray)
                
                if( p.InsightsElementType === 'CategoryPersona'){
                    // const id = isOpportunitiesNode
                    const id = document.querySelector(`#${p.id}__recommendation > div > div`)
                    const id_heading = document.getElementById(p.idHeadingInfo)
                    const id_headingInsight = document.getElementById(p.idHeadingInsightsInfo)
                    const id_headingTabs = document.getElementById(p.idHeadingTabsInfo)
                    const id_headingAppendices = document.getElementById(p.idHeadingAppendicesInfo)
                    const id_headingProgression = document.getElementById(p.idHeadingProgressionInfo)

                    id.style.width = (imageWidth)+'px'
                    id.style.minWidth = (imageWidth)+'px'
                    id.style.background = '#fff'
                    let jsonObject_body = {}

                    count = 0
                    const jsonObject_heading = nodeJsonFunc(id_heading.parentNode.childNodes, 'jsonObject_heading')
                    const jsonObject_headingInsights = id_headingInsight?.childNodes ? nodeJsonFunc(id_headingInsight.parentNode.childNodes, 'jsonObject_headingInsights') : {}
                    const jsonObject_headingTabs = id_headingTabs?.childNodes ? nodeJsonFunc(id_headingTabs.parentNode.childNodes, 'jsonObject_headingTabs') : {}
                    const jsonObject_headingAppendices = id_headingAppendices?.childNodes ? nodeJsonFunc(id_headingAppendices.parentNode.childNodes, 'jsonObject_headingAppendices') : {}
                    const jsonObject_headingProgression = id_headingProgression?.childNodes ? nodeJsonFunc(id_headingProgression.parentNode.childNodes, 'jsonObject_headingProgression') : {}

                    jsonObject_body = nodeJsonFunc(id.childNodes, 'jsonObject_body')
                    
                    id.style.width = '100%'
                    id.style.minWidth = '100%'
                    p.object = jsonObject_body
                    p.objectHeadingInfo = jsonObject_heading
                    p.objectHeadingInsightsInfo = jsonObject_headingInsights
                    p.objectHeadingTabsInfo = jsonObject_headingTabs
                    p.objectHeadingAppendicesInfo = jsonObject_headingAppendices
                    p.objectHeadingProgressionInfo = jsonObject_headingProgression
                    p.base64 = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAIAQMAAAD+wSzIAAAABlBMVEX///+/v7+jQ3Y5AAAADklEQVQI12P4AIX8EAgALgAD/aNpbtEAAAAASUVORK5CYII'
                    p.width = 200
                    p.height = 200
                    
                    pptIds[index] = p
                    // document.querySelector(`#${p.id}`).classList.remove('exportToPPT')
                    // document.querySelector(`#${p.id}`).classList.add('removedExportToPPT')
                    pptIdsFunc(index+1)
                }
            }
            else{
                p.loadingDetails.svgImgLoaded.push(false)
                await generateSingleImageFunc(_id)
            }
        }
        
        pptIdsFunc(0)
    }

    const checkDownloadPptApi = async () => {
        setPPTModalActiveAndClicked(true);
        setShowOverlay(true)
        setLoaderDetails_show(false)
        setLoaderDetails_total(0)
        setLoaderDetails_count(0)

        if(pptSubLhs.length > 0){ setPptIdsType('study'); onLoadFunc(pptSubLhs, 'study')}
        // else if(pptSubLhsAppendices.length > 0 && pptSubLhsProgression.length > 0) {
        else if(pptIdsTypes.length === 2) {
            if(pptIdsType === ''){
                setPptIdsType('appendices'); onLoadFunc(pptSubLhsAppendices, 'appendices')
            } else {
                setPptIdsType('progression'); onLoadFunc(pptSubLhsProgression, 'progression')
            }
        }
        else if(pptSubLhsAppendices.length > 0){ setPptIdsType('appendices'); onLoadFunc(pptSubLhsAppendices, 'appendices')}
        else if(pptSubLhsProgression.length > 0){ setPptIdsType('progression'); onLoadFunc(pptSubLhsProgression, 'progression')}
        // if(pptSubLhs.length > 0 && pptSubLhsAppendices.length > 0 && pptSubLhsProgression.length > 0){ setPptIdsTypes(['study', 'appendices', 'progression'])}

        if(pptSubLhs.length > 0 ){ setPptIdsTypes(['study'])}
        else if (pptSubLhsAppendices.length > 0 && pptSubLhsProgression.length > 0) { setPptIdsTypes(['appendices', 'progression']) }
        else if (pptSubLhsAppendices.length > 0) { setPptIdsTypes(['appendices']) }
        else if (pptSubLhsProgression.length > 0) { setPptIdsTypes(['progression']) }

        updateLoadingStatus('hide')
    }

    const updateLoadingStatus = (action='show', type, param1, param2, param3=0) => {
        if(window.localStorage.getItem('dev')){
            if(document.getElementById('pptLoadingStatus')){
                if(action === 'show'){
                    document.querySelector('#pptLoadingStatus').style.display = 'block'
                    if(type === 'loading') {
                        if (param1 > 0) {document.querySelector('#pptLoadingStatus b').innerHTML = param1}
                        document.querySelector('#pptLoadingStatus span').innerHTML = param2
                    } else if(type === 'insightsId') {
                        document.querySelector('#pptLoadingStatus ol').innerHTML += `<li>${param1} - ${param2}</li>`
                        document.querySelector('#pptLoadingStatus strong').innerHTML = parseInt(document.querySelector('#pptLoadingStatus strong').innerHTML) + param3
                    }
                } else if (action === 'hide') {
                    document.querySelector('#pptLoadingStatus').style.display = 'none'
                    document.querySelector('#pptLoadingStatus ol').innerHTML = ''
                    document.querySelector('#pptLoadingStatus span').innerHTML = ''
                }

                // if(studyLen === 1 || pptSubLhsAppendices === 1){
                //     document.querySelector('#pptLoadingStatus #timeStartEnd').innerHTML = ''
                // }
            }
        }
    }

    const loadDownloadPptFunc = () => {        
        // const arr = ['ResearchDesign', 'BusinessObjective', 'CustomImage', 'Recommendation']
        const studyLen = getSelectedData_subLhsApiLoaded('getLen', pptIdsType)
        const pptSubLhsAppendices = getSelectedData_subLhsApiLoaded('getLen', pptIdsType)
        const pptSubLhsProgression = getSelectedData_subLhsApiLoaded('getLen', pptIdsType)
        
        // console.log('Check 0 - ', isPPTModalActiveAndClicked, pptIds, subLhsApiLoaded)
        // console.log('Check 1 - ', isPPTModalActiveAndClicked, pptSubLhs, (pptSubLhs.length > 0 || pptSubLhsAppendices > 0 || pptSubLhsProgression > 0))
        if(isPPTModalActiveAndClicked && (pptSubLhs.length > 0 || pptSubLhsAppendices > 0 || pptSubLhsProgression > 0)){
            let downloadPptFlag = false;
            const keys = Object.keys(subLhsApiLoaded)

            const logData = pptSubLhs.map(d => ({title: d.Title, InsightsElementType: d.InsightsElementType, insightsElementId:d.InsightsElementId}))
            const logData2 =  logData.filter(f => !keys.includes('id-'+f.insightsElementId))
            // console.log('Check 2 - ', logData2)
            let setTimeoutFun
            
            if(logData2?.length > 0){
                console.log('APIs to load = ', pptSubLhs.length-logData2.length)
                // clearInterval(setTimeoutFun)
                // setTimeoutFun = setTimeout(() => loadDownloadPptFunc(), 2000)
            } else {
                clearInterval(setTimeoutFun)
                console.log('APIs loaded = ', pptSubLhs.length-logData2.length)
                // const len = Object.keys(subLhsApiLoaded).length
                if( studyLen > 0 && pptIdsType==='study' && studyLen >= (pptSubLhs.length-1) ){
                    if(window.localStorage.getItem('dev')) console.log(`Total APIs to load(${pptIdsType}) = ${pptSubLhs.length} | currently loaded = ${studyLen}`)
                    downloadPptFlag = true
                }
                if ( pptSubLhsAppendices > 0 && pptIdsType==='appendices' && pptSubLhsAppendices >= (oldPptSubLhsLength+(pptSubLhsAppendices-1)-appendicesIdsLoaded) ){
                    if(window.localStorage.getItem('dev')) console.log(`Total APIs to load(${pptIdsType}) = ${oldPptSubLhsLength+pptSubLhsAppendices-appendicesIdsLoaded} | currently loaded = ${pptSubLhsAppendices}`)
                    downloadPptFlag = true
                }
                if ( pptSubLhsProgression > 0 && pptIdsType==='progression' && pptSubLhsProgression >= (oldPptSubLhsLength+(pptSubLhsProgression-1)-progressionIdsLoaded) ){
                    if(window.localStorage.getItem('dev')) console.log(`Total APIs to load(${pptIdsType}) = ${oldPptSubLhsLength+pptSubLhsProgression-progressionIdsLoaded} | currently loaded = ${pptSubLhsProgression}`)
                    downloadPptFlag = true
                }

                if( downloadPptFlag ){
                    if(pptIds?.length > 0){
                        function fetchData() { 
                            setTimeout(async () => {
                                if(getImageDetailLogs()){
                                    const logoImgId = document.querySelector('#downloadLogo')
                                    await domtoimage.toPng(logoImgId, { quality: 0.95 })
                                        .then(async (dataUrl) => {
                                            if(getImageDetailLogs()){
                                                setLogoImgIdBase64(dataUrl)
                                                const len = (pptSubLhs.length < 10) ? 10 : Math.min(pptSubLhs.length, 20)
                                                setTimeout(() => {
                                                    downloadPPT(dataUrl)
                                                }, len*1000)
                                            } else {fetchData()}
                                        })
                                }
                                else{
                                    fetchData()
                                }
                            }, 500)
                        }
                        fetchData()
                    }
                }
            }
        }
    }

    useEffect(() => {
        loadDownloadPptFunc()
    }, [pptIds, subLhsApiLoaded, isPPTModalActiveAndClicked])

    useEffect(() => {
        updateLoadingStatus('hide')
    }, [])

    useEffect(() => {
        if(window.localStorage.getItem('dev')) console.log(`Total Images to load(${pptIdsType}) = ${loaderDetails_total} | currently loaded = ${loaderDetails_count} | flag = ${loaderDetails_show}`)

        const imageDetail = `Total Images to load(${pptIdsType}) = ${loaderDetails_total} | currently loaded = ${loaderDetails_count}`
        updateLoadingStatus('show', 'loading', loaderDetails_total, imageDetail)

        if(pptIdsType==='study' && loaderDetails_total >= 0){
            if(loaderDetails_count >= loaderDetails_total){
                setLoaderDetails_show(false)
            }
        }
        else if(pptIdsType==='appendices' && loaderDetails_total >= 0){
            if(loaderDetails_count >= loaderDetails_total){
                setLoaderDetails_show(false)
            }
        }
        else if(pptIdsType==='progression' && loaderDetails_total >= 0){
            if(loaderDetails_count >= loaderDetails_total){
                setLoaderDetails_show(false)
            }
        }
    }, [loaderDetails_total, loaderDetails_count])


    return (
        <>
            <Box className='BtnExportToPowerPoint' style={{position:'relative'}}>
                <MuiButton 
                    variant='contained'
                    color='dark'
                    onClick={() => { setPPTModalActive(true); setAskIaActiveState('none') }}
                    sx={{height:'40px'}}
                    startIcon={<Download color='white' />}
                >
                    Export to PowerPoint
                </MuiButton>
            </Box>
            <PptDrawer handleDownload={checkDownloadPptApi} />

            {showOverlay && <LoaderOverlay className='loaderOverlay' />}
            {!showOverlay && <AudioTag />}
            
            {/* {(Object.keys(subLhsApiLoaded).length < pptSubLhs.length || loaderDetails_show) && pptIdsType==='study' && <BlockingLoader msg={'Study report is being exported ........'} value={loaderDetails_count} maxValue={loaderDetails_total} />} */}
            {pptIdsType==='study' && loaderDetails_show && <BlockingLoader msg={'Study report is being exported'} value={loaderDetails_count} maxValue={loaderDetails_total} />}
            {pptIdsTypes.length === 1 && pptIdsType==='appendices' && loaderDetails_show && <BlockingLoader msg={'Appendices is being exported'} value={loaderDetails_count} maxValue={loaderDetails_total} />}
            {pptIdsTypes.length === 1 && pptIdsType==='progression' && loaderDetails_show && <BlockingLoader msg={'Progression is being exported'} value={loaderDetails_count} maxValue={loaderDetails_total} />}
            
            {pptIdsTypes.length === 2 && pptIdsType==='appendices' && loaderDetails_show && <BlockingLoader msg={'Appendices is being exported'} value={loaderDetails_count} maxValue={loaderDetails_total} maxPercentage={50} />}
            {pptIdsTypes.length === 2 && pptIdsType==='progression' && loaderDetails_show && <BlockingLoader msg={'Progression is being exported'} value={loaderDetails_count} maxValue={loaderDetails_total} minPercentage={50} maxPercentage={50} />}

            {/* {Object.keys(subLhsApiLoaded).length < pptSubLhs.length && pptIdsType==='study' && <BlockingLoader msg={'Study report is being prepared'} value={Object.keys(subLhsApiLoaded).length} maxValue={pptSubLhs.length} />} */}
            {Object.keys(subLhsApiLoaded).length < (oldPptSubLhsLength+pptSubLhsAppendices.length-appendicesIdsLoaded) && pptIdsType==='appendices' && <BlockingLoader msg={'Appendices is being prepared'} value={Object.keys(subLhsApiLoaded).length} maxValue={pptSubLhsAppendices.length} />}
            {Object.keys(subLhsApiLoaded).length < (oldPptSubLhsLength+pptSubLhsProgression.length-progressionIdsLoaded) && pptIdsType==='progression' && <BlockingLoader msg={'Progression is being prepared'} value={Object.keys(subLhsApiLoaded).length} maxValue={pptSubLhsProgression.length} />}

            {pptIdsType==='study' && (loaderDetails_count < 2) && <BlockingLoader msg={'Study report is being exported,'} value={0} maxValue={100} />}

            <HiddenPptLogo><img id='downloadLogo' src={props.logoDetails.logoImg} height={25} alt='logo' /></HiddenPptLogo>
        </>
    )

}

export default DownloadPpt
