import React, {useState, useEffect} from 'react'
import { ReportHeading } from '../../../../components'
import { getImageUrl } from '../../../../util'
import { useUpdateLhs } from '../../../../hooks/useUpdateLhs'
import { ReportOuterWrapper } from '../../../../components'
import { CustomImage_48b3fc72_d837_11ed_817c_0a1ea4c5532f, CustomImage_9d0c623b_b2de_11ed_817c_0a1ea4c5532f, CustomImage_5e95d366_6bf1_11ee_817c_0a1ea4c5532f, CustomImage_63357b94_b2da_11ee_9f55_0a1ea4c5532f, CustomImage_70a2369b_fafb_11ee_8482_128e718ba88f } from '../../../../assets/images/customImage'
import PieComponent from '../../../../assets/images/customImage/pieComponent'
import { OuterWrapper, ContentWrapper } from './style'

import {
  pieChart_data_ee0f401e_424d_11ef_8482_128e718ba88f, barGraph_data_ee0f401e_424d_11ef_8482_128e718ba88f,
  pieChart_data_70a2369b_fafb_11ee_8482_128e718ba88f, barGraph_data_70a2369b_fafb_11ee_8482_128e718ba88f,
  pieChart_data_63357b94_b2da_11ee_9f55_0a1ea4c5532f, barGraph_data_63357b94_b2da_11ee_9f55_0a1ea4c5532f,
  pieChart_data_5e95d366_6bf1_11ee_817c_0a1ea4c5532f, barGraph_data_5e95d366_6bf1_11ee_817c_0a1ea4c5532f,
  pieChart_data_9d0c623b_b2de_11ed_817c_0a1ea4c5532f
} from '../../../../assets/images/customImage/data'

const ReportCustomImage = (props) => {
  const [isImg, setIsImg] = useState()
  const imgPath = getImageUrl('customImage', props.InsightsElementId, 'svg')
  const {updateSubLhsApiLoaded} = useUpdateLhs()
  const [eventCount, setEventCount] = useState(1)

  const ethnographyPieBarChartIds = [
    '9d0c623b-b2de-11ed-817c-0a1ea4c5532f', '5e95d366-6bf1-11ee-817c-0a1ea4c5532f', '63357b94-b2da-11ee-9f55-0a1ea4c5532f', '70a2369b-fafb-11ee-8482-128e718ba88f', 'ee0f401e-424d-11ef-8482-128e718ba88f'
  ]

  const imageLoaded = () => {
    setEventCount(p => p+1)
    setTimeout(() => {
      if (isImg && imgPath) {
        imageLoaded()
      } else {
        updateSubLhsApiLoaded(props)
        setEventCount(p => p+1)
      }
    }, 2000)
  }

  useEffect(() => {
    setTimeout(() => {
      setIsImg(!['48b3fc72-d837-11ed-817c-0a1ea4c5532f', ...ethnographyPieBarChartIds].includes(props.InsightsElementId))
      imageLoaded()
    }, 2000)
    setEventCount(p => p+1)
  }, [])

  return (
    <>
      <ReportHeading resetInsightHeight={eventCount} downloadId={props.type} subHeading='' {...props} excel={false} />
      <ReportOuterWrapper>
        <OuterWrapper id={props.type}>
          <ContentWrapper>
            {isImg && imgPath && <img src={imgPath} alt='' onLoad={imageLoaded} />}
            {!isImg && <CustomPieImage count {...props} id={props.InsightsElementId} imgPath={imgPath} ethnographyPieBarChartIds={ethnographyPieBarChartIds} />}
          </ContentWrapper>
        </OuterWrapper>
      </ReportOuterWrapper>
    </>
  )
}

export default ReportCustomImage

const CustomPieImage = (props) => {

  const {id, ethnographyPieBarChartIds} = props
  let pieData = null
  let barData = null
  let pageUrlId = null
  if(id === 'ee0f401e-424d-11ef-8482-128e718ba88f') { // 
    pieData = pieChart_data_ee0f401e_424d_11ef_8482_128e718ba88f
    barData = barGraph_data_ee0f401e_424d_11ef_8482_128e718ba88f
    pageUrlId = '64329ce8-f13c-11ee-8482-128e718ba88f'
  } else if(id === '70a2369b-fafb-11ee-8482-128e718ba88f') { // 
    pieData = pieChart_data_70a2369b_fafb_11ee_8482_128e718ba88f
    barData = barGraph_data_70a2369b_fafb_11ee_8482_128e718ba88f
    pageUrlId = 'bdf5bf29-aa0c-11ee-9f55-0a1ea4c5532f'
  } else if(id === '63357b94-b2da-11ee-9f55-0a1ea4c5532f') {
    pieData = pieChart_data_63357b94_b2da_11ee_9f55_0a1ea4c5532f
    barData = barGraph_data_63357b94_b2da_11ee_9f55_0a1ea4c5532f
    pageUrlId = '769c0218-6e4b-11ee-817c-0a1ea4c5532f'
  } else if(id === '5e95d366-6bf1-11ee-817c-0a1ea4c5532f') {
    pieData = pieChart_data_5e95d366_6bf1_11ee_817c_0a1ea4c5532f
    barData = barGraph_data_5e95d366_6bf1_11ee_817c_0a1ea4c5532f
    pageUrlId = '52317cd4-681b-11ee-817c-0a1ea4c5532f'
  } else if(id === '9d0c623b-b2de-11ed-817c-0a1ea4c5532f') {
    pieData = pieChart_data_9d0c623b_b2de_11ed_817c_0a1ea4c5532f
    pageUrlId = '56e8ef56-a31d-11ed-817c-0a1ea4c5532f'
  }

  return (
    <>
      {id === '48b3fc72-d837-11ed-817c-0a1ea4c5532f' && <CustomImage_48b3fc72_d837_11ed_817c_0a1ea4c5532f />}
      {ethnographyPieBarChartIds.includes(id) && <PieComponent {...props} pie={pieData} bar={barData} id={id} pageUrlId={pageUrlId} />}
    </>
  )
}
