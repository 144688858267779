import React, { useState } from 'react'
import Header from './header'
import {LayoutFooter, Lhs, TourGuide} from '../../components'
import { useGlobalStore } from '../../store'

import {
  PostLoginOuterWrapper,
  LhsWrapper,
  RhsWrapper,
  ContentWrapper,
  ContentInnerWrapper
} from './style'
import { Box } from '@mui/material'

const PostLogin = (props) => {
  const { children } = props
  const { isTourGuideActive, showLhsMenu } = useGlobalStore()

  return (
    <PostLoginOuterWrapper>
      {isTourGuideActive && <TourGuide type='postLogin' />}
      <Header />
      <Box sx={{display: 'flex'}}>
        <LhsWrapper>
          <Lhs />
        </LhsWrapper>
        <RhsWrapper showLhsMenu={showLhsMenu}>
          <ContentWrapper>
            <ContentInnerWrapper>
              {children}
            </ContentInnerWrapper>
            <LayoutFooter />
          </ContentWrapper>
        </RhsWrapper>
      </Box>
    </PostLoginOuterWrapper>
  )
}

export default PostLogin
