import { Box, IconButton } from '@mui/material';
import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom';
import { edit } from '../../../../assets/images/icons';
import { MuiImage, NoDataFound, SearchInput } from '../../../../components'
import ProjectsService from '../../../../services/projects.service'
import { DraftWrap, InputWrap } from './style';
import dayjs from 'dayjs';
import { useGlobalStore } from '../../../../store';
import CustomTable from '../../../../components/customTable';

const EditButton = ({ data }) => {
  const { row } = data;

  const projectRouteMap = {
    'Brand Health': 'brand-health',
    'Campaign and Issues Assessment': 'campaign-issues-assessment',
    'Category Exploration': 'category-exploration',
    'Product Rating Optimizer': 'product-rating',
    'Trend Scoping': 'trend-scoping'
  }

  const history = useHistory();

  const editClickHandler = () => {
    history.push(`/initiate-projects/${projectRouteMap[row.ProjectType]}/?projectId=${row.id}`)
  }

  return (
    <IconButton sx={{ marginLeft: "10px" }} onClick={editClickHandler}>
      <MuiImage src={edit} width='15px' />
    </IconButton>
  )
}

const Draft = () => {
  const [screenSize, setScreenSize] = useState(1)
  const [projectsLoading, setProjectsLoading] = useState(false);
  const [text, setText] = useState('');
  const rootRef = useRef()
  // const { enqueueSnackbar } = useSnackbar()
  const [projectData, setProjectData] = useState([]);
  const { user } = useGlobalStore()

  const headerMeta = [
    {
      id: 'DraftName',
      label: 'Draft Name',
      minWidth: '40%',
      align: 'left',
      filterMethod: setProjectData,
    },
    {
      id: 'ProjectCreatorEmailAddress',
      label: 'Email Address',
      minWidth: '40%',
      align: 'left',
      filterMethod: setProjectData,
    },
    {
      id: 'ModifiedDate', // key of data
      label: 'Last Updated',
      minWidth: '20%',
      align: 'right',
      filterMethod: setProjectData,
    }
  ]

  const data = [
    {
      DraftName: "abc",
      ProjectCreatorEmailAddress: "atulfind@gmail.com",
      ModifiedDate: "dfkadf"
    }
  ]

  useEffect(() => {
    setScreenSize(rootRef.current.offsetWidth)
  }, [])

  useEffect(() => {
    if (user?.UserId) {
      setProjectsLoading(true);
      const userData = {
        CompanyId: user?.CompanyId,
        UserId: user?.UserId
      }
      ProjectsService.projectsApi({ IsDraft: true, user: userData }, true)
        .then((resp) => {
          const data = resp.map(r => { r.details = []; return r })
          const modifiedData = data.map(dataItem => ({
            ...dataItem,
            id: dataItem.ProjectId,
            ModifiedDate: dayjs(dataItem.ModifiedDate).format('MMMM, DD, YYYY')
          }))
          const filteredData = modifiedData.filter(item => item.IsDraft)
          setProjectData(filteredData);
          setProjectsLoading(false)
        })
        .catch((error) => {
          const resMessage = error
          console.log('ERROR : ', resMessage)
          // enqueueSnackbar(resMessage, { variant: 'error' })
          setProjectsLoading(false)
        })
    }
  }, [user])

  const inputChangeHandler = (e) => {
    setText(e.target.value)
  }

  const filteredRows = projectData.filter(rowItem => rowItem?.DraftName?.includes(text) || rowItem?.ProjectCreatorEmailAddress?.includes(text) || !text)
  const modifiedData = filteredRows.map(item => ({
    ...item,
    ModifiedDate: <Box sx={{ display: "flex", alignItems: 'center', marginLeft: 'auto', justifyContent: 'end' }}>
      {item?.ModifiedDate}
      <EditButton data={{ row: item }} />
    </Box>
  }))

  return (
    <DraftWrap ref={rootRef}>
      <InputWrap>
        <SearchInput
          placeholder='Search'
          value={text}
          onChange={inputChangeHandler}
          name='draft-search'
        />
      </InputWrap>
      <CustomTable header={headerMeta} data={modifiedData} />
      {
        !filteredRows?.length &&
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '280px'
        }} width="100%" height="100%">
          <NoDataFound title="No projects yet" width="90px" height="90px" />
        </Box>
      }
    </DraftWrap>
  );
};

export default Draft;
