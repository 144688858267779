
import { styled } from '@mui/material/styles'
import { Box, Collapse, IconButton, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { MuiButton } from '../../../../components'
import { LEGEND_TEXT_COLOR_MAP } from '../../../../constants'
import { MEDIA } from '../../../../constants'


export const LegendWrapper = styled(Box)(`
    padding-top:20px;
    & > div {justify-content:end;}
`)

export const ContentWrapper = styled(Box)( props =>`
    width: 100%;
    padding:20px 30px 50px 30px;
    display: flex;
    flex-direction:column;

    & .barRoot {max-width:100%;}
    @media (max-width: ${MEDIA.lg}) {
        padding:20px 20px 40px 20px;
    }
`)

export const TableCellBody = styled(TableCell)(`
    &.brdL {border-left: 1px #E0E0E0 solid;}
`)

export const Wrapper = styled(Grid)(`
        
`)

export const TableContainerWrapper = styled(TableContainer)(`
    overflow:visible;
    & .theme2 thead {
        display:none;
    }
    & .theme2 tr { 
        // background: transparent !important;
        box-shadow: none !important;
    }
    & .theme2 td { 
        border:0;
    }
    & .theme2 .driverName {
        font-size: 12px;
    }
    & .theme1 tr.tRow:nth-of-type(2n+2) {
        background: #F7F7F9;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
    }
    & .theme1 tr.details tr.tRow:nth-of-type(2n+2) {
        background: transparent;
        box-shadow: none;
    }
    & .theme1 tr.details .moreActiveButtonL3 .selected {
        background: #F5F5F5 !important;
    }
    & .theme1 tr.details .searchActiveButtonL3 .selected {
        background: transparent !important;
    }
    & .theme1 tr.details .searchActiveButtonL3 .level3Wrap .selected {
        background: #F5F5F5 !important;
    }
`)

export const TableHeadWrapper = styled(TableHead)(`
    border-bottom: 2px #eee solid;
    background: transparent;
    padding:20px 0;
    & img {
        padding:0 10px;
    }
`)

export const TableBodyWrapper = styled(TableBody)(`
    background:transparent;
    & .tr_heading{border-bottom: 1px #F1F1F3 solid; cursor: pointer !important;}
`)

export const TableRowWrapper = styled(TableRow)(`
    font-weight: 300;
    font-size: 14px;
    line-height: 14px;
    text-align: right;
    letter-spacing: -0.01em;
    color: #000000;

    // &:nth-of-type(2n+2) {
    //     background: #F7F7F9;
    //     box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
    // }

    &.selected {
        background: #F5F5F5;
    }
    &.details {    
        background: #FFFFFF;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
        padding:20px;
    }
    & td {
        padding:8px;
        position:relative;
        font-weight:300;
    }

    & .IsHighlighted {
        position:absolute;
        top:0; left:0;
        width:6px;
        height:100%;
        background: #D5732E;
    }
    
    & .Positive { color:${LEGEND_TEXT_COLOR_MAP.Positive}; }
    & .Neutral { color:${LEGEND_TEXT_COLOR_MAP.Natural}; }
    & .Negative { color:${LEGEND_TEXT_COLOR_MAP.Negative}; }
    & .Mixed { color:${LEGEND_TEXT_COLOR_MAP.Mixed}; }

`)

export const ContOuterWrap = styled(Box)(`
    display:flex;
    align-items: center;
    position:relative;
`)

export const ContWrap = styled(Box)(`
    width:150px;
    text-align:right;
    line-height:16px;
    padding-right:10px;
`)

export const InputRangeWrapper = styled(Box)(props => `
    position: absolute;
    top: 0;
    left: ${props?.minvalue || 0}px;
    width: calc(100% - ${props?.minvalue || 0}px);
    height: 100%;
    z-index: 1;
    &:before{
        content:'';
        position:absolute;
        left:${props.value}%;
        top:0;
        width:4px;
        height:100%;
        background:#00B0F0;
        z-index:1;
        margin-left:-2px;
    }
    &:after{
        content:'';
        position:absolute;
        left:${props.value}%;
        top:-6px;
        margin-left:-6px;
        width:12px;
        height:6px;
        background:#00B0F0;
        z-index:1;
        box-shadow:0px 44px 0 #00B0F0;
    }
`)

export const InputRange = styled('input')(`
    position: absolute;
    top: 0;
    left: 0;
    z-index:2;
    width:100%;
    height:100%;
    opacity:0.001;
`)

export const TableRowContent = styled(TableRow)(`
    background:#F5F5F5;
    & .tableCell{padding-bottom: 0, padding-top: 0}
    & .tr_collapse{padding-top: 15px; padding-bottom: 5px;}
`)

export const TableCellHead = styled(TableCell)(`
    vertical-align:bottom;
    text-transform: none !important;
    font-weight:600 !important;
    font-size:14px !important;
    line-height:21px !important;
    padding:8px;
    letter-spacing:0 !important;

    &.brdL {border-left: 1px #E0E0E0 solid;}
`)

export const CollapseContent = styled(Box)( props => `
    margin-bottom:10px;
    display:flex;
    align-items:center;
    color: ${props.type.toLowerCase() === 'completed' ? '#000' : '#7E7E7E'};
`)

export const TableChipCompleted = styled(MuiButton)( props => `
    padding:6px 0;
    margin-right: 5px;
    width:85px;
    text-align:center;
    
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;

    color: #008767;
    background-color: #A0E1D2;
`)

export const TableChipInProgress = styled(Box)( props => `
    padding:6px 0;
    margin-right: 5px;
    width:85px;
    text-align:center;
    
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;

    color: #C88F16;
    background-color: #FFE3C3;
`)
