import React, { useState } from 'react'
import { Typography } from '@mui/material'
import { ChartBarHorizontalCustom } from '../../../../components'
import { LEGEND_TEXT_COLOR_MAP } from '../../../../constants'
import { getObjectAsArray, charWidthFunc } from '../../../../util'

import { BarWrap, IssuesWrap, SegmentRow, SegmentRowContent, SegmentTitle, SegmentWrap } from './style'
import { useEffect } from 'react'

const ComparisonTable = ({ data, segmentData = {}, maxValue, type, insightsElementType }) => {
	const [segmentWidth, setSegmentWidth] = useState(0)
	const [segmentItemWidth, setSegmentItemWidth] = useState(0)
	
	const getMaxValueAndTest = (txtArray) => {
		let obj = txtArray.map(t => ({ name:t, value:t.length }))
		let maxVal = {name:'', value:0}
		for(let i=0; i<obj.length; i++) {
			if(obj[i].value > maxVal.value){
				maxVal = {name:obj[i].name, value:obj[i].value}
			}
		}
		return charWidthFunc(maxVal.name)
	}

	const setSegmentWidthFunc = (text) => {
		const wid = getMaxValueAndTest([text])
		if(wid > segmentWidth) {
			setSegmentWidth(prev => Math.max(prev, wid))
		}
		return text
	}

	useEffect( () => {
		let maxWidth = 0
		Object.keys(segmentData).map((key, i) => {
			const item = segmentData[key]
			const maxWid = getMaxValueAndTest(Object.keys(item))
			maxWidth = Math.max(maxWidth, maxWid)
		})
		setSegmentItemWidth(maxWidth)
	}, [])

	return (
		<SegmentWrap className={'segmentWrap'} style={{width: `calc(100% - ${segmentWidth+10}px)`}}>
			{
				(!['Triangulation', 'TriangulationComparisonViewOnly'].includes(insightsElementType)) && data?.map((item, index) => {
					return (
						<SegmentRow key={index} data-segment={item.SegmentType} className={'segmentRow'}>
							{
								item?.data.map((productItem, i) => {
									if (productItem?.SegmentName === "NA") return null;
									return (
										<SegmentRowContent key={i}>
											<SegmentTitle style={{minWidth: (segmentItemWidth+5)+'px'}}>{productItem.SegmentName}</SegmentTitle>
											<BarWrap className={productItem?.type?.toLowerCase()}>
												<ChartBarHorizontalCustom
													showInside={false}
													bottomText={false}
													data={productItem.data}
													valueInPercent={productItem.SegmentType.indexOf('Percent') !== -1}
													maxPercentRatio={productItem.SegmentMaxValue}
													maxWidth="100%"
												/>
											</BarWrap>
											<Typography className='outerText'>{productItem.SegmentValue}{productItem.SegmentType.indexOf('Percent') !== -1 && '%'}</Typography>
										</SegmentRowContent>
									)
								})
							}
						</SegmentRow>
					)
				})
			}

			{
				segmentData && Object.keys(segmentData).map((key, i) => {
					const item = segmentData[key];

					return (
						<SegmentRow key={key} data-segment={key} className={'segmentRows'} style={{paddingLeft:0}}>
							{['Triangulation', 'TriangulationComparisonViewOnly'].includes(insightsElementType) && <span className='segmentRowsPipe' />}
							{
								Object.keys(item)?.map((productKey, ind) => {
									const productItem = item[productKey];
									const driverAttributeStandouts = getObjectAsArray(productItem?.DriverAttributeStandouts?.DriverAttributeStandout);
									let chartData = productItem.data

									const txt = driverAttributeStandouts?.slice(0, 2).map(m => m?.AttributeName || '').join(',') || ''
									setSegmentWidthFunc(txt)

									if(['Triangulation', 'TriangulationComparisonViewOnly'].includes(insightsElementType)){
										const d = chartData?.[0]
										if( d.Positive + d.Neutral + d.Mixed + d.Negative === 0 ){
											chartData = [{SearchKeywords: productItem.Percent}]
										}
									}
									if(['SegmentDriversOnlyAnalysisWithoutSentiments'].includes(insightsElementType)){
										chartData = [{SearchKeywords: productItem.Percent}]
									}
									return (
										<SegmentRowContent key={productKey + ind}>
											<SegmentTitle className='segmentTitle' style={{minWidth: (segmentItemWidth+5)+'px'}}>{productItem.TargetSegment || productKey}</SegmentTitle>
											<BarWrap sx={{ paddingRight: "30px" }} className={productItem?.type?.toLowerCase()}>
												<ChartBarHorizontalCustom
													showInside={false}
													showToolTip={!(['DriversProgressionWithoutSentiments' , 'SearchDriversProgression'].includes(insightsElementType))}
													bottomText={false}
													data={chartData}
													total={productItem.Percent}
													valueInPercent
													maxPercentRatio={maxValue}
													maxWidth="100%"
												/>
											</BarWrap>
											{
												!!driverAttributeStandouts?.length &&
												<IssuesWrap className='attributes'>
													<div className='radio'>
														<div className='box'></div>
													</div>
													{
														driverAttributeStandouts?.slice(0, 2)?.map((driverAttribute, ii) => {
															return (
																<Typography key={ii} sx={{
																	marginRight: "3px",
																	color: LEGEND_TEXT_COLOR_MAP[type || driverAttribute?.Sentiment]
																}} className='radio-text'>
																	{`${(driverAttribute?.AttributeName)}${(ii === 0 && driverAttributeStandouts?.length > 1) ? ',' : ' '} `}
																</Typography>
															)
														})
													}
												</IssuesWrap>
											}
										</SegmentRowContent>
									)
								})
							}
						</SegmentRow>
					)
				})
			}
		</SegmentWrap>
	)
}

export default ComparisonTable