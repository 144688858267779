import React from 'react'
import { CONST_VERBATIM_PATH } from '../../constants'
import { MediaWrapper } from './style'


const VerbatimImageVideoTag = ({ data }) => {
  const checkMediaFunc = (data) => {
    const ext = (data?.VerbatimMediaPrivateName)?.split('.')?.pop() || data?.VerbatimMediaPrivateName || ''
    if(['mp4'].includes(ext)){return 'video'}
    else if(['jpeg'].includes(ext)){return 'img'}
    else{return 'text'}
  }

  return (
    <MediaWrapper>
     {(checkMediaFunc(data) === 'video') && data?.VerbatimMediaPrivateName && <video width="320" height="240" controls><source src={`${CONST_VERBATIM_PATH}${data?.VerbatimMediaPrivateName}`} type="video/mp4" /></video>}
     {(checkMediaFunc(data) === 'img') && data?.VerbatimMediaPrivateName && <img src={`${CONST_VERBATIM_PATH}${data?.VerbatimMediaPrivateName}`} alt={data?.VerbatimMediaPublicLink || ''} />}
    </MediaWrapper>
  )
}

export default VerbatimImageVideoTag
