import styled from "@emotion/styled";
import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import React from "react";
import { checkboxChecked, checkboxUnchecked } from "../../../../assets/images/icons";
import { MuiDatePicker, MuiFormLabel, MuiImage } from "../../../../components";

const EndDateWrap = styled(Box)(`
    display: flex;
    flex-direction: column;
    width: 100%;
    & .MuiFormControlLabel-root{
      margin: 0 !important;
      margin-left: -10px !important;
      margin-top: -13px !important;
    }
    & .DatePickerWrap {
      margin-top: -10px;
    }
`)

const FormGroupLabelWrap = styled(FormControlLabel)(`
  & .MuiTypography-root {
    font-size: 12px;
    white-space: nowrap;
    font-wieght: 300;
  }
`)

const StyledOrHeading = styled(Typography)(`
    font-size: 12px;
    white-space: nowrap;
    font-wieght: 300;
    color: #7E7E7E;
    height: 53px;
    display: flex;
    align-items: center;
    margin-top: auto;
    margin-top: -10px;
`);

const Rows = styled(Box)(`
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: space-between;
`);

const NoEndDate = ({ formik }) => {
  return (
    <EndDateWrap>
      <MuiFormLabel>End Date</MuiFormLabel>
      <Rows>
        <Box>
          <FormGroupLabelWrap
            label="No End Date"
            onChange={(e) => {
              formik.setFieldValue("NoProjectEndDate", e.target.checked);
              formik.setFieldValue("ProjectEndDate", "");
            }}
            control={<Checkbox
              icon={<MuiImage src={checkboxUnchecked} width="14px" />}
              checkedIcon={<MuiImage src={checkboxChecked} width="14px" />}
              checked={formik?.values?.NoProjectEndDate} />}
          />
        </Box>
        <StyledOrHeading>Or</StyledOrHeading>
        <Box sx={{width: "100%"}}>
          <MuiDatePicker
            fullWidth
            name="ProjectEndDate"
            disabled={!!formik.values.NoProjectEndDate}
            value={ formik?.values?.NoProjectEndDate ? null : formik.values.ProjectEndDate || ""}
            onChange={(value) =>
              formik.setFieldValue("ProjectEndDate", value.toISOString())
            }
            placeholder="End"
            label=""
            error={formik.errors.ProjectEndDate}
          />
        </Box>
      </Rows>
    </EndDateWrap>
  );
};

export default NoEndDate;
