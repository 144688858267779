import React, { useEffect, useState } from "react"
import { Legend, ReportHeading, SocialLegend, LoaderWrapper, ReportOuterWrapper } from "../../../../components"
import ReportsService from "../../../../services/reports.service"
import * as d3 from "d3"
import { SOCIAL_IMAGE_MAP } from "../../../../constants"
import { useUpdateLhs } from '../../../../hooks/useUpdateLhs'
import InfoRow from "./infoRow"
import { OuterWrapper, InnerWrapper, ContentWrapper, ImpactDestinationsWrapper, LegendWrapper } from "./style"

var margin = { top: 10, right: 0, bottom: 20, left: 0 },
  width = "100%",
  height = 500

const drawScatter = (data, props) => {
  const wrapper = document.getElementById(props.type)

  const svg = d3
    .select("#scatter-chart")
    .append("svg")
    .attr("width", width)
    .attr("height", height + 30)
    .append("g")
    .attr("transform", "translate(" + margin.left + "," + margin.top + ")")

  const x = d3
    .scaleLog()
    .domain([
      d3.min(data, (d) => parseInt(d.Engagement)),
      d3.max(data, (d) => parseInt(d.Engagement)),
    ])
    .range([0, "90%"])

  const y = d3
    .scaleLog()
    .domain([
      d3.min(data, (d) => parseInt(d.Reach)),
      d3.max(data, (d) => parseInt(d.Reach)),
    ])
    .range([height, 0])

  const div = d3.select('div.tooltip')
  const group = svg.append("g")


  group
    .selectAll("dot")
    .data(data)
    .enter()
    .append("svg:image")
    // .attr("xlink:href", (d) => SOCIAL_IMAGE_MAP[`${d.Platform.toLowerCase()}${d?.Standout && '_standout'}`])
    .attr("xlink:href", (d) => {
      return SOCIAL_IMAGE_MAP[`${d.Platform.toLowerCase()}${d?.Standout ? '_standout' : ''}`]
    })
    .attr("x", function (d) {
      return x(parseInt(d.Engagement))
    })
    .attr("y", function (d) {
      return y(parseInt(d.Reach)) - 12
    })
    // .attr("width", "18px")
    .attr("height", "15px")
    .style("cursor", "pointer")
    .attr("id", function (d) { return `id_${x(parseInt(d.Engagement))}_${y(parseInt(d.Reach))}` })
    .on("click", onClick)

  group.selectAll("text")
    .data(data)
    .enter()
    .append("text")
    .text((d) => `${d.Name} ${d.NetSentiment ? `(${d.NetSentiment > 0 ? '+'+d.NetSentiment:d.NetSentiment}%)`: ''}`)
    .attr("x", function (d) {
      return x(parseInt(d.Engagement))
    })
    .attr("y", function (d) {
      return y(parseInt(d.Reach)) + 15
    })
    .attr("font-size", "12px")
    .attr("fill", (d) => (parseInt(d.NetSentiment) < 0 ? "red" : parseInt(d.NetSentiment) === 0 ? "black" : "green"))
    .style("cursor", "pointer")
    .on("click", onClick)

  function onClick(events, d){
    if( (!!d?.ConsumerCommentsAnalysis?.length) ){
      const position = {
        x : events.clientX - (window.scrollX + wrapper.getBoundingClientRect().left),
        y : events.clientY - (window.scrollY + wrapper.getBoundingClientRect().top),
      }
      if( (position.x+380) > wrapper.offsetWidth ){
        position.x = position.x - ((position.x+380) - wrapper.offsetWidth)
      }
      if( (position.y+450) > wrapper.offsetHeight ){
        position.y = position.y - ((position.y+450) - wrapper.offsetHeight)
      }
      
      const percent = d.NetSentiment > 0 ? 'positive' : 'negative'
      const addImage = d.Standout ? `<img src='${SOCIAL_IMAGE_MAP.standout}' alt='' />` : ''

      const content = `
      <div class='overlay' id='popupClose'></div>
        <div class='popup' style='top:${position.y}px; left:${position.x}px;'>
          <a class='close' href='javascript:' id='close'>
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1L9 9" stroke="#C3C3C3" stroke-width="1.5" stroke-linecap="round"/>
              <path d="M9 1L1 9" stroke="#C3C3C3" stroke-width="1.5" stroke-linecap="round"/>
            </svg>
          </a>
          <span>
            <img src='${SOCIAL_IMAGE_MAP[d.Platform.toLowerCase()]}' alt='${SOCIAL_IMAGE_MAP[d.Platform.toLowerCase()]}' />
            ${addImage}
          </span>
          <a class='link' target='_blank' href='${d?.URL}'>${d.Name}</a>
          <p class='percentage ${percent}'>${d.NetSentiment}%</p>
          <p class='description'>${d?.ConsumerCommentsAnalysis}</p>
          <p class='post'>Top Post</p>
          <p class='type ${d.ExpertPostSentiment}'><a class='link' target='_blank' href='${d?.ExpertPostURL || '#'}'>${d?.ExpertPostTitle}</a></p>
        </div>
      `
      // div.html(content).style("left", (d3.event.pageX) + "px").style("top", (d3.event.pageY) + "px").style("display", "block")
      div.html(content).style("display", "block")
      div.select('a#close').on('click', function () { div.html('') })
      div.select('#popupClose').on('click', function () { div.html('') })
    }
    else{
      window.open(`${d.URL}`, "_blank")
    }
  }
}

// const legends = [
//   { label: "Positive", value: "Positive" },
//   { label: "Neutral", value: "Neutral" },
//   { label: "Mixed", value: "Mixed" },
//   { label: "Negative", value: "Negative" },
//   { label: "Standout Impact Destination", value: "Standout" }
// ]

const ReportImpactDestination = (props) => {
  const [loading, setLoading] = useState(false)
  const [socialLegend, setSocialLegend] = useState([])
  const [state, setState] = useState([])
  const [legends, setLegends] = useState([
    { label: "Positive", value: "Positive" },
    { label: "Neutral", value: "Neutral" },
    { label: "Mixed", value: "Mixed" },
    { label: "Negative", value: "Negative" }
  ])
  const {updateSubLhsApiLoaded} = useUpdateLhs()
  const [eventCount, setEventCount] = useState(1)

  const getData = () => {
    setLoading(true)
    ReportsService.getImpactDestinationElement({
      insightsElementId: props.InsightsElementId
    })
      .then((resp) => {
        setState(resp)
        const objLegends = {}
        resp.forEach(element => {
          objLegends[element.Platform] = ""
        })
        setSocialLegend(Object.keys(objLegends))
        updateSubLhsApiLoaded(props)
        
        const standoutArray = resp.map(d => d.Standout).filter(f => f===true)
        if(standoutArray.length > 0){
          setLegends([...legends, { label: "Standout Impact Destination", value: "Standout" }])
        }

        setEventCount(p => p+1)
      })
      .catch((error) => {
        const resMessage = error
        console.log('ERROR : ', resMessage)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    getData()
  }, [])
  
  useEffect(() => {
    if(!loading && state.length) {
      drawScatter(state, props)
      setEventCount(p => p+1)
    }
  }, [loading, state])

  return (
    <>
      <ReportHeading resetInsightHeight={eventCount} downloadId={props.type} downloadIdData={state} {...props} />
      <ReportOuterWrapper>
        <OuterWrapper id={props.type}>
          <LoaderWrapper loading={loading} loadOnlyApi={props.loadOnlyApi}>
            <InnerWrapper>
              <ContentWrapper>
                <ImpactDestinationsWrapper
                  sx={{ marginLeft: "25px", marginBottom: "50px", minHeight: 500 }}
                  id="scatter-chart"
                >
                  <div className="tooltip"></div>
                  <div className="columnLine"><span>Reach</span></div>
                  <div className="rowLine"><span>Engagement</span></div>
                </ImpactDestinationsWrapper>
                <InfoRow />
                <LegendWrapper>
                  <Legend legends={legends} />
                  <SocialLegend legends={socialLegend} />
                </LegendWrapper>
              </ContentWrapper>
            </InnerWrapper>
          </LoaderWrapper>
        </OuterWrapper>
      </ReportOuterWrapper>
    </>
  )
}

export default ReportImpactDestination
