import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { LEGEND_TEXT_COLOR_MAP } from '../../constants'

export const ImpactDestinationsWrapper = styled(Box)(`
    border: 1px solid #7E7E7E;
    position: relative;
    // &::after {
    //     content: '';
    //     background: #7E7E7E;
    //     height: 1px;
    //     left: 0;
    //     right: 0;
    //     position: absolute;
    //     top: 50%;
    // }
    // &::before {
    //     content: '';
    //     background: #7E7E7E;
    //     width: 1px;
    //     left: 50%;
    //     position: absolute;
    //     top: 0;
    //     bottom: 0;
    // }

    & .tooltip .overlay{
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: rgba(0, 0, 0, 0.3);
      z-index:1;
    }

    & .tooltip .overlay.template1{
      background: rgba(225, 225, 225, 0.005);
    }

    & .columnLine{
        position: absolute;
        width: 1px;
        height: 100%;
        background: #7E7E7E;
        top: 0;
        left: -20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    & .columnLine:after{
        top: 4px;
    }

    & .columnLine span{
        white-space: nowrap;
        // margin-left: -108px;
        background: #fff;
        transform: rotate(-90deg);
        position: static;
        margin-left: -1px;
        padding: 0 10px;
    }

    & .tooltip .popup{
      position: absolute;
      width: 280px;
      min-height: 160px;
      left: calc(50% - 140px);
      top: calc(50% - 80px);

      // margin-left: -28px;
      // margin-top: -28px;
      margin-left: -74px;
      margin-top: -49px;

      background: #FFFFFF;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      border-radius: 2px;
      z-index:2;
      display:flex;
      flex-direction:column;
      padding:20px 25px 20px 15px;
    }

    & .tooltip .popup .close{
      position:absolute;
      right:10px;
      top:3px;
    }

    & .tooltip .popup .link{
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      text-decoration-line: underline;
      color: #095BBD;
    }

    & .tooltip .popup img{
      width:16px;
      height:16px;
    }

    & .tooltip .popup p{
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color#000;
    }

    & .tooltip .popup .percentage{
      color: #000;
      font-weight: 500;
      padding-top:10px;
    }

    & .tooltip .popup .description{
      padding:10px 0;
      font-weight:normal;
    }

    & .tooltip .popup .post{
      font-weight: 500;
    }

    & .tooltip .popup .type{
      font-size: 12px;
      line-height: 18px;
      color: #00B050;
      text-decoration:underline;

      &.Positive a{ color: ${LEGEND_TEXT_COLOR_MAP.Positive};}
      &.Negative a{ color: ${LEGEND_TEXT_COLOR_MAP.Negative};}
      &.Mixed a{ color: ${LEGEND_TEXT_COLOR_MAP.Mixed};}
      &.Neutral a{ color: ${LEGEND_TEXT_COLOR_MAP.Neutral};}
    }

    & .tooltip .popup .positive{
      color: ${LEGEND_TEXT_COLOR_MAP.Positive};
    }
    & .tooltip .popup .positive:before{
      content:'+';
    }
    & .tooltip .popup .negative{
      color: ${LEGEND_TEXT_COLOR_MAP.Negative};
    }

    & .tooltip .popup.template1 {
        
        & h5 {font-weight: 500; font-size: 12px; line-height: 18px; padding-bottom:8px; }
        & ul {display:flex; list-style:none; margin:0; color:#000; }
        & ul li {font-weight: 300; font-size: 12px; line-height: 18px; color:#000; width:25%;}
        & hr {margin:10px 0;}
    }
`)
