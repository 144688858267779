import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const BrandMagnetScoreWarningWrap = styled(Box)(`
    display: flex;
    flex-direction: column;
    width: 100%;
`)

export const BarChartsWrapper = styled(Box)(`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    ul {
        flex-direction: column;
        margin-bottom: 18px;
        & li {
            .barSlice{
                display: flex;
                align-items: center;

                .barSliceLabel{
                    padding-left: 10px;
                }
            }
        }
    }
`)

export const ChartRow = styled(Box)(`
    overflow-y:hidden;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    .barSliceEndIcon{
        display: flex;
        align-items: center;
    }
    & span {
        font-size:18px;
        font-weight:400;
    }
    & .barRoot{
        margin-bottom: 8px;
    }
`)

export const RowName = styled(Box)(`
    z-index: 1;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    min-width: 80px;
    width: 100%;
    padding-left: 80px;
    padding-right: 10px;
    font-weight: 500;
    font-size: 16px;
    line-height: calc(24 / 16);
`)

export const RowSubName = styled(Box)(`
    z-index: 1;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: end;
    // min-width: 80px;
    width: 80px;
    padding-right: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: calc(24 / 16);
`)

export const ProgressionStyling = styled(Box)(`
    display: flex;
    width: 100%;

    & .barRoot {
        width: calc(100% - 85px);
    }
`)
