import React from 'react'

const IconUser = ({fill="black", width=18, height=23}) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.3931 2.60695C18.869 6.14491 18.869 11.8551 15.3931 15.3931C11.8551 18.869 6.14491 18.869 2.60695 15.3931C-0.868982 11.8551 -0.868982 6.14491 2.60695 2.60695C6.14491 -0.868982 11.8551 -0.868982 15.3931 2.60695V2.60695ZM14.7724 3.22759C11.6069 0.0620357 6.39309 0.0620357 3.22766 3.22759C0.0621 6.39315 0.0621 11.6069 3.22766 14.7723C6.39321 17.9998 11.607 17.9998 14.7724 14.7723C17.9999 11.6068 17.9999 6.39302 14.7724 3.22759Z" fill="#D5732E"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M6.96573 6.3872C6.96573 7.34933 6.3158 8.12913 5.51417 8.12913C4.71243 8.12913 4.0625 7.34933 4.0625 6.3872C4.0625 5.4252 4.71243 4.64526 5.51417 4.64526C6.3158 4.64526 6.96573 5.4252 6.96573 6.3872Z" fill="#D5732E"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M13.9345 6.3872C13.9345 7.34933 13.2846 8.12913 12.4828 8.12913C11.6812 8.12913 11.0312 7.34933 11.0312 6.3872C11.0312 5.4252 11.6812 4.64526 12.4828 4.64526C13.2846 4.64526 13.9345 5.4252 13.9345 6.3872Z" fill="#D5732E"/>
      <path d="M12.7738 13.4782C12.8388 13.9643 11.9936 14.0857 11.9287 13.5998C11.7986 12.8707 11.4736 12.3239 10.9535 11.8986C9.84827 10.9873 8.15794 10.9873 7.05281 11.8986C6.53269 12.3238 6.2077 12.8707 6.07764 13.5998C6.01267 14.0858 5.16745 13.9643 5.23253 13.4782C5.36259 12.5669 5.81763 11.8379 6.53279 11.2911C7.96311 10.1367 10.0436 10.1367 11.4738 11.2911C12.189 11.8379 12.644 12.5669 12.7741 13.4782H12.7738Z" fill="#D5732E"/>
    </svg>
  )
}

export default IconUser