import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { Legend, LoaderWrapper, ReportHeading, MuiButton, ReportOuterWrapper } from '../../../../components'
import ReportsService from '../../../../services/reports.service'
import { useUpdateLhs } from '../../../../hooks/useUpdateLhs'
import ComparisonTable from './comparisonTable'
import { ContentWrap, RootWrap, MuiButtonWrap } from './style'
import { getObjectAsArray, exportExcel_addSegments } from '../../../../util'

const CONFIG = {
  "DriversProgression3Level": {
    showLegends: true,
    filterType: 'periods'
  },
  "SearchDriversProgression3Level": {
    type: "SearchKeywords",
    showLegends: false,
    filterType: 'periods'
  },
  "SegmentDriversOnlyAnalysis3Level": {
    showLegends: true,
    filterType: 'segments'
  },
  "DriversProgressionWithoutSentiments3Level": {
    type: "SearchKeywords",
    showLegends: false,
    filterType: 'periods'
  },
  "SegmentDriversOnlyAnalysisWithoutSentiments3Level": {
    type: "SearchKeywords",
    showLegends: false,
    filterType: 'segments'
  }
}

const ComparisonViewDeepDive = (props) => {
  const [state, setState] = useState([])
  const [segmentData, setSegmentData] = useState(null)
  const [reportData, setReportData] = useState([])
  const [maxValue, setMaxValue] = useState(100)
  const [loading, setLoading] = useState(false)
  const [segmentExcelData, setSegmentExcelData] = useState()
  const {updateSubLhsApiLoaded} = useUpdateLhs()
  const [legendsData, setLegendsData] = useState([
    { label: 'Positive', value: 'Positive' },
    { label: 'Neutral', value: 'Neutral' },
    { label: 'Mixed', value: 'Mixed' },
    { label: 'Negative', value: 'Negative' }
  ])
  const [expandedStatus, setExpandedStatus] = useState(false)
  const [eventCount, setEventCount] = useState(1)

  const getData = (dd, field = "SegmentDrivers", isInner = true) => {
    const obj = {};
    const percentArr = [];
    for (let index = 0; index < dd?.length; index++) {
      const element = dd[index];
      const driverElement = element?.[field]?.DriverElement;
      const drivers = Array.isArray(driverElement) ? driverElement : Object.keys((driverElement || {}))?.length ? [driverElement] : [];
      for (let j = 0; j < drivers.length; j++) {
        const innerElement = drivers[j];
        const key = innerElement.DriverName;

        const associationData = [{
          SearchKeywords: innerElement.Percent
        }];

        const multiBarData = [{
          Positive: innerElement.PositivePercent,
          Neutral: innerElement.NeutralPercent,
          Mixed: innerElement.MixedPercent,
          Negative: innerElement.NegativePercent
        }]

        const keyName = isInner ? innerElement.TargetSegment : element.PeriodName;

        obj[key] = {
          ...(obj[key] || {}),
          [keyName]: {
            ...innerElement,
            data: CONFIG[props.InsightsElementType]?.type ? associationData : multiBarData
          }
        };
        percentArr.push(innerElement.Percent);
      }
    }
    setMaxValue(Math.max(...percentArr))
    return obj;
  }
  
  const updateExcelData = (data) => {
    const arr = {drivers:[], attributes:[], subAttributes:[]}
    const objArr1 = Object.keys(data)
    for(let i=0; i<objArr1.length; i++){
      const name1 = objArr1[i]
      const objArr2 = Object.keys(data[name1])
      for(let j=0; j<objArr2.length; j++){
        const name2 = objArr2[j]
        const obj = data[name1][name2]
        arr.drivers.push([{value: name1}, {value: name2}, ...exportExcel_addSegments(obj, true)])
        const driverAttri = getObjectAsArray(obj?.DriverAttributes?.DriverAttributeElement || [])
        for(let k=0; k<driverAttri.length; k++){
          arr.attributes.push([{value: name1}, {value: name2}, {value: driverAttri[k].AttributeName}, ...exportExcel_addSegments(driverAttri[k], true)])
          let subDriverAttri = getObjectAsArray(driverAttri[k]?.DriverSubAttributes?.DriverSubAttributeElement || [])
          for(let m=0; m<subDriverAttri.length; m++){
            arr.subAttributes.push([{value: name1}, {value: name2}, {value: driverAttri[k].AttributeName}, {value: subDriverAttri[m].SubAttributeName}, ...exportExcel_addSegments(subDriverAttri[m], true)])
          }
        }
      }
    }

    setSegmentExcelData(arr)
  }

  const getDriverProgression = () => {
    setLoading(true)
    ReportsService.getDriverProgressionElement({ insightsElementId: props.InsightsElementId }).then(resp => {
      const sd = getData(resp, "PeriodDrivers", false)
      setSegmentData(sd)
      updateExcelData(sd)

      const dd = resp.map(item => ({
        ...item,
        SegmentDrivers: item.PeriodDrivers
      }))
      setReportData(dd)
      updateSubLhsApiLoaded(props)
      setEventCount(p => p+1)
    }).catch(error => (
      console.log(error)
    )).finally(() => {
      setLoading(false)
    })
  }

  const getSegment = () => {
    setLoading(true)
    ReportsService.GetSegmentDriversAnalysisWithDeepDiveElement({ insightsElementId: props.InsightsElementId }).then(resp => {
      const averageStarRating = resp.map(item => {
        return ({
          SegmentType: item.SegmentType,
          SegmentName: item.SegmentName,
          SegmentMaxValue: item.SegmentMaxValue,
          SegmentValue: item.SegmentValue,
          type: 'Association',
          data: [{
            "0": item.SegmentValue,
          }]
        })
      })
      setState([{
        SegmentType: averageStarRating[0]?.SegmentType,
        data: averageStarRating
      }])

      
      const sd = getData(resp)
      setSegmentData(sd)
      updateExcelData(sd)

      setReportData(resp)
      updateSubLhsApiLoaded(props)
      setEventCount(p => p+1)
    }).catch(error => {
      console.log(error)
    }).finally(() => {
      setLoading(false)
    })
  }

  const expandCollapseFunc = (flag) => {
    setExpandedStatus(flag)
  }

  useEffect(() => {
    if (props.isDriversProgression) {
      getDriverProgression()
    } else {
      getSegment()
    }
  }, [])

  useEffect(() => {
    if (props?.driversSegmentsExpanded) {
      setExpandedStatus(true)
    }
  }, [props?.driversSegmentsExpanded])

  useEffect(() => {
    setEventCount(p => p+1)
  }, [state, segmentData, expandedStatus])

  const AdditionalButtons = ({ expandedStatus, expandCollapseFunc, expanded }) => {
    const eColor = expandedStatus && { color: '#095BBD' }
    const cColor = expandedStatus === false && { color: '#095BBD' }
    return (
      <>
        <MuiButtonWrap className='BtnExpandAllDrivers'><MuiButton variant='standard' color='primary' sx={eColor} onClick={() => expandCollapseFunc(true)}>Expand All</MuiButton></MuiButtonWrap>
        <span style={{ lineHeight: 2 }}>|</span>
        <MuiButtonWrap className='BtnCollapseAllDrivers'><MuiButton variant='standard' color='primary' sx={cColor} onClick={() => expandCollapseFunc(false)}>Collapse All</MuiButton></MuiButtonWrap>
        <span style={{lineHeight:2}}>|</span>
      </>
    )
  }

  return (
    <>
      <ReportHeading resetInsightHeight={eventCount} downloadId={props.type} {...props} additionalButton={<AdditionalButtons expandedStatus={expandedStatus} expandCollapseFunc={expandCollapseFunc} expanded={expandedStatus} />} downloadIdData={segmentExcelData} addTable={true} />
      <ReportOuterWrapper>
        <RootWrap id={props.type} className={`${props.InsightsElementType}`}>
          <LoaderWrapper loading={loading} loadOnlyApi={props.loadOnlyApi}>
            {
              !!Object?.keys(segmentData || {})?.length &&
              <>
                <ContentWrap className='segmentWrapper'>
                  <ComparisonTable
                    // keyOrdering={reportData.map(d => ({PeriodName: d.PeriodName, PeriodOrder: d.PeriodOrder}))}
                    keyOrdering={reportData.map(d => (d?.PeriodName || d?.SegmentName))}
                    isSearchDrivers={props.isSearchDrivers}
                    maxValue={maxValue}
                    data={state}
                    segmentData={segmentData}
                    type={CONFIG[props.InsightsElementType]?.type}
                    filterType={CONFIG[props.InsightsElementType]?.filterType}
                    insightsElementType={props.InsightsElementType}
                    expanded={expandedStatus}
                    filterUpdated={() => setEventCount(p => p+1)}
                  />
                </ContentWrap>
                {
                  CONFIG[props?.InsightsElementType]?.showLegends && 
                  <Box sx={{ display: 'flex', justifyContent: 'end', marginTop: '30px'}}>
                    <Legend legends={legendsData} />
                  </Box>
                }
              </>
            }
          </LoaderWrapper>
        </RootWrap>
      </ReportOuterWrapper>
    </>
  )
}

export default ComparisonViewDeepDive