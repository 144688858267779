import React from 'react'
const IconDownload = ({color = '#095BBD'}) => {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.75 11.0835H12.25V12.2502H1.75V11.0835ZM7.58333 5.25016H11.6667L7 9.91683L2.33333 5.25016H6.41667V0.583496H7.58333V5.25016Z" fill={color}/>
        </svg>
    )
}

export default IconDownload
