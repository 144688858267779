import React, { useState, useEffect, useRef } from 'react'
import PostLoginReports from '../../layout/postLoginReports'

import { Loader, IsLocalHost, TourGuide } from '../../components'
import { removeSpecialCharFromLhs } from '../../util'
import { EXPORT_PPT } from '../../constants'
import { IconClose } from '../../assets/images/icons'

import {
  ReportStudySetup1, ReportStudySetup2, ReportBusiness, ReportGenerative, ReportResearch, ReportBrandImagery,
  ReportLandScape, ReportDeepDive, ReportDeepDiveNew, ReportRecommendations, ReportCustomImage, ReportKeyTakeaways,
  ReportImpactDestination, ReportBrandAttributeMapElement, ReportFactorInterLinkage, ReportBrandImageryGraph, ReportBrandImageryGraphLevel2, ReportBrandImageryGraphLevel2AndProgressionGraph, ReportsCampaignOverview, ReportAura,
  ReportBrandHealthOverview, ReportBrandHealthOverviewAndProgression, ReportSandbox, ReportBrandMagnetScore, ReportBrandMagnetScoreAndProgression, ReportBuzzMetrics, ReportCampaignOverview, ReportSegment,
  RelativeBrandsAnalysis, BuyerMaturityByAge, BuyerMaturityByAgeProgression, CampaignSegmentsEngagementOverview, CampaignSegmentsEngagementProgression, CompetitionConsideration, ReportMining, ReportSegmentAndSegmentOfSegment, MultiBrandNPS,
  ReportVerbatim, ReportTabsPptSlide, ReportCategoryPersona, ComparisonViewDeepDive, ReportTriangulation
} from './components'

import { ContentInnerWrapper, ContentWrapper, PptLoadingStatus } from './style'
import { useGlobalStore } from '../../store'


const Reports = () => {
  const observerRef = useRef(null)
  const { subLhs, loadAllLhs, setLoadAllLhs, pptSubLhs, pptSubLhsAppendices, pptSubLhsProgression, isPPTModalActive, isAskIaActiveState, setAskIaActiveState, pptIdsType } = useGlobalStore()
  const [lhsLoading, setLhsLoading] = useState(false)
  const [subLhsData, setSubLhsData] = useState([])

  const isAskIaActiveStateObj = isAskIaActiveState.split('___')

  useEffect(() => {
    const timeout = setTimeout(() => {
      const elements = document.querySelectorAll('.pageRoot')
      if (window.IntersectionObserver) {
        observerRef.current = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                const elms = document.querySelectorAll('.l2TabLink')
                elms.forEach(els => els.classList.remove('active'))
                const el = document.querySelector(`.id_${entry.target.id}`)
                if (el) {
                  el.classList.add('active')
                }
              }
            })
          },
          { rootMargin: '0px 0px -80% 0px' }
        )
        elements.forEach((section) => {
          observerRef.current.observe(section)
        })
      }
    }, 500);
    return () => {
      clearTimeout(timeout)
    }
  }, [subLhs])

  const onChangeHandler = (param) => {
    setLhsLoading(param.lhsLoading)
  }

  useEffect(() => {
    if(!!subLhs?.length && !!loadAllLhs?.length && !lhsLoading){
      setTimeout(() => {
        setLoadAllLhs([])
      })
    }
  },[subLhs, loadAllLhs, lhsLoading, setLoadAllLhs])

  useEffect(() => {
    if(!!subLhs?.length){
      setSubLhsData([])
    }
  },[subLhs])

  useEffect(() => {
    if(!!subLhs?.length && subLhsData?.length === 0){
      setSubLhsData(subLhs)
    }
  },[subLhsData, subLhs])

  return (
    <PostLoginReports onChange={onChangeHandler}>
      {subLhsData?.length === 0 && <Loader />}

      {isAskIaActiveState === 'active' && <ReportGenerative type={'InsightsElementTypeQnA'} />}

      {(pptSubLhs.length + pptSubLhsAppendices.length + pptSubLhsProgression.length === 0) && <>
        {isAskIaActiveState === 'none' && !!subLhsData?.length && !lhsLoading && <ReportComponents isPPTModalActive={isPPTModalActive} pptSubLhs={subLhs} subLhs={subLhsData} lhsLoading={lhsLoading} loadOnlyApi={false} key={0} />}
        {
          !(['none','active'].includes(isAskIaActiveState)) && 
          <ReportComponents isAskIaActiveState={isAskIaActiveState} isPPTModalActive={false} pptSubLhs={subLhs} subLhs={[{InsightsElementId:isAskIaActiveStateObj[0], InsightsElementType:isAskIaActiveStateObj[1], Title:isAskIaActiveStateObj[2], EntityId:isAskIaActiveStateObj[3]}]} lhsLoading={lhsLoading} loadOnlyApi={false} key={0} />
        }
      </>}

      {pptSubLhs.length>0 && <>
        {isAskIaActiveState === 'none' && !!subLhsData?.length && !lhsLoading && <ReportComponents isPPTModalActive={isPPTModalActive} pptSubLhs={pptSubLhs} subLhs={subLhsData} lhsLoading={lhsLoading} loadOnlyApi={false} key={0} />}
        {
          !(['none','active'].includes(isAskIaActiveState)) && 
          <ReportComponents isAskIaActiveState={isAskIaActiveState} isPPTModalActive={false} pptSubLhs={pptSubLhs} subLhs={[{InsightsElementId:isAskIaActiveStateObj[0], InsightsElementType:isAskIaActiveStateObj[1], Title:isAskIaActiveStateObj[2], EntityId:isAskIaActiveStateObj[3]}]} lhsLoading={lhsLoading} loadOnlyApi={false} key={0} />
        }
      </>}

      {pptSubLhsAppendices.length>0 && <>
        {isAskIaActiveState === 'none' && !!subLhsData?.length && !lhsLoading && <ReportComponents isPPTModalActive={isPPTModalActive} pptSubLhs={pptSubLhsAppendices} subLhs={subLhsData} lhsLoading={lhsLoading} loadOnlyApi={false} key={0} />}
        {
          !(['none','active'].includes(isAskIaActiveState)) && 
          <ReportComponents isAskIaActiveState={isAskIaActiveState} isPPTModalActive={false} pptSubLhs={pptSubLhsAppendices} subLhs={[{InsightsElementId:isAskIaActiveStateObj[0], InsightsElementType:isAskIaActiveStateObj[1], Title:isAskIaActiveStateObj[2], EntityId:isAskIaActiveStateObj[3]}]} lhsLoading={lhsLoading} loadOnlyApi={false} key={0} />
        }
      </>}

      {pptSubLhsProgression.length>0 && <>
        {isAskIaActiveState === 'none' && !!subLhsData?.length && !lhsLoading && <ReportComponents isPPTModalActive={isPPTModalActive} pptSubLhs={pptSubLhsProgression} subLhs={subLhsData} lhsLoading={lhsLoading} loadOnlyApi={false} key={0} />}
        {
          !(['none','active'].includes(isAskIaActiveState)) && 
          <ReportComponents isAskIaActiveState={isAskIaActiveState} isPPTModalActive={false} pptSubLhs={pptSubLhsProgression} subLhs={[{InsightsElementId:isAskIaActiveStateObj[0], InsightsElementType:isAskIaActiveStateObj[1], Title:isAskIaActiveStateObj[2], EntityId:isAskIaActiveStateObj[3]}]} lhsLoading={lhsLoading} loadOnlyApi={false} key={0} />
        }
      </>}

      <IsLocalHost type='localStorage_dev_true'><PptLoadingStatus id='pptLoadingStatus'>Component loaded(<strong>0</strong>) :---<br /><ol></ol><br />Images Loaded(<b>0</b>) <br /><span></span><div id='timeStartEnd'></div></PptLoadingStatus></IsLocalHost>
    </PostLoginReports>
  )
}

const ReportComponents = (({ subLhs, pptSubLhs, isPPTModalActive, lhsLoading, loadOnlyApi }) => {
  const { isTourGuideActive, isAskIaActiveState, setAskIaActiveState, isPPTModalActiveAndClicked } = useGlobalStore()
  const [minHt, setMinHt] = useState()
  let previousTabId = 0
  const driversExpanded = false // NOTE: IN DOWNLOAD PPT > THIS WILL KEEP THE DRIVERS EXPANDED ..

  const onlyOneComponent = subLhs.length === 1 ? 'onlyOneComponent' : ''
  const askIaDetailPage = isAskIaActiveState !== 'none' ? 'askIaDetailPage' : ''
  const isAppendicesArray = []
  const isProgressionArray = []

  const closeAskIaDetailPage = () => {
    setAskIaActiveState('active')
  }

  const reorderingForExportToPptFunc = (list) => {
    const deepDiveProgression = EXPORT_PPT.deepDiveSegmentsProgression
    const appendices = list.filter(a => !deepDiveProgression.includes(a.InsightsElementTypeAppendices))
    const progression = list.filter(a => deepDiveProgression.includes(a.InsightsElementTypeProgression))
    return [...appendices, ...progression]
  }

  ////////////////////////////////////////////////////
  ////////////////////////////////////////////////////
  /* */
  const rhsSticky = (wrapper, stickyEl) => {
    const wrapperRect = wrapper.getBoundingClientRect();
    const stickyRect = stickyEl?.getBoundingClientRect() || null;
    const windowHeight = window.innerHeight; // 59

    if(isNaN(stickyRect)){
      if(wrapperRect.height > windowHeight - 140) {
        if (wrapperRect.bottom < windowHeight) {
          stickyEl.classList.add('bottom');
          stickyEl.classList.remove('fixed');
        } else if (wrapperRect.top < -40) {
          stickyEl.classList.add('fixed');
          stickyEl.classList.remove('bottom');
        } else if ((stickyRect.top - 45) <= wrapperRect.top) {
          stickyEl.classList.remove('fixed');
          stickyEl.classList.remove('bottom');
        }
      }
    }
  }

  const addingOnScrollFunc = () => {
    const moveInsightsToRight = document.querySelectorAll('.moveInsightsToRight')
    for(let i=0; i<moveInsightsToRight.length; i++){
      const stickySidebar = moveInsightsToRight[i].querySelector('.stickySidebar')
      if(isNaN(stickySidebar)){
        rhsSticky(moveInsightsToRight[i], stickySidebar)
      }
    }
  }

  useEffect(() => {
    setTimeout(() => {
      document.querySelector('.rhsContent').addEventListener('scroll', addingOnScrollFunc);
    }, 5000)
  }, [])
  /* */
  ////////////////////////////////////////////////////
  ////////////////////////////////////////////////////

  return (
    <>
      {((isPPTModalActive && isPPTModalActiveAndClicked) ? reorderingForExportToPptFunc(pptSubLhs) : subLhs).map((reportList, i) => {
        const list = { ...reportList, ...{loadOnlyApi:loadOnlyApi, driversSegmentsExpanded:!!reportList?.InsightsElementTypeAppendices, isPPTModalActive:isPPTModalActive} }

        const isAppendices = (!!list?.InsightsElementTypeAppendices) ? 'Appendices__' : ''
        if(isAppendices) isAppendicesArray.push(list)
        const isAppendicesStatus = isAppendicesArray?.[0]?.InsightsElementId === list?.InsightsElementId
        list.isAppendicesActive = isAppendices

        const isProgression = (!!list?.InsightsElementTypeProgression) ? 'Progression__' : ''
        if(isProgression) isProgressionArray.push(list)
        const isProgressionStatus = isProgressionArray?.[0]?.InsightsElementId === list?.InsightsElementId
        list.isProgressionActive = isProgression
      
        const listType = `${isAppendices}${isProgression}${list.InsightsElementType}__${list.InsightsElementId.replaceAll('-', '')}__${i}`
        const tabIdStatus = (previousTabId !== list.TabId) ? 'new' : 'old'

        if(previousTabId !== list.TabId){previousTabId = (list.TabId)}
        const lastPageSection = (i === (subLhs.length -1) && !isPPTModalActive) ? 'lastPageSection' : ''

        const className_moveInsightsToRight = ['BrandHealthSetup', 'CategoryExplorationSetup', 'ProductRatingOptimizationSetup'].includes(list.InsightsElementType) ? 'moveInsightsToLeft' : 'moveInsightsToRight'
        list.moveInsightsTo = className_moveInsightsToRight

        // if(isPPTModalActive && isPPTModalActiveAndClicked){
        //   if(!['ResearchDesign', 'BusinessObjective', 'CustomImage', 'Recommendation'].includes(list.InsightsElementType)){
        //     return false
        //   }
        // }

        return (
          // <ContentInnerWrapper className={`reportOuterWrapper pageRoot ${lastPageSection} ${onlyOneComponent} ${isPPTModalActive && 'isPPTModalActive'}`} style={minHt} key={i} id={`id_${list.Title.replaceAll(" ", "").replaceAll(":", "").replaceAll(".", "").replaceAll("/", "")}`}>
          <ContentInnerWrapper className={`reportOuterWrapper pageRoot ${lastPageSection} ${askIaDetailPage} ${onlyOneComponent} ${isPPTModalActive && 'isPPTModalActive'}`} style={minHt} key={i} id={`id_${removeSpecialCharFromLhs(list.Title)}_${(list.InsightsElementId).replaceAll('-','')}`}>

            {/* {!(['none','active'].includes(isAskIaActiveState)) && <IconCloseWrapper onClick={() => closeAskIaDetailPage()}><IconClose /></IconCloseWrapper>} */}
            {lhsLoading && <ContentWrapper sx={{ minHeight: '600px' }}><Loader /></ContentWrapper>}

            {!lhsLoading && <ContentWrapper isPPTModalActive={isPPTModalActive} className={className_moveInsightsToRight}>

              {list.InsightsElementType !== 'QnA' && <IsLocalHost>
                <div><b>InsightsElementType = {list.InsightsElementType}</b></div>
                <div><b>InsightsElementId = {list.InsightsElementId}</b></div>
              </IsLocalHost>}

              {/* {list.InsightsElementType !== 'QnA' && <>
                <div><b>InsightsElementType = {list.InsightsElementType}</b></div>
              </>} */}

              {/* {list.InsightsElementType !== 'QnA' && <b>InsightsElementType = {list.InsightsElementType}</b>} */}
              {isAppendicesStatus && isPPTModalActive && <ReportTabsPptSlide type={`reportAppendices__${listType}`} {...list} slideType='appendices' />}
              {isProgressionStatus && isPPTModalActive && <ReportTabsPptSlide type={`reportProgression__${listType}`} {...list} slideType='progression' />}
              {/* {tabIdStatus === 'new' && isPPTModalActive && <ReportTabsPptSlide type={`reportTabs__${listType}`} {...list} slideType='tabs' />} */}
              
              {list.InsightsElementType === 'FactorInterlinkage' && <ReportFactorInterLinkage type={listType} {...list} />}

              {list.InsightsElementType === 'ImpactDestinations' && <ReportImpactDestination type={listType} {...list} />}

              {list.InsightsElementType === 'AllRelativeBrandsAnalysis20Colors' && <RelativeBrandsAnalysis isExtraColor type={listType} {...list} />}

              {list.InsightsElementType === 'SegmentOfSegmentOverview20Colors' && <BuyerMaturityByAge isExtraColor type={listType} {...list} />}
              {list.InsightsElementType === 'BusinessObjective' && <ReportBusiness type={listType} {...list} />}
              {list.InsightsElementType === 'ResearchDesign' && <ReportResearch type={listType} {...list} />}

              {list.InsightsElementType === 'CampaignAndIssuesAssessmentSetup' && <ReportStudySetup2 type={listType} {...list} />}

              {/* {list.InsightsElementType === 'Aura' && <ReportAura type={listType} {...list} />}
              {list.InsightsElementType === 'AuraWithoutSentiments' && <ReportAura type={listType} {...list} />} */}
              {
                [
                  'Aura', 'AuraWithoutSentiments'
                ].includes(list.InsightsElementType) && <ReportAura type={listType} {...list} />
              }

              {/* {list.InsightsElementType === 'BrandHealthSetup' && <ReportStudySetup1 type={listType} {...list} />}
              {list.InsightsElementType === 'CategoryExplorationSetup' && <ReportStudySetup1 type={listType} {...list} />}
              {list.InsightsElementType === 'ProductRatingOptimizationSetup' && <ReportStudySetup1 type={listType} {...list} />} */}
              {
                [
                  'BrandHealthSetup', 'CategoryExplorationSetup', 'ProductRatingOptimizationSetup'
                ].includes(list.InsightsElementType) && <ReportStudySetup1 type={listType} {...list} />
              }

              {/* {list.InsightsElementType === 'BrandImageryMappingAndProgressionGraph' && <ReportBrandImageryGraphLevel2AndProgressionGraph type={listType} {...list} />}
              {list.InsightsElementType === 'BrandImageryMappingGraph' && <ReportBrandImageryGraphLevel2AndProgressionGraph type={listType} {...list} />} */}
              {
                [
                  'BrandImageryMappingAndProgressionGraph', 'BrandImageryMappingGraph'
                ].includes(list.InsightsElementType) && <ReportBrandImageryGraphLevel2AndProgressionGraph type={listType} {...list} />
              }

              {list.InsightsElementType === 'CampaignTriggers' && <ReportsCampaignOverview type={listType} {...list} />}

              {list.InsightsElementType === 'Sandbox' && <ReportSandbox type={listType} {...list} />}

              {list.InsightsElementType === 'Recommendation' && <ReportRecommendations type={listType} {...list} />}

              {/* {list.InsightsElementType === 'SearchDrivers' && <ReportDeepDiveNew type={listType} {...list} />}
              {list.InsightsElementType === 'Drivers' && <ReportDeepDiveNew type={listType} {...list} />}
              {list.InsightsElementType === 'DriversAndSubBrands' && <ReportDeepDiveNew type={listType} {...list} />}
              {list.InsightsElementType === 'DriversAndTriggers' && <ReportDeepDiveNew type={listType} {...list} />}
              {list.InsightsElementType === 'DriversAndRatingImpacts' && <ReportDeepDiveNew type={listType} {...list} />}
              {list.InsightsElementType === 'DriversWithoutSentiments' && <ReportDeepDiveNew type={listType} {...list} />} */}
              {
                [
                  'SearchDrivers', 'Drivers', 'DriversAndSubBrands', 'DriversWithoutSentimentsAndSubBrands', 'DriversAndTriggers', 'DriversAndRatingImpacts', 'DriversWithoutSentiments'
                ].includes(list.InsightsElementType) && <ReportDeepDiveNew type={listType} {...list} />
              }

              {/* {list.InsightsElementType === 'DriversMotivatorsDetractors' && <ReportDeepDiveNew motivatorsDetractors={true} type={listType} {...list} />}
              {list.InsightsElementType === 'DriversAndSubBrandsMotivatorsDetractors' && <ReportDeepDiveNew motivatorsDetractors={true} type={listType} {...list} />}
              {list.InsightsElementType === 'DriversAndTriggersMotivatorsDetractors' && <ReportDeepDiveNew motivatorsDetractors={true} type={listType} {...list} />}
              {list.InsightsElementType === 'DriversAndRatingImpactsMotivatorsDetractors' && <ReportDeepDiveNew motivatorsDetractors={true} type={listType} {...list} />} */}
              {
                [
                  'DriversMotivatorsDetractors', 'DriversAndSubBrandsMotivatorsDetractors', 
                  'DriversAndTriggersMotivatorsDetractors', 'DriversAndRatingImpactsMotivatorsDetractors'
                ].includes(list.InsightsElementType) && <ReportDeepDiveNew motivatorsDetractors={true} type={listType} {...list} />
              }

              {/* {list.InsightsElementType === 'BrandMagnetScore' && <ReportBrandMagnetScoreAndProgression type={listType} {...list} />}
              {list.InsightsElementType === 'BrandMagnetScoreAndProgression' && <ReportBrandMagnetScoreAndProgression type={listType} {...list} />} */}
              {
                [
                  'BrandMagnetScore', 'BrandMagnetScoreAndProgression', 
                ].includes(list.InsightsElementType) && <ReportBrandMagnetScoreAndProgression type={listType} {...list} />
              }

              {list.InsightsElementType === 'CompetitiveLandscape' && <ReportLandScape type={listType} {...list} />}
              {list.InsightsElementType === 'BrandImageryMappingTable' && <ReportBrandImagery type={listType} {...list} />}

              {list.InsightsElementType === 'ReportKeyTakeaways' && <ReportKeyTakeaways type={listType} {...list} />}
              {list.InsightsElementType === 'BrandCategoryOverview' && <ReportBrandHealthOverviewAndProgression type={listType} {...list} />}
              {list.InsightsElementType === 'BrandCategoryOverviewAndProgression' && <ReportBrandHealthOverviewAndProgression type={listType} {...list} />}

              {list.InsightsElementType === 'BuzzMetrics' && <ReportBuzzMetrics type={listType} {...list} />}
              {list.InsightsElementType === 'ReportCampaignOverview' && <ReportCampaignOverview type={listType} {...list} />}

              {list.InsightsElementType === 'AllRelativeBrandsAnalysis' && <RelativeBrandsAnalysis type={listType} {...list} />}
              {list.InsightsElementType === 'SegmentOverview' && <RelativeBrandsAnalysis type={listType} {...list} isExtraColor />}

              {list.InsightsElementType === 'SegmentDriversAnalysis' && <ReportSegment type={listType} {...list} />}
              {list.InsightsElementType === 'TopRelativeBrandsAnalysis' && <CompetitionConsideration type={listType} {...list} />}

              {list.InsightsElementType === 'SegmentOfSegmentOverview' && <BuyerMaturityByAgeProgression type={listType} {...list} />}
              {list.InsightsElementType === 'SegmentOfSegmentOverviewAndProgression' && <BuyerMaturityByAgeProgression type={listType} {...list} />}

              {list.InsightsElementType === 'CampaignSegmentsEngagementOverview' && <CampaignSegmentsEngagementOverview type={listType} {...list} />}
              {list.InsightsElementType === 'CampaignSegmentsEngagementProgression' && <CampaignSegmentsEngagementProgression type={listType} {...list} />}
              {list.InsightsElementType === 'InsightsMiningFrameworkWithSentiments' && <ReportMining type={listType} {...list} />}
              {list.InsightsElementType === 'InsightsMiningFrameworkWithoutSentiments' && <ReportMining type={listType} {...list} />}
              {list.InsightsElementType === 'SegmentAndSegmentOfSegment' && <ReportSegmentAndSegmentOfSegment type={listType} {...list} />}

              {list.InsightsElementType === 'DriversProgression' && <ReportSegment isDriversProgression hideTopBlueBar type={listType} {...list} />}
              {list.InsightsElementType === 'SearchDriversProgression' && <ReportSegment hideTopBlueBar isDriversProgression isSearchDrivers type={listType} {...list} />}

              {list.InsightsElementType === 'DriversProgressionWithoutSentiments' && <ReportSegment hideTopBlueBar isDriversProgression type={listType} {...list} />}
              {list.InsightsElementType === 'SegmentDriversOnlyAnalysisWithoutSentiments' && <ReportSegment hideTopBlueBar type={listType} {...list} />}

              {list.InsightsElementType === 'DriversProgressionWithoutSentiments3Level' && <ComparisonViewDeepDive hideTopBlueBar isDriversProgression type={listType} {...list} />} 
              {list.InsightsElementType === 'SegmentDriversOnlyAnalysisWithoutSentiments3Level' && <ComparisonViewDeepDive hideTopBlueBar type={listType} {...list} />}

              {list.InsightsElementType === 'Triangulation' && <ReportSegment isDriversProgression hideTopBlueBar type={listType} {...list} />}
              {list.InsightsElementType === 'TriangulationComparisonViewOnly' && <ReportSegment isDriversProgression hideTopBlueBar type={listType} {...list} />}
              
              {list.InsightsElementType === 'CustomImage' && <ReportCustomImage type={listType} {...list} />}

              {list.InsightsElementType === 'SegmentDriversOnlyAnalysis' && <ReportSegment hideTopBlueBar type={listType} {...list} />}

              {list.InsightsElementType === 'MultiBrandNPS' && <MultiBrandNPS type={listType} {...list} />}

              {list.InsightsElementType === 'CategoryPersona' && <ReportCategoryPersona type={listType} {...list} />}

              {list.InsightsElementType === 'DriversProgression3Level' && <ComparisonViewDeepDive isDriversProgression hideTopBlueBar type={listType} {...list} />}
              {list.InsightsElementType === 'SearchDriversProgression3Level' && <ComparisonViewDeepDive hideTopBlueBar isDriversProgression isSearchDrivers type={listType} {...list} />}
              {list.InsightsElementType === 'SegmentDriversOnlyAnalysis3Level' && <ComparisonViewDeepDive hideTopBlueBar type={listType} {...list} />}

              {
                [
                  'BrandAttributeMapElement', 'BrandAttributeMapAndProgression', 'TriangulationMapElement', 'TriangulationMapAndProgression'
                ].includes(list.InsightsElementType) && <ReportBrandAttributeMapElement type={listType} {...list} />
              }

            </ContentWrapper>}
            {isTourGuideActive && <TourGuide type='report' />}
          </ContentInnerWrapper>
        )
      })}
      
    </>
  )
})

export default Reports
