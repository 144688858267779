import React from 'react'

const IconLogoAskIa = () => {
  return (
    <svg width="76" height="32" viewBox="0 0 76 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="1" width="76" height="24" rx="2" fill="url(#paint0_linear_11319_5149)"/>
      <path d="M58.6028 7.134V19H56.2228V7.134H58.6028ZM68.2303 16.739H63.5043L62.7223 19H60.2233L64.4903 7.117H67.2613L71.5283 19H69.0123L68.2303 16.739ZM67.5843 14.835L65.8673 9.871L64.1502 14.835H67.5843Z" fill="white"/>
      <path d="M51 32V25H58L51 32Z" fill="#27AEF7"/>
      <rect x="1" y="2" width="50" height="22" rx="2" fill="white"/>
      <path d="M17.0458 16.872H12.5978L11.8618 19H9.50975L13.5258 7.816H16.1338L20.1498 19H17.7818L17.0458 16.872ZM16.4378 15.08L14.8218 10.408L13.2057 15.08H16.4378ZM25.5069 19.112C24.7282 19.112 24.0242 18.9787 23.3949 18.712C22.7762 18.4453 22.2855 18.0613 21.9229 17.56C21.5602 17.0587 21.3735 16.4667 21.3629 15.784H23.7629C23.7949 16.2427 23.9549 16.6053 24.2429 16.872C24.5415 17.1387 24.9469 17.272 25.4589 17.272C25.9815 17.272 26.3922 17.1493 26.6909 16.904C26.9895 16.648 27.1389 16.3173 27.1389 15.912C27.1389 15.5813 27.0375 15.3093 26.8349 15.096C26.6322 14.8827 26.3762 14.7173 26.0669 14.6C25.7682 14.472 25.3522 14.3333 24.8189 14.184C24.0935 13.9707 23.5015 13.7627 23.0429 13.56C22.5949 13.3467 22.2055 13.032 21.8749 12.616C21.5549 12.1893 21.3949 11.624 21.3949 10.92C21.3949 10.2587 21.5602 9.68267 21.8909 9.192C22.2215 8.70133 22.6855 8.328 23.2829 8.072C23.8802 7.80533 24.5629 7.672 25.3309 7.672C26.4829 7.672 27.4162 7.95467 28.1309 8.52C28.8562 9.07467 29.2562 9.85333 29.3309 10.856H26.8669C26.8455 10.472 26.6802 10.1573 26.3709 9.912C26.0722 9.656 25.6722 9.528 25.1709 9.528C24.7335 9.528 24.3815 9.64 24.1149 9.864C23.8589 10.088 23.7309 10.4133 23.7309 10.84C23.7309 11.1387 23.8269 11.3893 24.0189 11.592C24.2215 11.784 24.4669 11.944 24.7549 12.072C25.0535 12.1893 25.4695 12.328 26.0029 12.488C26.7282 12.7013 27.3202 12.9147 27.7789 13.128C28.2375 13.3413 28.6322 13.6613 28.9629 14.088C29.2935 14.5147 29.4589 15.0747 29.4589 15.768C29.4589 16.3653 29.3042 16.92 28.9949 17.432C28.6855 17.944 28.2322 18.3547 27.6349 18.664C27.0375 18.9627 26.3282 19.112 25.5069 19.112ZM37.7049 19L33.6409 14.024V19H31.4009V7.832H33.6409V12.84L37.7049 7.832H40.4089L35.8009 13.368L40.5369 19H37.7049Z" fill="#FF9900"/>
      <defs>
        <linearGradient id="paint0_linear_11319_5149" x1="38" y1="-11" x2="38" y2="29.5" gradientUnits="userSpaceOnUse">
        <stop stopColor="#9CDAFB"/>
        <stop offset="1" stopColor="#1AA9F6"/>
        </linearGradient>
      </defs>
    </svg>
  )
}

export default IconLogoAskIa