
// NOTE: THIS IS A BYPASS, DEVELOPED JUST FOR TESTING PURPOSE

import { getStudies, getAllInsightsElements, getInsightsElementsForTab } from './data'

const isTestingOnFlag = () => {
    // if( !['GetStudies', 'GetProjects'].includes(reqId) ){return false}

    const isTestingOn = JSON.parse(window.localStorage.getItem('testingMode') != 'test')
    const isTestUser = JSON.parse(window.localStorage.getItem('user')).UserEmail === 'sewantil@mavenmagnet.com'
    const isTestCompany = JSON.parse(window.localStorage.getItem('company')).CompanyLogo === 'testcompanyA.png'
    return (isTestingOn && isTestUser && isTestCompany)
}

const isTestingOn = (reqId, resp, payload={}) => {
    let data = resp
    if(isTestingOnFlag()){
        if ( reqId === 'GetProjects' ) {
            data = [
                ...resp,
                {
                    BrandName: "By Pass Project ..",
                    BusinessObjective: "",
                    CategoryName: "Auto",
                    CompanyId: "e0804536-45e9-11ed-bca4-0a1ea4c5532f",
                    Competitors: ['Honda', 'GM'],
                    CompetitorsOption: "NA",
                    CreatedDate: "2022-10-07T10:17:29",
                    DraftName: "",
                    IsDraft: false,
                    KeyQuestions: "",
                    MarketName: "USA",
                    ModifiedDate: "2022-10-07T10:17:29",
                    NoProjectEndDate: false,
                    ProjectCreatorEmailAddress: "adityag@mavenmagnet.com",
                    ProjectEndDate: "2024-07-01T00:00:00",
                    ProjectFrequency: "Biannual",
                    ProjectId: "3f70cc98-9999-1111-4444-000000000000",
                    ProjectStartDate: "2023-06-01T00:00:00",
                    ProjectType: "Custom",
                    TargetSegment: "",
                    details: []
                }
            ]
        } else if (reqId === 'GetStudies' && payload.projectId === '3f70cc98-9999-1111-4444-000000000000') {
            data = getStudies
        }
    }
    return data
}

const isTestingOnFunc = (reqId, resp2, payload) => {
    if (['GetStudies', 'GetProjects'].includes(reqId)) { return resp2 }
    
    if (reqId === 'GetAllInsightsElements') {
        resp2 = getAllInsightsElements
    }
    else if (reqId === 'GetInsightsElementsForTabResult') {
        resp2 = getInsightsElementsForTab(payload)
    }


    return resp2
}


export {
    isTestingOn,
    isTestingOnFlag,
    isTestingOnFunc
}