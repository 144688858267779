
import jsonToXml from '../util/jsonToXml'
import xml2js from 'xml2js';
import xmlToJson from './xmlToJson';

const soapEnvelopeAdding = (id, json, replaceMode='strict') => {
    const output_XML = jsonToXml([json], replaceMode)
    const soapData = `<?xml version="1.0" encoding="utf-8"?><soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/"><soap:Body><${id} xmlns="http://tempuri.org/">${output_XML?.split('?>')[1]}</${id}></soap:Body></soap:Envelope>`

    // console.log('||REQUEST|| SoapEnvelopeAdding 2 --> ', id, json, soapData)
    return soapData
}

const soapEnvelopeRemoving = async (id, response, type='object') => {
    // const responseXMLFormat0 = await response.replaceAll(' xmlns="http://tempuri.org/"','')?.split(`<${id}>`)[1].split(`</${id}>`)[0]
    const responseXMLFormat0 = await response.replaceAll(' xmlns="http://tempuri.org/"','')
    const responseXMLFormat1 = (responseXMLFormat0.indexOf(id) !== -1) ? (await responseXMLFormat0?.split(`<${id}>`)[1]?.split(`</${id}>`)[0]) : type

    if(responseXMLFormat1 === type) { return type==='object' ? {} : [] }

    return await xmlToJson(responseXMLFormat1, id, type)
}

export {soapEnvelopeAdding, soapEnvelopeRemoving}
