import { styled } from '@mui/material/styles'
import { Box, List } from '@mui/material'

export const Wrapper = styled(Box)(`
  position: relative;
  width:350px;
  & svg .recharts-radial-bar-background path {
    fill: #F4F8FF;
  }
  & svg .recharts-radial-bar-background path:last-child {
    fill: transparent;
  }
`)

export const LegendWrapper = styled(List)(`
  height:156px;
  width:50%;
  padding:0 4px 0 0;
  position:absolute;
  top:-24px;
  left:0;
  & li {
    font-weight: 400;
    font-size: 14px;
    line-height: 1;
    text-align:right;
    padding:1px 0;
    width:100%;
    display:block;
    display: flex;
    align-items: center;
    justify-content: end;
  }
`)
