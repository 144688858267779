import React from 'react'
import { Redirect, Route } from 'react-router-dom';
import { BlockingLoader } from '../components';
import { useGlobalStore } from '../store';

const ProtectedRoute = ({ component: Comp, path, ...rest }) => {

    const { isAuthenticated, isLoading } = useGlobalStore()
 
    if (isLoading) return <BlockingLoader />;

    return (
      <Route
        path={path}
        {...rest}
        render={(props) => {
          return isAuthenticated ? (
            <Comp {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/",
                state: {
                  prevLocation: path,
                  error: "You need to login first!",
                },
              }}
            />
          );
        }}
      />
    );
  };
export default ProtectedRoute