import React from 'react'
import styled from '@emotion/styled'
import { Box, Stack, Typography } from '@mui/material'
import {
  emojiGood,
  emojiAwesome,
  emojiHappy,
  emojiSad,
  EmojiGood,
  EmojiAwesome,
  EmojiHappy,
  EmojiSad
} from '../../assets/images/icons'
import MuiImage from '../muiImage'

const getEmoji = (number) => {
  if (number <= 20) {
    return emojiSad
  } else if (number <= 50) {
    return emojiGood
  } else if (number <= 80) {
    return emojiHappy
  } else {
    return emojiAwesome
  }
}

const StyledStack = styled(Stack)(`
    display: flex;
    align-items: center;
    & .emoji-item {
        display: flex;
        align-items: center;
        & .MuiTypography-root{
          font-size: 0.75rem;
        }
        & svg{
          margin-right: 6px
        }
      }
    }
`)

/**
 * @type "single" | "multi"
 * @number number between 0 - 100
 */
const EmojiRow = ({ number = 0, type = 'single' }) => {
  return type === 'single'
    ? (
      <MuiImage src={getEmoji(number)} width={22} />
      )
    : (
      <StyledStack flexWrap="wrap" gap="15px" className='emojiRow' direction='row'>
        <Box className='emoji-item'>
          {/* <MuiImage src={emojiSad} /> */}
          <EmojiSad />
          <Typography>0 - 20</Typography>
        </Box>
        <Box className='emoji-item'>
          {/* <MuiImage src={emojiGood} /> */}
          <EmojiGood />
          <Typography>21 - 50</Typography>
        </Box>
        <Box className='emoji-item'>
          {/* <MuiImage src={emojiHappy} /> */}
          <EmojiHappy />
          <Typography>51 - 80</Typography>
        </Box>
        <Box className='emoji-item'>
          {/* <MuiImage src={emojiAwesome} /> */}
          <EmojiAwesome />
          <Typography>81 - 100</Typography>
        </Box>
      </StyledStack>
      )
}

export default EmojiRow
