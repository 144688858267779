import { styled } from '@mui/material/styles'
import { Box, Menu, Typography, IconButton } from '@mui/material'
import { tableCss, ulLiCss } from '../../util'
import { MEDIA } from '../../constants'

export const HeadingOuterWrapper = styled(Box)(`
    display: flex;
    flex-direction: column;
    min-width:100%;
    padding-bottom: 20px;
    @media (max-width: ${MEDIA.lg}) {
        padding: 20px;
    }
    // border-bottom:2px blue solid;
`)

export const HeadingInnerWrapper = styled(Box)(`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`)

export const ContentWrapper = styled(Box)(`
    & b, & strong {
        font-weight: 500;
    }
    & p {
        padding-bottom:10px;
    }
    &.insightsExportToPPT {
        // WILL NOT BE USED ..
        .theadTr { background:#f1f1f1;}
        .theadTr td, .tbodyTr td { padding:10px; border:1px #E0E0E0 solid;}
        .tbodyTr td ul{ margin-left:10px;}
    }
`)

export const CardHeading = styled(Box)(`
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
    & img {
        cursor:pointer;
    }
    & .headingRte * {
        padding: 0;
    }
`)

export const ButtonBox = styled(Box)(`
    display:flex;
    white-space:nowrap;
    &.buttonList > span {
        display:flex;
    }
`)

export const CardRteContent = styled(Box)(`
    display: flex;
    // height: -webkit-fill-available;
    padding: 0;
    width: 100%;
    overflow-y: auto;
    flex-direction: column;

    & hr {
        display:none;
    }
    & ol, & ul {
        margin-left:15px;
    }
    & li {
        position:relative;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #000;
        line-height:18px;
        padding-bottom:5px;
    }
    
    & ul li ul {
        margin-top:5px;
    }

    & > ul > li:last-child:before {
        display:none;
    }

    ${tableCss};
    ${ulLiCss}
    
    & > ul > li {
        padding-bottom:10px;
    }

    &.slide {
        padding: 0 22px 0 13px;
        width: calc(100% + 20px);
    }
`)

export const Desktop = styled(Box)(`
    display:block;
    @media (max-width: 980px) {
        display:none;
    }
`)

export const Mobile = styled(Box)(`
    display:none;
    @media (max-width: 980px) {
        display:block;
    }
`)

export const SubHeading = styled(Typography)(`
    font-size:16px;
    font-weight:500;
`)

export const MenuWrapper = styled(Menu)(`
    display:none;
    & > ul {
        margin-right:10px !important;
    }
    @media (max-width: 980px) {
        display:block;
    }
    & .buttonList {
        padding:10px;
        display:flex;
        flex-direction:column;
    }
    & .buttonList > * {
        margin:5px 0;
        text-align:left;
        justify-content:start;
        width:100%;
    }
`)

export const IconArrowLeftWrapper = styled(Box)(`
    padding: 0 10px 0 5px;
    cursor: pointer;
`)

export const IconCloseOuterWrapper = styled(Box)(`
    position: absolute;
    z-index:1;
    top: 0;
    left: 0;
    width: 100%;
    height: 45px;
    display: flex;
    justify-content: end;
    align-items: center;
    background: #fff;  
`)

export const IconCloseWrapper = styled(Box)(`
    position: absolute;
    z-index:1;
    top: 10px;
    right: 15px;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    cursor: pointer;
`)

export const InsightsOuterWrapper = styled(Box)(`
    width: 100%;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    position: relative;
    padding-left:20px;
`)

export const InsightsInnerWrapper = styled(Box)(`
    width: 100%;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    // border-left: 1px #EEE solid;
    position: relative;
    overflow:hidden;
`)

const ButtonUpDownCss = `
    width: 100%;
    height: 40px;
    display: flex;
    border: 0;
    justify-content:center;
    align-items: center;
    cursor: pointer;
    background: #fff;
`

export const ButtonUp = styled('button')(`
    ${ButtonUpDownCss};
    // background: linear-gradient(180deg, #E1E1E1 0%, transparent 100%);
`)

export const ButtonDown = styled('button')(`
    ${ButtonUpDownCss};
    // background: linear-gradient(180deg, transparent 0%, #E1E1E1 100%);
`)

export const ButtonPlaceHolder = styled('div')(`
    ${ButtonUpDownCss};
    cursor: default;
`)

export const MenuIconButton = styled(IconButton)(`
  position: absolute;
  right: -10px;
  top: 2px;
  width: 30px;
  height: 30px;
  z-index: 1;
  transform: rotate(180deg);

  &.btnShow {
    right: 0;
    top: 5px;
  }
`)

export const SlideOverlay = styled(Box)(`
    width:100%;
    background: #fff;
    opacity: 0.2;
    height: calc(100% - 40px);
    display: block;
    position: absolute;
    top: 35px;
    left: 0;
    z-index: 1;
`)
