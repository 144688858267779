import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import { MEDIA } from '../../../../constants'

export const OuterWrapper = styled(Box)(`
    width:100%;
    &.exportToPPT {
        .barWrapper { min-height:200px; }
    }
`)

export const ContentWrapper = styled(Box)(`
    min-height:100px;
    min-width:100px;
    & img {
        max-width: calc(100%);
    }
    @media (max-width: ${MEDIA.lg}) {
        overflow: auto;
    }
`)
