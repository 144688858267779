import React, { useEffect } from 'react'
import { Switch, Route } from 'react-router-dom'

import { 
  Login, Sso, Authorize, ResetPassword, Projects, InitiateProjects, UserManagement, Resources, Reports
} from '../pages'

import AuthService from '../services/auth.service'
import EventBus from '../common/eventBus'

import { 
  ROUTE_LOGIN, ROUTE_AUTHORIZE, ROUTE_AUTHORIZE_AZURE, ROUTE_SSO, ROUTE_RESET_PASSWORD, ROUTE_PROJECTS, ROUTE_INITIATE_PROJECTS, ROUTE_USER_MANAGEMENT, ROUTE_RESOURCES, ROUTE_REPORTS
} from '../constants'
import ProtectedRoute from './protectedRoute'
import { useGlobalStore } from '../store'
import AuthorizeAzure from '../pages/authorizeAzure'

const BackOfficeRouter = props => {
  const {user} = useGlobalStore()
  useEffect(() => {
    if (!user?.isAuthenticated) {
      // console.log('Please login')
    }
    if (user) {
      // setCurrentUser(user)
      // setShowModeratorBoard(user.roles.includes('ROLE_MODERATOR'))
      // setShowAdminBoard(user.roles.includes('ROLE_ADMIN'))
    }
    const logOut = () => {
      AuthService.logout()
      // setShowModeratorBoard(false)
      // setShowAdminBoard(false)
      // setCurrentUser(undefined)
    }

    EventBus.on('logout', () => {
      logOut()
    })


    return () => {
      EventBus.remove('logout')
    }
  }, [])
  
  return (
    <Switch>
      <Route exact path={['/', ROUTE_LOGIN]} component={Login} />
      <Route exact path={ROUTE_SSO} component={Sso} />
      <Route exact path={ROUTE_RESET_PASSWORD} component={ResetPassword} />
      <Route exact path={ROUTE_AUTHORIZE} component={Login} />
      <Route exact path={`${ROUTE_AUTHORIZE}/:companyId`} component={Authorize} />
      <Route exact path={ROUTE_AUTHORIZE_AZURE} component={AuthorizeAzure} />

      <ProtectedRoute exact path={ROUTE_PROJECTS} component={Projects} />
      <ProtectedRoute path={ROUTE_INITIATE_PROJECTS} component={InitiateProjects} />
      <ProtectedRoute exact path={ROUTE_USER_MANAGEMENT} component={UserManagement} />
      <ProtectedRoute exact path={ROUTE_RESOURCES} component={Resources} />
      {/* <ProtectedRoute exact path={ROUTE_HELP} component={Help} /> */}

      <ProtectedRoute exact path={`${ROUTE_REPORTS}/:tabName/:id`} component={Reports} />
    
      <Route path='**' component={Login} />
    </Switch>
  )
}

export default BackOfficeRouter



// https://insightsassistant.mavenmagnet.com/authorize/19?id_token=jwtToken
// https://insightsassistant.mavenmagnet.com/authorize/15a8a391-a31b-11ed-817c-0a1ea4c5532f

// const module = await import('/modules/myCustomModule.js');

// import React, {lazy} from "react";
// const Login = lazy(()=> import("Pages/Login"));




