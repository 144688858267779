import React, { useState, useRef, useEffect } from 'react'
import { Box, IconButton } from '@mui/material'
import { useParams } from "react-router-dom";
import { useFormik } from 'formik'
import GenerativeService from '../../../../../services/generative.service'
import { MuiTextField, MuiButton, Modal } from '../../../../../components'
import { IconEdit } from '../../../../../assets/images/icons'
import { InputWrap, LoginButton, Title, Wrapper, ButtonWrapper, SuggestionWrapper, SuggestionInputWrapper, SuggestionLink } from './style'
import { useGlobalStore } from '../../../../../store'
import { getObjectAsArray } from '../../../../../util'

const Suggestions = ({ onClose, onLoadShow = false, showButton = true, disabledLinks, suggestionClicked, parentLoader, typing }) => {
  const [anchorElUser, setAnchorElUser] = useState(null)
  const [showSuggestionsModal, setShowSuggestionsModal] = useState(false)
  const {projectIdValue} = useGlobalStore()
  // const [loaderList, setLoaderList] = useState(false)
  const [suggestionList, setSuggestionList] = useState()
  const [suggestionData, setSuggestionData] = useState()
  const { id } = useParams()

  const handleOnSuggestions = () => {
    setAnchorElUser(null)
    setShowSuggestionsModal((prev) => !prev)
    onClose && onClose()
    setSuggestionData([])
  }

  const form = useRef()
  const inputRef = useRef()
  const [loader, setLoader] = useState(false)

  const onSubmit = () => {
    setLoader(true)
    GenerativeService.setSampleAskIAQuestionsForStudyApi({studyId:id, sampleQuestions: suggestionData.filter(f => f!=='').join('|')})
    .then((resp) => {
      setSuggestionList(suggestionData.filter(f => f!==''))
      setSuggestionData(suggestionData.filter(f => f!==''))
      setLoader(false)
      setShowSuggestionsModal(false)
    })
    .catch((error) => {
      setLoader(false)
      console.log('ERROR : ', error)
    })
  }

  const validation = (values) => {
    const errors = {}
    return errors
  }

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      // suggestions: user?.suggestions || '',
      // email: user?.UserEmail || '',
      // phoneNumber: '',
    },
    // validationSchema: Yup.object({
    //   // suggestions: Yup.string().max(25).required('Name is required'),
    //   // email: Yup.string().max(25).required('Email is required'),
    //   // phoneNumber: Yup.number() //.max(25).required('Phone Number is required')
    // }),
    validate: (values) => validation(values),
    onSubmit: (values) => {
      const updatedValues = {}
      onSubmit(updatedValues)
    }
  })

  const getSuggestionList = () => {
    // setLoaderList(true)
    GenerativeService.getStudiesApi({projectId: projectIdValue.ProjectId}, true)
      .then((resp) => {
        if(resp?.[0]?.Study){
          const data1 = getObjectAsArray(resp[0]?.Study).filter(f => f.StudyId === id)[0]
          // let data2 = (data1?.SampleQuestions.indexOf('|') !== -1 ) ? data1?.SampleQuestions.split('|') : ['No Suggestions']
          let data2 = data1?.SampleQuestions.split('|')
          setSuggestionData([...data2]);
          setSuggestionList([...data2]);
          // setLoaderList(false)
        }
      })
      .catch((error) => {
        // setLoaderList(false)
        const resMessage = error
        console.log('ERROR : ', resMessage)
        // enqueueSnackbar(resMessage, { variant: 'error' })
      })
  }

  const handleChange = (e, i=0) => {
    const arr = suggestionData
    arr[i] = e.target.value;
    setSuggestionData(arr);
  }

  const removeSuggestions = () => {
    const d = suggestionData;
    d.pop()
    setSuggestionData([...d])
  }

  const editSuggestionsFunc = () => {
    setShowSuggestionsModal(!disabledLinks)
    getSuggestionList()
  }

  useEffect(() => {
    setShowSuggestionsModal(onLoadShow)
  }, [onLoadShow])

  useEffect(() => {
    getSuggestionList()
  }, [])

  return (
    <>
      <SuggestionWrapper>
        {showButton && <IconButton aria-label="Edit" disabled={disabledLinks} onClick={() => editSuggestionsFunc()} sx={{height:'35px', margin:'-5px 0 0 5px'}}><IconEdit /></IconButton>}
        {suggestionList?.map((d, index) => {
          return (<SuggestionLink disabled={disabledLinks} key={index} onClick={() => suggestionClicked(d)}>{d}</SuggestionLink>)
        })}
      </SuggestionWrapper>
      {showSuggestionsModal && <Modal title='' showClose={true} onClose={handleOnSuggestions}>
        <Wrapper>
          <form ref={form} onSubmit={formik.handleSubmit}>
            <Title variant='body1'>Add Suggestions</Title>
            
            <SuggestionInputWrapper>
            {suggestionData?.map((data,i) => {
              const name = 'suggestions__'+data.trim()+'_'+i
              return (
                <InputWrap key={i}>
                  <MuiTextField
                    fullWidth
                    ref={inputRef[i]}
                    title={`Suggestion ${i+1}`}
                    name={name}
                    onChange={(e) => handleChange(e, i)}
                    type='text'
                    defaultValue={data}
                    variant='outlined'
                  />
                </InputWrap>
              )
            })}
            </SuggestionInputWrapper>

            <ButtonWrapper>
              {suggestionData.length > 1 && <MuiButton color='primary' variant='text' type='button' onClick={() => removeSuggestions()}> - Remove</MuiButton>}
              <MuiButton color='primary' variant='text' type='button' onClick={() => setSuggestionData([...suggestionData ,''])}> + Add More</MuiButton>
            </ButtonWrapper>

            <LoginButton
              color='primary'
              variant='contained'
              type='submit'
              onClick={formik.handleSubmit}
              disabled={loader || false}
            >
              Submit
            </LoginButton>

          </form>
        </Wrapper>
      </Modal>}
    </>
  )
}

export default Suggestions
