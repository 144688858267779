import styled from '@emotion/styled'
import { Box, Typography } from '@mui/material'
import { MuiButton } from '../../../components'

export const Title = styled(Typography)(`
  margin-top: 10px;
  padding-bottom: 30px;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  text-align: left;
`)

export const Wrapper = styled(Box)(`
  width:100%;
  max-width:370px;
  min-width:370px;
`)

export const DrfatButton = styled(MuiButton)(`
  float: left;
  margin-left: 0px !important;
  margin-top: 20px !important;
`)
