const data = [
    {
        CreatedDate: "2022-10-27T02:10:11",
        IsAskIAEnabled: false,
        IsFreezed: false,
        IsPublished: false,
        ModifiedDate: "2022-10-27T02:10:11",
        Period: 2020,
        ProjectId: "3f70cc98-9999-1111-4444-000000000000",
        SampleQuestions: "",
        StudyId: 'af4fa515-5500-11ed-817c-0a1ea4c5532f',
        StudyStatus: "InProgress",
        href: "/reports/background/af4fa515-5500-11ed-817c-0a1ea4c5532f",
        lhs: [
            {
                CreatedDate: "2022-10-28T05:42:51",
                ModifiedDate: "2022-10-28T05:42:51",
                StudyId: "af4fa515-5500-11ed-817c-0a1ea4c5532f",
                TabIcon: "background",
                TabId: "35633737-3634-6461-2d35-3638332d3131",
                TabName: "Background",
                TabOrder: 1,
                TabVisible: true
            },
            // {
            //     CreatedDate: "2022-10-28T05:42:51",
            //     ModifiedDate: "2022-10-28T05:42:51",
            //     StudyId: "af4fa515-5500-11ed-817c-0a1ea4c5532f",
            //     TabIcon: "executivesummary",
            //     TabId: "35633933-3234-6461-2d35-3638332d3131",
            //     TabName: "Executive Summary",
            //     TabOrder: 2,
            //     TabVisible: true
            // },
            // {
            //     CreatedDate: "2022-10-28T05:42:51",
            //     ModifiedDate: "2022-10-28T05:42:51",
            //     StudyId: "af4fa515-5500-11ed-817c-0a1ea4c5532f",
            //     TabIcon: "keyfindings",
            //     TabId: "35636363-3635-3232-2d35-3638332d3131",
            //     TabName: "Key Findings",
            //     TabOrder: 3,
            //     TabVisible: true
            // },
            // {
            //     CreatedDate: "2022-10-28T05:42:51",
            //     ModifiedDate: "2022-10-28T05:42:51",
            //     StudyId: "af4fa515-5500-11ed-817c-0a1ea4c5532f",
            //     TabIcon: "researchsection_misc1",
            //     TabId: "35636633-3339-6464-2d35-3638332d3131",
            //     TabName: "Competitive Analysis",
            //     TabOrder: 4,
            //     TabVisible: true
            // },
            // {
            //     CreatedDate: "2022-10-28T05:42:52",
            //     ModifiedDate: "2022-10-28T05:42:52",
            //     StudyId: "af4fa515-5500-11ed-817c-0a1ea4c5532f",
            //     TabIcon: "deepdive",
            //     TabId: "35643134-6365-3335-2d35-3638332d3131",
            //     TabName: "Deep Dive Analysis",
            //     TabOrder: 5,
            //     TabVisible: true
            // },
            // {
            //     CreatedDate: "2022-10-28T05:42:52",
            //     ModifiedDate: "2022-10-28T05:42:52",
            //     StudyId: "af4fa515-5500-11ed-817c-0a1ea4c5532f",
            //     TabIcon: "sandbox",
            //     TabId: "35643332-3037-3931-2d35-3638332d3131",
            //     TabName: "Sandbox",
            //     TabOrder: 6,
            //     TabVisible: true
            // }
        ]
    }
]

export default data