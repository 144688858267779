import React, { useRef, useState, useEffect, useCallback } from 'react'
import ReportsService from '../../services/reports.service'
import { Observations, LoaderWrapper, ReportEditHeader, DownloadExcel, InsightsRteContent } from '../../components'
import { Box, Menu, IconButton } from '@mui/material'
import { Menu as MenuIcon } from '@mui/icons-material'

import {ROLES_CLIENT_SECONDARY, ROLES_MM_PRIMARY} from '../../constants'
import { IconArrowLeft, IconClose } from '../../assets/images/icons'
import { useGlobalStore } from '../../store'
import decode from 'unescape'

import { 
  HeadingOuterWrapper, HeadingInnerWrapper, SubHeading, CardHeading, ContentWrapper, CardRteContent, ButtonBox, 
  Desktop, Mobile, MenuWrapper, IconArrowLeftWrapper, IconCloseOuterWrapper, IconCloseWrapper, InsightsOuterWrapper,
  ButtonPlaceHolder, ButtonUp, ButtonDown, MenuIconButton, InsightsInnerWrapper, SlideOverlay
} from './style'

const ReportHeading = (props) => {
  const {moveInsightsTo, resetInsightHeight, downloadId, Heading, subHeading, InsightsElementId, InsightsElementType, showObservations=true, additionalButton, additionalMiddleButton, onSuccess, addTable=false, afterHeadingComponent, onInsightsChange } = props

  // const {enqueueSnackbar} = useSnackbar()
  const {user, projectIdValue, isPPTModalActive, isAskIaActiveState, setAskIaActiveState} = useGlobalStore()
  // const [contentHeaderSections, setContentHeaderSections] = useState()
  const [loading, setLoading] = useState(false)
  const [pageHeading, setPageHeading] = useState()
  const [pageInsights, setPageInsights] = useState()
  const [pageHeadingData, setPageHeadingData] = useState(false)
  const [pageInsightsData, setPageInsightsData] = useState(false)
  const [hamburgerMenu, showHamburgerMenu] = useState(false)
  const [hamburgerMenuAnchorEl, showHamburgerMenuAnchorEl] = useState(null)

  const removingTags = (str) => {
    let d1 = str
    if(isPPTModalActive && downloadId?.length > 5){
        const id = `reportHeadingRteInsights__${downloadId}`
        if(document?.getElementById(id)?.innerHTML){
          const htmlNode = document?.querySelectorAll(`#${id} *`) || null
          if(isNaN(htmlNode)){
            for(let i=0; i<htmlNode.length; i++){
              htmlNode[i].removeAttribute('style')
            }
            const htmlStr = (document.getElementById(id).innerHTML).replaceAll('<ol','<ul').replaceAll('</ol>','</ul>')
            document.getElementById(id).innerHTML = htmlStr
          }
        }
    }
    return d1
  }

  const loadHeadingData = useCallback((getLatest=false) => {
    setLoading(true)
    ReportsService.reportGetHeaderApi({insightsElementId:InsightsElementId}, getLatest)
    .then((resp) => {
      setPageHeading( decode(resp) || Heading)
      setPageHeadingData( true )
      setLoading(false)
    })
    .catch((error) => {
      const resMessage = error
      console.log('ERROR : ', resMessage)
      setLoading(false)
    })
  }, [Heading, InsightsElementId])

  const loadInsightsData = useCallback((getLatest=false) => {
    ReportsService.reportGetInsightsApi({insightsElementId:InsightsElementId}, getLatest)
    .then((resp) => {
      setPageInsights( decode(resp) )
      setPageInsightsData( true )
    })
    .catch((error) => {
      const resMessage = error
      console.log('ERROR : ', resMessage)
    })
  }, [InsightsElementId])

  const onSuccessEdit = () => {
    loadHeadingData(true)
    loadInsightsData(true)
  }

  const showHamburderMenu = (event) => {
    showHamburgerMenuAnchorEl(event.currentTarget);
    showHamburgerMenu(!hamburgerMenu)
  }

  const backToAskIaLandingPage = () => {
    setAskIaActiveState('active')
  }
  
  const closeAskIaDetailPage = () => {
    setAskIaActiveState('none')
  }

  const onInsightsChangeFunc = (param) => {
    // if( ['BrandAttributeMapElement', 'BrandAttributeMapAndProgression'].includes(InsightsElementType) ){
    //   onInsightsChange(param)
    // }
    onInsightsChange(param)
  }
  
  useEffect(() => {
    if(!pageHeading){
      loadHeadingData()
      loadInsightsData()
    }
  }, [pageHeading, loadHeadingData, loadInsightsData])

  useEffect(() => {
    if(!!pageInsights?.length && downloadId?.length > 5){
      removingTags()
    }
  }, [pageInsights, downloadId])

  return (
    <>
      <HeadingOuterWrapper className=''>
        <LoaderWrapper loading={loading}>
          <HeadingInnerWrapper className='reportHeading'>
            <CardHeading>
              <Box style={{display:'flex', flexDirection:'column'}}>
                <Box style={{display:'flex'}}>
                  {!(['none','active'].includes(isAskIaActiveState)) && <IconArrowLeftWrapper onClick={() => backToAskIaLandingPage()}><IconArrowLeft /></IconArrowLeftWrapper>}
                  {!(['none','active'].includes(isAskIaActiveState)) && <IconCloseOuterWrapper><IconCloseWrapper onClick={() => closeAskIaDetailPage()}><IconClose /></IconCloseWrapper></IconCloseOuterWrapper>}

                  <ContentWrapper className='headingRte' id={`reportHeadingRte__${downloadId}`} dangerouslySetInnerHTML={{__html: pageHeading}}></ContentWrapper>
                  <>
                    { afterHeadingComponent }
                    {user && pageHeadingData && pageInsightsData && ROLES_MM_PRIMARY.includes(user?.UserType) && Heading?.length && !projectIdValue?.IsFreezed && <ReportEditHeader pageInsights={pageInsights} pageHeading={pageHeading} InsightsElementId={InsightsElementId} onSuccess={onSuccessEdit} addTable={addTable} />}
                  </>
                </Box>
                {subHeading && <SubHeading>{subHeading}</SubHeading>}
              </Box>
            </CardHeading>

            <ButtonsSection InsightsElementId={InsightsElementId} downloadId={downloadId} additionalButton={additionalButton} additionalMiddleButton={additionalMiddleButton} {...props} />

            {/* {showObservations && InsightsElementId && 
              <Desktop><ButtonsSection InsightsElementId={InsightsElementId} downloadId={downloadId} additionalButton={additionalButton} additionalMiddleButton={additionalMiddleButton} {...props} /></Desktop>
            }
            {showObservations && InsightsElementId && 
              <Mobile>
                <IconButton onClick={showHamburderMenu}><MenuIcon /></IconButton>
                <MenuWrapper open={hamburgerMenu} anchorEl={hamburgerMenuAnchorEl} onClose={() => {showHamburgerMenu(false); showHamburgerMenuAnchorEl(null)}}><ButtonsSection /></MenuWrapper>
              </Mobile>
            } */}
          </HeadingInnerWrapper>
          {((isPPTModalActive && !!pageInsights?.length) || moveInsightsTo === 'moveInsightsToLeft') && <CardRteContent id={`reportHeadingRteInsights__${downloadId}`} dangerouslySetInnerHTML={{__html: pageInsights}}></CardRteContent>}
        </LoaderWrapper>
      </HeadingOuterWrapper>
      {!isPPTModalActive && !!pageInsights?.length && (moveInsightsTo === 'moveInsightsToRight') && <InsightsContent downloadId={downloadId} pageInsights={pageInsights} resetInsightHeight={resetInsightHeight} onInsightsChange={onInsightsChangeFunc} />}
    </>
  )
}

export default ReportHeading


const ButtonsSection = (props) => {
  const {user} = useGlobalStore()
  const {additionalButton, additionalMiddleButton, downloadId, InsightsElementId, downloadIdData, excel=true} = props;

  return (
    <ButtonBox className='buttonList'>
      {additionalButton}
      {excel && <DownloadExcel downloadId={downloadId} downloadIdData={downloadIdData} {...props} />}
      {additionalMiddleButton}
      {ROLES_CLIENT_SECONDARY.includes(user.UserType) && <Observations insightsElementId={InsightsElementId} {...props} />}
    </ButtonBox>
  )
}

const InsightsContent = ({downloadId, pageInsights, resetInsightHeight, onInsightsChange}) => {
  const [btnDetails, setBtnDetails] = useState({showUp: false, showDown: false, disabled:'top'})
  const slideContainer = document.getElementById(`reportHeadingRteInsights__${downloadId}__wrapper`);

  const buttonUpFunc = () => {
    const slide = slideContainer.querySelector(".slide");
    const getTop = slide.scrollTop
    if ( getTop !== 0 ) {
      slide.scrollTo({
          top: getTop - 100,
          left: 0,
          behavior: "smooth",
      })
      setBtnDetails(prev => ({...prev, disabled: ''}))
    } else {
      setBtnDetails(prev => ({...prev, disabled: 'top'}))
    }
  }

  const buttonDownFunc = () => {
    const slide = slideContainer?.querySelector(".slide") || null
    if(isNaN(slide)){
      const getTop = slide.scrollTop
      const clientHeight = slide.clientHeight
      const scrollHeight = slide.scrollHeight
      if ( (getTop+clientHeight) < scrollHeight ) {
        slide.scrollTo({
            top: getTop + 100,
            left: 0,
            behavior: "smooth",
        })
        setBtnDetails(prev => ({...prev, disabled: ''}))
      } else {
        setBtnDetails(prev => ({...prev, disabled: 'bottom'}))
      }
    }
  }

  const updateHeight = () => {
    const slideContainer = document.getElementById(`reportHeadingRteInsights__${downloadId}__wrapper`) || null
    if(isNaN(slideContainer)){
      slideContainer.style.height = '50px' // Resetting values
      slideContainer.querySelector('.slide').style.height = '50px' // Resetting values
      slideContainer.querySelector('.slideOverlay').style.height = '50px' // Resetting values

      if(slideContainer?.nextSibling?.offsetHeight > 0) {
        const height = (slideContainer?.nextSibling?.offsetHeight) - 82
        slideContainer.style.height = height+'px'
        slideContainer.querySelector('.slide').style.height = height+'px'
        slideContainer.querySelector('.slideOverlay').style.height = (height-65)+'px'
      }
    }
  }

  const contentDetails = () => {
    const slideContainer = document.getElementById(`reportHeadingRteInsights__${downloadId}__wrapper`) || null
    if(isNaN(slideContainer)){
      const slideContainer = document.getElementById(`reportHeadingRteInsights__${downloadId}__wrapper`);
      const scrollHeight = slideContainer.querySelector(".slide").scrollHeight
      const show = (scrollHeight > slideContainer.offsetHeight)
      setBtnDetails(prev => ({...prev, showUp: show, showDown: show}))
      
      if(slideContainer?.nextSibling?.offsetHeight > 0) {
        const height = slideContainer.nextSibling.offsetHeight - (show ? 82 : 41)
        slideContainer.querySelector('.slide').style.height = height+'px'
        slideContainer.querySelector('.slideOverlay').style.height = (height-65)+'px'
      }
    }
  }

  const showInsightsFunc = (type='onload') => {
    const wrapperDiv = document.getElementById(`reportHeadingRteInsights__${downloadId}__wrapper`).parentNode
    wrapperDiv.classList.toggle('showRhsInsights')
    if(type !== 'onload') {
      onInsightsChange(type)
    }
  }

  const hideInsightsFunc = () => {
    const wrapperDiv = document.getElementById(`reportHeadingRteInsights__${downloadId}__wrapper`).parentNode
    wrapperDiv.classList.toggle('hideRhsInsights')
  }

  const applyStickySlideBarFunc = () => {
    const ths = document.getElementById(`reportHeadingRteInsights__${downloadId}__wrapper`) || null
    const mainHeaderHeight = document.querySelector('.desktopMenu.headerLogo').getBoundingClientRect().height || null
    if(isNaN(ths) && (mainHeaderHeight > 0) && ths?.parentNode?.querySelector('.reportOuterWrapper')){
      // ONLY RESETTING FUNCTIONS
      ths.style.removeProperty('height');
      ths.classList.remove('stickySidebar');
      ths.classList.remove('stickySidebarStatic');
      ths.querySelector('.slide').style.removeProperty('height');
      ths.querySelector('.slideOverlay').style.removeProperty('height');
    }

    if(isNaN(ths) && (mainHeaderHeight > 0) && ths?.parentNode?.querySelector('.reportOuterWrapper')){
      ths.classList.add('ready');
      // const rhsOuterBox = ths.getBoundingClientRect()
      const rhsOuterBox = ths
      const rhsInnerBox = ths.querySelector('.slide')
      const rhsOverlay = ths.querySelector('.slideOverlay')
      const lhsOuterBox = ths.parentNode.querySelector('.reportOuterWrapper').getBoundingClientRect()
      const windowHeight = window.innerHeight;

      rhsOuterBox.style.height = lhsOuterBox.height+'px'
      rhsInnerBox.style.height = lhsOuterBox.height+'px'
      rhsOverlay.style.height = (lhsOuterBox.height-65)+'px'

      if(lhsOuterBox.height > windowHeight){
        rhsOuterBox.style.height = (windowHeight - mainHeaderHeight)+'px'
        rhsInnerBox.style.height = (windowHeight - mainHeaderHeight)+'px'
        rhsOverlay.style.height = (windowHeight - mainHeaderHeight - 65)+'px'
        rhsOuterBox.classList.add('stickySidebar')
      } else {
        ths.classList.add('stickySidebarStatic')
      }

      const show = (rhsInnerBox.scrollHeight > ths.offsetHeight)
      setBtnDetails(prev => ({...prev, showUp: show, showDown: show}))
    }
  }

  useEffect(() => {
    showInsightsFunc()
    // updateHeight()
    // contentDetails()
    applyStickySlideBarFunc()
    hideInsightsFunc()
  }, [])

  useEffect(() => {
    // updateHeight()
    // contentDetails()
    applyStickySlideBarFunc()
  }, [resetInsightHeight])

  // useEffect(() => {
  //   window.addEventListener('resize', applyStickySlideBarFunc)
  //   // return () => {
  //   //   window.removeEventListener('resize', applyStickySlideBarFunc)
  //   // }
  // }, [])

  return (
    <>
      <InsightsOuterWrapper className='insightsOuterWrapper stickySidebarDefault' id={`reportHeadingRteInsights__${downloadId}__wrapper`}>
        <ArrowLeft className='btnShow' onClick={() => showInsightsFunc('show')} />
        <ArrowLeft className='btnHide' onClick={() => showInsightsFunc('hide')} />
        <InsightsInnerWrapper className='InsightsInnerWrapper'>
          {!(btnDetails.showUp && btnDetails.showDown) && <ButtonPlaceHolder />}
          {btnDetails.showUp && <ButtonUp onClick={buttonUpFunc}>
            <svg width="21" height="10" viewBox="0 0 21 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 9L10.5 1L20 9" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeOpacity={btnDetails.disabled === 'top' ? '0.4' : '1'} />
            </svg>
          </ButtonUp>}
          <SlideOverlay className='slideOverlay' />
          <CardRteContent className='slide' id={`reportHeadingRteInsights__${downloadId}`} dangerouslySetInnerHTML={{__html: pageInsights}}></CardRteContent>
          {btnDetails.showDown && <ButtonDown onClick={buttonDownFunc}>
            <svg width="21" height="10" viewBox="0 0 21 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M20 1L10.5 9L1 0.999998" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeOpacity={btnDetails.disabled === 'bottom' ? '0.4' : '1'} />
            </svg>
          </ButtonDown>}
          {/* {!(btnDetails.showUp && btnDetails.showDown) && <ButtonPlaceHolder />} */}
        </InsightsInnerWrapper>
      </InsightsOuterWrapper>
    </>
  )
}

const ArrowLeft = ({onClick, className}) => {
  return (
    <MenuIconButton size='small' onClick={onClick} className={className}>
      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11" fill="none">
        <path d="M9 1L5 5.5L9 10" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M5 1L1 5.5L5 10" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </MenuIconButton>
  )
}
