import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';

export const LinkWrapper = styled(NavLink)(`
   text-decoration: none;
   padding: 15px;
   width: 100%;
   display: flex;
   color: #000;
   font-size: 1rem;
   font-weight: 300;
   border-radius: 4px;
   margin-bottom: 15px;
   padding: 20px;
   font-size: 16px;
   &.active {
    font-weight: 500;
    background: #fff;
    &:hover {
      background: #fff;
     }
   };
   &:hover {
    background: rgba(55, 65, 81, 0.04);
   }
   &::last-child {
    margin-bottom: 0;
   }
`)