import React, { useState, useEffect } from 'react'
import { D3Pie } from '../../../../components'
import { GRAPH_COLOR_PALLET1, GRAPH_COLOR_PALLET2 } from '../../../../constants'
import { CardInnerWrapper, LhsWrap, ListItemTextWrap, RhsWrap } from './style'

const RelativeBrands = ({ graphPieData, isExtraColor=false }) => {
    const colors =  isExtraColor ? Object.values(GRAPH_COLOR_PALLET2) : GRAPH_COLOR_PALLET1; 
    const pieSettings = {
        width: 300,
        height: 300,
        cx: 40,
        cy: 40,
        outerRadius: 110,
        textShow: true,
        textLocation: 1.4,
        textSize: '14px',
        colors: colors,
        effects: 'InnerCircle',
        fontWeight: 300,
    }

    return (
        <CardInnerWrapper className='relativeBrands'>
            <LhsWrap>
                <D3Pie data={graphPieData} settings={pieSettings} />
            </LhsWrap>
            <RhsWrap>
                {graphPieData.map((leg, i) => {
                    if(leg.name === 'REMAINING_VALUE') return false
                    return (
                        <ListItemTextWrap
                            key={i}
                            primary={<><span style={{ backgroundColor: colors[i] }}></span><b>{leg.value}%</b></>}
                            secondary={leg.name}
                        />
                    )
                })}
            </RhsWrap>
        </CardInnerWrapper>
    )
}

export default RelativeBrands