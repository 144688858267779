import { Box } from '@mui/material'
import React, { useState } from 'react'
import { MuiButton, MuiCheckbox, MuiDatePicker, MuiSelect } from '../../../../components'
import { SENTIMENTS, VISIVILITY } from '../../../../constants'
import { AttributeName, BrandCategoryName, DriverName, SubAttributeName } from '../dropdowns'
import { FilterWrap } from './style'

const VerbatimFilter = ({ onChange, insightsElementId }) => {
  const [state, setState] = useState({})

  const inputChangeHandler = (name, value) => {
    setState(prev => {
      const obj = {
        ...prev,
        [name]: value,
      }

      if (name === 'entityName') {
        obj.attributeName = ""
        obj.driverName = ""
        obj.subAttributeName = ""
      }
      if (name === 'driverName') {
        obj.attributeName = ""
        obj.subAttributeName = ""
      }
      if (name === 'attributeName') {
        obj.subAttributeName = ""
      }
      return obj;
    })
  }

  const resetFilterHandler = () => {
    onChange({});
    setState({})
  }

  const onClickHandler = () => {
    const obj = {
      entityName: state.entityName || "",
      driverName: state.driverName || "",
      attributeName: state.attributeName || "",
      subAttributeName: state.subAttributeName || "",
      sentiment: state.sentiment || "",
      startDate: state?.startDate?.toISOString() || null,
      endDate: state?.endDate?.toISOString() || null,
      verbatimWithImageVideoPublicURLOnly: state.verbatimWithImageVideoPublicURLOnly || false,
      insightsAssistantVisibility: state.insightsAssistantVisibility || 'All'
    }
    if(!obj.entityName) delete obj.entityName;
    if(!obj.driverName) delete obj.driverName;
    if(!obj.attributeName) delete obj.attributeName;
    if(!obj.subAttributeName) delete obj.subAttributeName;
    if(!obj.sentiment) delete obj.sentiment;
    if(!obj.startDate) delete obj.startDate;
    if(!obj.endDate) delete obj.endDate;
    if(!obj.verbatimWithImageVideoPublicURLOnly) delete obj.verbatimWithImageVideoPublicURLOnly;
    if(!obj.insightsAssistantVisibility) delete obj.insightsAssistantVisibility;

    onChange(obj);
  }

  return (
    <FilterWrap className='verbatimFilterOuterWrapper'>
      <Box className="filterItemWrap">
        <BrandCategoryName insightsElementId={insightsElementId} name='entityName' value={state?.entityName} title='Brand/Category' onChange={inputChangeHandler} />
      </Box>
      <Box className="filterItemWrap">
        <DriverName insightsElementId={insightsElementId} entityName={state?.entityName} name='driverName' value={state?.driverName} title='Driver' onChange={inputChangeHandler} />
      </Box>
      <Box className="filterItemWrap">
        <AttributeName insightsElementId={insightsElementId} driverName={state?.driverName} entityName={state?.entityName} name='attributeName' value={state?.attributeName} title='Attribute' onChange={inputChangeHandler} />
      </Box>
      <Box className="filterItemWrap">
        <SubAttributeName insightsElementId={insightsElementId} attributeName={state?.attributeName} driverName={state?.driverName} entityName={state?.entityName} name='subAttributeName' value={state?.subAttributeName} title='Sub Attribute' onChange={inputChangeHandler} />
      </Box>
      <Box className="filterItemWrap">
        <MuiSelect
          name="sentiment"
          onChange={(e) => inputChangeHandler("sentiment", e.target.value)}
          title="Sentiment"
          data={SENTIMENTS}
          value={state?.sentiment || undefined}
          variant="standard"
          placeholder="Select"
          compact
          selectlabel="label"
          selectvalue="value"
        />
      </Box>
      <Box className="filterItemWrap">
        <MuiDatePicker
          name="startDate"
          value={state?.startDate || undefined}
          onChange={(value) => inputChangeHandler("startDate", value)}
          placeholder='Select'
          label="From Date"
        />
      </Box>
      <Box className="filterItemWrap">
        <MuiDatePicker
          name="endDate"
          value={state?.endDate || undefined}
          onChange={(value) => inputChangeHandler("endDate", value)}
          placeholder='Select'
          label="To Date"
        />
      </Box>
      <Box className="filterItemWrap">
        <MuiSelect
          name="insightsAssistantVisibility"
          onChange={(e) => inputChangeHandler("insightsAssistantVisibility", e.target.value)}
          title="Visibility"
          data={VISIVILITY}
          value={state?.insightsAssistantVisibility || undefined}
          variant="standard"
          placeholder="Select"
          compact
          selectlabel="label"
          selectvalue="value"
        />
      </Box>
      <Box sx={{ width: "100%", marginLeft: "2px" }}>
        <MuiCheckbox checked={state.verbatimWithImageVideoPublicURLOnly} onChange={(e) => inputChangeHandler("verbatimWithImageVideoPublicURLOnly", e.target.checked)} label='Image / Video Public URL' />
      </Box>
      <Box>
        <MuiButton onClick={onClickHandler} minwidth={'true'} color='primary' variant='contained'>Filter</MuiButton>
        <MuiButton onClick={resetFilterHandler} minwidth={'true'} color='gray' variant='contained' sx={{ marginLeft: "20px" }}>Reset</MuiButton>
      </Box>
    </FilterWrap>
  )
}

export default VerbatimFilter