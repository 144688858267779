import React, { useRef, useState, useEffect } from 'react'
import { IconButton, Box, Typography } from '@mui/material'
import { ReportHeading, MuiButton, LoaderWrapper, VerbatimImageVideoGrid, SearchKeywordGrid, Legend, ReportDriversEditHeaderLevel2, InsightsRteContent, ReportOuterWrapper } from '../../../../components'
import ReportsService from '../../../../services/reports.service'
import { useUpdateLhs } from '../../../../hooks/useUpdateLhs'
import { useGlobalStore } from '../../../../store'
import { NanoDataGrid, ProSimulator} from '..'
import {ROLES_MM_PRIMARY} from '../../../../constants'
import { IconReportSearch, IconReportMore, ClosePopup } from '../../../../assets/images/icons'
import { getImageUrl, exportExcel_addPercentage, exportExcel_addSegments, getObjectAsArray } from '../../../../util'
import Header from '../reportSegment/header'
import { LegendWrapper, IconButtonWrapper, GridOuterWrapper, GridHeaderWrapper, ContentWrapper, ContOuterWrap, DetailsHeading, InsightsRteContentWrapper, InsightsLevel2Details, IsDeepDiveImageWrap, MuiButtonWrap, Level1Wrapper, Level2Wrapper, Level3Wrapper, Level3OuterWrapper, Level4Wrapper, Level4OuterWrapper } from './style'

const CONFIG = {
    type: "SearchKeywords",
    attributeColor: "#000",
    tabs: [
      { id: 0, label: "Drivers", tutorial: "Driver" },
      { id: 1, label: "Motivators", tutorial: "Motivators" },
      { id: 2, label: "Detractors", tutorial: "Detractors" },
    ],
    showLegends: false
}

const ReportDeepDiveNew = (props) => {
  // const { enqueueSnackbar } = useSnackbar()
  const [deepDiveLoading, setDeepDiveLoading] = useState(true)
  const [deepDivePageData, setDeepDivePageData] = useState([])
  const [rowsIsSelected, setRowsIsSelected] = useState(false)
  // const [rowsLevel3IsSelected, setRowsLevel3IsSelected] = useState(false)
  const [expandedStatus, setExpandedStatus] = useState(false)
  const [overallRating, setOverallRating] = useState(0)
  const [settings, setSettings] = useState({})
  const [entityDetails, setEntityDetails] = useState()
  const [driversExcelData, setDriversExcelData] = useState()
  const {updateSubLhsApiLoaded} = useUpdateLhs()
  const {user, projectIdValue} = useGlobalStore()
  const tableRef = useRef(null)
  const [pptVerbatims, setPptVerbatims] = useState([])
  const [activeTab, setActiveTab] = useState(0)
  const [eventCount, setEventCount] = useState(1)

  const [deepDivePageTabWiseData, setDeepDivePageTabWiseData] = useState([])

  const expandedStatusFunc = () => {
    let expanded = null
    const length = deepDivePageData.length
    if (length > 0) {
      const expandedLength = deepDivePageData.map(s => s.selected).filter(f => f === true).length
      if (expandedLength === 0) { expanded = false }
      if (expandedLength === length) { expanded = true }
      setExpandedStatus(expanded)
    }
  }

  const levelHandleClose = (levelIndexingVal) => {
    const array = levelIndexingVal.split('___').map(s => Number(s.split('_')[1]))

    const data = deepDivePageData
    if (array.length === 1) {
      data[array[0]].selected = false
      data[array[0]].DriverAttributes = data[array[0]]?.DriverAttributes.map(d1 => {
        d1.selected = false
        d1.DriverAttributes = d1?.DriverAttributes?.map(d2 => {
          d2.selected = false
          return d2
        })
        return d1
      })
    }
    else if (array.length === 2) { 
      data[array[0]].DriverAttributes[array[1]].selected = false
    }
    else if (array.length === 3) { 
      data[array[0]].DriverAttributes[array[1]].DriverAttributes[array[2]].selected = false 
    }

    setDeepDivePageData([...data])
    rowsLevel3IsSelectedFunc([...data])
  }

  const level1HandleClick = (event, type, selectedRow) => {
    const data = deepDivePageData.map((d) => {
      if (d.levelIndexing === selectedRow.levelIndexing) {
        // d.selected = !d.selected
        d.selected = true
        d.buttonClicked = type
      }
      return d
    })
    const rowIsSelected = data?.map(d => d.selected)?.filter(f => f)?.length
    setRowsIsSelected(rowIsSelected === 1)
    setDeepDivePageData(data)
  }

  const level2HandleClick = (event, type, selectedRow) => {
    event.preventDefault();
    const data = deepDivePageData.map(l1 => {
      l1.DriverAttributes.map(l2 => {
        if (l2.levelIndexing === selectedRow.levelIndexing) { 
          // l2.selected = !l2.selected;
          l2.selected = true;
          l2.buttonClicked = type
        }
        else { 
          l2.selected = false
          l2.buttonClicked = 'none'
        }
        return l2
      })
      return l1
    })
    setDeepDivePageData(data)
  }

  const Level2Actions = ({ row }) => {
    let showSearch = true
    // SULAMAN2
    let count = {positive:0, negative:0};
    if(row.motivatorsDetractorSelection !== ''){
      for(let i=0; i<row.DriverAttributes.length; i++){
        if(row.DriverAttributes[i].PositivePercent > 0){
          count.positive = count.positive+1;
        } else if(row.DriverAttributes[i].NegativePercent > 0){
          count.negative = count.negative+1;
        }
      }
      if(row.motivatorsDetractorSelection === 'Positive' && count.positive === 0){ showSearch = false }
      else if(row.motivatorsDetractorSelection === 'Negative' && count.negative === 0){ showSearch = false }
    }
    // if(row.motivatorsDetractorSelection !== ''){
    //   let count = 0;
    //   for(let i=0; i<row.DriverAttributes.length; i++){
    //     if(row.DriverAttributes[i].Sentiment === row.motivatorsDetractorSelection){
    //       count = count+1;
    //     }
    //   }
    //   if(count === 0){ showSearch = false }
    // }
    return (
      <ContOuterWrap sx={{ justifyContent: 'right' }}>
        {!!row?.DriverAttributes.length && showSearch && <IconButton size='small' onClick={(e) => level2HandleClick(e, 'search', row)}><IconReportSearch /></IconButton>}
        {!!row?.Verbatim.length && <IconButton size='small' onClick={(e) => level2HandleClick(e, 'more', row)}><IconReportMore /></IconButton>}
        {!!row?.SearchKeywords.length && <IconButton size='small' onClick={(e) => level2HandleClick(e, 'more', row)}><IconReportMore /></IconButton>}
      </ContOuterWrap>
    )
  }

  const level3HandleClick = (event, type, selectedRow) => {
    const data = deepDivePageData.map(l1 => {
      l1.DriverAttributes.map(l2 => {
        if (l2?.DriverAttributes?.length) {
          l2.DriverAttributes.map(l3 => {
            if (l3.levelIndexing === selectedRow.levelIndexing) { l3.selected = !l3.selected; l3.buttonClicked = type } else { l3.selected = false }
            return l3
          })
        }
        return l2
      })
      return l1
    })
    setDeepDivePageData(data)
    rowsLevel3IsSelectedFunc(data)
  }

  const Level3Actions = ({ row }) => {
    return (
      <ContOuterWrap sx={{ justifyContent: 'right' }}>
        {!!row?.Verbatim.length && <IconButton size='small' onClick={(e) => level3HandleClick(e, 'more', row)}><IconReportMore /></IconButton>}
        {!!row?.SearchKeywords.length && <IconButton size='small' onClick={(e) => level3HandleClick(e, 'more', row)}><IconReportMore /></IconButton>}
      </ContOuterWrap>
    )
  }

  const NanoDataGridDetailsL1 = ({ propsInsightsElementType, content, settings }) => {    
    const updateDriversHeading = (e) => {
      loadDeepDive(true)
    }

    return (
      <>
        {deepDivePageData.map((lev1, i) => {
          if (!(lev1.levelIndexing === content.levelIndexing && lev1.selected)) return false
          const levButtonClicked = lev1.DriverAttributes.map(lev2 => lev2.buttonClicked !== 'none' && lev2.buttonClicked ).filter(b => b==='more' || b==='search')?.[0]
          const level2ClickedButton = lev1.DriverAttributes.map(d => { return (d.selected) && d.buttonClicked })[0]
          let showDeepDiveOnDownloadPPT = true
          if(propsInsightsElementType === 'DriversAndRatingImpacts'){
            showDeepDiveOnDownloadPPT = lev1.buttonClicked === 'search' && lev1.IsDeepDiveImage
          }
     
          // SULAMAN3
          let count = {positive:0, negative:0};
          if(lev1.motivatorsDetractorSelection !== ''){
            for(let i=0; i<lev1.DriverAttributes.length; i++){
              if(lev1.DriverAttributes[i].PositivePercent > 0){
                count.positive = count.positive+1;
              } else if(lev1.DriverAttributes[i].NegativePercent > 0){
                count.negative = count.negative+1;
              }
            }
            if(lev1.motivatorsDetractorSelection === 'Positive' && count.positive === 0){ return false }
            else if(lev1.motivatorsDetractorSelection === 'Negative' && count.negative === 0){ return false }
          }
              
          return (
            <div key={i} className={`level2OuterWrapper ${level2ClickedButton} ${lev1.isLevel3Active ? 'level3IsActive' : ''}  ${showDeepDiveOnDownloadPPT ? 'showDeepDiveOnDownloadPptTrue' : 'showDeepDiveOnDownloadPptFalse'} `}>
              <Level2Wrapper className={`level2Wrapper ${lev1.buttonClicked}ActiveButton level2Clicked${level2ClickedButton}  ${levButtonClicked}ActiveButtonL3`}>
                <IconButtonWrapper onClick={() => levelHandleClose(lev1.levelIndexing)}><ClosePopup /></IconButtonWrapper>
                <DetailsHeading className='DriversTr level2Content'>
                  {/* <Typography className='pptSubHeading'>{lev1.DriverName}</Typography> */}
                  <Typography className='subheadingL2 hideInPPT'>{lev1.DriverName}</Typography>
                  {activeTab === 0 && ROLES_MM_PRIMARY.includes(user?.UserType) && !projectIdValue?.IsFreezed && <ReportDriversEditHeaderLevel2 pageInsights={lev1.Insights} insightsElementId={lev1.InsightsElementId} driverId={lev1.DriverId} driverName={lev1.DriverName} onSuccess={updateDriversHeading} />}
                  {activeTab === 0 && lev1?.Insights?.length > 0 && <InsightsRteContentWrapper><InsightsRteContent content={lev1.Insights} /></InsightsRteContentWrapper>}
                </DetailsHeading>
                {/* {lev1?.Insights?.length > 0 && <InsightsLevel2Details dangerouslySetInnerHTML={{__html: lev1.Insights }} />} */}
                {/* {lev1?.Insights?.length > 0 && <InsightsRteContentWrapper className='DriversTr'><InsightsRteContent content={lev1.Insights} /></InsightsRteContentWrapper>} */}

                {lev1.buttonClicked === 'search' && lev1.IsDeepDiveImage &&
                  <IsDeepDiveImageWrap className='isDeepDiveImageWrap'><img src={getImageUrl('deepDive', lev1?.DeepDiveImageFileName, 'svg')} alt={lev1.DeepDiveImageFileName} /></IsDeepDiveImageWrap>
                }

                {lev1.buttonClicked === 'search' && !lev1.IsDeepDiveImage &&
                  <>
                    <NanoDataGrid 
                      rows={lev1.DriverAttributes}
                      // action={<Level2Actions row={lev1} showSearch={showSearchIconMotivatorsDetractorSelection} />}
                      action={<Level2Actions row={lev1} />}
                      theme='theme2'
                      setting={{SearchKeywords:settings.SearchKeywords, WithoutSentiments:settings?.WithoutSentiments}}
                    />
                    <NanoDataGridDetailsL2 lev1={lev1} settings={settings} />
                  </>
                }
                {lev1.buttonClicked === 'more' && !!lev1?.Verbatim?.length && <VerbatimImageVideoGrid entityType={entityDetails?.EntityType} data={lev1.Verbatim} insightsElementId={lev1.InsightsElementId}  />}
                {lev1.buttonClicked === 'more' && !!lev1?.SearchKeywords?.length && <SearchKeywordGrid entityType={entityDetails?.EntityType} data={lev1.SearchKeywords} insightsElementId={lev1.InsightsElementId}  />}
              </Level2Wrapper>
            </div>
          )
        })}
      </>
    )
  }

  const NanoDataGridDetailsL2 = ({ lev1, settings }) => {
    return (
      <>
        {lev1.DriverAttributes.map((lev2, j) => {
          if (!lev2.selected) return false

          return (
            <Level3OuterWrapper className={`level3OuterWrapper`} key={j}>
              <Level3Wrapper className={`level3Wrapper ${lev2.buttonClicked}ActiveButton`}>

                {lev2.buttonClicked === 'search' &&
                  <Box className='level3Wrap'>
                    <Box>
                      <DetailsHeading className='headingL3' variant='body1'>{lev2.DriverName}</DetailsHeading>
                      <NanoDataGrid
                        rows={lev2.DriverAttributes}
                        action={<Level3Actions />}
                        theme='theme2'
                        // setting={{SearchKeywords:settings.SearchKeywords}}
                        setting={{SearchKeywords:settings.SearchKeywords, WithoutSentiments:settings?.WithoutSentiments}}
                      />
                    </Box>
                    <NanoDataGridDetailsL3 lev2={lev2} />
                  </Box>
                }
                {lev2.buttonClicked === 'more' &&
                  <>
                    <IconButtonWrapper onClick={() => levelHandleClose(lev2.levelIndexing)}><ClosePopup /></IconButtonWrapper>
                    <DetailsHeading variant='body1'>{lev2.DriverName}</DetailsHeading>
                    {!!lev2?.Verbatim?.length && <VerbatimImageVideoGrid entityType={entityDetails?.EntityType} data={lev2.Verbatim} insightsElementId={lev2.InsightsElementId} />}
                    {!!lev2?.SearchKeywords?.length && <SearchKeywordGrid entityType={entityDetails?.EntityType} data={lev2.SearchKeywords} insightsElementId={lev2.InsightsElementId} />}
                  </>
                }
              </Level3Wrapper>
            </Level3OuterWrapper>
          )
        })}
      </>
    )
  }

  const NanoDataGridDetailsL3 = ({ lev2 }) => {
    return (
      <>
        {lev2.DriverAttributes.map((lev3, k) => {
          if (!lev3.selected) return false
          return (
            <Level4OuterWrapper className='level4OuterWrapper' key={k}>
              <Level4Wrapper className={`level4Wrapper ${lev3.buttonClicked}ActiveButton`}>
                <IconButtonWrapper onClick={() => levelHandleClose(lev3.levelIndexing)}><ClosePopup /></IconButtonWrapper>
                <DetailsHeading variant='body1'>{lev3.DriverName}</DetailsHeading>
                {!!lev3?.Verbatim?.length && <VerbatimImageVideoGrid entityType={entityDetails?.EntityType} data={lev3.Verbatim} insightsElementId={lev3.InsightsElementId} />}
                {!!lev3?.SearchKeywords?.length && <SearchKeywordGrid entityType={entityDetails?.EntityType} data={lev3.SearchKeywords} insightsElementId={lev3.InsightsElementId} />}
              </Level4Wrapper>
            </Level4OuterWrapper>
          )
        })}
      </>
    )
  }

  const expandCollapseFunc = (flag, deepDiveData) => {
    const data = deepDiveData.map(d => {
      if(d.DriverAttributes.length>0){
        const isRatingImpact = settings?.DriverRatingImpactsValue && d?.IsHighlighted
        if(isRatingImpact) { d.selected = flag }
        else if (!settings?.DriverRatingImpactsValue){ d.selected = flag }
      }
      d.buttonClicked = 'search'
      return d
    })
    setDeepDivePageData(data)
  }

  const rowsLevel3IsSelectedFunc = (data) => {
    const dataFinal = data.map(l1 => {
      let level3IsSelected = false
      l1.DriverAttributes.map(l2 => {
        if (l2?.DriverAttributes?.length) {
          l2.DriverAttributes.map(l3 => {
            if (l3.selected) { level3IsSelected = true }
            return l3
          })
        }

        return l2
      })
      l1.isLevel3Active = level3IsSelected
      return l1
    })
    setDeepDivePageData(dataFinal)
  }

  const loadDeepDive = (getLatest) => {
    setDeepDiveLoading(true)
    ReportsService.reportGetDriverElementsApi({ insightsElementId: props.InsightsElementId, entityId: props.EntityId, type: props.type, getLatest })
      .then((resp) => {
        setEntityDetails(resp.entityDetails)
        const settingData = {
          DriverTriggerStandouts: false,
          DriverSubBrandStandouts: false,
          DriverAttributeStandouts: false,
          DriverAttributes: false,
          Verbatim: false,
          SearchKeywords: false,
          // WithoutSentiments: (props.InsightsElementType === 'DriversWithoutSentiments'),
          WithoutSentiments: (['DriversWithoutSentiments', 'DriversWithoutSentimentsAndSubBrands'].includes(props.InsightsElementType)),
          DriverRatingImpacts: false,
          DriverRatingImpactsValue: false
        }

        let pptVerbatim = []
        const driversExcelDataObj = {
          drivers:[],
          driversWithoutSentiments:[],
          driversAndRatingImpacts:[],
          driverTriggerStandouts:[],
          driverSubBrandStandouts:[],
          searchDrivers:[],
          attributes:[],
          subAttributes:[]
        }

        let overallRatingValue = 0
        const tempResp = resp.respData
        tempResp.map((l1, i) => {
          l1.levelIndexing = `level1_${i}`
          l1.selected = false
          l1.motivatorsDetractorSelection = ''
          // l1.IsHighlighted = (l1.IsHighlighted === 'true')
          l1.buttonClicked = 'none'

          l1.DriverTriggerStandouts = l1?.DriverTriggerStandouts?.DriverTriggerStandout || []
          if (!Array.isArray(l1.DriverTriggerStandouts)) { l1.DriverTriggerStandouts = [l1.DriverTriggerStandouts] }
          if (l1.DriverTriggerStandouts.length > 0) { settingData.DriverTriggerStandouts = true }

          l1.DriverSubBrandStandouts = l1?.DriverSubBrandStandouts?.DriverSubBrandStandout || []
          if (!Array.isArray(l1.DriverSubBrandStandouts)) { l1.DriverSubBrandStandouts = [l1.DriverSubBrandStandouts] }
          if (l1.DriverSubBrandStandouts.length > 0) { settingData.DriverSubBrandStandouts = true }

          l1.DriverAttributeStandouts = l1?.DriverAttributeStandouts?.DriverAttributeStandout || []
          if (!Array.isArray(l1.DriverAttributeStandouts)) { l1.DriverAttributeStandouts = [l1.DriverAttributeStandouts] }
          if (l1.DriverAttributeStandouts.length > 0) { settingData.DriverAttributeStandouts = true }

          l1.DriverAttributes = l1?.DriverAttributes?.DriverAttributeElement || []
          if (!Array.isArray(l1.DriverAttributes)) { l1.DriverAttributes = [l1.DriverAttributes] }
          if (l1.DriverAttributes.length > 0) { settingData.DriverAttributes = true }

          l1.Verbatim = l1?.Verbatim?.VerbatimElement || []
          if (!Array.isArray(l1.Verbatim)) { l1.Verbatim = [l1.Verbatim] }
          if (l1.Verbatim.length > 0) { settingData.Verbatim = true }
          l1.Verbatim = l1.Verbatim.map(v => {v.EntityType = props?.EntityType || ''; return v})

          l1.SearchKeywords = l1?.SearchKeywords?.string || []
          if (!Array.isArray(l1.SearchKeywords)) { l1.SearchKeywords = [l1.SearchKeywords] }
          if (!settingData.SearchKeywords && l1?.SearchKeywords?.length > 0) { settingData.SearchKeywords = true }
          // l1.SearchKeywords = l1.SearchKeywords.map(v => {v.EntityType = props?.EntityType || ''; return v})
          pptVerbatim.push({name:l1.DriverName, details:(l1.SearchKeywords || l1.Verbatim)})

          l1.DriverRatingImpacts = l1?.DriverRatingImpacts?.DriverRatingImpact || {}
          if (l1?.DriverRatingImpacts?.DriverId) {
            l1.DriverRatingImpactsValue = `${l1.DriverRatingImpacts.RatingImpactAbsolute} | ${l1.DriverRatingImpacts.RatingImpactPercent}%` || ''
            overallRatingValue = overallRatingValue + l1.DriverRatingImpacts.RatingImpactAbsolute
            settingData.DriverRatingImpactsValue = true
          }
          const DriverRatingImpactsExcelValue = l1?.DriverRatingImpacts ? `${l1?.DriverRatingImpacts?.RatingImpactAbsolute} | ${l1?.DriverRatingImpacts?.RatingImpactPercent}%` : ''
          const DriverSubBrandStandoutsExcelValue = l1?.DriverSubBrandStandouts ? l1.DriverSubBrandStandouts.map(d => d.SubBrandName).join(', ') : ''
          const DriverTriggerStandoutsExcelValue = l1?.DriverTriggerStandouts ? l1.DriverTriggerStandouts.map(d => d.TriggerName).join(', ') : ''

          driversExcelDataObj.drivers.push([{value: l1.DriverName}, ...exportExcel_addSegments(l1, true)])
          driversExcelDataObj.driversAndRatingImpacts.push([{value: l1.DriverName}, {value: DriverRatingImpactsExcelValue}, ...exportExcel_addSegments(l1, true)])
          driversExcelDataObj.driverSubBrandStandouts.push([{value: l1.DriverName}, ...exportExcel_addSegments(l1, true), {value: DriverSubBrandStandoutsExcelValue}])
          driversExcelDataObj.driverTriggerStandouts.push([{value: l1.DriverName}, ...exportExcel_addSegments(l1, true), {value: DriverTriggerStandoutsExcelValue}])
          driversExcelDataObj.searchDrivers.push([{value: l1.DriverName}, exportExcel_addPercentage(l1.Percent)])

          if(!!l1.DriverAttributes?.length){
            l1.DriverAttributes.map((l2, j) => {
              l2.levelIndexing = `${l1.levelIndexing}___level2_${j}`
              l2.DriverName = l2.AttributeNames || l2.AttributeName
              l2.selected = false
              l2.motivatorsDetractorSelection = ''
              // l2.IsHighlighted = (l2.IsHighlighted === 'true')
              l2.buttonClicked = 'none'

              l2.Verbatim = (l2?.Verbatim?.VerbatimElement || [])
              if (!Array.isArray(l2.Verbatim)) { l2.Verbatim = [l2.Verbatim] }
              l2.Verbatim = l2.Verbatim.map(v => {v.EntityType = props.EntityType; return v})

              l2.SearchKeywords = (l2?.SearchKeywords?.string || [])
              if (!Array.isArray(l2.SearchKeywords)) { l2.SearchKeywords = [l2.SearchKeywords] }
              // l2.SearchKeywords = l2.SearchKeywords.map(v => {v.EntityType = props.EntityType; return v})

              driversExcelDataObj.attributes.push([{value: l1.DriverName}, {value: l2.DriverName}, ...exportExcel_addSegments(l2, true)])
              driversExcelDataObj.driversWithoutSentiments.push([{value: l1.DriverName}, {value: l2.DriverName}, exportExcel_addPercentage(l2.Percent)])

              if (l2?.DriverSubAttributes) {
                l2.DriverSubAttributes = l2.DriverSubAttributes?.DriverSubAttributeElement || []
                if (!Array.isArray(l2.DriverSubAttributes)) { l2.DriverSubAttributes = [l2.DriverSubAttributes] }
                // l2.DriverSubAttributes = l2.DriverSubAttributes[0].DriverSubAttributeElement

                l2.DriverSubAttributes.map((l3, k) => {
                  l3.levelIndexing = `${l2.levelIndexing}___level3_${k}`
                  l3.DriverName = l3.SubAttributeName
                  l3.selected = false
                  l3.motivatorsDetractorSelection = ''
                  // l3.IsHighlighted = (l3.IsHighlighted === 'true')
                  l3.buttonClicked = 'none'

                  l3.Verbatim = (l3?.Verbatim?.VerbatimElement || [])
                  if (!Array.isArray(l3.Verbatim)) { l3.Verbatim = [l3.Verbatim] }
                  l3.Verbatim = l3.Verbatim.map(v => {v.EntityType = props.EntityType; return v})

                  l3.SearchKeywords = (l3?.SearchKeywords?.string || [])
                  if (!Array.isArray(l3.SearchKeywords)) { l3.SearchKeywords = [l3.SearchKeywords] }
                  // l3.SearchKeywords = l3.SearchKeywords.map(v => {v.EntityType = props.EntityType; return v})

                  driversExcelDataObj.subAttributes.push([{value: l1.DriverName}, {value: l2.DriverName}, {value: l3.DriverName}, ...exportExcel_addSegments(l3, true)])

                  return l3
                })

                l2.DriverAttributes = l2.DriverSubAttributes
              } else {
                // COMMENTING FOR NOW
                // driversExcelDataObj.subAttributes.push([{value: l1.DriverName}, {value: l2.DriverName}, {value: '-'}, {value: 0}, {value: 0}, {value: 0}, {value: 0}, {value: 0}])
                l2.DriverAttributes = []
              }

              return l2
            })
          }
          else {
            // COMMENTING FOR NOW
            // driversExcelDataObj.attributes.push([{value: l1.DriverName}, {value:'-'}, {value:0}, {value:0}, {value:0}, {value:0}, {value:0}])
            // driversExcelDataObj.subAttributes.push([{value: l1.DriverName}, {value:'-'}, {value:'-'}, {value: 0}, {value: 0}, {value: 0}, {value: 0}, {value: 0}])
          }

          return l1
        })

        setDriversExcelData({...driversExcelDataObj, pptVerbatims:pptVerbatim})
        setPptVerbatims(pptVerbatim)
        setOverallRating(overallRatingValue)
        setSettings(settingData)
        setDeepDivePageData(tempResp)
        setDeepDivePageTabWiseData(tempResp)
        setDeepDiveLoading(false)
        updateSubLhsApiLoaded(props)
        
        // AUTO EXPANDING IF LENGTH BELOW 5
        // if (tempResp.length <= 5) {
        //   expandCollapseFunc(true, tempResp)
        // }
        if (props?.driversSegmentsExpanded) {
          expandCollapseFunc(true, tempResp)
        }

      })
      .catch((error) => {
        const resMessage = error
        console.log('ERROR : ', resMessage)
        setDeepDiveLoading(false)
      })
  }

  useEffect(() => {
    if (deepDivePageTabWiseData.length > 0) {

      const sortArrayOfObjects = (arr) => {
        return arr.sort((a, b) => {
          var keyA = a.Percent;
          var keyB = b.Percent;
          // Compare the 2 dates
          if (keyA < keyB) return 1;
          if (keyA > keyB) return -1;
          return 0;
        })
      }

      const updatePositivePercentage = (p) => {
        p.Percent = p.Percent - (p.NegativePercent + p.MixedPercent + p.NeutralPercent)
        p.NegativePercent = p.MixedPercent = p.NeutralPercent = 0
        p.Verbatim = p.Verbatim.filter(f => f.VerbatimSentiment === 'Positive')
        p.selected = false
        p.motivatorsDetractorSelection = 'Positive'
        return p
      }
      const updateNegativePercentage = (p) => {
        p.Percent = p.Percent - (p.PositivePercent + p.MixedPercent + p.NeutralPercent)
        p.PositivePercent = p.MixedPercent = p.NeutralPercent = 0
        p.Verbatim = p.Verbatim.filter(f => f.VerbatimSentiment === 'Negative')
        p.selected = false
        p.motivatorsDetractorSelection = 'Negative'
        return p
      }

      const tabWiseData = deepDivePageTabWiseData.map(d1 => {
        let d = {...d1}
        if(activeTab === 1){
          d = updatePositivePercentage(d)
          d.DriverAttributeStandouts = getObjectAsArray(d.DriverAttributeStandouts)?.filter(f => f.Sentiment === 'Positive') || []
          d.DriverSubBrandStandouts = getObjectAsArray(d.DriverSubBrandStandouts)?.filter(f => f.Sentiment === 'Positive') || []
          d.DriverRatingImpacts = getObjectAsArray(d.DriverRatingImpacts)?.filter(f => f.Sentiment === 'Positive') || []
          d.DriverAttributes = sortArrayOfObjects(d.DriverAttributes.map(e1 => {
            let e = {...e1}
            e = updatePositivePercentage(e)
            e.DriverAttributes = sortArrayOfObjects(e.DriverAttributes.map(f1 => {
              let f = {...f1}
              f = updatePositivePercentage(f)
              return f
            }))
            return e
          }))
        }
        else if(activeTab === 2){
          d = updateNegativePercentage(d)
          d.DriverAttributeStandouts = getObjectAsArray(d.DriverAttributeStandouts)?.filter(f => f.Sentiment === 'Negative') || []
          d.DriverSubBrandStandouts = getObjectAsArray(d.DriverSubBrandStandouts)?.filter(f => f.Sentiment === 'Negative') || []
          d.DriverRatingImpacts = getObjectAsArray(d.DriverRatingImpacts)?.filter(f => f.Sentiment === 'Negative') || []
          d.DriverAttributes = sortArrayOfObjects(d.DriverAttributes.map(e1 => {
            let e = {...e1}
            e = updateNegativePercentage(e)
            e.DriverAttributes = sortArrayOfObjects(e.DriverAttributes.map(f1 => {
              let f = {...f1}
              f = updateNegativePercentage(f)
              return f
            }))
            return e
          }))
        }
        return d
      })

      const finalData = sortArrayOfObjects(tabWiseData)

      const updatingIndexing = finalData.map((l1, i) => {
        l1.levelIndexing = `level1_${i}`
        l1.DriverAttributes.map((l2, j) => {
          l2.levelIndexing = `${l1.levelIndexing}___level2_${j}`
          if (l2?.DriverSubAttributes) {
            l2.DriverSubAttributes.map((l3, k) => {
              l3.levelIndexing = `${l2.levelIndexing}___level3_${k}`
              return l3
            })
          }
          if (l2?.DriverAttributes) {
            l2.DriverAttributes.map((l3, k) => {
              l3.levelIndexing = `${l2.levelIndexing}___level3_${k}`
              return l3
            })
          }
          return l2
        })
        return l1
      })

      setDeepDivePageData(updatingIndexing)
    }
  }, [activeTab])

  useEffect(() => {
    if (deepDivePageData.length === 0) {
      loadDeepDive(false)
    }
  }, [])

  useEffect(() => {
    expandedStatusFunc()
    setEventCount(p => p+1)
  }, [deepDivePageData])

  const legendsArray = [ { label: 'Positive', value: 'Positive' }, { label: 'Neutral', value: 'Neutral' }, { label: 'Mixed', value: 'Mixed' }, { label: 'Negative', value: 'Negative' }]
  
  const ProSimulatorButton = () => {
    return (
        <>{ROLES_MM_PRIMARY.includes(user?.UserType) && settings?.DriverRatingImpactsValue && <ProSimulator {...props} />}</>
    )
  }

  return (
    <>
      {settings && driversExcelData &&
        // <ReportHeading downloadId={props.type} downloadIdData={driversExcelData} downloadPptData={pptVerbatims || []} subHeading={settings.DriverRatingImpactsValue && `Current Rating: ${(5 - overallRating).toFixed(2)}`} {...props} additionalButton={<AdditionalButtons expandedStatus={expandedStatus} expandCollapseFunc={expandCollapseFunc} deepDivePageData={deepDivePageData} expanded={expandedStatus} />} additionalMiddleButton={<ProSimulatorButton />} />
        <ReportHeading resetInsightHeight={eventCount} downloadId={props.type} downloadIdData={driversExcelData} subHeading={settings.DriverRatingImpactsValue && `Current Rating: ${(5 - overallRating).toFixed(2)}`} {...props} additionalButton={<AdditionalButtons expandedStatus={expandedStatus} expandCollapseFunc={expandCollapseFunc} deepDivePageData={deepDivePageData} expanded={expandedStatus} />} additionalMiddleButton={<ProSimulatorButton />} addTable={false} />
      }

      <ReportOuterWrapper>
        <GridOuterWrapper className='gridStyling2' motivatorsDetractors={props.motivatorsDetractors}>
          <ContentWrapper id={props.type} rowlength={deepDivePageData?.length || 0}>
            {props?.motivatorsDetractors && <GridHeaderWrapper className="tabWrapper"><Header options={CONFIG.tabs || undefined} onChange={setActiveTab} value={activeTab} /></GridHeaderWrapper>}
            <LoaderWrapper loading={deepDiveLoading} loadOnlyApi={props.loadOnlyApi}>
              <NanoDataGrid
                rows={deepDivePageData}
                action={<Level1Actions settings={settings} level1HandleClick={level1HandleClick} />}
                showDetails={true}
                theme={`DriverDownloadPPT theme1 type${props.InsightsElementType}`}
                setting={settings}
                isAppendices={!!props?.InsightsElementTypeAppendices}
                showDetailsContent={<NanoDataGridDetailsL1 settings={settings} propsInsightsElementType={props.InsightsElementType} />}
              />
              {!(settings.WithoutSentiments || settings.SearchKeywords) && <LegendWrapper><Legend legends={legendsArray} /></LegendWrapper>}
            </LoaderWrapper>
          </ContentWrapper>
        </GridOuterWrapper>
      </ReportOuterWrapper>
    </>
  )
}

const AdditionalButtons = ({ expandedStatus, expandCollapseFunc, deepDivePageData, expanded }) => {
  const eColor = expandedStatus && { color: '#095BBD' }
  const cColor = expandedStatus === false && { color: '#095BBD' }
  return (
    <>
      <MuiButtonWrap className='BtnExpandAllDrivers'><MuiButton variant='standard' color='primary' sx={eColor} onClick={() => expandCollapseFunc(true, deepDivePageData)}>Expand All</MuiButton></MuiButtonWrap>
      <span style={{ lineHeight: 2 }}>|</span>
      <MuiButtonWrap className='BtnCollapseAllDrivers'><MuiButton variant='standard' color='primary' sx={cColor} onClick={() => expandCollapseFunc(false, deepDivePageData)}>Collapse All</MuiButton></MuiButtonWrap>
      <span style={{lineHeight:2}}>|</span>
      {/* <DownloadExcel downloadRef={tableRef} fileName='report-deep-dive' /> */}
    </>
  )
}

const Level1Actions = ({ row, settings, level1HandleClick }) => {
  let showSearch = true
  let count = {positive:0, negative:0};
  if(row.motivatorsDetractorSelection !== ''){
    for(let i=0; i<row.DriverAttributes.length; i++){
      if(row.DriverAttributes[i].PositivePercent > 0){
        count.positive = count.positive+1;
      } else if(row.DriverAttributes[i].NegativePercent > 0){
        count.negative = count.negative+1;
      }
    }
    if(row.motivatorsDetractorSelection === 'Positive' && count.positive === 0){ showSearch = false }
    else if(row.motivatorsDetractorSelection === 'Negative' && count.negative === 0){ showSearch = false }
  }
  // if(row.motivatorsDetractorSelection !== ''){
  //   let count = 0;
  //   for(let i=0; i<row.DriverAttributes.length; i++){
  //     if(row.DriverAttributes[i].Sentiment === row.motivatorsDetractorSelection){
  //       count = count+1;
  //     }
  //   }
  //   if(count === 0){ showSearch = false }
  // }

  return (
    <ContOuterWrap sx={{ justifyContent: 'right' }} className={`${row.buttonClicked}Selected`}>
      {!!row?.DriverAttributes.length && showSearch && 
        <>
          {settings?.DriverRatingImpactsValue && row?.IsHighlighted && <span className="BtnTrExpand"><IconButton size='small' onClick={(e) => level1HandleClick(e, 'search', row)}><IconReportSearch /></IconButton></span>}
          {!settings?.DriverRatingImpactsValue && <span className="BtnTrExpand"><IconButton size='small' onClick={(e) => level1HandleClick(e, 'search', row)}><IconReportSearch /></IconButton></span>}
        </>
      }
      {/* {!!row?.DriverAttributes.length && <IconButton size='small' onClick={(e) => level1HandleClick(e, 'search', row)}><IconReportSearch /></IconButton>} */}
      {!!row?.Verbatim.length && <span className="BtnTrQuotes"><IconButton size='small' onClick={(e) => level1HandleClick(e, 'more', row)}><IconReportMore /></IconButton></span>}
      {!!row?.SearchKeywords.length && <span className="BtnTrQuotes"><IconButton size='small' onClick={(e) => level1HandleClick(e, 'more', row)}><IconReportMore /></IconButton></span>}
    </ContOuterWrap>
  )
}

export default ReportDeepDiveNew
