import { Box } from '@mui/material'
import React from 'react'
import { HeaderTab, HeaderWrap } from './style'

const DEFAULT_OPTIONS = [
  {id: 0, label: "Comparison View"},
  {id: 1, label: "Segment  View"},
]

const Header = ({onChange, value, options=DEFAULT_OPTIONS}) => {
  return (
    <HeaderWrap className='tabsWrapper'>
      <Box className='tableFirstCol headerFirst'></Box>
      <HeaderTab className='headerLastCol'>
        {
          options?.map((item) => {
            const joinOptions = options.map(o => o.label).join('').replaceAll(' ','')+item.id
            const tutorialClass = item?.tutorial ? 'tabTutorial_'+(item.tutorial).replaceAll(' ', '-').split('-')[0] : '';
            return (
              <Box key={item.id} className={`tab ${(joinOptions)} ${value === item.id ? "active": ""} ${tutorialClass}`}><Box onClick={() => onChange(item.id)}>{item.label}</Box></Box>
            )
          })
        }
      </HeaderTab>
    </HeaderWrap>
  )
}

export default Header