
// import pptxgen from 'pptxgenjs';
import { getImageUrl } from '../util'
import { logoMaven } from '../assets/images'
import { HTMLToJSON } from 'html-to-json-parser'; // ES6


export const pptWidth = 13.3
export const pptHeight = 7.5

export const pxToInch = (px=0) => { return px * 0.0104166667 }
export const inchToPx = (inch=0) => { return inch * 96 }

export const calc = (px=0, type='w', percentage=100) => {
    const inches = pxToInch(px)
    return (type === 'h') ? (percentage/100* (pptHeight - inches)) : (percentage/100* (pptWidth - inches))
}

export const findHeight = (w, h, width) => {
    const height = (width * h) / w;
    return Math.round(height)
}
export const findWidth = (w, h, height) => {
    const width = (height * w) / h;
    return Math.round(width)
}

export const defaultPptxSettings = (pptx, props) => {
    pptx.author = 'MavenMagnet Insights Assistant';
    pptx.company = 'MavenMagnet';
    pptx.revision = '1';
    pptx.subject = 'Annual Report';
    pptx.title = props?.title || 'Title';
    pptx.subTitle = props?.subTitle || 'Sub Title';
    pptx.defineLayout({ name:'Custom', width:pptWidth, height:pptHeight });
    pptx.layout = 'Custom'
    
    pptx.defineSlideMaster(getDefinedSlideMaster('FIRST_SLIDE'))
    pptx.defineSlideMaster(getDefinedSlideMaster('MASTER_SLIDE'))
    pptx.defineSlideMaster(getDefinedSlideMaster('RECOMMENDATION_SLIDE'))
    pptx.defineSlideMaster(getDefinedSlideMaster('BREAKER_SLIDE'))
    pptx.defineSlideMaster(getDefinedSlideMaster('LAST_SLIDE'))

    return pptx
}

export const getDefinedSlideMaster = (slideTitle) => {
    if(slideTitle === 'FIRST_SLIDE'){
        return {
            title: 'FIRST_SLIDE',
            background: { color: 'FFFFFF' },
            objects: [
                { image: { path: getImageUrl('ppt','ppt-bg1', 'jpg'), x: 0, y: 0, w: pptWidth, h: pptHeight } },
                { image: { path: logoMaven, x: pxToInch(71), y: pxToInch(122), w: pxToInch(477), h: pxToInch(70) } },
                { placeholder: { text: '(Title)', options: { name: 'title', type: 'body', x: pxToInch(71), y: pxToInch(365), w:pxToInch(935), h:pxToInch(60), color: '#ffffff', fontSize: 31 } } },
                { placeholder: { text: '(Sub Title)', options: { name: 'subTitle', type: 'body', x: pxToInch(71), y: pxToInch(470), w:pxToInch(935), h:pxToInch(60), color: '#ffffff', fontSize: 23 } } },
                { placeholder: { text: '(Date)', options: { name: 'date', type: 'body', x: pxToInch(71), y: pxToInch(520), w:pxToInch(935), h:pxToInch(60), color: '#ffffff', fontSize: 23 } } },
                // { placeholder: { image: '(Image)', options: { name: 'clientLogo', path: 'clientLogo', x: pxToInch(71), y: pxToInch(122), w: pxToInch(477.35), h: pxToInch(70.67) } } },
            ],
            slideNumber: { x: 0, y: calc(45, 'h'), w:calc(10), color: '#f1f1f1', fontSize: 14, align: 'right' },
        }
    } else if(slideTitle === 'MASTER_SLIDE'){
        return {
            title: 'MASTER_SLIDE',
            background: { color: 'FFFFFF' },
            objects: [
                // { placeholder: { text: '', options: { name: 'title', type: 'body', x: pxToInch(40), y: pxToInch(13), w:calc(50), h:pxToInch(60), color: '#363636', fontSize: 32 } } },
                // { placeholder: { text: '     ', options: { name: 'subTitle', type: 'body', x: pxToInch(5), y: pxToInch(70), color: '#363636', fontSize: 20 } } },
                { rect: { fill: { type: 'solid', color: '#262626' }, w:'100%', h:pxToInch(53), x: 0, y: calc(53, 'h') } },
                { rect: { fill: { type: 'solid', color: '#e88b33' }, x: pxToInch(19), y: pxToInch(120), w:pxToInch(20), h:calc(120, 'h') } },
                { image: { path: logoMaven, x: pxToInch(59), y: calc(40, 'h'), w: pxToInch(202), h: pxToInch(29) } },
                { text: { text: '©mavenmagnet', options: { x: 0, y: calc(25.5, 'h'), w:calc(60), color: '#e88b33', fontSize: 14, align: 'right' } } }
            ],
            slideNumber: { x: 0, y: calc(45, 'h'), w:calc(10), color: '#f1f1f1', fontSize: 14, align: 'right' },
        }
    } else if(slideTitle === 'RECOMMENDATION_SLIDE'){
        return {
            title: 'RECOMMENDATION_SLIDE',
            background: { color: 'FFFFFF' },
            margin: [1, 1, 1, 1],
            // objects: [
            //     { text: { text: "(4.0 inches H)", options: { ...TEXT_PROPS, ...{ x: 0, y: 0, w: 1, h: 4.0 } } } },
            //     { text: { text: "(1.5 inches H)", options: { ...TEXT_PROPS, ...{ x: 1, y: 0, w: 1, h: 1.5 } } } },
            //     { text: { text: "(margin-top)", options: { ...TEXT_PROPS, ...{ x: 0, y: 0, w: "100%", h: MARGINS[0] } } } },
            //     { text: { text: "(margin-btm)", options: { ...TEXT_PROPS, ...{ x: 0, y: 7.5 - MARGINS[2], w: "100%", h: MARGINS[2], flipV: true } } } },
            // ],
            objects: [
                { placeholder: { text: '  ', options: { name: 'title', type: 'body', x: pxToInch(45), y: pxToInch(13), color: '#363636', fontSize: 32 } } },
                { placeholder: { text: '  ', options: { name: 'subTitle', type: 'body', x: pxToInch(45), y: pxToInch(70), w:calc(90), color: '#363636', fontSize: 20 } } },

                { rect: { fill: { type: 'solid', color: '#262626' }, w:'100%', h:pxToInch(53), x: 0, y: calc(53, 'h') } },
                { rect: { fill: { type: 'solid', color: '#FFFFFF' }, x: pxToInch(60), y: pxToInch(120), w: calc(90), h: calc(200, 'h'), rectRadius: 2, line: { color: "#000000", width: 2, beginArrowType: "diamond", endArrowType: "oval" } } },
                { rect: { fill: { type: 'solid', color: '#e88b33' }, x: pxToInch(19), y: pxToInch(120), w:pxToInch(20), h:calc(120, 'h') } },
                { image: { path: logoMaven, x: pxToInch(59), y: calc(40, 'h'), w: pxToInch(202), h: pxToInch(29) } },
                { text: { text: '©mavenmagnet', options: { x: 0, y: calc(25.5, 'h'), w:calc(60), color: '#e88b33', fontSize: 14, align: 'right' } } },
            ],
            slideNumber: { x: 0, y: calc(45, 'h'), w:calc(10), color: '#f1f1f1', fontSize: 14, align: 'right' },
        }
    } else if (slideTitle === 'BREAKER_SLIDE'){
        return {
            title: 'BREAKER_SLIDE',
            background: { color: 'FFFFFF' },
            objects: [
                { placeholder: { text: '', options: { name: 'title', type: 'body', x: pxToInch(40), y: '40%', w:calc(80), h:pxToInch(100), color: '#363636', align: 'center', fontSize: 32, bold:false } } },
                { rect: { fill: { type: 'solid', color: '#262626' }, w:'100%', h:pxToInch(53), x: 0, y: calc(53, 'h') } },
                { rect: { fill: { type: 'solid', color: '#e88b33' }, x: pxToInch(19), y: pxToInch(120), w:pxToInch(20), h:calc(120, 'h') } },
                { image: { path: logoMaven, x: pxToInch(59), y: calc(40, 'h'), w: pxToInch(202), h: pxToInch(29) } },
                { text: { text: '©mavenmagnet', options: { x: 0, y: calc(25.5, 'h'), w:calc(60), color: '#e88b33', fontSize: 14, align: 'right' } } }
            ],
            slideNumber: { x: 0, y: calc(45, 'h'), w:calc(10), color: '#f1f1f1', fontSize: 14, align: 'right' },
        }
    } else if (slideTitle === 'LAST_SLIDE'){
        return {
            title: 'LAST_SLIDE',
            background: { color: 'FFFFFF' },
            objects: [
                { image: { path: getImageUrl('ppt','ppt-bg2', 'jpg'), x: 0, y: 0, w: pptWidth, h: pptHeight } },
                { rect: { fill: { type: 'solid', color: '#262626' }, w:'100%', h:pxToInch(53), x: 0, y: calc(53, 'h') } },
                { text: { text: 'insightsassistant@mavenmagnet.com ', options: { x: 0, y: '50%', w:'100%', color: '#333333', fontSize: 14, align: 'center' } } },
                { text: { text: '©mavenmagnet', options: { x: 0, y: calc(25, 'h'), w:'100%', color: '#e88b33', fontSize: 14, align: 'center' } } }
            ],
            slideNumber: { x: 0, y: calc(45, 'h'), w:calc(10), color: '#f1f1f1', fontSize: 14, align: 'right' },
        }
    } 
}

export const convertHtmlToPptJson = async(id, props) => {
    const bulletList = props?.bulletList || [ 'dot', '25CC', '25AA', 'dot', '25CC', '25AA']
    const componentType = props?.componentType || ''

    const getColSpan = id?.querySelectorAll('tr')?.[0]?.querySelectorAll('td')?.length || 0

    id.innerHTML = id.innerHTML.replace(/<strong>/g, "").replace(/<\/strong>/g, "");
    id.innerHTML = id.innerHTML.replace(/<em>/g, "").replace(/<\/em>/g, "");
    id.innerHTML = id.innerHTML.replace(/<span>/g, "").replace(/<\/span>/g, "");
    id.innerHTML = id.innerHTML.replace(/<hr>/g, "").replace(/<hr\/>/g, "");
    id.innerHTML = id.innerHTML.replace(/<br>/g, "").replace(/<br\/>/g, "");

    const result = await HTMLToJSON(id, false);
    const options = { fontSize: 14, color: "000000" }
    const resultArray = []

    const removeTagsFromStr = (str) => str.replace('\n', '')+' ';

    const singleDataReturn = (data) => {
        const array = []
        for(let i=0; i<data.length; i++){
            if (typeof data[i] === 'object' && data[i]?.content) {
                array.push(data[i].content[0])
            } else if (data[i] === '\n') {
            } else {
                array.push(data[i])
            }
        }
        return array.join('\n\n')
    }

    const convertRteToPptObjects = (data, options, parentType='') => {

        options['fontSize'] = 14;
        // if(data?.type === 'LI'){
        //     console.log('data -------- ', data?.type === 'LI', '&&', data?.content.length > 0, data?.content)
        // }

        if(data?.type === 'LI' && data?.content?.length > 0){
            for(let i=0; i<data.content.length; i++){
                let text = data.content[i]
                if(!data?.ListLevel){ data.ListLevel = 0 }
                options['bold'] = false;
                options['italic'] = false;
                options['indentLevel'] = data.ListLevel
                if(componentType === 'recommendation'){ options['indentLevel'] = data.ListLevel-1 }
                let bullet = { code: bulletList[data?.ListLevel] };
                if(bulletList[data?.ListLevel] === 'none'){
                    options['bold'] = true;
                }


                // options['lineSpacingMultiple'] = 1
                // options['paraSpaceBefore'] = 20
                // options['paraSpaceAfter'] = 20

                // bullet = { code: bulletList[data.ListLevel] }
                // if(data?.ListLevel === 0 || data?.ListLevel === 3){ bullet = { code: "25AA" } }
                // if(data?.ListLevel === 0 || data?.ListLevel === 3){ bullet = { code: "25AA" } }
                // else if(data?.ListLevel === 1 || data?.ListLevel === 4){ bullet = { code: "2605" } }
                // else if(data?.ListLevel === 2 || data?.ListLevel === 5){ bullet = { code: "25CC" } }
                // if(parentType === 'TD'){ bullet = false }

                // if(typeof data.content[i] === 'object' && data.content[i].type === 'STRONG'){
                //     options['bold'] = data.content[i].type === 'STRONG';
                //     text = data.content[i].content[0]
                // } else if(typeof data.content[i] === 'object' && data.content[i].type === 'EM'){
                //     options['italic'] = data.content[i].type === 'EM';
                //     text = data.content[i].content[0]
                // } else if(typeof data.content[i] === 'object' && data.content[i].type === 'UL'){
                if(typeof data.content[i] === 'object' && data.content[i].type === 'UL'){
                    const listLevel = data?.ListLevel+1 || 0
                    data.content[i]['ListLevel'] = listLevel
                    const ulData = data.content[i].content.filter(f => f !== '\n').map(m => {
                        return { ...m, ListLevel: listLevel }
                    })
                    convertRteToPptObjects({type:'UL', content:ulData}, options)
                    continue;
                }
                else if(typeof data.content[i] === 'object' && ['TABLE', 'H4'].includes(data.content[i].type)){
                    // const listLevel = data?.ListLevel+1 || 0
                    // data.content[i]['ListLevel'] = listLevel
                    // const ulData = data.content[i].content.filter(f => f !== '\n').map(m => {
                    //     return { ...m, ListLevel: listLevel }
                    // })
                    convertRteToPptObjects(data.content[i], options, data.type)
                    continue;
                }

                if(data.content[i] !== '\n'){
                    if(text.length > 0){
                        resultArray.push([{ type:data.type, text: removeTagsFromStr(text), options: {...options, colspan:getColSpan, breakLine: false, bullet: bullet} }])
                    }
                }
            }
        }
        else if( ['TABLE', 'THEAD', 'TBODY', 'TFOOT', 'TR', 'TD', 'TH'].includes(data?.type) && data?.content.length > 0){
            if(data.type === 'TR'){
                const trArray = []
                for(let t=0; t<data.content.length; t++){
                    if(data.content[t] === '\n'){
                    } else {
                        const bg = (parentType === 'THEAD') ? 'd3d3d3' : 'ffffff';
                        options['bullet'] = false
                        options['indentLevel'] = 0
                        if(data.content[t]?.attributes){ options = {...options, ...data.content[t]?.attributes}}
                        if(typeof data.content[t].content[0] === 'object'){
                            const objData = data.content[t].content[0].content[0]
                            trArray.push({ parentType:parentType, type:data.type, text: objData+' ', attributes:data.content[t]?.attributes || {}, options: {...options, fill: { color: bg }, border: { pt: 0.5, color: "#d3d3d3" }} })
                        } else {
                            let content = data.content[t].content[0]
                            if( data.content[t].content.length > 1){
                                content = singleDataReturn(data.content[t].content)
                            }
                            trArray.push({ parentType:parentType, type:data.type, text: removeTagsFromStr(content)+' ', attributes:data.content[t]?.attributes || {}, options: {...options, fill: { color: bg }, border: { pt: 0.5, color: "#d3d3d3" }} })
                        }
                    }
                }
                resultArray.push(trArray)
            } 
            else {
                for(let i=0; i<data.content.length; i++){
                    if(typeof data.content[i] === 'object'){
                        convertRteToPptObjects(data.content[i], options, data.type)
                    // } else if(typeof data.content[i] === 'string'){
                    //     if(data.content[i] === '\n' || ['THEAD', 'TBODY', 'TFOOT', 'TR'].includes(data.type)){continue}
                    //     resultArray.push([{ type:data.type, text: 'This should not come ', options: {...options, colspan:getColSpan} }])
                    }
                }
            }
        }
        else if(data?.content){
            for(let i=0; i<data.content.length; i++){
                if(typeof data.content[i] === 'object'){
                    if(data.type === 'UL'){data.ListLevel = 0;}
                    convertRteToPptObjects(data.content[i], options)
                } else if(typeof data.content[i] === 'string'){
                    options['bold'] = data.type === 'STRONG';
                    options['italic'] = data.type === 'EM';
                    options['bullet'] = false;
                    options['breakLine'] = false;
                    if(data.content[i] === '\n'){ options.breakLine = true; options.fontSize = 2}
                    if(data.type === 'P'){ options.breakLine = true}

                    if(['UL'].includes(data.type)){
                        // skip
                        continue
                    } else {
                        // resultArray.push([{ type:data.type, text: 'C2- '+data.content[i]+' ', options: {...options, colspan:getColSpan} }])
                        resultArray.push([{ type:data.type, text: removeTagsFromStr(data.content[i])+' ', options: {...options, colspan:getColSpan} }])
                    }
                }
            }
        }
    }
    convertRteToPptObjects(result, options)

    let myPromise = new Promise(function(myResolve, myReject) {
        setTimeout(function() {
            myResolve(resultArray);
        }, 200);
    });

    return myPromise
}
