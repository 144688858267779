import React, {useState, useRef} from 'react'
import { search, cross } from '../../assets/images/icons'
import { MuiTextField } from '../index'
import { Wrapper, IconButtonWrapper } from './style'

const SearchBox = (props) => {
  const form = useRef()
  const { onChange, onClick } = props

  const [searchBoxValue, setSearchBoxValue] = useState('');
  const [showCancel, setShowCancel] = useState(false);

  const handlerChange = (e) => {
    setSearchBoxValue(e.target.value)
    // onChange(e.target.value)
  }

  const handlerSearchClicked = (e) => {
    e.preventDefault()
    setShowCancel(true)
    onClick(searchBoxValue)
  }

  const handlerClearSearch = () => {
    setSearchBoxValue('')
    setShowCancel(false)
    onClick('')
    // onChange('')
  }

  return (
    <Wrapper>
      <form ref={form} onSubmit={handlerSearchClicked} autoComplete='off'>
        <MuiTextField
          placeholder='Search'
          name='searchBoxValue'
          onChange={handlerChange}
          type='text'
          value={searchBoxValue}
          size='small'
          variant='outlined'
          className='searchBox'
        />
        {!showCancel && searchBoxValue.length<3 && <IconButtonWrapper type='button' disabled={true} sx={{opacity:0.4}}><img src={!showCancel && search} width='15px' /></IconButtonWrapper>}
        {!showCancel && searchBoxValue.length>=3 && <IconButtonWrapper type='submit' onClick={handlerSearchClicked}><img src={search} width='15px' /></IconButtonWrapper>}
        {showCancel && <IconButtonWrapper type="button" onClick={handlerClearSearch}><img src={cross} width='20px' /></IconButtonWrapper>}
      </form>
    </Wrapper>
  )
}
export default SearchBox
