import {
  ArrowBackIos
} from '@mui/icons-material';
import { Typography } from '@mui/material';
import React, { useState } from 'react';
import { MuiTextField } from '../../../../components';
import { IconWithTextWrap, InputWrap, RootWrap } from '../../style';
  
const AdditionalInformation = ({onChange, values, hidden=[], errors}) => {
  const isPresentValue = values?.BusinessObjective || values?.KeyQuestions || values?.TargetSegment;
  const [isActive, setActive] = useState(isPresentValue || false);

  const fields = [
    {
      id: 'BusinessObjective',
      title: 'Business Objective (Optional)',
      type:'area',
      placeholder: 'Enter',
    },
    {
      id: 'KeyQuestions',
      title: 'Key Questions (Optional)',
      type:'area',
      placeholder: 'Enter',
    },
    {
      id: 'TargetSegment',
      title: 'Target Segment & Analysis (Optional)',
      type:'area',
      placeholder: 'Enter',
      maxLength: 500,
    },
    {
      id: 'CategoryName',
      title: 'Category Name (Optional)',
      width: '50%',
      placeholder: 'Enter',
      maxLength: 100
    }
  ]

  return (
    <RootWrap>
      <IconWithTextWrap onClick={() => setActive((prev) => !prev)}>
        <Typography sx={{ color: '#095BBD', fontSize: '14px' }}>
          Additional Information
        </Typography>
        <ArrowBackIos
          fontSize='8px'
          sx={{
            transform: `rotate(${isActive ? '90' : '-90'}deg)`,
            marginTop: isActive ? '6px' : '-6px',
            marginLeft: '6px',
            color: '#095BBD',
          }}
        />
      </IconWithTextWrap>
      {isActive && (
        <div>
          {
            fields.map(field => hidden.includes(field.id) ? null :
                <InputWrap key={field.id} width={field.width}>
                  <MuiTextField 
                    shadow 
                    compact 
                    error={errors?.[field?.id || '']} 
                    maxLength={field.maxLength} 
                    value={values?.[field?.id || ''] || ''} 
                    onChange={onChange} 
                    name={field?.id} 
                    inputType={field.type} 
                    title={field.title} 
                    placeholder={field.placeholder}
                  />
                </InputWrap>
              )
          }
        </div>
      )}
    </RootWrap>
  );
};

export default AdditionalInformation;
