import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import { background } from '../../assets/images'
import { MEDIA } from '../../constants'

// export const ComponentName = styled('')(({theme}) => ({}))
// export const ComponentName = styled('')(({theme}) => ``);

export const PostLoginOuterWrapper = styled(Box)(`
  display: flex;
  height: 100vh;
  // background: #f1f1f1 url(${background}) no-repeat center center fixed;
  background-size:cover;
  display: flex;
  flex-direction: column;
  @media (max-width: ${MEDIA.lg}) {
    height: 100%;
  }
`)

export const LhsWrapper = styled(Box)(`
  display: flex;
  @media (max-width: ${MEDIA.lg}) {
    & .lhsWrapper {
      padding-top: 0;
    }
  }
`)

export const RhsWrapper = styled(Box)( props => `
  display: flex;
  // border: 1px blue solid;
  width: calc(100% - ${props?.showLhsMenu==='reportPage_showLhs' ? '110px' : '40px'});
  flex-direction: column;
  @media (max-width: ${MEDIA.lg}) {
    width: 100%;
    & .lhsWrapper {
      padding-top: 0;
    }
  }
`)

export const ContentWrapper = styled(Box)(`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 60px);
  overflow: auto;

  & .footerWrapper .ReportFooterOverlay{display:none;}
`)

export const ContentInnerWrapper = styled(Box)(`
  min-width:1000px;
  padding:30px 30px 15px;
  min-height: calc(100vh - 120px);
  display:inline-table;
  @media (max-width: ${MEDIA.lg}) {
    padding:20px;
  }
`)
