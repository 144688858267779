import pptxgen from 'pptxgenjs'
// import { getImageUrl } from '../../../util'
// import { logoMaven } from '../../../assets/images'
import { EXPORT_PPT } from '../../../constants'
import { defaultPptxSettings, pptWidth, pptHeight, pxToInch, inchToPx, calc, findHeight, findWidth, getDefinedSlideMaster } from '../../../util/ppt'

const subTitleObject = { valign:'top', type: 'body', x: pxToInch(40), y: pxToInch(70), w: calc(50), h: calc(200, 'h'), color:'#363636', fontSize: 20}
const subSubTitleObject = { valign:'top', type: 'body', x: pxToInch(40), y: pxToInch(70), w: calc(50), h: calc(200, 'h'), color:'#363636', fontSize: 15}

const writePPT = (pptArray, props, logoDetails, mergedSlidesCount, pptIdsType, pptIdsTypes, exportToPptObject, onSuccess) => {
    console.log('pptArray ====> ', pptArray)
    const pptx = new pptxgen();
    const pres = defaultPptxSettings(pptx, props)

    let txt = [[]]
    let _h4_count = 0

    const contentCheckFunc = (content, data, p) => {
        const bulletFunc = (data) => {
            let bulletObj = {}
            let _indentL = 0
            //  2605, 25BA, 25D1, 25AA

            if( ['Recommendation'].includes(p.type) ){
                if(data.tag === 'LI') {bulletObj = {bullet:true}; _indentL = 0}
                else if(data.tag === 'LILI') {bulletObj = {bullet:{code:'25BA'}}; _indentL = 0} // code:arrow-right
                else if(data.tag === 'LILILI') {bulletObj = {bullet:{code:'25CC'}}; _indentL = 1} // code:circle
                else if(data.tag === 'LILILILI') {bulletObj = {bullet:{code:'25AA'}}; _indentL = 2} // code:square
                else if(data.tag === 'LILILILILI') {bulletObj = {bullet:{code:'25CC'}}; _indentL = 3}
                else if(data.tag === 'LILILILILILI') {bulletObj = {bullet:true}; _indentL = 4}
            } else {
                if(data.tag === 'LI') {bulletObj = {bullet:true}; _indentL = 0}
                else if(data.tag === 'LILI') {bulletObj = {bullet:{code:'25CC'}}; _indentL = 1}
                else if(data.tag === 'LILILI') {bulletObj = {bullet:{code:'25AA', color:"#000000"}}; _indentL = 2}
                else if(data.tag === 'LILILILI') {bulletObj = {bullet:true}; _indentL = 3}
                else if(data.tag === 'LILILILILI') {bulletObj = {bullet:{code:'25CC'}}; _indentL = 4}
                else if(data.tag === 'LILILILILILI') {bulletObj = {bullet:{code:'25AA'}}; _indentL = 5}
            }

            const data_text = (data?.text)?.replaceAll('\n','').replaceAll('<hr>','') || ''
            let upperCase = ( ['CategoryPersona'].includes(p.type) ) ? data_text : (data_text).toUpperCase()

            const txtObj = { tag:data.tag, text: data?.tag==='H4' ? upperCase : data_text, options: { fontSize: 14, lineSpacing:23, ...bulletObj, indentLevel: _indentL } }
            if(data.tag === 'P'){
                // NOTE: this will create a new line every time when P tag is added.
                txtObj.options.breakLine = true
            }
            return txtObj
        }
        if(Array.isArray(content)){
            content.map((l2) => {
                if( typeof (l2.content) === 'object'){
                    if(txt[0].length <= 1){_h4_count=0}
                    const obj = bulletFunc(l2)
                    txt[_h4_count].push(obj)
                }
                contentCheckFunc(l2.content, l2, p)
            })
        }
        else if( typeof (content) === 'object'){
            const l2_arr = Object.keys(content)
            l2_arr.map((key2) => {
                contentCheckFunc(data.content[key2].content, data.content[key2], p)
            })
        }
        else if( typeof (content === 'string')){
            if(data.tag === 'H4') {
                if(txt[0].length <= 1){_h4_count=0}
                else{
                    _h4_count = _h4_count+1;
                    txt.push([])
                }
            }
            else if(data.tag === 'HR') {
                if(txt[0].length <= 1){_h4_count=0}
                else{
                    _h4_count = _h4_count+1;
                    txt.push([])
                }
            }
            const obj = bulletFunc(data)
            txt[_h4_count].push(obj)
        }
        return slide
    }

    const clientLogo = { 
        path: logoDetails.logoImgBase64, x: pxToInch(300), y: calc(40, 'h'), 
        w: pxToInch(logoDetails.logoW), h: pxToInch(logoDetails.logoH)
    }

    const heading1_text = (txt) => {
        return txt.toUpperCase()
    }
    const heading1_css = (txt) => {
        let dynamicFontSize = 0
        if(txt.length < 50){ dynamicFontSize = 32}
        else if(txt.length < 60){ dynamicFontSize = 28}
        else if(txt.length < 70){ dynamicFontSize = 24}
        return { type: 'body', valign:'top', x: pxToInch(40), y: pxToInch(13), w:calc(50), h:pxToInch(60), color: '#363636', fontSize: dynamicFontSize }
    }

    const jsonToPptFunc = (p, mixArrayData={insightsArrayData:null, tabsArrayData:null, appendicesArrayData:null, progressionArrayData:null}, oldSlide=null) => {
        let slide
        if(oldSlide){slide = oldSlide}


        const generateRecommendations = () => {
            txt = [[]]
            _h4_count = 0
            const key = Object.keys(p.object)

            try {
                p.object[key[0]].content.map((l1) => {
                    if(l1){
                        Object.keys(l1).map((key1) => {
                            l1[key1].content.map((l2) => {
                                const data = { tag: 'DIV', text: null, content:l2.content }
                                contentCheckFunc(data.content, data, p)
                            })
                        })
                    }
                    return l1
                })

                if(txt.length > 0 && txt[0].length > 0){
                    for(let i=0; i<txt.length; i++){
                        let t = txt[i]
                        let hrArray = [[]]
                        let heading = 'some text'

                        for(let h=0; h<t.length; h++){
                            if(t[h].tag === 'H4'){
                                heading = t[h]
                                continue
                            }
                            if(t[h].tag === 'LILI' && t[h].text === '<hr>'){
                                hrArray.push([])
                                continue                    
                            }
                            hrArray[hrArray.length-1].push(t[h])
                        }

                        for(let j=0; j<hrArray.length; j++){
                            const t2 = hrArray[j]
                            slide = pres.addSlide({masterName: 'MASTER_SLIDE'})
                            slide.addText(heading1_text(p.headings[0]), heading1_css(p.headings[0]))
                            slide.addShape(pres.shapes.RECTANGLE, { x: pxToInch(60), y: pxToInch(160), w: calc(90), h: calc(240, 'h'), fill: "#ffffff", rectRadius: 2, line: { color: "#000000", width: 2, beginArrowType: "diamond", endArrowType: "oval" } })
                            if(p?.headings[1]) { slide.addText( (p.headings[1]), subTitleObject) }
                            slide.addText( [heading] , { valign:'top', x: pxToInch(50), y: pxToInch(115), w: calc(100), h: pxToInch(30), fontSize:28, bold:true } )

                            slide.addText( t2, { valign:'top', x: pxToInch(50), y: pxToInch(165), w: calc(100), h: calc(270, 'h') } ) 
                            slide.addImage(clientLogo)
                        }

                    }
                }
            } catch (error) {
                console.log('TCF 3 > Error --------- ', error)
            }
        }

        const generatePersonaRecommendations = () => {
            txt = [[]]
            _h4_count = 0
            const key = Object.keys(p.object)

            p.object[key[0]].content.map((l1) => {
                Object.keys(l1).map((key1) => {
                    l1[key1].content.map((l2) => {
                        const data = { tag: 'DIV', text: null, content:l2.content }
                        contentCheckFunc(data.content, data, p)
                    })
                })
                return l1
            })

            if(txt.length > 0 && txt[0].length > 0){
                let t3 = []
                let t3ArrayL2 = []
                for(let i=0; i<txt.length; i++){
                    let t = txt[i]
                    let hrArray = [[]]

                    for(let h=0; h<t.length; h++){
                        if(t[h].tag === 'LILI'){
                            t[h].tag = 'LILILI'
                        }
                        else if(t[h].tag === 'LILILI'){
                            t[h].tag = 'LILILILI'
                        }
                        else if(t[h].tag === 'LILILILI'){
                            t[h].tag = 'LILILILILI'
                        }
                        else if(t[h].tag === 'LILILILILI'){
                            t[h].tag = 'LILILILILILI'
                        }
                    }

                    t[0].tag = 'LILI'
                    t[0].text = (t[0].text).replace(/\n/g, '')
                    t[0].options = {...t[1].options, ...{ bullet: { code: "25BA" } }}
                    
                    for(let h=0; h<t.length; h++){
                        if(t[h].tag === 'LILILI'){
                            t[h].options = {...t[h].options, indentLevel:2}
                        }
                        else if(t[h].tag === 'LILILILI'){
                            t[h].options = {...t[h].options, indentLevel:3}
                        }
                        else if(t[h].tag === 'LILILILILI'){
                            t[h].options = {...t[h].options, indentLevel:4}
                        }
                        else if(t[h].tag === 'LILILILILILI'){
                            t[h].options = {...t[h].options, indentLevel:5}
                        }
                    }
                    
                    for(let h=0; h<t.length; h++){
                        if((t[h].tag === 'LILI' && t[h].text === '<hr>') || (t[h].tag === 'LILILI' && t[h].text === '<hr>') || (t[h].tag === 'LILILILI' && t[h].text === '<hr>') || (t[h].tag === 'LILILILILI' && t[h].text === '<hr>') || (t[h].tag === 'LILILILILILI' && t[h].text === '<hr>')){
                            hrArray.push([])
                            continue
                        }
                        // hrArray[hrArray.length-1].push(t[h])
                        hrArray[hrArray.length-1].push(t[h])
                    }

                    t3 = [...t3, ...hrArray[0]]
                    t3ArrayL2.push(...hrArray)
                    // t3.push([...hrArray[0]])
              
                    // if( i === 0 ){
                    //     slide = pres.addSlide({masterName: 'MASTER_SLIDE'})
                    //     slide.addText(heading1_text(p.headings[0]), heading1_css(p.headings[0]))
                    //     slide.addShape(pres.shapes.RECTANGLE, { x: pxToInch(60), y: pxToInch(160), w: calc(90), h: calc(240, 'h'), fill: "#ffffff", rectRadius: 2, line: { color: "#000000", width: 2, beginArrowType: "diamond", endArrowType: "oval" } })
                    //     slide.addText( 'Opportunities' , { valign:'top', x: pxToInch(50), y: pxToInch(115), w: calc(100), h: pxToInch(30), fontSize:15, bold:true } )
                    // }
                    // slide.addText( t3, { valign:'top', x: pxToInch(13), y: pxToInch(165), w: calc(50), h: calc(270, 'h') } )
                }


                
                for(let k=0; k<t3ArrayL2.length; k++){
                    slide = pres.addSlide({masterName: 'MASTER_SLIDE'})
                    slide.addText(heading1_text(p.headings[0]), heading1_css(p.headings[0]))
                    slide.addShape(pres.shapes.RECTANGLE, { x: pxToInch(60), y: pxToInch(160), w: calc(90), h: calc(240, 'h'), fill: "#ffffff", rectRadius: 2, line: { color: "#000000", width: 2, beginArrowType: "diamond", endArrowType: "oval" } })
                    slide.addText( 'Opportunities' , { valign:'top', x: pxToInch(50), y: pxToInch(115), w: calc(100), h: pxToInch(30), fontSize:15, bold:true } )
            
                    slide.addText( t3ArrayL2[k], { valign:'top', x: pxToInch(13), y: pxToInch(165), w: calc(50), h: calc(270, 'h') } )
                    slide.addImage(clientLogo)
                }
            }
        }

        if( ['BusinessObjective__'].includes(p.type) && p.base64Arr.length === 0){
            txt = [[]]
            _h4_count = 0
            p.object['DIV1'].content.map((l1) => {
                Object.keys(l1).map((key1) => { contentCheckFunc(l1[key1].content, l1[key1], p) })
                return l1
            })
            if(txt.length > 0 && txt[0].length > 0){
                for(let i=0; i<txt.length; i++){
                    let t = txt[i]
                    slide = pres.addSlide({masterName: 'MASTER_SLIDE'})
                    slide.addText(heading1_text(p.headings[0]), heading1_css(p.headings[0]))
                    // if(p?.headings[1]) { slide.addText(p.headings[1] || '', { placeholder: 'subTitle' }) }
                    if(p?.headings[1]) { slide.addText( (p.headings[1]), subTitleObject) }
                    slide.addText( (t), { valign:'top', x: pxToInch(55), y: pxToInch(130), w: calc(80), h: calc(200, 'h') } )
                    slide.addImage(clientLogo)
                }
            }
        }
        else if( ['Recommendation__'].includes(p.type) && p.base64Arr.length === 0){
            generateRecommendations()
        }
        else if( ['BusinessObjective'].includes(p.type)){
            const finalResultArrayContextApi = exportToPptObject[p.exportToPptSlideId] // NOTE: THIS IS SHOWING SOME RANDOM ISSUES IN THE SAVED DATA
            const finalResultArray = finalResultArrayContextApi || JSON.parse(localStorage.getItem(p.exportToPptSlideId))
            localStorage.removeItem(p.exportToPptSlideId)

            slide = pres.addSlide({masterName: 'MASTER_SLIDE'})
            slide.addText(heading1_text(p.headings[0]), heading1_css(p.headings[0]))
            if(p?.headings[1]) { slide.addText( (p.headings[1]), subTitleObject) }
            slide.addImage(clientLogo)

            slide.addTable( finalResultArray, {
                x: 0.5, y: 1.3, w: '95%', color: '363636', margin:[0.04, 0.05, 0.04, 0.05], border:0,
                autoPage: true, newSlideStartY:1.3
            });
            slide.newAutoPagedSlides.forEach((s) => s.addImage(clientLogo) );
            slide.newAutoPagedSlides.forEach((s) => s.addText(heading1_text(p.headings[0]), heading1_css(p.headings[0])) );
        }
        else if( ['Recommendation'].includes(p.type)){
            const finalResultArrayContextApi = exportToPptObject[p.exportToPptSlideId] // NOTE: THIS IS SHOWING SOME RANDOM ISSUES IN THE SAVED DATA

            const finalResultArray = finalResultArrayContextApi || JSON.parse(localStorage.getItem(p.exportToPptSlideId))
            localStorage.removeItem(p.exportToPptSlideId)

            if(finalResultArray?.length > 0){
                
                for(let i=0; i<finalResultArray.length; i++){
                    const data = finalResultArray[i]
                    let heading = ''
                    if(finalResultArray[i][0][0].type === 'H4'){
                        heading = finalResultArray[i][0][0].text
                        data.shift()
                    }

                    if(data.length > 0){
                        slide = pres.addSlide({masterName: 'RECOMMENDATION_SLIDE'});
                        // slide.addText(heading1_text(heading), heading1_css(p.headings[0]))
                        slide.addTable(data, {
                            // x: 0.55, margin:[0.04, 0.05, 0.04, 0.05] // TODO
                            x: 0.68, y: 1.32, w: '92%', margin:0.05, border:0,
                            autoPage: true, newSlideStartY:1.32, 
                            // autoPageRepeatHeader:true, autoPageHeaderRows:1,
                            // verbose: true
                        });
                        slide.addImage(clientLogo)
                        slide.addText(heading1_text(p.headings[0]), { placeholder: 'title' })
                        // slide.addText(heading1_text(p.headings[0]), heading1_css(p.headings[0]))
                        slide.addText(heading, { placeholder: "subTitle" })
                        slide.newAutoPagedSlides.forEach((s) => s.addText(heading1_text(p.headings[0]), { placeholder: 'title' }) );
                        // slide.newAutoPagedSlides.forEach((s) => s.addText(heading1_text(p.headings[0]), heading1_css(p.headings[0])) );
                        slide.newAutoPagedSlides.forEach((s) => s.addText(heading, { placeholder: "subTitle" }) );
                        slide.newAutoPagedSlides.forEach((s) => s.addImage(clientLogo) );
                    }
                }
            }
        }
        else if(
            [...EXPORT_PPT.reportSegment, ...EXPORT_PPT.deepDiveDrivers, ...EXPORT_PPT.deepDiveSegments, 'CompetitiveLandscape', 'FactorInterlinkage', 'SegmentDriversOnlyAnalysis', 'InsightsMiningFrameworkWithoutSentiments', 'TopRelativeBrandsAnalysis', 'Drivers_____'].includes(p.type)
            || [...EXPORT_PPT.deepDiveDriversAppendices, ...EXPORT_PPT.deepDiveSegmentsAppendices, 'Appendices_____'].includes(p?.InsightsElementTypeAppendices)
            || [...EXPORT_PPT.deepDiveProgression, ...EXPORT_PPT.deepDiveSegmentsProgression, 'Progression_____'].includes(p?.InsightsElementTypeProgression)
            || ['BusinessObjective', 'Recommendation'].includes(p.type)
            // || ['BrandHealthSetup', 'BrandCategoryOverview'].includes(p.type) // THIS IS FOR ADDING THE TABLE IN EXPORT TO PPT AS IMAGES
            || ['BrandCategoryOverview'].includes(p.type) // THIS IS FOR ADDING THE TABLE IN EXPORT TO PPT AS IMAGES
        ) {

            const pptHeight = 535
            let totalImgHeight = 0
            let base64ArrLength = (p.legendFlag || p.footerPptFlag) ? p.base64Arr.length-1 : p.base64Arr.length
            let legendDataIndex = (p.legendFlag || p.footerPptFlag) ? p.base64Arr.length-1 : 0
            let headerDataIndex = p?.headerPptDataIndex || 0 // NEED TO UPDATE THIS DYNAMICALLY
            // if(p.headerPptFlag){ base64ArrLength = p.base64Arr.length-1 }

            let imageTopPosition = 120
            let insightsHeight = 0

            // if( [...EXPORT_PPT.deepDiveDriversAppendices, ...EXPORT_PPT.deepDiveSegmentsAppendices, ...EXPORT_PPT.deepDiveSegmentsProgression].includes(p?.InsightsElementTypeAppendices) ){
            //     slide = pres.addSlide({masterName: 'BREAKER_SLIDE'})
            //     slide.addText(('## '+p.headings[0]).toUpperCase(), { placeholder: 'title' })
            //     slide.addImage(clientLogo)
            // }

            for(let s=0; s<base64ArrLength; s++){
                if((insightsHeight + totalImgHeight + p.heightArr[s]) > (pptHeight)){ // 540 max height
                    totalImgHeight = 0
                    insightsHeight = 0
                }
                if(s>1 && p.hasHeaderClassForNewSlideArr[s] === true){
                    // This will create a new slide if the header has this [class] => hasHeaderClassForNewSlideArr[s]
                    totalImgHeight = 0
                }

                if(totalImgHeight === 0){
                    if(s===0 && mixArrayData?.insightsArrayData?.length <= 3){
                        // THIS WILL ADD INSIGHTS IN THE CURRENT SLIDE
                        insightsHeight = (mixArrayData?.insightsArrayData?.length * 25) + 20
                    }
                    else{
                        insightsHeight = 0
                        slide = pres.addSlide({masterName: 'MASTER_SLIDE'})
                        slide.addText(heading1_text(p.headings[0]), heading1_css(p.headings[0]))
                        
                        if(p?.headings[1] && s===1 && ['CampaignTriggers'].includes(p.type) ){
                            slide.addText( (p.headings[1] ), subTitleObject)
                        }
                        else if(p?.headings[1]) { slide.addText( (p.headings[1] ), subTitleObject) }

                        // if(p?.pptSubHeadingFlag) {
                        //     slide.addText( (p.headings[1], p.pptSubHeadingArray[s]), subTitleObject)
                        // }
                        slide.addImage(clientLogo)
                        
                        // if(s>headerDataIndex && p.headerPptFlag){
                        if(s>headerDataIndex && p.headerPptFlag ){
                            slide.addImage({ path: p.base64Arr[headerDataIndex], x: pxToInch(55), y: pxToInch(imageTopPosition + insightsHeight + totalImgHeight), w: pxToInch(p.widthArr[headerDataIndex]), h: pxToInch(p.heightArr[headerDataIndex]) })
                            totalImgHeight = totalImgHeight + p.heightArr[headerDataIndex]
                        }
                    }
                    if(p.legendFlag) {
                        slide.addImage({ path: p.base64Arr[legendDataIndex], x: pxToInch(55), y: pxToInch(120+525), w: pxToInch(p.widthArr[legendDataIndex]), h: pxToInch(p.heightArr[legendDataIndex]) })
                    }
                }

                if(p.heightArr[s] === 3){
                    // THIS WILL BE CONSIDERED AS THIS IS A PAGE BREAK
                    insightsHeight = 0
                    totalImgHeight = p.heightArr[s]
                    slide = pres.addSlide({masterName: 'MASTER_SLIDE'})
                    slide.addText(heading1_text(p.headings[0]), heading1_css(p.headings[0]))
                    if(p?.headings[1]) { slide.addText( (p.headings[1]), subTitleObject) }
                    // if(p?.pptSubHeadingFlag) {
                    //     slide.addText( (p.headings[1], p.pptSubHeadingArray[s]), subTitleObject)
                    // }
                    slide.addImage(clientLogo)
                    if(p.legendFlag) {
                        slide.addImage({ path: p.base64Arr[legendDataIndex], x: pxToInch(55), y: pxToInch(120+525), w: pxToInch(p.widthArr[legendDataIndex]), h: pxToInch(p.heightArr[legendDataIndex]) })
                    }
                }

                if(p?.pptSubHeadingFlag) {
                    const txt1 = p?.headings[1] ? p.headings[1]+' ' : ''
                    let txt2 = txt1 + p.pptSubHeadingArray[s]
                    if( ['CampaignTriggers'].includes(p.type) ){
                        if( s===2 ){
                            // HARD CODING TO FIX THE BUG, ITS ONLY FOR CampaignTriggers
                            txt2 = ''
                        } else if( s===3 ){
                            // HARD CODING TO FIX THE BUG, ITS ONLY FOR CampaignTriggers
                            txt2 = txt1 + p.pptSubHeadingArray[s-1]
                        } else if( s>=4 && txt2 === '' && totalImgHeight === 0){
                            for(let i = s; i >= 2; i--) {
                                if(p.pptSubHeadingArray[i] !== ''){
                                    txt2 = txt1 + p.pptSubHeadingArray[i]
                                    break
                                }
                            }
                        }
                    }
                    slide.addText( txt2, subTitleObject)
                }

                slide.addImage({ path: p.base64Arr[s], x: pxToInch(55), y: pxToInch(imageTopPosition + insightsHeight + totalImgHeight), w: pxToInch(p.widthArr[s]), h: pxToInch(p.heightArr[s]) })
                totalImgHeight = totalImgHeight + p.heightArr[s]

                const nextHeightArrValue = p.heightArr[s+1] || 300
                if(p.footerPptFlag && (((totalImgHeight + nextHeightArrValue) > pptHeight) || p.hasHeaderClassForNewSlideArr[s+1] === true) || p.hasHeaderClassForNewSlideArr[s] === true) {
                    if( p.heightArr[s] !== p.heightArr[legendDataIndex] ){
                        slide.addImage({ path: p.base64Arr[legendDataIndex], x: pxToInch(55), y: pxToInch(imageTopPosition + insightsHeight + totalImgHeight), w: pxToInch(p.widthArr[legendDataIndex]), h: pxToInch(p.heightArr[legendDataIndex]) })
                    }
                }
                // else if(p.hasHeaderClassForNewSlideArr[s+1] === true) {
                //     slide.addImage({ path: p.base64Arr[legendDataIndex], x: pxToInch(55), y: pxToInch(imageTopPosition + insightsHeight + totalImgHeight), w: pxToInch(p.widthArr[legendDataIndex]), h: pxToInch(p.heightArr[legendDataIndex]) })
                // }

                // slide.addImage({ path: p.base64Arr[s], x: pxToInch(55), y: pxToInch(imageTopPosition + insightsHeight + totalImgHeight), w: pxToInch(p.widthArr[s]), h: pxToInch(p.heightArr[s]) })
                // totalImgHeight = totalImgHeight + p.heightArr[s]
            }

            if( ['CategoryPersona'].includes(p.type) ){
                generatePersonaRecommendations()
            }
        }
        else {
            if(mixArrayData?.appendicesArrayData?.length){
                // THIS WILL TAKE TO CURRENT SLIDE
                const distance = (mixArrayData?.appendicesArrayData?.length * 25)
                if(p.height < 430){
                    slide.addImage({ path: p.base64, x: pxToInch(55), y: pxToInch(140+distance), w: pxToInch(p.width), h: pxToInch(p.height) })
                }
                else{
                    const pw = findWidth(p.width, p.height, p.height-distance)
                    const ph = p.height - distance
                    slide.addImage({ path: p.base64, x: pxToInch(55), y: pxToInch(140+distance), w: pxToInch(pw), h: pxToInch(ph) })
                }
            }
            else if(mixArrayData?.progressionArrayData?.length){
                // THIS WILL TAKE TO CURRENT SLIDE
                const distance = (mixArrayData?.progressionArrayData?.length * 25)
                if(p.height < 430){
                    slide.addImage({ path: p.base64, x: pxToInch(55), y: pxToInch(140+distance), w: pxToInch(p.width), h: pxToInch(p.height) })
                }
                else{
                    const pw = findWidth(p.width, p.height, p.height-distance)
                    const ph = p.height - distance
                    slide.addImage({ path: p.base64, x: pxToInch(55), y: pxToInch(140+distance), w: pxToInch(pw), h: pxToInch(ph) })
                }
            }
            else if(mixArrayData?.tabsArrayData?.length){
                // THIS WILL TAKE TO CURRENT SLIDE
                const distance = (mixArrayData?.tabsArrayData?.length * 25)
                if(p.height < 430){
                    slide.addImage({ path: p.base64, x: pxToInch(55), y: pxToInch(140+distance), w: pxToInch(p.width), h: pxToInch(p.height) })
                }
                else{
                    const pw = findWidth(p.width, p.height, p.height-distance)
                    const ph = p.height - distance
                    slide.addImage({ path: p.base64, x: pxToInch(55), y: pxToInch(140+distance), w: pxToInch(pw), h: pxToInch(ph) })
                }
            }
            else if(mixArrayData?.insightsArrayData?.length <= 3){
                // THIS WILL TAKE TO CURRENT SLIDE
                const distance = (mixArrayData?.insightsArrayData?.length * 25)
                if(p.height < 430){
                    slide.addImage({ path: p.base64, x: pxToInch(55), y: pxToInch(140+distance), w: pxToInch(p.width), h: pxToInch(p.height) })
                }
                else{
                    const pw = findWidth(p.width, p.height, p.height-distance)
                    const ph = p.height - distance
                    slide.addImage({ path: p.base64, x: pxToInch(55), y: pxToInch(140+distance), w: pxToInch(pw), h: pxToInch(ph) })
                }
            }
            else {
                // THIS WILL TAKE TO NEW SLIDE
                slide = pres.addSlide({masterName: 'MASTER_SLIDE'})
                slide.addText(heading1_text(p.headings[0]), heading1_css(p.headings[0]))
                // if(p?.headings[1]) { slide.addText( (p.headings[1]), subTitleObject) }
                if(p?.headings[1]) {
                    slide.addText( (p.headings[1]), (p?.headings[1].length < 100) ? subTitleObject : subSubTitleObject)
                }
                slide.addImage({ path: p.base64, x: pxToInch(55), y: pxToInch(120), w: pxToInch(p.width), h: pxToInch(p.height) })
                slide.addImage(clientLogo)
            }
        }
        // return slide
    }

    const getHeadings = (p) => {
        p.headings = []
        p.objectHeadingInfo.DIV1.content.map((l1) => {
            const keys = Object.keys(l1)
            if(keys.length === 0){
                p.headings.push(p.objectHeadingInfo.DIV1.text)
            }
            else {
                keys.map((key1) => { p.headings.push(l1[key1].text); return key1})
            }
            return l1
        })
        return p
    }

    let slide = pres.addSlide({masterName: 'FIRST_SLIDE' })
    slide.addText(props.title, { placeholder: 'title' })
    slide.addText(props.subTitle, { placeholder: 'subTitle' })
    slide.addText(props.lastUpdated, { placeholder: 'date' })
    slide.addImage({ path: logoDetails.logoImg, x: pxToInch(85), y: calc(90, 'h'), w: pxToInch(findWidth(logoDetails.logoW, logoDetails.logoH, 50)), h: pxToInch(50) })

    // console.time();
    const pptArrayFunc = (i) => {
        const p = getHeadings(pptArray[i])
        const insightsArray = Object.keys(p.objectHeadingInsightsInfo)
        const tabsArray = Object.keys(p.objectHeadingTabsInfo)
        const appendicesArray = Object.keys(p.objectHeadingAppendicesInfo)
        const progressionArray = Object.keys(p.objectHeadingProgressionInfo)
        let insightsArrayData = null
        let tabsArrayData = null
        let appendicesArrayData = null
        let progressionArrayData = null
        let slide

        if(appendicesArray?.length > 0){
            txt = [[]]
            _h4_count = 0
            const cont = p.objectHeadingAppendicesInfo[appendicesArray[0]].content;
            for(let c=0; c<cont.length; c++){
                const l1 = cont[c]
                const arr = Object.keys(l1)
                for(let a=0; a<arr.length; a++){
                    contentCheckFunc(l1[arr[a]].content, l1[arr[a]], p)
                }
            }

            if(txt.length > 0 && txt[0].length > 0){
                for(let i=0; i<txt.length; i++){
                    let t = txt[i]
                    appendicesArrayData = t
                    slide = pres.addSlide({masterName: 'BREAKER_SLIDE'})
                    slide.addText((t[0].text).toUpperCase(), { placeholder: 'title' })
                    slide.addImage(clientLogo)
                }
            }
        }

        if(progressionArray?.length > 0){
            txt = [[]]
            _h4_count = 0
            const cont = p.objectHeadingProgressionInfo[progressionArray[0]].content;
            for(let c=0; c<cont.length; c++){
                const l1 = cont[c]
                const arr = Object.keys(l1)
                for(let a=0; a<arr.length; a++){
                    contentCheckFunc(l1[arr[a]].content, l1[arr[a]], p)
                }
            }

            if(txt.length > 0 && txt[0].length > 0){
                for(let i=0; i<txt.length; i++){
                    let t = txt[i]
                    progressionArrayData = t
                    slide = pres.addSlide({masterName: 'BREAKER_SLIDE'})
                    slide.addText((t[0].text).toUpperCase(), { placeholder: 'title' })
                    slide.addImage(clientLogo)
                }
            }
        }

        if(tabsArray?.length > 0){
            txt = [[]]
            _h4_count = 0
            const cont = p.objectHeadingTabsInfo[tabsArray[0]].content;
            for(let c=0; c<cont.length; c++){
                const l1 = cont[c]
                const arr = Object.keys(l1)
                for(let a=0; a<arr.length; a++){
                    contentCheckFunc(l1[arr[a]].content, l1[arr[a]], p)
                }
            }

            if(txt.length > 0 && txt[0].length > 0){
                for(let i=0; i<txt.length; i++){
                    let t = txt[i]
                    tabsArrayData = t
                    slide = pres.addSlide({masterName: 'BREAKER_SLIDE'})
                    slide.addText((t[0].text).toUpperCase(), { placeholder: 'title' })
                    slide.addImage(clientLogo)
                }
            }
        
        }

        if( [...EXPORT_PPT.deepDiveDriversAppendices, ...EXPORT_PPT.deepDiveSegmentsAppendices].includes(p?.InsightsElementTypeAppendices) ){
            // ADDING BLANK SLIDE JUST FOR APPENDICES HEADING
            slide = pres.addSlide({masterName: 'BREAKER_SLIDE'})
            slide.addText((p.headings[0]).toUpperCase(), { placeholder: 'title' })
            slide.addImage(clientLogo)
        }

        if( [...EXPORT_PPT.deepDiveSegmentsProgression].includes(p?.InsightsElementTypeProgression) ){
            // ADDING BLANK SLIDE JUST FOR PROGRESSION HEADING
            slide = pres.addSlide({masterName: 'BREAKER_SLIDE'})
            slide.addText((p.headings[0]).toUpperCase(), { placeholder: 'title' })
            slide.addImage(clientLogo)
        }

        if(insightsArray?.length === 2){
            txt = [[]]
            _h4_count = 0
            const cont = p.objectHeadingInsightsInfo[insightsArray[1]].content;
            console.log('insightsArray?.length === 2 | cont = ', cont)
            for(let c=0; c<cont.length; c++){
                const l1 = cont[c]
                const arr = Object.keys(l1)
                for(let a=0; a<arr.length; a++){
                    contentCheckFunc(l1[arr[a]].content, l1[arr[a]], p)
                }
            }
            
            if(txt.length > 0 && txt[0].length > 0){
                for(let i=0; i<txt.length; i++){
                    let t = txt[i]
                    let insightsText = ' INSIGHTS'
                    insightsArrayData = t
                    if(insightsArrayData <= 3){
                        for(let x=0; x<t.length; x++){
                            if(t[x].length > 145){ console.log('WIDTH EXCEEDED', t[x]) }
                        }
                    }
                    if(t.length <= 3){insightsText = ''}
                    // const lineSpacing = (insightsArrayData.length <= 3) ? 5 : 8
                    slide = pres.addSlide({masterName: 'MASTER_SLIDE'})
                    slide.addText((heading1_text(p.headings[0]) || p.headings[0])?.replace(/\n/, insightsText), heading1_css(p.headings[0]))
                    // if(p?.headings[1]) { slide.addText( (p.headings[1]), subTitleObject) }
                    if(p?.headings[1]) {
                        slide.addText( (p.headings[1]), subTitleObject)
                    }
                    console.log('(t) = ', t, { valign:'top', x: pxToInch(55), y: pxToInch(110), w: calc(80), h: calc(200, 'h'), color:'#000000' })
                    slide.addText( (t), { valign:'top', x: pxToInch(55), y: pxToInch(110), w: calc(80), h: calc(200, 'h'), color:'#000000' } )
                    slide.addImage(clientLogo)
                }
            }
        }

        if(p?.base64?.length > 0 && p?.width > 100 && p?.height > 50 ){
            jsonToPptFunc(p, {insightsArrayData:insightsArrayData, appendicesArray:appendicesArray, progressionArray:progressionArray, tabsArray:tabsArray}, slide)
        }
        else if(p?.base64Arr?.length > 0 ){
            jsonToPptFunc(p, {insightsArrayData:insightsArrayData, appendicesArray:appendicesArray, progressionArray:progressionArray, tabsArray:tabsArray}, slide)
        }

        if(i < pptArray.length - mergedSlidesCount){
            pptArrayFunc(i+1)
        } else {
            // return false
            pres.addSlide({masterName: 'LAST_SLIDE' })
            pres.writeFile({ fileName: `${pptIdsType}_${(props.title).replaceAll(' ', '-').toLowerCase()}.pptx` })

            if(pptIdsTypes.length === 2 && pptIdsType === 'study'){
                onSuccess('studyDone')
            } else {
                onSuccess('done')
            }
        }
    }

    pptArrayFunc(0)
}

export {
    pptWidth,
    pptHeight,
    pxToInch,
    inchToPx,
    calc,
    findHeight,
    findWidth,
    subTitleObject,
    writePPT
}
