
import { Box, Checkbox, Divider, Drawer, IconButton, Skeleton } from '@mui/material'
import React, { useEffect, useState, memo } from 'react'
import { checkboxChecked, checkboxUnchecked, close, download, iconDownloadWhite } from '../../../../assets/images/icons'
import { BlockingLoader, MuiButton, MuiImage, Loader } from '../../../../components'
import reportsService from '../../../../services/reports.service'
import { useGlobalStore } from '../../../../store'
import { EXPORT_PPT } from '../../../../constants'
import { FormGroupLabelWrap, Heading, SubHeading, PPTBody, PPTHeader, PPTWrap, SubLHSItemWrapper, SubLHSOuterWrap, SubLHSItem, SubLHSWrap, HeadingForDrawer, Breaker, PPTBodyTabs, TabLink } from './styles'


const Listing = ({ className, isLoading, data, onChange, lhsItem, subLhsArray }) => {
    if( data.length === 0 ) return false
    return (
      <Box>
        <Heading>
          { isLoading ? <Skeleton width={160} /> : lhsItem?.title }
        </Heading>
        <SubLHSWrap>
          {
            data.map((subLHSItem, jIndex) => {
              const isPresent = subLhsArray.find(item => item.InsightsElementId === subLHSItem.InsightsElementId)
              return (
                <React.Fragment key={'content_'+jIndex}>
                  { subLHSItem.groupName && <SubHeading key={'subHeadingL1_'+jIndex}>{ subLHSItem.groupName }</SubHeading> }
                  <SubLHSItem key={'contentL1_'+jIndex}>
                    { isLoading
                      ? <Box sx={{ display: "flex" }}>
                        <Skeleton width={15} sx={{ marginRight: "10px" }} />
                        <Skeleton width={110} />
                      </Box>
                      : <FormGroupLabelWrap
                        label={subLHSItem.Title}
                        onChange={() => onChange(subLHSItem)}
                        control={<Checkbox
                          icon={<MuiImage src={checkboxUnchecked} width="14px" />}
                          checkedIcon={<MuiImage src={checkboxChecked} width="14px" />}
                          checked={!!isPresent} />}
                      />
                    }
                  </SubLHSItem>
                </React.Fragment>
              )
            })
          }
        </SubLHSWrap>
      </Box>
    )
}
  
  
export default memo(Listing)