import { Box, Grid, IconButton, Typography } from '@mui/material'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import {
  BlockingLoader,
  MuiButton,
  MuiImage,
  MuiSelect,
  MuiTextField,
} from '../../components'
import {
  ActionRowWrapper,
  ActionRow,
  AddMoreButton,
  Divider,
  Footer,
  FormOuterWrap,
  FormWrap,
  ReadOnlyTypography,
  StyledGrid,
  FormError
} from './style'
import * as Yup from 'yup'
import UserManagementService from '../../services/userManagement.service'
import { deleteIcon, edit } from '../../assets/images/icons'
import DeleteConfirm from './deleteConfirm/deleteConfirm'
import { PERMISSION } from '../../constants'
import { useGlobalStore } from '../../store'

const schema = Yup.object({
  UserEmail: Yup.string().email('Email must be a valid email').required('Email is a required field'),
  UserName: Yup.string().required('Name is a required field'),
  UserType: Yup.string().required('User Type is a required field'),
})

const setInitialValues = (data) => {
  return {
    CanInitiateNewProject: data ? data?.CanInitiateNewProject ? "enabled" : 'disabled' : '',
    UserName: data?.UserName || '',
    UserEmail: data?.UserEmail || '',
    UserType: data?.UserType || '',
    UsingDefaultPassword: data?.UsingDefaultPassword || '',
    MarketBrandCategoryPermissions: {
      MarketBrandCategoryPermission:
        data?.MarketBrandCategoryPermissions?.MarketBrandCategoryPermission &&
          Array.isArray(
            data?.MarketBrandCategoryPermissions?.MarketBrandCategoryPermission
          )
          ? data?.MarketBrandCategoryPermissions?.MarketBrandCategoryPermission
          : !!data?.MarketBrandCategoryPermissions
            ?.MarketBrandCategoryPermission ? [
            data?.MarketBrandCategoryPermissions
              ?.MarketBrandCategoryPermission,
          ] : [],
    },
  }
}

const UserForm = ({ data, refetch, onClose, userTypes }) => {
  const [isEditMode, setEditMode] = useState(false)
  const [isDeleteModalActive, setDeleteModalActive] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [isMarketCategoryPermissionsError, setMarketCategoryPermissionsError] = useState(false)

  // const { enqueueSnackbar } = useSnackbar()
  const { user, company } = useGlobalStore()
  const [isReadOnly, setReadOnly] = useState(false)
  const validation = (values) => {
    const error = {}
    if(values.UserType === 'Admin'){
      setMarketCategoryPermissionsError(false)
    } else{
      setMarketCategoryPermissionsError(values.MarketBrandCategoryPermissions.MarketBrandCategoryPermission.length === 0)
    }
    return error
  }

  const onSubmit = async (values) => {
    try {
      const MarketBrandCategoryPermissionsNew = []
      if(values?.MarketBrandCategoryPermissions?.MarketBrandCategoryPermission?.length){
        for(let i=0; i<values.MarketBrandCategoryPermissions.MarketBrandCategoryPermission.length; i++){
          MarketBrandCategoryPermissionsNew.push({
            MarketBrandCategoryPermission:{
              Market: values.MarketBrandCategoryPermissions.MarketBrandCategoryPermission[i].Market,
              BrandCategory: values.MarketBrandCategoryPermissions.MarketBrandCategoryPermission[i].BrandCategory
            }
          })
        }
      } else {
        MarketBrandCategoryPermissionsNew.push({
          MarketBrandCategoryPermission:{
            Market: 'All',
            BrandCategory: 'All'
          }
        })
      }

      values.MarketBrandCategoryPermissions = MarketBrandCategoryPermissionsNew
      
      setLoading(true)
      const obj = {
        user: { ...values, CompanyId: company.CompanyId },
        accountCreatorName: user?.UserName,
        delete: false
      }

      const resp = await UserManagementService.setUsers(obj)
      refetch()
      const closeData = {...values, UserId:resp}
      onClose(closeData)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: setInitialValues(),
    validationSchema: schema,
    validate: (values) => validation(values),
    onSubmit: (values) => {
      const updatedValues = { ...values }
      updatedValues.UsingDefaultPassword = true;
      if (data?.UserId) {
        updatedValues.UserId = data.UserId
        updatedValues.UsingDefaultPassword = !!data?.UsingDefaultPassword
      }
      updatedValues.CanInitiateNewProject = values.CanInitiateNewProject === 'enabled'
      if (updatedValues.UserType === 'Admin') {
        updatedValues.MarketBrandCategoryPermissions = null
        updatedValues.CanInitiateNewProject = true
        onSubmit(updatedValues)
      }
      else{
        if(updatedValues.MarketBrandCategoryPermissions.MarketBrandCategoryPermission.length > 0){
          onSubmit(updatedValues)
        }
      }
    },
  })

  useEffect(() => {
    const initialValues = setInitialValues(data)
    formik.setValues(initialValues)
    setReadOnly(!!data)
    setEditMode(false)
  }, [data])

  const deleteUserHandler = async () => {
    try {
      setLoading(true)
      const resp = await UserManagementService.setUsers({
        user: { UserId: data.UserId },
        accountCreatorName: user?.UserName,
        delete: true,
      })
      refetch()
      onClose()
      if (resp)
        console.log(`SUCCESS : ${data.UserName} deleted successfully`)
      else throw new Error('Internal server error')
    } catch (error) {
      const resMessage =
        error?.message ||
        error?.response?.statusText ||
        'Internal server error'
      console.log('ERROR : ', resMessage)
    } finally {
      setLoading(false)
    }
  }

  const isAdmin = formik.values.UserType === 'Admin'

  return (
    <FormOuterWrap>
      <ActionRowWrapper>
        <Typography textTransform="capitalize" fontSize='18px' marginBottom='10px' fontWeight='500' line-height='31px'>
          User Details
        </Typography>
        <ActionRow>
          {!isEditMode && isReadOnly && (
            <MuiButton
              onClick={() => {
                setEditMode(true)
                setReadOnly(false)
              }}
              minwidth={'true'}
              color='primary'
              variant='contained'
            >
              Edit
            </MuiButton>
          )}
          {!isReadOnly && (
            <>
              <MuiButton
                color='secondary' variant='outlined' className='cancelButton' minwidth={'true'}
                onClick={() => { setReadOnly(true); setEditMode(false); onClose() }} style={{marginRight:'10px'}}
              >
                Cancel
              </MuiButton>
              <MuiButton onClick={formik.handleSubmit} type='submit' color='primary' variant='contained' minwidth={'true'}>
                Save
              </MuiButton>
            </>
          )}
        </ActionRow>
      </ActionRowWrapper>
      <FormWrap>
        <Grid container spacing={3} className="row1">
          <Grid item sm={4} sx={{
            paddingTop:'5px !important',
            maxWidth: isEditMode ? "48% !important" : "50%"
          }}>
            {isReadOnly ? (
              <>
                <Typography sx={{
                  fontSize: "16px",
                  fontWeight: 500
                }} textTransform="capitalize" variant='button'>Name</Typography>
                <ReadOnlyTypography>{formik.values.UserName}</ReadOnlyTypography>
              </>
            ) : (
              <MuiTextField
                {...formik.getFieldProps('UserName')}
                compact
                shadow
                onChange={formik.handleChange}
                title='Name'
                name='UserName'
                error={formik.errors.UserName}
                helperText={formik.errors.UserName}
                disabled={isEditMode}
              />
            )}
          </Grid>
          <Grid item sm={4} sx={{
            paddingTop:'5px !important',
            maxWidth: isEditMode ? "48% !important" : "50%"
          }}>
            {isReadOnly ? (
              <>
                <Typography sx={{
                  fontSize: "16px",
                  fontWeight: 500
                }} textTransform="capitalize" variant='button'>Email</Typography>
                <ReadOnlyTypography>{formik.values.UserEmail}</ReadOnlyTypography>
              </>
            ) : (
              <MuiTextField
                {...formik.getFieldProps('UserEmail')}
                compact
                shadow
                error={formik.errors.UserEmail}
                helperText={formik.errors.UserEmail}
                disabled={isEditMode}
                onChange={formik.handleChange}
                title='Email'
                name='UserEmail'
              />
            )}
          </Grid>
          <Grid item sm={4} sx={{
            paddingTop:'5px !important',
            maxWidth: isEditMode ? "48% !important" : "50%"
          }}>
            {isReadOnly ? (
              <>
                <Typography sx={{
                  fontSize: "16px",
                  fontWeight: 500
                }} textTransform="capitalize" variant='button'>User Type</Typography>
                <ReadOnlyTypography>{formik.values.UserType}</ReadOnlyTypography>
              </>
            ) : (
              <MuiSelect
                name='UserType'
                onChange={formik.handleChange}
                title='User Type'
                data={userTypes}
                // isCommaSeparatedValue
                compact
                error={formik.errors.UserType}
                value={formik.values.UserType || ''}
              />
            )}
          </Grid>
        </Grid>
        <Divider />
        <Box>
          <Typography textTransform="capitalize" fontSize='18px' marginBottom='10px' fontWeight='500'>
            Permissions
          </Typography>
        </Box>

        <Grid container spacing={4}>
          <Grid item sm={6} className="row1" sx={{
            maxWidth: isEditMode ? "48% !important" : "50%"
          }}>
            {isReadOnly ? (
              <>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 500
                  }}
                  textTransform="capitalize" variant='button'>Initiate New Project</Typography>
                <ReadOnlyTypography>
                  {formik.values.CanInitiateNewProject === 'enabled' ? 'Enabled' : 'Disabled'}
                </ReadOnlyTypography>
              </>
            ) : (
              <MuiSelect
                name='CanInitiateNewProject'
                title='Initiate New Project'
                onChange={formik.handleChange}
                // isCommaSeparatedValue
                compact
                disabled={isAdmin}
                data={PERMISSION}
                value={(isAdmin || formik.values.CanInitiateNewProject === 'enabled') ? "enabled" : "disabled"}
              />
            )}
          </Grid>
        </Grid>
        {(!!formik.values.MarketBrandCategoryPermissions
          ?.MarketBrandCategoryPermission?.length ||
          !isReadOnly ||
          isAdmin) && (
            <>
              <Divider />
              <BrandCategories
                isEditMode={isEditMode}
                isReadOnly={isReadOnly}
                isAdmin={isAdmin}
                isMarketCategoryPermissionsError={isMarketCategoryPermissionsError}
                onChange={(eVal) =>
                  formik.setFieldValue(
                    'MarketBrandCategoryPermissions.MarketBrandCategoryPermission',
                    eVal
                  )
                }
                marketBrandCategoryPermissions={
                  isAdmin
                    ? (isEditMode || isReadOnly) ? [{ BrandCategory: 'All', Market: 'All' }] : []
                    : formik.values?.MarketBrandCategoryPermissions
                      ?.MarketBrandCategoryPermission
                }
              />
            </>
          )}

        {isLoading && <BlockingLoader />}
        {isDeleteModalActive && (
          <DeleteConfirm
            onClose={() => setDeleteModalActive(false)}
            onConfirm={deleteUserHandler}
          />
        )}
      </FormWrap>
    </FormOuterWrap>
  )
}

export default UserForm

const BrandCategories = ({
  marketBrandCategoryPermissions = [],
  onChange,
  isEditMode = false,
  isReadOnly = false,
  isAdmin = false,
  isMarketCategoryPermissionsError
}) => {
  const { user } = useGlobalStore();
  const [isRowActive, setRowActive] = useState(false)
  const [marketsAndCategories, setMarketAndCategories] = useState({
    markets: [],
    categories: [],
  })

  const addRowHandler = (val) => {
    onChange([...marketBrandCategoryPermissions, val])
    setRowActive(false)
  }

  const getBrandsAndCategoriesHandler = async () => {
    try {
      const resp = await UserManagementService.getBrandsAndCategories({ companyId: user?.CompanyId, userId: user?.UserId })
      const filterData = resp.filter(item => ((item.EntityType === "Category" || item.EntityType === "CategoryOnly" || item.EntityType === "Brand") && !!item.EntityName));
      setMarketAndCategories((prev) => ({
        ...prev,
        categories: [{ EntityName: 'All', EntityValue: 'All' }, ...filterData],
      }))
    } catch (error) { }
  }

  const getMarketsHandler = async () => {
    try {
      const resp = await UserManagementService.getMarkets({ companyId: user?.CompanyId, userId: user?.UserId })
      const modifiedData = resp?.map((item) => ({
        label: item,
        value: item,
      }))
      setMarketAndCategories((prev) => ({
        ...prev,
        markets: [{ label: 'All', value: 'All' }, ...modifiedData],
      }))
    } catch (error) { }
  }

  useEffect(() => {
    getBrandsAndCategoriesHandler()
    getMarketsHandler()
  }, [])

  const removeRowHandler = (index) => {
    const items = marketBrandCategoryPermissions?.filter(
      (_, ix) => index !== ix
    )
    onChange(items)
  }

  return (
    <Box>
      <Box>
        <Grid container spacing={3}>
          <Grid item sm={5}>
            <Typography sx={{
              fontSize: '16px',
              lineHeight: '27px',
              fontWeight: isReadOnly ? 500 : 300
            }}>Brand / Category</Typography>
          </Grid>
          <Grid item sm={5}>
            <Typography sx={{
              fontSize: '16px',
              lineHeight: '27px',
              fontWeight: isReadOnly ? 500 : 300
            }}>Market</Typography>
          </Grid>
          <Grid item sm={2}></Grid>
        </Grid>

        {Array.isArray(marketBrandCategoryPermissions) &&
          marketBrandCategoryPermissions?.map((project, index) => {
            return (
              <ProjectRow
                key={index}
                project={project}
                isReadOnly={isReadOnly}
                isEditMode={true}
                isAdmin={isAdmin}
                removeRowHandler={() => removeRowHandler(index)}
              />
            )
          })}
      </Box>
      {!!marketBrandCategoryPermissions?.length && !isReadOnly && <Divider />}
      {(!!isRowActive || !marketBrandCategoryPermissions.length) &&
        !isReadOnly && (
          <ProjectRow
            marketsAndCategories={marketsAndCategories}
            addRowHandler={addRowHandler}
            isAdmin={isAdmin}
            isMarketCategoryPermissionsError={isMarketCategoryPermissionsError}
          />
        )}
      {!isRowActive &&
        !!marketBrandCategoryPermissions.length &&
        !isReadOnly &&
        !isAdmin && (
          <AddMoreButton
            sx={{ marginTop: '-20px !important' }}
            onClick={() => setRowActive(true)}
          >
            + Add more
          </AddMoreButton>
        )}
    </Box>
  )
}

const ProjectRow = ({
  isReadOnly = false,
  isEditMode = false,
  project,
  removeRowHandler,
  addRowHandler,
  marketsAndCategories,
  isAdmin,
  isMarketCategoryPermissionsError
}) => {
  const [state, setState] = useState({
    Market: '',
    BrandCategory: '',
  })
  const [error, setError] = useState({});

  const inputChangeHandler = (e) => {
    const { name, value } = e.target
    setState((prev) => ({
      ...prev,
      [name]: value,
    }))
    setError(prev => ({
      ...prev,
      [name]: ''
    }))
  }

  const addRow = (row) => {
    const errorObj = {};
    if (!row?.Market) {
      errorObj.Market = "Market is a required field"
    }
    if (!row?.BrandCategory) {
      errorObj.BrandCategory = "Brand / Category is a required field"
    }

    if (Object.keys(errorObj).length) {
      setError(errorObj);
      return;
    }
    addRowHandler(row)
  }

  useEffect(()=>{
    const errorObj = {};
    if(isMarketCategoryPermissionsError){
      errorObj.Market = "Market is a required field"
      errorObj.BrandCategory = "Brand / Category is a required field"
    }
    setError(errorObj)
  },[isMarketCategoryPermissionsError])

  useEffect(()=>{
    if(isAdmin){
      setError({})
    }
  },[isAdmin])

  return (
    <Box style={{border:'0px red solid', paddingBottom: '5px'}}>
      <Grid container spacing={3}>
        <StyledGrid item sm={5}>
          {isReadOnly || isEditMode ? (
            <ReadOnlyTypography>{project?.BrandCategory}</ReadOnlyTypography>
          ) : (
            <MuiSelect
              name='BrandCategory'
              onChange={inputChangeHandler}
              // isCommaSeparatedValue
              compact
              disabled={isAdmin}
              selectlabel='EntityName'
              selectvalue='EntityName'
              value={isAdmin ? 'All' : state?.BrandCategory}
              data={marketsAndCategories?.categories || []}
              error={error?.BrandCategory}
            />
          )}
        </StyledGrid>
        <StyledGrid item sm={4}>
          {isReadOnly || isEditMode ? (
            <ReadOnlyTypography>{project?.Market}</ReadOnlyTypography>
          ) : (
            <MuiSelect
              name='Market'
              onChange={inputChangeHandler}
              // isCommaSeparatedValue
              compact
              disabled={isAdmin}
              value={isAdmin ? 'All' : state?.Market}
              data={marketsAndCategories?.markets || []}
              error={error?.Market}
            />
          )}
        </StyledGrid>
        <StyledGrid item sm={3} display='flex' sx={{flexDirection:'column'}} >
          {!isReadOnly && !isAdmin && (
            <MuiButton
              onClick={() =>
                isEditMode ? removeRowHandler() : addRow(state)
              }
              sx={{ marginTop: 'auto', height: '34px', marginLeft: 'auto', wordWrap: 'noWrap' }}
              color='primary'
              variant='contained'
            >
              {isEditMode ? 'Remove' : 'Add'} Permission
            </MuiButton>
          )}
          {!isAdmin && isMarketCategoryPermissionsError && <FormError>Please add permission</FormError>}
        </StyledGrid>
      </Grid>
    </Box>
  )
}
