import React, { useEffect, useRef, useState } from 'react'
import IconButton from '@mui/material/IconButton'
import { useLocation, useParams, useHistory } from 'react-router-dom'
import DEFAULT_ROUTES, { ROUTE_DATA } from './__mock'

import { NAME_HELP, ROUTE_REPORTS } from '../../constants'
import { getImageUrl, removeSpecialCharFromLhs } from '../../util'
import {ROLES_ALL, ROLES_CLIENT_PRIMARY, ROLES_CLIENT_SECONDARY} from '../../constants'

import MuiImage from '../muiImage'
import reportsService from '../../services/reports.service'
import Tabs from '../../layout/postLoginReports/tabs'
import { useGlobalStore } from '../../store'
import HeaderLogo from '../../layout/postLogin/header/headerLogo'
import { close, help } from '../../assets/images/icons'
import CanWeHelp from '../canWeHelp'
import PublishProject from '../../layout/postLoginReports/header/publish-project'

import {
  LhsWrapper,
  ListWrapper,
  LinkLabel,
  LhsRoot,
  BoxShadow,
  NavLinkWrap,
  NavLinkHelpWrap,
  IconButtonStyled,
  MenuIconButton
} from './style'

const Lhs = ({ color, isreportpage, onChange }) => {
  const rootRef = useRef(null);
  const { id, tabName } = useParams();
  const navigate = useHistory()
  const [reportsLhsData, setReportsLhsData] = useState(DEFAULT_ROUTES)
  const [selectedTab, setSelectedTab] = useState('');
  const [isSubTabsLoading, setSubTabsLoading] = useState(false);
  const [isTabsLoading, setTabsLoading] = useState(false);
  const [showCanWeHelpModal, setShowCanWeHelpModal] = useState(false);
  const { pathname, hash } = useLocation();
  const { user, subLhs, setSubLhs, setLhs, showLhsMenu, setShowLhsMenu, setAskIaActiveState, isAskIaActiveState } = useGlobalStore();

  const getSiblingsData = () => {
    const getDriversData = (data) => {
      reportsService.reportGetDriverElementsApi({ insightsElementId: data.InsightsElementId, entityId: data.EntityId })
        .then((resp) => { })
        .catch((error) => {
          const resMessage = error
          console.log('ERROR ', resMessage)
        })
    }

    const getLandscapeData = (data) => {
      reportsService.getCompetitiveLandscapeElement({ insightsElementId: data.InsightsElementId})
        .then((resp) => { })
        .catch((error) => {
          const resMessage = error
          console.log('ERROR ', resMessage)
        })
    }

    reportsService.reportAllTabsApi({ studyId: id })
      .then((resp) => {
        resp.map(tab => {
          if(tab.InsightsElementType === 'Drivers'){
            getDriversData(tab)
          }
          if(tab.InsightsElementType === 'CompetitiveLandscape'){
            getLandscapeData(tab)
          }
          return tab
        })
      })
      .catch((error) => {
        const resMessage = error
        console.log('ERROR ', resMessage)
      })
  }

  const getSubHeadings = (tabId) => {
    setSubTabsLoading(true)
    reportsService.reportTabsApi({ studyId: id, tabId })
      .then((resp) => {
        const lhsTabsRes = resp.sort((a, b) => a.InsightsElementOrder - b.InsightsElementOrder).filter(t => t.InsightsElementVisible).map((list, i) => {
          list.active = false
          list.tabContent = null
          list.isApiLoading = true
          // list.Title = (list?.Title)?.replace(/[^a-zA-Z0-9 ]/g, '') || ''
          list.Title = (list?.Title).replaceAll(':', ' - ')
          return list
        })
 
        if(lhsTabsRes?.length > 0){
            setSubLhs([...lhsTabsRes])
        }
      })
      .catch((error) => {
        const resMessage = error
        console.log('ERROR : ', resMessage)
      }).finally(() => {
        setSubTabsLoading(false)
      })
  }

  useEffect(() => {
    if (selectedTab?.TabId && isreportpage) {
      getSubHeadings(selectedTab?.TabId);
    }
  }, [selectedTab, isreportpage])

  useEffect(() => {
    if(isreportpage && subLhs?.length) {
      // const elId = subLhs[0]?.Title?.replaceAll(' ', '')
      const elId = removeSpecialCharFromLhs(subLhs[0]?.Title)
      const el = document.querySelector(`#${elId}`)
      if(el) {
        el.scrollIntoView({
          behavior: 'smooth'
        })
      }
    }
  }, [subLhs]);

  useEffect(() => {
    if (isreportpage) {
      onChange({lhsLoading:isSubTabsLoading})
    }
  },[isSubTabsLoading, onChange, isreportpage])

  const getLhsReportData = () => {
    setTabsLoading(true)
    reportsService.reportLhsApi({ studyId: id }).then((resp) => {
      const sortedRespData = resp.sort((a, b) => a.TabOrder - b.TabOrder).filter(f => f.TabVisible)
      const modifiedData = sortedRespData.map((list) => {
        // list.userType = ['Admin', user?.UserType]
        list.userType = (list.TabIcon === 'sandbox') ? ROLES_ALL : ROLES_CLIENT_SECONDARY
        list.title = list.TabName
        list.label = list.TabName
        if ( isreportpage ){
          list.icon = getImageUrl('icons', list.TabIcon, 'svg')
          list.href = `/reports/${list.TabIcon}/${id}`
        }
        else{
          list.icon = ROUTE_DATA[list.TabIcon].icon
          list.href = ROUTE_DATA[list.TabIcon].href + id
        }

        return list
      })
      setLhs(modifiedData)
      setReportsLhsData(modifiedData)
      const activeTab = modifiedData.find(item => pathname.includes(item.href))
      if (activeTab?.StudyId) {
        setSelectedTab(activeTab);
        getSiblingsData();
      }
      
      // if ( isreportpage && (sortedRespData[0].TabIcon !== 'background') ) {
      //   navigate.push(`${ROUTE_REPORTS}/${sortedRespData[0].TabIcon}/${id}`)
      //   getSubHeadings(sortedRespData[0].TabId)
      // }

    })
      .catch((error) => {
        const resMessage = error
        console.log(resMessage);
      })
      .finally(() => {
        setTabsLoading(false)
      })
  }

  useEffect(() => {
    if (isreportpage) {
      getLhsReportData();
    }
  }, [isreportpage])

  const hideLhs = () => {
    rootRef.current.classList.remove("show");
  }

  useEffect(() => {
    hideLhs();
  }, [pathname, hash])

  // Login Time checking // max 15 hours
  const hoursCheck = () => {
    const diff_hours = (dt2, dt1) => {
      var diff =(dt2.getTime() - dt1.getTime()) / 1000;
      diff /= (60 * 60);
      return Math.abs(Math.round(diff));
    }
    const loginedInHours = diff_hours(new Date(user?.loginTime), new Date())
    if (loginedInHours >= 15){
      localStorage.clear()
      navigate.push('/')
    }
  }

  const checkAskIaIsActiveFunc = () => {
    if(isAskIaActiveState !== 'none'){
      setAskIaActiveState('none')
    }
  }

  useEffect(() => {
    hoursCheck()
  }, [])

  useEffect(() => {
    if(showLhsMenu === 'projectPage' && isreportpage){
      setShowLhsMenu('reportPage_showLhs')
    }
  }, [showLhsMenu])

  return (
    <LhsRoot ref={rootRef} className={`lhsRoot ${showLhsMenu === 'reportPage_showLhs' ? '' : 'lhsHamburgerWrap'} ${isreportpage ? '' : 'lhsHamburgerWrap'} `}>

      {showLhsMenu==='reportPage_hideLhs' && isreportpage && <LhsWrapper isreportpage={+!!isreportpage} className="lhsWrapper lhsHamburger">
        <HamburgerMenu onClick={() => setShowLhsMenu('reportPage_showLhs')} />
        {ROLES_CLIENT_PRIMARY.includes(user.UserType) && <PublishProject userType={user.UserType} />}
      </LhsWrapper>}

      {showLhsMenu==='reportPage_showLhs' && isreportpage && <LhsWrapper isreportpage={+!!isreportpage} className="lhsWrapper lhsLinks">
        <ArrowLeft onClick={() => setShowLhsMenu('reportPage_hideLhs')} />
        <HeaderLogo parentClass='desktopMenu' />
        {user && reportsLhsData.map((list, i) => {
          if (list.id === 'initiateProjects' && list.userType.includes(user?.UserType) && !user?.CanInitiateNewProject) {return false}
          if (list.id === 'userManagement' && !list.userType.includes(user?.UserType)) {return false}
          const className = 'lhsLevel1_ '+list.title.replaceAll(' ','')
          return(<div key={i} className={`${isreportpage ? 'reports' : 'projects'}_list_${i} ${className}`}>
            {list.userType.includes(user?.UserType) &&
              <ListWrapper key={list.title} className={list.activeLink} onClick={() => checkAskIaIsActiveFunc()}>
                <NavLinkWrap color={color} activeClassName='activeName' to={list.href} title={list.title} onClick={() => setSelectedTab(list)}>
                  <MuiImage src={list.icon} width='30px' alt={list.label} />
                  {list.title && <LinkLabel>{list.title}</LinkLabel>}
                </NavLinkWrap>
              </ListWrapper>}
              {isreportpage && subLhs?.length > 0 && (selectedTab?.TabName === list.label) &&
                <Tabs data={subLhs} activeTabTitle={selectedTab?.TabName || ''} isLoading={isSubTabsLoading} tabLength={reportsLhsData.length} />
              }
          </div>
        )})}
      </LhsWrapper>}

      {!isreportpage && <LhsWrapper isreportpage={+!!isreportpage} className="lhsWrapper">
        <HeaderLogo parentClass='desktopMenu' />
        {user && reportsLhsData.map((list, i) => {
          if (list.id === 'initiateProjects' && list.userType.includes(user?.UserType) && !user?.CanInitiateNewProject) {return false}
          if (list.id === 'userManagement' && !list.userType.includes(user?.UserType)) {return false}
          const className = 'lhsLevel1_'+list.title.replaceAll(' ','')
          return(<div key={i} className={`${isreportpage ? 'reports' : 'projects'}_list_${i} ${className}`}>
            {list.userType.includes(user?.UserType) &&
              <ListWrapper key={list.title} className={list.activeLink} onClick={() => checkAskIaIsActiveFunc()}>
                <NavLinkWrap className='link' color={color} activeClassName='activeName' to={list.href} title={list.title} onClick={() => setSelectedTab(list)}>
                  <MuiImage src={list.icon} width='30px' alt={list.label} />
                  {list.label && <LinkLabel>{list.label}</LinkLabel>}
                </NavLinkWrap>
              </ListWrapper>}
              {isreportpage && subLhs?.length > 0 && (selectedTab?.TabName === list.label) &&
                <Tabs data={subLhs} activeTabTitle={selectedTab?.TabName || ''} isLoading={isSubTabsLoading} tabLength={reportsLhsData.length} />
              }
          </div>
        )})}
        <div>
          <ListWrapper>
            <NavLinkHelpWrap className='link' title={NAME_HELP} onClick={() => {setShowCanWeHelpModal(true)}}>
              <MuiImage src={help} width='30px' />
            </NavLinkHelpWrap>
          </ListWrapper>
        </div>
      </LhsWrapper>}

      <BoxShadow onClick={hideLhs} />
      <IconButtonStyled
        onClick={hideLhs}>
        <MuiImage src={close} />
      </IconButtonStyled>

      {showCanWeHelpModal && <CanWeHelp showButton={false} onLoadShow={true} onClose={()=> {setShowCanWeHelpModal(false)}} />}
    </LhsRoot>
  )
}

export default Lhs


const ArrowLeft = ({onClick}) => {
  return (
    <MenuIconButton size='small' onClick={onClick}>
      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11" fill="none">
        <path d="M9 1L5 5.5L9 10" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M5 1L1 5.5L5 10" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </MenuIconButton>
  )
}

const HamburgerMenu = ({onClick}) => {
  return (
    <MenuIconButton size='small' onClick={onClick}>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" fill="none">
        <path d="M18.3997 3.38728H1.60031C0.716446 3.38728 0 2.62905 0 1.69364C0 0.75823 0.716446 0 1.60031 0H18.3997C19.2836 0 20 0.75823 20 1.69364C20 2.62905 19.2836 3.38728 18.3997 3.38728Z" fill="black"/>
        <path d="M18.3997 10.6939H1.60031C0.716446 10.6939 0 9.93569 0 9.00028C0 8.06487 0.716446 7.30664 1.60031 7.30664H18.3997C19.2836 7.30664 20 8.06487 20 9.00028C20 9.93569 19.2836 10.6939 18.3997 10.6939Z" fill="black"/>
        <path d="M18.3997 18.0006H1.60031C0.716446 18.0006 0 17.2423 0 16.3069C0 15.3715 0.716446 14.6133 1.60031 14.6133H18.3997C19.2836 14.6133 20 15.3715 20 16.3069C20 17.2423 19.2836 18.0006 18.3997 18.0006Z" fill="black"/>
      </svg>
    </MenuIconButton>
  )
}
