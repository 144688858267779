import { Box } from '@mui/material';
import React, { useRef, useState } from 'react'
import { download, Download } from '../../assets/images/icons';
import Loader from '../loader';
import MuiButton from '../muiButton'
import MuiImage from '../muiImage';

const ExcelHtml = ({ downloadId }) => {
    const [loading, setLoading] = useState(false);
    const imgRef = useRef(null);
    const tableRef = useRef(null);

    const exportToExcel = () => {
        const el = document.getElementById(downloadId);
        const imag = el.getElementsByTagName('img')?.[0];
        imgRef.current.src = imag.src;
        imgRef.current.width = imag.width;
        imgRef.current.height = imag.height;
        let tableData = tableRef.current?.outerHTML;
        const a = document.createElement('a');
        a.href = `data:application/vnd.ms-excel, ${encodeURIComponent(tableData)}`
        a.download = 'downloaded_file_' + getRandomNumbers() + '.xls'
        a.click()
        setLoading(false)
    }

    function getRandomNumbers() {
        let dateObj = new Date()
        let dateTime = `${dateObj.getHours()}${dateObj.getMinutes()}${dateObj.getSeconds()}`

        return `${dateTime}${Math.floor((Math.random().toFixed(2) * 100))}`
    }

    return (
        <Box>
            <MuiButton
                variant='standard'
                color='primary'
                sx={{ color: '#095BBD', marginLeft: '15px' }}
                onClick={() => {
                    setLoading(true)
                    setTimeout(() => {
                        exportToExcel()
                    }, 2000);
                }}
                disabled={loading}
                startIcon={loading ? <Loader size={15} /> : <Download />}
                className='BtnExportToExcel'
            >
                Export
            </MuiButton>
            {
                loading &&
                <Box style={{ position: 'fixed', top: "-100000px", width: "1280px", height: "150px" }} >
                    <table ref={tableRef}>
                        <tr><td></td></tr>
                        <tr>
                            <td></td>
                            <td>
                                <img ref={imgRef} />
                            </td>
                        </tr>
                    </table>
                </Box>
            }
        </Box>
    )
}

export default ExcelHtml