import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";

export const VerbatimFilterOuterWrapper = styled(Box)(`
  // overflow-y:scroll;
  width: 100%;
  white-space:pre-wrap;
  display:flex;
  flex-direction:column;
  // margin-right: -30px;
  // padding-right: 30px;
  margin-top: -30px;

  &.gridFormatting .TableOuterWrap { overflow:visible; }
  &.gridFormatting .TableOuterWrap > table thead{ position:static; }

  &.gridFormatting .TableOuterWrap > table tbody {
      display: block;
      height: calc(100vh - 500px);
      overflow: auto;
  }
  &.gridFormatting.isCollapsed_true .TableOuterWrap > table tbody {
      height: calc(100vh - 250px);
  }
  &.gridFormatting .TableOuterWrap > table thead, &.gridFormatting .TableOuterWrap > table tbody tr {
      display: table;
      width: 100%;
      table-layout: fixed; /* even columns width , fix width of table too*/
  }
  &.gridFormatting .TableOuterWrap > table tbody tr.editRow { table-layout:auto; }
  &.gridFormatting .TableOuterWrap > table thead {
      width: calc( 100% - 1em ) /* scrollbar is average 1em/16px width, remove it from thead width */
  }
  &.gridFormatting .TableOuterWrap > table {
      width: 100%;
  }
  
  &.gridFormatting.isCollapsed_true .verbatimFilterOuterWrapper {
    display:none;
  }
  &.gridFormatting.isCollapsed_false .verbatimFilterOuterWrapper {
    display:flex;
  }

`);

export const VerbatimFilterInnerWrapper = styled(Box)(`
  display:flex;
  flex-direction:column;
  position:sticky;
  top:0;
  background:#fff;
  z-index: 1;
  padding:10px 0;
  margin-bottom: 10px;
`);

export const OuterWrapper = styled(Box)(`
  padding: 0 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
`);

export const SearchWrap = styled(Box)(`
  display: flex;
  justify-content: space-between;
  & .closeButton {
    position: static;
    margin-left: 10px;
  }
  & .searchInputWrap {
    box-shadow: 0px 1px 2px rgb(16 24 40 / 12%);
    border: 1px solid rgb(16 24 40 / 12%);
    & fieldset {
      border: 0;
    }
  }
`);

export const SearchInputWrap = styled(Box)(`
  min-width: 400px;
  display: flex;
  align-items: center;
  & .searchInputWrap {
    width: 100%;
  }
`);

export const FilterWrap = styled(Box)(`
  display: flex;
  flex-wrap: wrap;
  gap: 5px 20px;
  background: #F7F7F9;
  width: 100%;
  padding: 10px 20px 20px 20px;

  & .filterItemWrap {
    width: calc(25% - 20px);
    margin-bottom: 10px !important;
  }
  
  & .MuiInput-root {
    box-shadow: none
  }
  & .selectPlaceholder {
    margin-left: 0;
  }
  & .formLabel {
    font-size: 10px;
    color: #545454;
  }
  & .MuiSelect-select {
    font-size: 14px;
  }
  & .DatePickerWrap  {
    & label {
      font-size: 10px !important;
      color: #545454;
      margin-top: 4px;
    }
    & .MuiFormControl-root {
      box-shadow: none;
      height: 35px;
    }
    & input {
      padding: 9px 16px 9px 0;
    }
    & .MuiInputBase-root:hover {
      & fieldset {
        border-color: transparent;
        border-bottom:  1px solid #C4C3C3;
      }
    }
    & fieldset {
      border-color: transparent;
      border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    }
  }
`);

export const ActionWrap = styled(Box)(`
  position:relative;
  z-index: 2;
  display: flex;
  gap: 10px;
  margin-left: auto;
  margin-top: 10px;
  & button {
    font-weight: 500;
    font-size: 14px;
  }
`);

export const ModalContentWrap = styled(Box)(`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 20px 30px;
  & .selectError, .MuiFormHelperText-root {
    margin-left: 0;
    position: absolute;
    bottom: -18px;
  }
  & .Mui-disabled.formCheckbox img{
    opacity: 0.5
  }
  & .filterItemWrap{
    width: calc(50% - 15px);
    margin-bottom: 0;
    position: relative;
  }
  & .DatePickerWrap, .TextFieldWrapper {
    margin-top: 4px;
    & .MuiInputBase-root, .MuiFormControl-root{
      height: 34px;
    }
  }
  & .DatePickerWrap {
     margin-top: 0;
  }
`);

export const TableWrap = styled(Box)(`
  overflow:auto;
 
  & .tableSubTitle {
    margin-top: 5px;
  }
  & .tableHead {
    background: #fff;
    top:0;
  }
  & .tableBodyRow td {
    border-bottom: 1px solid #EEEEEE;
  }
  & .editRow {
    background: #D9D9D915;
    & td {
       border: 0;
    }
  }
  & .TextFieldWrapper {
    & .MuiTextField-root {
      height: 34px;
      background: #fff;
      & input {
        padding: 9px 10px;
      }
    }
  }
  & .selectWrap {
    background: #fff;
  }
`);

export const SuccessModalWrapper = styled(Box)(`
  width:100%;
  max-width:500px;
  min-width:370px;
`)

export const TableEditRow = styled(Box)(`
  width: 100%;
  display: flex;
  margin-top: 10px;
  gap: 30px;
`)

export const EditTableTitle = styled(Typography)(`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
`)

export const TypographyInput = styled(Box)(`
  font-size: 14px;
  font-weight: 300;
  height: 34px;
  display: flex;
  align-items: center;
`)

export const TableInputWrap = styled(Box)(`
  width: 25%;
`)

export const CustomTableHeadCell = styled("th")(props => `
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  padding: 10px;
  white-space: nowrap;
  width: ${props.width || "90px"};
  max-width: ${props.width || "90px"};
  min-width: ${props.width || "90px"};
`)

export const CustomTableBodyCell = styled("td")(props => `
  font-size: 14px !important;
  font-weight: 300;
  text-align: left;
  padding: 10px;
  vertical-align: baseline;
  width: ${props.width || "90px"};
  max-width: ${props.width || "90px"};
  min-width: ${props.width || "90px"};
  & .error, .MuiFormHelperText-root, .selectError {
    margin: 0;
  }
`)

export const CustomTableHead = styled("thead")(props => `
    position: sticky; 
    box-shadow: 0px 1px 0px #eee;
    top: ${props.collapsed ? "60px" : "300px"}
`)

export const CustomTable = styled("table")(`
  width: 100%; 
  border-collapse: collapse;
`)

export const MediaWrapper = styled(Box)(`
  width: 100%;
  & img {
    max-width:200px;
    max-height:120px;
  }
  & iframe {
    max-width:300px;
    max-height:180px;
  }
  & span {
    font-size:11px;
    color:red;
    max-width:250px;
    overflow-wrap:anywhere;
  }

`)

