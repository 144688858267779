import { Table, TableBody, TableHead } from '@mui/material'
import React from 'react'
import { CustomTableWrap } from './style'
import { TableBodyRow, TableHeaderRow } from './tableRow'

const CustomTable = ({header=[], data=[]}) => {

  return (
    <CustomTableWrap className='customTableWrap'>
       <Table className='tableRoot'>
        <TableHead>
          <TableHeaderRow headerMeta={header} />
        </TableHead>
        <TableBody>
          {data?.map((modul) => {
            return (
              <TableBodyRow
                key={modul.id}
                rowData={modul}
                headerMeta={header}
              />
            )
          })}
        </TableBody>
      </Table>
    </CustomTableWrap>
  )
}

export default CustomTable;

// 
// const header = [
//   {
//     id: 'DraftName',
//     label: 'Draft Name',
//     minWidth: '40%',
//     align: 'left', [left, right, center]
//     filterMethod: setProjectData,
//   }
// ]

// sample 1
// const data = [
//   {
//     DraftName: "abc",
//     ProjectCreatorEmailAddress: "atulfind@gmail.com",
//     ModifiedDate: "dfkadf"
//   }
// ]

// sample 2
// const data = [
//   {
//     DraftName: <Box>abc</Box>,
//     ProjectCreatorEmailAddress: "atulfind@gmail.com",
//     ModifiedDate: "dfkadf"
//   }
// ]