import React from 'react'

const IconUser = ({fill="black", width=18, height=23}) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.3931 2.60695C18.869 6.14491 18.869 11.8551 15.3931 15.3931C11.8551 18.869 6.14491 18.869 2.60695 15.3931C-0.868982 11.8551 -0.868982 6.14491 2.60695 2.60695C6.14491 -0.868982 11.8551 -0.868982 15.3931 2.60695V2.60695ZM14.7724 3.22759C11.6069 0.0620357 6.39309 0.0620357 3.22766 3.22759C0.0621 6.39315 0.0621 11.6069 3.22766 14.7723C6.39321 17.9998 11.607 17.9998 14.7724 14.7723C17.9999 11.6068 17.9999 6.39302 14.7724 3.22759Z" fill="#D5732E"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M6.96573 6.3872C6.96573 7.34933 6.3158 8.12913 5.51417 8.12913C4.71243 8.12913 4.0625 7.34933 4.0625 6.3872C4.0625 5.4252 4.71243 4.64526 5.51417 4.64526C6.3158 4.64526 6.96573 5.4252 6.96573 6.3872Z" fill="#D5732E"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M13.9345 6.3872C13.9345 7.34933 13.2846 8.12913 12.4828 8.12913C11.6812 8.12913 11.0312 7.34933 11.0312 6.3872C11.0312 5.4252 11.6812 4.64526 12.4828 4.64526C13.2846 4.64526 13.9345 5.4252 13.9345 6.3872Z" fill="#D5732E"/>
      <path d="M5.67812 11.3421C5.83256 12.0474 6.14127 12.6822 6.6815 13.176C7.91652 14.3046 10.0777 14.3046 11.3897 13.176C11.8528 12.6822 12.2388 12.0474 12.3158 11.3421H5.67785H5.67812ZM13.088 10.6367C13.088 11.8357 12.8565 12.7527 11.9302 13.6696C10.3093 15.0803 7.6851 15.0803 6.14127 13.6696C5.1379 12.7527 4.90625 11.8357 4.90625 10.6367H13.0881H13.088Z" fill="#D5732E"/>
    </svg>
  )
}

export default IconUser