import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { LoaderWrapper, ReportHeading, ReportOuterWrapper } from '../../../../components'
import ReportsService from '../../../../services/reports.service'
import { useUpdateLhs } from '../../../../hooks/useUpdateLhs'
import CompetitionConsiderationTable from './competitionConsiderationTable'
import { ContentWrap, RootWrap } from './style'
import { exportExcel_addPercentage } from '../../../../util'

const CompetitionConsideration = (props) => {
  const [state, setState] = useState([]);
  const [maxValue, setMaxValue] = useState(100)
  const [loading, setLoading] = useState(false)
  const [reportExcelData, setReportExcelData] = useState()
  const {updateSubLhsApiLoaded} = useUpdateLhs()

  const getSegment = () => {
    setLoading(true)
    ReportsService.getRelativeBrandsAnalysis({ insightsElementId: props.InsightsElementId })
      .then((resp) => {
        const filterItems = resp.filter(item => !!item?.BrandName)
        const valueArr = filterItems.map(item => item.BrandPercent)
        setMaxValue(Math.max(...valueArr))
        setState(filterItems);
        updateSubLhsApiLoaded(props)
        // setReportExcelData(resp.map(d => { return [{value: d.BrandName}, {value: d.BrandPercent}]} ))
        // setReportExcelData({heading:['Brand Names'], values: resp.map(d => { return [{value: d.BrandName}, exportExcel_addPercentage(d.BrandPercent)]} )}) Commented % as its not required ..
        setReportExcelData({heading:['Brand Names'], values: resp.map(d => { return [{value: d.BrandName}, (d.BrandPercent)]} )})
      })
      .catch((error) => {
        const resMessage = error
        console.log('ERROR : ', resMessage)
        setLoading(false)
      }).finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    getSegment()
  }, [])

  return (
    <>
      <ReportHeading downloadId={props.type} {...props} downloadIdData={reportExcelData} />
      <ReportOuterWrapper>
        <RootWrap id={props.type}>
          <LoaderWrapper loading={loading} loadOnlyApi={props.loadOnlyApi}>
            <ContentWrap>
              <CompetitionConsiderationTable maxValue={maxValue} data={state} />
            </ContentWrap>
          </LoaderWrapper>
        </RootWrap>
      </ReportOuterWrapper>
    </>
  )
}

export default CompetitionConsideration