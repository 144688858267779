import React from 'react'
import { Button, List, Box, Popover } from '@mui/material'
import { filter } from '../../assets/images/icons'
import { MuiImage, SearchInput } from '../../components'
import { SearchRow, StyledListItem } from './style'

const FilterRow = ({ setFilters, filters, disabled, userTypes=[] }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  return (
    <SearchRow className={disabled ? "formDisabled" : ""}>
      <SearchInput
        disabled={disabled}
        onChange={(e) =>
          setFilters((prev) => ({
            ...prev,
            searchText: e.target.value,
          }))
        }
        className='userSearch'
        value={filters?.searchText || ''}
      />
      <Box sx={{position:'relative'}} className='BtnUserManagementFilter'>
        <Button
          startIcon={<MuiImage src={filter} width='15px' />}
          className='userSearchButton'
          variant='outlined'
          disabled={disabled}
          onClick={handleClick}
        >
          Filter
        </Button>
      </Box>
      <Popover
        id={id}
        open={open}
        sx={{
          left: '-40px',
          top: '5px',
        }}
        anchorEl={anchorEl}
        onClose={handleClose}
        transformOrigin={{
          horizontal: 'center',
          vertical: "top"
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <List sx={{ width: '260px' }}>
          {userTypes.map((item, i) => (
            <StyledListItem
              key={i}
              onClick={() => {
                setFilters((prev) => ({
                  ...prev,
                  role: prev.role === item.value ? '' : item.value,
                }))
                handleClose()
              }}
              className={item.value === filters?.role ? 'active' : ''}
            >
              {item.label}
            </StyledListItem>
          ))}
        </List>
      </Popover>
    </SearchRow>
  )
}

export default FilterRow
