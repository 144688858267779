import React from 'react'

const IconUser = ({fill="black", width=18, height=23}) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.3931 2.60695C18.869 6.14491 18.869 11.8551 15.3931 15.3931C11.8551 18.869 6.14491 18.869 2.60695 15.3931C-0.868982 11.8551 -0.868982 6.14491 2.60695 2.60695C6.14491 -0.868982 11.8551 -0.868982 15.3931 2.60695V2.60695ZM14.7724 3.22759C11.6069 0.0620357 6.39309 0.0620357 3.22766 3.22759C0.0621 6.39315 0.0621 11.6069 3.22766 14.7723C6.39321 17.9998 11.607 17.9998 14.7724 14.7723C17.9999 11.6068 17.9999 6.39302 14.7724 3.22759Z" fill="#D5732E"/>
      <path d="M5.67812 11.3421C5.83256 12.0474 6.14127 12.6822 6.6815 13.176C7.91652 14.3046 10.0777 14.3046 11.3897 13.176C11.8528 12.6822 12.2388 12.0474 12.3158 11.3421H5.67785H5.67812ZM13.088 10.6367C13.088 11.8357 12.8565 12.7527 11.9302 13.6696C10.3093 15.0803 7.6851 15.0803 6.14127 13.6696C5.1379 12.7527 4.90625 11.8357 4.90625 10.6367H13.0881H13.088Z" fill="#D5732E"/>
      <path d="M13.3154 5.14308C13.1689 4.90185 12.8705 4.8367 12.6498 4.99708L10.8491 6.31181C10.7146 6.40983 10.6348 6.57343 10.6348 6.74965C10.6348 6.92587 10.7146 7.08949 10.8485 7.18749L12.6491 8.50222C12.7314 8.56209 12.8238 8.591 12.9144 8.591C13.0698 8.591 13.2222 8.50883 13.3147 8.35696C13.4618 8.11499 13.4023 7.78896 13.1809 7.628L11.9799 6.74964L13.1815 5.87276C13.4035 5.71106 13.4623 5.38503 13.3153 5.14305L13.3154 5.14308Z" fill="#D5732E"/>
      <path d="M4.84076 8.50294L6.64142 7.18795C6.77526 7.08991 6.85511 6.92627 6.85511 6.75002C6.85511 6.57377 6.77526 6.41012 6.64142 6.31209L4.84076 4.9971C4.61929 4.83669 4.32159 4.90185 4.17448 5.14313C4.02737 5.38514 4.08686 5.71124 4.30779 5.87223L5.50943 6.75001L4.30844 7.62707C4.08751 7.78822 4.02816 8.1149 4.17513 8.35618C4.26691 8.50881 4.41937 8.591 4.57478 8.591C4.66602 8.591 4.75913 8.56209 4.84072 8.50294L4.84076 8.50294Z" fill="#D5732E"/>
    </svg>    
  )
}

export default IconUser