
import xml2js from 'xml2js';

const xmlToJson = async (responseXMLFormat1, id, type) => {
    const xml = `<response>${responseXMLFormat1}</response>`

    const nameToUpperCase = (name) => {
        if(name === 'true') {return true}
        else if(name === 'false') {return false}
        else if(!isNaN(name)) {return +(name)}
        return name;
    }

    const object = {
        valueProcessors: [nameToUpperCase],
        explicitArray:false
    }

    // convert XML to JSON
    const finalData = await xml2js
    .parseStringPromise(xml, object)
    .then(result => {
        const isObject = (obj) => {
            const objArray = Object.keys(obj)

            if(objArray.length === 1){
                return obj[objArray[0]]
                // return objArray[0] === 'string' ? obj.string : obj[objArray[0]]
            }

            objArray.map(a => {
                if(Array.isArray(obj[a])){obj[a] = isArray(obj[a])}
                else if( typeof obj[a] === 'object' ){ obj[a] = isObject(obj[a])}
            })

            return obj
        }

        const isArray = (array) => {
            return array.map(a => {
                if( Array.isArray(a) ){ return isArray(a)}
                else if( typeof a === 'object' ){ return isObject(a)}
                return a
            })
        }

        if (type === 'object') {
            return isObject(result.response)
        }
        else if (type === 'array') {
            const arr = isObject(result.response)
            return Array.isArray(arr) ? arr : [arr] // [arrayFirstObj]
        }
        return null
    })

    // console.log('||RESPONSE|| SoapEnvelopeRemoving --> ', id, finalData)
    return finalData
}

export default xmlToJson

