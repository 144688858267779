import { IconButton } from "@mui/material";
import React, { useState } from "react";
import { arrowDown } from "../../assets/images/icons";
import MuiImage from "../muiImage";
import { StyledTableCell, StyledTableRow } from "./style";

export const TableHeaderRow = ({ headerMeta = [] }) => {
  return (
    <StyledTableRow className="table-head-row">
      {headerMeta.map((column) => (
        <TableHeadCell key={column?.id} column={column} />
      ))}
    </StyledTableRow>
  );
};

const TableHeadCell = ({ column }) => {
  const { filterMethod, id } = column || {};
  const [isAsc, setAsc] = useState(true);

  const cellClickHandler = () => {
    if(!filterMethod) return;
    filterMethod(prev => {
     const dd = prev.sort((a, b) => isAsc ? b[id].localeCompare(a[id]) : a[id].localeCompare(b[id]))
     return [...dd];
    });
    setAsc(prev => !prev)
  }

  return (
    <StyledTableCell
      align={column?.align || "left"}
      className="table-head-cell"
      onClick={cellClickHandler}
      sx={{
        maxWidth: column.minWidth,
        width: column.minWidth,
        cursor: !!filterMethod ? "pointer" : "default",
        ...(column?.sx || {}),
      }}
    >
      { column.isHTML ?
        <div
          className="table-head-cell-html"
          dangerouslySetInnerHTML={{ __html: column?.label }}
        ></div>
       : <>
        {column?.label}
        {
          !!filterMethod && <IconButton sx={{
            transform: `rotate(${isAsc ? "0deg" : "-180deg"})`
          }}>
            <MuiImage src={arrowDown}/>
          </IconButton>
        }
       </>}
    </StyledTableCell>
  );
};

export const TableBodyRow = ({
  headerMeta = [],
  rowData = {},
  rowClickCB,
  cursor = "default",
}) => {
  const tableRowClick = (data) => {
    if (rowClickCB) {
      rowClickCB(data);
    }
  };

  return (
    <StyledTableRow
      sx={{
        position: 'relative'
      }}
      onClick={() => tableRowClick(rowData)}
      className={`table-body-row cursor-${cursor}`}
    >
      {headerMeta?.map((column) => (
        <StyledTableCell
          className="table-body-cell"
          key={column.id}
          align={column.align || "left"}
          sx={{
            maxWidth: column.minWidth,
            width: column.minWidth,
            ...(rowData?.sx || {})
          }}
        > 
          {rowData?.[column.id]}
        </StyledTableCell>
      ))}
    </StyledTableRow>
  );
};
