import React, { useState } from 'react'

import CampaignMagnetScore from '../sandBoxDataTable/campaignMagnetScore'
import { Box, Grid, IconButton, Typography } from '@mui/material'

import { Close } from '@mui/icons-material'
import TextGrid from './textGrid'
import { VerbatimGrid, VerbatimImageVideoGrid } from '../../../../../components'
import { IconReportSearch, IconReportMore } from '../../../../../assets/images/icons'
import { getObjectAsArray } from '../../../../../util'
import {
  CampaignGraphsRoot,
  CardHeading,
  HeadingRow,
  VerbatimWrap,
  PageBreakerForPPT
} from './style'

const CampaignGraphs = ({ section, row, title, isLast }) => {
  const [selectedRow, setSelectedRow] = useState('')
  const [nestedSelectedRow, setNestedSelectedRow] = useState();
  const [selectedRowType, setSelectedRowType] = useState('')

  const pre = (selectedItem) => {
    const items = getObjectAsArray(selectedItem.element.DriverAttributes.DriverAttributeElement);
    let max = 0;
    for (let index = 0; index < items.length; index++) {
      const item = items[index];
      if(item.Percent > max){max = item.Percent}
    }
    const graphsData = items.map(item => {
      const barData = [{ Positive: item.PositivePercent, Neutral: item.NeutralPercent, Mixed: item.MixedPercent, Negative: item.NegativePercent}]
      return {
        element: item,
        id: item.AttributeId,
        barData: barData,
        heading: item.AttributeName,
        maxWidth: "90%",
        maxPercentRatio: max,
        valueInPercent: true,
        verbatim: item?.Verbatim?.VerbatimElement || [],
      }
    })
   setSelectedRow({
      graphs: graphsData,
      heading: selectedItem.heading,
    })
  }

  return (
    <CampaignGraphsRoot className="sectionWrap driverDeepdive">
      {/* <TitleForPPT className='titleForPPT'>{title}</TitleForPPT> */}
      <Grid container wrap='nowrap' className={`sectionWrap  ${section.type === 'ImpactBrandPerception' ? '' : 'campaignTriggersRow'}`}>
        <Grid style={{overflow:'hidden'}} item sm='6'>
          {section.heading && <CardHeading className={`${section.type} graphHeadingWrapper ${section.type === 'ImpactBrandPerception' ? 'campaignTriggersRow '+(section.graphs.length > 4 ? 'campaignTriggersRowNewSlidePPT' : '') : ''}`}>{section.heading}</CardHeading>}
          <Box sx={{ display: nestedSelectedRow ? "none": 'block' }}>
            {section.graphs.map((graph) => {
              const isSelected = selectedRow.id === graph.id
              return (
                <Box
                  className={`graphWrapper ${section.type === 'ImpactBrandPerception' ? 'campaignTriggersRow' : ''}`}
                  sx={{
                    display: 'flex',
                    background: isSelected ? '#F5F5F5' : '#ffffff',
                    alignItems: 'center',
                    width: '100%',
                    paddingRight:'20px'
                  }}
                  key={graph.id}
                >
                  <CampaignMagnetScore
                    heading={graph.heading}
                    barData={graph.barData}
                    showInside={true}
                    valueInPercent={graph.valueInPercent}
                    maxPercentRatio={graph.maxPercentRatio}
                    endIcon={graph.endIcon}
                    maxWidth={graph.maxWidth}
                    background={graph.background}
                    verbatim={graph.verbatim}
                  />
                  {!!graph?.verbatim && (
                    <>
                      <IconButton onClick={() => {pre(graph); setSelectedRowType('bars')}} size='small'><IconReportSearch /></IconButton>
                      <IconButton onClick={() => {setSelectedRow(graph); setSelectedRowType('verbatim')}} size='small'><IconReportMore /></IconButton>
                    </>
                  )}
                </Box>
              )
            })}
          </Box>
           {nestedSelectedRow && (
            <VerbatimWrap>
              <HeadingRow>
                <Typography className='headingRowTitle'>
                  {nestedSelectedRow.heading}
                </Typography>
                <IconButton onClick={() => setNestedSelectedRow('')} size='small'>
                  <Close />
                </IconButton>
              </HeadingRow>
              <VerbatimImageVideoGrid data={nestedSelectedRow.verbatim} insightsElementId={row.InsightsElementId} />
              {/* <VerbatimGrid data={nestedSelectedRow.verbatim} insightsElementId={row.InsightsElementId} /> */}
            </VerbatimWrap>
          )}
        </Grid>
        <Grid style={{overflow:'hidden'}} item sm='6'>
          {!section.heading && <TextGrid row={row}/>}
          {selectedRow && selectedRowType === 'verbatim' && (
            <VerbatimWrap>
              <HeadingRow>
                <Typography className='headingRowTitle'>{selectedRow.heading}</Typography>
                <IconButton onClick={() => setSelectedRow('')} size='small'><Close /></IconButton>
              </HeadingRow>
              <VerbatimImageVideoGrid data={selectedRow.verbatim} insightsElementId={row.InsightsElementId} />
              {/* <VerbatimGrid data={selectedRow.verbatim} insightsElementId={row.InsightsElementId} /> */}
            </VerbatimWrap>
          )}
          {selectedRow && selectedRowType === 'bars' && (
            <VerbatimWrap sx={{ background: "#fff !important", paddingTop: "0 !important", marginTop: "40px", borderLeft: nestedSelectedRow ? "0" : "1px solid #E0E0E0"}}>
              <HeadingRow>
                <Typography sx={{
                  fontSize: "14px !important",
                  fontWeight: "500 !important"
                }} className='headingRowTitle'>
                  {selectedRow.heading}
                </Typography>
                <IconButton onClick={() => {
                  setSelectedRow('');
                  setNestedSelectedRow('')
                }} size='small'>
                  <Close />
                </IconButton>
              </HeadingRow>
              
              {selectedRow?.graphs?.map((graph) => {
                  const isSelected = nestedSelectedRow?.id === graph.id
                  if( !Array.isArray(graph?.verbatim) ){ graph.verbatim = [graph?.verbatim] }
                  
                  return (
                    <Box
                      className='graphWrapper graph2'
                      sx={{
                        display: 'flex',
                        background: isSelected ? '#F5F5F5' : '#ffffff',
                        alignItems: 'center',
                        width: '100%',
                        paddingRight:'20px',
                        position: 'relative',
                        '&::before': isSelected ? {
                          content: '""',
                          position: 'absolute',
                          left: "-20px",
                          width: "20px",
                          top: 0,
                          bottom: 0,
                          background: '#F5F5F5'
                        } : {
                          content: '""'
                        }
                      }}
                      key={graph.id}
                    >
                        <CampaignMagnetScore
                          heading={graph.heading}
                          barData={graph.barData}
                          showInside={true}
                          valueInPercent={graph.valueInPercent}
                          maxPercentRatio={graph.maxPercentRatio}
                          endIcon={graph.endIcon}
                          maxWidth={graph.maxWidth}
                          background={graph.background}
                          verbatim={graph.verbatim}
                        />
                      {!!graph?.verbatim && <IconButton onClick={() => setNestedSelectedRow(graph)} size='small'><IconReportMore /></IconButton>}
                    </Box>
                  )
                })}
              
            </VerbatimWrap>
          )}
        </Grid>
      </Grid>
      {!isLast && section.type === 'ImpactBrandPerception' && <PageBreakerForPPT container className='campaignTriggersRow pageBreakerForPPT'><b>PageBreak</b></PageBreakerForPPT>}
    </CampaignGraphsRoot>
  )
}

export default CampaignGraphs
