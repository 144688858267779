import React, { useState, useRef } from 'react'
// import { useFormik } from 'formik'
// import * as Yup from 'yup'
import ReportsService from '../../../../../services/reports.service'

import { Modal, Rte, MuiButton } from '../../../../../components'
// import { IconButton } from '@mui/material'
import { IconEdit } from '../../../../../assets/images/icons'
import { rteRemovingStyleFromTable } from '../../../../../util'

import { LoginButton, Title, Wrapper, RteWrapper, RtePreviewCss } from './style'

const ReportEditBusiness = (props) => {
  const {InsightsElementId, pageData, onSuccess, title} = props
  const [anchorElUser, setAnchorElUser] = useState(null)
  const [showEditHeaderModal, setShowEditHeaderModal] = useState(false)

  const handleOnEditHeader = () => {
    setAnchorElUser(null)
    setShowEditHeaderModal((prev)=>!prev)
  }

  const form = useRef()
  const [loader, setLoader] = useState(false)
  const [headingValue, setHeadingValue] = useState(pageData || '')
  
  // const { enqueueSnackbar } = useSnackbar()

  const onSubmit = (e) => {
    e.preventDefault()
    const updatedValues = {
      insightsElementId : InsightsElementId,
      // businessObjective : encodeURIComponent(headingValue), // Hindi conversion
      businessObjective : encodeURIComponent(rteRemovingStyleFromTable(headingValue)), // Hindi conversion
      // businessObjective : (headingValue),
    }

    setLoader(true)
    ReportsService.reportSetBusinessObjectiveApi(updatedValues)
    .then((resp) => {
      setLoader(false)
      setShowEditHeaderModal(false)
      onSuccess()
    })
    .catch((error) => {
      setLoader(false)
      const resMessage = error
      console.log('ERROR : ', resMessage)
        // enqueueSnackbar(resMessage, { variant: 'error' })
    })
  }

  return (
    <>
      <MuiButton color='secondary' variant='outlined' startIcon={<IconEdit />} sx={{marginLeft:'15px', fontWeight:300}} onClick={() => setShowEditHeaderModal(true)}>Edit {title}</MuiButton>
      {showEditHeaderModal && <Modal title='' showClose={true} onClose={handleOnEditHeader}>
        <Wrapper>
          <form ref={form}>
            <Title variant='body1'>Edit {title}</Title>

            <RteWrapper>
              <Rte title='' value={headingValue} onChange={(e) => setHeadingValue(e)} height={450} customToolbar={'h4 | bold italic underline strikethrough | hr | subscript superscript | bullist numlist indent outdent | removeformat undo redo | table | fontsize | link '} content_style={RtePreviewCss} />
            </RteWrapper>

            <LoginButton
              color='primary'
              variant='contained'
              type='submit'
              onClick={onSubmit}
              disabled={loader || false}
            >
              Submit
            </LoginButton>
          </form>
        </Wrapper>
      </Modal>}
    </>
  )
}

export default ReportEditBusiness
