import React, { useState, useEffect } from 'react'
import { Typography } from '@mui/material'
import { youtube, tiktok, twitter, forums, instagram, linkedin, facebook, graphOne, graphTwo } from '../../../../assets/images/icons'
import { CardOuterWrapper, CardInnerWrapper, CardUl, CardLiHeading, CardLiContent, CardLi, CardGraphWrapper, CardMediaWrapper, Title, TitleDonut, TitlePie, GraphDescription, GraphLegendWrapper, GraphLegend } from './style'
import { ReportHeading, D3Pie,  NoDataFound, LoaderWrapper } from '../../../../components'
import ReportsService from '../../../../services/reports.service'
import { useUpdateLhs } from '../../../../hooks/useUpdateLhs'
import { setCurrency, exportExcel_addPercentage, exportExcel_addSegments } from '../../../../util'

const ReportStudySetup2 = (props) => {
  // const {SourceSplit=[]} = props
  // const { enqueueSnackbar } = useSnackbar()
  const [graphData, setGraphData] = useState([])
  const [loading, setLoading] = useState(false)
  const [pieShow, setPieShow] = useState(true)
  const [reportExcelData, setReportExcelData] = useState()
  const {updateSubLhsApiLoaded} = useUpdateLhs()

  const pieSettings = {
    width: 140,
    height: 140,
    cx: 35,
    cy: 35,
    outerRadius: 35,
    textShow: true,
    colors: ['#00B050', '#FFC000', '#D2D2D2', '#FF0000', '#475467']
  }

  const donutSettings = {
    width: 80,
    height: 80,
    cx: 5,
    cy: 5,
    innerRadius: 25,
    outerRadius: 35,
    textShow: false,
    colors: ['#095BBD', '#F5F5F5']
  }

  const listing = [
    {
      imgTitle: 'YouTube',
      imgSrc: youtube,
      volTitle: '32%(14,452)',
      volSrc: graphOne,
      senTitle: '-8%',
      senSrc: graphTwo
    },
    {
      imgTitle: 'TikTok',
      imgSrc: tiktok,
      volTitle: '16%(5,113)',
      volSrc: graphOne,
      senTitle: '+4%',
      senSrc: graphTwo
    },
    {
      imgTitle: 'Twitter',
      imgSrc: twitter,
      volTitle: '16%(4,960)',
      volSrc: graphOne,
      senTitle: '+64%',
      senSrc: graphTwo
    },
    {
      imgTitle: 'Facebook',
      imgSrc: facebook,
      volTitle: '16%(4,960)',
      volSrc: graphOne,
      senTitle: '+64%',
      senSrc: graphTwo
    },
    {
      imgTitle: 'Forums',
      imgSrc: forums,
      volTitle: '32%(14,452)',
      volSrc: graphOne,
      senTitle: '-8%',
      senSrc: graphTwo
    },
    {
      imgTitle: 'Instagram',
      imgSrc: instagram,
      volTitle: '16%(5,113)',
      volSrc: graphOne,
      senTitle: '+4%',
      senSrc: graphTwo
    },
    {
      imgTitle: 'LinkedIn',
      imgSrc: linkedin,
      volTitle: '16%(5,113)',
      volSrc: graphOne,
      senTitle: '+4%',
      senSrc: graphTwo
    }
  ]

  const PieChartSettings = {
    data: [
      { name: 'Group A', value: 400 },
      { name: 'Group B', value: 300 },
      { name: 'Group C', value: 300 },
      { name: 'Group D', value: 200 }
    ],
    colors: ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'],
    width: 400,
    height: 400,
    cx: 200,
    cy: 200,
    labelLine: false,
    outerRadius: 80,
    fill: '#8884d8',
    dataKey: 'value'
  }

  const loadStudySetup = () => {
    setLoading(true)
    ReportsService.reportStudySetupApi({ insightsElementId: props.InsightsElementId, type: props.type })
      .then((resp) => {
        updateList(resp?.SourceSplit)
        setLoading(false)
        updateSubLhsApiLoaded(props)

        const sheet1Css = {width: { wch: 30 }, style: {...{font:{bold:true}} } } 
        const reportExcelObj = {
          heading:[
            {title:'Source', ...sheet1Css},
            {title:'Volume Percent', ...sheet1Css},
            {title:'Count', ...sheet1Css},
            {title:'Positive Percent', ...sheet1Css},
            {title:'Neutral Percent', ...sheet1Css},
            {title:'Mixed Percent', ...sheet1Css},
            {title:'Negative Percent', ...sheet1Css}
          ],
          values: []
        }

        for(let j=0; j<resp.SourceSplit.length; j++) {
          reportExcelObj.values.push([{value: resp.SourceSplit[j].Platform}, exportExcel_addPercentage(resp.SourceSplit[j].Percent), {value: resp.SourceSplit[j].Count}, ...exportExcel_addSegments(resp.SourceSplit[j])])
          // reportExcelObj.push([{value: resp.SourceSplit[j].Platform}, exportExcel_addPercentage(resp.SourceSplit[j].Percent)])
        }
          // for(let i=0; i<resp.length; i++){
        //   const segmentSet = Array.isArray(resp[i]?.SecondarySegmentSet?.SecondarySegment) ? resp[i].SecondarySegmentSet.SecondarySegment : [resp[i].SecondarySegmentSet.SecondarySegment]
        //   for(let j=0; j<segmentSet.length; j++){
        //     // reportExcelDataObj.push([{value: resp[i].PrimarySegmentName}, {value: segmentSet[j].SegmentName}, {value: segmentSet[j].SegmentValue}])
        //     reportExcelDataObj.push([{value: resp[i].PrimarySegmentName}, {value: segmentSet[j].SegmentName}, exportExcel_addPercentage(segmentSet[j].SegmentValue)])
        //   }
        // }

        setReportExcelData(reportExcelObj)

      })
      .catch((error) => {
        const resMessage = error
        console.log('ERROR : ', resMessage)
        // enqueueSnackbar(resMessage, { variant: 'error' })
        setLoading(false)
      })
  }

  useEffect(() => {
    if (graphData.length === 0) loadStudySetup()
  }, [])

  const updateList = (SourceSplitData) => {
    const graphList = SourceSplitData.map((list) => {
      const indexed = listing.filter(l => l.imgTitle === list.Platform)
      if (indexed.length) {
        list.imgSrc = indexed[0].imgSrc
      }
      list.donut = [
        { name: list.Platform, value: Number(list?.Percent) || 0 },
        { name: '', value: (100 - Number(list?.Percent)) || 0 }
      ]
      list.pie = [
        { name: 'Positive', value: Number(list?.PositivePercent) || 0 },
        { name: 'Neutral', value: Number(list?.NeutralPercent) || 0 },
        { name: 'Mixed', value: Number(list?.MixedPercent) || 0 },
        { name: 'Negative', value: Number(list?.NegativePercent) || 0 },
        { name: 'NetVibe', value: Number(list?.NetPercent) || 0 }
      ]
      // const show = ((Number(list?.PositivePercent) + Number(list?.NeutralPercent) + Number(list?.MixedPercent) + Number(list?.NegativePercent) + Number(list?.NetPercent)) === 0)
      // if(show) {setPieShow(true)}

      return list
    })
    setGraphData(graphList)
  }

  const subHeading = `${props?.IndividualsPostsAnalyzed} posts were analyzed around ${props?.EntityName} AND ITS CAMPAIGN IN ${props?.TimePeriod} (Zero Sampling)`

  return (
    <>
      <ReportHeading downloadId={props?.type} {...props} downloadIdData={reportExcelData} />

      <CardOuterWrapper id={props.type}>
        <LoaderWrapper loading={loading} loadOnlyApi={props.loadOnlyApi}>
          {!graphData?.length && <NoDataFound title='We are working on this ...' position='center' />}

          {!!graphData?.length && <CardInnerWrapper>
            <CardUl>
              <CardLiHeading>
                <Typography>Volume</Typography>
                {pieShow && <Typography>Sentiment</Typography>}
              </CardLiHeading>
              <CardLiContent>
              {graphData.map((graph, index) => {
                return (
                  <CardLi key={index}>
                    <CardMediaWrapper><img src={graph.imgSrc} alt='' /><Title>{graph.Platform}</Title></CardMediaWrapper>
                    <CardGraphWrapper><D3Pie data={[...graph.donut]} settings={donutSettings} /><TitleDonut>{graph?.Percent}% ({setCurrency(graph?.Count)})</TitleDonut></CardGraphWrapper>
                    {pieShow && <CardGraphWrapper><D3Pie data={[...graph.pie]} settings={pieSettings} /><TitlePie>{graph.Percent}%</TitlePie></CardGraphWrapper>}
                  </CardLi>
                )
              })}
              </CardLiContent>
            </CardUl>
          </CardInnerWrapper>}

          {!!graphData?.length && pieShow && <GraphDescription>
            {/* TODO: use legend component */}
            <GraphLegendWrapper>
              <GraphLegend color='#00B050'>Positive</GraphLegend>
              <GraphLegend color='#FFC000'>Neutral</GraphLegend>
              <GraphLegend color='#D2D2D2'>Mixed</GraphLegend>
              <GraphLegend color='#FF0000'>Negative</GraphLegend>
              <GraphLegend color='#475467'>Net Vibe</GraphLegend>
            </GraphLegendWrapper>
            <Typography varient='body2'>POSITIVE: A post where all attributes are positive.</Typography>
            <Typography varient='body2'>NEUTRAL: A post with no sentiments. It is typically quoting fact.</Typography>
            <Typography varient='body2'>MIXED: A post where at least one attribute is positive and at least one attribute is negative.</Typography>
            <Typography varient='body2'>NEGATIVE: A post where all attributes are negative.</Typography>
          </GraphDescription>}
        </LoaderWrapper>
      </CardOuterWrapper>
    </>
  )
}

export default ReportStudySetup2
