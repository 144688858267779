import { styled } from '@mui/material/styles'
import { Box, Typography } from '@mui/material'
import { exportToPPT_tableCss } from '../../../../util'
import { MEDIA } from '../../../../constants'

export const OuterWrapper = styled(Box)(`
    display: flex;    
    width: 100%;
    // padding: 30px;
    ${exportToPPT_tableCss};

    @media (max-width: ${MEDIA.lg}) {
        // padding:30px 20px;
    }
    @media screen and (max-width: 980px) {
        flex-direction: column;
    }

    &.exportToPPT {
        // width: 1400px;
        padding:0;
        // border: 1px red solid;

        & > div {box-shadow:none;}
        & .tabWrapper {display:none;}
        
        // & .chartLhs {width:55%;}
        // & .chartRhs {width:45%; max-width:50%;}

        // & .emojiRow { 
        //     width:100%; 
        //     border: 1px red solid;
        // }
        // & .emoji-item{
        //     text-wrap: nowrap;
        //     border:1px red solid;
        //     min-width:60px;
        // }

        & .brandCategory { border:0; }
        & .brandCategory > div { padding:0; }
    }
    // border: 3px #d3d3d3 solid;

    & .brandHealthExportRowMainHeading {display:none;}
    & .brandHealthExportRowSubHeading span {display:none;}

    // &.exportToPPTAppendices {
    &.exportToPPTProgression {
        width: 1400px;

        & .brandCategory {border: 0;}
        & .brandCategory > div {padding: 0;}
        & .tabWrapper, & .filterOuterWrap {display: none;}
        & .brandHealthSectionsWrapper > div { width: 100%; background: transparent; box-shadow: none;}

        & .graph3Wrapper { display:none; }
        & .brandHealthExportRowMainHeading { 
            font-size:14px;
            display: flex;
            padding: 0;
            span {width: 50%; display:block; padding-top:12px; }
        }
        & .brandHealthExportRowSubHeading { width: 100% !important; padding: 2px 0 0 115px; display:flex; justify-content: space-between;}
        & .brandHealthExportRowSubHeading span { width: 50% !important; padding: 0 0 0 55px; display:block; }
        & .brandHealthExportRowGraph { display:flex; }
        & .brandHealthExportRowProgression {
            .graph1, .graph2 {
                width: 50%;
                padding: 2px 0;
                display: flex !important;
            }
        }

        // & .exportToPPTAppendicesWrapper {
        //     // border: 1px blue solid;
        //     display: flex;
        //     flex-wrap: wrap;
        // }
    }

`)

export const PieChartWrapper = styled(Box)(`
    min-height:400px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 40%;
    background: #F7F7F9;
    @media screen and (max-width: 980px) {
        width:100%;
    }
`)

export const PieChartHeading = styled(Box)(`
    display: flex;
    text-align: center;
    justify-content: center;
    width: 100%;
    font-weight: 500;
    font-size: 14px;
    height: 30px;
    line-height: 20px;
`)

export const PieChartBody = styled(Box)(`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & svg * {
        font-size:14px !important;
        font-weight:300 !important;
    }
`)

export const BarChartsWrapper = styled(Box)(`
    min-height:400px;
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    width: 70%;
    border-top:2px #f7f7f9 solid;
    @media screen and (max-width: 980px) {
        width:100%;
    }
`)

export const SentimentChartWrapper = styled(Box)(`
    position: relative;
    width: 100%;
    height:50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border:2px #f7f7f9 solid;
    border-top:0;
    padding:10px 0;
`)

export const SentimentChartLhs = styled(Box)(`
    position: absolute;
    top: calc(50% - 30px);
    left: -65px;
    transform: rotateZ(-90deg);
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 200px;
    height: 60px;
    text-transform: capitalize;
`)

export const SentimentChartRhs = styled(Box)(`
    display: flex;
    width: calc(100% - 70px);
    flex-direction: column;
`)


export const ChartRow = styled(Box)(`
    display: flex;
    width: 100%;
    padding: 5px 10px 5px 0;
`)

export const RowName = styled(Box)(`
    text-transform: capitalize;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 140px;
    padding-right: 10px;
    font-weight: 300;
    font-size: 14px;
    line-height: 13px;
    text-align:right;
`)

export const ProgressionFiltersWrapper = styled(Box)(`
    display: flex;
    width: 100%;
    margin-bottom:10px;
    // padding-top: 50px;

    & button:hover {
        background-color:transparent !important;
    }
`)

export const BrandHealthSectionsWrapper = styled(Box)(`
    display:flex;
    flex-wrap:wrap;
    justify-content:space-between;
    // padding:10px 28px 0px 28px;

    & > div:first-of-type {
        width:100% !important;
        margin-bottom: 15px;
    }
`)

export const BrandHealthSection = styled(Box)(`
    display: flex;
    border-radius: 2px;
    background: #F7F7F9;
    box-shadow: 0px 0px 5px 0px #D9D9D9;
    flex-direction:column;
    width:calc(50% - 8px);
    &:first-of-type { margin-bottom:14px;}
`)

export const HeadingSection = styled(Box)(`
    display: flex;
    height:40px;
    line-height:40px;
    padding:0 10px;
    border-bottom:1px #fff solid;
    color: #000;
    font-size: 14px;
    font-weight: 500;
`)

export const ContentSection = styled(Box)(`
    display: flex;
    flex-direction: column;
    padding:10px;
`)

export const SubHeadingSection = styled(Typography)(`
    font-size: 12px;
    font-weight: 500;
    width:100%;
    padding: 10px 0 0 130px;
`)

export const TabSection1 = styled(Box)( props => `
    display: flex;
    width: 100%;
    ${props?.hasTabs ? `
        // padding:10px;
        background:#fff !important;
        & > div:last-of-child {
            border:0;
        }
        & > div:last-of-child > div:first-of-child {
            border-width:0 0 1px 0 !important;
        }
        & > div:last-of-child > div:last-of-child {
            border:0;
        }
    ` : ''}
`)

export const TabSection2 = styled(Box)( props => `
    display: flex;
    width: 100%;
    flex-direction:column;
    
    & .barRoot {
        width: calc(100% - 110px);
    }
`)
