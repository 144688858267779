import React from 'react'

const CheckBox = ({variant, color='dark'}) => {
  const clr = color === 'primary' ? '#1B6AC6' : '#000'
  return (
    <>
      {variant === 'outlined' && <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="13" height="13" rx="0.5" fill="white" stroke={clr} />
      </svg>}
      {variant === 'contained' && <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="13" height="13" rx="0.5" fill={clr} stroke={clr} />
        <path d="M10.8291 4.17117C10.7201 4.06156 10.5725 4 10.4185 4C10.2645 4 10.1168 4.06156 10.0078 4.17117L5.69431 8.51684C5.68531 8.52598 5.6746 8.53324 5.6628 8.53819C5.651 8.54314 5.63834 8.54569 5.62555 8.54569C5.61277 8.54569 5.60011 8.54314 5.58831 8.53819C5.57651 8.53324 5.56579 8.52598 5.55679 8.51684L3.98885 6.93659C3.87964 6.82824 3.73235 6.76774 3.57906 6.76828C3.42577 6.76882 3.27889 6.83034 3.17044 6.93946C3.06198 7.04857 3.00072 7.19643 3.00001 7.35083C2.99929 7.50523 3.05918 7.65366 3.16663 7.76379L5.21396 9.82883C5.32291 9.93844 5.4706 10 5.62458 10C5.77857 10 5.92626 9.93844 6.03521 9.82883L10.8291 5.00031C10.8833 4.94593 10.9262 4.88132 10.9556 4.81018C10.9849 4.73904 11 4.66277 11 4.58574C11 4.50871 10.9849 4.43243 10.9556 4.36129C10.9262 4.29015 10.8833 4.22554 10.8291 4.17117Z" fill="white"/>
      </svg>}
    </>
  )
}

export default CheckBox
