import React from 'react'
import PropTypes from 'prop-types'
import { LayoutFooter } from '../../components'
import {
  PreLoginOuterWrapper,
  PreLoginInnerWrapper,
  Wrapper,
  FormWrapper,
  InsightsAssistantLogo,
  MavenMagnetLogo,
  FooterWrapper
} from './style'
import { insightsAssistant } from '../../assets/images/icons'
import { logoMaven } from '../../assets/images'


const PreLogin = ({ children }) => {
  return (
    <PreLoginOuterWrapper>
      <PreLoginInnerWrapper>
        <Wrapper>
          
          <FormWrapper>
            <InsightsAssistantLogo>
              <img src={insightsAssistant} width='93px' border='0' />
            </InsightsAssistantLogo>
            {children}
          </FormWrapper>

          <MavenMagnetLogo>
            <img src={logoMaven} width='270px' border='0' />
          </MavenMagnetLogo>

        </Wrapper>
      </PreLoginInnerWrapper>
      <FooterWrapper>
        <p> © MavenMagnet  |  <a href='https://www.mavenmagnet.com/privacy-policy' target='_blank'>Privacy Policy</a></p>
      </FooterWrapper>

    </PreLoginOuterWrapper>
  )
}

PreLogin.propTypes = {
  children: PropTypes.node
}

PreLogin.displayName = 'PreLogin'

export default PreLogin
