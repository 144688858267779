import React, { useState, useEffect, useRef } from 'react'
import { Box } from '@mui/material'
import { LEGEND_COLOR_MAP } from '../../constants'
import { Wrapper, InnerBar } from './style'

export default function ChartBarHorizontalCustom(props) {
  const [barGraphWidth, setBarGraphWidth] = useState(300)
  const refBarId = useRef(`_bar_${props.data.length}_${new Date().getTime()}`);
  const max = props?.isDecimal ? parseFloat(props?.maxPercentRatio) : parseInt(props?.maxPercentRatio) || 100
  const { startIcon, endIcon, showInside = true, bottomText = '', rootClass = 'barRoot', valueInPercent = false, maxWidth, background = 'transparent', showToolTip = false, customPercentage } = props
  const settings = {
    width: '100%',
    maxWidth: maxWidth || 190,
    height: props?.height || '28px'
  }

  useEffect(() => {
    if(refBarId?.current){
      setBarGraphWidth(refBarId.current.offsetWidth)
    }
  }, [refBarId])

  return (
    <Wrapper
      ref={refBarId}
      className={rootClass}
      sx={{
        width: settings.width,
        height: settings.height,
        maxWidth: settings.maxWidth,
        background: background
      }}
    >
      {props?.data.map((L1, i) => {
        const arr = Object.keys(L1)

        return (
          <li key={i} className='barItemRoot'>
            <Box className='barSliceWrap' sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
              {!!startIcon && <Box className='barSliceStartIcon' sx={{ marginRight: 1 }}>{startIcon}</Box>}
              {arr.map((L2, j) => {
                const numberValue = props?.isDecimal ? parseFloat(L1[L2]) : parseInt(L1[L2]);
                const widthInPercentage = customPercentage && numberValue ? customPercentage : (numberValue / max) * 100
                const l2Width = {
                  width: `${widthInPercentage}%`,
                  height: props?.height || '28px',
                  background: props?.bG || props?.colorMap?.[L2] || props?.colorIndex?.[j] || LEGEND_COLOR_MAP[L2] || 'teal',
                }
                const barVal = (showInside && L1[L2] !== 0) ? `${L1[L2]}${valueInPercent ? '%' : ''}` : null
                const toolTipBarVal = `${L1[L2]}${valueInPercent ? '%' : ''}`
                const getPxWidthFunc = () => {
                  const tdWidth = barGraphWidth - 20;
                  const divPercentWidth = widthInPercentage;
                  const value = (tdWidth*divPercentWidth)/100
                  return (value > 19)
                }

                return (
                  <InnerBar data-l2={[L1[L2]]} data-max={max} data='data' className={`barSlice ${L2} ${showToolTip || ['Neutral', 'Mixed'].includes(L2) ? 'showToolTip' : null} ${!getPxWidthFunc() ? 'showToolTip' : null}`} key={j} style={l2Width} data-tool={toolTipBarVal}>
                    {showInside && getPxWidthFunc() && L1[L2] !== 0 ? <span className='barSliceLabel'>{customPercentage ? parseInt(barVal).toLocaleString() : barVal}</span> : null}
                    {/* {arr.length - 1 === j && props?.total && (<span className='barSlicePercentage'>{props?.total}{valueInPercent ? '%' : ''}</span>)} */}
                  </InnerBar>
                )
              })}
              {props?.total && (<div className='barSlicePercentage'>{props?.total}{valueInPercent ? '%' : ''}</div>)}

              {!!endIcon && <Box className='barSliceEndIcon' sx={{ marginLeft: 1 }}>{endIcon}</Box>}
            </Box>
            {!!bottomText && (
              <Box className='barSliceBottom' sx={{ marginTop: '2px', fontSize: '14px', fontWeight: 300, whiteSpace: 'nowrap' }}>
                {bottomText}
              </Box>
            )}
          </li>
        )
      })}
    </Wrapper>
  )
}
