import React from 'react'
import { PrimaryText, RootWrap, SecondaryText } from './style'

const TextNumberBox = ({primary, secondary}) => {

  return (
    <RootWrap className='text-number-root'>
        <PrimaryText className='text-number-primary'>{primary}</PrimaryText>
        <SecondaryText marginTop="auto" className='text-number-seconary'>{secondary}</SecondaryText>
    </RootWrap>
  )
}

export default TextNumberBox