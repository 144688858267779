import React from 'react'
import { Box } from '@mui/material'
import { Wrapper, SearchList } from './style'

const SearchKeywordGrid = ({ data }) => {
  return (
    <Wrapper className='SearchKeyboard'>
      {data.map((v, i) => {
        return (
          <SearchList key={i}>{v}</SearchList>
        )
      })}
    </Wrapper>
  )
}

export default SearchKeywordGrid

