import { styled } from '@mui/system'
import { Box, Typography } from '@mui/material'

export const Heading = styled(Box)(props =>`
  padding-bottom:30px;
  & p {
    font-weight:500;
    font-size:16px;
    color: ${props.type ? '#EB3223' : '#000'};
  }
`)

export const ErrorMsg = styled(Typography)(`
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color:#000;
  padding-bottom:20px;
`)

export const LinkButton = styled('a')(`
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color:#000 !important;
  text-decoration: underline;
`)

