import React, { useEffect, useState } from "react";
import { MuiSelect } from "../../../../components";
import reportsService from "../../../../services/reports.service";

const BrandCategoryName = ({ insightsElementId, onChange, name="entityName", title="Brand/Category", value, isShadow, ...rest }) => {
  const [state, setstate] = useState([]);

  const getSandboxAllEntityNamesElementHandler = async () => {
    try {
      const resp = await reportsService.getSandboxAllEntityNamesElement({
        insightsElementId: insightsElementId,
      });
      const data = resp.map((item) => ({
        label: item,
        value: item,
      }));
      const filteredData = data.filter(item => !!item.value)
      if (!filteredData?.length) return;
      setstate([
        { label: '', value: '' },
        ...filteredData
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSandboxAllEntityNamesElementHandler();
  }, []);

  return (
    <MuiSelect
      name={name}
      compact
      onChange={(e) => onChange(name, e.target.value)}
      title={title}
      data={state}
      value={value || undefined}
      variant={isShadow ? "outlined" : "standard"}
      placeholder="Select"
      selectlabel="label"
      selectvalue="value"
      {...rest}
    />
  );
};

export default BrandCategoryName;