import React, { useState, useRef, useEffect } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import AuthService from '../../services/auth.service'
import { Box, Typography } from '@mui/material'
import PreLogin from '../../layout/preLogin'
import { MuiTextField, MuiButton } from '../../components'
import { ROUTE_LOGIN } from '../../constants'
import { ResetPasswordStep1Wrapper, WrapperResetPassStep3, Heeding, ButtonWrapper, FormError, SuccessMessageBox, InputWrap, OtpWrapper, ButtonWrap } from './style'
import OtpInputWithValidation from './OtpInputWithValidation'

const ResetPassword = (props) => {
  const [step, setStep] = useState(0)
  const [successData, setSuccessData] = useState({})

  return (
    <PreLogin>
      {step === 0 && <ResetPasswordStep1 onSuccess={(data) => {setSuccessData(data); setStep(1)}} />}
      {step === 1 && <ResetPasswordStep2 onSuccess={(data) => {setSuccessData(data); setStep(2)}} {...successData} />}
      {step === 2 && <ResetPasswordStep3 onSuccess={(data) => {setSuccessData(data); setStep(3)}} {...successData} />}
      {step === 3 && <ResetPasswordStep4 onSuccess={() => setStep(4)} />}
    </PreLogin>
  )
}

export default ResetPassword


const ResetPasswordStep1 = (props) => {
  const {onSuccess} = props

  const form = useRef()
  const [loader, setLoader] = useState(false)
  // const { enqueueSnackbar } = useSnackbar()

  const onSubmit = (data) => {
    setLoader(true)
    AuthService.resetPassword1Api(data)
      .then((resp) => {
        setLoader(false)
        if(!!resp){
          onSuccess({email:data.email, code:resp})
        }
        else{
          formik.setFieldError('email','Email address is incorrect')
        }
      })
      .catch((error) => {
        setLoader(false)
        const resMessage = error
        console.log('ERROR : ', resMessage)
        // enqueueSnackbar(resMessage, { variant: 'error' })
      })
  }

  const validation = (values) => {
    const errors = {}
    return errors
  }

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      email: ''
    },
    validationSchema: Yup.object({
      email: Yup
        .string()
        .email('Must be a valid email')
        .max(35)
        .required('Email is required')
    }),
    validate: (values) => validation(values),
    onSubmit: (values) => {
      const updatedValues = { ...values }
      onSubmit(updatedValues)
    }
  })

  return (
    <ResetPasswordStep1Wrapper>
      <form ref={form} onSubmit={formik.handleSubmit} autoComplete='off'>
        <Heeding>
          <Typography color='textPrimary'>Reset Password</Typography>
        </Heeding>
        <MuiTextField
          error={Boolean(formik.touched.email && formik.errors.email)}
          fullWidth
          helperText={formik.touched.email && formik.errors.email}
          label='Email'
          margin='normal'
          name='email'
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          type='text'
          value={formik.values.email}
          variant='standard'
        />
        
        <ButtonWrapper>
          <MuiButton className="resetButton" variant='contained' color='secondary' type='submit' onClick={formik.handleSubmit}>SEND CODE</MuiButton>
          <MuiButton className="backToLogin" to={ROUTE_LOGIN} variant='text' color='dark' size='small'>Back to Sign in</MuiButton>
        </ButtonWrapper>

      </form>
    </ResetPasswordStep1Wrapper>
  )

}


const ResetPasswordStep2 = (props) => {
  const {onSuccess, code, email} = props
  const form = useRef()

  const onSubmit = () => {
    onSuccess({email:email})
  }

  const validation = (values) => {
    const errors = {}
    if (values?.otp?.length < 6) {
      errors.fullCode = 'Code is required'
    }
    else {
      if(code !== parseInt(values?.otp)){
        errors.fullCode = 'Invalid Code'
      }
    }
    return errors
  }

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      otp: ''
    },
    validate: (values) => validation(values),
    onSubmit: (values) => {
      const updatedValues = {
        code : values.otp
      }
      onSubmit(updatedValues)
    }
  })

  useEffect(() => {
  }, [])

  return (
    <Box>
      <form ref={form} onSubmit={formik.handleSubmit} autoComplete='off'>
        <Heeding>
          <Typography color='textPrimary'>Enter the 6 digits code that you received in your email.</Typography>
        </Heeding>
        <OtpWrapper>
          <OtpInputWithValidation
            value={formik.values.otp}
            onChange={(e) => formik.setFieldValue('otp', e)}
          />
        </OtpWrapper>
        
        {formik.errors.fullCode && <FormError className='codeError'>{formik.errors.fullCode}</FormError>}
        <ButtonWrapper>
          <MuiButton className="resetButton" variant='contained' color='secondary' type='submit' onClick={formik.handleSubmit}>CONTINUE</MuiButton>
          <MuiButton className="backToLogin" to={ROUTE_LOGIN} variant='text' color='dark' size='small'>Back to Sign in</MuiButton>
        </ButtonWrapper>
      </form>
    </Box>
  )
  
}

const ResetPasswordStep3 = (props) => {
  const {onSuccess, email} = props
  const form = useRef()

  const [loader, setLoader] = useState(false)
  // const { enqueueSnackbar } = useSnackbar()

  const onSubmit = (data) => {
    setLoader(true)
    AuthService.resetPassword2Api(data)
      .then((resp) => {
        setLoader(false)
        onSuccess()
      })
      .catch((error) => {
        setLoader(false)
        const resMessage = error
        console.log('ERROR : ', resMessage)
        // enqueueSnackbar(resMessage, { variant: 'error' })
      })
  }

  const validation = (values) => {
    const errors = {}
    if (values.newPassword !== values.confirmPassword) {
      errors.confirmPassword = 'Confirm password not matching with New Password !'
    }
    return errors
  }

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      newPassword: Yup
        .string()
        .max(25)
        .required('New password is required'),
      confirmPassword: Yup
        .string()
        .max(25)
        .required('Confirm password is required')
    }),
    validate: (values) => validation(values),
    onSubmit: (values) => {
      const updatedValues = { ...values, email: email }
      onSubmit(updatedValues)
    }
  })

  return (
    <WrapperResetPassStep3>
      <form ref={form} onSubmit={formik.handleSubmit} autoComplete='off'>
        <Heeding>
          <Typography color='textPrimary'>Reset your password</Typography>
        </Heeding>
        <InputWrap mt={2}>
          <MuiTextField
            error={Boolean(formik.touched.newPassword && formik.errors.newPassword)}
            helperText={formik.touched.newPassword && formik.errors.newPassword}
            name='newPassword'
            label='New Password'
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            type='password'
            value={formik.values.newPassword}
            variant='standard'
          />
        </InputWrap>
        <InputWrap mt={1}>
        <MuiTextField
          error={Boolean(formik.touched.confirmPassword && formik.errors.confirmPassword)}
          helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
          name='confirmPassword'
          label='Confirm Password'
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          type='password'
          value={formik.values.confirmPassword}
          variant='standard'
        />
        </InputWrap>
        <ButtonWrapper>
          <MuiButton className="resetButton" variant='contained' color='secondary' type='submit' onClick={formik.handleSubmit}>CONTINUE</MuiButton>
          <MuiButton className="backToLogin" to={ROUTE_LOGIN} variant='text' color='dark' size='small'>Back to Sign in</MuiButton>
        </ButtonWrapper>

      </form>
    </WrapperResetPassStep3>
  )
  
}


const ResetPasswordStep4 = (props) => {
  const {onSuccess} = props

  const [loader, setLoader] = useState(false)

  return (
    <Box>
        <SuccessMessageBox>
          <Typography color='textPrimary' variant='body2'>Password Changed</Typography>
          <Typography color='textPrimary' variant='body1'>Your password has been changed successfully.</Typography>
        </SuccessMessageBox>
        <ButtonWrap>
          <MuiButton className="resetButton" variant='contained' color='secondary' to={ROUTE_LOGIN}>LOGIN NOW</MuiButton>
        </ButtonWrap>
    </Box>
  )
  
}


