import { Box } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { MuiButton, MuiSelect, MuiTextField } from "../../../../components";
import ReportsService from "../../../../services/reports.service";
import { FilterItemWrap } from "./style";

const Filters = ({ insightsElementId, onFilterChange, isShadow = false }) => {
  const form = useRef()
  const [filters, setFilters] = useState({
    entityName: "",
    driverName: "",
    attributeName: "",
    searchText: ""
  })
  const onChangeHandler = (key, value) => {
    const obj = {
      ...filters,
      [key]: value,
    }

    if (key === 'entityName') {
      obj.attributeName = ""
      obj.driverName = ""
      obj.searchText = ""
    }
    if (key === 'driverName') {
      obj.attributeName = ""
      obj.searchText = ""
    }
    if (key === 'attributeName') {
      obj.searchText = ""
    }

    setFilters(obj);
  };

  const submitPayload = (e) => {
    e.preventDefault()
    const obj = {...filters, insightsElementId: insightsElementId};
    // if(!obj?.entityName) delete obj?.entityName;
    // if(!obj?.driverName) delete obj?.driverName;
    // if(!obj?.attributeName) delete obj?.attributeName;
    // if(!obj?.searchText?.trim()) delete obj?.searchText;

    onFilterChange(obj)
  }

  return (
    <form ref={form} onSubmit={submitPayload}>
      <BrandCategoryFilter
        insightsElementId={insightsElementId}
        onChange={onChangeHandler}
        value={filters?.entityName || ''}
        isShadow={isShadow}
      />
      <DriverFilter
        insightsElementId={insightsElementId}
        onChange={onChangeHandler}
        entityName={filters?.entityName || ""}
        value={filters?.driverName || ''}
        isShadow={isShadow}
      />
      <AttributeFilter
        insightsElementId={insightsElementId}
        onChange={onChangeHandler}
        entityName={filters?.entityName || ""}
        driverName={filters?.driverName || ""}
        value={filters?.attributeName || ''}
        isShadow={isShadow}
      />
      <Box sx={{
        marginBottom: "20px",
        "& label": {
          color: '#545454',
          fontSize: '10px'
        }
      }}>
        <MuiTextField
          name="searchText"
          placeholder="Enter Text"
          compact
          onChange={(e) => onChangeHandler('searchText', e.target.value)}
          title="Search"
          value={filters?.searchText || ""}
        />
      </Box>
      <MuiButton
        className='sandBoxFilter'
        sx={{
          width: "100%",
          height: "40px"
        }}
        onClick={submitPayload}
        type='submit'
        color='primary'
        variant='contained'
      >
        Filter
      </MuiButton>
    </form>
  );
};

export default Filters;

const BrandCategoryFilter = ({ insightsElementId, onChange, value, isShadow }) => {
  const [state, setstate] = useState([]);

  const getSandboxEntityNamesElementHandler = async () => {
    try {
      const resp = await ReportsService.getSandboxEntityNamesElement({
        insightsElementId: insightsElementId,
      });
      const data = resp.map((item) => ({
        label: item,
        value: item,
      }));
      const filteredData = data.filter(item => !!item.value)
      if (!filteredData?.length) return;
      setstate([
        { label: '', value: '' },
        ...filteredData
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSandboxEntityNamesElementHandler();
  }, []);

  return (
    <FilterItemWrap className={`filterItemWrap ${isShadow ? "" : "defaultItem"}`}>
      <MuiSelect
        name="entityName"
        onChange={(e) => onChange(e.target.name, e.target.value)}
        title="Brand/Category"
        data={state}
        value={value || undefined}
        menuprops={{ style: { maxHeight: '200px', maxWidth: '180px' } }}
        variant={isShadow ? "outlined" : "standard"}
        placeholder="Select"
        compact
        selectlabel="label"
        selectvalue="value"
      />
    </FilterItemWrap>
  );
};

const DriverFilter = ({ insightsElementId, onChange, entityName, value, isShadow }) => {
  const [state, setstate] = useState([]);

  const getSandboxDriverNamesElementHandler = async () => {
    try {
      const resp = await ReportsService.getSandboxDriverNamesElement({
        insightsElementId: insightsElementId,
        entityName
      });
      const data = resp.map((item) => ({
        label: item,
        value: item,
      }));
      const filteredData = data.filter(item => !!item.value)
      if (!filteredData?.length) return;
      setstate([
        { label: '', value: '' },
        ...filteredData
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // if(entityName) {
    getSandboxDriverNamesElementHandler();
    // }
  }, [entityName]);


  return (
    <FilterItemWrap className={`filterItemWrap ${isShadow ? "" : "defaultItem"}`}>
      <MuiSelect
        name="driverName"
        onChange={(e) => onChange(e.target.name, e.target.value)}
        title="Driver"
        data={state}

        menuprops={{ style: { maxHeight: '200px', maxWidth: '180px' } }}
        compact
        // disabled={!entityName}
        placeholder="Select"
        // endAdornment
        variant={isShadow ? "outlined" : "standard"}
        selectlabel="label"
        selectvalue="value"
        value={value || null}
      />
    </FilterItemWrap>
  );
};


const AttributeFilter = ({ insightsElementId, onChange, entityName, driverName, value, isShadow }) => {
  const [state, setstate] = useState([]);

  const getSandboxAttributeNamesElementHandler = async () => {
    try {
      const resp = await ReportsService.getSandboxAttributeNamesElement({
        insightsElementId: insightsElementId,
        entityName,
        driverName
      })
      const data = resp.map((item) => ({
        label: item,
        value: item
      }))
      const filteredData = data.filter(item => !!item.value)
      // if (!filteredData?.length) return;
      setstate([
        { label: '', value: '' },
        ...filteredData
      ])
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getSandboxAttributeNamesElementHandler();
  }, [entityName, driverName]);

  return (
    <FilterItemWrap className={`filterItemWrap ${isShadow ? "" : "defaultItem"}`}>
      <MuiSelect
        name="attributeName"
        onChange={(e) => onChange(e.target.name, e.target.value)}
        title="Attribute"
        data={state}
        compact
        value={value || null}
        menuprops={{ style: { maxHeight: '200px', maxWidth: '180px' } }}
        placeholder="Select"
        // endAdornment
        variant={isShadow ? "outlined" : "standard"}
        selectlabel="label"
        selectvalue="value"
      />
    </FilterItemWrap>
  )
}



const SubAttributeFilter = ({ insightsElementId, onChange, entityName, driverName, attributeName, value, isShadow }) => {
  const [state, setstate] = useState([]);

  const getSandboxSubAttributeNamesElementHandler = async () => {
    try {
      const resp = await ReportsService.getSandboxSubAttributeNamesElement({
        insightsElementId: insightsElementId,
        entityName,
        driverName,
        attributeName
      });
      const data = resp.map((item) => ({
        label: item,
        value: item,
      }));
      const filteredData = data.filter(item => !!item.value)
      if (!filteredData?.length) return;
      setstate([
        { label: '', value: '' },
        ...filteredData
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSandboxSubAttributeNamesElementHandler();
  }, [entityName, driverName]);

  return (
    <FilterItemWrap className={`filterItemWrap ${isShadow ? "" : "defaultItem"}`}>
      <MuiSelect
        name="subAttributeName"
        onChange={(e) => onChange(e.target.name, e.target.value)}
        title="Sub Attribute"
        data={state}
        compact
        value={value || null}
        menuprops={{ style: { maxHeight: '200px', maxWidth: '180px' } }}
        placeholder="Select"
        // endAdornment
        variant={isShadow ? "outlined" : "standard"}
        selectlabel="label"
        selectvalue="value"
      />
    </FilterItemWrap>
  );
};
