
import exportFromJSON from 'export-from-json'
// https://www.npmjs.com/package/export-from-json
// https://codepen.io/zheeeng/pen/PQxBKr

const jsonToXml = (data, replaceMode) => {

    const fileName = 'temp'
    const exportType = 'xml'
    const fields = []

    const output = exportFromJSON({ data, fileName, fields, exportType, processor: content => content })
    
    let outputXML = JSON.parse(
        (JSON.stringify(output))
            .replace('<!DOCTYPE base>','')
            .replaceAll('<base>','').replaceAll('</base>','')
            .replaceAll('<element>','').replaceAll('</element>','')
            // .replace(/\s+/g, '')
            .replaceAll('\\t','')
            .replaceAll('\\n','')
            .split(' ').filter(item => !!item).join(' ')
            .replaceAll('> ','>').replaceAll(' <','<')
    )

    // if(replaceMode==='strict'){
    //     outputXML = JSON.parse(
    //         (JSON.stringify(outputXML))
    //             .split(' ').filter(item => !!item).join(' ')
    //     )
    // }

    return outputXML

}

export default jsonToXml

