import React, { useState, useEffect } from 'react'
import { IconButton } from '@mui/material'
import { useGlobalStore } from '../../../store'
import { MuiImage, Confirmation } from '../../../components'
import ProjectsService from '../../../services/projects.service'
import { freeze, unfreeze, publish, unpublish, adminReview, unAdminReview, enableAskIa, disableAskIa } from '../../../assets/images/icons'
import { ROLES_PUBLISHER } from '../../../constants'

const PublishProject = (props) => {
    const [loading, setLoading] = useState(true)
    const [confirmationVal, setConfirmationVal] = useState('')
    const [confirmationType, setConfirmationType] = useState('')
    const {user, projectIdValue, setProjectIdValue} = useGlobalStore()
    const {IsAskIAForStudy, IsFreezed, StudyId} = projectIdValue
    const [IsAdminReview, setIsAdminReview] = useState(null)
    const [IsPublished, setIsPublished] = useState(null)

    const PublishReport = () => {
        setLoading(true)
        ProjectsService.projectsSetPublishStudy({studyId:StudyId, publish:!IsPublished})
        .then((resp) => {
            const data = {...projectIdValue, IsPublished:!IsPublished}
            setProjectIdValue(data)
            setLoading(false)
            setConfirmationType(null)
            setIsPublished(!IsPublished)
            // setIsAdminReview(IsPublished)
            ///////////////////////////////////////////////
            ProjectsService.projectsDetailApi({projectId: projectIdValue.ProjectId}, true)
                .then((resp) => { })
                .catch((error) => { })
        })
        .catch((error) => {
            console.log('ERROR : ', error)
            setLoading(false)
        })
    }

    const FreezeReport = () => {
        setLoading(true)
        ProjectsService.projectsSetFreezeStudy({studyId:StudyId, freeze:!IsFreezed})
        .then((resp) => {
            const data = {...projectIdValue, IsFreezed:!IsFreezed}
            setProjectIdValue(data)
            setLoading(false)
            setConfirmationType(null)
            ///////////////////////////////////////////////
            ProjectsService.projectsDetailApi({projectId: projectIdValue.ProjectId}, true)
                .then((resp) => { })
                .catch((error) => { })
        })
        .catch((error) => {
            console.log('ERROR : ', error)
            setLoading(false)
        })
    }

    const AdminReviewReport = () => {
        setLoading(true)
        ProjectsService.projectsSetAdminReviewStudy({studyId:StudyId, adminReview:!IsAskIAForStudy})
        .then((resp) => {
            const data = {...projectIdValue, StudyStatus:projectIdValue.StudyStatus === 'AdminReview' ? 'InProgress' : 'AdminReview'}
            setProjectIdValue(data)
            setLoading(false)
            setConfirmationType(null)
            // setIsAdminReview(!IsAdminReview)
            // setIsPublished(IsAdminReview)
            ///////////////////////////////////////////////
            ProjectsService.projectsDetailApi({projectId: projectIdValue.ProjectId}, true)
                .then((resp) => { })
                .catch((error) => { })
        })
        .catch((error) => {
            console.log('ERROR : ', error)
            setLoading(false)
        })
    }

    const AskIAReport = () => {
        setLoading(true)
        ProjectsService.projectsSetAskIAForStudy({studyId:StudyId, askIA:!IsAskIAForStudy})
        .then((resp) => {
            const data = {...projectIdValue, IsAskIAForStudy:!IsAskIAForStudy}
            setProjectIdValue(data)
            setLoading(false)
            setConfirmationType(null)
            ///////////////////////////////////////////////
            ProjectsService.projectsDetailApi({projectId: projectIdValue.ProjectId}, true)
                .then((resp) => { })
                .catch((error) => { })
        })
        .catch((error) => {
            console.log('ERROR : ', error)
            setLoading(false)
        })
    }

    useEffect(() => {
        let aReview = !projectIdValue.IsPublished && projectIdValue.StudyStatus === 'AdminReview'
        let completed = projectIdValue.IsPublished // || projectIdValue.StudyStatus === 'Completed' 

        setIsAdminReview(aReview)
        setIsPublished(completed)
    }, [projectIdValue])

    return (
        <>
            {[ROLES_PUBLISHER].includes(props.userType) && !IsAdminReview && <IconButton variant='contained' color='light' title='Admin Review' onClick={() => {setConfirmationType('admintreview'); setConfirmationVal('admintreview') }}><MuiImage src={adminReview} alt='AdminReview' /></IconButton>}
            {[ROLES_PUBLISHER].includes(props.userType) && IsAdminReview && <IconButton variant='contained' color='light' title='Un Admin Review' onClick={() => {setConfirmationType('unadmintreview'); setConfirmationVal('unadmintreview') }}><MuiImage src={unAdminReview} alt='UnAdminReview' /></IconButton>}
            
            
            {[ROLES_PUBLISHER].includes(props.userType) && !IsPublished && <IconButton variant='contained' color='light' title='Publish' onClick={() => {setConfirmationType('publish'); setConfirmationVal('publish') }} sx={{marginLeft:'10px'}}><MuiImage src={publish} alt='Publish' /></IconButton>}
            {[ROLES_PUBLISHER].includes(props.userType) && IsPublished && <IconButton variant='contained' color='light' title='Unpublish' onClick={() => {setConfirmationType('publish'); setConfirmationVal('unpublish') }} sx={{marginLeft:'10px'}}><MuiImage src={unpublish} alt='UnPublish' /></IconButton>}
            {[ROLES_PUBLISHER].includes(props.userType) && !IsFreezed && <IconButton variant='contained' color='light' title='Freeze' onClick={() => {setConfirmationType('freeze'); setConfirmationVal('Freeze') }} sx={{marginLeft:'10px'}}><MuiImage src={freeze} alt='Freeze' /></IconButton>}
            {[ROLES_PUBLISHER].includes(props.userType) && IsFreezed && <IconButton variant='contained' color='light' title='Un Freeze' onClick={() => {setConfirmationType('freeze'); setConfirmationVal('Unfreeze') }} sx={{marginLeft:'10px'}}><MuiImage src={unfreeze} alt='UnFreeze' /></IconButton>}
            
            {[ROLES_PUBLISHER].includes(props.userType) && !IsAskIAForStudy && <IconButton variant='contained' color='light' title='Enable AskIA' onClick={() => {setConfirmationType('enableAskIA'); setConfirmationVal('enableAskIA') }} sx={{marginLeft:'10px'}}><MuiImage src={enableAskIa} alt='Enable AskIA' /></IconButton>}
            {[ROLES_PUBLISHER].includes(props.userType) && IsAskIAForStudy && <IconButton variant='contained' color='light' title='Disable AskIA' onClick={() => {setConfirmationType('disableAskIA'); setConfirmationVal('disableAskIA') }} sx={{marginLeft:'10px'}}><MuiImage src={disableAskIa} alt='Disable AskIA' /></IconButton>}
            
            {confirmationType === 'publish' && <Confirmation
                open={true} 
                title={`Are you sure you want to ${confirmationVal} the study?`}
                onClose={()=>setConfirmationType(null)} 
                onCancel={PublishReport}
                cancelMsg={'Yes'}
                showSubmitMsg={false}
            />}
            {confirmationType === 'publish' && <Confirmation
                open={true} 
                title={`Are you sure you want to ${confirmationVal} the study?`}
                onClose={()=>setConfirmationType(null)} 
                onCancel={PublishReport}
                cancelMsg={'Yes'}
                showSubmitMsg={false}
            />}
            {confirmationType === 'freeze' && <Confirmation
                open={true} 
                title={`Are you sure you want to ${confirmationVal} the study?`}
                onClose={()=>setConfirmationType(null)} 
                onCancel={FreezeReport}
                cancelMsg={'Yes'}
                showSubmitMsg={false}
            />}
            {confirmationType === 'admintreview' && <Confirmation
                open={true} 
                title={`Are you sure you want to move the study under review?`}
                onClose={()=>setConfirmationType(null)} 
                onCancel={AdminReviewReport}
                cancelMsg={'Yes'}
                showSubmitMsg={false}
            />}
            {confirmationType === 'unadmintreview' && <Confirmation
                open={true} 
                title={`Are you sure you want to move the study back to In Progress?`}
                onClose={()=>setConfirmationType(null)} 
                onCancel={AdminReviewReport}
                cancelMsg={'Yes'}
                showSubmitMsg={false}
            />}
            {confirmationType === 'enableAskIA' && <Confirmation
                open={true}
                title={`Are you sure you want to disable the Ask IA?`}
                onClose={()=>setConfirmationType(null)} 
                onCancel={AskIAReport}
                cancelMsg={'Yes'}
                showSubmitMsg={false}
            />}
            {confirmationType === 'disableAskIA' && <Confirmation
                open={true} 
                title={`Are you sure you want to enable the Ask IA?`}
                onClose={()=>setConfirmationType(null)} 
                onCancel={AskIAReport}
                cancelMsg={'Yes'}
                showSubmitMsg={false}
            />}

        </>
    )

}

export default PublishProject
