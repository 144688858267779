import { styled } from '@mui/material/styles'
import { Box, Typography } from '@mui/material'
import { exportToPPT_tableCss } from '../../../../util'
import { MEDIA } from '../../../../constants'

export const OuterWrapper = styled(Box)(`
    display: flex;    
    width: 100%;
    padding: 30px;
    ${exportToPPT_tableCss};
    @media (max-width: ${MEDIA.lg}) {
        padding:30px 20px;
    }
    @media screen and (max-width: 980px) {
        flex-direction: column;
    }
`)

export const PieChartWrapper = styled(Box)(`
    min-height:400px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 40%;
    background: #F7F7F9;
    @media screen and (max-width: 980px) {
        width:100%;
    }
`)

export const PieChartHeading = styled(Box)(`
    display: flex;
    text-align: center;
    justify-content: center;
    width: 100%;
    font-weight: 500;
    font-size: 14px;
    height: 30px;
    line-height: 20px;
`)

export const PieChartBody = styled(Box)(`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & svg * {
        font-size:14px !important;
        font-weight:300 !important;
    }
`)

export const BarChartsWrapper = styled(Box)(`
    min-height:400px;
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    width: 70%;
    border-top:2px #f7f7f9 solid;
    @media screen and (max-width: 980px) {
        width:100%;
    }
`)

export const SentimentChartWrapper = styled(Box)(`
    position: relative;
    width: 100%;
    height:50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border:2px #f7f7f9 solid;
    border-top:0;
    padding:10px 0;
`)

export const SentimentChartLhs = styled(Box)(`
    position: absolute;
    top: calc(50% - 30px);
    left: -65px;
    transform: rotateZ(-90deg);
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 200px;
    height: 60px;
    text-transform: capitalize;
`)

export const SentimentChartRhs = styled(Box)(`
    display: flex;
    width: calc(100% - 70px);
    flex-direction: column;
`)


export const ChartRow = styled(Box)(`
    display: flex;
    width: 100%;
    padding: 5px 40px 5px 0;
`)

export const RowName = styled(Box)(`
    text-transform: capitalize;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    max-width: 200px;
    min-width: 80px;
    width: 25%;
    padding-right: 10px;
    font-weight: 300;
    font-size: 12px;
    line-height: 13px;
    text-align:right;
`)
