import React, { useState } from 'react'
import { Skeleton, Typography } from '@mui/material'
import IconUser from '../../assets/images/iconUser'
import { USER_TYPE, INTERNAL_USER_TYPE } from '../../constants'
import { MuiImage, BlockingLoader } from '../../components'
import { deleteIcon } from '../../assets/images/icons'
import DeleteConfirm from './deleteConfirm/deleteConfirm'
import UserManagementService from '../../services/userManagement.service'
import { useGlobalStore } from '../../store'

import { UserIconWrap, UserNameWrap, UsersWrap, UserWrap, UserDeleteWrap } from './style'

const Users = ({ users, refetch, setActiveUser, activeUser, filters, isLoading, disabled }) => {
  
  const { user } = useGlobalStore()
  const [isDeleteModalActive, setDeleteModalActive] = useState(false)
  const [deleteModalId, setDeleteModalId] = useState()
  const [isDeleteLoading, setDeleteLoading] = useState(false)

  const deleteUserHandler = async () => {
    try {
      setDeleteLoading(true)
      const resp = await UserManagementService.setUsers({
        user: { UserId: deleteModalId.UserId },
        accountCreatorName: deleteModalId?.UserName,
        delete: true,
      })
      refetch()
      setActiveUser(null)
      if (resp){
        console.log(`SUCCESS : ${deleteModalId.UserName} deleted successfully`)
      }
      else throw new Error('Internal server error')
    } catch (error) {
      const resMessage =
        error?.message ||
        error?.response?.statusText ||
        'Internal server error'
      console.log('ERROR : ', resMessage)
    } finally {
      setDeleteLoading(false)
      setDeleteModalId(null)
      setDeleteModalActive(false)

    }
  }

  const userArray = user.UserType === 'Publisher' ? [...USER_TYPE, ...INTERNAL_USER_TYPE] : USER_TYPE

  return (
    <UsersWrap sx={{opacity: disabled ? 0.8 : 1}} className='UserList'>
      {userArray.map((userType) => {
        if(!userType?.value?.includes(filters.role)) return null;
        const arr = users[userType.value] || [];
        return arr?.map((user, index) => {
          if(!user.UserName.toLowerCase().includes(filters.searchText.toLowerCase())) return null;
         
         return  (
            <User
              user={user}
              key={user?.UserId}
              isActive={(activeUser?.UserId === user.UserId)}
              setActiveUser={disabled ? () => {} : setActiveUser}
              onDeleteClicked={() => {setDeleteModalId(user); setDeleteModalActive(true)}}
              disabled={disabled}
            />
          )
        })
      })}
      {
        isLoading && [0, 1].map(item => <User key={item}  isLoading/>)
      }
      
      {isDeleteLoading && <BlockingLoader />}
      {isDeleteModalActive && (
        <DeleteConfirm
          onClose={() => setDeleteModalActive(false)}
          onConfirm={deleteUserHandler}
        />
      )}
    </UsersWrap>
  )
}

export default Users

const User = ({ user, isActive = false, setActiveUser, isLoading, onDeleteClicked, disabled }) => {
  return (
    <UserWrap className={isActive ? 'active' : ''}>
      <UserIconWrap className='userWrap' onClick={() => {if(!disabled) setActiveUser(user)} }>
        {
          isLoading ? 
          <Skeleton width="78px" height="40px" variant='circular' /> : 
          <IconUser fill={isActive ? '#fff' : '#000'} />
        }
      </UserIconWrap>
      <UserNameWrap onClick={() => {if(!disabled) setActiveUser(user)} }>
        {
          isLoading ?
          <>
            <Skeleton width="80px" variant='text' />
            <Skeleton width="120px" variant='text' />
          </> : 
          <>
           <Typography className='name oneLineEllipsis'>{user?.UserName}</Typography>
           <Typography className='role'>{user?.UserType}</Typography>
          </>
        }
       
      </UserNameWrap>
      <UserDeleteWrap sx={{opacity: disabled ? 0 : 1}} onClick={() => {if(!disabled) onDeleteClicked()}} className='iconButton'>
        {
          isLoading ? <Skeleton width="15px" variant='text' /> : <MuiImage width='14px' src={deleteIcon} />
        }
      </UserDeleteWrap>
    </UserWrap>
  )
}
