import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { ChartBarHorizontalCustom, Legend, LoaderWrapper, ReportHeading, ProgressionWrapper, ProgressionFilters, ReportOuterWrapper } from '../../../../components'
import ReportsService from '../../../../services/reports.service'
import { useUpdateLhs } from '../../../../hooks/useUpdateLhs'
import { getObjectAsArray, exportExcel_addPercentage  } from '../../../../util'
import { LEGEND_COLOR_MAP, GRAPH_COLOR_PALLET2 } from '../../../../constants'
import BuyerTable from './buyerTable'
import { 
  RootWrap, TabSection1, TabSection2, ProgressionFiltersWrapper, BrandHealthSection, HeadingSection, ContentSection, ChartRow, RowName, LegendWrap } from './style'

const BuyerMaturityByAgeProgression = (props) => {
  const [state, setState] = useState([])
  const [loading, setLoading] = useState(false)
  const [reportExcelData, setReportExcelData] = useState()
  const {updateSubLhsApiLoaded} = useUpdateLhs()

	const [progressionDataL1Filtered, setProgressionDataL1Filtered] = useState({drivers:[], periods:[]})
	const [progressionDataFiltered, setProgressionDataFiltered] = useState({drivers:[], periods:[]})
  const [configTab, setConfigTab] = useState([])
	const [progressionData, setProgressionData] = useState()
  const [eventCount, setEventCount] = useState(1)
  const [pageFlag, setPageFlag] = useState(false)

  let scoreImageryTypeArray = {}
  let scoreImageryTypeArrayL2 = {}
  const filterDataObj = {drivers:[], periods:[]};

  const generateBuyerMaturityData = async (resp, hasTabs) => {
    scoreImageryTypeArray = {}
    scoreImageryTypeArrayL2 = {}

    const rowArrayDataFunc = (data, dataObj) => {
      filterDataObj.periods.push(dataObj?.title || '')
      filterDataObj.drivers.push(data.map((d) => (d.PrimarySegmentName)))
      return data
    }

    const configTabObject = hasTabs 
      ? (resp.map((d, i) => {
          return {
            periodInsightsElementId: d.PeriodInsightsElementId,
            id: i,
            label: i===0 ? d.PeriodName : 'Progression',
            tutorial: i===0 ? 'CurrentPeriod' : 'Progression',
            title: d.PeriodName,
            periodOrder: d.PeriodOrder,
            data: getObjectAsArray(d?.SegmentOfSegmentOverviewElements?.SegmentOfSegmentOverviewElement || []) // .map(f => ({ ...f, EntityType: f.Entity.EntityType })).filter(f => f.EntityType !== 'HiddenBrand' )
          }
        }))
      : []

    const rowArrayData = hasTabs
      ? configTabObject.map(d => {
        d.data = rowArrayDataFunc(d.data, d)
        return d
      })
      : [rowArrayDataFunc(resp, null)]

    const rowArray = hasTabs ? rowArrayData[0].data : rowArrayData[0]

    const removeUsingSet = (arr) => {
        let outputArray = Array.from(new Set(arr))
        return outputArray
    }
    const progressionDataL1FilteredData = {drivers:removeUsingSet(filterDataObj.drivers.flat(1)), periods:removeUsingSet(filterDataObj.periods)}
    setProgressionDataL1Filtered(progressionDataL1FilteredData)


    const loadTab1Func = (paramData) => {
      // Original Code start
      setState(paramData);
      const reportExcelDataObj = []        
      for(let i=0; i<paramData.length; i++){
        const segmentSet = Array.isArray(paramData[i]?.SecondarySegmentSet?.SecondarySegment) ? paramData[i].SecondarySegmentSet.SecondarySegment : [paramData[i].SecondarySegmentSet.SecondarySegment]
        for(let j=0; j<segmentSet.length; j++){
          reportExcelDataObj.push([{value: paramData[i].PrimarySegmentName}, {value: segmentSet[j].SegmentName}, exportExcel_addPercentage(segmentSet[j].SegmentValue)])
        }
      }
      setReportExcelData(reportExcelDataObj)
      // Original Code end
    }


    if(hasTabs){
      setConfigTab((configTabObject))
      const finalData = {sentimentSplit:[], sentimentSplitTemp:[], categorySentimentSplit:[], legendData:[]}

      for(let i=0; i<rowArrayData.length; i++){
        const d0 = rowArrayData[i].data;

        for(let j=0; j<d0.length; j++){
          const d1 = d0[j]
          const obj = {
            title: rowArrayData[i].title,
            name: d1.PrimarySegmentName
          }

          const arr = d1.SecondarySegmentSet.SecondarySegment
          for(let k=0; k<arr.length; k++){
            if( !finalData.legendData.includes(arr[k].SegmentName) ){ finalData.legendData.push(arr[k].SegmentName) }
            obj[arr[k].SegmentName.trim().replaceAll(' ','')] = arr[k].SegmentValue
          }

          finalData.sentimentSplitTemp.push(obj)
        } 
      }

      finalData.legendData = finalData.legendData.map((d,i) => ({label:d, value:i}))


      for(let m=0; m<progressionDataL1FilteredData.drivers.length; m++){
        const m1 = progressionDataL1FilteredData.drivers[m];
        const m2 = finalData.sentimentSplitTemp.filter(f => f.name === m1)
        finalData.sentimentSplit.push(m2)
      }
      
      loadTab1Func(rowArrayData[0].data)

      setProgressionData(finalData)
    } else {
      loadTab1Func(resp)
    }

    setLoading(false)
    setEventCount(p => p+1)
    setTimeout(() => {
      updateSubLhsApiLoaded(props)
    }, 2000)
  }

  const loadBuyerMaturityGraph = async () => {
    setPageFlag(true)
    setLoading(true)

    const hasTabs = props.InsightsElementType === 'SegmentOfSegmentOverviewAndProgression'
    const payload = { insightsElementId: props.InsightsElementId }

    await (hasTabs ? ReportsService.getSegmentOfSegmentProgressionElement(payload) : ReportsService.getSegmentOfSegmentOverviewElement(payload))
    .then(resp => {
        generateBuyerMaturityData(resp, hasTabs)
      })
      .catch(error => {
        const resMessage = error
        console.log('ERROR : ', resMessage)
        setLoading(false)
        setPageFlag(false)
      }).finally(() => {
        setLoading(false)
        setPageFlag(false)
      })
  }

  const filterChangeFunc = (data) => {
		setProgressionDataFiltered(data)
    setEventCount(p => p+1)
	}

  useEffect(() => {
    if (!pageFlag) {
      loadBuyerMaturityGraph()
    }
  }, [])

  return (
    <>
      {reportExcelData && <ReportHeading resetInsightHeight={eventCount} downloadId={props.type} {...props} downloadIdData={reportExcelData} />}
      <ReportOuterWrapper>
        <RootWrap id={props.type}>
          <LoaderWrapper loading={loading} loadOnlyApi={props.loadOnlyApi}>
            <ProgressionWrapper configTab={configTab} onChange={() => setEventCount(p => p+1)} defaultTab={(props?.isProgressionActive && props.InsightsElementType === 'SegmentOfSegmentOverviewAndProgression') ? 1 : 0}>
              <TabSection1 hasTabs={props.InsightsElementType === 'SegmentOfSegmentOverviewAndProgression'}>
                <BuyerTable isExtraColor={props.isExtraColor} data={state} />
              </TabSection1>
              <TabSection2>
                <ProgressionFiltersWrapper>
                  {state?.length > 0 && <ProgressionFilters data={progressionDataL1Filtered} onChange={filterChangeFunc} />}
                </ProgressionFiltersWrapper>
                {progressionData?.sentimentSplit.map((d, i) => {
                  if( !progressionDataFiltered?.drivers?.includes(d[0].name) ){ return false }
                  return (
                    <BrandHealthSections key={i} heading={d[0].name} className='segmentOfSegmentOverviewRowProgression'>
                      {d.map((g, i) => {
                        if( !progressionDataFiltered?.periods?.includes(g.title) ){ return false }
                        const keys = Object.keys(g).filter(f => !['title', 'name'].includes(f) )
                        const dataObj = {}
                        for (let i=0; i<keys.length; i++) {
                          dataObj[keys[i]] = g[keys[i]]
                        }
                        return (
                          <Box key={'graph1_'+i}>
                            <ChartRow className='graphRow'>
                              <RowName>{g?.title}</RowName>
                              <ChartBarHorizontalCustom
                                maxWidth='90%'
                                valueInPercent
                                data={[dataObj]}
                                maxPercentRatio={1}
                                colorIndex={LEGEND_COLOR_MAP}
                              />
                            </ChartRow>
                          </Box>
                        )
                      })}
                    </BrandHealthSections>
                  )
                })}
                {progressionData?.legendData && <LegendWrap className='legend'>
                  <Legend legends={progressionData?.legendData} colorPallet={GRAPH_COLOR_PALLET2} />
                </LegendWrap>}

              </TabSection2>
            </ProgressionWrapper>
          </LoaderWrapper>
        </RootWrap>
      </ReportOuterWrapper>
    </>
  )
}

export default BuyerMaturityByAgeProgression

const BrandHealthSections = ({heading, className, children}) => {
  return (
    <BrandHealthSection className={className}>
      <HeadingSection className='heading'>{heading}</HeadingSection>
      <ContentSection>{children}</ContentSection>
    </BrandHealthSection>
  )
}
