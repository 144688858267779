import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { ReportHeading, MuiButton, Loader } from '../../../../components'
import ReportsService from '../../../../services/reports.service'
import { useUpdateLhs } from '../../../../hooks/useUpdateLhs'
import { useGlobalStore } from '../../../../store'
import ReportEditKeyTakeaways from './reportEditKeyTakeaways'
import decode from 'unescape'
import {ROLES_MM_PRIMARY} from '../../../../constants'
import { OuterWrapper, InnerWrapper, ContentWrapper } from './style'
import { keyTakeAway } from './__mock'

const ReportKeyTakeaways = (props) => {
    // const { enqueueSnackbar } = useSnackbar()
    const [backgroundTabsLoading, setBackgroundTabsLoading] = useState(true)
    const [pageData, setPageData] = useState()
    const { id } = useParams()
    const {user} = useGlobalStore()
    const {updateSubLhsApiLoaded} = useUpdateLhs()

    const loadKeyTakeawaysObjective = (getLatest = false) => {
        setBackgroundTabsLoading(true)
        ReportsService.reportGetKeyTakeawaysObjectiveApi({ insightsElementId: props.InsightsElementId }, getLatest)
            .then((resp) => {
                // setPageData( decode(resp) )
                setPageData(`<ul>${keyTakeAway}</ul>`)
                // setPageData( resp )
                setBackgroundTabsLoading(false)
                updateSubLhsApiLoaded(props)
            })
            .catch((error) => {
                const resMessage = error
                console.log('ERROR : ', resMessage)
        // enqueueSnackbar(resMessage, { variant: 'error' })
                setBackgroundTabsLoading(false)
            })
    }

    const onSuccessEdit = () => {
        loadKeyTakeawaysObjective(true)
    }

    const KeyTakeawaysEditButton = () => {
        return (
            <>{ROLES_MM_PRIMARY.includes(user?.UserType) && !backgroundTabsLoading && <ReportEditKeyTakeaways InsightsElementId={props.InsightsElementId} pageData={pageData || ''} onSuccess={onSuccessEdit} />}</>
        )
    }

    useEffect(() => {
        if (!pageData) {
            loadKeyTakeawaysObjective()
        }
    }, [])

    return (
        <>
            <ReportHeading downloadId={props.type} subHeading='' {...props} additionalButton={<KeyTakeawaysEditButton />} />
            {backgroundTabsLoading && <Loader />}
            {!backgroundTabsLoading && <OuterWrapper id={props.type}>
                <InnerWrapper>
                    <ContentWrapper dangerouslySetInnerHTML={{ __html: pageData }} />
                </InnerWrapper>
            </OuterWrapper>}
        </>
    )
}

export default ReportKeyTakeaways
