import React from 'react'

const IconCopyButton = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="16" viewBox="0 0 12 16" fill="none">
        <path d="M3.375 0.500034C2.75824 0.500034 2.25 0.962066 2.25 1.52276V3.2273H1.125C0.508237 3.2273 0 3.68933 0 4.25003V14.4773C0 15.038 0.508237 15.5 1.125 15.5H8.625C9.24176 15.5 9.75 15.038 9.75 14.4773V12.7727H10.875C11.4918 12.7727 12 12.3107 12 11.75V3.90912C11.9992 3.81898 11.9592 3.7328 11.8887 3.66942L8.51367 0.601241C8.44395 0.537145 8.34915 0.500756 8.25 0.500034H4.875C4.82531 0.499396 4.77597 0.507743 4.72986 0.524589C4.68375 0.541435 4.64178 0.566444 4.60639 0.598164C4.571 0.629883 4.5429 0.66768 4.52372 0.709358C4.50454 0.751036 4.49467 0.795764 4.49467 0.840942C4.49467 0.886121 4.50454 0.930848 4.52372 0.972526C4.5429 1.0142 4.571 1.052 4.60639 1.08372C4.64178 1.11544 4.68375 1.14045 4.72986 1.1573C4.77597 1.17414 4.82531 1.18249 4.875 1.18185H7.875V3.90912C7.87501 3.99953 7.91452 4.08624 7.98485 4.15017C8.05517 4.2141 8.15055 4.25002 8.25 4.25003H11.25V11.75C11.25 11.9448 11.0892 12.0909 10.875 12.0909H3.375C3.16076 12.0909 3 11.9448 3 11.75V1.52276C3 1.328 3.16076 1.18185 3.375 1.18185C3.42469 1.18249 3.47403 1.17414 3.52014 1.1573C3.56625 1.14045 3.60822 1.11544 3.64361 1.08372C3.679 1.052 3.7071 1.0142 3.72628 0.972526C3.74546 0.930848 3.75533 0.886121 3.75533 0.840942C3.75533 0.795764 3.74546 0.751036 3.72628 0.709358C3.7071 0.66768 3.679 0.629883 3.64361 0.598164C3.60822 0.566444 3.56625 0.541435 3.52014 0.524589C3.47403 0.507743 3.42469 0.499396 3.375 0.500034ZM8.625 1.66125L10.7227 3.56821H8.625V1.66125ZM1.125 3.90912H2.25V11.75C2.25 12.3107 2.75824 12.7727 3.375 12.7727H9V14.4773C9 14.672 8.83924 14.8182 8.625 14.8182H1.125C0.910763 14.8182 0.75 14.672 0.75 14.4773V4.25003C0.75 4.05527 0.910763 3.90912 1.125 3.90912Z" fill="black"/>
    </svg>
  )
}

export default IconCopyButton