import styled from "@emotion/styled";
import { Box } from "@mui/material";


export const PaginationWrap = styled(Box)(`
    margin-top: auto;
    position: absolute;
    bottom: 10px;
    top: unset;
    right: 50px;
    & button {
        border-radius: 4px;
        border: 1px solid #F1F1F1;
    }
`);