import { styled } from '@mui/material/styles'
import { Box, Typography, Menu, Paper, IconButton } from '@mui/material'
import { reportAuraBackground } from '../../../../assets/images'
import { LEGEND_TEXT_COLOR_MAP } from '../../../../constants'
import { exportToPPT_tableCss } from '../../../../util'
import { MEDIA } from '../../../../constants'

export const Wrapper = styled(Box)(`
    // padding:20px 30px;
    &.exportToPPT {padding:0; display:flex; align-items:center; flex-direction:column; max-width:1200px; position:relative; }
    &.exportToPPT:before {position:absolute; top:0; right:-2px; width:6px; height:100%; background:#fff; z-index:1; display:block; content:'';} // TEMP HACK ONLY FOR EXPORT TO PPT SCENARIO
    &.exportToPPT > div:first-of-type{min-width:1200px; margin-top:-35px;}
    &.exportToPPT > div:last-child{width:100%;}
    &.exportToPPT > div:last-child > div{padding:2px 0;}
    &.exportToPPT > div, &.exportToPPT > div > div{max-height:530px;}
    &.exportToPPT .logoWrapper {margin-top:20px;}
    &.exportToPPT .graphDesc{position:relative; padding-right:10px;}
    
    ${ exportToPPT_tableCss }
    @media (max-width: ${MEDIA.lg}) {
        padding: 20px;
    }
`)

export const OuterWrapper = styled(Box)(`
    background:#fff url(${reportAuraBackground}) no-repeat calc(100% - 50% - 10px) 45px;
    width:100%;
    height:600px;
    min-width:950px;
`)

export const InnerWrapper = styled(Box)(`
    display:flex; flex-direction: column;
    height:570px; 
    width:100%; 
    padding-top: 30px;
    position: relative;
    max-width:calc(100% - 10px);

    &.AuraWithoutSentiments {
        span:before {display: none;}
        .box1 * , .box1 {color: #0A286E !important;}
        .box2 * , .box2 {color: #143C98 !important;}
        .box3 * , .box3 {color: #2756C3 !important;}
        .box4 * , .box4 {color: #4275E9 !important;}

        div lhs span,
        div rhs span { color:red; }
    }
`)

export const Row = styled(Box)(`
    width:100%; display:flex; justify-content: space-between; font-family: 'Poppins'; font-style: normal; letter-spacing: -0.01em; text-transform: lowercase; line-height: 1;
    font-weight:300 !important;
    
    & .Negative{color:${LEGEND_TEXT_COLOR_MAP.Negative}; }
    & .Negative:before{content:'-'}

    & .Positive{color:${LEGEND_TEXT_COLOR_MAP.Positive}; }
    & .Positive:before{content:'+'}

    & .Mixed{color:${LEGEND_TEXT_COLOR_MAP.MixedNegative}; }
    & .Mixed:before{content:'~'}

    & span:hover {
        filter: brightness(0.7);
        cursor:pointer;
    }
`)

export const Lhs = styled(Box)(`
    width:50%; text-align:right;
    & span{margin-left:5px;}
`)

export const Rhs = styled(Box)(`
    width:50%; text-align:left;
    & span{margin-right:5px;}
`)

export const Logo = styled(Box)(`
    position: absolute;
    top:calc((50% - 189px) + 11px);
    left:calc(50% - 189px);
    width:370px;
    height:370px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    & img {
        width:auto;
        max-height:200px;
        max-width:270px;
    }
`)


export const GraphDescription = styled(Box)(`
    // width:100%;
    // min-height:20px;
    // display:flex;
    // background:#f1f1f1;
    // border:0 !important;
    // & * {
    //     border:0 !important;
    //     margin:0 !important;
    //     padding:0 !important;
    // }
    p {
        font-size:12px;
        font-weight:300;
    }
`)

export const GraphLegendWrapper = styled(Box)(`
    display:flex;
    padding:10px 0;
`)

export const GraphLegend = styled(Typography)(props => `
    font-weight: 300;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: -0.01em;
    text-transform: capitalize;
    color: #000;
    margin-right:10px;
    display: flex;
    align-items: baseline;

    &:before{
        content:'';
        background:${props.color};
        width:8px;
        height:8px;
        display:block;
        margin-right:5px;
    }
`)

export const MenuWrapper = styled(Menu)(`
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.15);
    padding:40px 0 20px;
    z-index:5;
    border: 1px blue solid;
`)

export const MenuWrapper_ = styled(Paper)(`
    background: #F4F8FF;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.15);
    position:fixed;
    padding:40px 0 20px;
`)

export const MenuContent = styled(Box)(`
    padding:10px 10px 0 10px;
    font-style:italic;
    overflow:static;
    // width: 550px;
    width: 420px;
`)

export const MenuClose = styled(IconButton)(`
    position: absolute;
    right: 1%;
    top: 0%;
`)

export const MenuTitle = styled(Typography)(`
    font-weight: 500;
    font-size: 18px;
    font-style:normal;
    line-height: 27px;
    color: #000000;

    &.Negative{color:${LEGEND_TEXT_COLOR_MAP.Negative}; }
    &.Negative:before{content:'-'}

    &.Positive{color:${LEGEND_TEXT_COLOR_MAP.Positive}; }
    &.Positive:before{content:'+'}

    &.Mixed{color:${LEGEND_TEXT_COLOR_MAP.MixedNegative}; }
    &.Mixed:before{content:'~'}

    
    &.AuraWithoutSentiments:before{display:none;}

`)
