import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { Prompt } from 'react-router-dom'
import { useGlobalStore } from '../../store';


const LeavePageBlocker = ({ when, cb }) => {
  const message = "Page has unsaved changes";
  const {user} = useGlobalStore();
  useEffect(() => {
    if (!when) return () => {}

    const beforeUnloadCallback = (event) => {
      event.preventDefault()
      event.returnValue = message;
      return message
    }

    window.addEventListener('beforeunload', beforeUnloadCallback)
    return () => {
      window.removeEventListener('beforeunload', beforeUnloadCallback);
      if(cb) {
        cb({
          clientName: user.UserName,
          clientEmail: user.UserEmail,
          clientPhoneNumber: user.phoneNumber,
        })
      }
    }
  }, [when, message])

  return <Prompt when={when} message={message} />
}

LeavePageBlocker.propTypes = {
  when: PropTypes.bool.isRequired,
}

export default LeavePageBlocker