import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import { exportToPPT_tableCss } from '../../../../util'

const distance = '111'

export const RootWrap = styled(Box)(`
	width: 100%;
	// padding: 20px 30px 50px;
	&.exportToPPT {padding:0;}
	&.exportToPPT > div{box-shadow:none}
	&.exportToPPT .list{padding:0 20px;}
	${ exportToPPT_tableCss }
`)

export const ContentWrap = styled(Box)(`
	background: #fff;
	border: 1px #EEE solid;
	// box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
	margin-left: ${distance}px;
	border-radius: 2px;
	width: calc(100% - ${distance}px);
`)

export const HeaderWrap = styled(Box)(`
	display: flex;
	align-items: center;
	width: 100%;
`)

export const HeaderTab = styled(Box)(`
    display: flex;
	align-items: center;
	background: #F7F7F9;
	padding: 6px 10px;
	overflow: hidden;
	width: 100%;
	& .tab {
		padding: 5px 10px;
		font-size: 12px;
		font-weight: 300;
		margin-right: 2px;
		cursor: pointer;
		&.active {
			background: #fff;
			font-weight: 500;
		}
	}
`)

export const SegmentWrap = styled(Box)(`
	width: 100%;
`)

export const SegmentRow = styled(Box)(`
	font-size: 14px;
	font-weight: 300;
	margin-bottom: 5px;
	border: 1px solid #EEEEEE;
	border-left: 0;
	border-radius: 0 2px 2px 0;
	padding: 15px 30px;
	position: relative;
	min-height: 60px;
	display:flex;
	flex-direction: column;
	justify-content: center;
	&:before {
		content: attr(data-age);
		position: absolute;
		left: -${distance}px;
		width: ${distance}px;
		height: calc(100% + 2px);
		display: flex;
		align-items: center;
		border: 1px solid #EEEEEE;
		border-right: 0;
		padding: 16px;
		top: -1px;
		border-radius: 2px 0px 0px 2px;
	}
	& .outerText{
		font-size: 12px;
		margin-left: 6px;
		font-weight: 300;
	}
`)

export const SegmentRowContent = styled(Box)(`
	display: flex;
	align-items: center;
	margin: 4px 0;
	position: relative;
`);

export const BarWrap = styled(Box)(`
	width: 100%;
	& .barRoot, .barItemRoot, .barSliceWrap, .barSlice {
		height: 30px !important;
	}
	& .barSlicePercentage{
		font-weight: 300;
		font-size: 14px;
	}
`);

export const IssuesWrap = styled(Box)(`
	position: absolute;
	left: calc(100% + 5px);
	display: flex;
	align-items: center;
	& .radio {
		width: 10px;
		height: 10px;
		min-width: 10px;
		min-height: 10px;
		border: 1px solid #7E7E7E;
		margin-right: 4px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		& .box {
			width: 4.5px;
			height: 4.5px;
			min-width: 4.5px;
			min-height: 4.5px;
			background: #7E7E7E;
			border-radius: 50%;
		}
	}
	& .radio-text {
		font-size: 12px;
		font-weight: 300;
		white-space: nowrap;
	}
`);

export const LegendWrap = styled(Box)(`
	width: 100%;
	display: flex;
	justify-content: end;
	padding: 30px;
	padding-top: 25px;
`);

export const GraphWrapper = styled(Box)(`
	display:flex;
	background: #FFFFFF;
	// box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
	border: 1px #EEE solid;
`);

export const Graph1 = styled(Box)(`
	display:flex;
	min-height:230px;
	min-width:230px;
	justify-content:center;
	align-items:center;
	width:calc(25% - 1px);
	background: #F7F7F9;
	border-right:1px #fff solid;
`);

export const Graph2 = styled(Box)(`
	display:flex;
	flex-direction:column;
	padding:20px 40px;
	width:50%;
`);

export const Graph2InnerWrap = styled(Box)(`
	padding:7px 0;
`);

export const BarGraphTitle = styled(Typography)(`
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
	color: #000000;
	padding-bottom: 3px;
`);

export const LegendWrapper = styled(Box)(`
	padding:10px 0;
	display:flex;
	justify-content:end;
`);

