import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import { exportToPPT_tableCss } from '../../../../util'
import { MEDIA } from "../../../../constants";

const triangulationWrapperStyling = `
	&.triangulationWrapper { padding:0 30px 50px; }
	&.triangulationWrapper .segmentWrapper { margin:0; width:100%;}
	&.triangulationWrapper .segmentWrap { padding-bottom:20px; }
	&.triangulationWrapper .segmentRows { border:0; position:relative; margin:0; }
	&.triangulationWrapper .segmentRows > div {margin:0}
	&.triangulationWrapper .segmentRows > div > p:first-of-type {
		width:220px; height:20px; border-right: 0px #E4E7EC solid; padding-right: 10px; margin-right: 0; line-height:20px;
	}
	&.triangulationWrapper .segmentRows > div > div { width:calc(100% - 220px); }
	&.triangulationWrapper .segmentRows .attributes {display:none;}
	&.triangulationWrapper .segmentRows::before {
		margin:10px 0 0 220px; position:relative; top:0; left:0; border:0; width:calc(100% - 240px); border-left:0px #E4E7EC solid;
		font-weight:500; font-size:14px; color:#000000; border-radius:0; height:25px; padding:0 0 0 10px;
	}
	&.triangulationWrapper .segment-row { width:100% !important; border: 0; border-bottom: 1px solid #EEEEEE; padding-top: 30px; padding-bottom: 30px; margin: 0 !important; }
	&.triangulationWrapper .segment-row .segment-title { margin-left:10px; padding-bottom:10px;}
	&.triangulationWrapper .segment-row-content { margin:0; padding:0;  }
	&.triangulationWrapper .segment-row-content .segment-title { width:130px; padding-bottom:0; margin-left:0; margin-right:18px; line-height:13px !important; }
	&.triangulationWrapper .segment-row::before { display:none; }
	&.triangulationWrapper .segmentRowsPipe { width:1px; height:calc(100% - 45px); background:#E4E7EC; position:absolute; top:30px; left:240px; z-index:1; }
	&.triangulationWrapper .segmentRowsPipe:before { content:''; width:9px; height:9px; display:block; background:#E4E7EC; border-radius:5px; margin:-2px 0 0 -4px;}

	&.TriangulationComparisonViewOnly .segmentWrapper{box-shadow:none; background: transparent;}
	&.TriangulationComparisonViewOnly .tabsWrapper{display:none;}
	
	// @media (max-width: ${MEDIA.lg}) {
	// 	&.triangulationWrapper .segmentWrap { width:90%; }
	// }
	// @media (max-width: 1000px) {
	// 	&.triangulationWrapper .segmentWrap { width:100%; }
	// }
`

export const RootWrap = styled(Box)(`
	width: 100%;
	padding: 0 0 0 0;

	${triangulationWrapperStyling}

	&.exportToPPT {padding:0; width:1200px;}
	&.exportToPPT > div {margin:0; }
	&.exportToPPT .tabsWrapper {display:none;}
	&.exportToPPT .segmentWrap * {font-size:14px !important;}
	&.exportToPPT .segmentWrap {width: 100% !important;}
	&.exportToPPT .segmentWrap .segmentTitle { width:170px; }
	&.exportToPPT .segmentWrap > div {border:0; width: 100% !important; padding-right: 40%;}
	&.exportToPPT .segmentWrap > div:before {position:relative; left:auto; padding:0 0 10px 0; top:auto; border:0; font-weight:bold; width:auto;}
	// &.exportToPPT .segmentWrap > div p.MuiTypography-root {min-width: 140px !important;}
	&.exportToPPT .segmentWrap .segmentTitle {min-width: 140px !important;}
	&.exportToPPT .legendRoot { justify-content:end; }
	&.exportToPPT .segmentRow { margin-bottom:0; padding-bottom:0; }
	
	&.exportToPPT.triangulationWrapper .segmentRowsPipe { left:240px; height:calc(100% - 35px); top:20px;}
	&.exportToPPT.triangulationWrapper .segmentRows::before { margin:0 0 0 232px; padding-bottom:0; }
	${ exportToPPT_tableCss }

	// @media (max-width: ${MEDIA.lg}) {
	// 	padding:20px 20px 40px 20px;
	// }
`)

export const ContentWrap = styled(Box)(`
	background: #fff;
	// box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
	border: 1px #EEE solid;
	margin-left: 141px;
	border-radius: 2px;
	width: calc(100% - 141px);
`)

export const HeaderWrap = styled(Box)(`
	display: flex;
	align-items: center;
	width: 100%;
`)

export const HeaderTab = styled(Box)(`
    display: flex;
	align-items: center;
	background: #F7F7F9;
	padding: 6px 10px;
	// overflow: hidden; // showing error when we click on Global Tutorial Button
	width: 100%;
	& .tab {
		padding: 5px 10px;
		font-size: 14px;
		font-weight: 300;
		margin-right: 2px;
		cursor: pointer;
		position:relative;

		&.active {
			background: #fff;
			font-weight: 500;
		}
	}
`)

export const SegmentWrap = styled(Box)(`
	width: 65%;	

	& > .segmentRows:last-child {
		margin-bottom:-1px;
	}

    // @media (max-width: ${MEDIA.lg}) {
    //     width:calc(100% - 250px);
    // }
`)

export const SegmentRow = styled(Box)(`
	font-size: 12px;
	font-weight: 300;
	margin-bottom: 5px;
	border: 1px solid #EEEEEE;
	border-left: 0;
	border-radius: 0 2px 2px 0;
	padding: 6px 10px 6px 0px;
	position: relative;
	// min-height: 60px;
	display:flex;
	flex-direction: column;
	justify-content: center;
	&:before {
		content: attr(data-segment);
		position: absolute;
		left: -141px;
		width: 141px;
		height: calc(100% + 2px);
		display: flex;
		align-items: center;
		border: 1px solid #EEEEEE;
		border-right: 0;
		padding: 5px;
		top: -1px;
		line-height: 13px;
		border-radius: 2px 0px 0px 2px;
	}
	& .outerText{
		min-width: 32px;
		font-size: 12px;
		margin-left: 6px;
		font-weight: 300;
	}
`)

export const SegmentRowContent = styled(Box)(`
	display: flex;
	align-items: center;
	margin: 3px 0;
	position: relative;
`);

export const SegmentTitle = styled(Typography)(`
	font-size: 12px;
	font-weight: 300;
	width: 65px;
	min-width: 130px;
	text-align: right;
	margin-right: 10px;
	line-height: 12px;
`)

export const SegmentSubTitle = styled(Typography)(`
	width: auto;
	min-width: 100px;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	margin: 0 0 0 10px;
`)

export const BarWrap = styled(Box)(`
	width: 100%;
	&.association {
		background: #F1F1F3;
		border-radius: 0px 20px 20px 0px;
		padding: 4px;
		& .barSlice {
			border-radius: 0px 20px 20px 0px;
			height: 8px !important;
		}
	}
	& .barRoot, .barItemRoot, .barSliceWrap, .barSlice {
		height: 5px !important;
	}
	& .barSlicePercentage, & .barSliceLabel{
		font-size: 12px !important;
	}
`);

export const IssuesWrap = styled(Box)(`
	position: absolute;
	left: calc(100% + 5px);
	display: flex;
	align-items: center;

	& .radio {
		width: 10px;
		height: 10px;
		min-width: 10px;
		min-height: 10px;
		border: 1px solid #7E7E7E;
		margin-right: 4px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		& .box {
			width: 4.5px;
			height: 4.5px;
			min-width: 4.5px;
			min-height: 4.5px;
			background: #7E7E7E;
			border-radius: 50%;
		}
	}
	& .radio-text {
		font-size: 12px;
		font-weight: 300;
		white-space: nowrap;
	}
	
	@media (max-width: ${MEDIA.lg}) {
		overflow: hidden;
		width: 250px;
		flex-wrap: wrap;
		height: 20px;
	}
`);


export const ComparisonViewWrap = styled(Box)(`
	& .segment-row {
		// width: 65% !important;	
		@media (max-width: ${MEDIA.lg}) {
			// width:calc(100% - 250px) !important;
		}
	}
	& .segment-row:last-child {
		margin-bottom:-1px !important;
	}
	& .segment-row-content {
		margin-left: -140px;
		padding-right: 40px;
		& .segment-title {
			margin-right: 20px;
		}
		& .barRoot, .barItemRoot, .barSliceWrap, .barSlice {
			height: 20px !important;
		}
	}
`);