import { styled } from '@mui/material/styles'
import { Box, Typography } from '@mui/material'
import {MuiButton} from '../../../../../components'
import { ulLiCss } from '../../../../../util'

export const Wrapper = styled(Box)(`
  width:50vw;
  min-width:370px;
`)

export const LoginButton = styled(MuiButton)(`
  margin-top:30px;
`)

export const Title = styled(Typography)(`
  margin-top: -10px;
  padding-bottom: 30px;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
`)

export const RteWrapper = styled(Box)(`
  & * {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    text-transform: capitalize;
    color: #000000;
  }
`)

export const RtePreviewCss = `
  #tinymce { ${ ulLiCss } }
`
