import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import { exportToPPT_tableCss } from '../../../../util'
import { MEDIA } from "../../../../constants";

export const RootWrap = styled(Box)(`
	width: 100%;
	// padding: 20px 30px 50px;
	// padding: 0 15px 0 0;
    // border: 1px #EEE solid;

    &.exportToPPT .barWrapper {max-width:100%; overflow:static;}
    ${ exportToPPT_tableCss }
    
    @media (max-width: ${MEDIA.lg}) {
        padding:20px 20px 40px 20px;
    }
`)

export const MultiBarContainer = styled(Box)(`
    display: flex;
    width: 100%;
    overflow: auto;
    max-width:calc(100vw - 450px);
    & .brandBar {
        max-width:140px;
        min-width:140px;
    }
    // @media (max-width: ${MEDIA.xl}) {
    //     max-width:100%;
    // }
`)

export const BrandBarWrap = styled(Box)(`
    padding: 10px 10px 0 10px;
    border: 1px solid #F1F1F3;
    height: 552px;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 35px;
    margin-right: 14px;
`);

export const SegmentWrap = styled(Box)(`
    padding: 12px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content:center;
    margin-bottom: auto;
    background: #F1F1F3;
`)

export const SegmentText = styled(Typography)(`
    font-size: 14px;
    font-weight: 500;
    text-align:center;
    line-height: 16px;
    display: flex;
    align-items: center;
`)

