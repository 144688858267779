import React, { useEffect } from 'react'
import { 
    OuterWrapper
} from './style'

const TypingEffect = ({children, onChange, typingStatus, typingId, interval}) => {

  const generateTypingEffect = (param) => {
    const baseId = document.querySelector('#AskIaWrapper .answerWrap:last-of-type')

    if(baseId?.getElementsByClassName('typing')?.length === 0){
      var textPending = baseId.getElementsByClassName('typing_pending');
      if(textPending.length > 0){
        textPending[0].classList.add("typing");
        textPending[0].classList.remove("typing_pending");
      }
    }

    if(baseId?.getElementsByClassName('typing')?.length > 0){
      var text = baseId.getElementsByClassName('typing');
      var res = text[0].innerText.split("");
      text[0].innerHTML = "<span></span><div class='cursor'></div>";
      var cursor = text[0].querySelector('div');

      var i = 0;
      var strLength = res.length;
      if (i < strLength) {
        onChange('typingStarted')
      }

      interval.current = setInterval(() => {
        var string = res[i];

        if (i >= strLength) {
          if(text?.[0]){
            text[0].classList.remove("typing");
          }
          cursor.classList.remove("cursor");
          clearInterval(interval.current)
          generateTypingEffect()

        } else {
          // TYPING EFFECT
          if(text?.[0]){
            text[0].querySelector('span').innerHTML += string;
            i++;
          }

          if(i % 100 === 0){
            // const wrap = document.querySelector('#AskIaWrapper')
            // if(wrap) wrap.scrollTo(0, wrap.scrollHeight);
            document.getElementById('AskIaWrapper').scrollIntoView(false);
          }

        }
      }, 20);

    } else {
      document.getElementById('AskIaWrapper').scrollIntoView(false);
      onChange('typingDone')
    }
    document.getElementById('AskIaWrapper').scrollIntoView(false);
  }

  useEffect( () => {
    generateTypingEffect()
  }, [])

  useEffect( () => {
    if(typingStatus === 'typingStop'){
      clearInterval(interval.current)
      // const baseId = document.querySelector('#AskIaWrapper .answerWrap:last-of-type')
      // let textPending1 = baseId.querySelectorAll('.typing_pending')
      // for(let i=0; i<textPending1.length; i++){
      //   if(textPending1?.[i]){
      //     textPending1[i].innerHTML = "";
      //     textPending1[i].remove();
      //   } else if(textPending1?.[0]){
      //     textPending1[0].remove();
      //   }
      // }

      // let textPending = baseId.querySelectorAll('p');
      // for(let i=0; i<textPending.length; i++){
      //     textPending[0].classList.remove("typing");
      // }
      
      // const wrap = document.querySelector('#AskIaWrapper')
      // if(wrap) wrap.scrollTo(0, wrap.scrollHeight);
      document.getElementById('AskIaWrapper').scrollIntoView(false);
      onChange('typingDone')
    }
  }, [typingStatus])

  return (
    <OuterWrapper>
      {children}
    </OuterWrapper>
  )
}

export default TypingEffect

// https://codepen.io/stephaniehobson/pen/xWyqeY
