import { Grid } from '@mui/material'
import React from 'react'
import { CustomTab, MuiFormLabel, MuiTextField } from '../../../../components'
import { RootWrap } from './style'

const tabs = [
  {
    id: 'NoCompetitors',
    label: 'No Competitors',
  },
  {
    id: 'IdentifyCompetitors',
    label: 'Identify top 5 competitors',
  },
  {
    id: 'ListCompetitors',
    label: 'List Competitors',
  },
]

const campanies = ['', '', '', '', '']

const Competitors = ({ onChange, companyListChange, value = 'NoCompetitors', companyList=[] }) => {

  const companyChangeHandler = (index, value) => {
    companyList[index] = value
    companyListChange(companyList)
  }

  return (
    <RootWrap>
      <MuiFormLabel>Competitors</MuiFormLabel>
      <CustomTab tabs={tabs} value={value} onChange={(tab) => onChange(tab.id)}/>
       {
        value === 'ListCompetitors' &&
          <Grid container spacing={1} mt mb>
          {
            campanies.map((company, index) => {
              const value = companyList[index] || company
              return (
                <Grid item key={index}>
                  <MuiTextField shadow compact name={`company${value}`} value={value} onChange={(e) => companyChangeHandler(index, e.target.value)} placeholder={`Competitor Name ${index+1}`}/>
                </Grid>
              )
            })
          }
        </Grid>
       }
    </RootWrap>
  )
}

export default Competitors
