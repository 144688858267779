import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import { imageryBg, imageryBgPlain } from '../../../../assets/images'
import { MAGNET_SCORE_IMAGE } from '../../../../constants'
import { exportToPPT_tableCss } from '../../../../util'
import { MEDIA } from '../../../../constants'

const RIGHT_CONTENT = "242px"
const SEARCH_ICON = "50px"

export const OuterWrapper = styled(Box)(`
    width: 100%;
    padding: 20px 30px 50px 30px;

    &.showExpandAllCTA_false {
        .brandImageryRow.header{
            width: calc(100% - ${RIGHT_CONTENT});
            margin-right:0;
        }
        .brandImageryRow.footer{
            width: calc(100% - ${RIGHT_CONTENT});
            margin-right:0;
        }
        .GraphScaleWrapper > div {
            background-size:calc(100% - ${RIGHT_CONTENT});
        }
        .GraphScaleWrapper .brandImageryRow .graphRowDetails {
            display:none;
        }
        .GraphScaleWrapper .brandImageryRow .list {
            width: calc(100% - ${RIGHT_CONTENT});
        }
    }

    &.exportToPPT {padding:0;}
    &.exportToPPT .list {min-height:65px;}

    &.exportToPPT.brandImageryOuterWrapper { display: flex; flex-direction: column; min-width:1200px; max-width:1200px; }
    &.exportToPPT.brandImageryOuterWrapper { 
        .brandImageryRowAppendices *, .brandImageryRow * {font-size: 15px !important; font-weight:500;}
        .brandImageryRow .contentText { padding-right: 12px; }
        // .brandImageryRow .contentText * { font-size:13px !important; }
        .brandImageryRow .contentText button { display:none !important;}
        .brandImageryRow .graphRowDetails { display:none !important;}
        .brandImageryRow.header{ width: calc(100% - ${RIGHT_CONTENT}); margin-left: ${RIGHT_CONTENT}; margin-right: 0;}
        .brandImageryRow.footer{ width: 100%; padding-left: ${RIGHT_CONTENT} !important; margin: 0; border-top:2px #f5f5f5 solid;}
        .list{
            background: linear-gradient(to right, #fbd6d3 0%,#fbd6d3 33.33%,#dddddd 33.34%,#dddddd 66.66%,#e4fedd 66.67%,#e4fedd 100%) !important;
            width: calc(100% - ${RIGHT_CONTENT});
        }
        .contentText .pptSubHeading { display:none !important; }
    }
    &.exportToPPTAppendices .brandImageryRow.details { display:block !important; }
    &.exportToPPTAppendices .insightsWrapper { padding-bottom:10px; }
    &.exportToPPTAppendices .brandImageryRowAppendices.brandImageryRow.tRow { padding-top:8px; }
    &.exportToPPTAppendices .footer { padding:0 !important; }
    &.exportToPPTAppendices .footer span { font-size: 13px; }
    ${ exportToPPT_tableCss }

    @media (max-width: ${MEDIA.lg}) {
        padding:20px 20px 40px 20px;
    }
`)

export const GraphOuterWrapper = styled(Box)(`
    display: flex;
    flex-direction: column;
    width: 100%;
    // width: calc(100% - 30px);
    position: relative;
    align-items: end;
`)

export const MuiButtonWrap = styled(Box)(`
    position:relative;
`)

export const GraphInnerWrapper = styled(Box)(`
    display:flex;
    width:100%;
    position: relative;

    & > div > div:last-child > div > div:first-of-type {
        border-bottom: 2px solid #F5F5F5;
    }
`)

export const Graph = styled(Box)(props => `
    position: relative;
    display: flex;
    margin-left: auto;
    flex-direction: column;
    width: 100%;
    align-items: end;
    z-index: 1;
    background-image: url('${props.simulator === "yes" ? imageryBg : imageryBgPlain }');
    background-position: ${RIGHT_CONTENT} 0;
    background-repeat-x: no-repeat;
    background-size: calc(100% - (${RIGHT_CONTENT} + ${SEARCH_ICON}));
`)


export const GraphScaleWrapper = styled(Box)(`
    width: calc(100% - ${RIGHT_CONTENT} - ${SEARCH_ICON});
    margin-right:${SEARCH_ICON};
    display: flex;
    justify-content: space-between;
    
    & span {
        width: calc(100% / 7);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    & .txt1 { justify-content: start;}
    & .txt2 { justify-content: start;}
    & .txt3 { justify-content: start;}
    & .txt4 { justify-content: center; width:20px;}
    & .txt5 { justify-content: end;}
    & .txt6 { justify-content: end;}
    & .txt7 { justify-content: end;}
`
)

export const GraphInfoWrapper = styled(Box)(`
    width: calc(100% - ${RIGHT_CONTENT} - ${SEARCH_ICON});
    margin-right:${SEARCH_ICON};
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    > span {
        min-width:300px;
        font-size: 12px;
        line-height: calc(14 / 12);
        &:first-of-type {
            display: flex;
            align-items: center;
        }
        &:last-child {
            display: flex;
            align-items: center;
            flex-direction: row-reverse;
            >img{
                transform: rotateY(180deg);
                filter: hue-rotate(-245deg) brightness(1.5);
            }
        }
        >img{
            padding-right: 10px;
        }
    }
`)

export const SliderWrap = styled(Box)(props => `
  height: 12px;
  display: flex;
  align-items: center;
  & img {
    position: relative;
    left: ${props.percent}%;
    transform: translate(-${props.percent}%, 0px);
    width: ${MAGNET_SCORE_IMAGE[props?.logoname || ""]?.width || 'auto'}px;
    height: 12px;
  }
  & .slider {
    -webkit-appearance: none;
    width: 100%;
    height: 12px;
    border-radius: 5px;
    background: transparent;
    outline: none;
    -webkit-transition: .2s;
    transition: opacity .2s;
    border-radius: 0;
  }
  
  & .slider:hover {
    background: ${props.disabled ? "transparent" : "#ffffff50"};
  }
  
  & .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: ${MAGNET_SCORE_IMAGE[props?.logoname || ""]?.width || 80}px;
    height: 15px;
    object-fit: contain;
    border: 0;
    background-image: url(${props?.logo});
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    cursor: ${props.disabled ? "default" : "poiner"};
  }
  
  & .slider::-moz-range-thumb {
    appearance: none;
    width: ${MAGNET_SCORE_IMAGE[props?.logoname || ""]?.width || 80}px;
    height: 15px;
    object-fit: contain;
    border: 0;
    background-image: url(${props?.logo});
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    cursor: pointer;
  }
`)

export const GraphContentWrapper = styled(Box)(`
  width: 100%;
  display: flex;
  flex-direction: column;
  & .contentText {
    background:#fff;
  }

  &.showRowLevel2 {
    // background: #FFF;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.15);
    border-bottom: 20px #fff solid;
    position:relative;
  }
  &.showRowLevel2 .graphRowDetails {
    background: #fff;
  }
  &.showRowLevel2 .contentText {
    border-bottom:0 !important;
  }
  &.showRowLevel2 > div:last-child > div {
    border-bottom:0 !important;
  }
`)

export const GraphContent = styled(Box)(`
    width: 100%;
    display: flex;
    &:first-of-type {
       & .list {
        // border-color: transparent;
       }
    }
    &:last-child {
       & .contentText {
        //   border-bottom: 2px solid #F5F5F5;
       }
    }
`);

export const ContentText = styled(Box)(props => `
    width: ${RIGHT_CONTENT};
    min-width: ${RIGHT_CONTENT};
    text-align: right;
    display: flex;
    flex-direction:column;
    justify-content: center;
    border-top: 2px solid #F5F5F5;
    padding-right: 10px;
    & .title {
        font-size: 1rem;
        font-weight: 300;
    }
    & .percent {
        font-size: 1rem;
        font-weight: 500;
    }
    & .percent b {
        font-weight: 600;
    }
    &.showEdit_true {
        padding: 8px 40px 8px 0;
        position:relative;
        justify-content: flex-start;
    }
    &.showEdit_true button {
        position:absolute;
        top: 10px;
        right: 3px;
    }
    & .hideP {
        display:none !important;
    }
`);

export const GraphRow = styled(Box)(`
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: calc(100% - ${RIGHT_CONTENT} - ${SEARCH_ICON});
    min-height: 50px;
    border-top: 2px solid #FFFFFF;
`)

export const GraphRowDetails = styled(Box)(`
    display: flex;
    width: ${SEARCH_ICON};
    position: relative;
    justify-content: center;
    align-items: center;
    background:#fff;

    & > button {
        height:35px;
    }
`)

export const GraphContentLevel2 = styled(Box)(`
    display: flex;
    flex-wrap: wrap;
    background:#fff;

    & .contentText {
        padding:10px 0 0 240px;
        width: 100%;
        border:0;
        background: #fff;
    }
    & .contentText p {
        text-align:left;
        font-size: 12px;
        font-weight: 300;
    }
    & .list {
        margin-left:242px;
        background-image: url('${imageryBgPlain}');
        background-position: 0;
        background-repeat-x: no-repeat;
        background-size:100%; 
    }
`)

export const BrandImageryRowInsights = styled(Box)(`
    padding-left: 240px;
    background: #fff;
    & * {
        font-size:14px;
    }
    & .pptSubHeading {
        display:none;
    }
`)

export const BrandImageryRowInsightsHeading = styled(Box)(`
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    padding-top:10px;
`)
