import React, { useState, useEffect } from 'react'
import { MuiButton, MuiImage } from '../'
import { useGlobalStore } from '../../store'
import { postLoginData, postLoginReportData, reportData, arrowSvg, arrowSvgStr } from './content'
import { PostLoginOverlay, NoDataAvailable, TextWrapper, HeaderOverlay, LhsOverlay, ReportOverlay, AskIaOverlay, ReportFooterOverlay, CloseButton } from './style'

const TourGuide = ({show=false, data, type=''}) => {
  const { setTourGuideActive, subLhsApiLoaded, showLhsMenu } = useGlobalStore()
  const docW = document.body.clientWidth;

  const GenerateTours = ({data}) => {
    let count = 0
    return (
      <>
        {data.map((d, i) => {
          const ids = document.querySelectorAll(d.selector)
          const id = ids[0]
          if(!id) return false

          count++
          const idWidth = id.clientWidth
          const idHeight = id.clientHeight
          let textMarginTop = 0
          let textMarginLeft = 10

          if(d.valign === 'bottom'){
            textMarginTop = idHeight - 10
          }
          else if(d.valign === 'center'){
            textMarginTop = idHeight/2
          }
          if(d.align === 'right'){
            textMarginLeft = idWidth - 10
          }
          else if(d.align === 'center'){
            textMarginLeft = idWidth/2
          }

          const hideUsingMedia = !(docW < d?.hideMedia) || false

          const styleObject = {top: id.offsetTop, left: id.offsetLeft, marginTop: textMarginTop, marginLeft: textMarginLeft}

          if(type === 'report'){
            let reportStyling = `
              position: absolute;
              top: 0;
              left: 0;
              z-index: 100;
              color: #fff;
              font-weight: 500;
              font-size: 12px;
              line-height: 13px;
              max-width: 170px;
              white-space: pre-wrap;
              display:flex;
              width:100%;
              margin-top: ${textMarginTop}px;
              margin-left: ${textMarginLeft}px;              
            `

            if(d?.align === 'left'){
              reportStyling = reportStyling + `
                flex-direction: row-reverse;
                transform: translate(-100%);
              `
            }
            if(d?.valign === 'top'){
              reportStyling += `
                transform: translateY(-100%);
              `
            }
            if(d?.align === 'left' && d?.valign === 'top'){
              reportStyling += `
                transform: translateX(-100%) translateY(-100%);
              `
            }

            if(d?.style){reportStyling = reportStyling+d.style}

            for(let i=0; i<ids.length; i++){
              let id = ids[i]
              if(!id.querySelector('.dynamicTourText')){
                const node = document.createElement("div");
                node.className = 'dynamicTourText'
                node.style = reportStyling
                node.innerHTML = `${arrowSvgStr(d.align, d.valign, d.arrowDirection)} <span style='padding-top:${d.valign === 'bottom' ? 25 : 0}px;'>${d.content}</span>`
                id.appendChild(node)
              }
            }

            return false
          }

          if(!(hideUsingMedia && (type === 'postLogin' || type === 'postLoginReports'))){return false}

          return <TextWrapper data-type={d?.type} key={i} data-selector={(d.selector).replaceAll(' ','')} style={{...styleObject, ...(d.style || {})}} className={`align_${d.align} valign_${d.valign} arrowDirection_${d.arrowDirection}`}>
            {arrowSvg} <span>{d.content}</span>
          </TextWrapper>
        })}

        {count===0 && <NoDataAvailable>No Tutorial Available here</NoDataAvailable>}
      </>
    )
  }

  const closeTourGuide = () => {
    const dynamicTourText = document.querySelectorAll('.dynamicTourText')
    if(dynamicTourText.length > 0){
      for(let d=0; d<dynamicTourText.length; d++){
        document.querySelectorAll('.dynamicTourText')[0].remove()
      }
    }
    const scrollHeight = document.querySelector('.reportOuterWrapper.onlyOneComponent')
    if(scrollHeight?.scrollHeight){
      scrollHeight.style.height = 'auto'
    }
    setTourGuideActive(false)
  }

  const makeTourGuideActive = () => {
    setTourGuideActive(true)
    const winH = window.innerHeight
    const reportScrollHeight = document.querySelector('.reportOuterWrapper.onlyOneComponent')
    if(reportScrollHeight?.scrollHeight){
      if(reportScrollHeight?.scrollHeight < winH){
        reportScrollHeight.style.height = winH+'px'
      }
    }
  }

  return (
    <>
      {type === 'tutorialButton' && <MuiButton variant='contained' color='dark' onClick={makeTourGuideActive}>Tutorial</MuiButton>}
      {type === 'postLogin' && <>
        <PostLoginOverlay className='PostLoginOverlay' />
        <GenerateTours data={postLoginData} />
        <CloseButton variant='contained' color='light' onClick={closeTourGuide}>Close Tutorial</CloseButton>
      </>}
      {type === 'askIa' && <><AskIaOverlay className='AskIaOverlay' /></>}
      {type === 'report' && <><GenerateTours data={reportData} /><ReportOverlay className='ReportOverlay' /></>}
      {type === 'postLoginReports' && <>
        <HeaderOverlay className='HeaderOverlay' /><LhsOverlay className={`LhsOverlay ${showLhsMenu}`} />
        <GenerateTours data={postLoginReportData} />
        <CloseButton variant='contained' color='light' onClick={closeTourGuide}>Close Tutorial</CloseButton>
      </>}
      {type === 'reportFooter' && <ReportFooterOverlay className='ReportFooterOverlay' />}
    </>
  )
}
export default TourGuide
