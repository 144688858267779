import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import { MuiButton } from '../../../../components'
import { exportToPPT_tableCss } from '../../../../util'

export const OuterWrapper = styled(Box)(`
    width:100%;
    &.exportToPPT {
      width:1400px;
    }
    &.exportToPPT .innerWrapper {
      display:none;
    }
    &.exportToPPT .pptSubHeading {
      display:none;
    }
    &.exportToPPT .factorGraphList {
      width:100%;
      overflow:hidden;
      height:610px;
    }
    &.exportToPPT .factorGraphList .menuWrapper {
      box-shadow:none;
      min-width:100%;
      position: static;
    }
    &.exportToPPT .factorGraphList .svgWrapper {
      width:100%;
    }
    &.exportToPPT .factorGraphList menuWrapper * {
      font-size:16px !important;
    }
    ${exportToPPT_tableCss}
`);

export const InnerWrapper = styled(Box)(`
    display:flex; flex-direction: column; width:100%; position: relative;
`);

export const LegendWrapper = styled(Box)(`
  padding:0 10px 10px 10px;
  display: flex;
  justify-content: end;
  flex-direction:column;

  & p {
    font-size: 12px;
    font-weight: 300;
    width:100%;
    text-align: right;
  }

  & .legendRoot {
    justify-content: end;
  }

`);

export const MuiResetButton = styled(MuiButton)(`
  font-weight:300;
  width:100%;
  justify-content:space-between;
  margin:0 !important;
`)

export const PopupHeading = styled(Box)(`
  text-align: center;
  font-size: 20px;
  font-weight: 300;
  padding-bottom:20px;
  width:100%;
`)

export const PopupContent = styled(Box)(`
  padding-top:21px;
  width:100%;
  position:relative;
  text-align:left;
  & b {
    font-weight:600;
  }
  & ul {
    padding-left:20px;
  }
  & * {
    color: #000;
    font-size: 12px;
    font-weight: 300;
  }
`)

export const GraphWrapper = styled(Box)(`
  width:100%;
  position:relative;
`)

export const ExportToPPT = styled(Box)(`
  width:1400px;
  position:relative;
`)

export const PopupOverlay = styled(Box)(`
  position: absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background: rgba(255, 255, 255, 0.1);
  z-index:1;
`)

export const PopupWrapper = styled(Box)(`
  position: absolute;
  top:160px;
  left:calc(50% - (450px / 2));
  width: 450px;
  padding: 30px;
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0px 0px 20px 0px #ABABAB;
  text-align: center;
  & button {
    width: 135px;
    height: 40px;
    margin: 0 10px;
  }
`)

export const Overlay = styled(Box)(`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.1);
`)

export const MenuOuterWrapper = styled(Box)(`
    position: absolute;
    top: -10px;
    right: 0;
    z-index: 3;
    min-width: 250px;
    max-width: 420px;
    background: #FFFFFF;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    padding-top: 35px;
    overflow-x: hidden;
    & .insightsWrapper {
      white-space: pre-wrap;
    }
`)

export const MenuInnerWrapper = styled(Box)(`
    width:100%;
    padding:0 15px 15px 15px;
    margin-top:-10px;
    font-size: 14px;
    font-weight: 300;
    line-height: 16px;

    max-height: calc(100vh - 150px);
    overflow: auto;

`)

export const MenuWrapper = styled(Box)(`
    width: 100%;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    padding-bottom:15px;
    & .insightsWrapper{padding-top:5px !important;}
`)

export const MenuHeading1 = styled(Typography)(`
    width: 100%;
    display: flex;
`)

export const MenuHeading2 = styled(Typography)(`
    width: 100%;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    display: flex;
    justify-content: start;
    align-items: center;
    line-height: 1;
    flex-flow:wrap;
    & svg {
      margin: 0 5px;
      min-width:20px;
    }
`)

export const CloseWrap = styled(Box)(`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  z-index:3;
`)

export const EditWrap = styled(Box)(`
  position: absolute;
  top: 0;
  right: 20px;
  cursor: pointer;
  z-index:3;
`)
