import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import { background } from '../../assets/images'
import { MEDIA } from '../../constants'

export const PreLoginOuterWrapper = styled(Box)(`
  & > div:last-child{
    height:50px;
  } 
`)

export const PreLoginInnerWrapper = styled(Box)(`
  align-items: center;
  display: flex;
  flex-grow: 1;
  min-height: calc(100vh - 50px);
  background:#f1f1f1 url(${background}) no-repeat center center fixed;
  background-size:cover;
  padding:20px;
  @media (max-width: ${MEDIA.lg}) {
    height: 100%;
  }
`)

export const Wrapper = styled(Box)(`
  width:100%;
  max-width:680px;
  margin: 0 auto;
  @media only screen and (max-width: 1300px) { transform: scale(0.8); }
  @media only screen and (max-width: 1040px) { transform: scale(1); }
`)

export const FormWrapper = styled(Box)(`
  width:100%;
  max-width:430px;
  min-height:370px;
  margin:0 auto;
`)

export const InsightsAssistantLogo = styled(Box)(`
  padding-bottom:10px;
`)

export const MavenMagnetLogo = styled(Box)(`
  margin-top:5%;
  border-top: 1px solid #E0EDFF;
  padding-top:2%;
  text-align:center;
`)

export const FooterWrapper = styled(Box)(`
  position: static;
  justify-content: space-between;
  display: flex;
  height: 30px;
  padding: 0 20px;
  align-items: center;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  color: #000;
  & a{
    text-decoration:none;
    color: #000;
  }
`)
