import React, { useState, useEffect, useRef } from 'react'
import ReportsService from '../../services/reports.service'

import { Modal, Rte } from '..'
import { IconButton } from '@mui/material'
import { IconEdit } from '../../assets/images/icons'

import { LoginButton, Title, Wrapper } from './style'

const ReportDriversEditHeaderLevel2 = (props) => {
  const {onClose, insightsElementId, driverId, driverName, pageInsights, onSuccess} = props
  const [showEditHeaderModal, setShowEditHeaderModal] = useState(false)

  const handleOnEditHeader = () => {
    setShowEditHeaderModal(false)
  }

  const form = useRef()
  const [loader, setLoader] = useState(false)
  const [insightsValue, setInsightsValue] = useState(pageInsights || '')

  useEffect(() => {
    setInsightsValue(pageInsights);
  }, [pageInsights])
 
  const onSubmit = (e) => {
    e.preventDefault()
    const updatedValues = {
      insightsElementId : insightsElementId,
      driverId : driverId,
      insights : insightsValue
    }

    setLoader(true)
    ReportsService.reportSetDriverElementInsights(updatedValues)
    .then((resp) => {
      setLoader(false)
      setShowEditHeaderModal(false)
      onSuccess(updatedValues)
    })
    .catch((error) => {
      setLoader(false)
      const resMessage = error
      console.log('ERROR : ', resMessage)
        // enqueueSnackbar(resMessage, { variant: 'error' })
    })
  }

  return (
    <>
      <IconButton aria-label="Edit" onClick={() => setShowEditHeaderModal(true)} sx={{height:'35px', margin:'-5px 0 0 5px'}}><IconEdit /></IconButton>
      {showEditHeaderModal && <Modal title='' showClose={true} onClose={handleOnEditHeader}>
        <Wrapper>
          <form ref={form}>
            <Title variant='body1'>Edit {driverName}</Title>

            <Rte title={'Insights'} value={insightsValue} onChange={(e) => setInsightsValue(e)} height={320} />

            <LoginButton
              color='primary'
              variant='contained'
              type='submit'
              // onClick={formik.handleSubmit}
              onClick={onSubmit}
              disabled={loader || false}
            >
              Submit
            </LoginButton>
          </form>
        </Wrapper>
      </Modal>}
    </>
  )
}

export default ReportDriversEditHeaderLevel2
