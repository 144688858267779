import React from 'react'
import { ChartBarHorizontalCustom, EmojiRow } from '../../../../components'
import { BarChartsWrapper, BrandMagnetScoreWarningWrap, ChartRow, RowName } from './style'

const BrandMagnetScoreWarning = ({ rows = [] }) => {

  return (
    <BrandMagnetScoreWarningWrap className='brandMagnetScoreWarningWrap'>
      <BarChartsWrapper className='barChartsWrapper'>
        {rows.map((rowItem, uppderIndex) => {
          return (
              <ChartRow key={uppderIndex}>
                <RowName>{rowItem.title}</RowName>
                {
                  rowItem?.graphs?.map((item, index) => {
                    return (
                      <ChartBarHorizontalCustom
                        data={item.barData}
                        // height="auto"
                        key={index}
                        showInside
                        maxWidth='inside'
                        valueInPercent={false}
                        endIcon={<EmojiRow number={item.value} />}
                        maxPercentRatio={100}
                      />
                    )
                  })}
              </ChartRow>
          )
        })}
        <EmojiRow style={{ justifyContent: 'space-between' }} type='multi' />
      </BarChartsWrapper>
    </BrandMagnetScoreWarningWrap>)
}

export default BrandMagnetScoreWarning;