import React, { useEffect, useState, useRef } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import AuthService from '../../services/auth.service'
import PreLogin from '../../layout/preLogin'
import { MuiTextField, Loader } from '../../components'

import { LoginButton, LoginContent2, ErrorMsg, Msg, EmailInputWrap } from './style'

import { useMsal, useIsAuthenticated } from "@azure/msal-react" // Azure
import { loginRequest } from "../../AzureAuthConfig" // Azure

const azureFunc = () => {
  // console.log('READY', )
  localStorage.setItem('logs', JSON.stringify([]))
  localStorage.setItem('company', JSON.stringify({}))
  localStorage.setItem('user', JSON.stringify({}))
  const azureData = JSON.parse(localStorage.getItem('azureLoginDetails'))

  if(azureData?.email){
    const resp = {
      "email": azureData?.email,
      "companyId" : azureData?.companyId
    }
    
    AuthService.getSSOUserApi(resp)
    .then((resp) => {
      if(resp?.user?.UserId){
        // setUser(resp.user)
        // setCompany(resp.company)
        // props.history.push(ROUTE_PROJECTS)
        console.log('DONEEEEEEEEEEEEEEEE')
      }
      else{
        //  setServerErrorMsg('You do not have access to Insights Assistant. Please contact your administrator to get access.')
        console.log('You do not have access to Insights Assistant. Please contact your administrator to get access.')
      }
      window.localStorage.removeItem('azureLoginDetails')
    })
    .catch((error) => {
      console.log('ERROR : ', error)
    })
  }
}

const Sso = (props) => {
  const form = useRef();
  const [loader, setLoader] = useState(false)
  const [serverErrorMsg, setServerErrorMsg] = useState()
  const [azureLoginDetails, setAzureLoginDetails] = useState({companyId:'', ssoType:''})
  const { instance } = useMsal(); // Azure
  const isAuthenticatedAzure = useIsAuthenticated() // Azure

  const onSubmitAwsFlow = (data) => {
    AuthService.getSSOLogInUrlApi(data)
      .then( (resp) => {
        // getSSOType // [aws, azure, google]
        // getSSOCompanyID
        // edha.singh@mgmotor.co.in
        if(typeof resp === 'string'){
          window.location.href = resp
        }
        else if (typeof resp === 'object'){
          instance.loginRedirect(loginRequest).catch(e => {
            console.log('dddddd------', e);
          })
        }
        else if (data.email === 'sewantil@mavenmagnet.com'){
          // TESTING AZURE LOGIN THINGS
          // window.location.href = 'https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=http%3A%2F%2Flocalhost%3A3001%2F&client-request-id=af08c00c-4868-4adb-bac0-0e9dfd861a08'
          console.log('loginRequest = ', loginRequest)
          instance.loginRedirect(loginRequest).catch(e => {
            console.log('eeeeeeeeeeeeeeee------', e);
          })
        }
        else{
          setServerErrorMsg('Invalid Email')
        }
      })
      .catch((error) => {
        const resMessage = error
        console.log('ERROR : ', resMessage)
      }).finally(() => {
        setLoader(false)
      })
  }

  const onSubmitAzureFlow = (data) => {
    // TESTING AZURE LOGIN THINGS
    AuthService.getSSOCompanyIdApi(data)
    .then( (resp) => {
      // const tempResp = { companyId : resp || 'e0804536-45e9-11ed-bca4-0a1ea4c5532f' }
      const tempResp = { companyId : resp }

      // if(tempResp.companyId === 'e0804536-45e9-11ed-bca4-0a1ea4c5532f'){ // sewanti
        setAzureLoginDetails({...azureLoginDetails, companyId:tempResp.companyId})
        localStorage.setItem('azureLoginDetails', JSON.stringify({ssoType:'azure', companyId:tempResp.companyId, email: data.email}))

        instance.loginRedirect(loginRequest).catch(e => {
          console.log('eeeeeeeeeeeeeeee------', e);
        })
      // }
    })
    .catch((error) => {
      const resMessage = error
      console.log('ERROR : ', resMessage)
    }).finally(() => {
      setLoader(false)
    })
  }

  const onSubmit = (data) => {
    setLoader(true)

    AuthService.getSSOTypeApi(data)
      .then( (resp) => {
        // const tempResp = { Type : 'azure' } || resp
        const tempResp = resp
        setAzureLoginDetails({...azureLoginDetails, ssoType:tempResp})

        if(tempResp === 'Azure'){
          onSubmitAzureFlow(data)
        // } else if (tempResp.type === 'aws'){
        } else {
          onSubmitAwsFlow(data)
        }
      })
      .catch((error) => {
        const resMessage = error
        console.log('ERROR : ', resMessage)
      }).finally(() => {
        setLoader(false)
      })
  }

  const validation = (values) => {
    const errors = {}
    return errors
  }

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      email: ''
    },
    validationSchema: Yup.object({
      email: Yup.string().email().matches(/^(?!.*@[^,]*,)/).max(60).required('Email is required')
    }),
    validate: (values) => validation(values),
    onSubmit: (values) => {
      const updatedValues = { ...values }
      onSubmit(updatedValues)
      // redirectToSSO(updatedValues)
    }
  })

  useEffect(() => {
    if(isAuthenticatedAzure){
      azureFunc()
    }
  },[isAuthenticatedAzure])

  return (
    <>
      <PreLogin>
        {!isAuthenticatedAzure 
        ? <form onSubmit={formik.handleSubmit} ref={form} autoComplete='off'>
          <EmailInputWrap>
            <Msg>Use your organization's network email to sign in.</Msg>
            <MuiTextField
              error={Boolean(
                formik.touched.email && formik.errors.email
              )}
              fullWidth
              helperText={formik.touched.email && formik.errors.email}
              name='email'
              label='Email'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type='text' 
              value={formik.values.email || ''}
              variant='standard'
              color='dark'
              disabled={loader}
              sx={{'& input': {
                fontSize: '16px !important'
              } }}
            />
          </EmailInputWrap >

          {serverErrorMsg && <ErrorMsg>{serverErrorMsg}</ErrorMsg>}
          
          <LoginButton
            variant='contained'
            color='secondary'
            type='submit'
            onClick={formik.handleSubmit}
            disabled={loader}
            endIcon={loader && <Loader />}
          >
            Sign In
          </LoginButton>

          <LoginContent2> <a href='/login'>Sign in without SSO</a></LoginContent2>

        </form>
        : <Loader width={50} />
        }
      </PreLogin>
    </>
  )
}

export default Sso
