import { Box, Typography } from '@mui/material'
import React from 'react'
import { LegendItem, LegendsWrap } from './style'

const DEFAULT = [
  { label: 'Test Label', value: 'testLabel', color: 'red' }
]

const DynamicLegend = ({ legends = DEFAULT, showInPpt = false }) => {
  return (
    <LegendsWrap className={`legendWrap ${showInPpt && 'legendRoot'}`}>
      {legends.map((legendKey) => {
        return (
          <LegendItem key={legendKey.value} className='legendItem'>
            <Box sx={{ background: legendKey.color }} className='legendBox'></Box>
            <Typography className='legendText'>{legendKey.label}</Typography>
          </LegendItem>
        )
      })}
    </LegendsWrap>
  )
}

export default DynamicLegend
