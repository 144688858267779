import React, { useRef, useState, useEffect } from 'react'
import { LoaderWrapper, Legend } from '../../../../components'
import ReportsService from '../../../../services/reports.service'

import { Table, TableCell, TableRow } from '@mui/material'
import { ChartBarHorizontalCustom } from '../../../../components'
import { LegendWrapper, ContentWrapper, TableContainerWrapper, TableHeadWrapper, TableBodyWrapper, TableRowWrapper, TableCellHead, TableCellBody, ContWrap, InputRangeWrapper, InputRange, ContOuterWrap } from './style'


const ProSimulatorDriver = (props) => {
  // const { enqueueSnackbar } = useSnackbar()
  const [deepDiveLoading, setDeepDiveLoading] = useState(true)
  const [deepDivePageData, setDeepDivePageData] = useState([])
  const [overallRating, setOverallRating] = useState(0)
  const [settings, setSettings] = useState({})
  const [entityDetails, setEntityDetails] = useState()

  const changingDriverData = (resp) => {
    setEntityDetails(resp.entityDetails)
    const settingData = {
      DriverTriggerStandouts: false,
      DriverSubBrandStandouts: false,
      DriverAttributeStandouts: false,
      DriverAttributes: false,
      Verbatim: false,
      SearchKeywords: false,
      WithoutSentiments: (props.InsightsElementType === 'DriversWithoutSentiments'),
      DriverRatingImpacts: false,
      DriverRatingImpactsValue: false,
      overallRatingValue: 0,
      UpdateProductRating: false
    }

    let overallRatingValue = 0
    const tempResp = resp.respData
    tempResp.map((l1, i) => {
      l1.levelIndexing = `level1_${i}`
      l1.selected = false
      // l1.IsHighlighted = (l1.IsHighlighted === 'true')
      l1.buttonClicked = 'none'

      l1.DriverTriggerStandouts = l1?.DriverTriggerStandouts?.DriverTriggerStandout || []
      if (!Array.isArray(l1.DriverTriggerStandouts)) { l1.DriverTriggerStandouts = [l1.DriverTriggerStandouts] }
      if (l1.DriverTriggerStandouts.length > 0) { settingData.DriverTriggerStandouts = true }

      l1.DriverSubBrandStandouts = l1?.DriverSubBrandStandouts?.DriverSubBrandStandout || []
      if (!Array.isArray(l1.DriverSubBrandStandouts)) { l1.DriverSubBrandStandouts = [l1.DriverSubBrandStandouts] }
      if (l1.DriverSubBrandStandouts.length > 0) { settingData.DriverSubBrandStandouts = true }

      l1.DriverAttributeStandouts = l1?.DriverAttributeStandouts?.DriverAttributeStandout || []
      if (!Array.isArray(l1.DriverAttributeStandouts)) { l1.DriverAttributeStandouts = [l1.DriverAttributeStandouts] }
      if (l1.DriverAttributeStandouts.length > 0) { settingData.DriverAttributeStandouts = true }

      l1.DriverAttributes = l1?.DriverAttributes?.DriverAttributeElement || []
      if (!Array.isArray(l1.DriverAttributes)) { l1.DriverAttributes = [l1.DriverAttributes] }
      if (l1.DriverAttributes.length > 0) { settingData.DriverAttributes = true }

      l1.Verbatim = l1?.Verbatim?.VerbatimElement || []
      if (!Array.isArray(l1.Verbatim)) { l1.Verbatim = [l1.Verbatim] }
      if (l1.Verbatim.length > 0) { settingData.Verbatim = true }
      l1.Verbatim = l1.Verbatim.map(v => {v.EntityType = props?.EntityType || ''; return v})

      l1.SearchKeywords = l1?.SearchKeywords?.string || []
      if (!Array.isArray(l1.SearchKeywords)) { l1.SearchKeywords = [l1.SearchKeywords] }
      if (!settingData.SearchKeywords && l1?.SearchKeywords?.length > 0) { settingData.SearchKeywords = true }
      // l1.SearchKeywords = l1.SearchKeywords.map(v => {v.EntityType = props?.EntityType || ''; return v})

      l1.DriverRatingImpacts = l1?.DriverRatingImpacts?.DriverRatingImpact || {}
      if (l1?.DriverRatingImpacts?.DriverId) {
        l1.DriverRatingImpactsValue = `${l1.DriverRatingImpacts.RatingImpactAbsolute} | ${l1.DriverRatingImpacts.RatingImpactPercent}%` || ''
        settingData.overallRatingValue = settingData.overallRatingValue + l1.DriverRatingImpacts.RatingImpactAbsolute
        settingData.DriverRatingImpactsValue = true
      }

      l1.SimulationData={
        MixedPercent: l1.MixedPercent,
        NegativePercent: l1.NegativePercent,
        NeutralPercent: l1.NeutralPercent,
        Percent: l1.Percent,
        PositivePercent: l1.PositivePercent,
        DriverRatingImpactsValue: l1.DriverRatingImpactsValue,
        ValueChanged: false
      }

      return l1
    })

    setOverallRating(overallRatingValue)
    setSettings(settingData)
    setDeepDivePageData(tempResp)
    setDeepDiveLoading(false)
  }

  const loadDeepDive = () => {
    setDeepDiveLoading(true)
    ReportsService.reportGetDriverElementsApi({ insightsElementId: props.InsightsElementId, entityId: props.EntityId, type: props.type })
      .then((resp) => {
        changingDriverData(resp)
      })
      .catch((error) => {
        const resMessage = error
        console.log('ERROR : ', resMessage)
        setDeepDiveLoading(false)
      })
  }

  useEffect(() => {
    if (deepDivePageData.length === 0) {
      loadDeepDive()
    }
  }, [])

  const getUpdatedProductRating = () => {
    // setDeepDiveLoading(true)
    // ReportsService.reportGetDriverElementsApi({ insightsElementId: props.InsightsElementId, entityId: props.EntityId, type: props.type })
    //   .then((resp) => {
    //     changingDriverData(resp)
    //   })
    //   .catch((error) => {
    //     const resMessage = error
    //     console.log('ERROR : ', resMessage)
    //     setDeepDiveLoading(false)
    //   })
    let settingData = {...settings}
    settingData.UpdateProductRating = true
    setSettings(settingData)
  }

  const resetSimulation = () => {
    const resetData = [...deepDivePageData]
    resetData.map((r) => {
      r.SimulationData={
        MixedPercent: r.MixedPercent,
        NegativePercent: r.NegativePercent,
        NeutralPercent: r.NeutralPercent,
        Percent: r.Percent,
        PositivePercent: r.PositivePercent,
        DriverRatingImpactsValue: r.DriverRatingImpactsValue,
        ValueChanged: false
      }
      return r
    })
    setDeepDivePageData(resetData)
    
    let settingData = {...settings}
    settingData.UpdateProductRating = false
    setSettings(settingData)

    props.setSimulationEvents('')
  }

  useEffect(() => {
    if (props.simulationEvents === 'Update') {
      getUpdatedProductRating()
    }
    else if (props.simulationEvents === 'Reset') {
      resetSimulation()
    }
    // else if (props.simulationEvents === 'Save') {
    //   saveSimulation()
    // }
  }, [props.simulationEvents])


  const onChangeRangeFunc = (param, index) => {
    let data = [...deepDivePageData]
    data[index].SimulationData = param
    setDeepDivePageData(data)
  }

  const legendsArray = [ { label: 'Positive', value: 'Positive' }, { label: 'Neutral', value: 'Neutral' }, { label: 'Mixed', value: 'Mixed' }, { label: 'Negative', value: 'Negative' }]
  return (
    <>
      <ContentWrapper id={props.type}>
        <LoaderWrapper loading={deepDiveLoading} loadOnlyApi={props.loadOnlyApi}>
          <NanoDataGrid
            rows={deepDivePageData}
            showDetails={true}
            theme={`theme1 type${props.InsightsElementType}`}
            setting={settings}
            onChangeRange={onChangeRangeFunc}
          />
          <LegendWrapper><Legend legends={legendsArray} /></LegendWrapper>
        </LoaderWrapper>
      </ContentWrapper>
    </>
  )
}

export default ProSimulatorDriver


//////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////


const NanoDataGrid = (props) => {
  const { rows, theme='theme1', setting={} } = props

  setting.SimulationPreview = true
  if (rows.length === 0 ) { return false }
  const maxPercentRatio = rows?.[0].Percent

  return (
    <TableContainerWrapper>
      <Table className={theme}>
        <TableHeadWrapper>
          <TableRow>
            <TableCellHead style={{width:'235px'}}>Drivers</TableCellHead>

            {!setting.UpdateProductRating &&
              <>
                <TableCellHead className='brdL' style={{width:'300px'}}>Sentiment</TableCellHead>
                <TableCellHead style={{minWidth:'120px'}}>Impact<br />Potential</TableCellHead>
              </>
            }
            
            {setting.UpdateProductRating &&
              <>
                <TableCellHead className='brdL' style={{color:'#095BBD', width:'300px'}}>Simulated Rating: 4.52 <br /><br />Sentiment</TableCellHead>
                <TableCellHead style={{color:'#095BBD', minWidth:'120px'}}>Simulated<br />Impact</TableCellHead>
                <TableCellHead className='brdL' style={{width:'300px'}}>Current Rating: {(5 - setting.overallRatingValue).toFixed(2)} <br /><br />Sentiment</TableCellHead>
                <TableCellHead style={{minWidth:'120px'}}>Impact<br />Potential</TableCellHead>
              </>
            }

            {setting.DriverAttributeStandouts && <TableCellHead className='brdL' style={{minWidth:'300px'}}>Attributes</TableCellHead>}

          </TableRow>
        </TableHeadWrapper>
        <TableBodyWrapper>
          {rows.length !== 0 && rows.map((row,i) => {
            return (
              <Rows key={i} index={i} row={row} maxPercentRatio={maxPercentRatio} {...props} />
            )
          })}
        </TableBodyWrapper>
      </Table>
    </TableContainerWrapper>
  )
}


const Rows = ({index, row, action, showDetails=false, showDetailsContent, maxPercentRatio=100, setting, onChangeRange }) => {
  const onChangeRangeFunc = (param) => {
    onChangeRange(param, index)
  }

  return (
    <>
      <TableRowWrapper className={`DriversTr tRow ${row.selected?'selected':'notSelected'}`}>
        <TableCellBody style={{width:'235px'}} align='right'>{row?.IsHighlighted && <span className='IsHighlighted'></span>}{row?.DriverName} {setting.DriverRatingImpactsValue && `(${row.Percent}%)`}</TableCellBody>

        <TableCellBody className='brdL' style={{width:'300px'}}><BarChartWrapper simulationPreview={true} row={row.SimulationData} maxPercentRatio={maxPercentRatio} fullWidth={setting.DriverRatingImpactsValue} setting={setting} onChangeRange={onChangeRangeFunc}/></TableCellBody>
      
        {!setting.UpdateProductRating &&
          <>
            <TableCellBody>{row.SimulationData.DriverRatingImpactsValue}</TableCellBody>
          </>
        }

        {setting.UpdateProductRating &&
          <>
            <TableCellBody style={{color:'#095BBD'}}>{row.SimulationData.ValueChanged && row.SimulationData.DriverRatingImpactsValue}</TableCellBody>
            <TableCellBody className='brdL' style={{width:'300px'}}><BarChartWrapper row={row} maxPercentRatio={maxPercentRatio} fullWidth={setting.DriverRatingImpactsValue} setting={setting} /></TableCellBody>
            <TableCellBody>{row?.DriverRatingImpactsValue}</TableCellBody>
          </>
        }

        {setting.DriverAttributeStandouts && <TableCellBody className='brdL'>
          {row?.DriverAttributeStandouts.map((d,i) => (
            <span key={i} className={(!(setting.DriverRatingImpactsValue || setting.SearchKeywords) && d.Sentiment) || ""}>{d.AttributeName}{i<row.DriverAttributeStandouts.length-1 && ','} </span>
          ))}
        </TableCellBody>}

      </TableRowWrapper>
    </>
  )
}


const BarChartWrapper = (props) => {
  const {row, maxPercentRatio, fullWidth, simulationPreview=false, onChangeRange} = props

  const [dataRow, setDataRow] = useState()
  const [maxPercentRatioNew, setMaxPercentRatioNew] = useState(maxPercentRatio)

  const inPercentage = (val) => {
    return Math.round((val / row?.Percent) * 100)
  }

  const onChangeRangeFunc = (event) => {
    const value = +(event.target.value)
    const defaultRangeVal = (dataRow.positive+dataRow.neutral+dataRow.mixed)
    if(value === +dataRow.rangeValue){return false}

    let negVal = dataRow.negative
    let posVal = dataRow.positive
    
    if(value > +dataRow.rangeValue){ // Increamental
      const diff = (value-defaultRangeVal)
      negVal = negVal - diff
      posVal = posVal + diff
    }
    else if(value < +dataRow.rangeValue){ // Decremental
      const diff = (defaultRangeVal-value)
      negVal = negVal + diff
      posVal = posVal - diff
    }

    setDataRow({...dataRow, ...{
      rangeValue : value,
      positive : posVal,
      negative : negVal
    }})

    const updatedSimulatedData = {
      MixedPercent : dataRow.mixed,
      NegativePercent : (negVal/100)*row.Percent,
      NeutralPercent : dataRow.neutral,
      Percent: row.Percent,
      PositivePercent : (posVal/100)*row.Percent,
      DriverRatingImpactsValue: row.DriverRatingImpactsValue,
      ValueChanged: true
    }

    onChangeRange(updatedSimulatedData)
  }

  useEffect(() => {
    if(row?.Percent){
      if(fullWidth){ setMaxPercentRatioNew(row?.Percent || 0) }
      setDataRow({
        neutral:inPercentage(row.NeutralPercent),
        mixed:inPercentage(row.MixedPercent),
        positive:inPercentage(row.PositivePercent),
        negative:inPercentage(row.NegativePercent),
        rangeValue:inPercentage(row.NeutralPercent + row.MixedPercent + row.PositivePercent)
      })
    }
  }, [row])

  return (
    <ContOuterWrap>
      {simulationPreview && row?.Percent && dataRow?.positive &&
        <InputRangeWrapper value={dataRow.rangeValue} minvalue={dataRow.neutral+dataRow.mixed}>
          <InputRange 
            type='range' id='volume' name='volume'
            min={dataRow.neutral+dataRow.mixed} 
            max={inPercentage(row?.Percent || 0)} 
            value={dataRow.rangeValue} step='1' 
            onChange={onChangeRangeFunc}
          />
        </InputRangeWrapper>
      }
      {dataRow?.positive &&
        <ChartBarHorizontalCustom
          showInside={true}
          data={[{ Positive:dataRow.positive, Neutral:dataRow.neutral, Mixed:dataRow.mixed, Negative:dataRow.negative }]}
          total={!fullWidth && Number(row.Percent)} maxPercentRatio={maxPercentRatioNew} valueInPercent={true}
        />
      }
    </ContOuterWrap>
  )
}







