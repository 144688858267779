import React from 'react'
const IconReportMore = () => {
    return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.75466 22.014L3.70559 22.1787L12 21V21.1C12.0001 21.1 12.0001 21.1 12.0002 21.1C18.037 21.0999 22.6 16.8563 22.6 12C22.6 7.14519 18.0369 2.9 12 2.9C5.96312 2.9 1.4 7.14519 1.4 12C1.4 14.2312 2.33804 16.2989 3.94615 17.9116C4.09147 18.0574 4.20282 18.2336 4.27227 18.4274C4.34168 18.6211 4.36753 18.8278 4.34799 19.0326C4.2432 20.0426 4.04457 21.0407 3.75466 22.014ZM1.89054 23.3881L1.89057 23.388C2.02467 23.0718 2.14759 22.7509 2.25909 22.426L2.25916 22.426L2.26028 22.4222L2.26421 22.4091C2.63787 21.3239 2.9427 20.0749 3.05454 18.9096L3.05904 18.8627L3.0258 18.8294C1.20193 17.0011 0.1 14.6107 0.1 12C0.1 6.26836 5.41527 1.6 12 1.6C18.5847 1.6 23.9 6.26836 23.9 12C23.9 17.7316 18.5847 22.4 12 22.4H11.9999C10.8201 22.4016 9.6452 22.2484 8.50527 21.9444L8.46841 21.9345L8.43437 21.9518C7.66243 22.3422 5.99256 23.0577 3.25803 23.6004C2.90375 23.6706 2.54832 23.735 2.19188 23.7933C1.97594 23.8276 1.80031 23.6005 1.89054 23.3881Z" fill="black" stroke="white" strokeWidth="0.2"/>
        <path d="M10.599 10.14C10.3068 9.68494 9.87486 9.33704 9.368 9.14856C8.86115 8.96008 8.30681 8.94119 7.78831 9.09475C7.2698 9.24831 6.81515 9.566 6.49267 10.0001C6.17019 10.4342 5.99732 10.9612 6.00003 11.502C6.0003 11.9498 6.12082 12.3893 6.34899 12.7746C6.57717 13.1599 6.90463 13.4769 7.29717 13.6924C7.68971 13.9079 8.13291 14.014 8.58048 13.9997C9.02806 13.9854 9.46357 13.8511 9.84153 13.611C9.64503 14.1945 9.27903 14.817 8.67603 15.441C8.56066 15.5603 8.49743 15.7206 8.50024 15.8866C8.50164 15.9688 8.5192 16.0499 8.55194 16.1253C8.58467 16.2006 8.63194 16.2688 8.69103 16.326C8.75013 16.3831 8.81989 16.428 8.89635 16.4582C8.97281 16.4883 9.05446 16.5032 9.13664 16.5018C9.30261 16.499 9.46066 16.4303 9.57603 16.311C11.805 14.001 11.5155 11.49 10.599 10.143V10.14ZM16.599 10.14C16.3068 9.68494 15.8749 9.33704 15.368 9.14856C14.8612 8.96008 14.3068 8.94119 13.7883 9.09475C13.2698 9.24831 12.8151 9.566 12.4927 10.0001C12.1702 10.4342 11.9973 10.9612 12 11.502C12.0003 11.9498 12.1208 12.3893 12.349 12.7746C12.5772 13.1599 12.9046 13.4769 13.2972 13.6924C13.6897 13.9079 14.1329 14.014 14.5805 13.9997C15.0281 13.9854 15.4636 13.8511 15.8415 13.611C15.645 14.1945 15.279 14.817 14.676 15.441C14.6189 15.5001 14.574 15.5698 14.5438 15.6463C14.5137 15.7228 14.4988 15.8044 14.5002 15.8866C14.5016 15.9688 14.5192 16.0499 14.5519 16.1253C14.5847 16.2006 14.6319 16.2688 14.691 16.326C14.7501 16.3831 14.8199 16.428 14.8964 16.4582C14.9728 16.4883 15.0545 16.5032 15.1366 16.5018C15.2188 16.5004 15.2999 16.4828 15.3753 16.4501C15.4507 16.4173 15.5189 16.3701 15.576 16.311C17.805 14.001 17.5155 11.49 16.599 10.143V10.14Z" fill="black"/>
    </svg>
    )
}

export default IconReportMore
