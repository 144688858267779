import {ApiPost} from '../util/axios'

const canWeHelpApi = async (payload) => {
  const options = {
    reqId: 'SetClientHelpRequest',
    payload: payload,
    resId: 'SetClientHelpRequestResponse',
    resType: 'object'
  }

  return await ApiPost(options).then( (res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

export default { 
  canWeHelpApi
}
