import React, { useEffect, useState } from 'react'
import { LoaderWrapper, ReportHeading, ReportOuterWrapper } from '../../../../components'
import ReportsService from '../../../../services/reports.service'
import { useUpdateLhs } from '../../../../hooks/useUpdateLhs'
import { exportExcel_addPercentage } from '../../../../util'
import BuyerTable from './buyerTable'
import { RootWrap } from './style'

const BuyerMaturityByAge = (props) => {
  const [state, setState] = useState([])
  const [loading, setLoading] = useState(false)
  const [reportExcelData, setReportExcelData] = useState()
  const {updateSubLhsApiLoaded} = useUpdateLhs()

  const getSegment = () => {
    setLoading(true)
    ReportsService.getSegmentOfSegmentOverviewElement({ insightsElementId: props.InsightsElementId })
      .then((resp) => {
        setState(resp);
        setTimeout(() => {
          updateSubLhsApiLoaded(props)
        }, 2000)

        const reportExcelDataObj = []        
        for(let i=0; i<resp.length; i++){
          const segmentSet = Array.isArray(resp[i]?.SecondarySegmentSet?.SecondarySegment) ? resp[i].SecondarySegmentSet.SecondarySegment : [resp[i].SecondarySegmentSet.SecondarySegment]
          for(let j=0; j<segmentSet.length; j++){
            // reportExcelDataObj.push([{value: resp[i].PrimarySegmentName}, {value: segmentSet[j].SegmentName}, {value: segmentSet[j].SegmentValue}])
            reportExcelDataObj.push([{value: resp[i].PrimarySegmentName}, {value: segmentSet[j].SegmentName}, exportExcel_addPercentage(segmentSet[j].SegmentValue)])
          }
        }
        setReportExcelData(reportExcelDataObj)
      })
      .catch((error) => {
        const resMessage = error
        console.log('ERROR : ', resMessage)
        setLoading(false)
      }).finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    getSegment()
  }, [])

  return (
    <>
      {reportExcelData && <ReportHeading downloadId={props.type} {...props} downloadIdData={reportExcelData} />}
      <ReportOuterWrapper>
        <RootWrap id={props.type}>
          <LoaderWrapper loading={loading} loadOnlyApi={props.loadOnlyApi}>
            <BuyerTable isExtraColor={props.isExtraColor} data={state} />
          </LoaderWrapper>
        </RootWrap>
      </ReportOuterWrapper>
    </>
  )
}

export default BuyerMaturityByAge