import React from 'react'
import { ShadowWrap, StyledTypography } from './style'
import { LEGEND_TEXT_COLOR_MAP } from '../../../../constants'
import { Standout, GreenArrow, RedArrow } from '../../../../assets/images/icons'

const LandscapeCell = ({ legends, column, isLastColumn }) => {
  const negative = column?.find((item) => item.ImageryIndexSentiment === 'Negative')
  const positive = column?.find((item) => item.ImageryIndexSentiment === 'Positive')
  const neutral = column?.find((item) => item.ImageryIndexSentiment === 'Neutral')
  const mixed = column?.find((item) => item.ImageryIndexSentiment === 'Mixed')

  const colors = {
    Positive: "#148a49",
    Neutral: "#F5BD4F",
    Negative: "#d42323",
    Mixed: "#A6A6A6",
  }

  let borderColor = 'transparent'
  if (negative && !positive && !neutral) {
    borderColor = 'rgba(255, 0, 0, 0.5)';
  } else if (positive && !negative && !neutral) {
    borderColor = 'rgba(0, 176, 80, 0.5)'; // '#00B050' + 50;
  } else if (neutral && !positive && !negative) {
    if(neutral.InsightName !== '') {
      // borderColor = '#f5bd4fb8';
      borderColor = 'transparent';
    }
  }

  const sorting = (data) => {
    let headingData = []
    let contentData = []

    for(let i=0; i<data.length; i++){
      if(data[i]?.InsightSentiment?.length > 0) {
        data[i].eq = 2
        // const arr = [data[i].InsightSentiment, data[i].ImageryIndexSentiment]
        if(data[i].InsightSentiment === 'Positive'){data[i].eq = 3}
        else if(data[i].InsightSentiment === 'Negative'){data[i].eq = 1}
        else if(data[i].InsightSentiment === 'Neutral'){data[i].eq = 4}
        contentData.push(data[i])
      } else {
        headingData.push(data[i])
      }
    }

    if(headingData?.length > 0){return headingData}
    else{
      contentData = contentData.slice().sort(function(firstUser, secondUser) { 
        if(firstUser.eq < secondUser.eq) return 1;
        if(firstUser.eq > secondUser.eq) return -1;
        return 0;
      })
      return contentData
    }
  }

  // const sortData = (data) => {
  //   const finalData = sorting(data)
  //   if(finalData.length <= 1) { return finalData }
  //   console.log('finalData ------------> ', finalData)
  //   return finalData
  // }

  return (
    <ShadowWrap
      isSentiment={ negative || positive || neutral || mixed || !column}
      isLastColumn={isLastColumn}
      leftBorderColor={borderColor}
    >
      {!column && <StyledTypography>{column}</StyledTypography>}
      {column?.length > 0 && sorting(column)?.map((item, index) => {
        return (
          <StyledTypography
            className={`textItemShadow legends_${legends.length}`}
            key={item.InsightName + index}
            fontcolor={item.InsightSentiment === "Mixed" ? LEGEND_TEXT_COLOR_MAP.Mixed : colors[item.InsightSentiment]}
            background={(item.InsightSentiment && item.InsightName) && item?.InsightStandout}
          >
            <label>
              {item.InsightSentiment === "Mixed" && '~'}
              {item.InsightSentiment === "Negative" && '-'}
              {item.InsightSentiment === "Positive" && '+'}
              {item.InsightName || ''}
            </label>
            {/* {index !== (column.length - 1) ? ',' : ''} */}
            {/* {(item.InsightSentiment && item.InsightName) && item?.InsightStandout && <span><Standout /></span>} */}
            {(item.InsightSentiment && item.InsightName) && item?.InsightStandoutType === 'New' && <span><Standout /></span>}
            {(item.InsightSentiment && item.InsightName) && item?.InsightStandoutType === 'Up' && <span><GreenArrow /></span>}
            {(item.InsightSentiment && item.InsightName) && item?.InsightStandoutType === 'Down' && <span><RedArrow /></span>}
          </StyledTypography>
        )
      })}
    </ShadowWrap>
  )
}

export default LandscapeCell
