import { styled } from '@mui/system'
import { Box, RadioGroup } from '@mui/material'

export const FormLabel = styled('span')(`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #000;
  width:100%;
`)

export const FormError = styled(Box)(`
  font-size: 0.75rem;
  font-weight: 400;
  margin: 4px 14px 0 14px;
  color: #D14343;
`)

export const RadioGroupWrap = styled('label')(`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom:10px;
  font-size: 14px;
  font-weight:normal;
  
  & > span {
    padding:0 10px 0 0;
  }
  & svg {
    margin-left:0;
  }
`)
