import { styled } from '@mui/system'
import { Box, Typography } from '@mui/material'

export const ModalWrapper = styled(Box)(`
  display: ${props => props.showModal ? 'flex' : 'none'};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 10;
`)

export const Overlay = styled(Box)(`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 2;
  opacity:.7;
  cursor: pointer;
`)

export const CloseWrap = styled(Box)(`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index:1;
`)

export const Title = styled(Typography)(`
  margin: -10px 0 0 0 !important;
  padding: 0 0 30px 0 !important;
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 20px !important;
  line-height: 30px !important;
  color: #000 !important;
`)

export const Popup = styled(Box)(`
  z-index: 3;
  position: relative;
  background: #FFFFFF;
  width: fit-content;
  padding: 40px;
  border-radius: 10px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
`)
