import React from 'react'
const IconReportSearch = () => {
    return (
        <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.90677 19.5393V19.6893H2.05677H10.1339V21.4461H2.05677C1.55106 21.4461 1.06607 21.2452 0.708481 20.8876C0.350891 20.53 0.15 20.0451 0.15 19.5393V2.05677C0.15 1.55107 0.350891 1.06607 0.708481 0.708481C1.06607 0.350892 1.55107 0.15 2.05677 0.15H20.5677C21.0734 0.15 21.5584 0.350891 21.916 0.708481C22.2736 1.06607 22.4745 1.55107 22.4745 2.05677V10.1339H20.7177V2.05677V1.90677H20.5677H2.05677H1.90677V2.05677V19.5393Z" fill="black" stroke="white" strokeWidth="0.3"/>
            <path d="M18.5462 16.0606L18.4729 16.1639L18.5625 16.2535L21.7879 19.4789L20.4789 20.7879L17.2535 17.5625L17.1639 17.4729L17.0606 17.5462C15.7774 18.457 14.1989 18.8526 12.6378 18.6547C11.0767 18.4568 9.64686 17.6798 8.63153 16.4776C7.6162 15.2754 7.08942 13.7357 7.15555 12.1635C7.22168 10.5913 7.87588 9.10127 8.98858 7.98858C10.1013 6.87588 11.5913 6.22168 13.1635 6.15555C14.7357 6.08942 16.2754 6.6162 17.4776 7.63153C18.6798 8.64686 19.4568 10.0767 19.6547 11.6378C19.8526 13.1989 19.457 14.7774 18.5462 16.0606ZM10.9664 16.1177C11.6957 16.605 12.5532 16.8651 13.4303 16.8651C14.6065 16.8651 15.7345 16.3979 16.5662 15.5662C17.3979 14.7345 17.8651 13.6065 17.8651 12.4303C17.8651 11.5532 17.605 10.6957 17.1177 9.96642C16.6304 9.23712 15.9378 8.66869 15.1274 8.33302C14.3171 7.99736 13.4254 7.90954 12.5651 8.08066C11.7048 8.25178 10.9146 8.67416 10.2944 9.29438C9.67416 9.91461 9.25178 10.7048 9.08066 11.5651C8.90954 12.4254 8.99736 13.3171 9.33302 14.1274C9.66869 14.9378 10.2371 15.6304 10.9664 16.1177Z" fill="black" stroke="white" strokeWidth="0.3"/>
        </svg>
    )
}

export default IconReportSearch