import { useGlobalStore } from '../store'

const useExportToPptObjects = () => {
    const { exportToPptObject, setExportToPptObject } = useGlobalStore()
    const updatingExportToPptObject = (obj) => {
        const key = Object.keys(obj)[0]
        if(!exportToPptObject?.[key]){
            localStorage.setItem(key, JSON.stringify(obj[key]))
            // setExportToPptObject({...exportToPptObject, ...obj})
            setExportToPptObject({...obj})
        }
    }
    return {updatingExportToPptObject}
}

export {useExportToPptObjects}