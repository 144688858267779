import React, {useState, useEffect} from 'react'
import { Box, List, ListItem, Skeleton } from '@mui/material'
import { Loader } from '../../../components'
import { removeSpecialCharFromLhs } from '../../../util'
import { useGlobalStore } from '../../../store'

import { LhsMenuWrapper, Heading, ListLink } from './style'

export const Tabs = ({ data, activeTabTitle, isLoading, tabLength }) => {
  const { setAskIaActiveState, isAskIaActiveState } = useGlobalStore()
  const [subMenuData, setSubMenuData] = useState()

  const tabClickHandler = (id) => {
    if(subMenuData?.length > 0){
      const el = document.querySelector(`#${id}`);
      if (!el) return;
      el.scrollIntoView({
        behavior: 'smooth'
      });
    }
  }

  const toggleGroupFunc = (obj) => {
    const show = !obj.showListContent;
    const d = subMenuData.map(i => {
      if( i.ParentTitle === obj.ParentTitle ){
        i.showListContent = show;
      }
      return i
    })
    setSubMenuData(d)    
  }

  const checkAskIaStatusFunc = () => {
    if(isAskIaActiveState !== 'none'){
      setAskIaActiveState('none')
    }
  }

  useEffect(() => {
    const groupNames = []
    const d = data.map(i => {
      const data = {...i, showListContent:true}
      if(data?.ParentTitle && !groupNames.includes(data.ParentTitle)){
        groupNames.push(data.ParentTitle)
        data.groupName = data.ParentTitle;
        data.groupClass = 'nestedLinkStart';
      }
      else if(data?.ParentTitle){
        data.groupClass = 'nestedLinkMid';
      }
      return data
    })

    for(let i=1; i<d.length; i++){
      if((d[i].groupClass === 'nestedLinkStart' || d[i].groupClass === '') && d[i-1].groupClass === 'nestedLinkMid'){
        d[i-1].groupClass = 'nestedLinkEnd'
      } else if(d[i].groupClass === 'nestedLinkMid' && i === (d.length-1)){
        d[i].groupClass = 'nestedLinkEnd'
      }
    }
    
    setSubMenuData(d)
  }, [data])

  useEffect(() => {
    if(subMenuData?.length === 1){
      document.querySelector('body').classList.add('hasOneLinkInLevel2');
    } else {
      document.querySelector('body').classList.remove('hasOneLinkInLevel2');
    }
  }, [subMenuData])

  return (
    <LhsMenuWrapper className={`tabLevel2 ${subMenuData?.length > 11 ? 'excessLength' : ''}`} tabLength={tabLength}>
      {
        isLoading
          ? <Box>
              {/* <Skeleton variant='text' width={80}/>
              <Skeleton variant='text'/>
              <Skeleton variant='text' width={130}/> */}
            </Box>
          : <>
            {/* {activeTabTitle && <Heading>{activeTabTitle}</Heading>} */}
            <List>
              {subMenuData?.length > 0 && subMenuData.map((list, i) => {
                const customId = 'id_'+removeSpecialCharFromLhs(list.Title)+'_'+(list.InsightsElementId).replaceAll('-','');
                const isActiveClass = decodeURIComponent(window?.location?.hash) === `#${list.Title}_${i}` ? 'active' : 'inActive'
                return (
                  <ListItem key={i} onClick={() => checkAskIaStatusFunc()}>
                    {list?.groupName && <span className={`subHeading heading${list?.showListContent?'Show':'Hide'}`} onClick={() => toggleGroupFunc(list)}>{list?.groupName}</span>}
                    {list?.showListContent && <ListLink onClick={() => tabClickHandler(customId)} key={i} className={`l2TabLink ${customId} ${list?.ParentTitle?'nestedLink':''}  ${list?.groupClass}`} activeClassName={isActiveClass==='inActive'?'inActive':'activated'} to={`#${list.Title}_${i}`}>{list.Title}<span style={{display:'none'}}>_{i}</span></ListLink>}
                  </ListItem>
                )
              })}
              {subMenuData?.length === 0 && <ListItem><ListLink>No data found</ListLink></ListItem>}
            </List>
          </>
      }
    </LhsMenuWrapper>
  )
}
