import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const InputWrap = styled('div')(`
    width: 300px;
    margin-left: auto;
    & .searchInputWrap fieldset{
        border: 1px solid #EEEEEE;
    }
`)

export const DraftWrap = styled(Box)(`
   & .tableRoot {
    margin-top: 40px;
    overflow: auto;
   }
    & tr{
        box-shadow:none !important;
    }
    & th, td {
        border-bottom: 1px solid #EEEEEE;
        background: #fff;
        font-size: 14px;
        padding-left: 0;
        font-weight: 300;
    }
    & th {
        text-transform: capitalize !important;
    }
`)
