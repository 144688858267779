import {ApiPost} from '../util/axios'

const resetPassword1Api = async data => {
  const options = {
    reqId: 'GetUserPasscode',
    payload: { email: data.email },
    resId: 'GetUserPasscodeResponse',
    resType: 'object'
  }

  return await ApiPost(options).then( (res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const resetPassword2Api = async data => {
  const options = {
    reqId: 'SetUserPasswordForgotPassword',
    payload: { email: data.email, newPassword: data.newPassword },
    resId: 'SetUserPasswordForgotPasswordResult',
    resType: 'object'
  }

  return await ApiPost(options).then( (res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const changePasswordFirstTimeApi = async data => {
  const options = {
    reqId: 'SetUserPasswordFirstTimeLogIn',
    payload: { email: data.email, newPassword: data.newPassword },
    resId: 'SetUserPasswordFirstTimeLogInResponse',
    resType: 'object'
  }

  return await ApiPost(options).then( (res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const changePasswordApi = async data => {
  const options = {
    reqId: 'SetUserPassword',
    payload: { email: data.email, currentPassword: data.currentPassword, newPassword: data.newPassword },
    resId: 'SetUserPasswordResponse',
    resType: 'object'
  }

  return await ApiPost(options).then( (res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const loginApi = async data => {
  const options = {
    reqId: 'GetUser',
    payload: { email: data.email, password: data.password },
    resId: 'GetUserResult',
    resType: 'object'
  }

  return await ApiPost(options).then( (res) => {
    if (res?.UserId) {
      const timeStamp = new Date()
      const response = {...res, loginTime:timeStamp, devMode: res.CompanyId === 'e0804536-45e9-11ed-bca4-0a1ea4c5532f'}
      window.localStorage.setItem('user', JSON.stringify(response))
      window.localStorage.setItem('logs', JSON.stringify([]))
      // return 'Login Success'
      return companyDetailsApi(response)
    }
    return {}
  },
  (error) => {
    console.log('error - ', error)
  })
}

const authorizeApi = async data => {
  const options = {
    reqId: 'GetUser',
    payload: { email: data.email, password: data.password },
    resId: 'GetUserResult',
    resType: 'object'
  }

  return await ApiPost(options).then( (res) => {
    if (res?.UserId) {
      const timeStamp = new Date()
      const response = {...res, loginTime:timeStamp, devMode: res.CompanyId === 'e0804536-45e9-11ed-bca4-0a1ea4c5532f'}
      window.localStorage.setItem('user', JSON.stringify(response))
      window.localStorage.setItem('logs', JSON.stringify([]))
      // return 'Login Success'
      return companyDetailsApi(response)
    }
    return {}
  },
  (error) => {
    console.log('error - ', error)
  })
}

const getSSOUserApi = async data => {
  const options = {
    reqId: 'GetSSOUser',
    payload: data,
    resId: 'GetSSOUserResult',
    resType: 'object'
  }

  return await ApiPost(options).then( (res) => {
    if (res?.UserId) {
      const timeStamp = new Date()
      const response = {...res, loginTime:timeStamp, devMode: false}
      window.localStorage.setItem('user', JSON.stringify(response))
      window.localStorage.setItem('logs', JSON.stringify([]))
      // return 'Login Success'
      return companyDetailsApi(response)
    }
    return {}
  },
  (error) => {
    console.log('error - ', error)
  })
}

const getSSOLogInUrlApi = async data => {
  const options = {
    reqId: 'GetSSOLogInUrl',
    payload: data,
    resId: 'GetSSOLogInUrlResult',
    resType: 'object'
  }

  return await ApiPost(options).then( (res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const getSSOTypeApi = async data => {
  const options = {
    reqId: 'GetSSOType',
    payload: data,
    resId: 'GetSSOTypeResult',
    resType: 'object'
  }

  return await ApiPost(options).then( (res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const getSSOCompanyIdApi = async data => {
  const options = {
    reqId: 'GetSSOCompanyId',
    payload: data,
    resId: 'GetSSOCompanyIdResult',
    resType: 'object'
  }

  return await ApiPost(options).then( (res) => {
    return res
  },
  (error) => {
    console.log('error - ', error)
  })
}

const companyDetailsApi = async data => {
  const options = {
    reqId: 'GetCompany',
    payload: { userId: data.UserId },
    resId: 'GetCompanyResult',
    resType: 'object'
  }

  return await ApiPost(options).then( (res) => {
    window.localStorage.setItem('company', JSON.stringify(res))
    // return data
    return {user:data,company:res}
  },
  (error) => {
    console.log('error - ', error)
  })
}

const validateCompanyIdApi = async data => {
  const options = {
    reqId: 'GetCompany',
    payload: { userId: data.UserId },
    resId: 'GetCompanyResult',
    resType: 'object'
  }

  return await ApiPost(options).then( (res) => {
    window.localStorage.setItem('company', JSON.stringify(res))
    // return data
    return {user:data,company:res}
  },
  (error) => {
    console.log('error - ', error)
  })
}

const logout = () => {
  // window.localStorage.removeItem('user')
  // const url = `${API_URL}users/logout`
  // return axios.get(url, { headers: authHeader() })
}

// const getCurrentUser = JSON.parse(window.localStorage.getItem('user'))

export default {
  logout,
  // getCurrentUser,
  loginApi,
  authorizeApi,
  getSSOUserApi,
  getSSOLogInUrlApi,
  getSSOTypeApi,
  getSSOCompanyIdApi,
  validateCompanyIdApi,
  resetPassword1Api,
  resetPassword2Api,
  changePasswordApi,
  changePasswordFirstTimeApi
}
