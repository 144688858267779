import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import React from 'react';
import { createProject } from '../../../../assets/images/icons';
import { MuiImage } from '../../../../components';

const HomeWrap = styled('div')(`
  width: 100%;
  height: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`);

const ContentWrap = styled('div')(`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  & img {
    width: 114px;
    height: 107px;
  }
`);

const StyledTypography = styled(Typography)(`
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  margin-top: 40px;
`);

const Home = () => {
  return (
    <HomeWrap>
      <ContentWrap>
        <MuiImage src={createProject} width={50}/>
        <StyledTypography>Select a project!</StyledTypography>
      </ContentWrap>
    </HomeWrap>
  )
}

export default Home;