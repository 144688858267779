import { styled } from '@mui/material/styles'
import { Box, ListItemText } from '@mui/material'
import { exportToPPT_tableCss } from '../../../../util'
import { MEDIA } from '../../../../constants'

export const CardOuterWrapper = styled(Box)(`
  // padding:20px 30px 50px;
  // min-height: 350px;
  // border: 1px red solid;
  width:100%;

  &.exportToPPT > div {padding:0; box-shadow:none;}
  ${ exportToPPT_tableCss }

  @media (max-width: ${MEDIA.lg}) {
    padding: 30px 20px 40px;
    
    &.exportToPPT {min-width:1200px; max-width:1200px; width:1200px; }
    &.exportToPPT .relativeBrands{flex-direction:row;}
  }
`)

export const CardInnerWrapper = styled(Box)(`
  display: flex;
  align-items: center;
  background: #FFFFFF;
  // box-shadow: 0px 0px 10px #D9D9D9;
  border-radius: 2px;
  // padding: 30px 10px 25px 25px;
  padding: 0;
  // border: 1px #EEE solid;
  @media (max-width: ${MEDIA.lg}) {
    flex-direction : column;
  }
`)

export const LhsWrap = styled(Box)(`
  width: 300px;
  height: 300px;
  display:flex;
  justify-content:center;
  align-items:center;
`)

export const ListItemTextWrap = styled(ListItemText)(`
  margin-right: 10px;
`)

export const RhsWrap = styled(Box)(`
  min-width: 450px;
  padding-left: 80px;
  display:flex;
  justify-content:start;
  flex-wrap:wrap;
  align-items: flex-start;

  @media (max-width: 1280px) {
    padding-left: 50px !important;
  }
  @media (max-width: 760px) {
    min-width: 100%;
  }

  & div {
    padding-left:30px;
    position:relative;
    width:30%;
    flex:inherit;
    margin: 15px 0;
    @media (max-width: 760px){
        width: 50%;
    }
  }
  & div span > span {
    position:absolute;
    top:8px;
    left:10px;
    width: 10px;
    height: 10px;
    display:block;
  }
  & div span > b {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
  }
  & div p {
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
  }
`)

