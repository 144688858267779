import React, { useRef, useEffect, useState } from 'react';

// const correctOTP = "123456" // validate from your server

function OtpInputWithValidation({ value, onChange, numInputs=6 }) {

  const [otp, setOtp] = useState(new Array(numInputs).fill(""));
//   const [otpError, setOtpError] = useState(null);
  const otpBoxReference = useRef([]);

  function handleChange(value, index) {
    const finalValue = value.replace(/[^0-9]/gi, "");
    let newArr = [...otp];
    newArr[index] = finalValue
    setOtp(newArr);

    if(finalValue.length === 1 && index < numInputs-1){
      otpBoxReference.current[index + 1].focus()
    }
    
    onChange(newArr.join('')+'')
  }

  function handleBackspaceAndEnter(e, index) {
    if(e.key === "Backspace" && !e.target.value && index > 0){
      otpBoxReference.current[index - 1].focus()
    }
    if(e.key === "Enter" && e.target.value && index < numInputs-1){
      otpBoxReference.current[index + 1].focus()
    }
  }

//   useEffect(() => { 
//     if(otp.join("") !== "" && otp.join("") !== correctOTP){
//       setOtpError("❌ Wrong OTP Please Check Again")
//     }else{
//       setOtpError(null)
//     } 
//    }, [otp]);

  return (
    <div className='otpInput'>
      {otp.map((digit, index)=>(
        <input key={index} value={digit} maxLength={1}  
            onChange={(e)=> handleChange(e.target.value, index)}
            onKeyUp={(e)=> handleBackspaceAndEnter(e, index)}
            ref={(reference) => (otpBoxReference.current[index] = reference)}
            className='input'
        />
      ))}
      {/* <p>{otpError}</p> */}
    </div>
  )
}

export default OtpInputWithValidation;