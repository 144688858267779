import React, { useState, useEffect } from 'react'
import { MuiButton, Modal } from '../../components'
import { OuterWrapper, Wrapper, Heading, ButtonWrap, ContentWrapper, TitleWrap } from './style'

const PostLoginReportsEditModal = (props) => {
  const { 
    children,
    modalTitle = '',
    additionalButtons,
    buttonName = '',
    onLoadShow = false,
    showButton = true,
    modalOpened,
    modalClosed,
    spaceBottom,
    buttonClassName
  } = props

  const [showSimulatorModal, setShowSimulatorModal] = useState(false)
  
  const handleOnSimulator = () => {
    setShowSimulatorModal(false)
    modalClosed && modalClosed()
  }

  useEffect(() => {
    if(onLoadShow) setShowSimulatorModal(onLoadShow)
  }, [onLoadShow])

  useEffect(() => {
    if(showSimulatorModal && modalOpened) modalOpened()
  }, [showSimulatorModal])

  return (
    <>
      {showButton && <MuiButton className={`btnVerbatimManagement ${buttonClassName}`} variant='contained' color='light' onClick={() => setShowSimulatorModal(true)} style={{ marginLeft: '15px' }}>{buttonName}</MuiButton>}
      {showSimulatorModal &&
          <OuterWrapper>
            <Modal popupClass='postLoginReportsEditPopup' title='' modalClass='postLoginReportsEditModal' showClose={true} onClose={handleOnSimulator}>
            <Wrapper>
              <Heading>
                <TitleWrap>{modalTitle}</TitleWrap>
                <ButtonWrap>
                  {additionalButtons}
                </ButtonWrap>
              </Heading>
              <ContentWrapper spacebottom={spaceBottom} className='postLoginEditModalContent'>
                {children}
              </ContentWrapper>
            </Wrapper>
          </Modal>
        </OuterWrapper>
      }
    </>
  )
}

export default PostLoginReportsEditModal
