import React from 'react'

const DoubleArrow = () => {
  return (
    <svg width="14" height="6" viewBox="0 0 14 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.8786 2.7513L10.4513 0.0622342C10.26 -0.0932031 9.99527 0.0622343 9.99527 0.310934V1.49226H7.43578H6.5679H4.00841V0.310934C4.00841 0.0622343 3.74363 -0.0776593 3.5524 0.0622342L0.110323 2.7513C-0.0367744 2.87565 -0.0367744 3.10881 0.110323 3.23316L3.5524 5.93777C3.74363 6.0932 4.00841 5.93777 4.00841 5.68907V4.50774H6.5679H7.43578H9.99527V5.68907C9.99527 5.93777 10.26 6.07766 10.4513 5.93777L13.8786 3.23316C14.0405 3.12435 14.0405 2.87565 13.8786 2.7513Z" fill="black"/>
    </svg>
  )
}

export default DoubleArrow