import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import { exportToPPT_tableCss } from '../../../../util'

export const RootWrap = styled(Box)(`
	width: 100%;
	padding: 20px 30px 50px;
	&.exportToPPT {padding:0;}
	&.exportToPPT > div{box-shadow:none}
	&.exportToPPT .list{padding:0 20px;}
    ${exportToPPT_tableCss}
`)

export const LegendWrap = styled(Box)(`
	width: 100%;
	display: flex;
	justify-content: end;
	padding: 30px;
	padding-top: 25px;
`);

export const BarGraphTitle = styled(Typography)(`
	font-weight: 500;
	font-size: 16px;
	line-height: 21px;
	color: #000000;
	padding-bottom: 3px;
`);

export const LegendWrapper = styled(Box)(`
	padding:10px 0;
	display:flex;
	justify-content:end;
`);

export const SegmentInneWrapper = styled(Box)(`
	width: 100%;
	display: flex;
	flex-direction: column;

	& .rhs div:nth-of-type(1) {
		border-color: #a6a6a6;
	}

	& .rhs div:nth-of-type(2) {
		border-color: #fdc1ed;
	}

	& .rhs div:nth-of-type(3) {
		border-color: #ab8bf1;
	}

	& .rhs div:nth-of-type(4) {
		border-color: #b80d92;
	}

`);

export const HeadingWrapper = styled(Box)(`
	padding: 10px 0;
	justify-content: space-between;
	display: flex;
	& p:last-child {
		width:590px;
	}
`);

export const HeadingLhs = styled(Typography)(`
	font-weight: 500;
	font-size: 18px;
	line-height: 27px;
	width: calc(100%-440px);
`);

export const HeadingRhs = styled(Typography)(`
	font-weight: 500;
	font-size: 18px;
	line-height: 27px;
	text-align: center;
	width: 440px;
`);

export const BorderShadowWrapper = styled(Box)(`
	box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
`);


export const SegmentRowHeading = styled(Box)(`
	display: flex;
	border-radius: 4px 4px 0 0;
	justify-content:space-between;
	
	& .lhs{
		width:calc(100% - 440px);
		text-align:left;
		display:flex;
	}
	& .lhs p{
		font-weight: 300 !important;
		font-size: 12px !important;
		line-height: 14px;
	}

	& .rhs{
		display:flex;
		max-width:600px;
		width:100%;
	}

	& .rhs div {
		font-weight: 400;
		font-size: 14px;
		line-height: 14px;
		width: 150px;
		height: 46px;
		line-height: 46px;
		margin: 0 0 0 10px;
		text-align:center;
		background: #F1F1F3;
		border-radius: 4px 4px 0px 0px;
	}

	& .rhs div {
		border-left: 1px transparent solid;
		border-right: 1px transparent solid;
		border-top: 1px transparent solid;
    }

`);

export const SegmentRowConversation = styled(Box)(`
	display:flex;
	justify-content:space-between;

	&:nth-of-type(even){
		background:#F7F7F9;
	}

	& .lhs{
		width:calc(100% - 440px);
		text-align:left;
		padding:20px;
	}

	& .rhs{
		display:flex;
		max-width:600px;
		width:100%;
	}

	& .rhs div {
		padding:46px 0 0 0;
		width:150px;
		margin: 0 0 0 10px;
		font-weight: 400;
		font-size: 16px;
		line-height: 14px;
		text-align: center;
		border-left: 1px transparent solid;
		border-right: 1px transparent solid;
	}

	& .rhs div:nth-of-type(4) {
		// border-radius: 0 0 4px 4px;
	}

	&:last-child .rhs div {
		border-bottom-width: 1px;
		border-bottom-style: solid;
	}

	& .lhs div div {
		color:black;
	}
`);