import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import { exportToPPT_tableCss } from '../../../../util'
import { MEDIA } from '../../../../constants'

export const OuterWrapper = styled(Box)(`
    width: 100%;
    // padding: 20px 30px 50px 30px;
    position: relative;
    // &.exportToPPT { padding:0 10px 20px 0; border:0 !important;}
    &.exportToPPT { border:0 !important;}
    &.exportToPPT * { font-size:16px !important; line-height:1 !important; }
    &.exportToPPT td { min-height:40px; }
    ${ exportToPPT_tableCss }
    @media (max-width: ${MEDIA.lg}) {
        padding:20px 20px 40px 20px;
    }
`)

export const TableOuterWrapper = styled(Box)(`
    position:relative;
    padding-bottom:20px;
    overflow-x:auto;
`)

export const Table = styled('table')(`
    width: calc(100% + 10px);
    border-spacing: 10px 0;
`)

export const TableRow = styled('tr')(props => `
    > td:not(:first-of-type) {
        text-align: center;
        vertical-align: middle;
    }
    &:nth-of-type(2) {
        &:after {
            content: none !important;
        }
        > td:first-of-type:before {
            content: '';
            background: inherit;
            width: 100%;
            height: 100%;
            z-index: 1;
            top: -40px;
            left: 0;
            position: absolute;
            border:0;
        }
        > td:not(:first-of-type) {
            position: relative;
            &::before{
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
                width: 100%;
                height: calc(100% * ${props.BoxHeight || 6});
                z-index: 1;
            }
            &:hover{
                &::before{
                    background: #cbcbcb0f;
                }
            }
        }
    }
    &:not(:first-of-type) {
        &::after{
            content: '';
            z-index: 1;
            position: absolute;
            width: calc(100% - 10px);
            height: 1px;
            left: 0;
            bottom: auto;
            background: #E7E7E7;
        }
        > td:first-of-type {
            width: 242px;
            font-size: 14px;
            line-height: 3.5;
            font-weight: 300;
            color: #000;

        }
    }
`
)

export const TableCell = styled('td')(`
    width: calc((100% - 242px) / 4);
    min-height: 50px;
    position: relative;
    > svg {
        width: 20px;
        margin-top: 10px;
    }
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 1px;
        display: flex;
        border-bottom: 1px #E7E7E7 solid;
    }
`)

export const LegendWrapper = styled(Box)(`
    margin-left: auto;
    width: calc(100% - 255px);
    display: flex;
    justify-content: space-between;
`)

export const Mode = styled(Box)(`
    display: flex;
    > div {
        display: flex;
        align-items: center;
        padding: 0 10px;
        > img {
            filter: brightness(0);
            width: 15px;
        }
        > p {
            font-size: 12px;
            font-weight: 300;
            line-height: 1.5;
            padding-left: 5px;
        }
    }
`)

export const TableHeading = styled('th')(`
    padding-bottom: 10px;
    min-width: 170px;
    & img {
        max-height:15px;
    }
`)
