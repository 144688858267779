import React from 'react'
const IconClosePopup = () => {
    return (
        <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.8531 0C14.8135 0 17.6526 1.17718 19.7462 3.2726C21.8395 5.36816 23.0156 8.21037 23.0156 11.1734C23.0156 14.1364 21.8396 16.9786 19.7462 19.0742C17.6527 21.1696 14.8132 22.3468 11.8531 22.3468C8.89291 22.3468 6.0535 21.1696 3.95993 19.0742C1.86659 16.9786 0.690502 14.1364 0.690502 11.1734C0.693705 8.21097 1.87065 5.37094 3.96348 3.27625C6.05614 1.18138 8.89373 0.00328359 11.853 9.11621e-05L11.8531 0ZM11.8531 20.7508C14.3906 20.7508 16.8243 19.7417 18.6185 17.9456C20.4129 16.1495 21.421 13.7135 21.421 11.1736C21.421 8.63364 20.4129 6.1975 18.6185 4.40153C16.8242 2.60543 14.3905 1.5964 11.8531 1.5964C9.31559 1.5964 6.88181 2.60543 5.08759 4.40153C3.29322 6.19764 2.28517 8.63364 2.28517 11.1736C2.28784 13.7127 3.29678 16.1469 5.09059 17.9429C6.88441 19.7385 9.31645 20.7484 11.8533 20.751L11.8531 20.7508Z" fill="#C4C3C3"/>
            <path d="M8.09039 7.41732C8.23989 7.26768 8.44261 7.18359 8.65404 7.18359C8.86546 7.18359 9.06818 7.26768 9.21768 7.41732L11.8434 10.0454L14.469 7.41714V7.41732C14.6716 7.22153 14.9622 7.14707 15.2338 7.22153C15.5052 7.29618 15.7174 7.50853 15.7919 7.78021C15.8663 8.05206 15.7919 8.34299 15.5963 8.54571L12.9708 11.1739L15.5965 13.8022H15.5963C15.7503 13.9507 15.8379 14.1553 15.8398 14.3692C15.8416 14.5834 15.7576 14.7891 15.6063 14.9405C15.455 15.092 15.2495 15.1761 15.0356 15.1743C14.8218 15.1723 14.6175 15.0847 14.469 14.9306L11.8434 12.3025L9.21768 14.9307V14.9306C9.01514 15.1264 8.72451 15.2008 8.45292 15.1264C8.18151 15.0517 7.96937 14.8394 7.89479 14.5677C7.82039 14.2958 7.89479 14.0049 8.09038 13.8022L10.7159 11.174L8.0902 8.54572H8.09038C7.94088 8.39608 7.85687 8.19316 7.85687 7.98153C7.85687 7.76989 7.94088 7.56698 8.09038 7.41733L8.09039 7.41732Z" fill="#C4C3C3"/>
        </svg>
    )
}

export default IconClosePopup