import React from 'react'
import { Link } from 'react-router-dom'
import { insightsAssistant } from '../../../assets/images/icons'
import { ROUTE_PROJECTS } from '../../../constants'
import PoweredBy from './poweredBy'
import { LogoDiv } from './style'

const HeaderLogo = ({parentClass=''}) => {
  return (
    <LogoDiv className={`${parentClass} headerLogo`}>
       <Link to={ROUTE_PROJECTS}><img src={insightsAssistant} height='65' width='76' alt='Insights Assistant' /></Link>
       {/* <PoweredBy /> */}
    </LogoDiv>
  )
}

export default HeaderLogo