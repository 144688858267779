import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const InputWrap = styled(Box)(`
    width: 100%;
    width: 232px;
    margin-left: auto;
    & .MuiInputBase-root {
        height: 34px;
        background: #fff;
        overflow:hidden;
        & input{
            padding: 0 12px;
            height: 34px;
        }
    }
    & fieldset {
        border-color: rgba(0, 0, 0, 0.23) !important; 
    }
`)
