import styled from "@emotion/styled";
import { Box, IconButton } from "@mui/material";
import { MuiButton } from "../../../../components";
import { LEGEND_COLOR_MAP } from "../../../../constants";
import { exportToPPT_tableCss } from '../../../../util'
import { MEDIA } from "../../../../constants";

export const RootWrap = styled(Box)(`
	width: 100%;
	// padding: 20px 30px 50px;
	&.withoutSentiment {
		& .segmentRow {
			&:before{
				color: #000;
			}
		}
		& .miningBoxWrap {
			background: #5BA3FA;
			border-color: transparent;
			color: #FFFFFF;
			&.child-mining-box {
				color: #000;
				background: #fff;
				border: 1px solid #5BA3FA;
				&:before{
					background: #5BA3FA;
				}
				&:after{
					width: 0;
				}
			}
		}
	}

    // @media (max-width: ${MEDIA.lg}) { padding:20px 20px 40px 20px; }

	&.exportToPPT { padding:0; }
	&.exportToPPT * { font-size:14px; }
	&.exportToPPT .segmentRow {width: 100% !important; padding:10px; border:0; box-shadow:none;}
	&.exportToPPT .segmentRow > div:last-child { display:none; }
	&.exportToPPT .segmentRow .segmentRowContent { border: 0; width: 100% !important; display:flex !important;}
	&.exportToPPT .segmentRow:before { color: #000; position: relative; border: 0; width: 100%; margin: 0; padding: 0; left: 0; font-size:14px; font-weight:500; }
	&.exportToPPT .miningDetails { display:flex !important; }
	${ exportToPPT_tableCss }

`)

export const ContentWrap = styled(Box)(`
	background: #fff;
	box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
	margin-left: 141px;
	border-radius: 2px;
	width: calc(100% - 141px);
`)

export const HeaderWrap = styled(Box)(`
	display: flex;
	align-items: center;
	width: 100%;
`)

export const HeaderTab = styled(Box)(`
    display: flex;
	align-items: center;
	background: #F7F7F9;
	padding: 6px 10px;
	overflow: hidden;
	width: 100%;
	& .tab {
		padding: 5px 10px;
		font-size: 12px;
		font-weight: 300;
		margin-right: 2px;
		cursor: pointer;
		&.active {
			background: #fff;
			font-weight: 500;
		}
	}
`)

export const SegmentWrap = styled(Box)(`
	width: 100%;
`)

export const SegmentRow = styled(Box)(props => `
	font-size: 12px;
	font-weight: 300;
	margin-bottom: 5px;
	border: 1px solid #EEEEEE;
	border-left: 0;
	border-radius: 0 2px 2px 0;
	padding: 5px 10px;
	position: relative;
	min-height: 60px;
	display:flex;
	flex-direction: column;
	justify-content: center;
  	width: calc(100% - 20px);
	flex-wrap: wrap;
	&:before {
		content: attr(data-mining-attribute);
		position: absolute;
		left: -141px;
		width: 141px;
		height: calc(100% + 2px);
		display: flex;
		align-items: ${props.expended ? "flex-start" : "center"};
		border: 1px solid #EEEEEE;
		border-right: 0;
		padding: 16px;
		top: -1px;
		border-radius: 2px 0px 0px 2px;
		padding-top: ${props.expended ? "20px" : "16px"};
		color: ${props.sentiment === "Mixed" ? '#353535' : LEGEND_COLOR_MAP[props.sentiment]}
	}
	& .outerText{
		font-size: 12px;
		margin-left: 6px;
		font-weight: 300;
	}
`)

export const SegmentRowContent = styled(Box)(`
	display: flex;
	align-items: center;
	position: relative;
	flex-wrap: wrap;
	margin-top: -8px;
`);

export const IssuesWrap = styled(Box)(`
	position: absolute;
	left: calc(100% + 5px);
	display: flex;
	align-items: center;
	& .radio {
		width: 10px;
		height: 10px;
		min-width: 10px;
		min-height: 10px;
		border: 1px solid #7E7E7E;
		margin-right: 4px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		& .box {
			width: 4.5px;
			height: 4.5px;
			min-width: 4.5px;
			min-height: 4.5px;
			background: #7E7E7E;
			border-radius: 50%;
		}
	}
	& .radio-text {
		font-size: 12px;
		font-weight: 300;
		white-space: nowrap;
	}
`);

export const MiningBoxWrap = styled(Box)((props) => `
	width: 130px;
	min-width: 130px;
	max-width: 130px;
	display: flex;
	min-height: 46px;
	margin-right: 10px;
	display: flex;
	align-items: center;
	border: 1px solid ${props.ischild ? "#D9D9D9" : LEGEND_COLOR_MAP[props.sentiment]};
	padding: 2px 10px;
	margin-top: 10px;
	background: ${props.custombackground || "#fff"};
	color: ${props.customcolor || "#000"};
	font-weight: 300;
	font-size: 12px;
	line-height: 13px;
	position: relative;

	& span {
		position: relative;
	    z-index: 3;
	}

	&.child-mining-box:before {
		content: '';
		width: 1px;
		height: 12px;
		position: absolute;
		background: #D9D9D9;
		top: -12px;
		left: 50%;
		z-index:1;
	}
  &.child-mining-box:after {
		content: '';
		width: 3px;
		height: 100%;
		position: absolute;
		background: ${LEGEND_COLOR_MAP[props.sentiment]};
		top: 0px;
		left: -1px;
	}
`);

export const MiningBoxDetail = styled(Box)(`
  	position:relative;
	display:flex;
	flex-wrap:wrap;

	&:before {
		content: '';
		position: absolute;
		top: -20px;
		left: 66px;
		width: calc(100% - 140px);
		height: 1px;
		background: #5ba3fa;
	}

	&.miningDetails .dataOverflow:after {
		content: attr(data-name);
		position: relative;
		top: 0;
		left: 0;
		width: 100% !important;
		height: calc(100% + 0px);
		background: #fff;
		z-index: 2;
		display: flex;
		align-items: center;
	}
`);

export const MiningColumnWrap = styled(Box)(`
	display: flex;
	flex-direction: column;
	margin-bottom: auto;
`);

export const ExpandCollapseWrap = styled(Box)(`
	position: absolute;
	right: -20px;
	top: 11px;
`);

export const ExpandButton = styled(MuiButton)(props =>`
	color: ${props.expended ? "#000000 " : '#095BBD'};
	font-size: 14px;
	font-weight: ${props.expended ? '300' : '500'};
	&.Mui-disabled {
		color: #000000;
	}
	& svg {
		display:none;
		width: 17px;
		margin-right: 10px;
	}
`)

export const ExpandButtonWrap = styled(Box)(`
	position:relative;
`)

export const Pipe = styled(Box)(`
	line-height:2;
`)
