import React from 'react'
import { Radio, FormControlLabel } from '@mui/material'
import { FormLabel, FormError, RadioGroupWrap } from './style'

const MuiRadioButton = (props) => {
  return (
    <RadioGroupWrap onChange={props.onChange}>
      <Radio checked={props.checked} value={props.value} name={props.name} variant="outlined" size="sm" />
      {props?.title && <FormLabel>{props.title}</FormLabel>}
      {props?.error && <FormError>{props?.error}</FormError>}
    </RadioGroupWrap>
  )
}

export default MuiRadioButton
