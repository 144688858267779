
import React from 'react'

const IconThumbsDownButton = ({isClicked=false}) => {
  return (
    <>
      {isClicked 
      ? <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.5 11L8 15L9.5 14V11.5L8.77441 9.5H11L13.5 8.5L13 6L12.5 5L12 1.5L10.5 0.5H7H3.5L3 1.5H1.5H0.5V8L1.5 8.5H3L5.5 11Z" fill="#353535"/>
        </svg>
      : <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.5789 9.07422C13.1282 9.62116 12.393 9.96095 11.6603 9.96095H9.46885C9.85117 10.6302 10.1303 11.2587 10.3015 11.8373C10.5806 12.7797 10.5746 13.5961 10.2838 14.2636C9.89486 15.1566 9.03476 15.7374 7.72745 15.9899C7.58465 16.0174 7.43597 15.9882 7.31523 15.9086C7.19448 15.8289 7.11153 15.7056 7.08489 15.566C6.5199 12.6071 5.38753 11.6661 4.38851 10.8358C3.84027 10.3801 3.2757 9.91093 2.88063 9.17734H1.27265C0.570934 9.17734 0 8.62154 0 7.93842V2.22495C0 1.54182 0.570934 0.98603 1.27265 0.98603H2.72845C3.15572 0.37795 3.87217 9.53674e-07 4.63833 9.53674e-07H10.4138C11.531 9.53674e-07 12.4117 0.81807 12.7122 2.13495L13.9588 7.59554C14.0761 8.10945 13.9412 8.63457 13.5789 9.07422ZM12.8936 7.82596L11.6471 2.36538C11.5576 1.97355 11.2584 1.06224 10.4138 1.06224H4.63833C4.17059 1.06224 3.7375 1.3303 3.5349 1.74517C3.4445 1.9303 3.25272 2.04827 3.04217 2.04827H1.27265C1.17429 2.04827 1.09117 2.12918 1.09117 2.22495V7.93842C1.09117 8.03417 1.17429 8.11509 1.27265 8.11509H3.22774C3.44905 8.11509 3.64843 8.24522 3.73247 8.44451C4.02414 9.13623 4.4976 9.52971 5.097 10.0279C6.11487 10.8738 7.36714 11.9144 8.04022 14.815C8.67272 14.6106 9.0885 14.2868 9.27928 13.8489C9.66385 12.9661 9.2258 11.4989 8.04586 9.71769C7.93759 9.55428 7.92974 9.34636 8.0253 9.17564C8.12088 9.00494 8.30463 8.89871 8.50436 8.89871H11.6603C12.0577 8.89871 12.4867 8.70213 12.7278 8.40953C12.8831 8.22096 12.9389 8.02465 12.8936 7.82596Z" fill="#353535"/>
        </svg>}
    </>
  )
}

export default IconThumbsDownButton