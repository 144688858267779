import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import { MEDIA } from '../../constants'

export const FooterWrapper = styled(Box)(`
  border-top: 1px solid #E0E0E0;
  position: static;
  justify-content: space-between;
  display: flex;
  height: 60px;
  margin-top: -1px;
  padding: 20px 30px;
  align-items: center;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  color: #000;
  & a{
    text-decoration:none;
    color: #000;
  }

  & .BtnCanWeHelpButton {
    display:inline;
  }

  & .BtnCanWeHelpButton button {
    text-decoration:none;
    color: #000;
    background:transparent;
    font-size:12px;
    font-weight:300;
    padding:0;
    line-height:1;
    outline:0;
    border:0 !important;
    margin-left:0 !important;
  }

  @media (max-width: ${MEDIA.lg}) {
    padding: 20px;
  }
  @media (max-width: 500px) {
    flex-direction: column-reverse;
  }
`)
