import { Box, IconButton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { edit } from '../../assets/images/icons'
import MuiImage from '../muiImage'
import EditVerbatimModal from './editModal'
import { ROLES_MM_PRIMARY } from '../../constants'
import { VerbatimImageVideoTag } from '../../components'
import { Wrapper, Content, IconWrap, Text1, Text2 } from './style'
import { useGlobalStore } from '../../store'

const VerbatimGrid = ({ data, bg = 'transparent', insightsElementId, showEntityName=false, entityType, showImageVideo=false }) => {
  return (
    <Wrapper className='Information' bg={bg}>
      {data.map((v, i) => {
        return (
          <EditVerbatim key={i} verbatim={v} insightsElementId={insightsElementId} showEntityName={showEntityName} entityType={entityType} VerbatimAdditionalInfo={v?.VerbatimAdditionalInfo || ""}  showImageVideo={showImageVideo} />
        )
      })}
    </Wrapper>
  )
}

export default VerbatimGrid

const EditVerbatim = ({ verbatim, insightsElementId = '', showEntityName=false, entityType, VerbatimAdditionalInfo="", showImageVideo }) => {
  // verbatim.EntityType = ['Brands', 'CompetitorBrand', '', '']
  const [isActiveModal, setActiveModal] = useState(false)
  const [state, setState] = useState(verbatim)
  const {user} = useGlobalStore()
  // const type = (state?.VerbatimMediaPrivateName).split('.').pop() || null

  useEffect(() => {
    setState(verbatim);
  }, [verbatim]);

  return (
    <Content className={state?.VerbatimSentiment}>
      {showImageVideo && !!state?.VerbatimMediaPrivateName && <VerbatimImageVideoTag data={state} />}
      <Text1>
        {state?.VerbatimText}
        <Text2>
          {`[${verbatim.EntityName}${VerbatimAdditionalInfo ? `, ${VerbatimAdditionalInfo}` : ''}]`}
          <Box sx={{ display: 'inline-block' }}>
            {insightsElementId && ROLES_MM_PRIMARY.includes(user?.UserType) && 
              <IconWrap>
                <IconButton size='small' onClick={() => setActiveModal(true)}>
                  <MuiImage src={edit} width='12px' />
                </IconButton>
              </IconWrap>
            }
            {isActiveModal &&
              <EditVerbatimModal insightsElementId={insightsElementId} 
                updateContent={(newText) => setState(prev => ({...prev, VerbatimText: newText }))}
                onClose={() => setActiveModal(false)} text={state.VerbatimText} />
            }
          </Box>
        </Text2>
      </Text1>
      {/* {(entityType === 'Category' || showEntityName) && <span style={{color:'#000', marginLeft: '8px', fontSize: '12px'}}>{`[${verbatim.EntityName}${VerbatimAdditionalInfo ? `, ${VerbatimAdditionalInfo}` : ''}]`}</span>} */}
      {/* <Text2>{`[${verbatim.EntityName}${VerbatimAdditionalInfo ? `, ${VerbatimAdditionalInfo}` : ''}]`}</Text2> */}
      
    </Content>
  )
};