// https://codepen.io/vlt5/pen/NqOvoG
// https://www.youtube.com/watch?v=bXN9anQN_kQ&list=PLDZ4p-ENjbiPo4WH7KdHjh_EMI7Ic8b2B&index=17&ab_channel=TheMuratorium
import React, { useEffect, useRef } from 'react'
import * as d3 from 'd3'

export default function D3Pie (props) {
  return (
    <D3PieChild
      data={props?.data}
      width={props?.settings?.width || 100}
      height={props?.settings?.height || 100}
      innerRadius={props?.settings?.innerRadius || 0}
      outerRadius={props?.settings?.outerRadius || 35}
      x={props?.settings?.cx || 15}
      y={props?.settings?.cy || 15}
      color={props?.settings?.colors}
      textShow={props?.settings?.textShow || false}
      textLineShow={props?.settings?.textLineShow || false}
      textPosition={props?.settings?.textPosition || 'outside'}
      textLocation={props?.settings?.textLocation || 2}
      textSize={props?.settings?.textSize || '12px'}
      textCenterTitle={props?.settings?.textCenterTitle || ''}
      fontWeight={props?.settings?.fontWeight || '400'}
      effects={props?.settings?.effects || 'none'}
    />
  )
}

const D3PieChild = (props) => {
  const {
    data,
    width = 140,
    height = 140,
    innerRadius = 0,
    outerRadius = 50,
    x = 0,
    y = 0,
    color,
    textLineShow = false,
    textShow = false,
    textLocation = 2,
    textSize = '12px',
    effects = 'InnerCircle',
    textPosition = 'inside', // [inside, outside]
    textCenterTitle = '', // [inside]
    fontWeight=400
  } = props
  const ref = useRef(null)
  const createPie = d3
    .pie()
    .value(d => d.value)
    .sort(null)
  const createArc = d3
    .arc()
    .innerRadius(innerRadius)
    .outerRadius(outerRadius)
  const colors = d3.scaleOrdinal(color || d3.schemeCategory10)
  const format = d3.format('.2f')

  useEffect(() => {
    const dataArray = createPie(data)
    const group = d3.select(ref.current)
    const groupWithData = group.selectAll('g.arc').data(dataArray)

    groupWithData.exit().remove()

    const groupWithUpdate = groupWithData
      .enter()
      .append('g')
      .attr('class', 'arc')

    const path = groupWithUpdate
      .append('path')
      .merge(groupWithData.select('path.arc'))

    path
      .attr('class', 'arc')
      .attr('d', createArc)
      .attr('fill', (d, i) => {
        return (d.data.name === 'REMAINING_VALUE') ? '#f1f1f1' : colors(i)
      })


    if (effects === 'InnerCircle') {
      const circle = group.append("circle");
      circle.attr("x", width/2);
      circle.attr("y", height/2);
      circle.attr("r", 45);
      circle.attr("fill", "#ffffff1a");
    }
    
    if (textCenterTitle !== '') {
      const text = groupWithUpdate.append('text')
      .merge(groupWithData.select('text'))
      text
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .attr('transform', 'translate(50%)')
        .style('fill', 'black')
        .style('font-size', 14)
        .text(d => textCenterTitle)
    }

    if (textShow) {
      const text = groupWithUpdate
        .append('text')
        .merge(groupWithData.select('text'))

      if (textPosition === 'inside') {
        text
          .attr('text-anchor', 'middle')
          .attr('alignment-baseline', 'middle')
          .attr('transform', d => `translate(${createArc.centroid(d)})`)
          .style('fill', 'white')
          .style('font-size', 10)
          .text(d => format(d.value))
      }
      if (textPosition === 'outside') {
        text
          .attr('text-anchor', 'start')
          .attr('alignment-baseline', 'middle')
          .style('fill', '#000')
          .attr('dy', 5)
          .attr('text-anchor', 'middle')
          .attr('transform', d => {
            return 'translate(' +
              (((outerRadius * textLocation) - 20) * Math.sin(((d.endAngle - d.startAngle) / textLocation) + d.startAngle)) +
              ', ' +
              (-1 * ((outerRadius * textLocation) - 25) * Math.cos(((d.endAngle - d.startAngle) / textLocation) + d.startAngle)) +
              ')'
          })
          // .attr('display', function (d) { return d.value >= textLocation ? null : 'none' })
          .text((d, i) => {
            return (d.data.name === 'REMAINING_VALUE') ? '' : d.value.toFixed(0) + '%'
          })
          .attr('style', `font-size: ${textSize};`).style("font-weight", fontWeight)
      }
      if (textLineShow) {
        const line1 = groupWithUpdate.append("line")
        line1.attr("class", "horizontalGrid")
        .attr("x1", (d) => { return (((outerRadius * textLocation) - outerRadius) * Math.sin(((d.endAngle - d.startAngle) / textLocation) + d.startAngle)); })
        .attr("y1", (d) => { return (-1 * ((outerRadius * textLocation) - outerRadius) * Math.cos(((d.endAngle - d.startAngle) / textLocation) + d.startAngle)); })
        .attr("x2", (d) => { return (((outerRadius * textLocation) - 35) * Math.sin(((d.endAngle - d.startAngle) / textLocation) + d.startAngle)); })
        .attr("y2", (d) => { return (-1 * ((outerRadius * textLocation) - 40) * Math.cos(((d.endAngle - d.startAngle) / textLocation) + d.startAngle)); })
        .attr('stroke', (d, i) => colors(i))
        .attr("stroke-width", 1)

        const circle = groupWithUpdate.append("circle");
        circle.attr("r", 3.5)
          .attr('fill', (d, i) => colors(i))
          .attr('transform', d => {
            const xVal = (((outerRadius * textLocation) - 35) * Math.sin(((d.endAngle - d.startAngle) / textLocation) + d.startAngle))
            const yVal = (-1 * ((outerRadius * textLocation) - 40) * Math.cos(((d.endAngle - d.startAngle) / textLocation) + d.startAngle))
            return `translate(${xVal || 0}, ${yVal || 0})`
          })
      }
    }
  },[data])

  return (
    <svg width={width} height={height}>
      <g transform={`translate(${x} ${y})`}>
        <g
          ref={ref}
          transform={`translate(${outerRadius} ${outerRadius})`}
        />
      </g>
    </svg>
  )
}
