
export const ROUTE_LOGIN = '/login'
export const ROUTE_AUTHORIZE = '/authorize'
export const ROUTE_AUTHORIZE_AZURE = '/authorize-azure'
export const ROUTE_SSO = '/sso'
export const ROUTE_RESET_PASSWORD = '/reset-password'
export const ROUTE_PROJECTS = '/projects'
export const ROUTE_INITIATE_PROJECTS = '/initiate-projects'
export const ROUTE_INITIATE_PROJECTS_DRAFTS = '/initiate-projects/draft'
export const ROUTE_USER_MANAGEMENT = '/user-management'
export const ROUTE_RESOURCES = '/resources'
export const ROUTE_HELP = '/help'
export const ROUTE_REPORTS = '/reports'

export const ROUTE_REPORTS_BACKGROUND = '/reports-background/'
export const ROUTE_REPORTS_EXECUTIVE_SUMMARY = '/reports-executive-summary/'
export const ROUTE_REPORTS_KEY_FUNDINGS = '/reports-key-findings/'
export const ROUTE_REPORTS_RESEARCH_SECTION1 = '/reports-research-section1/'
export const ROUTE_REPORTS_RESEARCH_SECTION2 = '/reports-research-section2/'
export const ROUTE_REPORTS_DEEP_DIVE_ANALYSIS = '/reports-deep-dive-analysis/'
export const ROUTE_REPORTS_SANDBOX = '/reports-sandbox/'
