import React, { useCallback, useEffect, useState } from 'react'
import {Box} from '@mui/material'
import { Legend, ReportHeading, ReportOuterWrapper } from '../../../../components'
import ReportsService from '../../../../services/reports.service'
import { useUpdateLhs } from '../../../../hooks/useUpdateLhs'
import LandscapeCell from './landScapeCell'
import LandScapeHeader from './landScapeHeader'
import { exportExcel_addPercentage, getObjectAsArray } from '../../../../util'
import { LegendWrap, RootWrap, StyledCellWrap, TableWrap, ListHeaderWrapper, ListDetailsWrapper } from './style'

const ReportLandScape = (props) => {
  const [state, setState] = useState([])
  const [magnetScore, setMagnetScore] = useState([])
  const [showScore, setShowScore] = useState(false)
  const [isDataAvaiableInMatrix, setDataAvaiableInMatrix] = useState(true)
  const [reportExcelData, setReportExcelData] = useState()
  const { updateSubLhsApiLoaded } = useUpdateLhs()
  const [legends, setLegends] = useState([
    { label: 'Positive', value: 'Positive' },
    { label: 'Neutral', value: 'Neutral' },
    { label: 'Mixed', value: 'Mixed' },
    { label: 'Negative', value: 'Negative' }
  ])

  const getCustomData = (arr) => {
    const obj = {}

    for (let index = 0; index < arr.length; index++) {
      const element = arr[index]

      if (obj[element.Entity.EntityName]) {
        obj[element.Entity.EntityName] = [
          ...obj[element.Entity.EntityName],
          element,
        ]
      } else {
        obj[element.Entity.EntityName] = [element]
      }
    }
    return obj
  }

  const getData = useCallback(() => {
    ReportsService.getCompetitiveLandscapeElement({
      insightsElementId: props.InsightsElementId
    })
      .then((resp) => {
        const sortingData = (data) => {
          // return data;
          return data.sort(function(a, b){
            if(a.EntityName < b.EntityName) { return -1; }
            if(a.EntityName > b.EntityName) { return 1; }
            return 0;
          });
        }

        const data = resp.map((item) => {
          return {
            ...item,
            customData: {
              Start: [
                {
                  InsightName: item.DriverName,
                },
                {
                  InsightName: `(${item.DriverPercent}%)`,
                },
              ],
              ...getCustomData(
                getObjectAsArray(item?.EntityImagerySentimentInsight
                  ?.EntityImagerySentimentInsight) || []
              ),
            },
            EntityImagerySentimentInsight:{
              EntityImagerySentimentInsight: sortingData( (getObjectAsArray(item.EntityImagerySentimentInsight.EntityImagerySentimentInsight)).map((d) => ({...d, EntityType:d.Entity.EntityType, EntityName:d.Entity.EntityName})) )
            }
          }
        })

        // console.log('data --------> ', data)
        setState(data)
        updateSubLhsApiLoaded(props)

        // Export to Excel code starts here ..
        const reportExcelDataObj = { heading:[{title: 'Driver Names'}, {title: 'Percent'}], values:[] }
        const reportExcelDataObjHeadingIndex = []
        for(let i=0; i<resp[0].EntityImagerySentimentInsight.EntityImagerySentimentInsight.length; i++){
          const val = resp[0].EntityImagerySentimentInsight.EntityImagerySentimentInsight[i].Entity.EntityName
          if(reportExcelDataObjHeadingIndex.indexOf(val) === -1){
            reportExcelDataObj.heading.push({title:val})
            reportExcelDataObjHeadingIndex.push(val)
          }
        }

        for(let i=0; i<resp.length; i++){
          const arr = [{value: resp[i].DriverName}, exportExcel_addPercentage(resp[i].DriverPercent)]
          const tempValArray = [[], []]
          for(let j=0; j<reportExcelDataObjHeadingIndex.length; j++){ arr.push({value:''}); tempValArray.push([]) }

          for(let j=0; j<resp[i].EntityImagerySentimentInsight.EntityImagerySentimentInsight.length; j++){
            const entityObj = resp[i].EntityImagerySentimentInsight.EntityImagerySentimentInsight[j]
            const getIndex = reportExcelDataObjHeadingIndex.indexOf(entityObj.Entity.EntityName)+2
            let sign = ''
            if((entityObj.InsightSentiment === 'Negative')) { sign = '-' }
            else if((entityObj.InsightSentiment === 'Positive')) { sign = '+' }
            else if((entityObj.InsightSentiment === 'Neutral')) { sign = '~' }
            let value = (sign+(entityObj.InsightName)).replace('++','+').replace('--','-').replace('~~','~')
            if(value==='~' || value==='+' || value==='-') value = ''

            tempValArray[getIndex].push(value)
            arr[getIndex] = {value:(tempValArray[getIndex]).join(', ')}
          }
          reportExcelDataObj.values.push(arr)
        }
        setReportExcelData(reportExcelDataObj)        
        
      })
      .catch((error) => {

      })
  }, [])

  const getBrandCategoryMetricsElementHandler = useCallback(() => {
    ReportsService.getBrandCategoryMetricsElement({
      insightsElementId: props.InsightsElementId
    }).then(resp => {
      setDataAvaiableInMatrix(!!resp?.length);
      const filteredData = resp.filter(item => item.Entity.EntityType !== 'Category').filter(item => item.Entity.EntityType !== 'HiddenBrand')
      const modifiedData = filteredData.map(item => ({
        name: item.Entity.EntityName,
        logo: item.Entity.EntityLogo,
        score: item.BrandMagnetScore,
        type: item.Entity.EntityType
      }))
      const isScoreFound = modifiedData?.every(item => item?.score);
      setShowScore(isScoreFound && resp.length);
      setMagnetScore(modifiedData)
      updateSubLhsApiLoaded(props)
    }).catch(error => {

    })
  }, [])

  useEffect(() => {
    getData()
    getBrandCategoryMetricsElementHandler()
  }, [])

  useEffect(() => {
    if(state?.length && !isDataAvaiableInMatrix) {
      let obj = {}
      const mappedData = state?.forEach(element => {
        obj = {
          ...obj,
          ...(element?.customData || {})
        }
      });
      const data = Object.keys(obj)?.map(key => {
        const element = obj[key]?.[0] || {};
        return ({
          name: element?.Entity?.EntityName,
          logo: element?.Entity?.EntityLogo,
          type: element?.Entity?.EntityType
        })
      })
      const filteredData = data?.filter(item => item?.name)
      setMagnetScore(filteredData)
      setShowScore(false)
    }
  }, [state, isDataAvaiableInMatrix])
  
  useEffect(() => {
    if(state?.length) {
      const stateArray1 = ((state?.map(row => row.EntityImagerySentimentInsight.EntityImagerySentimentInsight.map(d => d.InsightStandout))).flat(1))?.filter(flag => flag)      
      if(stateArray1.length > 0){
        setLegends([{ label: 'Standout Insight', value: 'StandoutInsight' }, ...legends])
      }
    }
  }, [state])

  return (
    <>
      {!!state?.length && !!magnetScore?.length &&
        <>
          <ReportHeading downloadId={props.type} {...props} downloadIdData={reportExcelData} />
          <ReportOuterWrapper>
            <RootWrap id={props.type} dataLength={state?.length}>
              <TableWrap className={'listWrapper'}>
                <ListHeaderWrapper className="pptListWrapper pptListHeader"><LandScapeHeader showScore={showScore} scores={[...magnetScore]} /></ListHeaderWrapper>
                {state?.map((row, i) => {
                  return (
                    <ListDetailsWrapper key={i} className="pptListWrapper">
                      { [{ name: 'Start', logo: 'Start' }, ...magnetScore].map((columnKey, index, arr) => {
                        const column = row?.customData?.[columnKey.name]
                        return (
                          <StyledCellWrap className={'list'} key={columnKey?.name} width={`${100 / (arr.length)}%`}>
                            <LandscapeCell
                                legends={legends.filter(f => f.value === 'StandoutInsight')}
                                column={column}
                                isLastColumn={index + 1 === arr.length}
                            />
                          </StyledCellWrap>
                        )
                      })}
                    </ListDetailsWrapper>
                  )
                })}
              </TableWrap>
              <LegendWrap>
                <Legend legends={legends} />
              </LegendWrap>
            </RootWrap>
          </ReportOuterWrapper>
        </>
      } 
    </>
  )
}

export default ReportLandScape
