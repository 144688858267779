import { styled } from '@mui/system'

export const ListWrapper = styled('ul')(`
    flex-wrap: wrap;
    padding:10px 0;
    justify-content: space-between;
    display: flex;
    list-style:none;
    text-transform: capitalize;
    
    & img {
        width:100px;
    }
    & p {
        padding: 14px;
        width: calc(100% - 100px);
    }
`)

export const List = styled('li')(`
    width: calc(50% - 20px);
    padding:20px 0;
`)

export const Link = styled('a')(`
    min-height:100px;
    text-decoration: none;
    color: black;
    display: flex;
    align-items: center;
    justify-content: end;
    box-shadow: 0px 0 5px rgb(0 0 0 / 10%);
    cursor:pointer;
    &:hover{
        box-shadow: 0px 0px 12px 8px rgb(2 2 2 / 3%);
        transform: translateY(-5px) scale(1.005) translateZ(0);
    }
`)


