import React, { useState, useEffect, useRef } from 'react'
import { Dialog } from '@mui/material'
import { DialogContentWrapper, Percentage, Msg } from './style'
import { Loader } from '../'
// import LoaderProgressive from './loaderProgressive'

const BlockingLoader = props => {
  const { msg = undefined, value, maxValue, minPercentage=0, maxPercentage=100} = props
  const [fakePercentage, setFakePercentage] = useState(0)

  let percentage = Math.round(value/maxValue*maxPercentage) || null
  if(percentage === 100){percentage = 99}

  const timer = useRef(null)
  const updateFakePercentage = (percent) => {
    setFakePercentage(percent)
    timer.current = setTimeout( () => {
      setFakePercentage(percent+1)
      updateFakePercentage(percent+1)
    }, 4000)
  }

  useEffect(() => {
    if(percentage && percentage<95){
      if(timer.current) clearTimeout(timer.current)
      setFakePercentage(percentage)
      updateFakePercentage(percentage)
    }
    else if (percentage>99) {
      setFakePercentage(98)
    }
    return () => {clearTimeout(timer.current)}
  },[percentage])
  
  useEffect(() => {
    if (fakePercentage>99) {
      clearTimeout(timer.current)
      setFakePercentage(98)
    }
  },[fakePercentage])

  return (
    <Dialog id='dialog-loading' open disableEscapeKeyDown>
      <DialogContentWrapper>
        {<Loader width={70} height={70} />}
        {/* {maxValue === 0 && <Loader width={70} height={70} />} */}
        {/* {maxValue > 0 && <LoaderProgressive width={70} height={70} percentage={percentage} />} */}
        {/* {maxValue > 0 && percentage > 0 && <Percentage>{percentage}% / ( {fakePercentage}% ) </Percentage> } */}
        {maxValue > 0 && fakePercentage >= 0 && <Percentage>{fakePercentage+minPercentage}%</Percentage> }
        {msg && <Msg>{msg}</Msg>}
      </DialogContentWrapper>
    </Dialog>
  )
}

export default BlockingLoader
