import { Box, Typography } from '@mui/material'
import React from 'react'
import { SOCIAL_IMAGE_MAP } from '../../constants'
import { MuiImage } from '..'
import { LegendItem, LegendsWrap } from './style'

const DEFAULT = ['Facebook', 'Forum', 'Instagram', 'Twitter', 'TikTok', 'YouTube', 'Reddit', 'GitHub']

const SocialLegend = ({ legends = DEFAULT }) => {
  return (
    <LegendsWrap>
      {legends.map((legnedKey) => {
        return (
          <LegendItem key={legnedKey}>
            <Box className='socialLegendBox'>
              <MuiImage src={SOCIAL_IMAGE_MAP[legnedKey.toLowerCase()]} width='14px'/>
            </Box>
            <Typography className='sociallegendText'>{legnedKey}</Typography>
          </LegendItem>
        )
      })}
    </LegendsWrap>
  )
}

export default SocialLegend
